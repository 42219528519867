import { AppStoreGallery } from './AppStoreGallery';
import TermsAndPrivacy from './TermsAndPrivacy';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LogoFullWhite } from 'components/svg/logo_full_white.svg';
import IconRound from 'components/blocks/IconRound';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import Paragraph from 'components/blocks/Paragraph';

import { useCycle } from 'framer-motion';
import { setStaticPage } from 'store/actions/staticPages';

import GetLang from 'hoc/GetLang';
import { useEffect } from 'react';
import { analytics } from 'helpers/segment';
import LottieBlock from 'components/blocks/LottieBlock';
import DesktopAlternate from 'components/blocks/DesktopAlternate';
import { useLocation, useSearchParams } from 'react-router-dom';
import { loadNamedState, saveNamedState } from 'store/localStorage';
import BtnInstall from './BtnInstall';
import { checkReferrer } from './checkReferrer';
import { getSendLink, getUrlParams } from 'helpers/helperFunctions';

const PageHomeInstall = ({ setStaticPage }) => {
    const location = useLocation();
    // let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        analytics.page('Install Page');
        // console.log(location);

        const referrer = location?.search ? `https://balabenzine.app/${location?.search}` : document.referrer || undefined;
        checkReferrer(referrer);
    }, []);

    return (
        <GetLang>
            {({ rtl, lang, translations }) => (
                <>
                    <DesktopAlternate />
                    <div className={classNames('bg-bb-blue-900 flex flex-col justify-between min-h-screen-100 h-full bg-cover sm:hidden')}>
                        <div className={classNames('text-white text-vw-4xl md:text-4xl bg-bb-blue-900 relative')}>
                            <div className={classNames('w-full h-screen-1/3')}>
                                <div className='absolute inset-x-0 top-0 h-screen-2/5 overflow-hidden'>
                                    <LottieBlock speed={0.35} />
                                </div>
                                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 `}>
                                    <LogoFullWhite />
                                </div>
                            </div>
                        </div>

                        <div className={classNames('z-10 bg-white rounded-t-3xl')}>
                            <div className='relative h-full text-vw-base '>
                                <IconRound icon='DownloadCloud2' preset='yellow' />

                                <div className={classNames('pt-vw-12 pb-vw-40')} style={{ direction: rtl ? 'rtl' : 'ltr' }}>
                                    <HeadlineType1 label={translations.data['install.introTitle']} textSize='text-vw-2xl' margins='mb-vw-4' />

                                    <div className={classNames('px-vw-8 flex flex-col items-center')}>
                                        <div className='w-full'>
                                            <Paragraph preset='small-faded' margins='mb-vw-8' align='justify-left' className='static' color='text-gray-900'>
                                                <div
                                                    className={'static'}
                                                    dangerouslySetInnerHTML={{
                                                        __html: translations.data['install.introBody']
                                                        // content.introBody
                                                    }}
                                                />
                                            </Paragraph>
                                        </div>
                                    </div>
                                    <AppStoreGallery classNames={classNames} />

                                    <HeadlineType1 label={translations.data['install.reqTitle']} textSize='text-vw-2xl' margins='mb-vw-4' />

                                    <div className={classNames('px-vw-8 flex flex-col items-center')}>
                                        <div className='w-full'>
                                            <Paragraph preset='small-faded' margins='mb-vw-8' align='justify-left' className='static' color='text-gray-900'>
                                                <div
                                                    className={'static'}
                                                    dangerouslySetInnerHTML={{
                                                        __html: translations.data['install.reqBody']
                                                    }}
                                                />
                                            </Paragraph>
                                        </div>
                                    </div>

                                    <div className={classNames('px-vw-8 flex flex-col items-center')}>
                                        <div className={classNames('px-vw-6 py-vw-8 bg-gray-100 rounded-3xl')}>
                                            <div className='w-full'>
                                                <div className='flex gap-2 text-vw-xl items-center'>
                                                    <i className='ri-apple-fill'></i>
                                                    <h2 className='font-bold'>{translations.data['install.iPhoneTitle']}</h2>
                                                </div>
                                                <Paragraph preset='small-faded' margins='mt-vw-4a mb-vw-4a' align='justify-left'>
                                                    <div
                                                        className={'static install'}
                                                        dangerouslySetInnerHTML={{
                                                            __html: translations.data['install.iPhoneBody']
                                                        }}
                                                    />
                                                </Paragraph>
                                            </div>
                                            <div className='w-full mt-vw-6'>
                                                <div className='flex gap-2 text-vw-xl items-center'>
                                                    <i className='ri-android-fill text-lime-500'></i>
                                                    <h2 className='font-bold'>{translations.data['install.androidTitle']}</h2>
                                                </div>
                                                <Paragraph preset='small-faded' margins='mt-vw-4a' align='justify-left'>
                                                    <div
                                                        className={'static install'}
                                                        dangerouslySetInnerHTML={{
                                                            __html: translations.data['install.androidBody']
                                                        }}
                                                    />
                                                </Paragraph>
                                            </div>
                                        </div>
                                        <TermsAndPrivacy lang={lang} setStaticPage={setStaticPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={classNames(
                                'fixed z-20 bottom-0 bg-white shadow-2xl-top shadow-bb-blue-800/20 gap-2 w-full flex flex-col justify-center items-center px-vw-8 py-vw-6'
                            )}
                        >
                            <BtnInstall
                                disabled={false}
                                rtl={rtl}
                                dataInstall={translations.data['install.btnInstall']}
                                dataInstalled={translations.data['install.btnInstalled']}
                            ></BtnInstall>
                            <div className='text-vw-sm static'>
                                {`Copyright ${new Date().getFullYear()} - `}
                                <strong>
                                    <u>
                                        <a href='https://balabenzine.com' target='_blank'>
                                            Bala Benzine
                                        </a>
                                    </u>
                                </strong>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    staticPages: state.staticPages,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHomeInstall);
