import BtnPill from 'components/buttons/BtnPill';
import { LayoutPageBody, LayoutPageHeader } from 'components/layouts';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLoading, toggleLang, toggleReducedMotion, toggleTime } from 'store/actions/globalState';
import { langButtons, motionButtons, timeButtons } from 'constants/structRoutes';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import SectionFlat from 'components/blocks/SectionFlat';
import BtnSettings from 'components/buttons/BtnSettings';
import { updateOnboarding } from 'store/actions/onboarding';
import { defaultOnboarding } from 'constants/defaultObjects';
import { updateProfile } from 'fetch/apiRequests';
import { loadUser } from 'store/actions/auth';
import { useEffect } from 'react';
import { analytics } from 'helpers/segment';

const PageSettings = ({
    loadUser,
    updateOnboarding,
    toggleLang,
    toggleTime,
    toggleReducedMotion,
    handleSettings,
    globalState: { lang, time24, reducedMotion },
    auth: { user, isAuthenticated }
}) => {
    const handleLang = async (val) => {
        if (val !== lang) {
            toggleLang(val);
            await updateProfile({ lang: val ?? 'en' }, user);
            analytics.track('Language Changed', { lang: val ?? 'en' }, isAuthenticated);
            loadUser(false);
        }
    };

    const handleTime = (time) => {
        toggleTime(time);
        analytics.track('Time Format Changed', { time24 }, isAuthenticated);
    };
    const handleReducedMotion = (motion) => {
        toggleReducedMotion(motion);
        analytics.track('Motion Settings Changed', { reducedMotion }, isAuthenticated);
    };

    const handleTooltips = () => {
        updateOnboarding(defaultOnboarding());
        analytics.track('Tooltips Reset', null, isAuthenticated);
    };

    const handleAppSettings = () => {
        // delete all localStorage
    };

    useEffect(() => {
        analytics.page('Settings', 'App', isAuthenticated);
    }, []);

    const pageData = {
        page: 'app.settings',
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        btnFlip: true,
        btnIcon: 'Close',
        zIndex: 41,
        checkIdParam: true
    };

    return (
        <>
            <LayoutPageHeader
                handleButton={handleSettings}
                pageData={pageData}
                // pageParams={params}
            ></LayoutPageHeader>

            <div className='h-body overflow-y-auto text-vw-base no-scrollbar'>
                <LayoutPageBody minHeight=''>
                    <GetLang>
                        {({ rtl, translations }) => (
                            <div>
                                <SectionFlat title={'e.pageSettings.language'}>
                                    {langButtons.map((singleLang) => (
                                        <BtnSettings
                                            key={`btn_lang_${singleLang.lang}`}
                                            val={singleLang.lang}
                                            testVal={lang}
                                            handleClick={handleLang}
                                            label={singleLang.label}
                                        />
                                    ))}
                                </SectionFlat>
                                <SectionFlat title={'e.pageSettings.time'}>
                                    {timeButtons.map((timeButton) => (
                                        <BtnSettings
                                            key={`btn_time_${timeButton.time24}`}
                                            val={timeButton.time24}
                                            testVal={time24}
                                            handleClick={handleTime}
                                            label={timeButton.label}
                                        />
                                    ))}
                                </SectionFlat>

                                <SectionFlat title={'e.pageSettings.motion'}>
                                    {motionButtons.map((motionButton) => (
                                        <BtnSettings
                                            key={`btn_motion_${motionButton.motion}`}
                                            val={motionButton.motion}
                                            testVal={reducedMotion}
                                            handleClick={handleReducedMotion}
                                            label={motionButton.label}
                                        />
                                    ))}
                                </SectionFlat>
                                <SectionFlat>
                                    <BtnSettings type='border' normalButton handleClick={handleTooltips} label={'e.pageSettings.tooltips.reset'} />
                                    {/* <BtnSettings
                                        normalButton
                                        handleClick={handleAppSettings}
                                        label={'e.pageSettings.reset'}
                                    /> */}
                                </SectionFlat>
                            </div>
                        )}
                    </GetLang>
                </LayoutPageBody>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    search: state.search,
    results: state.results
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLang,
            toggleReducedMotion,
            toggleTime,
            toggleLoading,
            updateOnboarding,
            loadUser
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSettings);
