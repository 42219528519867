import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnSettings = ({
    type,
    val,
    testVal,
    handleClick,
    disabled,
    label,
    children,
    normalButton
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        type={
                            type ??
                            (normalButton
                                ? 'primary'
                                : val === testVal
                                ? 'confirmed'
                                : 'default')
                        }
                        height={normalButton ? 'h-vw-14' : 'h-vw-10'}
                        justify={
                            normalButton ? 'justify-center' : 'justify-between'
                        }
                        rtl={rtl}
                        params={[val]}
                        disabled={disabled}
                        handleClick={handleClick}
                        textSize='text-vw-sm'
                    >
                        {label ? (
                            <>
                                {/* {normalButton && <span className='w-6' />} */}

                                {translations.data[label] ?? label}
                                {normalButton ? null : val === testVal ? (
                                    <Icons icon={'Check'} />
                                ) : (
                                    <span className='w-6' />
                                )}
                            </>
                        ) : (
                            children
                        )}
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnSettings;
