import { useDrag } from '@use-gesture/react';
import { animate, motion, useMotionValue, useCycle } from 'framer-motion';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useState, useEffect } from 'react';
import classNames from 'classnames';

// const variants = {
//     on: (x) => ({
//         x: -120,

//         transition: {
//             x: { duration: 0.2, ease: [0.6, 0, 0, 1] }
//         }
//     }),
//     off: (x) => ({
//         x: '0',

//         transition: {
//             x: { duration: 0.2, ease: [0.6, 0, 0, 1] }
//         }
//     })
// };

const animations = {
    default: {
        type: 'tween',
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1]
    },
    open: {
        type: 'tween',
        duration: 0.2,
        ease: [0.25, 1, 0.5, 1]
    },
    close: {
        type: 'tween',
        duration: 0.2,
        ease: [0.25, 1, 0.5, 1]
    }
};

const SwipeContainer = ({ runOnOpen, runOnClosed, children, rtl, swipeOffset = 120, threshold = 0.4, isFocus, setIsFocus, swipe = true, minHeight }) => {
    // const [anim, cycleAnim] = useCycle('off', 'on');
    // const [isFocus, setIsFocus] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        isOpen ? runOnOpen?.() : runOnClosed?.();
    }, [isOpen]);

    // let x = useMotionValue(rtl ? -swipeOffset : 0);
    let x = useMotionValue(0);
    const bind = useDrag(
        ({ last, velocity: [vx], direction: [dx], movement: [mx], offset: [ox], cancel, canceled }) => {
            // x.set(ox);
            // console.log({ ox, mx, vx, dx, last });
            // console.log(x.current);
            // if (ox < -120) cancel();

            if (swipe) {
                if (last) {
                    // console.log('transitioning');
                    // console.log({ ox, mx, vx, dx, last });
                    setIsFocus(true);
                    if (!rtl) {
                        if (ox < -swipeOffset * threshold) {
                            animate(x, -swipeOffset, {
                                ...animations.open,
                                onComplete: () => setIsOpen(true)
                            });
                        } else if (ox >= -swipeOffset * threshold && dx !== 0) {
                            animate(x, 0, {
                                ...animations.close,
                                onComplete: () => setIsOpen(false)
                            });
                        }
                    } else {
                        if (ox > swipeOffset * threshold) {
                            // console.log('auto open');
                            animate(x, swipeOffset, {
                                ...animations.open,
                                onComplete: () => setIsOpen(true)
                            });
                        } else if (ox <= swipeOffset * threshold && dx !== 0) {
                            // console.log('auto close');
                            animate(x, 0, {
                                ...animations.close,
                                onComplete: () => setIsOpen(false)
                            });
                        }
                    }
                } else {
                    // if (!rtl) {
                    //     if (x.current <= -swipeOffset) setIsOpen(true);
                    //     if (x.current >= 0) setIsOpen(false);
                    // } else {
                    //     if (x.current <= -swipeOffset) setIsOpen(false);
                    //     if (x.current >= 0) setIsOpen(true);
                    // }

                    // console.log('popped');
                    animate(x, ox, {
                        ...animations.default,
                        onUpdate: () => {
                            // console.log(x.current);
                            if (!rtl) {
                                if (x.current >= -5) setIsOpen(false);
                                if (x.current <= -swipeOffset) setIsOpen(true);
                            } else {
                                if (x.current <= 5) setIsOpen(false);
                                if (x.current >= swipeOffset - 5) setIsOpen(true);
                            }
                        }
                    });
                }
            }
        },
        swipe && {
            from: () => {
                if (rtl) return isOpen ? [0, x.get()] : [x.get(), -swipeOffset];
                else return isOpen ? [x.get(), -swipeOffset] : [0, x.get()];
            },

            filterTaps: true,

            bounds: {
                right: rtl ? swipeOffset : 0,
                left: rtl ? 0 : -swipeOffset
            },
            rubberband: false
        }
    );
    // useDidMountEffect(() => {
    //     if (isOpen && !isFocus) {
    //         if (!rtl) {
    //             animate(x.get(), 0, {
    //                 ...animations.close,
    //                 onComplete: () => setIsOpen(false)
    //             });
    //         } else {
    //             animate(x, -swipeOffset, {
    //                 ...animations.close,
    //                 onComplete: () => setIsOpen(false)
    //             });
    //         }
    //     }
    // }, [isFocus]);
    useEffect(() => {
        if (swipe && isOpen && !isFocus) {
            if (!rtl) {
                // console.log('should close this one');
                animate(x, 0, {
                    ...animations.default,
                    onComplete: () => setIsOpen(false)
                });
            } else {
                animate(x, -swipeOffset, {
                    ...animations.default,
                    onComplete: () => setIsOpen(false)
                });
            }
        }
    }, [isFocus]);

    // useDidMountEffect(() => {
    //     console.log('It is now', isOpen);
    // }, [isOpen]);

    return (
        <div
            // onClick={() => cycleAnim()}
            className='overflow-hidden w-screen-100 md:w-full h-full'
        >
            <motion.div
                className={classNames('flex items-center h-full flex-nowrap', minHeight)}
                {...bind()}
                // animate={getAnimate}
                // initial={false}
                // variants={variants}
                // custom={x}

                style={{ touchAction: 'pan-y', x: x }}
                // onClick={() => console.log('hello')}
            >
                {children({ isOpen })}
            </motion.div>
        </div>
    );
};

export default SwipeContainer;
