import { appVersion } from 'constants/globalConstants';

const identify = (profileBody, user, isAuthenticated) => {
    if (user && isAuthenticated) {
        const profile = { ...user?.profile, ...profileBody };

        window?.analytics.identify(user?.id, {
            name: `${profile?.firstName} ${profile?.lastName}`,
            email: user?.email.toLowerCase(),
            phone: user?.phoneNumber,
            gender: profile?.gender,
            dob: profile?.dob,
            lang: profile?.lang,
            avatar: profile?.avatar,
            verifiedProfile: profile?.verifiedProfile,
            verifiedId: profile?.verifiedId,
            verifiedCarRegistration: profile?.verifiedCarRegistration,
            hasCar: profile?.hasCar,
            rating: profile?.rating,
            totalReviews: profile?.totalReviews,
            totalRewards: profile?.totalRewards,
            totalRating: profile?.totalRating,
            totalPublished: profile?.totalPublished,
            totalBooked: profile?.totalBooked,
            memberSince: profile?.createdAt,
            lastLoggedInDate: new Date(),
            lastBookedDate: profile?.lastBookedDate,
            lastPublishedDate: profile?.lastPublishedDate,
            appVersion
        });
    }
};

const page = (pageName, category = 'App', isAuthenticated = true) => {
    if (isAuthenticated || category === 'Static') {
        window?.analytics.page(category, pageName);
    }
};

const track = (eventName, attributes, isAuthenticated) => {
    if (eventName.startsWith('Signup') || isAuthenticated) {
        window?.analytics.track(eventName, attributes);
    }
};

/* 
User Registered
User LoggedIn
App Loaded
Profile Updated
Profile Photo Added
Profile Photo Cleared
Language Changed
User Reported  
Conversation Started
Whatsapp Shared
Trip Published
Trip Canceled
Booking Requested
Booking Canceled
Booking Declined
Booking Approved
ID Verification Requested
ID Verification Canceled
ID Verification Declined
ID Verification Approved
Car Verification Requested
Car Verification Canceled
Car Verification Declined
Car Verification Approved
Trip Rating Submitted


*/

export const analytics = {
    identify,
    page,
    track
};
