import { useCallback, useRef } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { connect } from 'react-redux';

import { LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';

import { setStaticPage } from 'store/actions/staticPages';

const PageImageZoom = ({ setStaticPage, staticPages: { page, payload } }) => {
    const imgRef = useRef();
    const onUpdate = useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            console.log(value);
            img.style.setProperty('transform', value);
            // img.style.setProperty('height', '800px');
            // img.style.setProperty('width', '1600px');
        }
    }, []);

    const pageData = {
        backBtn: true,
        zIndex: 41,
        btnIcon: 'Close',
        btnFlip: true,
        checkIdParam: false
    };

    const closeModal = () => {
        setStaticPage(null, null);
    };

    return (
        <>
            <LayoutPageHeader handleButton={closeModal} pageData={pageData}></LayoutPageHeader>

            <div className={classNames('h-body-w-header-0', 'overflow-y-auto-a text-vw-base mt-vw-6a no-scrollbar font-semibold text-gray-500')}>
                <QuickPinchZoom onUpdate={onUpdate}>
                    <img ref={imgRef} src={payload?.src} className='' />
                </QuickPinchZoom>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState,
    staticPages: state.staticPages
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageImageZoom);
