import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputLocation from 'components/forms/InputLocation';
import { updateSearchSeats } from 'store/actions/search';
import useMyNavigation from 'hooks/useMyNavigation';
import { getNavParams } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import BtnIcon from 'components/buttons/BtnIcon';
import classNames from 'classnames';

const LayoutFindSeats = ({
    updateSearchSeats,
    search: {
        search: { seats },
        isValid
    },
    source
}) => {
    const handleSetSeats = (opType) => {
        if (opType === 'add') updateSearchSeats(seats + 1);
        if (opType === 'sub') updateSearchSeats(seats - 1);
    };

    const { handleNavigation, location } = useMyNavigation();

    const handleNavAction = () => {
        const { toPage, navParams } = getNavParams({
            location,
            page: `app.searchCarSharing.${source}`,
            pageParams: null,
            isValid
        });
        handleNavigation(toPage, navParams);
    };

    return (
        <GetLang>
            {({ tripLang, lang, rtl }) => (
                <div
                    className={classNames(
                        'flex justify-between gap-4 items-center',
                        { 'flex-row-reverse': rtl }
                    )}
                >
                    <BtnIcon
                        icon={'Subtract'}
                        handleClick={handleSetSeats}
                        params={['sub']}
                        disabled={seats <= 1}
                    />
                    <div
                        className={classNames(
                            'text-6xl font-extrabold text-bb-blue-500'
                        )}
                    >
                        {seats}
                    </div>
                    <BtnIcon
                        icon={'Add'}
                        handleClick={handleSetSeats}
                        params={['add']}
                        disabled={seats >= 8}
                    />
                    {/* <InputLocation
                        source={source}
                        handleSetLocation={handleSetSeats}
                        currentValue={search?.[source][tripLang]?.formatted}
                        handleNavAction={handleNavAction}
                    /> */}
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    results: state.results
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateSearchSeats
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutFindSeats);
