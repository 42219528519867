import { LayoutPage, LayoutPageBody } from 'components/layouts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CardVerification } from 'components/cards';

import useMyNavigation from 'hooks/useMyNavigation';

import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';

import NoResults from 'components/blocks/NoResults';
import BtnLoadState from 'components/buttons/BtnLoadState';

import { useEffect, useRef } from 'react';
import { setScroll } from 'store/actions/globalState';
import BtnScroll from 'components/buttons/BtnScroll';

import { PageLoading } from 'pages/index';
import TabSingle from 'components/tabs/TabSingle';
import SwitchSection from 'components/blocks/SwitchSection';
import { useState } from 'react';

const pageData = {
    page: 'app.admin.verify',
    pageRedirect: 'app.searchCarSharing',
    apiUrl: '/verifications/find-admin',
    crudType: 'POST',
    backBtn: true,
    zIndex: 38,
    checkIdParam: false,
    ridePath: 'app.admin.user',
    resultsPerPage: 20
};

const { resultsPerPage } = pageData;

const PageAdminVerify = ({
    onboarding: { tooltips },
    globalState: { scroll },
    setScroll,
    auth: {
        user: { admin }
    }
}) => {
    const ref = useRef(null);

    const { handleNavigation, location } = useMyNavigation();

    const handleButton = () => {
        setScroll(null);
    };

    const [filter, setFilter] = useState({ status: 'PENDING', type: null });

    const handlePending = () => {
        setFilter((prev) => ({ ...prev, status: !prev.status }));
    };

    useEffect(() => {
        if (!admin) handleNavigation('app.searchCarSharing', null);
    }, []);

    useEffect(() => {
        // console.log('loaded verif');
        if (scroll?.page === 'app.admin.verify') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    return (
        <LayoutPage pageData={{ ...pageData, body: filter }} infinite={true}>
            {({
                rtl,
                lang,
                location,
                animStates,
                translations,

                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                size,
                setSize,
                handleNavigation,
                reducedMotion,
                getReducedMotion,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.count,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                const isEmpty = newData?.count === 0;
                const isReachingEnd = newData?.count === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('data is', data);
                return (
                    <>
                        <TabSingle label={'menu.admin.verify'} suffix={`(${newData?.count})`} />
                        <div ref={ref} className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <BtnScroll containerRef={ref} page={'app.admin.verify'} />
                            <LayoutPageBody minHeight={''}>
                                <div className=''>
                                    <>
                                        <div className=''>
                                            <OfflineMode />
                                        </div>
                                        <SwitchSection
                                            icon={'Time'}
                                            iconColorPreset={filter?.status ? 'dark-gray' : 'default'}
                                            color='on'
                                            label='menu.admin.verify.filter.pending'
                                            visible={true}
                                            val={filter?.status}
                                            setVal={handlePending}
                                        />

                                        <div>
                                            <ul className={`flex flex-col items-center`}>
                                                {newData?.data != null ? (
                                                    newData?.data?.length > 0 ? (
                                                        newData?.data?.map?.((item) => {
                                                            return <CardVerification data={item} key={item._id} rtl={rtl} lang={lang} />;
                                                        })
                                                    ) : (
                                                        <div className='w-full px-vw-6 py-vw-6'>
                                                            <NoResults
                                                                title='e.nothingFound.verification.title'
                                                                description='e.nothingFound.verification.description'
                                                            />
                                                        </div>
                                                    )
                                                ) : (
                                                    <PageLoading bg='bg-white' overlay={true} />
                                                )}
                                            </ul>
                                            {!isReachingEnd && online && (
                                                <div className={classNames('px-vw-6 py-vw-4')}>
                                                    <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    auth: state.auth,
    onboarding: state.onboarding
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PageAdminVerify);
