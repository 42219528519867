import { defaultSize, defaultStroke } from './defaults';
const IconUserCheck = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <path d='M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2'></path>
                <circle cx='8.5' cy='7' r='4'></circle>
                <polyline points='17 11 19 13 23 9'></polyline>
            </svg>
        </>
    );
};

export default IconUserCheck;
