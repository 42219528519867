import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import {
    Attachment,
    // Avatar,
    messageHasReactions,
    MessageOptions,
    MessageRepliesCountButton,
    MessageStatus,
    MessageText,
    MessageTimestamp,
    ReactionSelector,
    SimpleReactionsList,
    useMessageContext,
    useChatContext,
    MessageSimple,
    useDeleteHandler,
    useFlagHandler,
    useChannelActionContext
} from 'stream-chat-react';
import classNames from 'classnames';

import './CustomMessage.css';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import Avatar from 'components/blocks/Avatar';
import { widthBiggerThan } from 'helpers/utils';

const CustomMessage = ({ chat: { channelHistory }, ...props }) => {
    const { isReactionEnabled, message, reactionSelectorRef, showDetailedReactions, isMyMessage, readBy, getMessageActions } = useMessageContext();
    const { addNotification } = useChannelActionContext();
    const handleDelete = useDeleteHandler(message);
    const handleFlagging = useFlagHandler(message, { notify: addNotification });
    const { client } = useChatContext();
    const [read, setRead] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const messageWrapperRef = useRef(null);

    const hasReactions = messageHasReactions(message);
    const { historyDate } = props;
    const isMine = isMyMessage(message);
    // console.log(readBy);
    // console.log(message.user);

    const handleRead = () => {
        const allExceptMe = readBy.filter((readers) => readers.id !== client.user.id);
        if (allExceptMe.length > 0) setRead(true);
    };

    const getMessageVisibility = () => {
        return true;
        if (channelHistory != null) {
            return new Date(message?.created_at) > new Date(channelHistory) ? true : false;
        } else return false;
    };

    useEffect(() => {
        if (isMine) handleRead();
    }, [readBy]);

    // useEffect(() => {
    //     if (historyDate) {
    //         console.log('historyDate in custom message', historyDate);
    //         setIsVisible(new Date(message?.created_at) > new Date(historyDate) ? true : false);
    //     } else setIsVisible(true);

    //     // console.log('message is', message);
    // }, [historyDate]);

    if (message.type === 'deleted' && getMessageVisibility()) {
        return (
            <GetLang>
                {({ translations, rtl }) => (
                    <div className='message-wrapper2'>
                        <div className='message-wrapper-content2 relative'>
                            <div className={classNames('mb-vw-1.5 md:mb-1.5 w-full flex', isMine ? 'justify-end' : 'justify-start gap-2')}>
                                {!isMine && <Avatar profile={{ avatar: message.user?.image }} size='w-vw-8 h-vw-8 md:w-8 md:h-8' iconSize={24} />}
                                <div
                                    className={classNames(
                                        isMine ? 'bg-bb-blue-550 text-white' : 'bg-gray-100 ',
                                        ' rounded-xl px-vw-4 py-vw-2 md:px-4 md:py-2 max-w-vw-72 md:max-w-96 font-medium italic break-all'
                                    )}
                                >
                                    {translations.data['e.chat.deletedMessage']}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </GetLang>
        );
    }

    return (
        // str-chat__message-simple__actions
        <>
            {/* <MessageSimple {...props} /> */}
            {getMessageVisibility() ? (
                <GetLang>
                    {({ rtl, translations }) => (
                        <div className={classNames('message-wrapper2 px-vw-4 md:px-4', showActions && isMine && 'bg-gray-200 ')}>
                            <div className='message-wrapper-content2'>
                                <div
                                    className={classNames('mb-vw-1.5 md:mb-1.5 w-full flex relative', isMine ? 'justify-end' : 'justify-start gap-2')}
                                    onClick={() => setShowActions(!showActions)}
                                >
                                    {/* {!isMine && <Avatar image={message.user?.image} />} */}
                                    {!isMine && <Avatar profile={{ avatar: message.user?.image }} size='w-vw-8 h-vw-8 md:w-8 md:h-8' iconSize={24} />}

                                    <div
                                        style={{ direction: rtl ? 'rtl!important' : 'ltr!important' }}
                                        className={classNames(
                                            isMine ? 'is-mine bg-bb-blue-550 text-white' : 'bg-gray-100 ',
                                            ' rounded-xl px-vw-4 md:px-4 py-vw-2 md:py-2 max-w-vw-72 md:max-w-96 font-medium break-words overflow-hidden text-ellipsis',
                                            rtl && 'text-right'
                                        )}
                                    >
                                        <MessageText className={'max-w-vw-64 md:max-w-80'} />
                                        <div
                                            className={classNames(
                                                'float-right ml-4 mt-1 text-vw-xs md:text-xs font-medium ',
                                                isMine ? 'text-white/75' : 'text-black/50'
                                            )}
                                        >
                                            <div className='flex items-center gap-2'>
                                                <MessageTimestamp />
                                                {read && (
                                                    <Icons size={widthBiggerThan() ? '20px' : '4.5vw'} icon='CheckDouble' containerClass='text-emerald-200' />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {showActions && isMine && (
                                        <div
                                            onClick={isMine ? handleDelete : null}
                                            className={classNames(
                                                'absolute top-1/2 -translate-y-1/2 left-0 w-vw-10 h-vw-10 md:w-10 md:h-10 text-gray-600  flex items-center justify-center rounded-full'
                                            )}
                                        >
                                            <Icons icon='Trash' size={widthBiggerThan() ? '20px' : '4.8vw'} />
                                        </div>
                                    )}
                                </div>
                                <MessageStatus />
                                {message.attachments && <Attachment attachments={message.attachments} />}
                                {hasReactions && !showDetailedReactions && isReactionEnabled && <SimpleReactionsList />}
                                <MessageRepliesCountButton reply_count={message.reply_count} />
                            </div>
                        </div>
                    )}
                </GetLang>
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    chat: state.chat
});

export default connect(mapStateToProps, null)(CustomMessage);
