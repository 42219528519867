import { translations } from 'constants/translations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useSearchParams, Navigate, useNavigate } from 'react-router-dom';
import { motion, useIsPresent } from 'framer-motion';
import { animations, getAnimStates, getReducedMotion } from 'constants/animations';
import { buildQueryString, paramsToObject } from 'helpers/helperFunctions';
import { fetcher } from 'fetch/swrHelpers';
import { routePath } from 'constants/structRoutes';
import { PageError } from 'pages';
import { setNewNotifications, toggleTransition } from 'store/actions/globalState';
import { useStickySWR } from 'hooks/useStickySWR';
// import { mutate, useSWR } from 'swr';
import useMyNavigation from 'hooks/useMyNavigation';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { useEffect } from 'react';
import { unreadNotifications, updateProfile } from 'fetch/apiRequests';
import { loadUser } from 'store/actions/auth';
import { useLayoutEffect } from 'react';
import classNames from '../../../node_modules/classnames/index';
import DesktopAlternate from 'components/blocks/DesktopAlternate';

const LayoutPage = ({
    bgColor = 'bg-white',
    loadUser,
    handleData,
    children,
    sticky = true,
    infinite = false,
    makeRequest = true,
    setNewNotifications,
    toggleTransition,
    search: { seats },
    auth,

    globalState: { lang, isTransition, reducedMotion },
    pageData: {
        page,
        pageRedirect = 'app.searchCarSharing',
        crudType = 'GET',
        body,
        apiUrl,
        zIndex = 40,
        checkIdParam = false,
        resultsPerPage,
        managed = true,
        singleResult = false,
        overlay = false
    }
}) => {
    const user = auth?.user;
    const isAuthenticated = auth?.isAuthenticated;
    const profile = auth?.user?.profile;

    const online = useOnlineStatus();
    const rtl = translations?.[lang]?.rtl;
    const tripLang = lang !== 'ar' ? 'en' : 'ar';
    // const location = useLocation();
    // const navigate = useNavigate();
    const { handleNavigation, location } = useMyNavigation();

    let [searchParams] = useSearchParams();
    let params, isValidParams;
    let pagePath = `${routePath(page)}`;
    const { animPages, animCards } = getAnimStates(page, location, rtl);

    const handleLang = async (val) => {
        const res = await updateProfile({ lang: val ?? 'en' }, user);
        loadUser();
    };

    useLayoutEffect(() => {
        if (!profile?.lang) handleLang(lang);
    }, []);

    // const handleNavigation = (toPage = 'app', pageParams) => {
    //     let navLink = routePath(toPage);
    //     let navTo = navLink;
    //     let navFrom = location.pathname;
    //     let navSearch = location.search;

    //     const queryString = buildQueryString(pageParams);

    //     navigate(`${navLink}${queryString}`, {
    //         state: {
    //             from: navFrom,
    //             to: navTo,
    //             fromSearch: navSearch
    //         }
    //     });
    // };
    let paramString = '';
    if (checkIdParam) {
        params = paramsToObject(searchParams.entries());
        isValidParams = !!params?.id;
        paramString = `/${params.id}`;
    }

    // const swrKey = () => {
    //     let requestURL;
    //     if (pagePath === location.pathname) {
    //         if (crudType === 'GET') {
    //             requestURL = apiUrl && params?.id && `${apiUrl}/${params.id}`;
    //             return requestURL;
    //         }
    //         if (crudType === 'POST') {
    //             const body = {
    //                 passengerId: user?._id,
    //                 rideId: 'someid',
    //                 seats
    //             };
    //             requestURL = apiUrl;
    //             return [requestURL, body];
    //         }
    //         return null;
    //     }
    //     return null;
    // };

    // SWR part

    const requestURL = `${apiUrl}${paramString}`;

    // console.log('request url', requestURL);

    const getKey = (pageIndex, previousPageData) => {
        if (previousPageData && !previousPageData.data.length) {
            // console.log('null reached end');
            return null; // reached the end
        }
        if (((makeRequest && (pagePath === location.pathname || overlay)) || !managed) && requestURL) {
            const strParams = `?page=${pageIndex}&perPage=${resultsPerPage}`;
            if (crudType === 'GET') {
                if (singleResult) return requestURL;
                return `${requestURL}${strParams}`;
            }
            if (singleResult) return [`${requestURL}${strParams}`, body];

            return [`${requestURL}?page=${pageIndex}&perPage=${resultsPerPage}`, body];
        }
        // console.log('null from end of function');
        return null;
    };

    const swrOptions = {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
        initialSize: 1,
        revalidateAll: true,
        revalidateFirstPage: true,
        persistSize: false
    };

    const { data, error, isValidating, size, setSize, mutate } = useStickySWR(getKey, fetcher[crudType], swrOptions, sticky, infinite);
    // console.log('data', data);
    const mutateData = async () => {
        await mutate();
        // mutate(
        //     makeRequest && pagePath === location.pathname && requestURL
        //         ? requestURL
        //         : null
        // );
    };

    // console.log('error', error);

    ////// SWR infinite

    useEffect(() => {
        handleData?.(data, isValidating);
    }, [data, isValidating]);

    const checkNewNotifications = async () => {
        const res = await unreadNotifications(user?._id);
        // console.log('checked new Not res', res);
        res?.newNotifications != null && setNewNotifications(res.newNotifications);
    };

    useEffect(() => {
        if (isAuthenticated) checkNewNotifications();
    }, []);

    if (pagePath === location.pathname) {
        if (isValidParams === false) {
            return <Navigate to={`${routePath(pageRedirect)}`} />;
        }
    }

    //
    if (error) return <PageError code={error.status} error={error} isValidating={isValidating} />;
    // SWR end

    return (
        <>
            <motion.div
                key={page}
                style={{ zIndex }}
                className={classNames(bgColor, 'fixed md:statica inset-0   text-vw-base md:text-base no-scrollbar lining-nums md:mt-20')}
                initial={managed ? getReducedMotion('page', animPages, reducedMotion).initial : false}
                animate={managed ? getReducedMotion('page', animPages, reducedMotion).animate : null}
                variants={managed ? animations.pageSlides : null}
                exit={managed ? () => (isAuthenticated != null ? getReducedMotion('page', animPages, reducedMotion).exit : false) : null}
            >
                {children({
                    lang,
                    translations: translations[lang],
                    rtl,
                    tripLang,
                    location,
                    animStates: { animPages, animCards },
                    params,
                    isValidParams,
                    data,
                    isValidating,
                    mutateData,
                    handleNavigation,
                    reducedMotion,
                    getReducedMotion,
                    online,
                    setSize,
                    size
                })}
            </motion.div>
        </>
    );
    // return (
    //     <div
    //         key={page}
    //         style={{ zIndex }}
    //         className='fixed inset-0 bg-white'
    //         // initial={animPages.initial}
    //         // animate={animPages.animate}
    //         // variants={animations.pageSlides}
    //         // exit={animPages.exit}
    //     >
    //         {children({
    //             lang,
    //             translations: translations[lang],
    //             rtl,
    //             tripLang,
    //             location,
    //             animStates: { animPages, animCards },
    //             params,
    //             isValidParams,
    //             data,
    //             isValidating,
    //             mutateData,
    //             handleNavigation
    //         })}
    //     </div>
    // );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleTransition,
            setNewNotifications,
            loadUser
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
