import { connect } from 'react-redux';

import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { bindActionCreators } from 'redux';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';
import { PageLoading } from 'pages/index';
import IconRound from 'components/blocks/IconRound';
import { setStaticPage } from 'store/actions/staticPages';
import { loginSetStep } from 'store/actions/auth';
import { useState, useEffect, useRef } from 'react';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import Uploader from 'components/blocks/Uploader';
import verifyImage from 'components/images/id_verification_01.jpg';
import verifyPlate from 'components/images/plate_verification_01.jpg';
import BtnLoadState from 'components/buttons/BtnLoadState';

import { updateVerification, createVerification } from 'fetch/apiRequests';
import useMyNavigation from 'hooks/useMyNavigation';
import { analytics } from 'helpers/segment';

const PageVerifyPlate = ({ setStaticPage, auth: { isAuthenticated } }) => {
    const pageData = {
        page: 'static.help.terms',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/verifications/me',
        backBtn: true,
        zIndex: 41,
        btnIcon: 'Close',
        btnFlip: true,
        checkIdParam: false,
        // crudType: 'GET'
        singleResult: true
    };

    const { handleNavigation, location } = useMyNavigation();

    const handlePersonal = () => {
        handleNavigation('app.profile.personal');
    };
    let verificationObj;

    // const pageData = {
    //     page: 'app.rides.booked',
    //     pageRedirect: 'app.searchCarSharing',
    //     apiUrl: '/bookings/me',
    //     backBtn: true,
    //     zIndex: 38,
    //     checkIdParam: false,
    //     ridePath: 'app.rides.booking',
    //     resultsPerPage: 10
    // };

    const submitModal = () => {
        if (!verificationObj) {
            // console.log('submitting');
        }
        closeModal();
    };

    const [isModalAction, setIsModalAction] = useState(false);

    const submitVerification = async (mutateData) => {
        setIsModalAction(true);
        // create verification
        const res = await createVerification({ type: 'PLATE', payload: uploadedImages });

        mutateData();
        setIsModalAction(false);
        closeModal(true);
    };

    const cancelVerification = async (mutateData, id) => {
        setIsModalAction(true);

        // cancel verification
        const res = await updateVerification(id, 'PLATE');

        mutateData();
        setIsModalAction(false);
        closeModal(true);
    };

    const closeModal = (mutate) => {
        if (mutate) handleNavigation('app.profile');
        setStaticPage(null);
    };
    const [uploadedImages, setUploadedImages] = useState([]);
    const widgetApi = useRef();

    const getUrlArray = (url, count) => {
        return new Array(count).fill().map((item, index) => ({ url: `${url}nth/${index}/` }));
    };

    const handleVerifyImages = async (url) => {
        // console.log(url);
        setUploadedImages(getUrlArray(url?.cdnUrl, url?.count));
        // const res = await updateProfile({ avatar: url?.cdnUrl ?? null });
        // loadUser();
        // mutateData();
    };

    const openUploader = () => {
        const dialog = widgetApi.current.openDialog();
        // console.log(dialog);

        dialog.switchTab('file');
    };

    const handleClearImages = async (fn) => {
        handleVerifyImages([], fn);
    };

    // useEffect(() => {
    //     return () => {
    //         handleNavigation('app.profile');
    //     };
    // }, []);

    useEffect(() => {
        analytics.page('Verify Car', 'App', isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={{ ...pageData, overlay: true }}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                // console.log('my verifications', data);

                verificationObj = data?.PLATE?.[0];
                const payload = verificationObj?.payload;
                // console.log(verificationObj);

                return (
                    <>
                        <LayoutPageHeader
                            handleButton={closeModal}
                            pageData={pageData}
                            // pageParams={params}
                        ></LayoutPageHeader>

                        <div className={classNames('h-body-w-header-0', 'overflow-y-auto text-vw-base mt-vw-6a no-scrollbar font-semibold text-gray-500')}>
                            <LayoutPageBody minHeighta={classNames('min-h-body-w-header-footer-2')}>
                                <div className=''>
                                    {data ? (
                                        <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                            <div className={classNames('h-body flex flex-col justify-between', 'px-vw-6 relative w-full')}>
                                                <div
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                >
                                                    <OfflineMode margins=' my-6' />
                                                    <h2
                                                        style={{
                                                            direction: rtl ? 'rtl' : 'ltr'
                                                        }}
                                                        className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                                    >
                                                        {translations?.data?.['e.verify.plate.title']}
                                                    </h2>

                                                    <div className={classNames('mt-vw-4')}>
                                                        {translations?.data?.[verificationObj ? 'e.verify.plate.body.pending' : 'e.verify.plate.body.new']}
                                                    </div>
                                                    {!payload && !uploadedImages?.length && (
                                                        <div className={classNames('w-full flex justify-center object-contain h-vh-36 mt-vw-8')}>
                                                            <img src={verifyPlate} />
                                                        </div>
                                                    )}
                                                    {payload?.length > 0 && (
                                                        <div className={classNames('w-full flex justify-center gap-2 mt-vw-8 mb-vw-6')}>
                                                            {payload.map((singleImage) => (
                                                                <div key={singleImage.url}>
                                                                    <img src={singleImage.url} className={classNames('object-cover w-full h-vw-60')} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                    {!payload && uploadedImages?.length > 0 && (
                                                        <div className={classNames('w-full flex justify-center gap-2 mt-vw-8 mb-vw-6')}>
                                                            {uploadedImages.map((singleImage) => (
                                                                <div key={singleImage.url}>
                                                                    <img src={singleImage.url} className={classNames('object-cover w-full h-vw-60')} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={classNames('flex flex-col')}>
                                                    {!verificationObj && (
                                                        <BtnPill
                                                            type={'confirmed'}
                                                            justify={'justify-center'}
                                                            rtl={rtl}
                                                            params={[]}
                                                            handleClick={openUploader}
                                                            margins='mt-vw-6 mb-2'
                                                            disabled={!online}
                                                        >
                                                            {translations?.data?.['e.verify.addImages']}
                                                        </BtnPill>
                                                    )}

                                                    {/* <BtnPill
                                                        type={verificationObj ? 'cancel' : 'primary'}
                                                        justify={'justify-center'}
                                                        rtl={rtl}
                                                        params={[]}
                                                        handleClick={submitModal}
                                                        margins='mt-2'
                                                        disabled={!online || uploadedImages?.length < 2}
                                                    >
                                                        {translations?.data?.[verificationObj ? 'e.verify.cancel' : 'e.verify.submit']}
                                                    </BtnPill> */}
                                                    <BtnLoadState
                                                        colorType={verificationObj ? 'cancel' : 'primary'}
                                                        disabled={!online || (!verificationObj && uploadedImages?.length < 2)}
                                                        spinner={isModalAction}
                                                        handleClick={verificationObj ? cancelVerification : submitVerification}
                                                        params={verificationObj ? [mutateData, verificationObj.id] : [mutateData]}
                                                        label={verificationObj ? 'e.verify.cancel' : 'e.verify.submit'}
                                                    />
                                                    <BtnPill
                                                        type='default'
                                                        justify={'justify-center'}
                                                        rtl={rtl}
                                                        params={[false]}
                                                        handleClick={closeModal}
                                                        margins='mb-vw-4'
                                                    >
                                                        {translations?.data?.['e.later']}
                                                    </BtnPill>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <PageLoading bg='bg-white' overlay={true} />
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                        <Uploader multiple={true} maxImages={4} handleUpdate={handleVerifyImages} params={[mutateData]} widgetRef={widgetApi} verify={true} />
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage,
            loginSetStep
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageVerifyPlate);
