import * as types from 'store/actions/types';

export function updateSelectedDay(day) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_SELECT_DAY,
            day
        });
    };
}

export function updatePublishFull(rideObj) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_RIDE_UPDATE,
            rideObj
        });
    };
}

export function updatePublishSeats(seatsOffered) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_SEATS_UPDATE,
            seatsOffered
        });
    };
}

export function updatePublishSeatsRemaining(seatsRemaining) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_SEATSREMAINING_UPDATE,
            seatsRemaining
        });
    };
}

export function updatePublishDate(date) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_DATE_UPDATE,
            date
        });
    };
}

export function updatePublishDateRange(key, body) {
    // console.log('key', key);
    // console.log('body', body);
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_DATERANGE_UPDATE,
            key,
            body
        });
    };
}

export function updatePublishTime(time) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_TIME_UPDATE,
            time
        });
    };
}

export function updatePublishDescription(description) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_DESCRIPTION_UPDATE,
            description
        });
    };
}

export function updatePublishPreferences(preferences) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_PREFERENCES_UPDATE,
            preferences
        });
    };
}

export function updatePublishLocation(location, source) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_LOCATION_UPDATE,
            location,
            source
        });
    };
}

export function updatePublishLadiesOnly(ladiesOnly) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_LADIESONLY_UPDATE,
            ladiesOnly
        });
    };
}

export function updatePublishRecurring(recurring) {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_RECURRING_UPDATE,
            recurring
        });
    };
}

export function togglePublishSwitching() {
    return (dispatch) => {
        dispatch({
            type: types.PUBLISH_STOP_SWITCH
        });
    };
}
