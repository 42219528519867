import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnRequestView = ({ params, handleClick, disabled }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        type='confirmed'
                        justify={'justify-between'}
                        rtl={rtl}
                        disabled={disabled}
                        params={params}
                        handleClick={handleClick}
                    >
                        <>
                            <span className='w-6' />
                            {translations?.data?.['e.viewBookingRequest']}
                            <Icons icon={'ArrowRightLong1'} flip={rtl} />
                        </>
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnRequestView;
