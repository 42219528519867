import Spinner from 'components/blocks/Spinner';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnLoadMore = ({ colorType, params, handleClick, spinner, children, disabled, archive, label = 'e.loadMore' }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        type={colorType ?? (archive ? 'archive' : 'primary')}
                        justify={spinner ? 'justify-center' : 'justify-center'}
                        rtl={rtl}
                        disabled={disabled}
                        params={params}
                        handleClick={spinner ? null : handleClick}
                    >
                        {spinner ? <Spinner strokeColor={archive ? '#333333' : '#ffffff'} /> : <>{children ?? translations?.data?.[label] ?? label}</>}
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnLoadMore;
