import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import Spinner from 'components/blocks/Spinner';

const Input = ({
    id,
    obj,
    handleChange,
    placeholder,
    disabled,
    regex,
    tabId,
    tab,
    maxTabs = 3,
    handleTab,
    owValidation,
    owValidationValue,
    loading,
    minTestLength = 0,
    padding,
    children,
    clear,
    root
}) => {
    const ref = useRef(null);

    const handleInput = (e) => {
        let cValue = e.target.value;
        if (id === 'email') cValue = cValue.toLowerCase().trim();
        const testPassed = cValue?.length >= minTestLength ? regex?.test(cValue) ?? true : null;
        handleChange(id, cValue, testPassed);
    };

    const handleKey = (e) => {
        if (e.key === 'Enter') {
            if (tabId < maxTabs) handleTab(tabId + 1);
            else handleTab(1);
        }
    };

    // const clearInput = () => {
    //     handleChange(id, '');
    //     ref?.current?.focus?.();
    // };

    useEffect(() => {
        if (tab && tab === tabId) setTimeout(() => ref.current.focus(), 50);
    }, [ref, tab]);

    return (
        <GetLang>
            {({ lang, translations, rtl }) => (
                <div className='flex flex-col h-full w-full'>
                    <div className='relative'>
                        <input
                            // autoFocus
                            id={id}
                            name={`field_${id}`}
                            autoComplete='new-password2'
                            ref={ref}
                            value={root ? obj : obj.value}
                            type='text'
                            onFocus={() => handleTab?.(tabId)}
                            onChange={handleInput}
                            onKeyPress={handleKey}
                            disabled={disabled}
                            dir={rtl ? 'rtl' : 'ltr'}
                            placeholder={placeholder ? translations?.data?.[placeholder] ?? placeholder : ''}
                            className={classNames(
                                'relative h-vw-16 py-vw-3 w-full rounded-2xl block appearance-none placeholder-gray-400 placeholder-opacity-100 text-gray-700 leading-5 focus:outline-none bg-gray-100 font-semibold text-vw-lg border-2 border-transparent focus:border-bb-blue-500',
                                {
                                    'pl-vw-6 pr-vw-14': !rtl && !padding,
                                    'pr-vw-6 pl-vw-14': rtl && !padding
                                },
                                padding
                            )}
                        />

                        {loading && (
                            <div
                                // onClick={clearInput}
                                className={classNames('focus:outline-none absolute top-1/2 transform -translate-y-1/2 text-emerald-500', {
                                    'right-vw-4': !rtl && !clear,
                                    'left-vw-4': rtl && !clear,
                                    'right-vw-14': !rtl && clear,
                                    'left-vw-14': rtl && clear
                                })}
                            >
                                <Spinner />
                                {/* <Icons icon='Check' /> */}
                            </div>
                        )}
                        {owValidation
                            ? owValidationValue != null &&
                              !loading &&
                              obj?.value?.length >= minTestLength && (
                                  <div
                                      // onClick={clearInput}
                                      className={classNames('focus:outline-none absolute top-1/2 transform -translate-y-1/2 ', {
                                          'right-vw-4': !rtl,
                                          'left-vw-4': rtl,
                                          'text-emerald-500': owValidationValue,
                                          'text-red-500': !owValidationValue
                                      })}
                                  >
                                      <Icons icon={owValidationValue ? 'Check' : 'Slash'} />
                                  </div>
                              )
                            : obj.isValid &&
                              !loading && (
                                  <div
                                      // onClick={clearInput}
                                      className={classNames('focus:outline-none absolute top-1/2 transform -translate-y-1/2 text-emerald-500', {
                                          'right-vw-4': !rtl,
                                          'left-vw-4': rtl
                                      })}
                                  >
                                      <Icons icon='Check' />
                                  </div>
                              )}
                        {children}
                        {clear && (root ? obj : obj.value) && (
                            <button onClick={clear} className={classNames('absolute top-1/2 -translate-y-1/2', rtl ? 'left-vw-6' : 'right-vw-4')}>
                                <Icons icon={'Close'} containerClass='flex-none text-gray-500' />
                            </button>
                        )}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default Input;
