import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

const SectionFlat = ({ title, children }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div
                    className={classNames(
                        rtl ? 'items-end' : 'items-start',
                        'px-vw-6 w-full flex flex-col border-t-8 last:border-b-8 border-gray-100 py-vw-6 gap-4'
                    )}
                >
                    {title ? (
                        <h2
                            style={{
                                direction: rtl ? 'rtl' : 'ltr'
                            }}
                            className={classNames(
                                'text-bb-blue-550 text-vw-xl font-bold'
                            )}
                        >
                            {translations.data[title] ?? title}
                        </h2>
                    ) : null}

                    <div className='flex flex-col w-full'>{children}</div>
                </div>
            )}
        </GetLang>
    );
};

export default SectionFlat;
