import React, { useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';

import './MessagingChannelList.css';

import { PageLoading } from 'pages/index';
import NoResults from 'components/blocks/NoResults';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import classNames from 'classnames';
import OfflineMode from 'components/blocks/OfflineMode';
import useMyNavigation from 'hooks/useMyNavigation';
import { widthBiggerThan } from 'helpers/utils';

const MessagingChannelList = ({ children, error = false, loading, onCreateChannel, params }) => {
    const { client, setActiveChannel } = useChatContext();

    const { handleNavigation, location } = useMyNavigation();

    const online = useOnlineStatus();
    useEffect(() => {
        // if id passed in params, set active to that id

        const channelOnLoad = async (client) => {
            // console.log('members are', [client.user.id, params.otherUser]);
            const channel = await client.channel(
                'messaging',

                {
                    members: [client.user.id, params.otherUser]
                }
            );
            // console.log('channel is', channel);
            await channel.watch();
            // await channel.addMembers();
            await setActiveChannel(channel);
        };
        if (!loading && params?.otherUser) {
            // console.log('setting channel on load');
            channelOnLoad(client);
        }
        // if (!loading && !children?.props?.children?.length) {
        //     getDemoChannel(client);
        // }
    }, [loading, params]); // eslint-disable-line

    const ListHeaderWrapper = ({ children }) => {
        return (
            <div className='messaging__channel-list2 overflow-y-auto no-scrollbar h-body-w-header pt-vw-4a pb-vw-16a'>
                {/* <div className='messaging__channel-list__header2'>
                    <Avatar image={image} name={name} size={40} />
                    Conversations
                    <div className='messaging__channel-list__header__name'>
                        {name || id}
                    </div>
                </div> */}
                <div className='h-vw-2 md:h-2 bg-gray-200 md:hidden'></div>
                {children}
            </div>
        );
    };

    if (loading) {
        return (
            <PageLoading bg='bg-white' />
            // <ListHeaderWrapper>
            //     <div className='messaging__channel-list__message'>
            //         <SkeletonLoader />
            //     </div>
            // </ListHeaderWrapper>
        );
    }

    if (error) {
        return (
            <ListHeaderWrapper>
                <GetLang>
                    {({ rtl, translations }) => (
                        <div className='w-full px-vw-6 py-vw-6 md:px-6 md:py-6 h-body-w-header2 flex flex-col justify-center1'>
                            <OfflineMode margins='mb-vw-6 md:mb-6' />
                            <div className='mt-vw-6 md:mt-6'>
                                <NoResults title='e.nothingFound.chat.title' description='e.nothingFound.chat.description' image='noChannels' />

                                <div className={classNames('px-vw-10a flex items-center justify-center w-max mx-auto')}>
                                    <BtnPill paddingX='px-vw-8' type='primary' handleClick={null} disabled={!online} justify={'justify-between'} rtl={rtl}>
                                        <Icons icon={'Refresh'} size={widthBiggerThan() ? '28px' : '6.2vw'} />
                                        {translations?.data?.['e.nothingFound.chat.button']}
                                        {/* <span className='w-6' /> */}
                                    </BtnPill>
                                </div>
                            </div>
                        </div>
                    )}
                </GetLang>
            </ListHeaderWrapper>
        );
    }

    if (!children?.props?.children) {
        return (
            <ListHeaderWrapper>
                <GetLang>
                    {({ rtl, translations }) => (
                        <div className='w-full px-vw-6 py-vw-6 md:px-6 md:py-6 h-body-w-header2 flex flex-col justify-center1'>
                            <OfflineMode margins='mb-vw-6 md:mb-6' />
                            <div className='mt-vw-6 md:mt-6'>
                                <NoResults title='e.nothingFound.channel.title' description='e.nothingFound.channel.description' image='noChannels' />

                                <div className={classNames('px-vw-10a flex items-center justify-center w-max mx-auto')}>
                                    <BtnPill
                                        paddingX='px-vw-8 md:px-8'
                                        type='primary'
                                        handleClick={() => handleNavigation('app.searchCarSharing', null)}
                                        disabled={!online}
                                        justify={'justify-between'}
                                        rtl={rtl}
                                    >
                                        <Icons icon={'Find'} />
                                        {translations?.data?.['e.nothingFound.booking.button']}
                                        {/* <span className='w-6' /> */}
                                    </BtnPill>
                                </div>
                            </div>
                        </div>
                    )}
                </GetLang>
            </ListHeaderWrapper>
        );
    }

    const clean = (obj) => {
        // console.log('children are', obj?.props?.children ? 'Yes' : 'No Children');
        // console.log('error', error);
        return obj;
    };

    return <ListHeaderWrapper>{clean(children)}</ListHeaderWrapper>;
};

export default React.memo(MessagingChannelList);
