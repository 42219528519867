export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const SUBMIT_FAIL = 'SUBMIT_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const USER_PROFILE_MISSING = 'USER_PROFILE_MISSING';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL';
export const AUTH_LOADING = 'AUTH_LOADING';
export const GENERAL_LOADING = 'GENERAL_LOADING';
export const AUTH_SWITCHING = 'AUTH_SWITCHING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SWITCH_ERROR = 'SWITCH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SWITCH_SUCCESS = 'SWITCH_SUCCESS';
export const SWITCH_FAIL = 'SWITCH_FAIL';

export const LOGOUT = 'LOGOUT';
export const AUTH_CLEAR_ERRORS = 'AUTH_CLEAR_ERRORS';

export const AUTH_STEPS_CLEAR = 'AUTH_STEPS_CLEAR';
export const AUTH_STEPS_SET = 'AUTH_STEPS_SET';
export const AUTH_STEPS_START = 'AUTH_STEPS_START';
export const AUTH_STEPS_VERIFYUSER = 'AUTH_STEPS_VERIFYUSER';
export const AUTH_STEPS_NEWUSER = 'AUTH_STEPS_NEWUSER';
export const AUTH_STEPS_OTP = 'AUTH_STEPS_OTP';
export const AUTH_STEPS_FINISHED = 'AUTH_STEPS_FINISHED';

export const AUTH_OTPREQUEST_SET = 'AUTH_OTPREQUEST_SET';
export const AUTH_OTPREQUEST_CLEAR = 'AUTH_OTPREQUEST_CLEAR';
export const AUTH_SOCKETID_SET = 'AUTH_SOCKETID_SET';

export const TMP_LOGIN = 'TMP_LOGIN';
export const TMP_LOGOUT = 'TMP_LOGOUT';

/*----------*/
// Static Pages
/*----------*/
export const STATIC_SET_PAGE = 'STATIC_SET_PAGE';

/*----------*/
// Search
/*----------*/
export const SEARCH_SEATS_UPDATE = 'SEARCH_SEATS_UPDATE';
export const SEARCH_DATES_UPDATE = 'SEARCH_DATES_UPDATE';
export const SEARCH_LOCATION_UPDATE = 'SEARCH_LOCATION_UPDATE';
export const SEARCH_FILTERS_UPDATE = 'SEARCH_FILTERS_UPDATE';

export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_STOP_SWITCH = 'SEARCH_STOP_SWITCH';

/*----------*/
// Results
/*----------*/
export const RESULTS_FILTERS_UPDATE = 'RESULTS_FILTERS_UPDATE';
export const RESULTS_SORTING_UPDATE = 'RESULTS_SORTING_UPDATE';

/*----------*/
// Rides
/*----------*/
export const RIDES_TAB_TOGGLE = 'RIDES_TAB_TOGGLE';

/*----------*/
// Admin
/*----------*/
export const ADMIN_SELECTEDUSERS_UPDATE = 'ADMIN_SELECTEDUSERS_UPDATE';
export const ADMIN_SELECTEDRIDES_UPDATE = 'ADMIN_SELECTEDRIDES_UPDATE';

/*----------*/
// Publish
/*----------*/

export const PUBLISH_SELECT_DAY = 'PUBLISH_SELECT_DAY';
export const PUBLISH_RIDE_UPDATE = 'PUBLISH_RIDE_UPDATE';
export const PUBLISH_SEATS_UPDATE = 'PUBLISH_SEATS_UPDATE';
export const PUBLISH_SEATSREMAINING_UPDATE = 'PUBLISH_SEATSREMAINING_UPDATE';
export const PUBLISH_DATERANGE_UPDATE = 'PUBLISH_DATERANGE_UPDATE';
export const PUBLISH_DATE_UPDATE = 'PUBLISH_DATE_UPDATE';
export const PUBLISH_TIME_UPDATE = 'PUBLISH_TIME_UPDATE';
export const PUBLISH_DESCRIPTION_UPDATE = 'PUBLISH_DESCRIPTION_UPDATE';
export const PUBLISH_PREFERENCES_UPDATE = 'PUBLISH_PREFERENCES_UPDATE';
export const PUBLISH_LOCATION_UPDATE = 'PUBLISH_LOCATION_UPDATE';
export const PUBLISH_LADIESONLY_UPDATE = 'PUBLISH_LADIESONLY_UPDATE';
export const PUBLISH_RECURRING_UPDATE = 'PUBLISH_RECURRING_UPDATE';
export const PUBLISH_STOP_SWITCH = 'PUBLISH_STOP_SWITCH';

/*----------*/
// Google Map
/*----------*/

export const GOOGLE_MAP_INITIALISED = 'GOOGLE_MAP_INITIALISED';
export const GOOGLE_MAP_INITIALISED_FAILED = 'GOOGLE_MAP_INITIALISED_FAILED';

/*----------*/
// Globalstate
/*----------*/

export const LOAD_APP_SETTINGS = 'LOAD_APP_SETTINGS';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const TOGGLE_LANG = 'TOGGLE_LANG';
export const TOGGLE_TIME = 'TOGGLE_TIME';
export const TOGGLE_REDUCEDMOTION = 'TOGGLE_REDUCEDMOTION';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const TOGGLE_TRANSITION = 'TOGGLE_TRANSITION';
export const TOGGLE_TAB = 'TOGGLE_TAB';
export const SET_SCROLL = 'SET_SCROLL';
export const NEW_NOTIFICATIONS_SET = 'NEW_NOTIFICATIONS_SET';

/*----------*/
// Onboarding
/*----------*/
export const ONBOARDING_TOOLTIP_UPDATE = 'ONBOARDING_TOOLTIP_UPDATE';
export const ONBOARDING_FIRSTTIME_UPDATE = 'ONBOARDING_FIRSTTIME_UPDATE';
export const ONBOARDING_UPDATE = 'ONBOARDING_UPDATE';

/*----------*/
// startup
/*----------*/
export const GET_STARTUP_DATA = 'GET_STARTUP_DATA';
export const GET_IP_DATA = 'GET_IP_DATA';

/*----------*/
// Profile
/*----------*/
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';

/*----------*/
// Chat
/*----------*/

export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_LOAD_SUCCESS = 'CHAT_LOAD_SUCCESS';
export const CHAT_LOAD_FAIL = 'CHAT_LOAD_FAIL';
export const CHAT_CLEAR_ERRORS = 'CHAT_CLEAR_ERRORS';
export const CHAT_LOGOUT = 'CHAT_LOGOUT';
export const CHAT_SET_HISTORY = 'CHAT_SET_HISTORY';
export const CHAT_SET_CHANNEL_ACTIVE = 'CHAT_SET_CHANNEL_ACTIVE';
