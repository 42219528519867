import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageFooter, LayoutPageBody } from 'components/layouts';
import Icons from 'components/icons/index';
import AvatarFull from 'components/blocks/AvatarFull';
import Itinerary from 'components/blocks/Itinerary';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations } from 'constants/animations';
import { motion } from 'framer-motion';
import StatusBooked from 'components/blocks/StatusBooked';
import ModalBottom from 'components/modals/ModalBottom';
import { useEffect, useState } from 'react';
import { updateBooking, updateReview } from 'fetch/apiRequests';
import BtnPill from 'components/buttons/BtnPill';
import BtnIcon from 'components/buttons/BtnIcon';
import classNames from 'classnames';
import useMyNavigation from 'hooks/useMyNavigation';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import OfflineMode from 'components/blocks/OfflineMode';
import CardUser from 'components/cards/CardUser';
import CardSection from 'components/cards/CardSection';
import CardUserBis from 'components/cards/CardUserBis';
import RatingSlider from 'components/blocks/RatingSlider';
import { getBodyHeight, getRecurringDay } from 'helpers/helperFunctions';
import { analytics } from 'helpers/segment';

const PageRideBookingArchived = ({ auth: { isAuthenticated } }) => {
    const pageData = {
        page: 'app.rides.archived.booking',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/bookings/view',
        backBtn: true,
        zIndex: 43,
        checkIdParam: true
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);
    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    const { handleNavigation, location } = useMyNavigation();

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const [userRating, setUserRating] = useState(null);

    useEffect(() => {
        analytics.page('Booked Ride Archive', 'App', isAuthenticated);
    }, []);

    const handleAction = async (bookingId, reviewId, mutateData, status) => {
        setIsModalAction(true);
        setIsModal(false);

        if (status === 'RATE') {
            if (userRating)
                await updateReview({
                    reviewId,
                    rating: userRating,
                    bookingId
                });
        } else await updateBooking({ bookingId, status });
        await mutateData();
        setIsModalAction(false);
        setUserRating(null);

        if (status === 'DELETE') handleNavigation('app.rides.booked.archived', null);
    };

    const handleContact = (id) => {
        handleNavigation('app.messages', { otherUser: id });
    };

    const handleUserProfile = (id) => {
        handleNavigation('app.user', { id });
    };

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidParams, translations, data, isValidating, mutateData, handleNavigation, online }) => {
                // console.log('data', data);
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            {(data?.status?.startsWith('CANCELED_BY_') || data?.status?.startsWith('DECLINED_BY_')) && !isModalAction && (
                                <div
                                    className={classNames('flex items-center', {
                                        'flex-row-reverse': rtl
                                    })}
                                >
                                    {!data?.archive && <BtnIcon icon='Archive' iconSize={'5.8vw'} noBg handleClick={setMode} params={['ARCHIVE_BOOKING']} />}
                                    <BtnIcon disabled={!online} icon='Trash' iconSize={'5.8vw'} noBg handleClick={setMode} params={['DELETE_BOOKING']} />
                                </div>
                            )}
                        </LayoutPageHeader>
                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <LayoutPageBody
                                minHeight=''
                                // minHeight={getBodyHeight(
                                //     data?.status,
                                //     'ride.booking'
                                // )}
                            >
                                <div className='text-vw-base'>
                                    {data && !isModalAction ? (
                                        <motion.div
                                            key='rideBookingArchived'
                                            initial={'in'}
                                            animate={'in'}
                                            variants={animations.elementSlides}
                                            className={`list-none w-full bg-white rounded-3xl relative`}
                                        >
                                            <StatusBooked status={data?.status} square archive={true} ladiesOnly={data?.ladiesOnly} />
                                            <OfflineMode margins='mt-6' />

                                            <div className={`mt-vw-6 px-vw-6 md:mt-6 md:px-6 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                                                <Pill icon={data?.recurring ? 'Repeat1' : 'Calendar2'} colorPreset={'dark-gray'}>
                                                    <FormattedDateTime
                                                        dateTime={data?.dateTime}
                                                        date
                                                        dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null}
                                                    />
                                                </Pill>
                                                <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                                                    <FormattedDateTime dateTime={data?.dateTime} time />
                                                </Pill>
                                            </div>

                                            <Itinerary
                                                data={data}
                                                archive={true}

                                                // showTime
                                            />

                                            <CardSection
                                                paddingY='py-vw-4'
                                                border='border-t border-b'
                                                // swipeMenuOptions={getSwipeMenuOptions(
                                                //     data?.driverId
                                                // )}
                                                handleClick={() => handleUserProfile(data?.driverId.id)}
                                                arrow
                                            >
                                                <CardUserBis archive={true} user={data?.driverId} />
                                            </CardSection>
                                        </motion.div>
                                    ) : (
                                        <CardSkeleton rtl={rtl} type='booking' />
                                    )}
                                </div>
                            </LayoutPageBody>

                            {data && !isModalAction && data?.archive && data?.review && (
                                <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6`}>
                                    <RatingSlider
                                        name={data?.driverId?.profile?.displayName ?? data?.driverId?.profile?.firstName}
                                        ask={!data?.review?.rating}
                                        rating={data?.review?.rating}
                                        setUserRating={setUserRating}
                                        userRating={userRating}
                                    />

                                    {!data?.review?.rating && (
                                        <BtnPill
                                            type='primary'
                                            disabled={!userRating}
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?._id, data?.review?._id, mutateData, 'RATE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.bug.submit']}
                                                <Icons icon={'Plane1'} size={'5.8vw'} flip={rtl} />
                                            </>
                                        </BtnPill>
                                    )}
                                </div>
                            )}
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center '>
                                <OfflineMode margins='' />
                                {mode === 'ARCHIVE_BOOKING' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.booking.status.archive.headline']}
                                        </div>
                                        <BtnPill
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, null, mutateData, 'ARCHIVE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.booking.status.archive']}
                                                <Icons icon={'Archive'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DELETE_BOOKING' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.booking.status.delete.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='cancel'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, null, mutateData, 'DELETE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.booking.status.delete']}
                                                <Icons icon={'Trash'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, null)(PageRideBookingArchived);
