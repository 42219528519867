import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';
import { BtnRequestCancel, BtnContactDriver } from 'components/buttons';
import Icons from 'components/icons/index';
import { bindActionCreators } from 'redux';
import Itinerary from 'components/blocks/Itinerary';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations } from 'constants/animations';
import { motion } from 'framer-motion';
import StatusBooked from 'components/blocks/StatusBooked';
import ModalBottom from 'components/modals/ModalBottom';
import { useEffect, useState } from 'react';

import { updateBooking, updateReview } from 'fetch/apiRequests';
import BtnPill from 'components/buttons/BtnPill';
import BtnIcon from 'components/buttons/BtnIcon';
import classNames from 'classnames';
import useMyNavigation from 'hooks/useMyNavigation';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getBodyHeight, getPresetByStatus, getRecurringDay } from 'helpers/helperFunctions';
import OfflineMode from 'components/blocks/OfflineMode';

import CardSection from 'components/cards/CardSection';
import CardUserBis from 'components/cards/CardUserBis';
import BtnMiddleAction from 'components/buttons/BtnMiddleAction';
import RatingSlider from 'components/blocks/RatingSlider';
import MessageBox from 'components/blocks/MessageBox';
import { setStaticPage } from 'store/actions/staticPages';
import IconRound from 'components/blocks/IconRound';
import { isDateTimeInPast } from 'helpers/dates';
import { analytics } from 'helpers/segment';

const PageRideBooking = ({ setStaticPage, onboarding: { tooltips }, auth: { isAuthenticated } }) => {
    const pageData = {
        page: 'app.rides.booking',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/bookings/view',
        backBtn: true,
        zIndex: 43,
        checkIdParam: true
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);
    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    const [userRating, setUserRating] = useState(null);

    const { handleNavigation, location } = useMyNavigation();

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const handleAction = async (bookingId, reviewId, mutateData, status) => {
        setIsModalAction(true);
        setIsModal(false);

        if (status === 'RATE') {
            if (userRating)
                await updateReview({
                    reviewId,
                    rating: userRating,
                    bookingId
                });
        } else await updateBooking({ bookingId, status });
        await mutateData();
        setIsModalAction(false);
        setUserRating(null);

        if (status === 'DELETE') handleNavigation('app.rides.booked', null);
    };

    const handleContact = (id) => {
        analytics.track('Chat Passenger => Driver (Booking)', null, true);
        handleNavigation('app.messages', { otherUser: id });
    };

    const handleUserProfile = (id) => {
        handleNavigation('app.user', { id });
    };

    const getSwipeMenuOptions = (userId) => [
        {
            icon: 'Messages1',
            handleClick: handleContact,
            params: [userId]
        },
        {
            icon: 'User',
            handleClick: handleUserProfile,
            params: [userId]
        }
    ];

    const shouldArchive = (dateTime, archive, recurring) => {
        return recurring ? archive : isDateTimeInPast(dateTime) ? true : archive;
    };

    useEffect(() => {
        analytics.page('Booked Ride', 'App', isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidParams, translations, data, isValidating, mutateData, handleNavigation, online }) => {
                // console.log('data is', data);
                // console.log('isValidating', isValidating);
                // console.log('location', location);
                const archive = shouldArchive(data?.dateTime, data?.archive, data?.recurring);
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            {(data?.status?.startsWith('CANCELED_BY_') || data?.status?.startsWith('DECLINED_BY_')) && !isModalAction && (
                                <div
                                    className={classNames('flex items-center', {
                                        'flex-row-reverse': rtl
                                    })}
                                >
                                    {!archive && <BtnIcon icon='Archive' iconSize={'5.8vw'} noBg handleClick={setMode} params={['ARCHIVE_BOOKING']} />}
                                    {data.isActive && <BtnIcon icon='Trash' iconSize={'5.8vw'} noBg handleClick={setMode} params={['DELETE_BOOKING']} />}
                                </div>
                            )}
                        </LayoutPageHeader>
                        {/* <div className='h-body-w-header md:h-body-w-header-desktop overflow-y-auto text-vw-base md:text-base flex flex-col justify-between md:max-w-4xl md:mx-auto'> */}
                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            {/* <LayoutPageBody minHeight={getBodyHeight(data?.status, 'ride.booking')}> */}
                            <LayoutPageBody minHeight=''>
                                <div className=''>
                                    {data && !isModalAction ? (
                                        <motion.div
                                            key='rideBooking'
                                            initial={'in'}
                                            animate={'in'}
                                            variants={animations.elementSlides}
                                            className={`list-none w-full bg-white rounded-3xl relative`}
                                        >
                                            <StatusBooked status={data?.status} square archive={archive} ladiesOnly={data?.ladiesOnly} />
                                            <OfflineMode margins='mt-6' />
                                            <div className={`mt-vw-6 px-vw-6 md:mt-6 md:px-6 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                                                <Pill
                                                    icon={data?.recurring ? 'Repeat1' : 'Calendar2'}
                                                    colorPreset={getPresetByStatus(data?.status, archive, data?.recurring)}
                                                >
                                                    <FormattedDateTime
                                                        dateTime={data?.dateTime}
                                                        date
                                                        dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null}
                                                    />
                                                </Pill>
                                                <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                                                    <FormattedDateTime dateTime={data?.dateTime} time />
                                                </Pill>
                                            </div>

                                            <Itinerary
                                                data={data}
                                                archive={archive}
                                                // showTime
                                            />

                                            <CardSection
                                                paddingY='py-vw-4 md:py-4'
                                                border='border-t border-b'
                                                // swipeMenuOptions={getSwipeMenuOptions(
                                                //     data?.driverId
                                                // )}
                                                handleClick={() => handleUserProfile(data?.driverId.id)}
                                                arrow
                                            >
                                                <CardUserBis archive={archive} user={data?.driverId} showReviews={false} />
                                            </CardSection>

                                            {tooltips?.viewPricing &&
                                                !(data?.status?.startsWith('CANCELED_BY_') || data?.status?.startsWith('DECLINED_BY_')) &&
                                                !archive && (
                                                    // <MessageBox
                                                    //     noTitle
                                                    //     fullButton
                                                    //     id='priceTooltip'
                                                    //     margins={classNames('mt-vw-6')}
                                                    //     icon='Info'
                                                    //     action={() => setStaticPage('pricing')}
                                                    // >
                                                    //     {translations?.data?.['e.tooltip.compensation.short']}
                                                    // </MessageBox>
                                                    <MessageBox
                                                        label=''
                                                        noTitle
                                                        id='viewPricing'
                                                        margins={classNames('mt-vw-6 mb-vw-6')}
                                                        bodyAction={() => setStaticPage('pricing')}
                                                    >
                                                        <IconRound icon='Coins' preset='yellow' presetSize='small' />
                                                        {translations?.data?.['e.tooltip.compensation.short']}
                                                    </MessageBox>
                                                )}
                                        </motion.div>
                                    ) : (
                                        <CardSkeleton rtl={rtl} type='booking' />
                                    )}
                                </div>
                            </LayoutPageBody>

                            {data && !isModalAction && archive && data?.review && (
                                <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6`}>
                                    <RatingSlider
                                        name={data?.driverId?.profile?.displayName ?? data?.driverId?.profile?.firstName}
                                        ask={!data?.review?.rating}
                                        rating={data?.review?.rating}
                                        setUserRating={setUserRating}
                                        userRating={userRating}
                                    />

                                    {!data?.review?.rating && (
                                        <BtnPill
                                            type='primary'
                                            disabled={!userRating}
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, data?.review?._id, mutateData, 'RATE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.bug.submit']}
                                                <Icons icon={'Plane1'} size={'5.8vw'} flip={rtl} />
                                            </>
                                        </BtnPill>
                                    )}
                                </div>
                            )}

                            {data && !isModalAction && !archive && (
                                <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 md:py-4 md:px-6`}>
                                    <>
                                        <BtnContactDriver
                                            disabled={!online || data?.driverId?.blockUser}
                                            params={[data?.driverId?.id]}
                                            handleClick={handleContact}
                                        />

                                        {data?.status?.startsWith('APPROVED_BY_') && (
                                            <BtnMiddleAction
                                                type='cancel-middle'
                                                icon='CloseCircle'
                                                disabled={!online}
                                                label='e.cancelBooking'
                                                handleClick={setMode}
                                                params={['CANCELED_BY_PASSENGER']}
                                            />
                                            // <BtnRequestCancel disabled={!online} handleClick={() => setMode('CANCELED_BY_PASSENGER')}>
                                            //     {translations?.data?.['e.cancelBooking']}
                                            // </BtnRequestCancel>
                                        )}

                                        {data?.status?.startsWith('PENDING') && (
                                            <BtnMiddleAction
                                                type='cancel-middle'
                                                icon='CloseCircle'
                                                disabled={!online}
                                                label='e.cancelRequest'
                                                handleClick={setMode}
                                                params={['DECLINED_BY_PASSENGER']}
                                            />
                                            // <BtnRequestCancel middleAction disabled={!online} handleClick={() => setMode('DECLINED_BY_PASSENGER')} />
                                        )}
                                    </>
                                </div>
                            )}
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center'>
                                <OfflineMode margins='' />
                                {mode?.startsWith('CANCELED_BY') && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.cancelBooking.headline']}
                                        </div>
                                        <BtnRequestCancel handleClick={() => handleAction(data?.id, null, mutateData, 'CANCELED_BY_PASSENGER')}>
                                            {translations?.data?.['e.cancelBooking']}
                                        </BtnRequestCancel>

                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode?.startsWith('DECLINED_BY') && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.cancelRequest.headline']}
                                        </div>
                                        <BtnRequestCancel handleClick={() => handleAction(data?.id, null, mutateData, 'DECLINED_BY_PASSENGER')}>
                                            {translations?.data?.['e.cancelRequest']}
                                        </BtnRequestCancel>

                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'ARCHIVE_BOOKING' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.booking.status.archive.headline']}
                                        </div>
                                        <BtnPill
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, mutateData, 'ARCHIVE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.booking.status.archive']}
                                                <Icons icon={'Archive'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DELETE_BOOKING' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.booking.status.delete.headline']}
                                        </div>
                                        <BtnPill
                                            type='cancel'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, null, mutateData, 'DELETE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.booking.status.delete']}
                                                <Icons icon={'Trash'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    onboarding: state.onboarding,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageRideBooking);
