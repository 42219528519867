export const isSSR = !(typeof window !== 'undefined' && window.document?.createElement);

export const getRefElement = (element) => {
    if (element && 'current' in element) {
        return element.current;
    }

    return element;
};

export const widthBiggerThan = (size = 400) => {
    return window?.innerWidth >= size;
};

export const heightBiggerThan = (size = 800) => {
    return window?.innerHeight >= size;
};

export const userLocale = () => {
    const locale = window?.navigator?.languages?.[0];
    if (locale?.startsWith('fr')) return { lang: 'fr', rtl: false };
    if (locale?.startsWith('ar')) return { lang: 'ar', rtl: true };
    return { lang: 'en', rtl: false };
};
