import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import Rating from 'components/blocks/Rating';
import StatusBooked from 'components/blocks/StatusBooked';
import UserDisplay from 'components/blocks/UserDisplay';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';

const CardUserBis = ({ user, booking, archive, showReviews = true }) => {
    // const profile = user?.profile ?? booking?.passengerId?.profile;

    const profile = { ...(user?.profile ?? booking?.passengerId?.profile), user: { blockUser: user?.blockUser ?? booking?.passengerId?.blockUser } };

    return (
        <GetLang>
            {({ rtl }) => (
                <div className={classNames('flex justify-between w-full', rtl && 'flex-row-reverse')}>
                    <div className={classNames('flex items-center gap-6', rtl ? 'flex-row-reverse' : 'flex-row')}>
                        <Avatar profile={profile} size={'h-vw-12 w-vw-12 md:h-16 md:w-16'} preset={archive ? 'archive' : 'default'} />
                        <div>
                            {/* <div className={classNames(' font-bold', rtl ? 'text-right' : 'text-left', archive ? 'text-gray-700' : 'text-bb-blue-500')}>
                                {profile?.displayName || profile?.firstName}
                            </div> */}
                            <UserDisplay profile={profile} banned />
                            {!profile?.user?.blockUser && <Rating rating={profile?.rating} reviews={profile?.totalReviews || null} showReviews={showReviews} />}
                        </div>
                    </div>
                    <div className={classNames('')}>
                        {booking && <StatusBooked threshold={1} archive={archive} status={booking?.status} noText seats={booking?.seats} />}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default CardUserBis;
