import { DateTime } from 'luxon';
import { translations } from 'constants/translations';

// export const getRelativeDate = (dateString, lang) => {
//     const diff = getDaysDifference(new Date(dateString), new Date());
//     if (diff <= 1 && diff >= -1) {
//         return DateTime.fromISO(`${dateString}T00:00:00.001`)
//             .setLocale(translations[lang].formatting.locale)
//             .toRelativeCalendar();
//     }
//     if (diff > 1 && diff < 7) {
//         return DateTime.fromISO(`${dateString}T00:00:00.001`)
//             .setLocale(translations[lang].formatting.locale)
//             .toFormat('cccc');
//     }
//     return DateTime.fromISO(`${dateString}T00:00:00.001`)
//         .setLocale(translations[lang].formatting.locale)
//         .toFormat(translations[lang].formatting.dateResults);
// };

export const getRelativeDate = (dateString, lang) => {
    if (!dateString) return null;

    const diff = getDaysDifference(new Date(dateString), new Date());
    if (diff <= 0 && diff >= -1) {
        return DateTime.fromISO(dateString).setLocale(translations?.[lang]?.formatting?.locale).toRelativeCalendar({ unit: 'days' });
    }
    if (diff > 0 && diff < 7) {
        return DateTime.fromISO(dateString).setLocale(translations?.[lang]?.formatting?.locale).toFormat('cccc');
    }
    return DateTime.fromISO(dateString).setLocale(translations?.[lang]?.formatting?.locale).toFormat(translations?.[lang]?.formatting?.dateResults);
};

export const getDaysDifference = (futureDate, pastDate) => {
    // dates are objects
    // rebuild raw dates without any time from these dates
    const dateF = new Date(DateTime.fromJSDate(futureDate).toFormat('yyyy-LL-dd'));
    const dateP = new Date(DateTime.fromJSDate(pastDate).toFormat('yyyy-LL-dd'));

    const diff = Math.floor((dateF.getTime() - dateP.getTime()) / (1000 * 3600 * 24));
    return diff;
};

export const isDateInPast = (dateString) => {
    const todayString = getDateString().date;
    const today = new Date(todayString);
    const testDate = new Date(getDateString(dateString).date);
    // console.log('today', today);
    // console.log('testDate', testDate);
    if (testDate < today) return todayString;
    return false;
};

export const isDateTimeInPast = (dateTimeString) => {
    const now = new Date();
    const testDate = new Date(dateTimeString);
    if (testDate < now) return true;
    return false;
};

export const getDayDifference = (pastDate, firstDate) => {
    const startDate = firstDate ? new Date(firstDate) : new Date();
    const parsedStartDate = new Date(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`);
    const tmpDate = new Date(pastDate);
    const parsedPastDate = new Date(`${tmpDate.getFullYear()}-${tmpDate.getMonth() + 1}-${tmpDate.getDate()}`);

    return (parsedStartDate.getTime() - parsedPastDate.getTime()) / 86400000;
};

export const getDateString = (dateString) => {
    let newDate = new Date();
    if (dateString) newDate = new Date(dateString);
    const newMonth = newDate.getMonth() + 1;
    const newYear = newDate.getFullYear();
    const newDay = newDate.getDate();
    const date = `${newYear}-${newMonth > 9 ? newMonth : `0${newMonth}`}-${newDay > 9 ? newDay : `0${newDay}`}`;

    return { date, day: newDay, month: newMonth, year: newYear };
};

export function getDaysInMonthUTC(month, year) {
    let mon = month - 1;
    let date = new Date(Date.UTC(year, mon, 1));
    let days = [];
    while (date.getUTCMonth() === mon) {
        const newDate = new Date(date);
        const newMonth = newDate.getMonth() + 1;
        const newYear = newDate.getFullYear();
        const newDay = newDate.getDate();
        days.push({
            day: newDay,
            date: newDate,
            string: `${newYear}-${newMonth > 9 ? newMonth : `0${newMonth}`}-${newDay > 9 ? newDay : `0${newDay}`}`
        });
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}

function getDay(date) {
    let day = date.getDay();
    if (day == 0) day = 7;
    return day - 1;
}

export const buildCalendarDays = (month, year) => {
    // get days in selected month
    const days = getDaysInMonthUTC(month, year);

    // get Day of first day of that month

    const firsDayOfMonth = getDay(days[0].date);

    for (let i = 0; i < firsDayOfMonth; i++) {
        days.unshift(null);
    }
    return { days, month, year, monthPadded: month > 9 ? month : `0${month}` };
};

export function getMonthYear() {
    const currentDate = new Date();
    return {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear()
    };
}

export const buildFullCalendar = () => {
    const today = new Date();
    const cMonth = today.getMonth() + 1;
    const cYear = today.getFullYear();
    const monthsArr = Array(12)
        .fill()
        .map((month, index) => {
            const newMonth = (cMonth + index) % 12 || 12;
            const newYear = Number(cYear) + Math.floor((cMonth + index - 1) / 12);
            return { month: newMonth, year: newYear };
        });
    // console.log('months arr', monthsArr);

    const allDays = monthsArr.map((monthItem) => {
        return buildCalendarDays(monthItem.month, monthItem.year);
    });
    return allDays;
    // calendar should get today's date
    // and get the month
    // and build month for next full year (loop)
    // for each month you need
};
