import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootReducer from './reducers';
import { loadState, saveState } from './localStorage';
import { throttle } from 'lodash';

const persistedState = {
    // ...loadState(),
    // globalState: {lang: "ar"}
};

const middleware = process.env.NODE_ENV === 'development' ? [thunk, createLogger(), promise] : [thunk, promise];

const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(...middleware)));

store.subscribe(
    throttle(() => {
        if (window?.navigator?.onLine)
            saveState({
                auth: store.getState().auth,
                globalState: store.getState().globalState,
                search: store.getState().search,
                rides: store.getState().rides,
                publish: store.getState().publish,
                results: store.getState().results,
                onboarding: store.getState().onboarding,
                static: store.getState().staticPages
            });
    }, 30000)
);

export default store;
