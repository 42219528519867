import axios from 'axios';
import { toast } from 'react-toastify';
import { ErrorSuccessLang } from 'helpers/errors';

import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    USER_PROFILE_MISSING,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    AUTH_ERROR,
    AUTH_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    AUTH_CLEAR_ERRORS,
    AUTH_STEPS_SET,
    AUTH_STEPS_CLEAR,
    AUTH_OTPREQUEST_SET,
    AUTH_SOCKETID_SET
} from './types';
import setAuthToken from 'helpers/setAuthToken';
import { loadState, loadNamedState, saveNamedState } from 'store/localStorage';
import { loadChatUser } from './chat';
import { analytics } from 'helpers/segment';

// Load user

export const loginSetStep = (step) => async (dispatch) => {
    dispatch({
        type: AUTH_STEPS_SET,
        payload: step
    });
};

export const loginClearSteps = () => async (dispatch) => {
    dispatch({
        type: AUTH_STEPS_CLEAR
    });
};

export const setSocketId = (socketId) => async (dispatch) => {
    dispatch({
        type: AUTH_SOCKETID_SET,
        payload: socketId
    });
};

// Verify User
export const verifyUser =
    (phoneNumber, lang = 'en', force = false) =>
    async (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({ phoneNumber, lang, force });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE}/otp`, body, config);
           
            await dispatch({
                type: AUTH_OTPREQUEST_SET,
                payload: res.data
            });
            analytics.track('OTP Request Sent', null, true);
            if (res.data?.requestId) {
                dispatch(loginSetStep('verifyOtp'));
            } else if (res.data?.type === 'register') {
                dispatch(loginSetStep('newUser'));
            } else dispatch(loginSetStep('startError'));
        } catch (error) {
            let payload;

            if (error.response) {
                payload = [error.response?.data?.message?.[0]?.messages?.[0]?.id];
            } else if (error.request) {
                payload = ['Auth.error.network'];
            } else {
                payload = ['Auth.error.generic'];
            }

            dispatch({
                type: LOGIN_FAIL,
                payload
            });

            if (payload[0] !== 'Auth.form.error.maxAttempts' &&  payload[0] !== 'Auth.form.error.vonage' )
                dispatch(loginSetStep('startError'));
        }
    };

export const loadUser = (loadChat, firstLoad, container) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);

        try {
            let res;
            let offlineState;

            if (!window?.navigator?.onLine) {
                // console.log('trying to load');
                offlineState = loadState();
                // console.log('offline state', offlineState);
                if (offlineState.auth.token && offlineState.auth.user) {
                    res = {};
                    res.data = offlineState.auth.user;
                }
            } else {
                // console.log('nav is online');
                res = await axios.get(`${process.env.REACT_APP_API_BASE}/users/me`);
            }

            if (res.data?.profile?.firstName?.length && res.data?.profile?.lastName?.length) {
                const { confirmed, blockUser, _id, id, email, username, profile, phoneNumber, role } = res.data;
                const user = {
                    confirmed,
                    blockUser,
                    _id,
                    id,
                    email,
                    username,
                    profile,
                    phoneNumber,
                    admin: role === '63066106486c0f0f89d54d68'
                };
                dispatch({
                    type: USER_LOADED,
                    payload: user
                });
                // console.log(user);
                if (firstLoad) analytics.identify({}, user, true);
                if (container === 'app') analytics.track('App Loaded', null, true);
                if (loadChat) dispatch(loadChatUser(user));
            } else
                dispatch({
                    type: USER_PROFILE_MISSING,
                    payload: res.data
                });
        } catch (err) {
            // console.log('Some error happened while loading user');
            //logout completely

            dispatch({
                type: AUTH_ERROR,
                payload: null
            });
            dispatch({
                type: LOGOUT,
                payload: null
            });
            window.location.reload();
        }
    } else {
        // console.log('token not found in storage');
        dispatch({
            type: AUTH_ERROR,
            payload: null
        });
    }
};

// clear errors
export const clearLoginErrors = () => async (dispatch) => {
    dispatch({
        type: AUTH_CLEAR_ERRORS
    });
};

// Register User
export const register =
    ({ username, email, password, profile }) =>
    async (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({ username, email, password, profile });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE}/auth/local/register`, body, config);

            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data
            });
            analytics.track('User Registered', null, true);

            dispatch(loadUser(true, true));
        } catch (err) {
            if (err.response) {
                const errorObj = err.response.data;
                // console.log('ERROR STATUS CODE:', errorObj);
                if (errorObj.statusCode == '403') {
                    toast.error(<ErrorSuccessLang errorId='FORBIDDEN' />, {
                        hideProgressBar: true,
                        autoClose: 2500
                    });
                }
                const errors = errorObj.data && errorObj.data[0]?.messages;
                if (errors) {
                    errors.forEach((error) =>
                        toast.error(<ErrorSuccessLang errorId={error.id} />, {
                            hideProgressBar: true,
                            autoClose: 2500
                        })
                    );
                }
                // console.log('err', err.response.data.msg);
                dispatch({
                    type: REGISTER_FAIL,
                    payload: errors ? errors.map((error) => error.message) : [errorObj.message]
                });
            } else if (err.request) {
                // console.log('error no resp from server', err.request);
                toast.error(<ErrorSuccessLang errorId='NETWORKERROR' />);
                dispatch({
                    type: REGISTER_FAIL,
                    payload: ['networkError']
                });
            } else {
                // console.log('Error', err.message);
                toast.error(<ErrorSuccessLang errorId='DEFAULTERROR' />);
                dispatch({
                    type: REGISTER_FAIL,
                    payload: ['somethingWrong']
                });
            }
            return err;
        }
    };

// Login User
export const loginByNumber = (jsBody) => async (dispatch) => {
    dispatch({
        type: AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify(jsBody);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/otp/verify`, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });

        analytics.track('User LoggedIn', null, true);

        dispatch(clearLoginErrors());
        dispatch(loadUser(true, true));
    } catch (error) {
        let payload;

        if (error.response) {
            payload = [error.response?.data?.message?.[0]?.messages?.[0]?.id];
        } else if (error.request) {
            payload = ['Auth.error.network'];
        } else {
            payload = ['Auth.error.generic'];
        }

        dispatch({
            type: LOGIN_FAIL,
            payload
        });
    }
};

// Login User
export const login =
    ({ identifier, password }) =>
    async (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({ identifier, password });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE}/auth/local`, body, config);
            // toast.success(<ErrorSuccessLang errorId='LOGIN_SUCCESS' />, {
            //     hideProgressBar: true,
            //     autoClose: 1500,
            // });
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });

            dispatch(loadUser(true, true));
        } catch (err) {
            // console.log('login errors', err);
            if (err.response) {
                const errorObj = err.response.data;
                // console.log('ERROR STATUS CODE:', errorObj);
                if (errorObj.statusCode == '403') {
                    toast.error(<ErrorSuccessLang errorId='FORBIDDEN' />, {
                        hideProgressBar: true,
                        autoClose: 2500
                    });
                }
                const errors = errorObj.data && errorObj.data[0]?.messages;
                if (errors) {
                    errors.forEach((error) => toast.error(<ErrorSuccessLang errorId={error.id} />));
                }
                // console.log('err', err.response.data.msg);
                dispatch({
                    type: LOGIN_FAIL,
                    payload: errors ? errors.map((error) => error.message) : [errorObj.message]
                });
            } else if (err.request) {
                // console.log('error no resp from server', err.request);
                toast.error(<ErrorSuccessLang errorId='NETWORKERROR' />);
                dispatch({
                    type: LOGIN_FAIL,
                    payload: ['networkError']
                });
            } else {
                // console.log('Error', err.message);
                toast.error(<ErrorSuccessLang errorId='DEFAULTERROR' />);
                dispatch({
                    type: LOGIN_FAIL,
                    payload: ['somethingWrong']
                });
            }
        }
    };

// Logout
export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
        payload: null
    });
};

export const createProfile =
    ({ firstName, lastName, mobile, user }) =>
    async (dispatch) => {
        dispatch({
            type: AUTH_LOADING
        });

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const settings = {
            tour: true,
            tooltips: true
        };
        const body = JSON.stringify({
            firstName,
            lastName,
            mobile,
            user,
            settings
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_BASE}/profiles`, body, config);

            // toast.success(<ErrorSuccessLang errorId='PROFILE_SUCCESS' />, {
            //     hideProgressBar: true,
            //     autoClose: 1500,
            // });
            dispatch({
                type: USER_PROFILE_SUCCESS,
                payload: res.data
            });

            dispatch(loadUser(true, true));
        } catch (err) {
            if (err.response) {
                const errorObj = err.response.data;
                // console.log('ERROR STATUS CODE:', errorObj);
                if (errorObj.statusCode == '403') {
                    toast.error(<ErrorSuccessLang errorId='FORBIDDEN' />, {
                        hideProgressBar: true,
                        autoClose: 2500
                    });
                }
                const errors = errorObj.data && errorObj.data[0]?.messages;
                if (errors) {
                    errors.forEach((error) => toast.error(<ErrorSuccessLang errorId={error.id} />));
                }

                dispatch({
                    type: USER_PROFILE_FAIL,
                    payload: errors ? errors.map((error) => error.message) : [errorObj.message]
                });
            } else if (err.request) {
                // console.log('error no resp from server', err.request);
                toast.error(<ErrorSuccessLang errorId='NETWORKERROR' />);
                dispatch({
                    type: USER_PROFILE_FAIL,
                    payload: ['networkError']
                });
            } else {
                // console.log('Error', err.message);
                toast.error(<ErrorSuccessLang errorId='DEFAULTERROR' />);
                dispatch({
                    type: USER_PROFILE_FAIL,
                    payload: ['somethingWrong']
                });
            }
        }
    };
