import Icons from 'components/icons/index';
import classNames from 'classnames';
import Roundify from './Roundify';
import { widthBiggerThan } from 'helpers/utils';
import { useState } from 'react';
import { avatarCircles as styles } from 'constants/styles';

const Avatar = ({ profile, size, iconSize, preset = 'default', banned }) => {
    const [ready, setReady] = useState(false);
    return (
        <div className={`relative`}>
            <div
                className={classNames(
                    'rounded-full flex justify-center items-center  overflow-hidden',

                    size ?? styles[preset].icon.circleSize,
                    styles[preset].icon.icon,
                    (!profile?.avatar || !ready) && styles[preset].icon.border
                )}
            >
                {profile?.avatar ? (
                    <>
                        <img src={profile.avatar} className={classNames('object-cover w-full h-full', !ready && 'hidden')} onLoad={() => setReady(true)} />
                        {!ready && <Icons stroke={styles[preset].icon.iconStroke} icon={'User'} size={iconSize ?? styles[preset].icon.iconSize} />}
                    </>
                ) : (
                    <Icons stroke={styles[preset].icon.iconStroke} icon={'User'} size={iconSize ?? styles[preset].icon.iconSize} />
                )}
            </div>

            {false && profile?.verifiedProfile && !banned && (
                <>
                    {profile?.verifiedCarRegistration && (
                        <div className={styles[preset].verifyCar.position}>
                            <Roundify
                                bgColor={styles[preset].verifyCar.bgColor}
                                color={styles[preset].verifyCar.color}
                                width={styles[preset].verifyCar.width}
                                height={styles[preset].verifyCar.height}
                                extraClass={styles[preset].verifyCar.extraClass}
                            >
                                <Icons icon={'Car1'} size={styles[preset].verifyCar.iconSize} />
                            </Roundify>
                        </div>
                    )}
                    <div className={styles[preset].verify.position}>
                        <Roundify
                            bgColor={styles[preset].verify.bgColor}
                            color={styles[preset].verify.color}
                            width={styles[preset].verify.width}
                            height={styles[preset].verify.height}
                            extraClass={styles[preset].verify.extraClass}
                        >
                            <Icons icon={'Check'} size={styles[preset].verify.iconSize} />
                        </Roundify>
                    </div>
                </>
            )}
            {banned && (
                <div className={styles[preset].red.position}>
                    <Roundify
                        bgColor={styles[preset].red.bgColor}
                        color={styles[preset].red.color}
                        width={styles[preset].red.width}
                        height={styles[preset].red.height}
                        extraClass={styles[preset].red.extraClass}
                    >
                        <Icons icon={'Slash'} size={styles[preset].red.iconSize} />
                    </Roundify>
                </div>
            )}
        </div>
    );
};

export default Avatar;
