import classNames from 'classnames';
import { connect } from 'react-redux';
import BtnIcon from 'components/buttons/BtnIcon';
import GetLang from 'hoc/GetLang';
import { useState } from 'react';
import { getDeviceProps, reportBug } from 'fetch/apiRequests';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import Icons from 'components/icons/index';
import BtnPill from 'components/buttons/BtnPill';

import { useOnlineStatus } from 'hooks/useOnlineStatus';

import Spinner from 'components/blocks/Spinner';
import OfflineMode from 'components/blocks/OfflineMode';
import { appVersion } from 'constants/globalConstants';

const PageBug = ({ auth: { user }, handleBug }) => {
    const isOnline = useOnlineStatus();

    const [loading, setLoading] = useState(false);
    const [textObj, setTextObj] = useState({});

    const location = useLocation();

    const size = useWindowSize();

    let handleSubmit = async () => {
        const deviceProps = await getDeviceProps();
        const { hardwareName, platformName, platformVersion } = deviceProps;
        const body = {
            date: new Date().toDateString(),
            time: new Date().toTimeString(),
            action: textObj?.trying,
            description: textObj?.report,
            hardwareName: hardwareName?.[0],
            platformName,
            platformVersion,
            deviceRes: `${size?.width} x ${size?.height}`,
            browser: window?.navigator?.userAgent,
            appVersion,
            bugType: "I'm stuck",
            page: `${location?.pathname}${location?.search}`,
            previousPage: location?.state?.from,
            userEmail: user?.email,
            userNumber: user?.phoneNumber
        };

        // console.log(hardwareName);
        // console.log(deviceProps);

        setLoading(true);
        const res = await reportBug(body);
        setLoading(false);
        handleBug(false);
    };

    const handleChange = (e) => {
        setTextObj((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <>
                    <div className={classNames('bg-white')}>
                        <div className='w-full min-h-vw-16 '>
                            <div className={classNames('py-vw-2 px-vw-2 h-full flex items-center gap-4 justify-end', rtl ? 'flex-row-reverse ' : 'flex-row ')}>
                                <BtnIcon icon={'Close'} handleClick={handleBug} flip={rtl} noBg />
                            </div>
                        </div>

                        <div className={classNames('w-full px-vw-6')}>
                            <div className={classNames('flex gap-2 justify-center items-center text-vw-lg font-medium mb-vw-8', rtl && 'flex-row-reverse')}>
                                <Icons containerClass={classNames('text-bb-red-400')} icon={'Bug1'} />
                                <span>{translations.data['e.bug.submitBug']}</span>
                            </div>
                            <div>
                                <div className='flex flex-col mb-vw-6'>
                                    <label htmlFor='trying' className={classNames('mb-2', rtl && 'text-right')}>
                                        {translations.data['e.bug.action']}
                                    </label>
                                    <textarea
                                        className='mx-auto w-full focus:outline-none bg-gray-100 p-vw-4 rounded-2xl resize-none'
                                        id='trying'
                                        name='trying'
                                        rows='2'
                                        cols='40'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex flex-col mb-4'>
                                    <label htmlFor='report' className={classNames('mb-2', rtl && 'text-right')}>
                                        {translations.data['e.bug.description']}
                                    </label>
                                    <textarea
                                        className='mx-auto w-full focus:outline-none bg-gray-100 p-vw-4 rounded-2xl resize-none'
                                        id='report'
                                        name='report'
                                        rows='5'
                                        cols='40'
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='w-full flex justify-center mb-4 pt-4'>
                                    <BtnPill
                                        paddingX='px-vw-8'
                                        type='cancel'
                                        handleClick={handleSubmit}
                                        disabled={!isOnline || loading || !textObj?.trying || !textObj?.report}
                                        rtl={rtl}
                                    >
                                        {loading ? (
                                            <Spinner strokeColor='#FF4466' />
                                        ) : (
                                            <>
                                                <Icons icon={'Bug1'} />
                                                {translations.data['e.bug.submit']}
                                            </>
                                        )}
                                    </BtnPill>
                                </div>
                                <OfflineMode />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(PageBug);
