import { connect } from 'react-redux';

const ErrorSuccessLangNS = ({ errorId, globalState: { lang } }) => {
    return errorId;
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

const ErrorSuccessLang = connect(mapStateToProps)(ErrorSuccessLangNS);
export { ErrorSuccessLang };
