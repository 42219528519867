/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    firstTime: true,
    tooltips: {
        ridesOffered: true,
        ridesOfferedArchived: true,
        ridesBooked: true,
        ridesBookedArchived: true,
        manageBookings: true,
        tripViewBookings: true,
        publishPricing: true,
        viewPricing: true,
        ladiesOnly: true,
        searchResults: true
    }
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const updateTooltip = (draft, action) => {
    const { tooltip, value } = action;
    console.log('tooltip', tooltip, value);
    draft.tooltips[tooltip] = value;
    return draft;
};

const updateFirstTime = (draft, action) => {
    const { value } = action;

    draft.firstTime = value;
    return draft;
};

const updateOnboarding = (draft, action) => {
    const { onboarding } = action;

    draft = onboarding;
    return draft;
};

export default createReducer(initialState, {
    [types.ONBOARDING_TOOLTIP_UPDATE]: updateTooltip,
    [types.ONBOARDING_FIRSTTIME_UPDATE]: updateFirstTime,
    [types.ONBOARDING_UPDATE]: updateOnboarding
});
