import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import useScroll from 'hooks/useScroll';
import classNames from 'classnames';
import BtnPill from './BtnPill';
import { setScroll } from 'store/actions/globalState';
import { useRef, useState, useEffect } from 'react';

const BtnScroll = ({ setScroll, page, params, handleClick, children, disabled, archive, visible = true, containerRef, stack }) => {
    const [localScroll, setLocalScroll] = useState(0);

    const scrollObj = useScroll({
        wait: 500,
        element: containerRef
    });

    const { y, direction } = scrollObj;

    const stateRef = useRef();
    stateRef.current = y;

    const getVisibleStatus = () => {
        if (direction === 'up') {
            if (y > 1000) return true;
        }
        return false;
    };

    const handleToTop = () => {
        containerRef?.current?.scrollTo?.({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        setLocalScroll(containerRef?.current?.scrollTop);
        // console.log(scrollObj);
    }, [scrollObj]);

    useEffect(() => {
        return () => {
            setScroll({
                page,
                top: stateRef.current
            });
            // console.log(
            //     'unloading scroll and setting its value',
            //     stateRef.current
            // );
        };
    }, []);

    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <div
                        className={classNames(
                            'absolute left-1/2 -translate-x-1/2  z-50 transition-all duration-300 ease-in-out',

                            {
                                'translate-y-vw-28 md:translate-y-36': !getVisibleStatus() && stack,
                                'translate-y-vw-20 md:translate-y-32': !getVisibleStatus() && !stack,
                                'bottom-vw-24 md:bottom-16': !stack,
                                'bottom-vw-36 md:bottom-28': stack
                            }
                        )}
                    >
                        <BtnPill
                            type={archive ? 'dark-gray' : 'primary'}
                            width='w-max'
                            height='h-vw-10 md:h-10'
                            // justify={'justify-between'}
                            rtl={rtl}
                            disabled={disabled}
                            params={params}
                            handleClick={handleToTop}
                        >
                            {children || (
                                <>
                                    <span className='flex-none'>{translations.data['e.scrollToTop']}</span>
                                    <Icons icon={'ArrowUpLong'} />
                                </>
                            )}
                        </BtnPill>
                    </div>
                );
            }}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setScroll
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(BtnScroll);
