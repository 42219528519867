import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';
import { routePath } from 'constants/structRoutes';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnIcon from 'components/buttons/BtnIcon';

import { togglePublishSwitching, updatePublishTime } from 'store/actions/publish';
import BtnPill from 'components/buttons/BtnPill';
import { useLayoutEffect, useEffect } from 'react';

import { updateProfile } from 'fetch/apiRequests';

import { useState } from 'react';

import SwitchBtn from 'components/blocks/SwitchBtn';
import Input from 'components/forms/Input';
import Roundify from 'components/blocks/Roundify';
import GetLang from 'hoc/GetLang';
import _, { divide } from 'lodash';
import { regexString16, regexString20, regexEmail, regexDate } from 'helpers/regexPatterns';
import BtnLoadState from 'components/buttons/BtnLoadState';
import { loadUser } from 'store/actions/auth';
import OfflineMode from 'components/blocks/OfflineMode';
import ListBox from 'components/forms/ListBox';
import MessageBox from 'components/blocks/MessageBox';
import { setStaticPage } from 'store/actions/staticPages';
import LabelMaker from 'components/forms/LabelMaker';
import { genderList } from 'constants/defaultObjects';
import store from 'store/index';
import { analytics } from 'helpers/segment';

const showDisplayName = false;
const showGender = true;

const transformDate = (date, type = 'to') => {
    if (!date) return undefined;
    let newDate;
    if (type === 'from') newDate = `${date.slice(-2)}/${date.slice(5, 7)}/${date.slice(0, 4)}`;
    else newDate = `${date.slice(-4)}-${date.slice(3, 5)}-${date.slice(0, 2)}`;

    return newDate;
};

const PageProfilePersonal = ({
    setStaticPage,
    loadUser,
    auth: {
        isAuthenticated,
        user,
        user: { profile }
    }
}) => {
    const { firstName, lastName, displayName, dob, gender, phoneNumber, verifiedId } = profile;
    const newDob = transformDate(dob, 'from');
    const pageData = {
        page: `app.profile.personal`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false
    };

    const makeDefaultProfile = (type = 'value') => {
        if (type === 'validation')
            return {
                firstName: true,
                lastName: true,
                displayName: displayName ?? null,
                dob: newDob ?? null,
                gender: 'na'
            };
        return {
            firstName: firstName,
            lastName: lastName,
            displayName: displayName ?? '',
            dob: transformDate(dob, 'from') ?? '',
            gender: gender ?? 'na'
        };
    };

    const [isModalAction, setIsModalAction] = useState(false);
    const [personalInfo, setPersonalInfo] = useState(makeDefaultProfile());
    const [backupPersonalInfo, setBackupPersonalInfo] = useState(makeDefaultProfile());
    const [validFields, setValidFields] = useState(makeDefaultProfile('validation'));

    const [enabled, setEnabled] = useState(false);
    const [isSame, setIsSame] = useState(true);
    const [allFieldsValid, setAllFieldsValid] = useState(null);

    const handleSave = async (mutateData) => {
        setIsModalAction(true);
        const res = await updateProfile({ ...personalInfo, dob: transformDate(personalInfo?.dob) }, user);
        // console.log(res);
        loadUser();
        setIsSame(true);
        mutateData();
        setIsModalAction(false);
    };

    const handlePersonalInfo = (id, value, passedTest) => {
        setPersonalInfo((prev) => ({ ...prev, [id]: value }));
        setValidFields((prev) => ({ ...prev, [id]: passedTest }));
    };

    const checkSameness = (objA, objB) => {
        return _.isEqual(objA, objB);
    };

    useEffect(() => {
        loadUser();
    }, []);

    useEffect(() => {
        if (!enabled) {
            setPersonalInfo(makeDefaultProfile());
            setBackupPersonalInfo(makeDefaultProfile());
            setValidFields(makeDefaultProfile('validation'));
        }
    }, [enabled]);

    useEffect(() => {
        // console.log(personalInfo);
        setIsSame(checkSameness(personalInfo, backupPersonalInfo));
    }, [personalInfo, profile, backupPersonalInfo]);

    useEffect(() => {
        setBackupPersonalInfo(makeDefaultProfile());
    }, [profile]);

    useEffect(() => {
        setAllFieldsValid(Object.values(validFields).every((item) => item !== false));
    }, [validFields]);

    useEffect(() => {
        analytics.page('Profile Personal', 'App', isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({ rtl, lang, tripLang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation, online }) => {
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}></LayoutPageHeader>
                        <div className='h-body-w-header overflow-y-auto no-scrollbar flex flex-col justify-between'>
                            <LayoutPageBody paddingBottom='' minHeight={'min-h-body-w-header-1'}>
                                <div className={`select-none`}>
                                    <div className={classNames('px-vw-6 pb-6 flex flex-col bg-white rounded-t-3xl relative gap-4 ')}>
                                        <div className={classNames('flex justify-between items-center  mb-vw-4', rtl && 'flex-row-reverse')}>
                                            <h2
                                                style={{
                                                    direction: rtl ? 'rtl' : 'ltr'
                                                }}
                                                className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                            >
                                                {translations.data['e.profile.personalInfo.title']}
                                            </h2>
                                            <SwitchBtn enabled={enabled} setEnabled={setEnabled} />
                                        </div>
                                        <OfflineMode margins='' force={false} />

                                        <div className={classNames('flex flex-col gap-8', rtl && 'items-end')}>
                                            <LabelMaker
                                                label='e.profile.personalInfo.firstName'
                                                suffix='e.profile.personalInfo.required'
                                                value={personalInfo.firstName}
                                                edit={enabled}
                                                onChangeHandler={handlePersonalInfo}
                                                id='firstName'
                                                regex={regexString20}
                                                valid={validFields.firstName}
                                                disabled={!online || isModalAction}
                                            />
                                            <LabelMaker
                                                label='e.profile.personalInfo.lastName'
                                                suffix='e.profile.personalInfo.required'
                                                value={personalInfo.lastName}
                                                edit={enabled}
                                                onChangeHandler={handlePersonalInfo}
                                                id='lastName'
                                                regex={regexString20}
                                                valid={validFields.lastName}
                                                disabled={!online || isModalAction}
                                            />
                                            <LabelMaker
                                                label='e.profile.personalInfo.displayName'
                                                suffix='e.profile.personalInfo.optional'
                                                value={personalInfo.displayName}
                                                edit={enabled}
                                                onChangeHandler={handlePersonalInfo}
                                                id='displayName'
                                                regex={regexString20}
                                                valid={validFields.displayName}
                                                minTestLength={1}
                                                disabled={!online || isModalAction}
                                                visible={showDisplayName}
                                            />
                                            <LabelMaker
                                                label='e.profile.personalInfo.dob'
                                                suffix='e.profile.personalInfo.optional'
                                                value={personalInfo.dob}
                                                edit={enabled}
                                                type='number'
                                                onChangeHandler={handlePersonalInfo}
                                                id='dob'
                                                regex={regexDate}
                                                valid={validFields.dob}
                                                minTestLength={1}
                                                placeholder={'23/04/2001'}
                                                disabled={!online || isModalAction}
                                            />
                                            <div className={classNames(enabled ? 'gap-2' : 'gap-8', 'flex flex-col  w-full')}>
                                                <LabelMaker
                                                    label='e.profile.personalInfo.gender'
                                                    suffix='e.profile.personalInfo.recommended'
                                                    value={genderList.find((item) => item.id === personalInfo.gender)}
                                                    edit={verifiedId ? (gender === 'na' || !gender ? enabled : false) : enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='gender'
                                                    list={genderList}
                                                    disabled={!online || isModalAction}
                                                />
                                                <MessageBox
                                                    label=''
                                                    noTitle
                                                    fullButton
                                                    id='priceTooltip'
                                                    margins={classNames('mb-vw-4')}
                                                    padding=''
                                                    icon='Info'
                                                    action={() => setStaticPage('gender')}
                                                >
                                                    {translations?.data?.['e.tooltip.important']}
                                                </MessageBox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </LayoutPageBody>
                            {enabled && (
                                <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 border-t border-gray-200`}>
                                    <BtnLoadState
                                        disabled={isSame || !allFieldsValid || !online}
                                        spinner={isModalAction}
                                        handleClick={handleSave}
                                        params={[mutateData]}
                                        label='e.profile.personalInfo.save'
                                    />
                                </div>
                            )}
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loadUser,
            togglePublishSwitching,
            updatePublishTime,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageProfilePersonal);
