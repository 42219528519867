import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';

import { CardResult } from 'components/cards';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations, animStates2 } from 'constants/animations';
import { motion, LayoutGroup } from 'framer-motion';
import { BtnIcon } from 'components/buttons';
import LocationBox from 'components/blocks/LocationBox';
import PosterResults from 'components/blocks/PosterResults';
import { toggleSearchSwitching, updateSearchFilters } from 'store/actions/search';
import useMyNavigation from 'hooks/useMyNavigation';
import { getNavParams } from 'helpers/helperFunctions';
import OfflineMode from 'components/blocks/OfflineMode';
import BtnPill from 'components/buttons/BtnPill';
import NoResults from 'components/blocks/NoResults';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnLoadState from 'components/buttons/BtnLoadState';
import useScroll from 'hooks/useScroll';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { setScroll } from 'store/actions/globalState';
import BtnScroll from 'components/buttons/BtnScroll';
import ModalBottom from 'components/modals/ModalBottom';
import { convertRides, createSuggestion } from 'fetch/apiRequests';
import { widthBiggerThan } from 'helpers/utils';
import MessageBox from 'components/blocks/MessageBox';
import IconRound from 'components/blocks/IconRound';
import SwitchSection from 'components/blocks/SwitchSection';
import { setStaticPage } from 'store/actions/staticPages';
import { analytics } from 'helpers/segment';
import { appVersion } from 'constants/globalConstants';
import { updateSelectedRides } from 'store/actions/admin';

const PageTripResults = ({
    updateSelectedRides,
    setStaticPage,
    updateSearchFilters,
    onboarding: { tooltips },
    auth: {
        user,
        user: { profile, admin, phoneNumber },
        isAuthenticated
    },
    adminState: { selectedRides },
    globalState: { scroll },
    toggleSearchSwitching,
    search: { search, isValid, seats }
}) => {
    const [isModal, setIsModal] = useState(false);

    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);

    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    useEffect(() => {
        analytics.page('Search Results', 'App', isAuthenticated);
    }, []);

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const clearSelectedRides = () => {
        updateSelectedRides([]);
    };

    const convertSelectedRides = async (mutateData) => {
        setIsModalAction(true);
        await convertRides(selectedRides);
        await mutateData();
        setIsModalAction(false);
    };

    const pageData = {
        page: 'app.search',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/rides/search',
        body: { ...search, userId: user?._id, appVersion },
        crudType: 'POST',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false,
        resultsPerPage: 50
    };
    const { resultsPerPage } = pageData;

    const isSuggestable = () => {
        return search?.from?.en?.formatted || search?.to?.en?.formatted;
    };

    const { handleNavigation, location } = useMyNavigation();
    const handleSwitching = () => () => {
        toggleSearchSwitching();
        const { toPage, navParams } = getNavParams({
            location,
            page: 'app.search.switch',
            pageParams: null,
            isValid: true
        });
        handleNavigation(toPage, navParams);
    };

    const ref = useRef(null);

    useEffect(() => {
        if (scroll?.page === 'app.search') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    const handleAction = async (mutateData, status) => {
        if (status === 'SUGGEST_ITINERARY' && isSuggestable()) {
            setIsModalAction(true);
            setIsModal(false);
            await createSuggestion({
                suggestedBy: user?._id,
                from: search?.from,
                to: search?.to
            });
            // await mutateData();
            setIsModalAction(false);
            setMode(null);
        }
    };

    // console.log('rendering: List Results');

    return (
        <LayoutPage pageData={pageData} sticky={true} infinite={true}>
            {({
                rtl,
                lang,
                location,
                animStates: { animPages, animCards },
                params,
                translations,
                isValidating,
                isValidParams,
                data,
                size,
                setSize,
                mutateData,
                handleNavigation,
                reducedMotion,
                getReducedMotion,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.count,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                const isEmpty = newData?.count === 0;
                const isReachingEnd = newData?.count === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('search', search);

                const locFrom = search.from.location.coordinates.length === 0 ? false : true;
                const locTo = search.to.location.coordinates.length === 0 ? false : true;

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            {locFrom || locTo ? (
                                <>
                                    <LocationBox search={search} />
                                    <BtnIcon icon='ArrowUpDown1' handleClick={handleSwitching(mutateData)} rtl={rtl} iconSize={24} noBg />
                                </>
                            ) : process.env.REACT_APP_ADMIN === 'enabled' && admin && phoneNumber === '33648517574' ? (
                                <div
                                    className={classNames('flex items-center', {
                                        'flex-row-reverse': rtl
                                    })}
                                >
                                    {selectedRides?.length > 0 ? (
                                        <>
                                            <BtnIcon disabled={!online} icon='Close' iconSize={'5.8vw'} noBg handleClick={clearSelectedRides} params={[]} />
                                            <BtnIcon
                                                disabled={!online}
                                                icon='Repeat1'
                                                iconSize={'5.8vw'}
                                                noBg
                                                handleClick={convertSelectedRides}
                                                params={[mutateData]}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            ) : null}
                        </LayoutPageHeader>

                        <div
                            ref={ref}
                            className={classNames(
                                locFrom || locTo ? 'h-body-w-header-2' : 'h-body-w-header',
                                ' overflow-y-auto no-scrollbar flex flex-col justify-between md:h-full'
                            )}
                        >
                            {newData?.count ? (
                                <div
                                    className={classNames(
                                        'absolute left-1/2 -translate-x-1/2 bottom-vw-24 md:bottom-16 z-50 transition-all duration-300 ease-in-out'
                                        // 'translate-y-vw-20 md:translate-y-32'
                                    )}
                                >
                                    <BtnPill
                                        type={'primary'}
                                        width='w-max'
                                        height='h-vw-10 md:h-10'
                                        // justify={'justify-between'}
                                        rtl={rtl}
                                        // disabled={disabled}
                                        // params={params}
                                        handleClick={() => setStaticPage('filterSettings')}
                                    >
                                        <>
                                            {translations?.data?.['e.filter.button']}
                                            {/* <span className='flex-none'>Filter & sort</span> */}
                                            {/* <Icons icon={'ArrowUpLong'} /> */}
                                        </>
                                    </BtnPill>
                                </div>
                            ) : null}
                            <BtnScroll containerRef={ref} page={'app.search'} stack />
                            <LayoutPageBody minHeight={'min-h-body-w-header-1 md:min-h-auto'}>
                                <>
                                    <PosterResults
                                        data={newData}
                                        // isValidating={isValidating}
                                        search={search}
                                        animStates={{ animPages, animCards }}
                                        reducedMotion={reducedMotion}
                                    />

                                    <LayoutGroup>
                                        <div className='lg:max-w-3xl lg:mx-auto'>
                                            <div className=''>
                                                {(tooltips?.ladiesOnly || tooltips?.searchResults) && (
                                                    <div className={classNames('mb-vw-4 flex flex-col gap-2')}>
                                                        {(profile?.gender === 'female' || user?.admin) && tooltips?.ladiesOnly !== false && (
                                                            <motion.div layout='position' key='k2a'>
                                                                <MessageBox
                                                                    label=''
                                                                    noTitle
                                                                    id='ladiesOnly'
                                                                    margins=''
                                                                    bodyAction={() => setStaticPage('ladiesOnly')}
                                                                >
                                                                    {!(profile?.verifiedProfile && profile?.gender === 'female') && (
                                                                        <IconRound icon='Female1' preset='yellow' presetSize='small' />
                                                                    )}
                                                                    {translations?.data?.['e.tooltip.ladiesOnly.short']}
                                                                </MessageBox>
                                                            </motion.div>
                                                        )}
                                                        {tooltips?.searchResults !== false && (
                                                            <motion.div layout='position' key='k2b'>
                                                                <MessageBox
                                                                    id='searchResults'
                                                                    className={classNames('flex flex-col', rtl && 'items-end')}
                                                                    label=''
                                                                    preset='light-green'
                                                                >
                                                                    <div
                                                                        className={classNames(
                                                                            'rounded-full px-4 py-2 w-max mb-vw-3 bg-emerald-200 text-emerald-600 font-bold flex gap-1',
                                                                            rtl && 'flex-row-reverse'
                                                                        )}
                                                                    >
                                                                        <Icons icon={'Id2'} />
                                                                        <Icons icon={'Car1'} />
                                                                    </div>
                                                                    {translations?.data?.['e.tooltip.searchResults']}
                                                                </MessageBox>
                                                            </motion.div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <motion.ul
                                                layout='position'
                                                key='resultsList'
                                                className={`px-vw-6a flex flex-col items-center`}
                                                initial={getReducedMotion('card', animCards, reducedMotion).initial}
                                                animate={getReducedMotion('card', animCards, reducedMotion).animate}
                                                variants={animations.staggerFromTop}
                                            >
                                                {newData?.count != null ? (
                                                    newData?.count > 0 ? (
                                                        newData?.data?.map?.((item) => {
                                                            return <CardResult search={search} data={item} key={item._id} rtl={rtl} lang={lang} />;
                                                        })
                                                    ) : (
                                                        <div
                                                            className='w-full px-vw-6 pt-vw-8 pb-vw-8 md:px-6 md:pt-8 md:pb-8'
                                                            initial={getReducedMotion('results', animStates2.results, reducedMotion).initial}
                                                            animate={getReducedMotion('results', animStates2.results, reducedMotion).animate}
                                                            variants={animations.elementSlides}
                                                        >
                                                            <NoResults
                                                                title='e.nothingFound.results.title'
                                                                description='e.nothingFound.results.description'
                                                                noImage
                                                            />

                                                            <div className={classNames('px-vw-10a flex flex-col items-center justify-center w-max mx-auto')}>
                                                                <BtnPill
                                                                    paddingX='px-vw-8 md:px-8'
                                                                    gap='gap-4'
                                                                    type='primary'
                                                                    handleClick={() => handleNavigation('app.searchCarSharing', null)}
                                                                    disabled={!online}
                                                                    justify={'justify-between'}
                                                                    rtl={rtl}
                                                                >
                                                                    <Icons icon={rtl ? 'ArrowRightLong1' : 'ArrowLeftLong1'} />
                                                                    {translations?.data?.['e.nothingFound.results.button']}
                                                                    {/* <span className='w-6' /> */}
                                                                </BtnPill>
                                                                <BtnPill
                                                                    type={'primary'}
                                                                    width='w-max'
                                                                    height='h-vw-10 md:h-10'
                                                                    // justify={'justify-between'}
                                                                    rtl={rtl}
                                                                    // disabled={disabled}
                                                                    // params={params}
                                                                    handleClick={() => setStaticPage('filterSettings')}
                                                                >
                                                                    <>
                                                                        <span className='flex-none'>Filter & sort</span>
                                                                        {/* <Icons icon={'ArrowUpLong'} /> */}
                                                                    </>
                                                                </BtnPill>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <>
                                                        <CardSkeleton rtl={rtl} />
                                                        <CardSkeleton rtl={rtl} />
                                                    </>
                                                )}
                                            </motion.ul>
                                        </div>
                                    </LayoutGroup>

                                    {!isReachingEnd ? (
                                        <div className={classNames('px-vw-6 py-vw-4 md:px-6 md:py-4 mb-vw-12')}>
                                            <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} />
                                        </div>
                                    ) : (
                                        <div className={classNames('h-vw-20')}></div>
                                    )}
                                </>
                            </LayoutPageBody>
                            {!newData?.count && isSuggestable() && (
                                <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 md:px-6 md:py-4 border-t border-gray-200`}>
                                    <BtnPill type='confirmed' handleClick={setMode} params={['SUGGEST_ITINERARY']} justify={'justify-between'} rtl={rtl}>
                                        <span className='w-vw-6 md:w-6' />

                                        {translations?.data?.['e.suggestRide']}
                                        <Icons icon={'Itinerary1'} />
                                    </BtnPill>
                                </div>
                            )}
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6  md:px-6 md:py-6 flex flex-col items-center '>
                                <OfflineMode margins='' />

                                {mode === 'SUGGEST_ITINERARY' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 md:px-2 md:mt-4 md:mb-4 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.suggestRide.title']}
                                        </div>
                                        <div className='px-vw-2 mb-vw-6 md:px-2 md:mb-6 font-medium text-vw-base text-center text-gray-600'>
                                            {translations?.data?.['e.suggestRide.body']}
                                        </div>
                                        <BtnPill
                                            type='primary'
                                            disabled={!online}
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'SUGGEST_ITINERARY')}
                                        >
                                            <>
                                                <span className='w-vw-6 md:w-6' />
                                                {translations?.data?.['e.suggestRide']}
                                                <Icons
                                                    icon={'Itinerary1'}
                                                    size={widthBiggerThan(1024) ? '24px' : widthBiggerThan() ? '32px' : '5.8vw'}
                                                    flip={rtl}
                                                />
                                            </>
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    search: state.search,
    results: state.results,
    onboarding: state.onboarding,
    adminState: state.admin
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSearchSwitching,
            setScroll,
            updateSearchFilters,
            setStaticPage,
            updateSelectedRides
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTripResults);
