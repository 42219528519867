import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePublishDate, updatePublishDateRange, updateSelectedDay } from 'store/actions/publish';

import { motion } from 'framer-motion';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

import { daysOfWeek, daysOfWeekStrings } from 'constants/defaultObjects';
import Pill from 'components/blocks/Pill';

import { animations } from 'constants/animations';

const DaysOfWeekInline = ({
    dateRange,
    handleDateRange,
    updateDayAndOpenModal,
    showOnlyIfActive = true,
    handleClear,
    animation = {
        layout: 'position',
        initial: 'fadeOut',
        animate: 'inFieldDelayed',
        variants: animations.pageSlides
    }
}) => {
    return (
        <GetLang>
            {({ lang, rtl, translations, time24 }) => (
                <div>
                    <motion.div
                        layout={animation.layout}
                        initial={animation.initial}
                        animate={animation.animate}
                        variants={animation.variants}
                        className={classNames(rtl && 'flex-row-reverse', 'flex flex-wrap justify-between mb-vw-4')}
                    >
                        {daysOfWeek.map((day, index) => (
                            <SwitchSectionCalendar
                                key={day}
                                icon='Calendar1'
                                iconColorPreset={'light-violet'}
                                colorOff='light-gray'
                                border='border-t-a'
                                label='e.view.recurring'
                                visible={true}
                                // visible={dateRange?.[index]?.enabled}
                                dayIndex={index}
                                day={day}
                                dayBody={dateRange?.[index]}
                                updateDay={handleDateRange}
                                generalTime={dateRange?.time}
                                generalSeats={dateRange?.seats}
                                updateDayAndOpenModal={updateDayAndOpenModal}
                                handleClear={handleClear}
                                showOnlyIfActive={showOnlyIfActive}
                            />
                        ))}
                    </motion.div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishDate,
            updatePublishDateRange,
            updateSelectedDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DaysOfWeekInline);

const SwitchSectionCalendar = ({
    showOnlyIfActive,
    visible,
    dayIndex,
    dayBody,
    day,
    updateDay,
    generalTime,
    generalSeats,
    updateDayAndOpenModal,
    handleClear,

    label,
    color,
    colorOff,
    lock,
    border = 'border-b',
    icon,
    iconColorPreset,
    padding = 'px-vw-6a md:px-6a py-vw-4a'
}) => {
    return (
        <GetLang>
            {({ rtl, lang, translations }) =>
                visible ? (
                    <button
                        style={{ direction: rtl ? 'rtl' : 'ltr' }}
                        className='focus:outline-none'
                        onClick={() => updateDay(dayIndex, { ...dayBody, enabled: !dayBody.enabled })}
                    >
                        <Pill
                            sizePreset='small-circle'
                            textSize={lang === 'ar' ? 'text-vw-xxs' : 'text-vw-sm'}
                            colorPreset={dayBody?.enabled ? 'light-blue' : 'faded'}
                            margins=''
                        >
                            {daysOfWeekStrings[lang][dayIndex][lang === 'ar' ? 'iso3' : 'iso2']}
                            {/* <FormattedDateTime dateString={DateTime.fromISO(day).setLocale(translations.formatting.locale).toFormat('ccc')} date /> */}
                        </Pill>
                    </button>
                ) : null
            }
        </GetLang>
    );
};
