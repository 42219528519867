import classNames from 'classnames';

const presets = {
    default: {
        color: 'text-gray-700',
        size: 'text-vw-lg',
        weight: 'font-medium'
    },
    'small-faded': {
        color: 'text-gray-500',
        size: 'text-vw-base',
        weight: 'font-medium'
    }
};

const Paragraph = ({ className, children, preset = 'default', color, size, weight, margins = 'mb-vw-6', padding = '', align = 'text-center' }) => {
    return (
        <div
            className={classNames(
                className,

                color ?? presets[preset].color,
                size ?? presets[preset].size,
                weight ?? presets[preset].weight,
                margins,
                padding,
                align
            )}
        >
            {children}
        </div>
    );
};

export default Paragraph;
