import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';
import { routePath } from 'constants/structRoutes';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnIcon from 'components/buttons/BtnIcon';

import { togglePublishSwitching, updatePublishTime } from 'store/actions/publish';
import BtnPill from 'components/buttons/BtnPill';
import { useEffect } from 'react';
import _ from 'lodash';
import { updateProfileAdmin } from 'fetch/apiRequests';

import { useState, useRef } from 'react';

import SwitchBtn from 'components/blocks/SwitchBtn';
import Input from 'components/forms/Input';
import Roundify from 'components/blocks/Roundify';
import GetLang from 'hoc/GetLang';

import { regexString16, regexString20, regexEmail, regexDate } from 'helpers/regexPatterns';
import BtnLoadState from 'components/buttons/BtnLoadState';
import { loadUser } from 'store/actions/auth';
import OfflineMode from 'components/blocks/OfflineMode';

import { setStaticPage } from 'store/actions/staticPages';
import LabelMaker from 'components/forms/LabelMaker';
import { genderList, langList, yesNoNullList } from 'constants/defaultObjects';
import store from 'store/index';

import Avatar from 'components/blocks/Avatar';
import Uploader from 'components/blocks/Uploader';
import AvatarEditor from 'components/blocks/AvatarEditor';
import UserDisplay from 'components/blocks/UserDisplay';
import SwitchSection from 'components/blocks/SwitchSection';
import BtnContactDriver from 'components/buttons/BtnContactDriver';
import { handleWhatsApp } from 'constants/translations';

const transformDate = (date, type = 'to') => {
    if (!date) return undefined;
    let newDate;
    if (type === 'from') newDate = `${date.slice(-2)}/${date.slice(5, 7)}/${date.slice(0, 4)}`;
    else newDate = `${date.slice(-4)}-${date.slice(3, 5)}-${date.slice(0, 2)}`;

    return newDate;
};

const PageAdminUserEdit = ({
    setStaticPage,
    loadUser,
    auth: {
        isAuthenticated,
        user,
        user: { profile }
    }
}) => {
    const pageData = {
        page: 'app.admin.user',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/profiles/find-admin',
        backBtn: true,
        zIndex: 41,
        checkIdParam: true
    };

    const makeDefaultProfile = (profile, type) => {
        // if (type === 'validation')
        //     return {
        //         firstName: true,
        //         lastName: true,
        //         email: true,
        //         phoneNumber: true,
        //         dob: true
        //     };

        return {
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            verifiedId: profile?.verifiedId,
            verifiedProfile: profile?.verifiedProfile,
            verifiedCarRegistration: profile?.verifiedCarRegistration,
            dob: transformDate(profile?.dob, 'from') ?? '',
            lang: profile?.lang,
            gender: profile?.gender ?? 'na',
            avatar: profile?.avatar,
            blockUser: profile?.user?.blockUser
        };
        // return {...profile};
    };

    const [isModalAction, setIsModalAction] = useState(false);
    const [personalInfo, setPersonalInfo] = useState({});
    const [backupPersonalInfo, setBackupPersonalInfo] = useState({});
    const [validFields, setValidFields] = useState(makeDefaultProfile(null, 'validation'));

    const [enabled, setEnabled] = useState(false);
    const [isSame, setIsSame] = useState(true);
    const [allFieldsValid, setAllFieldsValid] = useState(null);

    const widgetApi = useRef();

    const handleImage = (url) => {
        handlePersonalInfo('avatar', url?.cdnUrl);
    };

    const handleClear = () => {
        handlePersonalInfo('avatar', '');
    };

    const openUploader = () => {
        const dialog = widgetApi.current.openDialog();

        dialog.switchTab('file');
    };

    const objDifference = (objA, objB) => {
        const changes = _.differenceWith(_.toPairs(objA), _.toPairs(objB), _.isEqual);
        return _.fromPairs(changes);
    };

    const handleSave = async (userId, mutateData) => {
        const { firstName, lastName, displayName } = personalInfo;

        let profileToSave = objDifference(personalInfo, backupPersonalInfo);
        if (profileToSave?.dob) profileToSave.dob = transformDate(personalInfo?.dob);
        // console.log(profileToSave);

        setIsModalAction(true);

        const res = await updateProfileAdmin(userId, profileToSave);

        setIsSame(true);
        mutateData();
        setIsModalAction(false);

        setBackupPersonalInfo((prev) => personalInfo);
    };

    const handlePersonalInfo = (id, value, passedTest) => {
        setPersonalInfo((prev) => ({ ...prev, [id]: value }));
        setValidFields((prev) => ({ ...prev, [id]: passedTest }));
    };

    const handleFilter = (key) => {
        setPersonalInfo((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const handleEnabled = (profile) => {
        if (!enabled) {
            setPersonalInfo(makeDefaultProfile(profile));
            setBackupPersonalInfo(makeDefaultProfile(profile));
        }
        setEnabled(!enabled);
    };

    const checkSameness = (objA, objB) => {
        return _.isEqual(objA, objB);
    };

    // useEffect(() => {
    //     if (!enabled) {
    //         setPersonalInfo(makeDefaultProfile());
    //         setBackupPersonalInfo(makeDefaultProfile());
    //         setValidFields(makeDefaultProfile('validation'));
    //     }
    // }, [enabled]);

    useEffect(() => {
        // console.log(personalInfo);
        setIsSame(checkSameness(personalInfo, backupPersonalInfo));
    }, [personalInfo, backupPersonalInfo]);

    useEffect(() => {
        setAllFieldsValid(Object.values(validFields).every((item) => item !== false));
    }, [validFields]);

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, tripLang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation, online }) => {
                // console.log(data);
                return data ? (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params} useBrowserHistory={true}></LayoutPageHeader>
                        <div className='h-body-w-header overflow-y-auto no-scrollbar flex flex-col justify-between'>
                            <LayoutPageBody paddingBottom='' minHeight={'min-h-body-w-header-1'}>
                                <div className={`select-none`}>
                                    <div className={classNames('flex flex-col bg-white rounded-t-3xl relative gap-4 ')}>
                                        <div className='px-vw-6 pb-vw-6'>
                                            <div className={classNames('flex justify-between items-center  mb-vw-4', rtl && 'flex-row-reverse')}>
                                                <h2
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                    className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                                >
                                                    {translations.data['e.profile.personalInfo.title']}
                                                </h2>
                                                <SwitchBtn enabled={enabled} setEnabled={() => handleEnabled(data)} />
                                            </div>
                                            <OfflineMode margins='' force={false} />
                                            <UserDisplay
                                                profile={data}
                                                noName
                                                // fullName
                                                phone
                                                email={data?.user?.email}
                                                gender
                                                nameClass='text-vw-sm md:text-base lg:text-sm'
                                                emailClass='text-vw-sm mt-vw-3'
                                                banned
                                            />

                                            <AvatarEditor avatar={enabled ? personalInfo?.avatar : data?.avatar} handleClear={handleClear} edit={enabled} />
                                            {enabled && (
                                                <BtnPill
                                                    type={'confirmed'}
                                                    justify={'justify-center'}
                                                    rtl={rtl}
                                                    params={[]}
                                                    handleClick={openUploader}
                                                    margins='mt-vw-6 mb-2'
                                                    disabled={!online}
                                                >
                                                    {translations?.data?.['e.verify.photo.select']}
                                                </BtnPill>
                                            )}
                                            <div className={classNames('flex flex-col gap-8', rtl && 'items-end')}>
                                                <LabelMaker
                                                    label='e.profile.personalInfo.firstName'
                                                    suffix='e.profile.personalInfo.required'
                                                    value={enabled ? personalInfo.firstName : data?.firstName}
                                                    edit={enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='firstName'
                                                    regex={regexString20}
                                                    valid={validFields.firstName}
                                                    disabled={!online || isModalAction}
                                                />
                                                <LabelMaker
                                                    label='e.profile.personalInfo.lastName'
                                                    suffix='e.profile.personalInfo.required'
                                                    value={enabled ? personalInfo.lastName : data?.lastName}
                                                    edit={enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='lastName'
                                                    regex={regexString20}
                                                    valid={validFields.lastName}
                                                    disabled={!online || isModalAction}
                                                />
                                                <LabelMaker
                                                    label='e.profile.personalInfo.dob'
                                                    suffix='e.profile.personalInfo.optional'
                                                    value={enabled ? personalInfo?.dob : transformDate(data.dob, 'from')}
                                                    edit={enabled}
                                                    type='number'
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='dob'
                                                    regex={regexDate}
                                                    valid={validFields.dob}
                                                    minTestLength={1}
                                                    placeholder={'23/04/2001'}
                                                    disabled={!online || isModalAction}
                                                />
                                                <LabelMaker
                                                    top={false}
                                                    label='e.profile.personalInfo.gender'
                                                    suffix='e.profile.personalInfo.recommended'
                                                    value={genderList.find((item) => item.id === (enabled ? personalInfo.gender : data.gender))}
                                                    edit={enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='gender'
                                                    list={genderList}
                                                    disabled={!online || isModalAction}
                                                />
                                                <LabelMaker
                                                    top={false}
                                                    label='e.profile.personalInfo.lang'
                                                    value={langList.find((item) => item.id === (enabled ? personalInfo.lang : data.lang))}
                                                    edit={enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='lang'
                                                    list={langList}
                                                    disabled={!online || isModalAction}
                                                />

                                                {/* <LabelMaker
                                                    top={false}
                                                    label='menu.admin.verify.filter.verifiedProfile'
                                                    value={yesNoNullList.find(
                                                        (item) => item.value === (enabled ? personalInfo.verifiedProfile : data.verifiedProfile)
                                                    )}
                                                    valueId='value'
                                                    edit={enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='verifiedProfile'
                                                    list={yesNoNullList}
                                                    disabled={!online || isModalAction}
                                                />
                                                <LabelMaker
                                                    top={false}
                                                    label='menu.admin.verify.filter.verifiedId'
                                                    value={yesNoNullList.find((item) => item.value === (enabled ? personalInfo.verifiedId : data.verifiedId))}
                                                    edit={enabled}
                                                    onChangeHandler={handlePersonalInfo}
                                                    valueId='value'
                                                    id='verifiedId'
                                                    list={yesNoNullList}
                                                    disabled={!online || isModalAction}
                                                />
                                                <LabelMaker
                                                    top={false}
                                                    label='menu.admin.verify.filter.verifiedCar'
                                                    value={yesNoNullList.find(
                                                        (item) => item.value === (enabled ? personalInfo.verifiedCarRegistration : data.verifiedCarRegistration)
                                                    )}
                                                    edit={enabled}
                                                    valueId='value'
                                                    onChangeHandler={handlePersonalInfo}
                                                    id='verifiedCarRegistration'
                                                    list={yesNoNullList}
                                                    disabled={!online || isModalAction}
                                                /> */}
                                            </div>
                                        </div>
                                        <div>
                                            <SwitchSection
                                                border='border-t border-b'
                                                icon={'User'}
                                                lock={!enabled}
                                                iconColorPreset={
                                                    enabled
                                                        ? personalInfo?.verifiedProfile
                                                            ? 'light-green'
                                                            : 'default'
                                                        : data?.verifiedProfile
                                                        ? 'light-green'
                                                        : 'default'
                                                }
                                                color='on'
                                                label='menu.admin.verify.filter.verifiedProfile'
                                                visible={true}
                                                val={enabled ? personalInfo.verifiedProfile : data.verifiedProfile}
                                                setVal={() => handleFilter('verifiedProfile')}
                                            />
                                            <SwitchSection
                                                icon={'Id2'}
                                                lock={!enabled}
                                                iconColorPreset={
                                                    enabled
                                                        ? personalInfo?.verifiedId
                                                            ? 'light-green'
                                                            : 'default'
                                                        : data?.verifiedId
                                                        ? 'light-green'
                                                        : 'default'
                                                }
                                                color='on'
                                                label='menu.admin.verify.filter.verifiedId'
                                                visible={true}
                                                val={enabled ? personalInfo?.verifiedId : data?.verifiedId}
                                                setVal={() => handleFilter('verifiedId')}
                                            />

                                            <SwitchSection
                                                icon={'Car1'}
                                                lock={!enabled}
                                                iconColorPreset={
                                                    enabled
                                                        ? personalInfo?.verifiedCarRegistration
                                                            ? 'light-green'
                                                            : 'default'
                                                        : data?.verifiedCarRegistration
                                                        ? 'light-green'
                                                        : 'default'
                                                }
                                                color='on'
                                                label='menu.admin.verify.filter.verifiedCar'
                                                visible={true}
                                                val={enabled ? personalInfo.verifiedCarRegistration : data.verifiedCarRegistration}
                                                setVal={() => handleFilter('verifiedCarRegistration')}
                                            />

                                            <SwitchSection
                                                border=''
                                                icon={'UserBlock'}
                                                lock={!enabled}
                                                iconColorPreset={
                                                    enabled
                                                        ? personalInfo?.blockUser
                                                            ? 'light-red'
                                                            : 'default'
                                                        : data?.user?.blockUser
                                                        ? 'light-red'
                                                        : 'default'
                                                }
                                                color='on'
                                                label='menu.admin.verify.filter.blockUser'
                                                visible={true}
                                                val={enabled ? personalInfo?.blockUser : data?.user?.blockUser}
                                                setVal={() => handleFilter('blockUser')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </LayoutPageBody>

                            <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 border-t border-gray-200`}>
                                <BtnContactDriver
                                    user
                                    whatsApp
                                    disabled={!online}
                                    params={[data?.mobile, data?.firstName, data?.lang, user?.profile?.firstName]}
                                    handleClick={handleWhatsApp}
                                />

                                {enabled && (
                                    <BtnLoadState
                                        disabled={isSame || !allFieldsValid || !online}
                                        spinner={isModalAction}
                                        handleClick={handleSave}
                                        params={[data?.user?.id, mutateData]}
                                        label='e.profile.personalInfo.save'
                                    />
                                )}
                            </div>
                        </div>
                        <Uploader multiple={false} maxImages={1} handleUpdate={handleImage} params={[mutateData]} widgetRef={widgetApi} verify={false} />
                    </>
                ) : null;
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loadUser,
            togglePublishSwitching,
            updatePublishTime,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageAdminUserEdit);
