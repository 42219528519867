import classNames from 'classnames';
import { useCycle } from 'framer-motion';

const btnStyles = {
    primary: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-white',
        bgColor: 'bg-bb-blue-550 ',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false,
        shadow: 'shadow-xl shadow-blue-300/50'
    }
};

const BtnInstall = ({
    dataInstall = 'Install Bala Benzine',
    dataInstalled = 'Installed',
    shadow,
    absolute,
    pulse,
    children,

    width = 'w-full',
    height = 'h-vw-14 md:h-14',
    gap = 'gap-2',
    type = 'primary',
    uppercase = true,
    tracking = 'tracking-wide',
    weight = 'font-medium',
    disabled,
    disabledClass = 'bg-gray-100 text-gray-300',

    color = 'text-white',
    bgColor = 'bg-bb-blue-550 ',
    noBg = false,

    tapClass = 'bg-gray-900 text-white scale-102a translate-y-0.5',
    rtl,
    textSize = !rtl ? 'text-vw-sm md:text-xs' : '',
    justify = 'justify-center',
    paddingX = 'px-vw-6 md:px-6',
    margins = 'mb-2 last:mb-0'
}) => {
    const [anim, cycleAnim] = useCycle('off', 'on');

    // const setupOrigin = () => {
    //     console.log('setup origin');
    // };
    return (
        <div
            className={classNames(margins, {
                absolute: absolute,
                [absolute]: absolute,
                [width]: !absolute
            })}
        >
            <button
                onTouchStart={() => !disabled && cycleAnim()}
                onTouchEnd={() => !disabled && cycleAnim()}
                // onClick={setupOrigin}
                data-icons='false'
                data-install={dataInstall}
                data-installed={dataInstalled}
                className={classNames(
                    'progressier-install-button',
                    'select-none flex items-center transform transition-all duration-100 focus:outline-none outline-none rounded-full ',
                    width,
                    weight,
                    height,
                    gap,
                    textSize,
                    justify,
                    paddingX,

                    {
                        [tracking]: !rtl,
                        [btnStyles[type]?.shadow]: shadow,
                        uppercase: uppercase,
                        'animate-pulse': pulse,
                        [color]: anim === 'off' && !disabled,
                        [bgColor]: anim === 'off' && !noBg && !disabled,

                        [tapClass]: anim === 'on' && !disabled,
                        'flex-row-reverse': rtl,
                        [disabledClass]: disabled
                    }
                )}
            ></button>
        </div>
    );
};
export default BtnInstall;
