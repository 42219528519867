import {
    LayoutPage,
    LayoutPageHeader,
    LayoutPageBody,
    LayoutPageSubHeader,
    LayoutPublishSeats,
    LayoutPublishDate,
    LayoutPublishTime,
    LayoutPublishLocation
} from 'components/layouts';
import { connect } from 'react-redux';
import CalendarHeader from 'components/calendar/CalendarHeader';

const PageProfilePasswordModal = ({ section }) => {
    const pageData = {
        page: `app.profile.password`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false
    };

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({
                rtl,
                lang,
                translations,
                location,
                animStates,
                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                handleNavigation
            }) => {
                return (
                    <>
                        <LayoutPageHeader
                            pageData={pageData}
                            pageParams={params}
                        />
                        <LayoutPageSubHeader
                            label={`e.page.publish.${section}.headline`}
                        ></LayoutPageSubHeader>
                        <LayoutPageBody>
                            <div className={`select-none px-6`}>
                                Password here
                            </div>
                        </LayoutPageBody>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search
});

export default connect(mapStateToProps, null)(PageProfilePasswordModal);
