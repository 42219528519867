import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import { mapTypeToSentence } from 'constants/structRoutes';

const styles = {
    BOOKING_PASSENGER_DRIVER_APPROVED: {
        color: 'text-emerald-600',
        bg: 'bg-emerald-100',
        border: 'border-emerald-300',
        icon: 'CheckDouble'
    },
    BOOKING_PASSENGER_ADMIN_APPROVED: {
        color: 'text-emerald-500',
        bg: 'bg-emerald-100',
        border: 'border-emerald-300',
        icon: 'CheckDouble'
    },
    BOOKING_PASSENGER_DRIVER_CANCELED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    BOOKING_PASSENGER_ADMIN_CANCELED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    BOOKING_PASSENGER_DRIVER_DECLINED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    BOOKING_PASSENGER_ADMIN_DECLINED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },

    BOOKING_DRIVER_PASSENGER_CANCELED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    BOOKING_DRIVER_PASSENGER_REQUESTED: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Time'
    },

    BOOKING_DRIVER_PASSENGER_REQUESTED: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Time'
    },
    ERROR: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Bug1'
    },

    REWARD: {
        color: 'text-yellow-500',
        bg: 'bg-yellow-50',
        border: 'border-yellow-500',
        icon: 'Reward1'
    },
    MESSAGE_RECEIVED: {
        color: 'text-bb-blue-500',
        bg: 'bg-bb-blue-50',
        border: 'border-bb-blue-500',
        icon: 'Messages1'
    },
    MESSAGE_SENT: {
        color: 'text-bb-blue-500',
        bg: 'bg-bb-blue-50',
        border: 'border-bb-blue-500',
        icon: 'Messages1'
    },
    VERIFICATION_PLATE_PENDING: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Car1',
        iconSize: '5.3vw'
    },

    VERIFICATION_PLATE_CANCELED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Car1',
        iconSize: '5.3vw'
    },
    VERIFICATION_PLATE_REJECTED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Car1',
        iconSize: '5.3vw'
    },
    VERIFICATION_PLATE_APPROVED: {
        color: 'text-emerald-500',
        bg: 'bg-emerald-100',
        border: 'border-emerald-300',
        icon: 'Car1',
        iconSize: '5.3vw'
    },
    VERIFICATION_ID_PENDING: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Id2',
        iconSize: '5.3vw'
    },

    VERIFICATION_ID_CANCELED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Id2',
        iconSize: '5.3vw'
    },
    VERIFICATION_ID_REJECTED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Id2',
        iconSize: '5.3vw'
    },
    VERIFICATION_ID_APPROVED: {
        color: 'text-emerald-500',
        bg: 'bg-emerald-100',
        border: 'border-emerald-300',
        icon: 'Id2',
        iconSize: '5.3vw'
    },
    VERIFICATION_PROFILE_APPROVED: {
        color: 'text-emerald-500',
        bg: 'bg-emerald-100',
        border: 'border-emerald-300',
        icon: 'Check',
        iconSize: '5.3vw'
    }
};

const StatusNotification = ({ threshold = 0, toReview, status = 'ERROR', noText, archive = false, icon, children, margins = 'mb-6a', seats }) => {
    const colorStatus = archive ? (toReview ? 'REVIEW' : 'ARCHIVED') : status;
    const tmpStatus = status.startsWith('REWARD') ? 'REWARD' : status;
    // console.log(status);
    return (
        <GetLang>
            {({ translations, lang, rtl }) => (
                <>
                    <div
                        className={classNames(
                            'h-vw-10 md:h-10 flex-none rounded-full flex items-center text-vw-sm md:text-sm font-bold relative',
                            rtl && 'flex-row-reverse',

                            styles[colorStatus]?.bg,
                            styles[colorStatus]?.color
                        )}
                    >
                        <div
                            className={classNames(
                                'h-vw-10 w-vw-10 md:h-10 md:w-10 rounded-full flex items-center justify-center flex-none',
                                styles[colorStatus]?.bg,
                                styles[colorStatus]?.color
                            )}
                        >
                            <Icons icon={styles[tmpStatus]?.icon} size={styles[tmpStatus]?.iconSize} />
                        </div>

                        <div className={classNames(rtl ? 'pl-4 pr-0' : 'pr-4 pl-0')}>{translations.data[mapTypeToSentence[tmpStatus]?.title]}</div>
                    </div>
                </>
            )}
        </GetLang>
    );
};

export default StatusNotification;
