import PageStaticTemplateV2 from './PageStaticTemplateV2';

const PageStaticNews = ({ overlay }) => {
    return (
        <PageStaticTemplateV2
            overlay={overlay}
            page='static.latest'
            apiUrl='/latest-news/latest-v2'
            icon='ShieldUser'
            iconColor='text-gray-500'
            keepLoginStep
            analyticsPage='Latest Updates'
        />
    );
};

export default PageStaticNews;
