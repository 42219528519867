import { useState } from 'react';
import classNames from 'classnames';
import { widthBiggerThan } from 'helpers/utils';
import BtnIcon from 'components/buttons/BtnIcon';
import Icons from 'components/icons';

const AvatarEditor = ({ avatar, handleClear, edit }) => {
    const [ready, setReady] = useState(false);

    return (
        <div className={'flex justify-center relative mt-vw-8 px-vw-6 mb-vw-6'}>
            {avatar ? (
                <>
                    <div className={classNames(!ready && 'hidden')}>
                        <div className={classNames('h-vh-36-a aspect-square overflow-hidden rounded-full')}>
                            <img src={avatar} onLoad={() => setReady(true)} className={classNames('object-cover w-full h-full')} />
                        </div>
                        {edit && (
                            <div className={classNames('absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2')}>
                                <BtnIcon handleClick={handleClear} icon={'Trash'} iconSize={widthBiggerThan() ? '20px' : '5.8vw'} />
                            </div>
                        )}
                    </div>
                    {!ready && (
                        <div
                            className={classNames(
                                'bg-bb-blue-50 text-bb-blue-550 h-vh-36-a w-full aspect-square overflow-hidden rounded-full  flex justify-center items-center'
                            )}
                        >
                            <Icons stroke={'0.75'} icon={'User'} size={'40vw'} />
                        </div>
                    )}
                </>
            ) : (
                <div
                    className={classNames(
                        'bg-bb-blue-50 text-bb-blue-550 h-vh-36-a w-full aspect-square overflow-hidden rounded-full  flex justify-center items-center'
                    )}
                >
                    <Icons stroke={'0.75'} icon={'User'} size={'40vw'} />
                </div>
            )}
        </div>
    );
};

export default AvatarEditor;
