import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LogoFullWhite } from 'components/svg/logo_full_white.svg';
import IconRound from 'components/blocks/IconRound';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import Paragraph from 'components/blocks/Paragraph';

import { setStaticPage } from 'store/actions/staticPages';

import GetLang from 'hoc/GetLang';
import { useEffect, useState } from 'react';
import { analytics } from 'helpers/segment';
import LottieBlock from 'components/blocks/LottieBlock';
import DesktopAlternate from 'components/blocks/DesktopAlternate';
import { useLocation, useSearchParams } from 'react-router-dom';
import InputShell from 'components/forms/InputShell';
import PhoneInput from 'components/forms/PhoneInput';

import useMyNavigation from 'hooks/useMyNavigation';
import useWindowSize from 'hooks/useWindowSize';
import BtnLoadState from 'components/buttons/BtnLoadState';
import { sendInstallLink } from 'fetch/apiRequests';
import { getSendLink, getUrlParams } from 'helpers/helperFunctions';

const PageSocialInstall = ({ globalState: { lang }, setStaticPage }) => {
    const location = useLocation();
    const [phone, setPhone] = useState('961');

    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { handleNavigation } = useMyNavigation();
    const { ratio } = useWindowSize();

    let [searchParams, setSearchParams] = useSearchParams();

    const handleSend = async () => {
        setIsLoading(true);

        const res = await sendInstallLink(phone, lang, getSendLink(getUrlParams(searchParams)));
        setIsLoading(false);
        if (res?.error) setError({ status: res?.status, statusText: res?.statusText });
        else handleNavigation('done', null);
    };

    useEffect(() => {
        analytics.page('Social Link Page');
    }, []);

    useEffect(() => {
        if (!isPhoneValid) {
            if (error) setError(null);
        }
    }, [isPhoneValid]);

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <>
                    <DesktopAlternate />
                    <div className={classNames('bg-bb-blue-900 flex flex-col justify-between min-h-screen-100 h-full bg-cover sm:hidden')}>
                        <div className={classNames('text-white text-vw-4xl md:text-4xl bg-bb-blue-900 relative')}>
                            <div className={classNames(ratio < 0.5 ? 'h-vh-28' : 'h-vh-24', 'w-full  md:h-128')}>
                                {/* <div className={classNames('w-full h-screen-1/3')}> */}
                                <div className='absolute inset-x-0 top-0 h-screen-2/5 overflow-hidden'>
                                    <LottieBlock speed={0.35} />
                                </div>
                                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 `}>
                                    <LogoFullWhite />
                                </div>
                            </div>
                        </div>

                        <div className={classNames('z-10 bg-white rounded-t-3xl')}>
                            <div className={classNames(ratio < 0.5 ? 'min-h-vh-72' : 'min-h-vh-72', 'relative h-full text-vw-base ')}>
                                <IconRound icon='Whatsapp' preset='yellow' />

                                <div
                                    className={classNames('px-vw-8 pt-vw-12 pb-vw-6 flex flex-col h-full justify-between gap-4')}
                                    style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                >
                                    <div>
                                        <HeadlineType1 label={translations.data['install.socialTitle']} textSize='text-vw-2xl' margins='mb-vw-4' />

                                        <div className='w-full'>
                                            <Paragraph preset='small-faded' margins='mb-vw-8' align='justify-left' className='static' color='text-gray-900'>
                                                <div
                                                    className={'static'}
                                                    dangerouslySetInnerHTML={{
                                                        __html: translations.data['install.socialBody']
                                                        // content.introBody
                                                    }}
                                                />
                                            </Paragraph>
                                        </div>
                                        <div style={{ direction: 'ltr' }}>
                                            <InputShell>
                                                <PhoneInput
                                                    value={phone}
                                                    handleUpdate={setPhone}
                                                    handleIsValid={setIsPhoneValid}
                                                    allowed={true}
                                                    disabled={isLoading}
                                                />
                                            </InputShell>

                                            <BtnLoadState
                                                disabled={!isPhoneValid}
                                                spinner={isLoading}
                                                handleClick={handleSend}
                                                // params={[mutateData]}
                                                label='install.socialButton'
                                            />
                                            {error ? (
                                                <div
                                                    className={classNames(
                                                        'bg-red-100 text-vw-base mt-vw-4 font-medium text-bb-red-400 rounded-lg py-vw-1 flex justify-center'
                                                    )}
                                                >
                                                    {`${error.status} - ${error.statusText}`}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className={classNames('w-full flex flex-col justify-center items-center')}>
                                        <div className='text-vw-sm static'>
                                            {`Copyright ${new Date().getFullYear()} - `}
                                            <strong>
                                                <u>
                                                    <a href='https://balabenzine.com' target='_blank'>
                                                        Bala Benzine
                                                    </a>
                                                </u>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div
                            className={classNames(
                                'fixed z-20 bottom-0 bg-white shadow-2xl-top shadow-bb-blue-800/20 gap-2 w-full flex flex-col justify-center items-center px-vw-8 py-vw-6'
                            )}
                        >
                            <InputShell>
                                <PhoneInput value={phone} handleUpdate={setPhone} handleIsValid={setIsPhoneValid} allowed={true} />
                            </InputShell>

                            <BtnPill
                                type='primary'
                                justify='justify-between'
                                shadow={isPhoneValid}
                                disabled={!isPhoneValid}
                                handleClick={handleSend}
                                // absolute='bottom-vw-8 inset-x-vw-10 '
                                tracking={!rtl && 'tracking-wide'}
                                rtl={rtl}
                            >
                                <span className='w-vw-6' />
                                {translations?.data?.['e.loginFlow.start.button']}
                                <Icons icon={'ArrowRightLong1'} flip={rtl} />
                            </BtnPill>

                            <div className='text-vw-sm static'>
                                {`Copyright ${new Date().getFullYear()} - `}
                                <strong>
                                    <u>
                                        <a href='https://balabenzine.com' target='_blank'>
                                            Bala Benzine
                                        </a>
                                    </u>
                                </strong>
                            </div>
                        </div> */}
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    staticPages: state.staticPages,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSocialInstall);
