import NavButton from 'components/blocks/NavButton';
import NavItem from 'components/blocks/NavItem';
import { translations } from 'constants/translations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLang, toggleNav } from 'store/actions/globalState';
import classNames from 'classnames';
import { routePath } from 'constants/structRoutes';
import { setChannelActive } from 'store/actions/chat';

const LayoutNav = ({
    setChannelActive,
    chat: { channelActive },
    pos = 'bottom-0 md:top-0 md:bottom-auto',
    toggleNav,
    globalState: { lang, navIsOpen, newNotifications },
    auth: { isAuthenticated, setupStep }
}) => {
    const rtl = translations?.[lang]?.rtl;
    const langFix = lang !== 'ar' ? 'pr-vw-4 pl-0 md:pr-4' : 'pl-vw-2 pr-vw-2 md:pl-2 md:pr-2';
    // console.log('layout nav rendered');
    return (
        <div
            className={classNames('overflow-hidden fixed inset-x-0 duration-300 ease-in-out-expo transform', pos, {
                'translate-y-0': !channelActive && setupStep === 'language',
                'translate-y-vw-20 md:translate-y-0': setupStep !== 'language' || channelActive
                // 'translate-y-vw-20 ': channelActive
            })}
            style={{ zIndex: 60 }}
        >
            <nav
                className={classNames(
                    'border-t1 border-gray-2001 flex bg-gray-50 h-vw-20 md:h-20 md:justify-between',

                    rtl && 'flex-row-reverse'
                )}
            >
                {lang && <NavButton toggleNav={toggleNav} navIsOpen={navIsOpen} notification={newNotifications} />}

                <ul
                    className={`md:hidden w-full flex ${classNames({
                        'flex-row-reverse': rtl
                    })} justify-between ${langFix} px-vw-4 md:px-4 md:justify-center`}
                >
                    <NavItem
                        id={0}
                        label={translations?.[lang]?.data?.['nav.find']}
                        icon='Find'
                        linkTo={routePath('app.searchCarSharing')}
                        handleClick={toggleNav}
                        delay={0}
                        animationState={isAuthenticated}
                        rtl={rtl}
                        toggleNav={toggleNav}
                        navIsOpen={navIsOpen}
                    />
                    <NavItem
                        id={1}
                        label={translations?.[lang]?.data?.['nav.rides']}
                        icon='Ride1'
                        linkTo={routePath('app.rides')}
                        activeArray={[routePath('app.rides'), routePath('app.rides.booked'), routePath('app.rides.offered')]}
                        handleClick={toggleNav}
                        delay={1}
                        animationState={isAuthenticated}
                        rtl={rtl}
                        toggleNav={toggleNav}
                        navIsOpen={navIsOpen}
                    />
                    <NavItem
                        id={2}
                        label={translations?.[lang]?.data?.['nav.publish']}
                        icon='PublishPlus'
                        linkTo={routePath('app.offerSeats')}
                        handleClick={toggleNav}
                        delay={2}
                        animationState={isAuthenticated}
                        rtl={rtl}
                        toggleNav={toggleNav}
                        navIsOpen={navIsOpen}
                    />

                    <NavItem
                        id={3}
                        label={translations?.[lang]?.data?.['nav.messages']}
                        icon='Messages1'
                        linkTo={routePath('app.messages')}
                        handleClick={toggleNav}
                        delay={3}
                        animationState={isAuthenticated}
                        // notification={newNotifications}
                        rtl={rtl}
                        toggleNav={toggleNav}
                        navIsOpen={navIsOpen}
                    />
                    {/* <NavItem
                        id={4}
                        // label={' '}
                        label={translations?.[lang]?.data?.['nav.news']}
                        icon='Notification'
                        linkTo={routePath('app.notifications')}
                        handleClick={toggleNav}
                        delay={3}
                        animationState={isAuthenticated}
                        notification={newNotifications}
                        rtl={rtl}
                    /> */}
                </ul>

                <ul
                    className={`hidden md:flex ${classNames({
                        'flex-row-reverse': rtl
                    })} justify-between ${langFix} px-vw-4 md:px-4 md:justify-between md:gap-4 lg:gap-8 xl:gap-12`}
                >
                    <NavItem
                        id={0}
                        label={translations?.[lang]?.data?.['nav.find']}
                        icon='Find'
                        linkTo={routePath('app.searchCarSharing')}
                        handleClick={toggleNav}
                        delay={0}
                        animationState={isAuthenticated}
                        rtl={rtl}
                    />
                    <NavItem
                        id={1}
                        label={translations?.[lang]?.data?.['nav.rides']}
                        icon='Ride1'
                        linkTo={routePath('app.rides')}
                        activeArray={[routePath('app.rides'), routePath('app.rides.booked'), routePath('app.rides.offered')]}
                        handleClick={toggleNav}
                        delay={1}
                        animationState={isAuthenticated}
                        rtl={rtl}
                    />
                    <NavItem
                        id={1}
                        label={translations?.[lang]?.data?.['nav.rides']}
                        icon='Ride1'
                        linkTo={routePath('app.rides')}
                        activeArray={[routePath('app.rides'), routePath('app.rides.booked'), routePath('app.rides.offered')]}
                        handleClick={toggleNav}
                        delay={1}
                        animationState={isAuthenticated}
                        rtl={rtl}
                    />
                    <NavItem
                        id={2}
                        label={translations?.[lang]?.data?.['nav.publish']}
                        icon='PublishPlus'
                        linkTo={routePath('app.offerSeats')}
                        handleClick={toggleNav}
                        delay={2}
                        animationState={isAuthenticated}
                        rtl={rtl}
                    />
                </ul>
                <ul
                    className={`hidden md:flex ${classNames({
                        'flex-row-reverse': rtl
                    })} justify-between ${langFix} px-vw-4 md:px-4 md:justify-between md:gap-4 lg:gap-4 xl:gap-8`}
                >
                    <NavItem
                        id={3}
                        label={translations?.[lang]?.data?.['nav.messages']}
                        icon='Messages1'
                        linkTo={routePath('app.messages')}
                        handleClick={toggleNav}
                        delay={3}
                        animationState={isAuthenticated}
                        rtl={rtl}
                    />
                    <NavItem
                        id={4}
                        // label={' '}
                        label={translations?.[lang]?.data?.['nav.news']}
                        icon='Notification'
                        linkTo={routePath('app.notifications')}
                        handleClick={toggleNav}
                        delay={3}
                        animationState={isAuthenticated}
                        notification={newNotifications}
                        rtl={rtl}
                    />
                </ul>
            </nav>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    chat: state.chat
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLang,
            toggleNav,
            setChannelActive
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutNav);
