import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';
import { BtnRequestCancel, BtnContactDriver } from 'components/buttons';

import Icons from 'components/icons/index';
import AvatarFull from 'components/blocks/AvatarFull';
import Itinerary from 'components/blocks/Itinerary';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations } from 'constants/animations';
import { motion, LayoutGroup } from 'framer-motion';
import StatusBooked from 'components/blocks/StatusBooked';
import ModalBottom from 'components/modals/ModalBottom';
import { useState } from 'react';

import { updateRideStatus, updateBooking, updateRideBookingAvailability, updateRideLadiesOnly } from 'fetch/apiRequests';
import StatusOffered from 'components/blocks/StatusOffered';
import BtnPill from 'components/buttons/BtnPill';
import classNames from 'classnames';

import { useEffect } from 'react';
import useMyNavigation from 'hooks/useMyNavigation';
import BtnIcon from 'components/buttons/BtnIcon';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getPresetByStatus, getRecurringDay } from 'helpers/helperFunctions';
import OfflineMode from 'components/blocks/OfflineMode';
import CardSection from 'components/cards/CardSection';
import CardUserBis from 'components/cards/CardUserBis';
import BtnMiddleAction from 'components/buttons/BtnMiddleAction';
import MessageBox from 'components/blocks/MessageBox';
import SwitchBtn from 'components/blocks/SwitchBtn';
import { isDateTimeInPast } from 'helpers/dates';
import SwitchSection from 'components/blocks/SwitchSection';
import { analytics } from 'helpers/segment';

const PageRideOffer = ({
    onboarding: { tooltips },
    auth: {
        user,
        user: { profile },
        isAuthenticated
    }
}) => {
    const pageData = {
        page: 'app.rides.offer',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/rides/published',
        backBtn: true,
        zIndex: 43,
        checkIdParam: true
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);
    const [paBooking, setPaBooking] = useState(null);
    const [isPassengerModalAction, setIsPassengerModalAction] = useState(false);
    const [enabled, setEnabled] = useState(false);

    const handlePassengerModal = (clickedBooking) => {
        setMode('EDIT_PASSENGER');
        setPaBooking(clickedBooking);
    };

    useEffect(() => {
        // update enabled part of ride
    }, [enabled]);

    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    useEffect(() => {
        analytics.page('Published Ride', 'App', isAuthenticated);
    }, []);

    const { handleNavigation, location } = useMyNavigation();

    const handleModalClose = () => {
        setPaBooking(null);
        setMode(null);
        setIsModal(false);
    };

    const handleAvailability = async (rideId, mutateData, doNotAcceptBooking) => {
        setIsModalAction(true);
        await updateRideBookingAvailability({ rideId, doNotAcceptBooking });
        await mutateData();
        setIsModalAction(false);
        analytics.track('Restrict Booking Toggle', { doNotAcceptBooking }, isAuthenticated);
    };

    const handleLadiesOnly = async (rideId, mutateData, ladiesOnly, bookingsLength) => {
        if (!bookingsLength) {
            setIsModalAction(true);
            await updateRideLadiesOnly({ rideId, ladiesOnly });
            await mutateData();
            setIsModalAction(false);
            analytics.track('Ladies-Only Edit Ride Toggle', { ladiesOnly }, isAuthenticated);
        }
    };

    const handleAction = async (rideId, mutateData, status) => {
        setIsModalAction(true);
        setIsModal(false);
        await updateRideStatus({ rideId, status });
        await mutateData();
        setIsModalAction(false);
        if (status === 'DELETE') handleNavigation('app.rides.offered', null);
    };

    const handleActionPassenger = async (status, mutateData) => {
        setIsModalAction(true);
        handleModalClose();
        await updateBooking({ bookingId: paBooking._id, status });
        await mutateData();
        setIsModalAction(false);
    };

    const handleContact = (id) => {
        analytics.track('Chat Driver => Passenger (Published)', null, isAuthenticated);
        handleNavigation('app.messages', { otherUser: id });
    };

    const handleUserProfile = (id) => {
        handleNavigation('app.user', { id });
    };

    const getSwipeMenuOptions = (userId, blocked) => {
        const options = [
            {
                icon: 'Messages1',
                handleClick: handleContact,
                params: [userId]
            },
            {
                icon: 'User',
                handleClick: handleUserProfile,
                params: [userId]
            }
        ];
        return blocked ? options.slice(1) : options;
    };

    const shouldArchive = (dateTime, archive, recurring) => {
        return recurring ? archive : isDateTimeInPast(dateTime) ? true : archive;
    };

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidParams, data, isValidating, mutateData, translations, handleNavigation, online }) => {
                // console.log('data is', data);
                // console.log('params', params);
                const bookedPassengers = data?.bookings.filter((booking) => booking.status !== 'PENDING');
                const archive = shouldArchive(data?.dateTime, data?.archive, data?.recurring);

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            {data?.status && data?.status !== 'PUBLISHED' && !isModalAction && (
                                <div
                                    className={classNames('flex items-center', {
                                        'flex-row-reverse': rtl
                                    })}
                                >
                                    {!archive && (
                                        <BtnIcon disabled={!online} icon='Archive' iconSize={'5.8vw'} noBg handleClick={setMode} params={['ARCHIVE_RIDE']} />
                                    )}

                                    <BtnIcon disabled={!online} icon='Trash' iconSize={'5.8vw'} noBg handleClick={setMode} params={['DELETE_RIDE']} />
                                </div>
                            )}
                        </LayoutPageHeader>

                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <>
                                <LayoutPageBody
                                    minHeight=''
                                    // minHeight={getBodyHeight(
                                    //     data?.status,
                                    //     'ride.offer',
                                    //     data?.bookings?.length
                                    // )}
                                >
                                    <motion.div className='' layout='position' key='publishedTop'>
                                        {data && !isModalAction ? (
                                            <motion.div
                                                key='rideOffer'
                                                initial={'in'}
                                                animate={'in'}
                                                variants={animations.elementSlides}
                                                className={`list-none w-full bg-white rounded-3xl relative`}
                                            >
                                                <StatusOffered
                                                    status={data?.status}
                                                    square
                                                    archive={archive}
                                                    ladiesOnly={data?.ladiesOnly}
                                                    recurring={data?.recurring}
                                                />
                                                <OfflineMode margins='mt-6' />

                                                <div className={`mt-vw-6 px-vw-6 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                                                    <Pill
                                                        icon={data?.recurring ? 'Repeat1' : 'Calendar2'}
                                                        colorPreset={getPresetByStatus(data?.status, archive, data?.recurring)}
                                                    >
                                                        <FormattedDateTime
                                                            dateTime={data?.dateTime}
                                                            date
                                                            dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null}
                                                        />
                                                    </Pill>

                                                    <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                                                        <FormattedDateTime dateTime={data?.dateTime} time />
                                                    </Pill>
                                                </div>
                                                <Itinerary data={data} archive={archive} />

                                                {data.status === 'PUBLISHED' && (
                                                    <div className={classNames('w-full border-t-8 border-gray-100 flex flex-col', { 'items-end': rtl })}>
                                                        <div className='px-vw-6 py-vw-6'>
                                                            {archive ? (
                                                                <Pill
                                                                    sizePreset='small'
                                                                    margins=''
                                                                    icon='Users'
                                                                    bgColor=''
                                                                    border='ring-2'
                                                                    borderColor='ring-gray-700'
                                                                    textColor='text-gray-700'
                                                                    textTransform=' font-semibold'
                                                                    colorPreset='light-gray'
                                                                >
                                                                    {translations?.data?.['e.booking.bookings']}
                                                                </Pill>
                                                            ) : (
                                                                <Pill
                                                                    sizePreset='small'
                                                                    margins=''
                                                                    icon='Users'
                                                                    bgColor='bg-bb-blue-50'
                                                                    textColor='text-bb-blue-500'
                                                                    textTransform=' font-semibold'
                                                                    colorPreset='light-gray'
                                                                >
                                                                    {translations?.data?.['e.booking.bookings']}
                                                                </Pill>
                                                            )}
                                                        </div>

                                                        {archive ? (
                                                            bookedPassengers?.length > 0 ? (
                                                                <ul>
                                                                    {bookedPassengers.map((booking) => {
                                                                        return (
                                                                            <CardSection
                                                                                key={booking._id}
                                                                                border='border-t'
                                                                                paddingY='py-vw-4'
                                                                                swipeMenuOptions={getSwipeMenuOptions(booking.passengerId._id)}
                                                                                arrow={false}
                                                                            >
                                                                                <CardUserBis archive={true} booking={booking} />
                                                                            </CardSection>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            ) : (
                                                                <div className={classNames('px-vw-10 text-gray-600 font-semibold')}>
                                                                    {translations?.data['e.noPassengersOnRide.past']}
                                                                </div>
                                                            )
                                                        ) : data?.bookings?.length > 0 ? (
                                                            <>
                                                                <div>
                                                                    {tooltips?.manageBookings !== false && (
                                                                        <MessageBox noTitle id='manageBookings' margins='-mt-vw-2 mb-vw-4' label=''>
                                                                            {translations?.data?.['e.tooltip.acceptUsers']}
                                                                        </MessageBox>
                                                                    )}
                                                                </div>

                                                                <ul>
                                                                    {data?.bookings.map((booking) => {
                                                                        return (
                                                                            <CardSection
                                                                                key={booking._id}
                                                                                border='first:border-t border-b'
                                                                                paddingY='py-vw-4'
                                                                                archive={archive}
                                                                                swipeMenuOptions={getSwipeMenuOptions(
                                                                                    booking?.passengerId?._id,
                                                                                    booking?.passengerId?.blockUser
                                                                                )}
                                                                                handleClick={() => handlePassengerModal(booking)}
                                                                                arrow={false}
                                                                            >
                                                                                <CardUserBis archive={archive} booking={booking} showReviews={false} />
                                                                            </CardSection>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </>
                                                        ) : (
                                                            <div
                                                                className={classNames(
                                                                    'px-vw-10 text-gray-600 font-semibold',
                                                                    !archive && ' border-b border-gray-200 pb-vw-4'
                                                                )}
                                                            >
                                                                {translations?.data[archive ? 'e.noPassengersOnRide.past' : 'e.noPassengersOnRide']}
                                                            </div>
                                                        )}

                                                        <SwitchSection
                                                            icon={'Plane1'}
                                                            iconColorPreset={data?.doNotAcceptBooking ? 'default' : 'light-green'}
                                                            color='on'
                                                            label='e.ride.acceptBookings'
                                                            visible={!archive}
                                                            val={!data?.doNotAcceptBooking}
                                                            setVal={() => handleAvailability(data?.id, mutateData, !data?.doNotAcceptBooking)}
                                                        />
                                                        <SwitchSection
                                                            icon={'Female1'}
                                                            iconColorPreset={data?.ladiesOnly ? 'light-pink' : 'default'}
                                                            lock={data?.bookings?.length}
                                                            color={data?.ladiesOnly && !data?.bookings?.length ? 'on' : 'locked'}
                                                            label='e.ride.ladiesOnly'
                                                            visible={profile?.verifiedProfile && profile?.gender === 'female'}
                                                            val={data?.ladiesOnly}
                                                            setVal={() => handleLadiesOnly(data?.id, mutateData, !data?.ladiesOnly, data?.bookings?.length)}
                                                        />
                                                    </div>
                                                )}
                                            </motion.div>
                                        ) : (
                                            <CardSkeleton rtl={rtl} type='booking' />
                                        )}
                                    </motion.div>
                                </LayoutPageBody>

                                {data?.status !== 'CANCELED' && !isModalAction && !archive && (
                                    <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 border-t-a border-gray-200-a`}>
                                        <>
                                            {data?.status === 'PUBLISHED' && (
                                                <BtnMiddleAction
                                                    type='cancel-middle'
                                                    icon='CloseCircle'
                                                    disabled={!online}
                                                    label='e.cancelRide'
                                                    handleClick={setMode}
                                                    params={['CANCEL_RIDE']}
                                                />
                                                // <BtnRequestCancel middleAction disabled={!online} handleClick={() => setMode('CANCEL_RIDE')}>
                                                //     {translations?.data?.['e.cancelRide']}
                                                // </BtnRequestCancel>
                                            )}
                                            {(data?.status === 'CANCELED' || data?.status === 'DRAFT') && (
                                                <BtnMiddleAction
                                                    label='e.ride.status.setToPublish'
                                                    handleClick={setMode}
                                                    params={['PUBLISH_RIDE']}
                                                    disabled={!online}
                                                    icon='UploadCloud'
                                                />
                                                // <BtnPill
                                                //     disabled={!online}
                                                //     type='primary'
                                                //     justify={'justify-between'}
                                                //     rtl={rtl}
                                                //     params={[false]}
                                                //     handleClick={() => setMode('PUBLISH_RIDE')}
                                                // >
                                                //     <>
                                                //         <span className='w-6' />
                                                //         {translations.data['e.ride.status.setToPublish']}
                                                //         <Icons icon={'UploadCloud'} size={'5.8vw'} flip={rtl} />
                                                //     </>
                                                // </BtnPill>
                                            )}

                                            {data?.status === 'PUBLISHED' && data?.bookings?.length === 0 && (
                                                <BtnMiddleAction
                                                    label='e.ride.status.setToDraft'
                                                    handleClick={setMode}
                                                    params={['DRAFT_RIDE']}
                                                    disabled={!online}
                                                    icon='Unpublish'
                                                    iconSize={'6.4vw'}
                                                />
                                                // <BtnPill
                                                //     disabled={!online}
                                                //     type='confirmed'
                                                //     justify={'justify-between'}
                                                //     rtl={rtl}
                                                //     params={[false]}
                                                //     handleClick={() => setMode('DRAFT_RIDE')}
                                                // >
                                                //     <>
                                                //         <span className='w-6' />
                                                //         {translations.data['e.ride.status.setToDraft']}
                                                //         <Icons icon={'Draft2'} size={28} flip={rtl} />
                                                //     </>
                                                // </BtnPill>
                                            )}
                                        </>
                                    </div>
                                )}
                            </>
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height={getModalHeight(lang)}
                        >
                            {!paBooking && (
                                <div className='px-vw-6 py-vw-6 flex flex-col items-center '>
                                    <OfflineMode margins='' />
                                    {mode === 'CANCEL_RIDE' && (
                                        <>
                                            <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                                {translations?.data?.['e.cancelRide.headline']}
                                            </div>
                                            <BtnRequestCancel disabled={!online} handleClick={() => handleAction(data?.id, mutateData, 'CANCELED')}>
                                                {translations?.data?.['e.cancelRide']}
                                            </BtnRequestCancel>
                                            <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                                {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                            </BtnPill>
                                        </>
                                    )}
                                    {mode === 'PUBLISH_RIDE' && (
                                        <>
                                            <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                                {translations?.data?.['e.ride.status.setToPublish.headline']}
                                            </div>
                                            <BtnPill
                                                disabled={!online}
                                                type='primary'
                                                justify={'justify-between'}
                                                rtl={rtl}
                                                params={[false]}
                                                handleClick={() => handleAction(data?.id, mutateData, 'PUBLISHED')}
                                            >
                                                <>
                                                    <span className='w-6' />
                                                    {translations?.data?.['e.ride.status.setToPublish']}
                                                    <Icons icon={'UploadCloud'} size={'5.8vw'} flip={rtl} />
                                                </>
                                            </BtnPill>
                                            <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                                {translations?.data?.['e.later']}
                                            </BtnPill>
                                        </>
                                    )}
                                    {mode === 'DELETE_RIDE' && (
                                        <>
                                            <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                                {translations?.data?.['e.ride.status.delete.headline']}
                                            </div>
                                            <BtnPill
                                                disabled={!online}
                                                type='cancel'
                                                justify={'justify-between'}
                                                rtl={rtl}
                                                params={[false]}
                                                handleClick={() => handleAction(data?.id, mutateData, 'DELETE')}
                                            >
                                                <>
                                                    <span className='w-6' />
                                                    {translations?.data?.['e.ride.status.delete']}
                                                    <Icons icon={'Trash'} size={'5.8vw'} />
                                                </>
                                            </BtnPill>
                                            <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                                {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                            </BtnPill>
                                        </>
                                    )}
                                    {mode === 'DRAFT_RIDE' && (
                                        <>
                                            <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                                {translations?.data?.['e.ride.status.setToDraft.headline']}
                                            </div>
                                            <BtnPill
                                                disabled={!online}
                                                type='primary'
                                                justify={'justify-between'}
                                                rtl={rtl}
                                                params={[false]}
                                                handleClick={() => handleAction(data?.id, mutateData, 'DRAFT')}
                                            >
                                                <>
                                                    <span className='w-6' />
                                                    {translations?.data?.['e.ride.status.setToDraft']}
                                                    <Icons icon={'Draft2'} size={'5.8vw'} />
                                                </>
                                            </BtnPill>
                                            <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                                {translations?.data?.['e.later']}
                                            </BtnPill>
                                        </>
                                    )}
                                    {mode === 'ARCHIVE_RIDE' && (
                                        <>
                                            <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                                {translations?.data?.['e.ride.status.archive.headline']}
                                            </div>
                                            <BtnPill
                                                disabled={!online}
                                                type='primary'
                                                justify={'justify-between'}
                                                rtl={rtl}
                                                params={[false]}
                                                handleClick={() => handleAction(data?.id, mutateData, 'ARCHIVE')}
                                            >
                                                <>
                                                    <span className='w-6' />
                                                    {translations?.data?.['e.ride.status.archive']}
                                                    <Icons icon={'Archive'} size={'5.8vw'} />
                                                </>
                                            </BtnPill>
                                            <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                                {translations?.data?.['e.later']}
                                            </BtnPill>
                                        </>
                                    )}
                                </div>
                            )}
                            {paBooking && (
                                <div className='pb-vw-6 flex flex-col items-center'>
                                    <AvatarFull
                                        className='w-full px-vw-6 py-vw-6'
                                        profile={paBooking?.passengerId?.profile}
                                        blocked={paBooking?.passengerId?.blockUser}
                                    />

                                    <StatusBooked status={paBooking?.status} margins='' height='h-vw-16' />
                                    <OfflineMode margins='pt-4' />
                                    <div className={classNames('mt-vw-6 flex flex-col items-center w-full px-vw-6')}>
                                        {paBooking?.status === 'PENDING' && (
                                            <>
                                                <BtnPill
                                                    disabled={!online}
                                                    type='primary'
                                                    justify={'justify-between'}
                                                    rtl={rtl}
                                                    params={[false]}
                                                    handleClick={() => handleActionPassenger('APPROVED_BY_DRIVER', mutateData)}
                                                >
                                                    <>
                                                        <span className='w-6' />
                                                        {translations?.data?.['e.approveRequest']}
                                                        <Icons icon={'Check'} size={28} flip={rtl} />
                                                    </>
                                                </BtnPill>
                                                <BtnRequestCancel
                                                    disabled={!online}
                                                    handleClick={() => handleActionPassenger('DECLINED_BY_DRIVER', mutateData)}
                                                >
                                                    {translations?.data?.['e.declineRequest']}
                                                </BtnRequestCancel>
                                            </>
                                        )}
                                        {paBooking?.status.startsWith('APPROVED_BY') && (
                                            <BtnRequestCancel disabled={!online} handleClick={() => handleActionPassenger('CANCELED_BY_DRIVER', mutateData)}>
                                                {translations?.data?.['e.cancelBooking']}
                                            </BtnRequestCancel>
                                        )}

                                        <BtnContactDriver
                                            params={[paBooking?.passengerId?.id]}
                                            handleClick={handleContact}
                                            passenger
                                            disabled={!online || paBooking?.passengerId?.blockUser}
                                        />
                                    </div>
                                </div>
                            )}
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    onboarding: state.onboarding,
    auth: state.auth
});

export default connect(mapStateToProps, null)(PageRideOffer);
