import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import IconRound from 'components/blocks/IconRound';
import Paragraph from 'components/blocks/Paragraph';
import BtnPill from 'components/buttons/BtnPill';
import InputShell from 'components/forms/InputShell';
import PhoneInput from 'components/forms/PhoneInput';

import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';

import { useState, useEffect } from 'react';
import { verifyUser, clearLoginErrors, loginSetStep } from 'store/actions/auth';
import { Link, useNavigate } from 'react-router-dom';
import { routePath } from 'constants/structRoutes';

import { motion, AnimatePresence, useCycle } from 'framer-motion';

import { animations, getAnimStates, getReducedMotion } from 'constants/animations';
import { PageStaticTerms, PageStaticPrivacy } from 'pages/index';
import { setStaticPage } from 'store/actions/staticPages';

const LoginFlowStart = ({
    allowed,
    setStaticPage,
    loginSetStep,
    clearLoginErrors,
    auth: { loading, error },
    verifyUser,
    globalState: { reducedMotion },
    staticPages: { page }
}) => {
    const [phone, setPhone] = useState('961');
    const [popupPage, setPopupPage] = useState(null);
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const handleVerifyUser = (lang) => {
        verifyUser(phone, lang);
    };

    const animStates = {
        default: getAnimStates('app.settings').animPages
    };

    useEffect(() => {
        if (error?.length > 0) {
            clearLoginErrors();
        }
    }, [phone]);

    useEffect(() => {
        if (!error) loginSetStep('start');
    }, [error]);

    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <>
                    <div className='relative h-full text-vw-base'>
                        {!loading && <IconRound icon='User' preset='yellow' />}
                        <div className={classNames('py-vw-12')}>
                            <HeadlineType1 label='e.loginFlow.start.title' />

                            <div className={classNames('px-vw-10 flex flex-col items-center')}>
                                <Paragraph>{translations?.data?.['e.loginFlow.start.subtitle']}</Paragraph>
                                <InputShell>
                                    <PhoneInput value={phone} handleUpdate={setPhone} handleIsValid={setIsPhoneValid} allowed={allowed} />
                                </InputShell>
                                <Paragraph preset='small-faded' margins='mt-vw-2 mb-vw-4'>
                                    {translations?.data?.['e.loginFlow.start.note']}
                                </Paragraph>
                                {/* <div className={classNames('text-vw-xs text-gray-500 font-medium')}>
                                    By signing up and creating an account, you agree to the Terms of Service and Privacy Policy of Bala Benzine.
                                </div> */}
                                <div className='mb-vw-2 items-start relative flex flex-col py-vw-4 rounded-2xl text-vw-sm font-medium text-yellow-700 bg-yellow-100'>
                                    {lang === 'en' && (
                                        <div
                                            style={{
                                                direction: rtl ? 'rtl' : 'ltr'
                                            }}
                                            className='px-vw-6 text-vw-sm inline'
                                        >
                                            By signing up and creating an account, you agree to the{' '}
                                            <span onClick={() => setStaticPage('terms')} className='font-bold underline inline'>
                                                Terms of Service
                                            </span>{' '}
                                            and{' '}
                                            <span onClick={() => setStaticPage('privacy')} className='font-bold underline inline'>
                                                Privacy Policy
                                            </span>{' '}
                                            of Bala Benzine.
                                        </div>
                                    )}
                                    {lang === 'fr' && (
                                        <div
                                            style={{
                                                direction: rtl ? 'rtl' : 'ltr'
                                            }}
                                            className='px-vw-6 text-vw-sm inline'
                                        >
                                            En créant un compte, vous acceptez les{' '}
                                            <span onClick={() => setStaticPage('terms')} className='font-bold underline inline'>
                                                termes et conditions
                                            </span>{' '}
                                            et la{' '}
                                            <span onClick={() => setStaticPage('privacy')} className='font-bold underline inline'>
                                                politique de confidentialité
                                            </span>{' '}
                                            de Bala Benzine.
                                        </div>
                                    )}
                                    {lang === 'ar' && (
                                        <div
                                            style={{
                                                direction: rtl ? 'rtl' : 'ltr'
                                            }}
                                            className='px-vw-6 text-vw-sm inline'
                                        >
                                            من خلال التسجيل وإنشاء حساب ، فإنك توافق على{' '}
                                            <span onClick={() => setStaticPage('terms')} className='font-bold underline inline'>
                                                الشروط والأحكام
                                            </span>{' '}
                                            و
                                            <span onClick={() => setStaticPage('privacy')} className='font-bold underline inline'>
                                                سياسة الخصوصية الخاصة
                                            </span>{' '}
                                            ل-bala benzine
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!error && (
                            <BtnPill
                                type='primary'
                                justify='justify-between'
                                shadow={isPhoneValid}
                                disabled={!isPhoneValid}
                                handleClick={() => handleVerifyUser(lang)}
                                absolute='bottom-vw-8 inset-x-vw-10 '
                                tracking={!rtl && 'tracking-wide'}
                                rtl={rtl}
                            >
                                <span className='w-vw-6' />
                                {translations?.data?.['e.loginFlow.start.button']}
                                <Icons icon={'ArrowRightLong1'} flip={rtl} />
                            </BtnPill>
                        )}
                        {error?.length > 0 && (
                            <motion.div
                                key='loginStartError'
                                initial={'bottomFast'}
                                animate={'delayedIn'}
                                variants={animations.pageSlides}
                                className={classNames(
                                    'h-full-a absolute inset-x-vw-10 bottom-0 rounded-t-2xl bg-red-50  text-bb-red-400 text-vw-sm font-medium text-center'
                                )}
                            >
                                <div className='relative px-vw-6 pt-vw-8 pb-vw-6'>
                                    <IconRound icon='Close' preset='red' presetSize='small' />

                                    {translations?.data?.[error[0]] ?? error[0]}
                                </div>
                            </motion.div>
                        )}
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    staticPages: state.staticPages,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearLoginErrors,
            loginSetStep,
            verifyUser,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlowStart);
