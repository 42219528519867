import classNames from 'classnames';
import Icons from 'components/icons/index';
import { animations } from 'constants/animations';
import { motion, AnimatePresence } from 'framer-motion';

const ModalTop = ({ children, handleOpen, isOpen, height, aKey = 'req', move, rounded = 'rounded-b-3xl' }) => {
    // console.log('isOpen', isOpen);
    return (
        <AnimatePresence>
            {isOpen && (
                <div className={classNames('fixed top-vw-16 md:top-20 bottom-0 inset-x-0 z-100 select-none', move)}>
                    <motion.div
                        onClick={() => handleOpen(false)}
                        key={aKey + '_bg'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={classNames('fixed inset-x-0 top-0 bottom-0 bg-gray-800/30 ')}
                    />
                    <motion.div
                        key={aKey + '_fg'}
                        initial={'top'}
                        animate={'in'}
                        exit={'top'}
                        variants={animations.pageSlides}
                        className={classNames(
                            rounded,
                            'fixed inset-x-0 top-0 md:left-auto md:w-96 bg-white border border-gray-200 text-vw-base md:text-base transition-height duration-400 ease-in-out overflow-hidden',
                            height
                        )}
                    >
                        {children}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default ModalTop;
