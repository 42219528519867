import * as types from 'store/actions/types';

export function googleMapsInitialised() {
    return (dispatch) => {
        dispatch({
            type: types.GOOGLE_MAP_INITIALISED
        });
    };
}

export function googleMapsInitialisedFailed() {
    return (dispatch) => {
        dispatch({
            type: types.GOOGLE_MAP_INITIALISED_FAILED
        });
    };
}
