import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { connect } from 'react-redux';

import { CardOffered } from 'components/cards';
import CardSkeleton from 'components/skeletons/CardSkeleton';

import classNames from 'classnames';

import StatusOffered from 'components/blocks/StatusOffered';
import Cloner from 'components/blocks/Cloner';
import OfflineMode from 'components/blocks/OfflineMode';
import { motion, LayoutGroup } from 'framer-motion';
import { animations, transitions, animStates2 } from 'constants/animations';
import MessageBox from 'components/blocks/MessageBox';
import NoResults from 'components/blocks/NoResults';

import Icons from 'components/icons/index';
import BtnPill from 'components/buttons/BtnPill';
import BtnLoadState from 'components/buttons/BtnLoadState';
import ModalBottom from 'components/modals/ModalBottom';
import BtnRequestCancel from 'components/buttons/BtnRequestCancel';
import { useState, useEffect, useRef } from 'react';
import { updateRideStatus } from 'fetch/apiRequests';
import useScroll from 'hooks/useScroll';
import BtnScroll from 'components/buttons/BtnScroll';
import { analytics } from 'helpers/segment';

const pageData = {
    page: 'app.rides.offered.archived',
    pageRedirect: 'app.searchCarSharing',
    apiUrl: '/rides/archived',
    backBtn: true,
    zIndex: 38,
    checkIdParam: false,
    ridePath: 'app.rides.offer',
    resultsPerPage: 50
};

const { resultsPerPage } = pageData;
const PageRidesOfferedArchived = ({ auth: { isAuthenticated }, onboarding: { tooltips }, globalState: { scroll } }) => {
    const [isModal, setIsModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);
    const ref = useRef(null);
    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    // useEffect(() => {
    //     console.log(currentId);
    // }, [currentId]);

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const handleAction = async (mutateData, status) => {
        if (currentId) {
            setIsModalAction(true);
            setIsModal(false);
            await updateRideStatus({ rideId: currentId, status });
            await mutateData();
            setIsModalAction(false);
        }
    };

    useEffect(() => {
        if (scroll?.page === 'app.rides.offered.archived') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    useEffect(() => {
        analytics.page('Published Rides List Archive', 'App', isAuthenticated);
    }, []);

    // console.log('rendering: List Published Archive');

    return (
        <LayoutPage pageData={pageData} infinite={true}>
            {({
                rtl,
                lang,
                location,
                translations,
                animStates,
                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                size,
                setSize,
                reducedMotion,
                getReducedMotion,
                handleNavigation,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.count,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                const isEmpty = newData?.count === 0;
                const isReachingEnd = newData?.count === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('data is', data);

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params} />

                        <div ref={ref} className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar'>
                            <BtnScroll archive={true} containerRef={ref} page={'app.rides.offered.archived'} />
                            <LayoutPageBody minHeight={'min-h-body-w-header-footer-1'}>
                                <div className=''>
                                    <LayoutGroup>
                                        <StatusOffered margins='' status={'ARCHIVED'} square count={newData?.count} height='h-vw-16'>
                                            <div className={classNames('flex items-center', rtl && 'flex-row-reverse')}>
                                                <span>{translations?.data?.['e.offered.archived.headline']}</span>
                                                {newData?.count ? (
                                                    <span
                                                        className={classNames(
                                                            'rounded-full text-vw-sm font-bold ring-1 ring-gray-700 px-vw-3 h-vw-6 inline-flex items-center',
                                                            rtl ? 'mr-2' : 'ml-2'
                                                        )}
                                                    >
                                                        {`${newData.count}`}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </StatusOffered>
                                        <div>
                                            <OfflineMode />
                                            {tooltips?.ridesOfferedArchived && (
                                                <motion.div layout='position' className='py-4 first:py-4'>
                                                    <MessageBox noTitle id='ridesOfferedArchived' label=''>
                                                        {translations?.data?.['e.offered.archived.section.subtitle']}
                                                    </MessageBox>
                                                </motion.div>
                                            )}
                                        </div>
                                        <motion.div layout='position' transition={transitions.listAdjust}>
                                            <ul
                                                className={`px-vw-6a flex flex-col items-center`}
                                                // initial={animStates.animCards.initial}
                                                // animate={animStates.animCards.animate}
                                                // variants={animations.staggerFromTop}
                                            >
                                                {!isModalAction && newData?.count != null ? (
                                                    newData?.count > 0 ? (
                                                        newData?.data?.map?.((item) => {
                                                            return (
                                                                <CardOffered
                                                                    setMode={setMode}
                                                                    setCurrentId={setCurrentId}
                                                                    data={item}
                                                                    key={item._id}
                                                                    rtl={rtl}
                                                                    lang={lang}
                                                                    archive={true}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <motion.div
                                                            className='w-full px-vw-6 pt-vw-6'
                                                            initial={getReducedMotion('results', animStates2.results, reducedMotion).initial}
                                                            animate={getReducedMotion('results', animStates2.results, reducedMotion).animate}
                                                            variants={animations.elementSlides}
                                                        >
                                                            <NoResults
                                                                title='e.nothingFound.offered.archived.title'
                                                                description='e.nothingFound.offered.archived.description'
                                                            />
                                                        </motion.div>
                                                    )
                                                ) : (
                                                    <Cloner clones={4}>
                                                        <CardSkeleton rtl={rtl} type='offered' />
                                                    </Cloner>
                                                )}
                                            </ul>

                                            {!isReachingEnd && !isModalAction && online && (
                                                <div className={classNames('px-vw-6 py-vw-4')}>
                                                    <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} archive={true} />
                                                </div>
                                            )}
                                        </motion.div>
                                    </LayoutGroup>
                                </div>
                            </LayoutPageBody>
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center '>
                                <OfflineMode margins='' />
                                {mode === 'CANCEL_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.cancelRide.headline']}
                                        </div>
                                        <BtnRequestCancel disabled={!online} handleClick={() => handleAction(mutateData, 'CANCELED')}>
                                            {translations?.data?.['e.cancelRide']}
                                        </BtnRequestCancel>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={setIsModal}>
                                            {translations?.data?.[lang === 'ar' ? 'e.keepIt.f' : 'e.keepIt.m']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'PUBLISH_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.setToPublish.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'PUBLISHED')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.setToPublish']}
                                                <Icons icon={'UploadCloud'} size={'5.8vw'} flip={rtl} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DELETE_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.delete.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='cancel'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'DELETE')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.delete']}
                                                <Icons icon={'Trash'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.keepIt.f' : 'e.keepIt.m']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DRAFT_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.setToDraft.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'DRAFT')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.setToDraft']}
                                                <Icons icon={'Unpublish'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'ARCHIVE_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.archive.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'ARCHIVE')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.archive']}
                                                <Icons icon={'Archive'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    onboarding: state.onboarding,
    auth: state.auth
});

export default connect(mapStateToProps, null)(PageRidesOfferedArchived);
