import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import IconRound from 'components/blocks/IconRound';
import Paragraph from 'components/blocks/Paragraph';
import OtpInput from 'components/forms/OtpInput';
import { animations } from 'constants/animations';
import { motion } from 'framer-motion';
import GetLang from 'hoc/GetLang';
import { useEffect, useState } from 'react';
import { loginByNumber, clearLoginErrors, loginSetStep } from 'store/actions/auth';
import { loadNamedState } from 'store/localStorage';

const LoginFlowVerify = ({ loginSetStep, loginByNumber, globalState: { lang }, auth: { loading, error, otpReq }, info }) => {
    const [otp, setOtp] = useState('');
    const handleVerifyOtp = (otp) => {
        const { phoneNumber, requestId } = otpReq;
        const body = {
            phoneNumber,
            requestId,
            otp,
            info: {
                firstName: info?.firstName?.value,
                lastName: info?.lastName?.value,
                email: info?.email?.value,
                gender: info?.gender?.value
            },
            lang,
            installSource: loadNamedState('appInstall')?.source || 'Direct'
        };
        clearLoginErrors();
        loginByNumber(body);
    };

    useEffect(() => {
        if (otp.length === 4)
            setTimeout(() => {
                handleVerifyOtp(otp);
                setOtp('');
            }, 200);
    }, [otp]);

    useEffect(() => {
        if (error) {
            loginSetStep('verifyOtpError');
            // console.log('error', error);
        }
    }, [error]);

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className='relative h-full text-vw-base'>
                    {!loading && <IconRound icon='User' preset='yellow' />}
                    <div className={classNames('pt-vw-12 h-full')}>
                        <HeadlineType1 label='e.loginFlow.verify.title' />

                        <div className={classNames('px-vw-10 flex flex-col items-center')}>
                            <Paragraph margins='mb-vw-2'>{translations?.data?.['e.loginFlow.verify.subtitle']}</Paragraph>
                            <div className='text-bb-blue-500 font-semibold text-vw-xl mb-vw-6 tracking-wide'>{`+${otpReq?.phoneNumber}`}</div>
                            {error?.[0] !== 'Auth.otp.verify.error.status.17' && (
                                <>
                                    <OtpInput otp={otp} handleOtp={setOtp} />

                                    <Paragraph preset='small-faded' margins='my-vw-6'>
                                        {translations?.data?.['e.loginFlow.verify.note']}
                                        <button className={classNames('text-bb-blue-500 font-bold px-vw-2', { 'float-left': rtl })}>
                                            {translations?.data?.['e.loginFlow.verify.note2']}
                                        </button>
                                    </Paragraph>
                                </>
                            )}
                            {error?.length > 0 && (
                                <motion.div
                                    key='loginVerifyError'
                                    initial={'bottomFast'}
                                    animate={'delayedIn'}
                                    variants={animations.pageSlides}
                                    className={classNames(
                                        'h-full-a absolute inset-x-vw-10 bottom-0 rounded-t-2xl bg-red-50  text-bb-red-400 text-vw-sm font-medium text-center'
                                    )}
                                >
                                    <div className='relative px-vw-6 pt-vw-8 pb-vw-6'>
                                        <IconRound icon='Close' preset='red' presetSize='small' />

                                        {translations?.data?.[error[0]] ?? error[0]}
                                    </div>
                                </motion.div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearLoginErrors,
            loginByNumber,
            loginSetStep
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlowVerify);
