import { defaultSize, defaultStroke } from './defaults';
const IconDownloadCloud2 = ({ size, className }) => {
    return (
        <>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size || defaultSize} height={size || defaultSize}>
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M13 13v5.585l1.828-1.828 1.415 1.415L12 22.414l-4.243-4.242 1.415-1.415L11 18.585V13h2zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z' />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconDownloadCloud2;
