/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    selectedRides: [],
    selectedUsers: []
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const updateSelectedUsers = (draft, action) => {
    const { selectedUsers } = action;
    draft.selectedUsers = selectedUsers;
    return draft;
};

const updateSelectedRides = (draft, action) => {
    const { selectedRides } = action;
    draft.selectedRides = selectedRides;
    return draft;
};

export default createReducer(initialState, {
    [types.ADMIN_SELECTEDUSERS_UPDATE]: updateSelectedUsers,
    [types.ADMIN_SELECTEDRIDES_UPDATE]: updateSelectedRides
});
