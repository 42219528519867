import { getDayDifference, getRelativeDate } from 'helpers/dates';

import GetLang from 'hoc/GetLang';
import { DateTime } from 'luxon';
import classNames from 'classnames';

const FormattedDateTime = ({ dateTime, date, dateString, timeString, time, both, showDaysAgoTime = 2 }) => {
    const dayDiff = getDayDifference(dateTime);

    return (
        <GetLang>
            {({ lang, time24 }) => (
                <>
                    <>{date ? (dateString ? dateString : getRelativeDate(dateTime, lang)) : null}</>
                    <>{time ? (timeString ? timeString : DateTime.fromISO(dateTime).toFormat(time24 ? 'HH:mm' : 'hh:mm a')) : null}</>
                    {/* <>{time && DateTime.fromISO(dateTime).toFormat(time24 ? 'HH:mm' : 'hh:mm a')}</> */}
                    {both && (
                        <>
                            <span className={classNames('capitalize')}>{getRelativeDate(dateTime, lang)}</span>
                            {dayDiff < 2 && <span>{DateTime.fromISO(dateTime).toFormat(time24 ? 'HH:mm' : 'hh:mm a')}</span>}
                        </>
                    )}
                </>
            )}
        </GetLang>
    );
};

export default FormattedDateTime;
