const TermsAndPrivacy = ({ lang, setStaticPage }) => {
    return (
        <div className='mt-vw-6 mb-vw-2 items-start relative flex flex-col py-vw-4 rounded-2xl text-vw-sm font-medium text-yellow-700 bg-yellow-100'>
            {lang === 'en' && (
                <div className='px-vw-6 text-vw-sm inline'>
                    By signing up and creating an account, you agree to the{' '}
                    <span onClick={() => setStaticPage('terms')} className='font-bold underline inline'>
                        Terms of Service
                    </span>{' '}
                    and{' '}
                    <span onClick={() => setStaticPage('privacy')} className='font-bold underline inline'>
                        Privacy Policy
                    </span>{' '}
                    of Bala Benzine.
                </div>
            )}
            {lang === 'fr' && (
                <div className='px-vw-6 text-vw-sm inline'>
                    En créant un compte, vous acceptez les{' '}
                    <span onClick={() => setStaticPage('terms')} className='font-bold underline inline'>
                        termes et conditions
                    </span>{' '}
                    et la{' '}
                    <span onClick={() => setStaticPage('privacy')} className='font-bold underline inline'>
                        politique de confidentialité
                    </span>{' '}
                    de Bala Benzine.
                </div>
            )}
            {lang === 'ar' && (
                <div
                    style={{
                        direction: 'rtl'
                    }}
                    className='px-vw-6 text-vw-sm inline'
                >
                    من خلال التسجيل وإنشاء حساب ، فإنك توافق على{' '}
                    <span onClick={() => setStaticPage('terms')} className='font-bold underline inline'>
                        الشروط والأحكام
                    </span>{' '}
                    و
                    <span onClick={() => setStaticPage('privacy')} className='font-bold underline inline'>
                        سياسة الخصوصية الخاصة
                    </span>{' '}
                    ل-bala benzine
                </div>
            )}
        </div>
    );
};

export default TermsAndPrivacy;
