import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';
import { BtnRequestCancel, BtnContactDriver } from 'components/buttons';
import Icons from 'components/icons/index';
import { bindActionCreators } from 'redux';

import CardSkeleton from 'components/skeletons/CardSkeleton';
import { useEffect, useState } from 'react';

import { updateAdminVerification } from 'fetch/apiRequests';
import BtnLoadState from 'components/buttons/BtnLoadState';
import classNames from 'classnames';
import useMyNavigation from 'hooks/useMyNavigation';

import FormattedDateTime from 'components/blocks/FormattedDateTime';

import OfflineMode from 'components/blocks/OfflineMode';

import { setStaticPage } from 'store/actions/staticPages';

import Avatar from 'components/blocks/Avatar';

import { useNavigate, useLocation } from 'react-router-dom';

import StatusNotification from 'components/blocks/StatusNotification';
import UserDisplay from 'components/blocks/UserDisplay';
import { PageLoading } from 'pages/index';
import ListBox from 'components/forms/ListBox';
import { genderList } from 'constants/defaultObjects';
import { getWhatsAppMessage, getWhatsAppUrl, handleWhatsApp } from 'constants/translations';

const PageAdminVerification = ({
    setStaticPage,
    auth: {
        user: {
            admin,
            profile: { firstName: adminFirstName }
        }
    }
}) => {
    const pageData = {
        page: 'app.admin.verification',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/verifications/find-admin',
        backBtn: true,
        zIndex: 43,
        checkIdParam: true
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);

    const { handleNavigation, location } = useMyNavigation();

    useEffect(() => {
        if (!admin) handleNavigation('app.searchCarSharing', null);
    }, []);

    const handleAction = async (verification, mutateData, status) => {
        setIsModalAction(true);
        await updateAdminVerification(
            verification?.id,
            { status, type: verification?.type },
            {
                user: verification?.user?.id,
                verifiedProfile: verification?.user?.profile?.verifiedProfile,
                avatar: verification?.user?.profile?.avatar,
                mobile: verification?.user?.phoneNumber,
                lang: verification?.user?.profile?.lang
            }
        );
        await mutateData();
        setIsModalAction(false);
        handleNavigation('app.admin.verify', null);
    };

    // const handleContact = (phone, name, lang) => {
    //     window?.open(getWhatsAppUrl(phone, 'reachout', name, lang, firstName), '_blank');
    // };

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidParams, translations, data, isValidating, mutateData, handleNavigation, online }) => {
                // console.log('data is', data);
                // console.log('isValidating', isValidating);
                // console.log('location', location);

                const user = data?.user;
                const type = data?.type;
                const status = data?.status;
                const payload = data?.payload;
                const createdAt = data?.createdAt;
                const gender = data?.user?.profile?.gender;

                // console.log('gender', gender);

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params} useBrowserHistory={true}></LayoutPageHeader>

                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <LayoutPageBody minHeight=''>
                                <div className=''>
                                    {data && !isModalAction ? (
                                        <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                            <div className={classNames('h-body-w-header-2 flex flex-col justify-between', 'px-vw-6 relative w-full')}>
                                                <div>
                                                    <OfflineMode margins='mt-6' />

                                                    <div>
                                                        <div className={classNames('w-full flex justify-between', rtl && 'flex-row-reverse')}>
                                                            <StatusNotification status={`VERIFICATION_${type}_${status}` ?? 'ERROR'} />
                                                            <div
                                                                className={classNames(
                                                                    'text-gray-600 flex items-center gap-1 text-vw-sm md:text-base font-semibold',
                                                                    rtl && 'flex-row-reverse'
                                                                )}
                                                            >
                                                                <FormattedDateTime dateTime={createdAt} both />
                                                            </div>
                                                        </div>

                                                        <div className={`mt-vw-4 md:mt-4 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                                                            <div className={classNames('flex items-center gap-4', rtl ? 'flex-row-reverse' : 'flex-row')}>
                                                                <Avatar
                                                                    profile={
                                                                        user?.profile ?? {
                                                                            avatar: 'https://ucarecdn.com/e2b2a458-d347-4187-9570-df3852f03654/maskable_icon_x128.png'
                                                                        }
                                                                    }
                                                                    size={'h-vw-12 w-vw-12 md:h-16 md:w-16'}
                                                                    preset={'default'}
                                                                />
                                                                <div>
                                                                    <UserDisplay profile={user?.profile} fullName phone lang gender email={user?.email} />
                                                                    {/* <div
                                                                        className={classNames(
                                                                            rtl && 'flex-row-reverse',
                                                                            'flex items-center text-vw-base font-semibold text-gray-600 gap-2'
                                                                        )}
                                                                    >
                                                                        <div className={classNames(rtl && 'text-right')}>
                                                                            {translations.data[genderList.find((item) => item.id === gender)?.text]}
                                                                        </div>
                                                                        <div
                                                                            className={classNames(rtl && 'text-right', 'uppercase')}
                                                                        >{`(${user?.profile?.lang})`}</div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {payload?.length > 0 && (
                                                        <div className={classNames('w-full flex justify-center gap-2 mt-vw-8 mb-vw-6')}>
                                                            {payload.map((singleImage) => (
                                                                <div
                                                                    key={singleImage?.url}
                                                                    onClick={() => setStaticPage('imageZoom', { src: singleImage?.url })}
                                                                >
                                                                    <img src={singleImage?.url} className={classNames('object-cover w-full h-vw-60')} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>

                                                <div className={classNames('flex flex-col ')}>
                                                    <BtnContactDriver
                                                        disabled={!online}
                                                        whatsApp
                                                        params={[user?.phoneNumber, user?.profile?.firstName, user?.profile?.lang, adminFirstName]}
                                                        handleClick={handleWhatsApp}
                                                    />

                                                    {status === 'PENDING' && (
                                                        <>
                                                            <BtnLoadState
                                                                colorType={'primary'}
                                                                disabled={!online}
                                                                spinner={isModalAction}
                                                                handleClick={handleAction}
                                                                params={[data, mutateData, 'APPROVED']}
                                                                label={'e.verify.approve'}
                                                            />
                                                            <BtnLoadState
                                                                colorType={'cancel'}
                                                                disabled={!online}
                                                                spinner={isModalAction}
                                                                handleClick={handleAction}
                                                                params={[data, mutateData, 'REJECTED']}
                                                                label={'e.verify.reject'}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <PageLoading bg='bg-white' overlay={true} />
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageAdminVerification);
