import PageStaticTemplate from './PageStaticTemplate';

const PageStaticAbout = ({ overlay }) => {
    return (
        <PageStaticTemplate
            page='static.about'
            apiUrl='/static-pages/62fbdbf6e2654aac50328df0'
            icon='Drop1'
            overlay={overlay}
            analyticsPage='What is Bala Benzine'
        />
    );
};

export default PageStaticAbout;
