import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { connect } from 'react-redux';
import { CardBooking } from 'components/cards';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import StatusBooked from 'components/blocks/StatusBooked';
import Cloner from 'components/blocks/Cloner';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';
import { motion, LayoutGroup } from 'framer-motion';
import { animations, transitions, animStates2 } from 'constants/animations';
import MessageBox from 'components/blocks/MessageBox';
import NoResults from 'components/blocks/NoResults';
import Icons from 'components/icons/index';
import BtnLoadState from 'components/buttons/BtnLoadState';
import BtnPill from 'components/buttons/BtnPill';
import ModalBottom from 'components/modals/ModalBottom';
import BtnRequestCancel from 'components/buttons/BtnRequestCancel';
import { useState, useEffect, useRef } from 'react';
import { updateBooking, updateReview } from 'fetch/apiRequests';
import useMyNavigation from 'hooks/useMyNavigation';
import useScroll from 'hooks/useScroll';
import BtnScroll from 'components/buttons/BtnScroll';
import RatingSlider from 'components/blocks/RatingSlider';
import { analytics } from 'helpers/segment';

const pageData = {
    page: 'app.rides.booked.archived',
    pageRedirect: 'app.searchCarSharing',
    apiUrl: '/bookings/archived',
    backBtn: true,
    zIndex: 38,
    checkIdParam: false,
    ridePath: 'app.rides.booking',
    resultsPerPage: 50
};

const { resultsPerPage } = pageData;

const PageRidesBookedArchived = ({ auth: { isAuthenticated }, onboarding: { tooltips }, globalState: { scroll } }) => {
    const [isModal, setIsModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);

    const ref = useRef(null);

    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    // useEffect(() => {
    //     console.log(currentId);
    // }, [currentId]);

    // useEffect(() => {
    //     console.log('rating is', rating);
    // }, [rating]);

    const { handleNavigation, location } = useMyNavigation();

    const handleButton = () => {
        handleNavigation('app.rides.booked.archived', null);
    };

    const handleModalClose = () => {
        setMode(null);
        setUserRating(null);
        setIsModal(false);
    };

    const [userRating, setUserRating] = useState(null);

    // const handleRating = (star) => {
    //     if (star === rating) setRating(0);
    //     else setRating(star);
    // };

    const handleAction = async (mutateData, status) => {
        if (currentId) {
            if (status === 'RATE') {
                if (userRating) {
                    setIsModalAction(true);
                    setIsModal(false);
                    await updateReview({
                        reviewId: currentId?.reviewId,
                        rating: userRating,
                        bookingId: currentId?.id
                    });
                    await mutateData();
                    setIsModalAction(false);
                    setMode(null);
                    setUserRating(null);
                    setCurrentId(null);
                }
            } else {
                setIsModalAction(true);
                setIsModal(false);
                await updateBooking({ bookingId: currentId?.id, status });
                await mutateData();
                setIsModalAction(false);
                setMode(null);
                setUserRating(null);
                setCurrentId(null);
            }
        }
    };

    useEffect(() => {
        if (scroll?.page === 'app.rides.booked.archived') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    useEffect(() => {
        analytics.page('Booked Rides List Archive', 'App', isAuthenticated);
    }, []);

    // console.log('rendering: List Booked Archive');

    return (
        <LayoutPage pageData={pageData} infinite={true}>
            {({
                rtl,
                lang,
                location,
                animStates,
                translations,
                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                size,
                setSize,
                reducedMotion,
                getReducedMotion,
                handleNavigation,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.count,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                const isEmpty = newData?.count === 0;
                const isReachingEnd = newData?.count === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('mode is', mode);
                // console.log('data is', data);

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params} />
                        <div ref={ref} className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar'>
                            <BtnScroll archive={true} containerRef={ref} page={'app.rides.booked.archived'} />
                            <LayoutPageBody minHeight={'min-h-body-w-header-footer-1'}>
                                <div className=''>
                                    <LayoutGroup>
                                        <StatusBooked margins='' status={'ARCHIVED'} square height='h-vw-16'>
                                            <div className={classNames('flex items-center', rtl && 'flex-row-reverse')}>
                                                <span>{translations?.data?.['e.booked.archived.headline']}</span>
                                                {newData?.count ? (
                                                    <span
                                                        className={classNames(
                                                            'rounded-full text-vw-sm font-bold ring-1 ring-gray-700 px-vw-3 h-vw-6 inline-flex items-center',
                                                            rtl ? 'mr-2' : 'ml-2'
                                                        )}
                                                    >
                                                        {`${newData.count}`}
                                                    </span>
                                                ) : null}
                                            </div>
                                        </StatusBooked>
                                        <div>
                                            <OfflineMode />
                                            {tooltips?.ridesBookedArchived && (
                                                <motion.div layout='position' className='py-4 first:py-4'>
                                                    <MessageBox noTitle id='ridesBookedArchived' label=''>
                                                        {translations?.data?.['e.booked.archived.section.subtitle']}
                                                    </MessageBox>
                                                </motion.div>
                                            )}
                                        </div>

                                        <motion.div layout='position' transition={transitions.listAdjust}>
                                            <ul className={`flex flex-col items-center`}>
                                                {!isModalAction && newData?.count != null ? (
                                                    newData?.count > 0 ? (
                                                        newData?.data?.map?.((item) => {
                                                            return (
                                                                <CardBooking
                                                                    setMode={setMode}
                                                                    setCurrentId={setCurrentId}
                                                                    data={item}
                                                                    key={item._id}
                                                                    rtl={rtl}
                                                                    lang={lang}
                                                                    archive={true}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <motion.div
                                                            className='w-full px-vw-6 pt-vw-6'
                                                            initial={getReducedMotion('results', animStates2.results, reducedMotion).initial}
                                                            animate={getReducedMotion('results', animStates2.results, reducedMotion).animate}
                                                            variants={animations.elementSlides}
                                                        >
                                                            <NoResults
                                                                title='e.nothingFound.booking.archived.title'
                                                                description='e.nothingFound.booking.archived.description'
                                                            />
                                                        </motion.div>
                                                    )
                                                ) : (
                                                    <Cloner clones={4}>
                                                        <CardSkeleton rtl={rtl} type='booked' />
                                                    </Cloner>
                                                )}
                                            </ul>
                                            {!isReachingEnd && !isModalAction && online && (
                                                <div className={classNames('px-vw-6 py-vw-4')}>
                                                    <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} archive={true} />
                                                </div>
                                            )}
                                        </motion.div>
                                    </LayoutGroup>
                                </div>
                            </LayoutPageBody>
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center'>
                                <OfflineMode margins='' />
                                {mode?.startsWith('CANCELED_BY') && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.cancelBooking.headline']}
                                        </div>
                                        <BtnRequestCancel handleClick={() => handleAction(mutateData, 'CANCELED_BY_PASSENGER')}>
                                            {translations?.data?.['e.cancelBooking']}
                                        </BtnRequestCancel>

                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode?.startsWith('DECLINED_BY') && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.cancelRequest.headline']}
                                        </div>
                                        <BtnRequestCancel handleClick={() => handleAction(mutateData, 'DECLINED_BY_PASSENGER')}>
                                            {translations?.data?.['e.cancelRequest']}
                                        </BtnRequestCancel>

                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'ARCHIVE_BOOKING' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.booking.status.archive.headline']}
                                        </div>
                                        <BtnPill
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'ARCHIVE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.booking.status.archive']}
                                                <Icons icon={'Archive'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DELETE_BOOKING' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.booking.status.delete.headline']}
                                        </div>
                                        <BtnPill
                                            type='cancel'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'DELETE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.booking.status.delete']}
                                                <Icons icon={'Trash'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'RATE_BOOKING' && (
                                    <>
                                        <RatingSlider
                                            name={currentId?.name}
                                            ask={true}
                                            rating={currentId?.currentRating}
                                            setUserRating={setUserRating}
                                            userRating={userRating}
                                        />
                                        {!currentId?.currentRating && (
                                            <BtnPill
                                                type='primary'
                                                disabled={!userRating}
                                                justify={'justify-between'}
                                                rtl={rtl}
                                                params={[false]}
                                                handleClick={() => handleAction(mutateData, 'RATE')}
                                            >
                                                <>
                                                    <span className='w-vw-6' />
                                                    {translations?.data?.['e.bug.submit']}
                                                    <Icons icon={'Plane1'} size={'5.8vw'} flip={rtl} />
                                                </>
                                            </BtnPill>
                                        )}
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    onboarding: state.onboarding,
    auth: state.auth
});

export default connect(mapStateToProps, null)(PageRidesBookedArchived);
