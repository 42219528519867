import axios from 'axios';
import { getSelectedDaysArr } from 'helpers/helperFunctions';
import { analytics } from 'helpers/segment';
import { cloneDeep } from 'lodash';
import store from 'store/index';

const isAuthenticated = () => store.getState()?.auth?.isAuthenticated;

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

// export const verifyUser = async (phoneNumber, lang = 'en', force = false) => {
//     try {
//         const res = await axios.post(
//             `${process.env.REACT_APP_API_BASE}/otp`,
//             { phoneNumber, lang, force },
//             config
//         );
//         return res.data;
//     } catch (error) {
//         return error.response.data;
//     }
// };

// export const verifyOtp = async (body) => {
//     try {
//         const res = await axios.post(
//             `${process.env.REACT_APP_API_BASE}/otp/verify`,
//             body,
//             config
//         );
//         return res.data;
//     } catch (error) {
//         return error.response.data;
//     }
// };

export const banUser = async (obj) => {
    const body = JSON.stringify(obj);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/chat/ban`, body, config);
        // console.log('res', res);
        return res?.data;
    } catch (error) {
        if (error.response) {
            return { status: error.response };
        } else if (error.request) {
            return { status: 500 };
        } else {
            return { status: 501 };
        }
    }
};

export const getChannelHistory = async (obj) => {
    const body = JSON.stringify(obj);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/channels/history`, body, config);
        // console.log('res', res);
        return res?.data;
    } catch (error) {
        if (error.response) {
            return { status: error.response };
        } else if (error.request) {
            return { status: 500 };
        } else {
            return { status: 501 };
        }
    }
};

export const getDeviceProps = async () => {
    let promise = new Promise((resolve, reject) => {
        window?.fod.complete((data) => {
            resolve({
                hardwareName: data.device['hardwarename'],
                platformName: data.device['platformname']?.toString(),
                platformVersion: data.device['platformversion']?.toString(),
                browserName: data.device['browservendor']?.toString(),
                browserVersion: data.device['browserversion']?.toString()
            });
        });
    });

    return promise;
};

export const reportUser = async (obj) => {
    const body = JSON.stringify(obj);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/reportings`, body, config);
        analytics.track('User Reported', obj, true);
        return { status: res.status };
    } catch (error) {
        if (error.response) {
            return { status: error.response.status };
        } else if (error.request) {
            return { status: 500 };
        } else {
            return { status: 501 };
        }
    }
};

export const reportBug = async (obj) => {
    const body = JSON.stringify(obj);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/bug`, body, config);
        // console.log('res', res);

        analytics.track('Bug Reported', null, true);
        return res?.data;
    } catch (error) {
        if (error.response) {
            return { status: error.response };
        } else if (error.request) {
            return { status: 500 };
        } else {
            return { status: 501 };
        }
    }
};

export const verifyEmail = async (email) => {
    // let promise = new Promise((resolve, reject) =>
    //     setTimeout(() => {
    //         resolve(true);
    //     }, 1000)
    // );
    // return promise;
    const body = JSON.stringify({ email });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/otp/verify/email`, body, config);
        // console.log('res', res);
        return res?.data?.available;
    } catch (error) {
        let payload;

        if (error.response) {
            payload = [error.response?.data?.message?.[0]?.messages?.[0]?.id];
        } else if (error.request) {
            payload = ['Auth.error.network'];
        } else {
            payload = ['Auth.error.generic'];
        }
    }
};

export const sendInstallLink = async (phone, lang = 'en', url = 'https://balabenzine.app') => {
    const body = JSON.stringify({ phone, lang, url });

    try {
        console.log(body);
        // let res;
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/actions/send-link`, body, config);
        console.log('res', res);
        return { error: false, data: res?.data };
    } catch (error) {
        if (error?.response) {
            return {
                error,
                status: error?.response?.status,
                statusText: error?.response?.statusText
            };
        } else if (error?.request) {
            return {
                error,
                status: error?.request?.status,
                statusText: error?.request?.statusText
            };
        }
        return {
            error,
            status: 'xxx',
            statusText: 'Unidentified Generic Error'
        };
    }
};

// const isoTimeBody = (data) => {
//     // check if recurring
//     const body = cloneDeep(data);
//     console.log(body);
//     if (body?.recurring) {
//         const dayArr = [0, 1, 2, 3, 4, 5, 6];
//         for (const day in dayArr) {
//             body.dateRange[day].isoTime = new Date(`2000-01-01T${body.dateRange?.[day]?.time || body.dateRange?.time || '00:00'}`).toISOString();
//             body.dateRange[day].seats = body.dateRange?.[day]?.seats || body.dateRange?.seats || 1;
//         }
//     }

//     return { ...body, dateTime: body?.recurring ? new Date().toISOString() : new Date(`${body.date}T${body.time}`).toISOString() };
// };

export const publishRide = async (body) => {
    const newBody = {
        ...body,
        days: getSelectedDaysArr(body.dateRange),
        dateTime: body?.recurring ? new Date().toISOString() : new Date(`${body.date}T${body.time}`).toISOString()
    };

    console.log(newBody);

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/rides/me`, newBody, config);
        analytics.track('Ride Published', null, true);
        return res;
    } catch (error) {
        // return error;
        console.log(error.response);
        return error.response;
    }
};

export const getGlobalSettings = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/global-settings`, config);
        // console.log(res);
        return res.data;
    } catch (error) {
        // console.log(error.response);
        return error.response.data;
    }
};

export const getLatestNews = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}/latest-news/latest-v2`, config);
        // console.log(res);
        return res.data;
    } catch (error) {
        // console.log(error.response);
        return error.response.data;
    }
};

export const makeBooking = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/bookings/make`, body, config);
        analytics.track('Booking Requested', null, true);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const createSuggestion = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/suggestions`, body, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const convertRides = async (rides) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/actions/convert`, { rides }, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateReview = async ({ reviewId, rating, bookingId }) => {
    // console.log({ rating, bookingId });
    try {
        const res = await axios.put(
            `${process.env.REACT_APP_API_BASE}/reviews/rating/${reviewId}`,
            { rating, bookingId },

            config
        );
        analytics.track('Trip Rating Submitted', null, true);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateProfile = async (body, currentUser) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/profiles`, body, config);
        analytics.identify(body, currentUser, isAuthenticated());
        analytics.track('Profile Updated', null, isAuthenticated());
        // console.log(res);
        return res.data;
    } catch (error) {
        // console.log(error.response);
        return error.response.data;
    }
};

export const updateProfileAdmin = async (userId, body) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/profiles/update-admin/${userId}`, body, config);

        return res.data;
    } catch (error) {
        // console.log(error.response);
        return error.response.data;
    }
};

export const createVerification = async (body) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/verifications/new`, body, config);
        if (body?.typ === 'ID') analytics.track('ID Verification Requested', null, isAuthenticated());
        if (body?.typ === 'PLATE') analytics.track('Car Verification Requested', null, isAuthenticated());
        return res.data;
    } catch (error) {
        // console.log(error.response);
        return error.response.data;
    }
};

export const updateVerification = async (id, type) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/verifications/updateStatus/${id}`, {}, config);
        if (type === 'ID') analytics.track('ID Verification Canceled', null, isAuthenticated());
        if (type === 'PLATE') analytics.track('Car Verification Canceled', null, isAuthenticated());

        // console.log(res);
        return res.data;
    } catch (error) {
        // console.log(error.response);
        return error.response.data;
    }
};

export const updateNotification = async (notificationIds, notificationBody) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/notifications`, { idList: notificationIds, notification: notificationBody }, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const chatNotification = async (userId) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/notifications/messaging`, { userId }, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateNotificationClearDate = async (notificationClearDate) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/profiles/notification`, { notificationClearDate }, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const unreadNotifications = async (userId) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_BASE}/notifications/unread`, {}, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateBooking = async ({ bookingId, status = 'CANCELED_BY_PASSENGER' }) => {
    try {
        const res = await axios.put(
            `${process.env.REACT_APP_API_BASE}/bookings/${endpointByStatus.booking[status]}/${bookingId}`,
            bodyByStatus(status),
            config
        );
        if (status === 'CANCELED_BY_PASSENGER') analytics.track('Booking Canceled By Passenger', null, true);
        if (status === 'DECLINED_BY_DRIVER') analytics.track('Booking Request Declined By Driver', null, true);
        if (status === 'DECLINED_BY_PASSENGER') analytics.track('Booking Request Canceled By Passenger', null, true);
        if (status === 'APPROVED_BY_DRIVER') analytics.track('Booking Request Approved', null, true);
        if (status === 'CANCELED_BY_DRIVER') analytics.track('Booking Canceled By Driver', null, true);

        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateAdminVerification = async (verificationId, body, extra) => {
    const verificationBody = {
        extra,
        body
    };

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/verifications/update-admin/${verificationId}`, verificationBody, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateRideStatus = async ({
    rideId,
    status = 'CANCELED' // not currently used
}) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/rides/${endpointByStatus.ride[status]}/${rideId}`, bodyByStatus(status), config);
        if (status === 'PUBLISHED') analytics.track('Ride Published', null, true);
        if (status === 'CANCELED') analytics.track('Ride Canceled', null, true);
        if (status === 'DRAFT') analytics.track('Ride Drafted', null, true);

        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateRideBookingAvailability = async ({ rideId, doNotAcceptBooking }) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/rides/accept-booking/${rideId}`, { doNotAcceptBooking }, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

export const updateRideLadiesOnly = async ({ rideId, ladiesOnly }) => {
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_BASE}/rides/ladies-only/${rideId}`, { ladiesOnly }, config);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
};

const endpointByStatus = {
    ride: {
        CANCELED: 'status',
        PUBLISHED: 'status',
        DRAFT: 'status',
        DELETE: 'me',
        ARCHIVE: 'me'
    },
    booking: {
        CANCELED_BY_PASSENGER: 'cancel',
        CANCELED_BY_DRIVER: 'cancel',
        CANCELED_BY_ADMIN: 'cancel',
        DECLINED_BY_PASSENGER: 'cancel',
        DECLINED_BY_DRIVER: 'cancel',
        DECLINED_BY_ADMIN: 'cancel',
        APPROVED_BY_DRIVER: 'cancel',
        APPROVED_BY_ADMIN: 'cancel',
        DELETE: 'me',
        ARCHIVE: 'me'
    }
};

const bodyByStatus = (status) => {
    switch (status) {
        case 'APPROVED_BY_DRIVER':
        case 'APPROVED_BY_ADMIN':
        case 'CANCELED_BY_PASSENGER':
        case 'CANCELED_BY_DRIVER':
        case 'CANCELED_BY_ADMIN':
        case 'DECLINED_BY_PASSENGER':
        case 'DECLINED_BY_DRIVER':
        case 'DECLINED_BY_ADMIN':
        case 'CANCELED':
        case 'PUBLISHED':

        case 'DRAFT':
            return { status };

        case 'DELETE':
            return { isActive: false, archive: true };
        case 'ARCHIVE':
            return { archive: true };
        default:
            return null;
    }
};
