import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavTab from 'components/blocks/NavTab';
import NavTabItem from 'components/blocks/NavTabItem';
import GetLang from 'hoc/GetLang';

import { routePath } from 'constants/structRoutes';
import { useEffect } from 'react';
import classNames from 'classnames';

const TabSingle = ({ label, suffix }) => {
    return (
        <GetLang>
            {({ translations, rtl }) => (
                <div className={classNames('w-full min-h-vw-16 md:min-h-20 bg-gray-50 relative select-none overflow-hidden mb-0.5s z-100')}>
                    <div className={` flex flex-nowrap ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center justify-between`}>
                        <div
                            className={classNames(
                                'text-bb-blue-500  px-vw-8 md:px-4 flex justify-center items-center w-full h-vw-16 md:h-20 font-bold text-vw-xl md:text-lg relative'
                            )}
                        >
                            <div
                                // style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                className={classNames('h-full flex items-center gap-2', rtl && 'flex-row-reverse')}
                            >
                                <span>{translations?.data?.[label]}</span>
                                {suffix && <span>{suffix}</span>}
                            </div>
                        </div>
                    </div>
                    <div className={`absolute bottom-0 inset-x-0 h-0.5 bg-gray-100`}></div>
                    <div
                        className={classNames('transform transition duration-300 ease-in-out absolute bottom-0 w-full h-0.5 bg-bb-blue-550 rounded-full', {
                            'left-0': !rtl,
                            'right-0': rtl
                        })}
                    ></div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TabSingle);
