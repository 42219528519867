import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import IconRound from 'components/blocks/IconRound';
import Paragraph from 'components/blocks/Paragraph';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import useMyNavigation from 'hooks/useMyNavigation';

const LoginFlowFinished = ({ auth: { loading } }) => {
    const { handleNavigation, location } = useMyNavigation();
    const handleFinished = () => {
        handleNavigation('app.searchCarSharing', null);
        // loginSetStep('start');
    };

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className='relative h-full text-vw-base'>
                    {!loading && <IconRound icon='Check' preset='green' />}
                    <div className={classNames('py-vw-12')}>
                        <HeadlineType1 label='e.loginFlow.finished.title' />

                        <div className={classNames('px-vw-10 flex flex-col items-center')}>
                            <Paragraph> {translations?.data?.['e.loginFlow.finished.subtitle']}</Paragraph>
                        </div>
                        <div className={classNames('flex justify-center items-center gap-4')}>
                            <img src='https://ucarecdn.com/d9bc03fb-9a6d-4369-9f67-e58e84e0ec6e/' className='w-vw-16 h-auto' />

                            {/* <img src='https://ucarecdn.com/70f47f0a-aebd-47e8-b1df-05e38b5d8144/AutomobileonAppleiOS154.png' className='w-vw-16 h-auto' /> */}
                        </div>
                    </div>

                    <BtnPill
                        type='primary'
                        justify='justify-between'
                        absolute='bottom-vw-8 inset-x-vw-10 '
                        shadow
                        handleClick={handleFinished}
                        tracking={!rtl && 'tracking-wide'}
                        rtl={rtl}
                    >
                        <span className='w-vw-6' />
                        {translations?.data?.['e.loginFlow.finished.button']}
                        <Icons icon={'Ride1'} flip={rtl} />
                    </BtnPill>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlowFinished);
