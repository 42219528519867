import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadUser } from 'store/actions/auth';
import { LayoutPage, LayoutPageHeader, LayoutPageBody, LayoutPageFooter } from 'components/layouts';

import { updateProfile } from 'fetch/apiRequests';

import OfflineMode from 'components/blocks/OfflineMode';
import useMyNavigation from 'hooks/useMyNavigation';
import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import Rating from 'components/blocks/Rating';
import ListItem from 'components/blocks/ListItem';
import CardSection from 'components/cards/CardSection';
import UploadGalleryImages from 'components/blocks/UploadGalleryImages';
import { useRef } from 'react';
import Uploader from 'components/blocks/Uploader';
import { PageLoading } from 'pages/index';
import { setStaticPage } from 'store/actions/staticPages';
import { useEffect } from 'react';
import UserDisplay from 'components/blocks/UserDisplay';
import { analytics } from 'helpers/segment';

const PageProfile = ({ setStaticPage, loadUser, staticPages: { page }, auth: { user, isAuthenticated } }) => {
    const pageData = {
        page: 'app.profile',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/profiles/me',
        backBtn: false,
        zIndex: 41,
        checkIdParam: false
    };

    const { handleNavigation, location } = useMyNavigation();

    const handlePersonal = () => {
        handleNavigation('app.profile.personal');
    };

    const widgetApi = useRef();

    const handleProfileImage = async (url, mutateData) => {
        const res = await updateProfile({ avatar: url?.cdnUrl ?? null }, user);
        analytics.track(url ? 'Profile Photo Added' : 'Profile Photo Cleared', null, isAuthenticated);
        loadUser();
        mutateData();
    };

    const openUploader = () => {
        const dialog = widgetApi.current.openDialog();
        // console.log(dialog);

        // dialog.switchTab('file');
    };

    const handleClearImage = async (fn) => {
        handleProfileImage(null, fn);
    };

    const getSwipeMenuOptions = (fn) => [
        {
            icon: 'Trash',
            handleClick: handleClearImage,
            params: [fn]
        }
        // {
        //     icon: 'UserBlock',
        //     handleClick: openUploader,
        //     params: [fn]
        // }
    ];

    useEffect(() => {
        analytics.page('Profile', 'App', isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                // console.log('data', data);

                const idArr = data?.verifications?.ID;
                const plateArr = data?.verifications?.PLATE;

                return (
                    <>
                        {/* <LayoutPageHeader pageData={pageData} pageParams={params} useBrowserHistory={true}></LayoutPageHeader> */}
                        <div className='h-body no-scrollbar overflow-y-auto text-vw-base mt-vw-6a'>
                            <LayoutPageBody minHeight={classNames('min-h-body-w-header-footer-2')}>
                                <div className=''>
                                    {data ? (
                                        <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                            <OfflineMode margins=' mb-6' />
                                            <CardSection
                                                paddingY='py-vw-6'
                                                border='border-t border-b'
                                                swipeMenuOptions={getSwipeMenuOptions(mutateData)}
                                                handleClick={null}
                                            >
                                                <div
                                                    className={classNames(
                                                        'px-vw-6a w-full justify-between flex items-center border-ba border-gray-200 pb-vw-6a',
                                                        rtl && 'flex-row-reverse'
                                                    )}
                                                >
                                                    <div
                                                        style={{
                                                            direction: rtl ? 'rtl' : 'ltr'
                                                        }}
                                                        className={classNames(' flex flex-col', rtl && 'text-right')}
                                                    >
                                                        <div
                                                            className={classNames(
                                                                'text-bb-blue-550 font-bold',
                                                                (data?.displayName || data?.firstName).length > 15 ? ' text-vw-xl' : 'text-vw-2xl'
                                                            )}
                                                        >
                                                            {data?.displayName || data?.firstName}
                                                        </div>

                                                        <div
                                                            className={classNames('text-gray-400 text-vw-base font-bold')}
                                                        >{`${data?.firstName} ${data?.lastName}`}</div>
                                                        <UserDisplay profile={data} noName className={classNames('mt-vw-2')} phone email={user?.email} />
                                                    </div>

                                                    <button onClick={() => setStaticPage('uploadAvatar')} className='focus:outline-none '>
                                                        <Avatar profile={data} iconSize={'10vw'} size={'w-vw-20 h-vw-20'} />
                                                    </button>
                                                </div>
                                            </CardSection>

                                            <CardSection tap={false} border='' borderColor='border-gray-100'>
                                                <Rating
                                                    className='text-gray-500 font-semibold gap-4 text-vw-lg'
                                                    iconSize='6vw'
                                                    iconClass='text-gray-400'
                                                    rating={data?.rating}
                                                    reviews={data?.totalReviews || null}
                                                    showReviews
                                                />
                                            </CardSection>

                                            <div
                                                className={classNames(
                                                    rtl ? 'items-end' : 'items-start',
                                                    'px-vw-6 w-full flex flex-col border-t-8 border-b-8 border-gray-100 py-vw-6 gap-4'
                                                )}
                                            >
                                                <h2
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                    className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                                >
                                                    {translations.data[data?.verifiedProfile ? 'e.verified.profile.me' : 'e.verify.title']}
                                                </h2>
                                                {!data?.verifiedProfile && (
                                                    <div className='mb-vw-2 items-start relative flex flex-col py-vw-4 rounded-2xl text-vw-sm font-medium text-yellow-700 bg-yellow-100'>
                                                        <div
                                                            style={{
                                                                direction: rtl ? 'rtl' : 'ltr'
                                                            }}
                                                            className='px-vw-6 text-vw-sm'
                                                        >
                                                            {translations.data['e.verify.subtitle']}
                                                        </div>
                                                    </div>
                                                )}

                                                <ul className={classNames(rtl ? 'items-end' : 'items-start', 'w-full flex flex-col gap-6')}>
                                                    {data?.verifiedMobile && (
                                                        <ListItem
                                                            icon='Phone1'
                                                            blockClass={classNames(
                                                                'text-vw-base, font-semibold text-gray-500 flex flex-wrap items-center gap-2'
                                                            )}
                                                        >
                                                            <span className='flex-none'>{translations.data['e.verified.mobile']}</span>
                                                            {/* <span
                                                                style={{ direction: 'ltr' }}
                                                                className={classNames('rounded-full uppercase bg-gray-100 px-vw-4 py-xw-2 text-vw-sm')}
                                                            >
                                                                {`+${data?.mobile}`}
                                                            </span> */}
                                                        </ListItem>
                                                    )}
                                                    {data?.avatar && <ListItem icon='User'>{translations.data['e.verified.photo']}</ListItem>}
                                                    {data?.verifiedEmail && <ListItem>{translations.data['e.verified.email']}</ListItem>}
                                                    {data?.verifiedId && <ListItem icon='Id2'>{translations.data['e.verified.id']}</ListItem>}
                                                    {data?.verifiedCarRegistration && <ListItem icon='Car1'>{translations.data['e.verified.plate']}</ListItem>}

                                                    {!data?.avatar && (
                                                        <button onClick={() => setStaticPage('uploadAvatar')}>
                                                            <ListItem preset='blue'>{translations.data['e.verify.photo']}</ListItem>
                                                        </button>
                                                    )}
                                                    {!data?.verifiedMobile && <ListItem preset='blue'>{translations.data['e.verify.mobile']}</ListItem>}
                                                    {!data?.verifiedId && (
                                                        <button onClick={() => setStaticPage('verifyId')}>
                                                            <ListItem
                                                                preset={idArr.length ? 'pending' : 'blue'}
                                                                blockClass={classNames(
                                                                    'text-vw-base, font-semibold text-gray-500 flex flex-wrap items-center gap-2'
                                                                )}
                                                            >
                                                                <span className='flex-none'>{translations.data['e.verify.id']}</span>
                                                                {idArr.length ? (
                                                                    <span
                                                                        style={{ direction: 'ltr' }}
                                                                        className={classNames('rounded-full uppercase bg-gray-100 px-vw-4 py-xw-2 text-vw-sm')}
                                                                    >
                                                                        {translations.data['e.verify.pending']}
                                                                    </span>
                                                                ) : null}
                                                            </ListItem>
                                                        </button>
                                                    )}
                                                    {!data?.verifiedCarRegistration && (
                                                        <button onClick={() => setStaticPage('verifyPlate')}>
                                                            <ListItem
                                                                preset={plateArr.length ? 'pending' : 'blue'}
                                                                blockClass={classNames(
                                                                    'text-vw-base, font-semibold text-gray-500 flex flex-wrap items-center gap-2'
                                                                )}
                                                            >
                                                                <span className={classNames(rtl ? 'text-right' : 'text-left')}>
                                                                    {translations.data['e.verify.plate']}
                                                                </span>
                                                                {plateArr.length ? (
                                                                    <span
                                                                        style={{ direction: 'ltr' }}
                                                                        className={classNames('rounded-full uppercase bg-gray-100 px-vw-4 py-xw-2 text-vw-sm')}
                                                                    >
                                                                        {translations.data['e.verify.pending']}
                                                                    </span>
                                                                ) : null}
                                                            </ListItem>
                                                        </button>
                                                    )}
                                                    {/* {!data?.licensePlate && <ListItem preset='blue'>{translations.data['e.verify.plate']}</ListItem>} */}
                                                </ul>

                                                <Uploader
                                                    multiple={false}
                                                    maxImages={1}
                                                    handleUpdate={handleProfileImage}
                                                    params={[mutateData]}
                                                    widgetRef={widgetApi}
                                                />
                                                {/* <div className='text-sm w-full h-full flex tracking-widest uppercase justify-center items-center '>
                                                    
                                                    <UploadGalleryImages multiple={false} maxImages={1} handleUpdate={null} />
                                                </div> */}
                                            </div>
                                            <CardSection arrow handleClick={handlePersonal} tap={true} border='border-b' borderColor='border-gray-200'>
                                                <h2
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                    className={classNames('text-bb-blue-550 text-vw-xl font-bold', rtl && 'text-right')}
                                                >
                                                    {translations.data['e.profile.personalInfo.title']}
                                                </h2>
                                            </CardSection>
                                        </div>
                                    ) : (
                                        <PageLoading bg='bg-white' />

                                        // <CardSkeleton rtl={rtl} type='view' />
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    staticPages: state.staticPages
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loadUser,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageProfile);
