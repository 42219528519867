import { routePath, validateRoute } from 'constants/structRoutes';
import {
    Outlet,
    useParams,
    useNavigate,
    useLocation,
    Navigate
} from 'react-router-dom';
const PageStaticHelp = ({ step }) => {
    let location = useLocation();

    if (validateRoute('static.help', location)) {
        return <div>Page Help Home</div>;
    }

    return <Outlet />;
};

export default PageStaticHelp;
