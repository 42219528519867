import Icons from 'components/icons/index';
import classNames from 'classnames';
import { motion, useCycle } from 'framer-motion';

const btnStyles = {
    primary: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-white',
        bgColor: 'bg-bb-blue-550 ',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false,
        shadow: 'shadow-xl shadow-blue-300/50'
    },
    secondary: {
        tapClass: 'bg-bb-blue-50 text-bb-blue-500 translate-y-0.5',
        color: 'text-gray-500',
        bgColor: 'bg-gray-100',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    pending: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-gray-600',
        bgColor: 'bg-gray-300',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    skeleton: {
        tapClass: 'bg-gray-300 text-gray-600',
        color: 'text-gray-500',
        bgColor: 'bg-gray-100',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    confirmed: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-bb-blue-500',
        bgColor: 'bg-bb-blue-50',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    green: {
        tapClass: 'bg-emerald-900 text-white scale-102a translate-y-0.5',
        color: 'text-emerald-900',
        bgColor: 'bg-emerald-200',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    approve: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-emerald-600',
        bgColor: 'bg-emerald-100',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    cancel: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-white',
        bgColor: 'bg-bb-red-400',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    'cancel-middle': {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-red-600',
        bgColor: 'bg-red-50',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    border: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-bb-blue-500 font-bold',
        bgColor: 'border-bb-blue-500 border-2',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    'border-neutral': {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-gray-600 font-bold',
        bgColor: 'border-gray-400 border-2',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    'rating-100': {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-yellow-500',
        bgColor: 'bg-yellow-100',
        weight: 'font-bold',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    'rating-0': {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-yellow-600',
        bgColor: 'ring-2 ring-yellow-400',
        weight: 'font-bold',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    },
    archive: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-gray-900',
        bgColor: 'ring-2 ring-gray-700',
        disabledClass: 'bg-gray-100 text-gray-300',
        weight: 'font-bold',
        noBg: false
    },
    'dark-gray': {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-white',
        bgColor: 'bg-gray-800',
        disabledClass: 'bg-gray-100 text-gray-300',

        noBg: false
    },
    exceed: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-red-600',
        bgColor: 'bg-red-50',
        disabledClass: 'bg-red-50 text-red-600',
        noBg: false
    },
    default: {
        tapClass: 'bg-gray-900 text-white scale-102a translate-y-0.5',
        color: 'text-gray-500',
        bgColor: 'bg-gray-100',
        disabledClass: 'bg-gray-100 text-gray-300',
        noBg: false
    }
};

const BtnPill = ({
    shadow,
    absolute,
    pulse,
    children,
    handleClick,
    params = [],
    width = 'w-full',
    height = 'h-vw-14 md:h-14',
    gap = 'gap-2',
    type = 'default',
    uppercase = true,
    tracking = 'tracking-wide',
    weight = btnStyles[type]?.weight || 'font-medium',
    disabled,
    disabledClass = btnStyles[type]?.disabledClass || btnStyles.default.disabledClass,

    color = btnStyles[type]?.color || btnStyles.default.color,
    bgColor = btnStyles[type]?.bgColor || btnStyles.default.bgColor,
    noBg = btnStyles[type]?.noBg || btnStyles.default.noBg,

    tapClass = btnStyles[type]?.tapClass || btnStyles.default.tapClass,
    rtl,
    textSize = !rtl ? 'text-vw-sm md:text-xs' : '',
    justify = 'justify-center',
    paddingX = 'px-vw-6 md:px-6',
    margins = 'mb-2 last:mb-0'
}) => {
    const [anim, cycleAnim] = useCycle('off', 'on');

    const handleAction = () => {
        if (!disabled && handleClick) handleClick(...params);
    };

    return (
        <div
            className={classNames(margins, {
                absolute: absolute,
                [absolute]: absolute,
                [width]: !absolute
            })}
        >
            <motion.button
                onClick={handleAction}
                onTouchStart={() => !disabled && cycleAnim()}
                onTouchEnd={() => !disabled && cycleAnim()}
                className={classNames(
                    'select-none flex items-center transform transition-all duration-100 focus:outline-none outline-none rounded-full ',
                    width,
                    weight,
                    height,
                    gap,
                    textSize,
                    justify,
                    paddingX,

                    {
                        [tracking]: !rtl,
                        [btnStyles[type]?.shadow]: shadow,
                        uppercase: uppercase,
                        'animate-pulse': pulse,
                        [color]: anim === 'off' && !disabled,
                        [bgColor]: anim === 'off' && !noBg && !disabled,

                        [tapClass]: anim === 'on' && !disabled,
                        'flex-row-reverse': rtl,
                        [disabledClass]: disabled
                    }
                )}
            >
                {children}
            </motion.button>
        </div>
    );
};

export default BtnPill;
