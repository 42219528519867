import GetLang from 'hoc/GetLang';
import { DateTime } from 'luxon';
import classNames from 'classnames';
const daysOfWeek = [
    '2022-04-11T00:00:00.001',
    '2022-04-12T00:00:00.001',
    '2022-04-13T00:00:00.001',
    '2022-04-14T00:00:00.001',
    '2022-04-15T00:00:00.001',
    '2022-04-16T00:00:00.001',
    '2022-04-17T00:00:00.001'
];

const CalendarHeader = () => {
    return (
        <GetLang>
            {({ translations, lang, rtl }) => (
                <div
                    className={classNames('font-bold mb-vw-6 flex w-full', {
                        'flex-row-reverse': rtl
                    })}
                >
                    {daysOfWeek.map((day) => {
                        return (
                            <div
                                className={classNames(
                                    'w-1/7 flex items-center justify-center flex-none'
                                )}
                                key={day}
                            >
                                <div
                                    className={classNames(
                                        'w-full text-center',
                                        lang === 'ar'
                                            ? 'text-vw-xs'
                                            : 'text-vw-base'
                                    )}
                                >
                                    {DateTime.fromISO(day)
                                        .setLocale(
                                            translations.formatting.locale
                                        )
                                        .toFormat('ccc')}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </GetLang>
    );
};

export default CalendarHeader;
