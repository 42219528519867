import PageStaticTemplate from './PageStaticTemplate';

const PageStaticGender = ({ overlay }) => {
    return (
        <PageStaticTemplate
            overlay={overlay}
            page='static.help.gender'
            apiUrl='/static-pages/6314aacdc9b4d3aa64248904'
            icon='ShieldUser'
            iconColor='text-gray-500'
            keepLoginStep
            analyticsPage='Protecting Everyong > Adding Gender'
        />
    );
};

export default PageStaticGender;
