import React from 'react';
import { useChatContext, useTypingContext } from 'stream-chat-react';
import classNames from 'classnames';

import './TypingIndicator.css';
import GetLang from 'hoc/GetLang';

const statusText = {
    en: {
        one: ' is typing',
        two: [' and ', ' are typing'],
        many: [' and ', ' more are typing']
    },
    fr: {
        one: ' tape un message',
        two: [' et ', ' sont train de taper'],
        many: [' et ', ' autres personnes sont en train de taper']
    },
    ar: {
        one: ' يكتب رسالة',
        two: [' و', ' يكتبان رسالة'],
        many: [' و', ' آخرون يكتبون رسالة']
    }
};

export const TypingIndicator = () => {
    const { client } = useChatContext();
    const { typing } = useTypingContext();

    if (!client || !typing) return null;

    const users = Object.values(typing)
        .filter(({ user }) => user?.id !== client.user?.id)
        .map(({ user }) => user.name || user.id);

    const getTextMessage = (lang) => {
        let text = '';
        if (users.length === 1) {
            text = `${users[0]}${statusText[lang]['one']}`;
        } else if (users.length === 2) {
            text = `${users[0]}${statusText[lang]['two'][0]}${users[1]}${statusText[lang]['two'][1]}`;
        } else if (users.length > 2) {
            text = `${users[0]}${statusText[lang]['many'][0]}${users.length - 1}${statusText[lang]['many'][1]}`;
        }

        return text;
    };

    return (
        <GetLang>
            {({ rtl, translations, lang }) =>
                getTextMessage(lang) && (
                    <div className={classNames('px-vw-4 -mt-vw-4')}>
                        <div
                            className={classNames(
                                'w-full text-vw-sm text-gray-400 font-medium italic flex items-center gap-3 mx-vw-4-a',
                                rtl && 'flex-row-reverse-a justify-start-a'
                            )}
                        >
                            <div className={classNames('bg-gray-100 rounded-xl px-vw-3')}>
                                <div className={classNames('flex gap-1.5 translate-y-vw-1')}>
                                    <div className='animate-bounce h-vw-8 flex items-center'>
                                        <div className='w-vw-2 h-vw-2 bg-gray-400 rounded-full' />
                                    </div>
                                    <div className='animate-bounce animation-delay-150 h-vw-8 flex items-center'>
                                        <div className='w-vw-2 h-vw-2 bg-gray-400 rounded-full' />
                                    </div>
                                    <div className='animate-bounce animation-delay-300 h-vw-8 flex items-center '>
                                        <div className='w-vw-2 h-vw-2 bg-gray-400 rounded-full' />
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ direction: rtl ? 'rtl' : 'ltr' }}>{getTextMessage(lang)}</div> */}
                            {/* <div style={{ direction: rtl ? 'rtl' : 'ltr' }}>FaridForm est en train de taper un message</div> */}
                        </div>
                    </div>
                )
            }
        </GetLang>
    );
};
