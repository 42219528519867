import axios from 'axios';

const axiosConfig = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const fetchWithBody = async (url, body) => {
    try {
        // console.log('SWR called');
        const res = await axios.post(
            `${process.env.REACT_APP_API_BASE}${url}`,
            body,
            axiosConfig
        );
        return res.data;
    } catch (error) {
        throw error.response;
    }
};

export const fetchWithGet = async (url) => {
    try {
        // console.log('SWR called');
        const res = await axios.get(`${process.env.REACT_APP_API_BASE}${url}`);

        return res.data;
    } catch (error) {
        throw error.response;
    }
};

export const fetcher = {
    POST: fetchWithBody,
    GET: fetchWithGet
};
