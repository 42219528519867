import classNames from 'classnames';
import Roundify from 'components/blocks/Roundify';
import Input from 'components/forms/Input';
import ListBox from 'components/forms/ListBox';
import GetLang from 'hoc/GetLang';

const LabelMaker = ({
    id,
    label,
    value,
    list,
    edit,
    type,
    onChangeHandler,
    regex,
    valid,
    minTestLength = 0,
    placeholder,
    suffix,
    disabled,
    visible = true,
    top,
    valueId = 'id'
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) =>
                visible ? (
                    <div className={classNames('flex flex-col gap-1 font-semibold w-full', rtl && 'items-end')}>
                        <div className={classNames('text-gray-400 text-vw-base font-bold flex items-center justify-between2 gap-2', rtl && 'flex-row-reverse')}>
                            <div>{translations.data[label] ?? label}</div>
                            {edit && suffix && <div className={classNames('text-vw-sm ')}>{`(${translations.data[suffix] ?? suffix})`}</div>}
                        </div>
                        {edit ? (
                            list ? (
                                <ListBox id={id ?? 'default'} list={list} selectedValue={value} handleChange={onChangeHandler} top={top} valueId={valueId} />
                            ) : (
                                <Input
                                    disabled={disabled}
                                    id={id ?? 'default'}
                                    obj={{ value }}
                                    handleChange={onChangeHandler}
                                    regex={regex}
                                    owValidation={true}
                                    owValidationValue={valid}
                                    minTestLength={minTestLength}
                                    placeholder={placeholder}
                                />
                            )
                        ) : value ? (
                            <div className={classNames('text-bb-blue-550 text-vw-xl')}>{value?.id ? translations.data?.[value.text] : value}</div>
                        ) : (
                            <Roundify height='h-vw-4' width='w-vw-16' bgColor={'bg-gray-150'} margins='mt-vw-2' />
                        )}
                    </div>
                ) : null
            }
        </GetLang>
    );
};

export default LabelMaker;
