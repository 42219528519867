import { useOnlineStatus } from 'hooks/useOnlineStatus';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const styles = {
    absolute: 'fixed top-4 left-1/2 -translate-x-1/2 z-1000',
    default: 'w-max mx-auto'
};

const OfflineMode = ({ force, position = 'default', margins = 'pt-4 last:py-4' }) => {
    const isOnline = useOnlineStatus();

    return !isOnline || force ? (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <div
                    // style={{ zIndex: 1000 }}
                    className={classNames(margins, position && styles[position], ' select-none pointer-events-none ')}
                >
                    <div
                        className={classNames(
                            lang !== 'ar' && 'uppercase tracking-wider text-vw-xs',
                            lang === 'ar' && 'text-vw-base',
                            'px-4 py-2 rounded-full bg-red-50 text-red-500 border2 border-red-400a  font-medium  shadow-red-400/20'
                        )}
                    >
                        {translations.data['e.offline.title']}
                    </div>
                </div>
            )}
        </GetLang>
    ) : null;
};

export default OfflineMode;
