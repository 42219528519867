import axios from 'axios';
import { CHAT_LOADING, CHAT_LOAD_SUCCESS, CHAT_LOAD_FAIL, CHAT_CLEAR_ERRORS, CHAT_LOGOUT, CHAT_SET_HISTORY, CHAT_SET_CHANNEL_ACTIVE } from './types';

import { loadNamedState, saveNamedState } from 'store/localStorage';

// Load Chat user

export const loadChatUser = (user) => async (dispatch) => {
    const { profile } = user;
    if (!localStorage.chat) {
        try {
            dispatch({
                type: CHAT_LOADING,
                payload: null
            });

            console.log('chat token not found in storage');
            const res = await axios.post(`${process.env.REACT_APP_API_BASE}/chat/token`, { name: profile.firstName, image: profile.avatar });

            dispatch({
                type: CHAT_LOAD_SUCCESS,
                payload: res.data
            });

            saveNamedState('chat', res.data);
        } catch (err) {
            console.log('Some error happened while retrievig token');
            dispatch({
                type: CHAT_LOAD_FAIL,
                payload: err
            });
        }
    } else {
        let chatLocal = loadNamedState('chat');

        if (!chatLocal.chatToken)
            dispatch({
                type: CHAT_LOAD_FAIL,
                payload: 'Missing Token'
            });
        else
            dispatch({
                type: CHAT_LOAD_SUCCESS,
                payload: chatLocal
            });
    }
};

// clear errors
export const setChannelHistory = (history) => async (dispatch) => {
    dispatch({
        type: CHAT_SET_HISTORY,
        payload: history
    });
};

export const setChannelActive = (state) => async (dispatch) => {
    dispatch({
        type: CHAT_SET_CHANNEL_ACTIVE,
        payload: state
    });
};

// clear errors
export const clearChatErrors = () => async (dispatch) => {
    dispatch({
        type: CHAT_CLEAR_ERRORS
    });
};

// Logout chat
export const logoutChat = () => (dispatch) => {
    dispatch({
        type: CHAT_LOGOUT,
        payload: null
    });
};
