import { routePath } from 'constants/structRoutes';
import { useNavigate, useLocation } from 'react-router-dom';
import Itinerary from 'components/blocks/Itinerary';
import AvatarFull from 'components/blocks/AvatarFull';
import { motion, useCycle } from 'framer-motion';
import StatusBooked from 'components/blocks/StatusBooked';
import classNames from 'classnames';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';

import CardSection from './CardSection';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setScroll } from 'store/actions/globalState';
import CardSeats from './CardSeats';
import Icons from 'components/icons/index';
import IconRoundStatus from 'components/blocks/IconRoundStatus';

import { getRecurringDay } from 'helpers/helperFunctions';
import { updateSelectedRides } from 'store/actions/admin';
import { DateTime } from 'luxon';

const CardResult = ({
    updateSelectedRides,
    search,
    data,
    rtl,
    lang,
    setScroll,
    scrollTop = 0,
    adminState: { selectedRides },
    auth: {
        user: { admin, phoneNumber }
    }
}) => {
    const [anim, cycleAnim] = useCycle('off', 'on');

    const navigate = useNavigate();
    const location = useLocation();
    const handleButton = () => {
        // setScroll({ page: 'app.search', top: scrollTop });
        navigate(`${routePath('app.trip')}?id=${data.id}`, {
            state: { from: location.pathname, to: routePath('app.trip') }
        });
    };

    const updateThisCard = () => {
        //check if this ID exists
        if (selectedRides?.find((item) => item?.id === data?.id)) updateSelectedRides(selectedRides.filter((item) => item.id !== data?.id));
        else
            updateSelectedRides([
                ...selectedRides,
                {
                    id: data?.id,
                    driverId: data?.driverId?.id,
                    from: data?.from,
                    to: data?.to,
                    dateTime: `2000-01-01${data?.dateTime.slice(10)}`,
                    ladiesOnly: data?.ladiesOnly,
                    seatsOffered: data?.seatsOffered,
                    recurringDay: DateTime.fromISO(data?.dateTime).weekday - 1
                }
            ]);
        // console.log('day is', DateTime.fromISO(data?.dateTime).weekday - 1);
        // if (selectedRides?.includes(data?.id)) updateSelectedRides(selectedRides.filter((item) => item !== data?.id));
        // else updateSelectedRides([...selectedRides, data?.id]);
    };

    return (
        <>
            <CardSection
                parentAnim={anim}
                border='border-t-8 last:border-b-8'
                borderColor='border-gray-100'
                paddingX=''
                paddingY=''
                containerClass={'flex flex-col justify-between'}
                verticalStack={true}
                swipeMenuOptions={[]}
            >
                {(data?.selfBooking?.status || data?.ladiesOnly || (admin && !data?.recurring)) && (
                    <div
                        className={classNames('z-50a absolute top-vw-6 md:top-6 flex flex-col gap-2', {
                            'left-vw-6 md:left-6': rtl,
                            'right-vw-6 md:right-6': !rtl
                        })}
                    >
                        {data?.selfBooking?.status && (
                            <StatusBooked status={data?.selfBooking?.status} noText archive={data?.archive} ladiesOnly={data?.ladiesOnly} />
                        )}
                        {data?.ladiesOnly && <IconRoundStatus icon={'Female1'} colorPreset={'light-pink'} />}
                        {process.env.REACT_APP_ADMIN === 'enabled' && admin && phoneNumber === '33648517574' && !data?.recurring && (
                            <button onClick={updateThisCard}>
                                <IconRoundStatus
                                    icon={selectedRides?.find((item) => item?.id === data?.id) ? 'Close' : 'Add'}
                                    colorPreset={selectedRides?.find((item) => item?.id === data?.id) ? 'red' : 'light-violet'}
                                />
                            </button>
                        )}
                    </div>
                )}
                <div onTouchStart={() => cycleAnim()} onTouchEnd={() => cycleAnim()} onClick={handleButton}>
                    <div className={`px-vw-6 md:px-6 pt-vw-6 md:pt-6 w-full flex-col md:flex-row md:gap-2 flex ${rtl ? 'items-end' : 'items-start'}`}>
                        <Pill
                            icon={data?.recurring ? 'Repeat1' : 'Calendar2'}
                            sizePreset='small'
                            colorPreset={data?.recurring ? 'light-violet' : data?.archive ? 'dark-gray' : 'blue'}
                        >
                            <FormattedDateTime dateTime={data?.dateTime} date dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null} />
                        </Pill>
                        <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                            <FormattedDateTime dateTime={data?.dateTime} time />
                        </Pill>
                    </div>

                    <Itinerary data={data} search={search} showProximity archive={data?.archive} />
                    <CardSeats data={data} rtl={rtl} label={'e.booking.confirmedBookings'} />
                    <AvatarFull
                        profile={data?.driverId?.profile}
                        blocked={data?.driverId?.blockUser}
                        preset={data?.recurring ? 'default' : data?.archive ? 'archive' : 'default'}
                    />
                </div>
            </CardSection>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    adminState: state.admin
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll,
            updateSelectedRides
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardResult);
