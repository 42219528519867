import * as types from 'store/actions/types';

export function updateSelectedUsers(selectedUsers) {
    return (dispatch) => {
        dispatch({
            type: types.ADMIN_SELECTEDUSERS_UPDATE,
            selectedUsers
        });
    };
}

export function updateSelectedRides(selectedRides) {
    return (dispatch) => {
        dispatch({
            type: types.ADMIN_SELECTEDRIDES_UPDATE,
            selectedRides
        });
    };
}
