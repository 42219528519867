import { Outlet } from 'react-router-dom';

import LayoutNav from './LayoutNav';
import LayoutMenu from './LayoutMenu';
import { PageLoading } from 'pages';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLoading } from 'store/actions/globalState';
import { motion } from 'framer-motion';
import { animations } from 'constants/animations';
import DesktopAlternate from 'components/blocks/DesktopAlternate';
// import BugReport from 'components/blocks/BugReport';

const Layout = ({ toggleLoading, globalState: { isLoading, lang }, auth }) => {
    return (
        <>
            <div className='sm:hidden h-full'>
                {/* <BugReport /> */}
                {isLoading && <PageLoading />}
                <Outlet />
                {auth?.isAuthenticated && (
                    <>
                        <LayoutMenu />
                        <LayoutNav />
                    </>
                )}
            </div>
            <DesktopAlternate />
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleLoading
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
