import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import useMyNavigation from 'hooks/useMyNavigation';
import classNames from 'classnames';
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion';
import { animations, getAnimStates, getReducedMotion } from 'constants/animations';

const PseudoInput = ({
    toPage,
    icon,
    value,
    placeholder,
    width = 'w-full lg:w-72',
    disabled,
    clear,
    show,
    extraFunction,
    justifyContent,
    justifyMain = 'justify-between',
    clearSize = '4vw',
    iconSize = '6vw',
    iconGap = 'gap-4',
    animation
}) => {
    const { handleNavigation, location } = useMyNavigation();
    const handleClick = () => {
        if (!disabled) {
            extraFunction?.();
            handleNavigation(toPage, null);
        }
    };

    return (
        <GetLang>
            {({ tripLang, rtl, translations }) => (
                <div className={classNames(justifyMain, width, 'flex  items-center gap-2', rtl && 'flex-row-reverse')}>
                    <div
                        onClick={handleClick}
                        className={classNames(iconGap, justifyContent, 'w-full flex items-center text-gray-700 leading-6  text-vw-base md:text-base', {
                            'flex-row-reverse': rtl
                        })}
                    >
                        <Icons icon={icon} containerClass='flex-none text-bb-blue-500 ' size={iconSize} />
                        <motion.div
                            {...animation}
                            className={classNames('capitalize font-semibold leading-normal', {
                                'text-right': rtl,
                                'text-gray-600': value,
                                'text-gray-400': !value
                            })}
                        >
                            {value || (placeholder ? translations?.data?.[placeholder] : '')}
                        </motion.div>
                    </div>
                    {clear && show && (
                        <button onClick={clear} className={classNames('')}>
                            <Icons icon={'Close'} containerClass='flex-none text-gray-500' size={clearSize} />
                        </button>
                    )}
                </div>
            )}
        </GetLang>
    );
};

export default PseudoInput;
