import { daysOfWeekStrings } from 'constants/defaultObjects';
import { variousUrls } from 'constants/globalConstants';
import { translations } from 'constants/translations';
import { isDateTimeInPast } from './dates';
import { widthBiggerThan } from './utils';
export const pluralize = (objString, qty, lang) => {
    let localeString;
    if (Number(qty) == Number(1)) localeString = translations?.[lang]?.data?.[objString][1];
    else if (Number(qty) == Number(2)) localeString = translations?.[lang]?.data?.[objString][2];
    else localeString = translations?.[lang]?.data?.[objString][3];

    if (Number(qty) > 10 && lang === 'ar') localeString = translations?.[lang]?.data?.[objString][1];
    const qtyLocale = qty.toLocaleString(translations[lang].formatting.locale);

    if (lang === 'ar') {
        return Number(qty) < 3 ? localeString : `${qtyLocale} ${localeString}`;
    } else {
        return `${qtyLocale} ${localeString}`;
    }
};

// parse search params into an object
export const paramsToObject = (entries) => {
    const paramsObj = {};
    for (const [key, value] of entries) {
        // each 'entry' is a [key, value] tupple
        paramsObj[key] = value;
    }

    return paramsObj;
};

// Check if keys passed in keysArr exist in obj and are not null
export const validateObjParams = (keysArr, obj) => {
    let isValid = false;
    if (Object.keys(obj).length > 0) {
        isValid = keysArr.every((elm) => obj[elm] != null);
    }

    return isValid;
};

export const buildQueryString = (pageParams) => {
    if (pageParams) {
        if (typeof pageParams === 'string') {
            if (pageParams.charAt(0) === '?') {
                return pageParams;
            }
            return '';
        } else {
            return (
                '?' +
                Object.keys(pageParams)
                    .map((key) => {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(pageParams[key]);
                    })
                    .join('&')
            );
        }
    }
    return '';
};

export const getNavParams = ({ location, page, pageParams, isValid }) => {
    let toPage = 'app';
    let navParams;
    // the below is for the back button on a page
    switch (page) {
        case 'app.settings':
            toPage = 'app.searchCarShaing';
            navParams = pageParams;
            break;

        case 'app.book':
            toPage = 'app.trip';
            navParams = pageParams;
            break;

        case 'app.rides.offer':
            if (location.state.from === '/c/notifications') {
                toPage = 'app.notifications';
                navParams = null;
            } else {
                toPage = 'app.rides.offered';
                navParams = null;
            }

            break;
        case 'app.rides.archived.offer':
            if (location.state.from === '/c/notifications') {
                toPage = 'app.notifications';
                navParams = null;
            } else {
                toPage = 'app.rides.offered.archived';
                navParams = null;
            }

            break;
        case 'app.rides.archived.booking':
            if (location.state.from === '/c/notifications') {
                toPage = 'app.notifications';
                navParams = null;
            } else {
                toPage = 'app.rides.booked.archived';
                navParams = null;
            }

            break;

        case 'app.rides.offered.archived':
            toPage = 'app.rides.offered';
            navParams = null;
            break;
        case 'app.rides.booked.archived':
            toPage = 'app.rides.booked';
            navParams = null;
            break;
        case 'app.rides.booking':
            if (location.state.from === '/c/trip' && location.state.fromSearch) {
                toPage = 'app.trip';
                navParams = location.state.fromSearch;
            } else if (location.state.from === '/c/notifications') {
                toPage = 'app.notifications';
                navParams = null;
            } else {
                toPage = 'app.rides.booked';
                navParams = null;
            }

            break;

        case 'app.trip':
            toPage = isValid ? 'app.search' : 'app.searchCarSharing';
            navParams = pageParams;
            break;
        case 'app.search':
            toPage = 'app.searchCarSharing';
            navParams = pageParams;
            break;
        case 'app.search.switch':
            toPage = 'app.search';
            navParams = null;
            break;
        case 'app.searchCarSharing.from':
        case 'app.searchCarSharing.to':
        case 'app.searchCarSharing.seats':
        case 'app.searchCarSharing.date':
        case 'app.searchCarSharing.time':
            toPage = 'app.searchCarSharing';
            navParams = null;
            break;

        case 'app.offerSeats.from':
        case 'app.offerSeats.to':
        case 'app.offerSeats.seats':
        case 'app.offerSeats.date':
        case 'app.offerSeats.time':
            toPage = 'app.offerSeats';
            navParams = null;
            break;
        case 'app.profile.personal':
        case 'app.profile.verify':
        case 'app.profile.reviews':
        case 'app.profile.personal.photo':
        case 'app.profile.password':
            toPage = 'app.profile';
            navParams = null;
            break;

        case 'app.profile.personal.first':
        case 'app.profile.personal.last':
        case 'app.profile.personal.gender':
        case 'app.profile.personal.dob':
        case 'app.profile.personal.email':
        case 'app.profile.personal.mobile':
            toPage = 'app.profile.personal';
            navParams = null;
            break;
        case 'app.profile.verify.verifyId':
        case 'app.profile.verify.verifyEmail':
        case 'app.profile.verify.verifyMobile':
        case 'app.profile.verify.verifyPlate':
        case 'app.profile.verify.verifyDriver':
            toPage = 'app.profile.verify';
            navParams = null;
            break;
        default:
    }

    return { toPage, navParams };
};

export const getUrlParams = (searchParams) => {
    const params = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }
    return params;
};

export const getSendLink = (urlParams) => {
    return variousUrls[urlParams?.utm_source ?? 'other']?.social ?? variousUrls['other'].social;
};

export const getBodyHeight = (status, page, bookings) => {
    // console.log('status', status);
    let parsedStatus = status;
    if (status === 'PUBLISHED' && bookings) parsedStatus = 'PUBLISHED_BOOKED';
    if (status?.startsWith('CANCELED_BY')) parsedStatus = 'CANCELED_BY';
    if (status?.startsWith('DECLINED_BY')) parsedStatus = 'DECLINED_BY';
    if (status?.startsWith('APPROVED_BY')) parsedStatus = 'APPROVED_BY';

    return bodyHeights[page][parsedStatus];
};

export const bodyHeights = {
    'ride.offer': {
        CANCELED: 'min-h-body-w-header-footer-1 md:min-h-body-w-header-footer-1-desktop',
        PUBLISHED: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop',
        PUBLISHED_BOOKED: 'min-h-body-w-header-footer-1 md:min-h-body-w-header-footer-1-desktop',
        DRAFT: 'min-h-body-w-header-footer-1 md:min-h-body-w-header-footer-1-desktop'
    },
    'ride.booking': {
        CANCELED_BY: 'min-h-body-w-header-footer-1 md:min-h-body-w-header-footer-1-desktop',
        DECLINED_BY: 'min-h-body-w-header-footer-1 md:min-h-body-w-header-footer-1-desktop',
        APPROVED_BY: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop',
        PENDING: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop'
    },
    trip: {
        CANCELED_BY: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop',
        DECLINED_BY: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop',
        APPROVED_BY: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop',
        PENDING: 'min-h-body-w-header-footer-2 md:min-h-body-w-header-footer-2-desktop'
    }
};

export const getStatusStyle = (status) => {
    if (status?.startsWith('APPROVED_BY')) return 'APPROVED_BY';
    if (status?.startsWith('CANCELED_BY')) return 'CANCELED_BY';
    if (status?.startsWith('DECLINED_BY')) return 'CANCELED_BY';
    return status;
};

export const getPresetByStatus = (status, archive, recurring) => {
    // console.log('status', status);
    if (recurring) {
        if (archive) return 'dark-gray';
        if (status === 'PUBLISHED') return 'light-violet';
        if (status?.startsWith('APPROVED_BY')) return 'light-violet';
        if (status === 'PENDING') return 'light-violet';
    }
    if (archive) return 'dark-gray';
    if (status === 'PENDING') return 'blue';
    if (status === 'DRAFT') return 'gray';
    if (status === 'PUBLISHED') return 'blue';
    if (status?.startsWith('APPROVED_BY')) return 'blue';
    if (status === 'CANCELED') return 'gray';
    if (status?.startsWith('CANCELED_BY')) return 'gray';
    if (status?.startsWith('DECLINED_BY')) return 'gray';
    return 'blue';
};

export const validateLocation = (locObj) => {
    if (
        locObj?.location?.coordinates?.length == 2 &&
        locObj?.place_id &&
        locObj?.en?.formatted &&
        locObj?.ar?.formatted &&
        locObj?.en?.admin_l1 &&
        locObj?.ar?.admin_l1 &&
        locObj?.en?.admin_l2 &&
        locObj?.ar?.admin_l2
    )
        return true;
    return false;
};

export const validateDateTime = (date, time) => {
    if (time && date) {
        const d = new Date(`${date}T${time}`);
        // console.log('date, time', date, time);
        // console.log('attempted time d is:', d);

        return d instanceof Date && !isNaN(d);
    }
    return false;
};

export const validateEmptyWeek = (dateRangeObj, returnObj = false) => {
    let enabledArr = [];
    let daysFill = new Array(7).fill(2);
    daysFill.forEach((day, index) => {
        enabledArr[index] = dateRangeObj[index];
    });

    return returnObj ? enabledArr.filter((day) => day.enabled) : enabledArr.some((elm) => elm.enabled);
};

export const validateSelectedDays = (dateRangeObj) => {
    const selectedDays = validateEmptyWeek(dateRangeObj, true);
    console.log({ selectedDays });
    if (selectedDays?.length > 0) {
        const selectedDaysValidity = selectedDays.map((day) => (day.time || dateRangeObj.time) && (day.seats || dateRangeObj.seats));
        return selectedDaysValidity.every((elm) => elm);
    }
    return false;
};

export const getSelectedDaysArr = (dateRangeObj) => {
    const daysArr = [0, 1, 2, 3, 4, 5, 6];
    const days = [];
    daysArr.forEach((day) => {
        if (dateRangeObj[day].enabled)
            days.push({
                recurringDay: day,
                time: new Date(`2000-01-01T${dateRangeObj?.[day]?.time || dateRangeObj?.time || '00:00'}`).toISOString(),
                seats: dateRangeObj?.[day]?.seats || dateRangeObj?.seats || 1
            });
    });
    return days;
};

export const validateDateRageEmpty = (dateRangeObj) => {
    const selectedDays = validateEmptyWeek(dateRangeObj, true);

    if (selectedDays?.length > 0 || dateRangeObj?.time || dateRangeObj?.seats > 1) return true;

    return false;
};

export const validateTrip = (publish) => {
    const { seatsOffered, from, to, date, time, dateRange, recurring } = publish;
    if (!recurring) {
        if (seatsOffered && validateLocation(from) && validateLocation(to) && validateDateTime(date, time) && !isDateTimeInPast(`${date}T${time}`)) return true;
        return false;
    } else {
        if (validateLocation(from) && validateLocation(to) && validateSelectedDays(dateRange)) return true;
        return false;
        // valide location from
        // valid location to
        // at least one day selected
        // for each day verify that they have a time and seats (ie validate selected days)
    }
};

export const capitalize = (string) => {
    return string?.[0].toUpperCase() + string?.slice(1).toLowerCase();
};

export const getRecurringDay = (recurringDay, lang) => {
    return `${translations[lang].data['e.days.every']} ${daysOfWeekStrings[lang][recurringDay.toString()].full.slice(lang === 'ar' ? 2 : 0)}${
        lang === 'fr' ? 's' : ''
    }`;
};
