import PageStaticTemplate from './PageStaticTemplate';

const PageStaticTerms = ({ overlay }) => {
    return (
        <PageStaticTemplate
            overlay={overlay}
            page='static.help.terms'
            apiUrl='/static-pages/62fb920f5d780aac0c00c9d0'
            icon='FileList3'
            keepLoginStep
            analyticsPage='Terms and Conditions'
        />
    );
};

export default PageStaticTerms;
