import { defaultSize, defaultStroke } from './defaults';
const IconLogoBlue = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 256 256'
                width={size || defaultSize}
                height={size || defaultSize}
            >
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    {/* <rect width='256' height='256' fill='#fff' /> */}
                    <g
                        id='Layer_2'
                        data-name='Layer 2'
                        transform='translate(79 19.575)'
                    >
                        <g id='Layer_1' data-name='Layer 1'>
                            <path
                                id='Path_7'
                                data-name='Path 7'
                                d='M97.287,83.9c0,28.814-18.306,48.816-42.032,48.816-11.867,0-20.339-4.935-26.441-12.2v10H0V0H28.814V47.8c5.932-7.628,14.744-12.715,26.611-12.715C78.644,35.082,97.287,54.914,97.287,83.9Zm-29.164,0c0-12.877-8.475-21.353-19.661-21.353S28.814,71.017,28.814,83.9c0,13.052,8.475,21.357,19.661,21.357,11.015-.009,19.661-8.314,19.661-21.357Z'
                                fill='#00a'
                            />
                            <path
                                id='Path_8'
                                data-name='Path 8'
                                d='M63.014,379.773a4.531,4.531,0,0,1,2.759-2.033c.965-.166,2.382.516,4.227,2.033.754.866,1.759,1.893,3,3.087s2.544,2.517,3.9,3.98,2.786,3.006,4.3,4.63,2.979,3.248,4.388,4.873a5.721,5.721,0,0,1,1.624,3.082,4.572,4.572,0,0,1-.974,3.589L73.571,422.98a5.9,5.9,0,0,1-1.7,1.867,1.45,1.45,0,0,1-1.3.161,4.039,4.039,0,0,1-1.216-.9,12.181,12.181,0,0,0-1.288-1.131L49.383,407.725a2.837,2.837,0,0,1-.489-3.329,27.019,27.019,0,0,1,2.118-4.159q3.082-5.689,6.008-10.961a89.648,89.648,0,0,1,5.994-9.5Z'
                                transform='translate(-26.783 -208.243)'
                                fill='#e1b03d'
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconLogoBlue;
