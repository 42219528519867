import * as types from 'store/actions/types';
import { updateOnboarding } from './onboarding';
import { loadNamedState, saveNamedState } from 'store/localStorage';
import store from 'store';
import { updateProfile } from 'fetch/apiRequests';
import { appVersion } from 'constants/globalConstants';

export const loadAppSettings = () => {
    if (localStorage.globalState) {
        let globalStateLocal = loadNamedState('globalState');

        if (globalStateLocal) {
            // dispatch change to globalState
            store.dispatch(toggleLang(globalStateLocal.lang));
            store.dispatch(toggleReducedMotion(globalStateLocal.reducedMotion));
            store.dispatch(toggleTime(globalStateLocal.time24));
        }
    } else {
        saveNamedState('globalState', store.getState().globalState);
    }

    if (localStorage.onboarding) {
        let onboardingLocal = loadNamedState('onboarding');

        if (onboardingLocal) {
            // dispatch change to globalState
            store.dispatch(updateOnboarding(onboardingLocal));
        }
    } else {
        saveNamedState('onboarding', store.getState().onboarding);
    }

    if (localStorage.version) {
        let versionLocal = loadNamedState('version');
        if (versionLocal !== appVersion) {
            localStorage.removeItem('version');
            window?.sessionStorage.clear();
            window.location.reload();
        }
    } else {
        // console.log('version not found. clearing and adding');
        window?.sessionStorage.clear();

        saveNamedState('version', appVersion);
        window.location.reload();
    }
};

export function loadSettings(globalState) {
    return (dispatch) => {
        dispatch({
            type: types.LOAD_APP_SETTINGS,
            globalState
        });
    };
}

export function toggleNav(navIsOpen) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_NAV,
            navIsOpen
        });
    };
}

export function toggleLang(lang) {
    saveNamedState('globalState', { ...store.getState().globalState, lang });

    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_LANG,
            lang
        });

        // if (localStorage.getItem('lang') !== lang)
        //     localStorage.setItem('lang', lang);
    };
}

export function toggleTime(time24) {
    saveNamedState('globalState', { ...store.getState().globalState, time24 });
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_TIME,
            time24
        });

        // if (localStorage.getItem('lang') !== lang)
        //     localStorage.setItem('lang', lang);
    };
}

export function toggleTab(page, tab) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_TAB,
            page,
            tab
        });
    };
}

export function toggleReducedMotion(reducedMotion) {
    saveNamedState('globalState', {
        ...store.getState().globalState,
        reducedMotion
    });
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_REDUCEDMOTION,
            reducedMotion
        });

        // if (localStorage.getItem('lang') !== lang)
        //     localStorage.setItem('lang', lang);
    };
}

export function toggleLoading(isLoading) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_LOADING,
            isLoading
        });
    };
}

export function toggleTransition(isTransition) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_TRANSITION,
            isTransition
        });
    };
}

export function setScroll(scroll) {
    return (dispatch) => {
        dispatch({
            type: types.SET_SCROLL,
            scroll
        });
    };
}

export function setNewNotifications(newNotifications) {
    return (dispatch) => {
        dispatch({
            type: types.NEW_NOTIFICATIONS_SET,
            newNotifications
        });
    };
}
