import BtnIcon from 'components/buttons/BtnIcon';
import GetLang from 'hoc/GetLang';
import React, { useCallback, useContext, useEffect } from 'react';

import { ChatAutoComplete, EmojiPicker, useChannelStateContext, useMessageInputContext, useChatContext } from 'stream-chat-react';
import classNames from 'classnames';

import { EmojiIcon, SendIcon } from '../../assets';

import './MessagingInput.css';
import Icons from 'components/icons/index';
import { TypingIndicator } from '../index';
import { defaultSize } from 'components/icons/defaults';
import { useSearchParams } from 'react-router-dom';
import { paramsToObject } from 'helpers/helperFunctions';
import { useState } from 'react';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { chatNotification } from 'fetch/apiRequests';
import useDidMountEffect from 'hooks/useDidMountEffect';

const MessagingInput = (props) => {
    let [searchParams] = useSearchParams();
    const params = paramsToObject(searchParams.entries());
    const { messages, channel } = useChannelStateContext();
    const [channelHistory, setChannelHistory] = useState({});
    const [otherUser, setOtherUser] = useState(null);
    const messageInput = useMessageInputContext();
    const { client } = useChatContext();
    const { banned } = props;
    // const [shouldNotify, setShouldNotify] = useState(!!params.otherUser);
    const [shouldNotify, setShouldNotify] = useState(false);

    const delayToNotifyInSecsOffline = 1 * 60; // 1 mins
    const delayToNotifyInSecsOnline = 3 * 60; // 3 mins

    // after mount and on first dep change
    useDidMountEffect(() => {
        // check date between last message sent

        // check first if online
        let diff;
        if (channelHistory?.last_message_at) {
            diff = Math.floor((new Date() - new Date(channelHistory?.last_message_at)) / 1000);
        }
        const isOtherUserOnline = channel?.state?.members?.[otherUser]?.user?.online;

        if (isOtherUserOnline) {
            console.log('last message in seconds', diff);
            if (diff >= delayToNotifyInSecsOnline && otherUser) {
                setShouldNotify(true);
            }
        } else {
            console.log('last message in seconds', diff);
            if ((diff == null || diff >= delayToNotifyInSecsOffline) && otherUser) {
                setShouldNotify(true);
            }
        }

        setChannelHistory((prev) => ({ ...prev, last_message_at: channel.state.last_message_at }));
    }, [channel.state.last_message_at]);

    useEffect(() => {
        if (shouldNotify && otherUser) {
            // send message
            handleChatNotification(otherUser);
            console.log('am going to notify user of this msg');
            setShouldNotify(false);
        }
    }, [shouldNotify]);

    useEffect(() => {
        if (channel.state.last_message_at) {
            setChannelHistory((prev) => ({ ...prev, last_message_at: channel.state.last_message_at }));
        }

        const singleMember = Object.values(channel.state.members || {}).filter((member) => member.user_id !== client.user?.id);

        setOtherUser(singleMember[0]?.user_id);

        console.log('last message is', channel.state.last_message_at);
        console.log('other user is', singleMember[0]?.user_id);
    }, []);

    const handleChatNotification = async (userId) => {
        await chatNotification(userId);
    };

    const handleSend = (e) => {
        console.log('sending message');
        console.log(channel);
        // if (shouldNotify) {
        //     console.log('Also sending user a notification');
        //     setShouldNotify(false);
        // } else console.log('Not sending user a notification');
        // setShouldNotify(true);
        messageInput.handleSubmit(e);
    };

    const onChange = useCallback(
        (event) => {
            const { value } = event.target;

            messageInput.handleChange(event);
        },
        [messageInput.text] // eslint-disable-line
    );

    const isOnline = useOnlineStatus();

    const { closeEmojiPicker, emojiPickerIsOpen, handleEmojiKeyDown, handleSubmit, openEmojiPicker, text } = messageInput;

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <>
                    {/* <TypingIndicator /> */}
                    {!banned && isOnline ? (
                        <div
                            style={{ direction: rtl ? 'rtl' : 'ltr' }}
                            className={classNames(
                                'absolute-a bottom-0-a w-full-a px-vw-4 md:px-12 relative py-vw-2 md:py-2 flex justify-between gap-2 min-h-vw-16 md:min-h-20 md:max-h-40 max-h-vw-36 bg-gray-100 items-center'
                            )}
                        >
                            <div
                                className={classNames(
                                    'min-h-10 messaging-input__input-wrapper2  bg-white flex gap-2 items-center rounded-2xl grow-1 w-full overflow-y-hidden',
                                    rtl ? 'pr-vw-4 md:pr-4 flex-row-reverse2' : 'pl-vw-4 md:pl-4'
                                )}
                            >
                                <div
                                    className='messaging-input__button emoji-button2 '
                                    role='button'
                                    aria-roledescription='button'
                                    onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
                                    ref={messageInput.emojiPickerRef}
                                >
                                    <EmojiIcon />
                                </div>

                                <ChatAutoComplete
                                    disabled
                                    onChange={onChange}
                                    rows={1}
                                    placeholder={translations.data['e.sendMessage']}
                                    handleSubmit={handleSend}
                                />
                            </div>

                            <div
                                role='button'
                                aria-roledescription='button'
                                onClick={handleSend}
                                className={classNames(
                                    'w-vw-12 h-vw-12 md:w-12 md:h-12 flex flex-none items-center justify-center transition-colors duration-100 focus:outline-none rounded-full',

                                    {
                                        ['bg-gray-100 text-gray-300']: !text,
                                        ['bg-bb-blue-500 text-white']: text
                                    }
                                )}
                            >
                                <Icons icon={'Plane1'} size={defaultSize} flip={rtl} />
                            </div>

                            <EmojiPicker />
                        </div>
                    ) : null}
                </>
            )}
        </GetLang>
    );
};

export default React.memo(MessagingInput);
