import { routePath } from 'constants/structRoutes';
import { useNavigate, useLocation } from 'react-router-dom';
import Itinerary from 'components/blocks/Itinerary';
import StatusOffered from 'components/blocks/StatusOffered';
import classNames from 'classnames';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getPresetByStatus, getRecurringDay } from 'helpers/helperFunctions';

import StatusBooked from 'components/blocks/StatusBooked';

import CardSection from './CardSection';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setScroll } from 'store/actions/globalState';
import CardSeats from './CardSeats';
import IconRoundStatus from 'components/blocks/IconRoundStatus';

const CardOffered = ({ setMode, setCurrentId, data, rtl, lang, archive }) => {
    const path = archive ? 'app.rides.archived.offer' : 'app.rides.offer';

    const navigate = useNavigate();
    const location = useLocation();
    const handleButton = () => {
        navigate(`${routePath(path)}?id=${data.id}`, {
            state: { from: location.pathname, to: routePath(path) }
        });
    };

    const pendingCount = data?.booking?.PENDING ?? 0;
    const approvedCount = data?.booking?.APPROVED_BY_ADMIN + data?.booking?.APPROVED_BY_DRIVER;
    const totalCount = Number(pendingCount) + Number(approvedCount);

    const handleMenuClick = (status) => {
        if (setCurrentId && setMode) {
            setCurrentId(data?._id);
            setMode(status);
        }
    };

    // data?.bookings?.length === 0
    const getSwipeMenuOptions = (status) => {
        const swipeMenuOptions = [];

        !archive &&
            data?.status === 'DRAFT' &&
            swipeMenuOptions.push({
                icon: 'UploadCloud',
                handleClick: handleMenuClick,
                params: ['PUBLISH_RIDE']
            });

        !archive &&
            data?.status === 'PUBLISHED' &&
            swipeMenuOptions.push({
                icon: 'CloseCircle',
                handleClick: handleMenuClick,
                params: ['CANCEL_RIDE']
            });
        !archive &&
            data?.status === 'PUBLISHED' &&
            totalCount === 0 &&
            swipeMenuOptions.push({
                icon: 'Unpublish',
                handleClick: handleMenuClick,
                params: ['DRAFT_RIDE']
            });
        !archive &&
            data?.status === 'CANCELED' &&
            !data?.archive &&
            swipeMenuOptions.push({
                icon: 'Archive',
                handleClick: handleMenuClick,
                params: ['ARCHIVE_RIDE']
            });
        !archive &&
            data?.status !== 'PUBLISHED' &&
            swipeMenuOptions.push({
                icon: 'Trash',
                handleClick: handleMenuClick,
                params: ['DELETE_RIDE']
            });

        archive &&
            !data.archive &&
            swipeMenuOptions.push({
                icon: 'Archive',
                handleClick: handleMenuClick,
                params: ['ARCHIVE_RIDE']
            });
        archive &&
            data?.status !== 'PUBLISHED' &&
            swipeMenuOptions.push({
                icon: 'Trash',
                handleClick: handleMenuClick,
                params: ['DELETE_RIDE']
            });

        return swipeMenuOptions;
    };

    return (
        <>
            <CardSection
                runOnOpen={() => setCurrentId?.(data?._id)}
                runOnClosed={() => setCurrentId?.(null)}
                border='border-t-8 last:border-b-8'
                borderColor='border-gray-100'
                paddingX=''
                paddingY=''
                containerClass={'flex flex-col justify-between'}
                // borderColor='border-gray-200'
                verticalStack={true}
                handleClick={handleButton}
                swipeMenuOptions={getSwipeMenuOptions(data?.driverId?._id)}
            >
                <div
                    className={classNames('z-50a absolute top-vw-6 md:top-6 flex flex-col gap-2', {
                        'left-vw-6 md:left-6': rtl,
                        'right-vw-6 md:right-6': !rtl
                    })}
                >
                    <StatusOffered status={data?.status} noText archive={data?.archive || archive} recurring={data?.recurring} />
                    {data?.ladiesOnly ? <IconRoundStatus icon={'Female1'} colorPreset='light-pink' /> : null}
                </div>

                <div>
                    <div className={`px-vw-6 pt-vw-6 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                        <Pill
                            icon={data?.recurring ? 'Repeat1' : 'Calendar2'}
                            sizePreset='small'
                            colorPreset={getPresetByStatus(data?.status, data?.archive || archive, data?.recurring)}
                        >
                            <FormattedDateTime dateTime={data?.dateTime} date dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null} />

                            {/* <FormattedDateTime dateTime={data?.dateTime} date /> */}
                        </Pill>
                        <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                            <FormattedDateTime dateTime={data?.dateTime} time />
                        </Pill>
                    </div>

                    <Itinerary data={data} archive={data?.archive || archive} />
                    <CardSeats data={data} archive={archive} rtl={rtl} label={'e.booking.bookings'} />
                </div>
            </CardSection>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardOffered);
