import imageEmpty from 'components/images/bb_empty_small.png';
import chatEmpty from 'components/images/bb_empty_chat.png';
import notificationEmpty from 'components/images/bb_empty_notifications_2.png';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import HeadlineType1 from './HeadlineType1';
import Paragraph from './Paragraph';

const images = {
    noResults: {
        image: imageEmpty,
        size: 'h-vh-24'
    },
    noChannels: {
        image: chatEmpty,
        size: 'h-vh-44'
    },
    noNotifications: {
        image: notificationEmpty,
        size: 'h-vh-44'
    }
};

const NoResults = ({ title = 'e.nothingFound.booking.title', description = 'e.nothingFound.booking.description', image = 'noResults', noImage }) => {
    return (
        <GetLang>
            {({ translations, rtl }) => (
                <div className={classNames('w-full')}>
                    {!noImage && (
                        <div className={classNames('w-full flex justify-center object-contain', images[image].size ?? images['noResults'].size)}>
                            <img src={images[image].image ?? images['noResults'].image} />
                        </div>
                    )}
                    <div className={classNames(!noImage && 'mt-vw-8')}>
                        <HeadlineType1 textSize='text-vw-2xl' margins='mb-vw-4'>
                            {translations?.data?.[title]}
                        </HeadlineType1>

                        <Paragraph preset='small-faded' margins='mb-vw-8'>
                            {translations?.data?.[description]}
                        </Paragraph>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default NoResults;
