/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    page: null,
    payload: null
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const setStaticPage = (draft, action) => {
    const { page, payload } = action;

    draft.page = page;
    draft.payload = payload;
    return draft;
};

export default createReducer(initialState, {
    [types.STATIC_SET_PAGE]: setStaticPage
});
