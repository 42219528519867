import { defaultSize, defaultStroke } from './defaults';
const IconSlash = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <circle cx='12' cy='12' r='10'></circle>
                <line x1='4.93' y1='4.93' x2='19.07' y2='19.07'></line>
            </svg>
        </>
    );
};

export default IconSlash;
