import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputLocation from 'components/forms/InputLocation';
import { updateSearchLocation } from 'store/actions/search';
import useMyNavigation from 'hooks/useMyNavigation';

import GetLang from 'hoc/GetLang';

const LayoutFindLocation = ({ updateSearchLocation, search: { search, isValid }, source }) => {
    const handleSetLocation = (location, source) => {
        updateSearchLocation(location, source);
    };

    const { handleNavigation, location } = useMyNavigation();

    const handleNavAction = () => {
        handleNavigation('app.searchCarSharing', null);
    };

    return (
        <GetLang>
            {({ tripLang, lang, rtl }) => (
                <div className={'flex flex-col gap-4'}>
                    <InputLocation
                        source={source}
                        handleSetLocation={handleSetLocation}
                        currentValue={search?.[source]?.[tripLang]?.formatted}
                        handleNavAction={handleNavAction}
                    />
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    results: state.results
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateSearchLocation
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutFindLocation);
