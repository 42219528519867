import { connect } from "react-redux";
import { LayoutPage } from "components/layouts";
import { bindActionCreators } from "redux";
import OfflineMode from "components/blocks/OfflineMode";
import classNames from "classnames";
import IconRound from "components/blocks/IconRound";
import { setStaticPage } from "store/actions/staticPages";
import { loginSetStep } from "store/actions/auth";
import { useEffect } from "react";
import BtnPill from "components/buttons/BtnPill";
import BtnIcon from "components/buttons/BtnIcon";
import { motion } from "framer-motion";
import {
  animations,
  getAnimStates,
  getReducedMotion,
} from "constants/animations";
import { analytics } from "helpers/segment";
const PageStaticTemplateV2 = ({
  analyticsPage = "Untitled",
  loginSetStep,
  setStaticPage,
  page,
  apiUrl,
  overlay,
  iconColor,
  auth: { setupStep },
  keepLoginStep,
  globalState: { reducedMotion },
}) => {
  const pageData = {
    page,
    pageRedirect: "app.searchCarSharing",
    apiUrl,
    backBtn: true,
    zIndex: 41,
    btnIcon: "Close",
    btnFlip: true,
    checkIdParam: false,
    crudType: "GET",
    singleResult: true,
    overlay: true,
    iconColor,
  };

  const closeModal = () => {
    setStaticPage(null);
  };

  const validateSetup = () => {
    if (setupStep !== "language" && !keepLoginStep) {
      loginSetStep("language");
    }
  };

  useEffect(() => {
    setTimeout(validateSetup, 600);
  }, []);

  useEffect(() => {
    analytics.page(analyticsPage, "Static");
  }, []);

  const getDataLang = (data, lang) => {
    switch (lang) {
      case "en":
        return {
          title: data?.enTitle,
          desc: data?.enDescription,
        };
      case "fr":
        return {
          title: data?.frTitle ?? data?.enTitle,
          desc: data?.frDescription ?? data?.enDescription,
        };
      case "ar":
        return {
          title: data?.arTitle ?? data?.enTitle,
          desc: data?.arDescription ?? data?.enDescription,
        };
      default:
        return;
    }
  };

  const animStates = {
    default: getAnimStates("app.settings").animPages,
  };

  return (
    <LayoutPage pageData={pageData}>
      {({
        rtl,
        lang,
        translations,
        data,
      }) => {
        return (
          <>
            {data?.length ? (
              <div>
                <motion.div
                  key="latestNews1"
                  className="fixed inset-0 bg-bb-blue-900/50 shadow-2xl-a rounded-3xl-a overflow-y-auto-a no-scrollbar-a"
                  initial={"fadeOut"}
                  animate={"in"}
                  variants={animations.pageSlides}
                  exit={() =>
                    getReducedMotion("page", animStates.default, reducedMotion)
                      .exit
                  }
                ></motion.div>

                <motion.div
                  className={classNames("text-vw-base relative  h-screen-100")}
                  key="latestNews2"
                  initial={
                    getReducedMotion("page", animStates.default, reducedMotion)
                      .initial
                  }
                  animate={
                    getReducedMotion("page", animStates.default, reducedMotion)
                      .animate
                  }
                  variants={animations.pageSlides}
                  exit={() =>
                    getReducedMotion("page", animStates.default, reducedMotion)
                      .exit
                  }
                >
                  <div className="bg-white shadow-2xl shadow-bb-blue-900/50 rounded-3xl w-vw-90  absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                    <div
                      className={classNames(
                        "absolute inset-x-0 h-vw-80 top-0 flex rounded-t-3xl bg-gradient-to-t from-white to-gray-100",
                        rtl ? "justify-start" : "justify-end"
                      )}
                    ></div>
                    <div
                      className={classNames(
                        "absolute inset-x-0 h-vw-12  top-0 z-100 flex rounded-t-3xl",
                        rtl ? "justify-start" : "justify-end"
                      )}
                    >
                      <BtnIcon
                        color={iconColor}
                        icon={"Close"}
                        handleClick={closeModal}
                        noBg
                      />
                    </div>
                    <IconRound
                      icon={"List1"}
                      preset="yellow"
                      pos="top-center-normal"
                      zDepth="z-100"
                    />
                    <div className="relative  h-full w-full overflow-y-auto no-scrollbar max-h-screen-90 pt-vw-4 pb-vw-20">
                      <div
                        className={`list-none w-full  bg-bb-blue-700/50-a  bordera relative ring-8a transition-all duration-300 `}
                        style={{ direction: rtl ? "rtl" : "ltr" }}
                      >
                        <div
                          className={classNames(
                            overlay && "h-full flex flex-col justify-between",
                            "px-vw-6 pt-vw-6 pb-vw-6  relative w-full"
                          )}
                        >
                          <div>
                            <OfflineMode margins=" my-6" />
                            <div className={"mb-vw-6"}>
                              <h1
                                className={classNames(
                                  "text-bb-blue-550 text-2xl text-center font-bold decoration-4 underline decoration-yellow-400 underline-offset-4"
                                )}
                              >
                                {translations.data["e.latestNews.title"]}
                              </h1>
                            </div>
                            {data?.map((item) => (
                              <section key={item._id}>
                                <div
                                  className={classNames(
                                    "w-full bg-bb-blue-50 text-bb-blue-550 flex justify-center h-vw-14 items-center rounded-lg text-vw-base font-semibold tracking-widest"
                                  )}
                                >
                                  {item?.title}
                                </div>
                                <div
                                  className={"static logs"}
                                  dangerouslySetInnerHTML={{
                                    __html: getDataLang(item, lang)?.desc,
                                  }}
                                />
                              </section>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "absolute bottom-0 rounded-b-3xl bg-white shadow-2xl-top shadow-bb-blue-800/5 gap-2 w-full flex flex-col justify-center items-center px-vw-6 py-vw-6"
                      )}
                    >
                      <BtnPill
                        type="primary"
                        justify={"justify-center"}
                        rtl={rtl}
                        params={[false]}
                        handleClick={closeModal}
                      >
                        {translations?.data?.["e.static.btn.close"]}
                      </BtnPill>
                    </div>
                  </div>
                </motion.div>
              </div>
            ) : null}
          </>
        );
      }}
    </LayoutPage>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  globalState: state.globalState,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setStaticPage,
      loginSetStep,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageStaticTemplateV2);
