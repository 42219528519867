import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { routePath } from 'constants/structRoutes';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnIcon from 'components/buttons/BtnIcon';

import { getRelativeDate, isDateInPast, isDateTimeInPast } from 'helpers/dates';
import { togglePublishSwitching, updatePublishTime } from 'store/actions/publish';
import BtnPill from 'components/buttons/BtnPill';
import PseudoInput from 'components/forms/PseudoInput';
import InputShell from 'components/forms/InputShell';
import { useLayoutEffect, useEffect } from 'react';
import { updatePublishDate } from 'store/actions/publish';
import { DateTime } from 'luxon';
import { publishRide } from 'fetch/apiRequests';
import { validateDateTime, validateTrip } from 'helpers/helperFunctions';
import ModalBottom from 'components/modals/ModalBottom';
import { useState } from 'react';
import { PageLoading } from 'pages/index';
import PosterImage from 'components/blocks/PosterImage';
import NotificationBox from 'components/blocks/NotificationBox';
import LayoutPageSubHeader from 'components/layouts/LayoutPageSubHeader';

const PageProfileVerify = ({ togglePublishSwitching, updatePublishTime, globalState: { lang, time24 }, publish: { publish, isValid } }) => {
    const pageData = {
        page: `app.profile.verify`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [response, setResponse] = useState(null);
    const [retry, setRetry] = useState(false);
    const { handleNavigation, location } = useMyNavigation();

    // const handlePublish = async () => {
    //     await publishRide(publish);
    // };

    const handleModalClose = () => {
        setIsModal(false);
        setResponse(null);
    };

    const handleRetry = () => {
        setResponse(null);
        handleAction();
    };

    const handleAction = async () => {
        setResponse(null);
        setIsModal(false);
        setIsModalAction(true);
        const res = await publishRide(publish);
        setResponse(res?.status?.toString() || '5xx');
        // console.log('res', res);
        setIsModalAction(false);
    };

    useLayoutEffect(() => {
        const isToday = isDateInPast(publish.date);
        if (isToday) updatePublishDate([isToday]);
        if (!validateDateTime(publish.date, publish.time)) updatePublishTime('');
    }, []);

    useEffect(() => {
        if (response) setIsModal(true);
    }, [response]);

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({ rtl, lang, tripLang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation }) => {
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}></LayoutPageHeader>
                        <LayoutPageBody paddingBottom=''>
                            <div className={`select-none`}>
                                <div className={classNames('-mt-8 px-6 pt-12 pb-6 flex flex-col bg-white rounded-t-3xl relative gap-4 ')}>
                                    <div className={classNames('absolute top-0 transform left-1/2 -translate-x-1/2 -translate-y-1/2')}>
                                        <BtnIcon
                                            tapClass='bg-yellow-50 text-yellow-600'
                                            color='text-yellow-600'
                                            bgColor='bg-bb-yellow-400'
                                            icon='ArrowUpDown1'
                                            iconSize='24'
                                            handleClick={togglePublishSwitching}
                                        />
                                    </div>
                                    <InputShell>
                                        <PseudoInput
                                            toPage='app.offerSeats.from'
                                            icon='MapPin1'
                                            placeholder='e.page.search.from'
                                            value={publish?.from[tripLang].formatted}
                                        />
                                    </InputShell>
                                    <InputShell>
                                        <PseudoInput
                                            toPage='app.offerSeats.to'
                                            icon='MapPin1'
                                            placeholder='e.page.search.to'
                                            value={publish?.to[tripLang].formatted}
                                        />
                                    </InputShell>
                                    <InputShell>
                                        <PseudoInput toPage='app.offerSeats.date' icon='Calendar2' value={getRelativeDate(publish?.date, lang)} />
                                    </InputShell>
                                    <InputShell>
                                        <PseudoInput
                                            toPage='app.offerSeats.time'
                                            icon='Time'
                                            value={
                                                validateDateTime(publish.date, publish.time)
                                                    ? DateTime.fromISO(`${publish.date}T${publish.time}`).toFormat(time24 ? 'HH:mm' : 'hh:mm a')
                                                    : ''
                                            }
                                            placeholder={'e.page.publish.time'}
                                            width='w-4/5'
                                        />
                                        <PseudoInput toPage='app.offerSeats.seats' icon='User' value={publish?.seatsOffered} width='' />
                                    </InputShell>
                                </div>
                            </div>
                        </LayoutPageBody>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            togglePublishSwitching,
            updatePublishTime
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageProfileVerify);
