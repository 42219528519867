import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody, LayoutPageFooter } from 'components/layouts';
import { BtnRequestView, BtnRequestSend, BtnContactDriver, BtnRequestConfirm } from 'components/buttons';
import { bindActionCreators } from 'redux';
import Itinerary from 'components/blocks/Itinerary';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations } from 'constants/animations';
import { motion, LayoutGroup } from 'framer-motion';
import ModalBottom from 'components/modals/ModalBottom';
import { useState } from 'react';
import { makeBooking } from 'fetch/apiRequests';

import BtnPill from 'components/buttons/BtnPill';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import OfflineMode from 'components/blocks/OfflineMode';
import StatusTrip from 'components/blocks/StatusTrip';
import useMyNavigation from 'hooks/useMyNavigation';
import classNames from 'classnames';
import StatusOffered from 'components/blocks/StatusOffered';
import CardSection from 'components/cards/CardSection';
import CardUserBis from 'components/cards/CardUserBis';
import CardSeats from 'components/cards/CardSeats';
import BtnMiddleAction from 'components/buttons/BtnMiddleAction';
import MessageBox from 'components/blocks/MessageBox';
import { setStaticPage } from 'store/actions/staticPages';
import IconRound from 'components/blocks/IconRound';
import { analytics } from 'helpers/segment';
import { useEffect } from 'react';
import Icons from 'components/icons/index';
import { getRecurringDay } from 'helpers/helperFunctions';

const PageTripView = ({ setStaticPage, auth: { user, isAuthenticated }, search: { search }, onboarding: { tooltips } }) => {
    const pageData = {
        page: 'app.trip',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/rides/view',
        backBtn: true,
        zIndex: 41,
        checkIdParam: true
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [isExceed, setIsExceed] = useState(true);
    const handleAction = async (body, mutateData) => {
        setIsModalAction(true);
        setIsModal(false);
        await makeBooking(body);
        await mutateData();
        setIsModalAction(false);
    };

    const buildBookingBody = (data) => {
        return {
            rideId: data?.id,
            status: 'PENDING',
            passengerId: user?._id,
            driverId: data?.driverId?.id,
            seats: search.seats,
            from: { ...data?.from },
            to: { ...data?.to },
            dateTime: data?.dateTime
        };
    };

    const { handleNavigation, location } = useMyNavigation();

    const handleContact = (id) => {
        analytics.track('Chat Passenger => Driver (View)', null, true);
        handleNavigation('app.messages', { otherUser: id });
    };

    const handleUserProfile = (id) => {
        handleNavigation('app.user', { id });
    };

    const getSwipeMenuOptions = (userId) => [
        {
            icon: 'Messages1',
            handleClick: handleContact,
            params: [userId]
        },
        {
            icon: 'User',
            handleClick: handleUserProfile,
            params: [userId]
        }
    ];

    useEffect(() => {
        analytics.page('Public Ride', 'App', isAuthenticated);
    }, []);

    useEffect(() => {
        if (isModal) analytics.track('Booking Modal Open', null, isAuthenticated);
    }, [isModal]);

    // console.log('rendering: View');

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                // console.log('data is', data);
                const inPresent = new Date(data?.dateTime) >= new Date();
                const approvedCount = data?.booking?.APPROVED_BY_ADMIN + data?.booking?.APPROVED_BY_DRIVER;
                // const inPresent = true;
                // console.log('is present?', inPresent);

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}></LayoutPageHeader>
                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <>
                                <LayoutPageBody
                                    // minHeight={classNames(
                                    //     data?.selfBooking?.status === 'EXCEEDED_REQUEST_ATTEMPTS' ? 'min-h-body-w-header-footer-1' : 'min-h-body-w-header-footer-2'
                                    // )}
                                    minHeight=''
                                >
                                    <div className=''>
                                        {data && !isModalAction ? (
                                            data?.blockUser ? (
                                                <div className={classNames('absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ')}>
                                                    <IconRound icon='UserBlock' presetSize='large' preset='light-red' />
                                                </div>
                                            ) : (
                                                <motion.div
                                                    key='tripView'
                                                    initial={'in'}
                                                    animate={'in'}
                                                    variants={animations.elementSlides}
                                                    className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}
                                                >
                                                    {data.status !== 'PUBLISHED' && (
                                                        <StatusOffered status={'CANCELED'} square archive={data?.archive} ladiesOnly={data?.ladiesOnly}>
                                                            {translations?.data?.['trip.status.CANCELED']}
                                                        </StatusOffered>
                                                    )}

                                                    {(data?.ladiesOnly || data?.selfBooking?.status) && (
                                                        <div className={classNames('mb-vw-6')}>
                                                            {data?.selfBooking?.status && (
                                                                <StatusTrip
                                                                    margins=''
                                                                    status={data?.selfBooking?.status}
                                                                    square
                                                                    archive={data?.archive}
                                                                    ladiesOnly={data?.ladiesOnly}
                                                                />
                                                            )}
                                                            {data?.ladiesOnly && <StatusOffered margins='' status={''} square ladiesOnly={data?.ladiesOnly} />}
                                                        </div>
                                                    )}
                                                    <OfflineMode margins=' mb-6' />
                                                    <div
                                                        className={classNames(
                                                            'px-vw-6 w-full flex-col flex',
                                                            !data?.selfBooking?.status && 'mt-vw-2',
                                                            rtl ? 'items-end' : 'items-start'
                                                        )}
                                                    >
                                                        <Pill
                                                            icon={data?.recurring ? 'Repeat1' : 'Calendar2'}
                                                            colorPreset={data?.archive ? 'dark-gray' : data?.recurring ? 'light-violet' : 'blue'}
                                                        >
                                                            <FormattedDateTime
                                                                dateTime={data?.dateTime}
                                                                date
                                                                dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null}
                                                            />
                                                        </Pill>
                                                        <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                                                            <FormattedDateTime dateTime={data?.dateTime} time />
                                                        </Pill>
                                                    </div>

                                                    <Itinerary
                                                        data={data}
                                                        search={search}
                                                        showProximity
                                                        showDistance
                                                        archive={data?.archive}
                                                        // showTime
                                                    />

                                                    <CardSection
                                                        paddingY='py-vw-4'
                                                        border='border-t border-b'
                                                        // swipeMenuOptions={getSwipeMenuOptions(data?.driverId)}
                                                        handleClick={() => handleUserProfile(data?.driverId.id)}
                                                        arrow
                                                    >
                                                        <CardUserBis user={data?.driverId} archive={data?.archive} showReviews={false} />
                                                    </CardSection>

                                                    {approvedCount > 0 && (
                                                        <>
                                                            <div
                                                                className={classNames('w-full border-t-8 border-gray-100 flex flex-col', { 'items-end': rtl })}
                                                            >
                                                                <div className='px-vw-6a pt-vw-6 pb-vw-2'>
                                                                    <CardSeats
                                                                        padding=''
                                                                        preset='view'
                                                                        label={'e.booking.confirmedBookings'}
                                                                        data={data}
                                                                        rtl={rtl}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className={classNames('mt-vw-4')}>
                                                        {approvedCount > 0 && tooltips?.tripViewBookings !== false && (
                                                            <MessageBox noTitle id='tripViewBookings' margins='mt-vw-2' label=''>
                                                                <IconRound icon='Info' preset='yellow' presetSize='small' />
                                                                {translations?.data?.['e.tooltip.tripViewBookings']}
                                                            </MessageBox>
                                                        )}
                                                        {!data?.doNotAcceptBooking && tooltips?.viewPricing && (
                                                            <MessageBox
                                                                label=''
                                                                noTitle
                                                                id='viewPricing'
                                                                margins={classNames(approvedCount > 0 ? 'mt-vw-2' : 'mt-vw-6 mb-vw-6')}
                                                                bodyAction={() => setStaticPage('pricing')}
                                                            >
                                                                {(tooltips?.tripViewBookings === false || !approvedCount) && (
                                                                    <IconRound icon='Coins' preset='yellow' presetSize='small' />
                                                                )}
                                                                {translations?.data?.['e.tooltip.compensation.short']}
                                                            </MessageBox>
                                                        )}
                                                        {data?.doNotAcceptBooking && (
                                                            <MessageBox
                                                                label=''
                                                                noAction={true}
                                                                fullButton
                                                                preset='black'
                                                                noTitle
                                                                id='notAcceptingTooltip'
                                                                margins={classNames(approvedCount > 0 ? 'mt-vw-2' : 'mt-vw-6 mb-vw-6')}
                                                                icon='Lock1'
                                                                // action={() => setStaticPage('pricing')}
                                                            >
                                                                {translations?.data?.['e.tooltip.noBooking']}
                                                            </MessageBox>
                                                        )}
                                                    </div>
                                                </motion.div>
                                            )
                                        ) : (
                                            <CardSkeleton rtl={rtl} type='view' />
                                        )}
                                    </div>
                                </LayoutPageBody>

                                {(inPresent || data?.recurring) && data && !isModalAction && !data?.archive && data?.status === 'PUBLISHED' && (
                                    <motion.div
                                        layout='position'
                                        key='viewFooter'
                                        className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 md:py-4 md:px-6`}
                                    >
                                        <>
                                            <BtnContactDriver
                                                disabled={!online || (!data?.selfBooking && data?.doNotAcceptBooking)}
                                                params={[data?.driverId?.id]}
                                                handleClick={handleContact}
                                            />
                                            {data?.selfBooking ? (
                                                data.selfBooking.status !== 'EXCEEDED_REQUEST_ATTEMPTS' ? (
                                                    <BtnMiddleAction
                                                        handleClick={handleNavigation}
                                                        disabled={!online}
                                                        params={[
                                                            'app.rides.booking',
                                                            {
                                                                id: data?.selfBooking?.bookingId
                                                            }
                                                        ]}
                                                        label='e.viewBookingRequest'
                                                    />
                                                ) : null
                                            ) : (
                                                <BtnMiddleAction
                                                    handleClick={setIsModal}
                                                    disabled={!online || data?.doNotAcceptBooking}
                                                    params={[true]}
                                                    label='e.sendBookingRequest'
                                                    icon='Plane1'
                                                />
                                                // <BtnRequestSend disabled={!online} handleClick={() => setIsModal(true)} />
                                            )}
                                        </>
                                    </motion.div>
                                )}
                            </>
                        </div>

                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={setIsModal}
                            // height=''
                        >
                            <OfflineMode margins='mt-6 -mb-4' />
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center'>
                                {data?.selfBooking?.status !== 'EXCEEDED_REQUEST_ATTEMPTS' ? (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.sendBookingRequest.headline']}
                                        </div>
                                        <BtnRequestConfirm
                                            disabled={!online}
                                            spinner={false}
                                            handleClick={() => handleAction(buildBookingBody(data), mutateData)}
                                        />

                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={setIsModal}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                ) : (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.sendBookingRequest.exceed.headline']}
                                        </div>

                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={setIsModal}>
                                            {translations?.data?.['e.iUnderstand']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    search: state.search,
    onboarding: state.onboarding
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTripView);
