import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

const styles = {
    PENDING: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Time'
    },
    APPROVED_BY_DRIVER: {
        color: 'text-emerald-600',
        bg: 'bg-emerald-50',
        border: 'border-emerald-300',
        icon: 'CheckDouble'
    },
    APPROVED_BY_ADMIN: {
        color: 'text-emerald-500',
        bg: 'bg-emerald-50',
        border: 'border-emerald-300',
        icon: 'CheckDouble'
    },
    CANCELED_BY_DRIVER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    CANCELED_BY_PASSENGER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    CANCELED_BY_ADMIN: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    DECLINED_BY_DRIVER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },

    DECLINED_BY_ADMIN: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    DECLINED_BY_PASSENGER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    EXCEEDED_REQUEST_ATTEMPTS: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    ARCHIVED: {
        color: 'text-gray-700',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Archive'
    }
};

const StatusTrip = ({ status = 'PENDING', height = 'h-vw-12', noText, archive = false, icon, children, margins = 'mb-6' }) => {
    const colorStatus = archive ? 'ARCHIVED' : status;
    return (
        <GetLang>
            {({ translations, lang, rtl }) => (
                <>
                    {!noText ? (
                        <div
                            className={classNames(
                                rtl ? 'text-vw-base' : 'tracking-wide text-vw-sm ',
                                'w-full px-vw-6 flex items-center justify-center uppercase font-medium py-vw-3',
                                styles[colorStatus]?.color,
                                styles[colorStatus]?.bg,
                                styles[colorStatus]?.border,
                                {
                                    'flex-row-reverse': rtl
                                }
                            )}
                        >
                            <Icons icon={styles[status]?.icon} />
                            <span className={classNames(rtl ? 'mr-vw-2 text-right' : 'ml-vw-2')}>
                                {children || translations?.data?.[`trip.request.${status}`]}
                            </span>
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                'h-vw-10 w-vw-10 rounded-full flex items-center justify-center flex-none',
                                styles[colorStatus]?.bg,
                                styles[colorStatus]?.color,
                                {
                                    'flex-row-reverse': rtl
                                }
                            )}
                        >
                            <Icons icon={styles[status]?.icon} />
                        </div>
                    )}
                </>
            )}
        </GetLang>
    );
};

export default StatusTrip;
