import PageStaticTemplate from './PageStaticTemplate';

const PageStaticPricing = ({ overlay }) => {
    return (
        <PageStaticTemplate
            overlay={overlay}
            page='static.help.pricing'
            apiUrl='/static-pages/6303d1485fba77ee60a5a461'
            icon='Coins'
            iconColor='text-gray-500'
            analyticsPage='Pricing and Payment'
        />
    );
};

export default PageStaticPricing;
