import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

const styles = {
    DRAFT: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Unpublish'
    },
    PUBLISHED: {
        color: 'text-emerald-600',
        bg: 'bg-emerald-50',
        border: 'border-emerald-300',
        icon: 'Ride1',
        archiveIcon: 'CheckDouble'
    },

    CANCELED: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    ARCHIVED: {
        color: 'text-gray-700',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Archive'
    },
    LADIESONLY: {
        color: 'text-pink-600',
        bg: 'bg-pink-50',
        border: 'border-t border-pink-600/20',
        icon: 'Female1'
    }
};

const StatusOffered = ({ height = 'h-vw-12', status = 'DRAFT', noText, archive = false, icon, children, margins = 'mb-6', ladiesOnly, recurring }) => {
    const colorStatus = archive ? 'ARCHIVED' : status;

    return (
        <GetLang>
            {({ translations, lang, rtl }) => (
                <>
                    {!noText ? (
                        <div className={margins}>
                            {status && (
                                <div
                                    className={classNames(
                                        height,
                                        rtl ? 'text-vw-base' : 'tracking-wide text-vw-sm ',
                                        'w-full px-vw-6 flex items-center justify-center uppercase font-medium',
                                        styles[colorStatus]?.color,
                                        styles[colorStatus]?.bg,
                                        styles[colorStatus]?.border,
                                        {
                                            'flex-row-reverse': rtl
                                        }
                                    )}
                                >
                                    <Icons icon={icon || styles[status]?.icon} />
                                    <span className='mx-vw-2'>
                                        {children || translations?.data?.[`publishing.status.${status}${archive && !recurring ? '.archive' : ''}`]}
                                    </span>
                                </div>
                            )}
                            {ladiesOnly && (
                                <div
                                    className={classNames(
                                        rtl ? 'text-vw-base' : 'tracking-wide text-vw-sm ',
                                        'w-full px-vw-6 h-vw-12 flex items-center justify-center uppercase font-medium  ',
                                        styles['LADIESONLY']?.color,
                                        styles['LADIESONLY']?.bg,
                                        status && styles['LADIESONLY']?.border,
                                        {
                                            'flex-row-reverse': rtl
                                        }
                                    )}
                                >
                                    <Icons icon={icon || styles['LADIESONLY']?.icon} />
                                    <span className='mx-vw-2'>{translations?.data?.['e.ride.status.ladiesOnly']}</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                'h-vw-10 w-vw-10 rounded-full flex items-center justify-center flex-none',
                                styles[colorStatus]?.bg,
                                styles[colorStatus]?.color,
                                {
                                    'flex-row-reverse': rtl
                                }
                            )}
                        >
                            <Icons icon={styles[status]?.icon} />
                        </div>
                    )}
                </>
            )}
        </GetLang>
    );
};

export default StatusOffered;
