import { defaultSize, defaultStroke } from './defaults';
const IconMessages1 = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
            >
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path d='M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z' />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconMessages1;
