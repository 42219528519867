import { connect } from 'react-redux';
import { translations } from 'constants/translations';
const GetLang = ({ children, globalState: { lang, time24 } }) => {
    // let lang = 'en';
    const rtl = !!translations?.[lang]?.rtl;
    const tripLang = lang !== 'ar' ? 'en' : 'ar';
    return children({
        tripLang,
        lang,
        rtl,
        translations: translations[lang],
        time24
    });
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(GetLang);
