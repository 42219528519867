import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputLocation from 'components/forms/InputLocation';
import { updatePublishLocation } from 'store/actions/publish';
import useMyNavigation from 'hooks/useMyNavigation';

import GetLang from 'hoc/GetLang';

const LayoutPublishLocation = ({
    updatePublishLocation,
    publish: { publish, isValid },
    source
}) => {
    const handleSetLocation = (location, source) => {
        updatePublishLocation(location, source);
    };

    const { handleNavigation, location } = useMyNavigation();

    const handleNavAction = () => {
        handleNavigation('app.offerSeats', null);
    };

    return (
        <GetLang>
            {({ tripLang, lang, rtl }) => (
                <div className={'flex flex-col gap-4'}>
                    <InputLocation
                        source={source}
                        handleSetLocation={handleSetLocation}
                        currentValue={publish?.[source][tripLang]?.formatted}
                        handleNavAction={handleNavAction}
                    />
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishLocation
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutPublishLocation);
