const Cloner = ({
    clones = 3,
    children,
    containerClass = 'w-full mb-vw-2a last:mb-0a '
}) => {
    const cloneArr = new Array(clones).fill();
    return (
        <>
            {cloneArr.map((clone, index) => (
                <div key={`sk_o)${index}`} className={containerClass}>
                    {children}
                </div>
            ))}
        </>
    );
};

export default Cloner;
