import BtnIcon from 'components/buttons/BtnIcon';
import { getNavParams } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from '../../../node_modules/classnames/index';

const LayoutPageHeader = ({
    search: { isValid },
    children,
    handleButton,
    useBrowserHistory = false,
    pageData: { backBtn = true, page, btnFlip = false, btnIcon = 'ArrowLeftLong1', overlay, iconColor },
    pageParams
}) => {
    const { handleNavigation, location } = useMyNavigation();
    const navigate = useNavigate();
    const handleBack = () => {
        if (handleButton) handleButton();
        else {
            if (useBrowserHistory) {
                // console.log('location', location);
                navigate(
                    location.state.prevState
                        ? `${location.state.prevState.from}${location.state.prevState.fromSearch ?? ''}`
                        : `${location.state.from}${location.state.fromSearch ?? ''}`,
                    {
                        state: {
                            from: location.pathname,
                            fromSearch: location.search,
                            to: `${location.state.from}`,
                            toSearch: `${location.state.fromSearch}`,
                            prevState: location.state.prevState
                                ? null
                                : {
                                      from: location?.state?.from,
                                      fromSearch: location?.state?.fromSearch,
                                      to: location?.state?.to,
                                      toSearch: location?.state?.toSearch
                                  }
                        }
                    }
                );
            } else {
                const { toPage, navParams } = getNavParams({
                    location,
                    page,
                    pageParams,
                    isValid
                });
                handleNavigation(toPage, navParams);
            }
        }
    };

    return (
        <GetLang>
            {({ rtl, lang, translations }) => (
                <div className={classNames(overlay && 'absolute top-0 inset-x-0 z-100', 'md:max-w-3xl md:mx-auto w-full min-h-vw-16 md:min-h-16')}>
                    <div
                        className={`py-vw-2 md:py-2 md:px-2 px-vw-2 h-full flex ${
                            rtl != btnFlip ? 'flex-row-reverse' : 'flex-row'
                        } items-center justify-between gap-4`}
                    >
                        {backBtn && <BtnIcon color={iconColor} icon={btnIcon} handleClick={handleBack} flip={rtl != btnFlip} noBg />}

                        {children}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search
});

export default connect(mapStateToProps, null)(LayoutPageHeader);
