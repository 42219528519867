export const resultsPerPage = 5;

export const allowedPhonesObj = {
    33648517574: 11, // France
    33611555789: 11, // France
    33659199217: 11,
    351912335607: 12, // Portugal,
    447947900304: 12,
    34610919267: 11
};

export const allowedPhonesPrefixObj = {
    9613: 10, // Leb
    96170: 11,
    96171: 11,
    96176: 11,
    96178: 11,
    96179: 11,
    96181: 11,
    default: 8
};

export const appVersion = '1.2.6';

export const variousUrls = {
    facebook: {
        landing: 'https://balabenzine.app?utm_source=facebook&utm_medium=facebook',
        social: 'https://balabenzine.app?utm_source=facebook&utm_medium=whatsapp'
    },
    linkedin: {
        landing: 'https://balabenzine.app?utm_source=linkedin&utm_medium=linkedin',
        social: 'https://balabenzine.app?utm_source=linkedin&utm_medium=whatsapp'
    },
    email: {
        landing: 'https://balabenzine.app?utm_source=email&utm_medium=email',
        social: 'https://balabenzine.app?utm_source=email&utm_medium=whatsapp'
    },
    google: {
        landing: 'https://balabenzine.app?utm_source=google&utm_medium=google',
        social: 'https://balabenzine.app?utm_source=google&utm_medium=whatsapp'
    },
    whatsapp: {
        landing: 'https://balabenzine.app?utm_source=whatsapp&utm_medium=whatsapp',
        social: 'https://balabenzine.app?utm_source=whatsapp&utm_medium=whatsapp'
    },
    other: {
        landing: 'https://balabenzine.app?utm_source=other&utm_medium=other',
        social: 'https://balabenzine.app?utm_source=other&utm_medium=whatsapp'
    }
};
// export const allowedPhonesObj = {
//     1: 11, // US + Canada + American phone numbering plan
//     2010: 12, // Egypt
//     2011: 12,
//     2012: 12,
//     2015: 12,
//     905: 12, // Turkey
//     9613: 10, // Leb
//     96170: 11,
//     96171: 11,
//     96176: 11,
//     96178: 11,
//     96179: 11,
//     96181: 11,
//     9627: 12,
//     965: 10, // Kuwait
//     97150: 12,
//     97152: 12, // UAE
//     97154: 12,
//     97155: 12,
//     97156: 12,
//     97158: 12,
//     973: 11,
//     9743: 11, // Qatar
//     9745: 11,
//     9746: 11,
//     9747: 11,
//     968: 11,
//     316: 11, // Netherlands
//     324: 11, // Belgium
//     336: 11, // France
//     337: 11,
//     346: 11, // Spain
//     347: 11,
//     393: 12, // Italy
//     49176: 12, // Germany
//     491609: 12,
//     4915: 12,
//     4917: 11,
//     4916: 11,
//     417: 12, // Switzerland
//     447: 12, // UK
//     966: 12, // KSA
//     3519: 12, //portugal
//     3579: 11, // cyprus
//     374: 11, // Armenia
//     380: 12, // Armenia
//     default: 8
// };
