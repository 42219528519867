import { defaultSize, defaultStroke } from './defaults';
const IconUserBlock = ({ size, className, stroke = 2 }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
                fill='none'
                stroke='currentColor'
                strokeWidth={stroke}
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                {/* <rect id='frame' width='24' height='24' fill='none' /> */}
                <path
                    id='body'
                    d='M8.218,15H7.171A3.171,3.171,0,0,0,4,18.171v1.586'
                    transform='translate(1.657 -0.309)'
                />
                <path
                    id='head'
                    d='M5.315,1.092A2.789,2.789,0,0,1,6,3,3,3,0,0,1,3,6a3.237,3.237,0,0,1-1.773-.579'
                    transform='translate(9 11) rotate(-90)'
                />
                <line
                    id='slash'
                    x2='14.14'
                    y2='14.14'
                    transform='translate(4.93 4.93)'
                />
                <circle
                    id='circle'
                    cx='10'
                    cy='10'
                    r='10'
                    transform='translate(2 2)'
                />
            </svg>
        </>
    );
};

export default IconUserBlock;
