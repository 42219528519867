import * as types from 'store/actions/types';

export function updateSearchSeats(seats) {
    return (dispatch) => {
        dispatch({
            type: types.SEARCH_SEATS_UPDATE,
            seats
        });
    };
}

export function updateSearchDates(dateRange) {
    return (dispatch) => {
        dispatch({
            type: types.SEARCH_DATES_UPDATE,
            dateRange
        });
    };
}

export function updateSearchLocation(location, source) {
    return (dispatch) => {
        dispatch({
            type: types.SEARCH_LOCATION_UPDATE,
            location,
            source
        });
    };
}

export function updateSearchFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: types.SEARCH_FILTERS_UPDATE,
            filters
        });
    };
}

export function clearSearch() {
    return (dispatch) => {
        dispatch({
            type: types.SEARCH_CLEAR
        });
    };
}

export function toggleSearchSwitching() {
    return (dispatch) => {
        dispatch({
            type: types.SEARCH_STOP_SWITCH
        });
    };
}
