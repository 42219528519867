import Icons from 'components/icons';
import classNames from 'classnames';

const presetColors = {
    'light-yellow': {
        bg: 'bg-yellow-200',
        text: 'text-yellow-600'
    },
    yellow: {
        bg: 'bg-bb-yellow-400',
        text: 'text-yellow-700'
    },
    red: {
        bg: 'bg-bb-red-400',
        text: 'text-white'
    },
    'light-red': {
        bg: 'bg-red-100',
        text: 'text-red-500'
    },
    pink: {
        bg: 'bg-red-100',
        text: 'text-red-600'
    },
    green: {
        bg: 'bg-emerald-300',
        text: 'text-emerald-900'
    },
    'light-green': {
        bg: 'bg-emerald-200',
        text: 'text-emerald-600'
    },
    'light-blue': {
        bg: 'bg-bb-blue-75',
        text: 'text-bb-blue-400'
    },
    blue: {
        bg: 'bg-bb-blue-500',
        text: 'text-white'
    },
    black: {
        bg: 'bg-gray-800',
        text: 'text-white'
    },
    gray: {
        bg: 'bg-gray-300',
        text: 'text-gray-800'
    }
};
const positions = {
    'left-center': 'top-1/2 left-0 -translate-x-1/2 -translate-y-1/2',
    'right-center': 'top-1/2 right-0 translate-x-1/2 -translate-y-1/2',
    'top-center': 'top-0 left-1/2 -translate-x-1/2 -translate-y-vw-4.5',
    'top-center-normal': 'top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-100-a'
};

const presetSizes = {
    default: {
        size: 'w-vw-14 h-vw-14',
        iconSize: '7.7vw'
    },
    large: {
        size: 'w-vw-36 h-vw-36',
        iconSize: '28vw',
        stroke: '1'
    },
    small: {
        size: 'w-vw-8 h-vw-8',
        iconSize: '5.8vw'
    }
};

const IconRound = ({ icon = 'User', preset = 'yellow', presetSize = 'default', iconSize, size, color, bgColor, pos = 'top-center-normal', zDepth }) => {
    return (
        <div
            className={classNames(
                zDepth,
                positions[pos],
                'absolute transform  rounded-full flex items-center justify-center',
                size ?? presetSizes[presetSize].size,
                {
                    [presetColors[preset]?.bg ?? presetColors.black.bg]: !bgColor,
                    [presetColors[preset]?.text ?? presetColors.black.text]: !color
                }
            )}
        >
            <Icons icon={icon} size={iconSize ?? presetSizes[presetSize].iconSize} stroke={presetSizes[presetSize]?.stroke} />
        </div>
    );
};

export default IconRound;
