import React, { useEffect, useLayoutEffect, useRef } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import Geocode from 'react-geocode';
import { buildLocationFromAddress } from 'helpers/helperGoogleMaps';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import { defaultLocationObj } from 'constants/defaultObjects';
import { previousDay } from 'date-fns';
import useGeoLocation from 'hooks/useGeolocation';

const InputLocation = ({
    currentValue,
    handleSetLocation,
    source,

    handleNavAction
}) => {
    // Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    const geolocation = useGeoLocation();
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        defaultValue: currentValue,
        cacheKey: 'lb',
        // cache: 24 * 60 * 60 * 10,
        requestOptions: {
            // types: ['geocode'],
            language: 'en-US',
            componentRestrictions: {
                country: 'lb'
            }
        },
        debounce: 600
    });

    const ref = useRef(null);

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const filterDown = (results) => {
        const searchElements = [
            'geocode',
            'locality',
            'sublocality',
            'neighborhood',
            'administrative_area_level_1',
            'administrative_area_level_2',
            'administrative_area_level_3'
        ];
        const filtered = results.filter((result) => {
            return result.types.some((one) => searchElements.includes(one));
        });
        // console.log('filtered', filtered);
        return filtered;
    };
    const clearCountryName = (str) => {
        if (str?.toLowerCase() === 'lebanon') return '';
        if (str?.toLowerCase()?.includes(', lebanon')) return str?.replace(', Lebanon', '');
        return str;
        // return str.replace(', Lebanon', '');
    };
    // When you select a result
    const handleSelect = (suggestion) => async () => {
        const address = `${suggestion.description}`;
        setValue(address, false);
        clearSuggestions();
        const locationObj = await buildLocationFromAddress(address, suggestion?.place_id);
        handleSetLocation(locationObj, source);
        if (handleNavAction) {
            handleNavAction();
        }
    };

    // const handleKeypress = () => {
    //     //it triggers by pressing the enter key
    //   if (e.keyCode === 13) {
    //     handleSubmit();
    //   }
    // };

    const handleGeolocation = async () => {
        if (!geolocation.error) {
            // console.log(geolocation);
            const { coordinates } = geolocation;
            Geocode.fromLatLng(coordinates.lat, coordinates.lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    // console.log('pre-address', address);
                    handleSelect({ description: address })();
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    };

    const clearInput = () => {
        setValue('');
        handleSetLocation(defaultLocationObj(), source);
        ref?.current?.focus?.();
    };

    useEffect(() => {
        // console.log('ref is', ref);

        setTimeout(() => ref.current.focus(), 50);
        // ref.current.focus();
    }, [ref]);

    const renderSuggestions = (rtl) =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text }
            } = suggestion;
            // console.log('suggestion is:', suggestion);

            return (
                <li
                    className={classNames('border-b border-gray-300 flex justify-between gap-3 items-center', { 'flex-row-reverse': rtl })}
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                >
                    <div
                        className={classNames('py-vw-6 flex flex-col', {
                            'items-end': rtl
                        })}
                    >
                        <div className={classNames('text-vw-lg font-semibold text-bb-blue-500', { 'justify-self-end': false, 'text-right': rtl })}>
                            {main_text}
                        </div>
                        <div
                            className={classNames('text-gray-600', {
                                'justify-self-end': false
                            })}
                        >
                            {clearCountryName(secondary_text)}
                        </div>
                    </div>
                    <div className={classNames('text-gray-500')}>
                        <Icons icon={rtl ? 'ArrowLeftShort1' : 'ArrowRightShort1'} />
                    </div>
                </li>
            );
        });

    return (
        <GetLang>
            {({ lang, translations, rtl }) => (
                <div className='flex flex-col h-full'>
                    <div className='relative'>
                        <input
                            // onKeyPress={handleKeypress}

                            autoFocus
                            ref={ref}
                            value={value}
                            onChange={handleInput}
                            disabled={!ready}
                            dir={rtl ? 'rtl' : 'ltr'}
                            placeholder={translations?.data?.[`e.location.placeholder.${source}`]}
                            className={classNames(
                                'relative h-vw-16 py-vw-3 w-full rounded-2xl block appearance-none placeholder-gray-400 placeholder-opacity-100 text-gray-700 leading-5 focus:outline-none bg-gray-100 font-semibold text-vw-lg ',
                                {
                                    'pl-vw-6 pr-vw-14': !rtl,
                                    'pr-vw-6 pl-vw-14': rtl
                                }
                            )}
                        />
                        {value && (
                            <button
                                onClick={clearInput}
                                className={classNames('focus:outline-none absolute top-1/2 transform -translate-y-1/2 text-gray-500', {
                                    'right-vw-4': !rtl,
                                    'left-vw-4': rtl
                                })}
                            >
                                <Icons icon='Close' />
                            </button>
                        )}
                    </div>
                    {!value && (
                        <div
                            className={classNames('border-b border-gray-300 flex justify-between items-center', { 'flex-row-reverse': rtl })}
                            key={'aa'}
                            onClick={handleGeolocation}
                        >
                            <div
                                className={classNames('py-vw-6 gap-3 flex items-center text-bb-blue-500', {
                                    'flex-row-reverse': rtl
                                })}
                            >
                                <Icons icon={'Compass1'} />
                                <div className={classNames('text-vw-lg font-semibold text-bb-blue-500', { 'justify-self-end': false })}>
                                    {translations?.data?.['e.useCurrentLocation']}
                                </div>
                            </div>
                            <div className={classNames('text-gray-500')}>
                                <Icons icon={rtl ? 'ArrowLeftShort1' : 'ArrowRightShort1'} />
                            </div>
                        </div>
                    )}
                    {/* We can use the "status" to decide whether we should display the dropdown or not */}
                    {status === 'OK' && <ul className='select-none'>{renderSuggestions(rtl)}</ul>}
                </div>
            )}
        </GetLang>
    );
};

export default InputLocation;

// Each time you update the location you add the location to the history object of the source (from/to)
// And overwrite the previous. If a new entry equals a previous one, then bump to first using the array move method
