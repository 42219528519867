import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import SwitchBtn from './SwitchBtn';
import Icons from 'components/icons/index';
import IconRoundStatus from './IconRoundStatus';

const SwitchSection = ({
    visible,
    val,
    setVal,
    label,
    color,
    colorOff,
    lock,
    border = 'border-b',
    icon,
    iconColorPreset,
    padding = 'px-vw-6 md:px-6 py-vw-4',
    noSwitch
}) => {
    return (
        <GetLang>
            {({ rtl, lang, translations }) =>
                visible ? (
                    <div
                        className={classNames(
                            padding,
                            border,
                            'border-gray-200',
                            rtl && 'flex-row-reverse',
                            'w-full text-vw-sm font-semibold text-gray-500  flex items-center justify-between h-full gap-6'
                        )}
                    >
                        <div className={classNames('flex gap-4 items-center', rtl && 'flex-row-reverse')}>
                            {icon && <IconRoundStatus icon={icon} colorPreset={iconColorPreset} />}
                            <div className={classNames(rtl && 'text-right')} style={{ direction: rtl ? 'rtl' : 'ltr' }}>
                                {translations?.data?.[label]}
                            </div>
                        </div>
                        {!noSwitch && <SwitchBtn enabled={val} setEnabled={setVal} color={color} lock={lock} colorOff={colorOff} />}
                    </div>
                ) : null
            }
        </GetLang>
    );
};

export default SwitchSection;
