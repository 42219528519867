import { defaultSize, defaultStroke } from './defaults';
const IconDrop1 = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
            >
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path d='M12 3.1L7.05 8.05a7 7 0 1 0 9.9 0L12 3.1zm0-2.828l6.364 6.364a9 9 0 1 1-12.728 0L12 .272z' />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconDrop1;
