import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LOGOUT } from 'store/actions/types';
import { genericAction } from 'store/actions/generic';

import { useIsPresent, motion } from 'framer-motion';
import { PageLoading } from 'pages/index';
export const RequireAuth = ({ genericAction, children, auth: { isAuthenticated, loading } }) => {
    // const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {}, [isAuthenticated]);

    let location = useLocation();

    if (isAuthenticated === null && loading) {
        return <PageLoading />;
    }

    if (isAuthenticated) {
        return children;
    }

    if (!isAuthenticated && !loading) {
        // genericAction({ type: LOGOUT });
        // logout or remove token
        return <Navigate to='/login' state={{ from: location, to: '/login' }} replace />;
    }
    return null;
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            genericAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
