import classNames from 'classnames';

const Roundify = ({
    children,
    width = 'w-vw-6',
    height = 'h-vw-6',
    bgColor = 'bg-gray-300',
    color = 'text-white',
    gap = 'gap-2',
    padding = '',
    margins = '',
    extraClass = '',
    justify = 'justify-center'
}) => {
    return (
        <div className={classNames('rounded-full text-vw-xs flex items-center', width, height, bgColor, color, gap, padding, extraClass, justify, margins)}>
            {children}
        </div>
    );
};

export default Roundify;
