import { NavLink } from 'react-router-dom';
import Icons from 'components/icons/';
import classNames from 'classnames';
import { widthBiggerThan } from 'helpers/utils';
import GetLang from 'hoc/GetLang';
const NavButton = ({ navIsOpen, toggleNav, notification, rtl }) => {
    const defaultClass = 'flex flex-col';
    const activeClass = `text-blue-700 ${defaultClass}`;
    const inactiveClass = `text-gray-400 ${defaultClass}`;

    return (
        <GetLang>
            {({ rtl }) => (
                <div className=' flex items-center justify-center'>
                    {notification > 0 && (
                        // <div className={classNames('absolute flex top-vw-4', rtl ? 'right-vw-4' : 'left-vw-12')}>
                        //     <span className='animate-ping rounded-full h-3 w-3 transform bg-bb-red-400'></span>
                        //     <span className='absolute left-vw-0.5 top-vw-0.5 rounded-full h-2 w-2 transform bg-bb-red-400'></span>
                        // </div>
                        <div className={classNames('absolute flex top-vw-3', rtl ? 'right-vw-10' : 'left-vw-10')}>
                            {/* <span className='animate-ping rounded-full h-3 w-3 transform bg-bb-red-400'></span> */}
                            <span className='absolute left-vw-0.5 top-vw-0.5 rounded-full h-vw-5 w-vw-5 transform bg-bb-red-400 text-white justify-center items-center flex text-vw-xxs font-semibold'>
                                {notification}
                            </span>
                        </div>
                    )}
                    <button
                        onClick={() => toggleNav(!navIsOpen)}
                        className={classNames(
                            'md:hidden flex items-center justify-center  text-white h-full w-vw-20 md:w-20',
                            navIsOpen && 'bg-bb-blue-700',
                            !navIsOpen && 'bg-gray-100'
                        )}
                    >
                        <Icons icon={navIsOpen ? 'LogoWhite' : 'LogoBlue'} size={widthBiggerThan() ? '56px' : '11.6vw'} />
                        {/* <i className={`${icon} text-2xl`}></i> */}
                    </button>
                    <button
                        onClick={() => toggleNav(!navIsOpen)}
                        className={classNames(
                            'relative group md:flex hidden items-center justify-center  text-white h-full w-vw-20 md:w-20 bg-gray-100 hover:bg-bb-blue-700'
                        )}
                    >
                        <Icons containerClass='absolute opacity-100 group-hover:opacity-0' icon={'LogoBlue'} size={'56px'} />
                        <Icons containerClass='absolute opacity-0 group-hover:opacity-100' icon={'LogoWhite'} size={'56px'} />
                        {/* <i className={`${icon} text-2xl`}></i> */}
                    </button>
                </div>
            )}
        </GetLang>
    );
};

export default NavButton;
