import { combineReducers } from 'redux';
import auth from './auth';
import globalState from './globalState';
import search from './search';
import publish from './publish';
import results from './results';
import rides from './rides';
import onboarding from './onboarding';
import chat from './chat';
import staticPages from './staticPages';
import admin from './admin';

export default combineReducers({
    auth,
    globalState,
    search,
    rides,
    publish,
    results,
    onboarding,
    chat,
    staticPages,
    admin
});
