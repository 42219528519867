/* eslint-disable import/no-anonymous-default-export */
import {
    AUTH_SOCKETID_SET,
    AUTH_STEPS_CLEAR,
    AUTH_STEPS_SET,
    AUTH_OTPREQUEST_SET,
    AUTH_OTPREQUEST_CLEAR,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    SUBMIT_SUCCESS,
    SUBMIT_FAIL,
    USER_LOADED,
    USER_PROFILE_MISSING,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    AUTH_ERROR,
    GENERAL_LOADING,
    AUTH_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    AUTH_CLEAR_ERRORS,
    CREATE_PROFILE,
    UPDATE_USER_BY_ID,
    UPDATE_PROFILE,
    TMP_LOGIN,
    TMP_LOGOUT
} from 'store/actions/types';

const initialState = {
    token: localStorage?.getItem('token'),
    isAuthenticated: null,
    isProfile: false,
    loading: false,
    setupStep: null,
    otpReq: null,
    error: null,
    user: null,
    socketId: null
};

export default function (state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case AUTH_SOCKETID_SET:
            return {
                ...state,
                socketId: payload
            };
        case AUTH_OTPREQUEST_SET:
            return {
                ...state,
                otpReq: payload
            };
        case AUTH_OTPREQUEST_CLEAR:
            return {
                ...state,
                otpReq: null
            };
        case AUTH_STEPS_CLEAR:
            return {
                ...state,
                setupStep: null,
                loading: false
            };
        case AUTH_STEPS_SET:
            return {
                ...state,
                setupStep: payload,
                loading: false
            };
        case `${UPDATE_PROFILE}_PENDING`:
        case `${CREATE_PROFILE}_PENDING`:
        case GENERAL_LOADING:
        case AUTH_LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case USER_LOADED:
        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isProfile: true,
                loading: false,
                user: payload,
                error: null
            };
        case USER_PROFILE_MISSING:
        case USER_PROFILE_FAIL:
            return {
                ...state,
                isAuthenticated: true,
                isProfile: false,
                loading: false,
                user: payload,
                error: null
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.jwt);
            return {
                ...state,
                token: payload.jwt,
                isAuthenticated: true,
                isProfile: false,
                loading: true,
                error: null,
                setupStep: 'finished'
            };
        case TMP_LOGIN:
            // localStorage.setItem('token', 'yes');
            return {
                ...state,

                isAuthenticated: true
            };
        case TMP_LOGOUT:
            localStorage.removeItem('token');
            return {
                ...state,

                isAuthenticated: false
            };

        case SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case SUBMIT_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };

        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                error: payload
            };
        case LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('chat');
            localStorage.removeItem('state');
            localStorage.removeItem('onboarding');
            localStorage.removeItem('globalState');
            localStorage.removeItem('updateId');
            localStorage.removeItem('version');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                isProfile: false,
                error: payload,
                user: null
            };
        case AUTH_CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        case `${UPDATE_USER_BY_ID}_FULFILLED`:
            return {
                ...state,
                user: payload.data.data.updateUser.user
            };
        default:
            return state;
    }
}
