import classNames from 'classnames';
import Icons from 'components/icons/index';
import { animations } from 'constants/animations';
import { motion, AnimatePresence } from 'framer-motion';

const ModalBottom = ({ children, handleOpen, isOpen, height, aKey = 'req', move, rounded = 'rounded-t-3xl' }) => {
    // console.log('isOpen', isOpen);
    return (
        <AnimatePresence>
            {isOpen && (
                <div className={classNames('fixed inset-0', move)}>
                    <motion.div
                        onClick={() => handleOpen(false)}
                        key={aKey + '_bg'}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={classNames('fixed inset-x-0 top-0 bottom-vw-20 bg-gray-800/30 z-100')}
                    />
                    <motion.div
                        key={aKey + '_fg'}
                        initial={'bottom'}
                        animate={'in'}
                        exit={'bottom'}
                        variants={animations.pageSlides}
                        className={classNames('fixed inset-x-0 bottom-vw-20 bg-white z-1000 text-vw-base', rounded, height)}
                    >
                        {children}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default ModalBottom;
