import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody } from 'components/layouts';
import Itinerary from 'components/blocks/Itinerary';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations } from 'constants/animations';
import { motion } from 'framer-motion';

import StatusOffered from 'components/blocks/StatusOffered';
import { updateRideStatus } from 'fetch/apiRequests';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import OfflineMode from 'components/blocks/OfflineMode';
import ModalBottom from 'components/modals/ModalBottom';
import { useEffect, useState } from 'react';

import BtnPill from 'components/buttons/BtnPill';
import useMyNavigation from 'hooks/useMyNavigation';
import BtnIcon from 'components/buttons/BtnIcon';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import CardUser from 'components/cards/CardUser';
import { getBodyHeight, getRecurringDay } from 'helpers/helperFunctions';
import CardSection from 'components/cards/CardSection';
import CardUserBis from 'components/cards/CardUserBis';
import { analytics } from 'helpers/segment';

const PageRideOfferArchived = ({ auth: { isAuthenticated } }) => {
    const pageData = {
        page: 'app.rides.archived.offer',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/rides/published',
        backBtn: true,
        zIndex: 43,
        checkIdParam: true
    };

    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);

    const handlePassengerModal = (clickedBooking) => {
        setMode('EDIT_PASSENGER');
    };

    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    useEffect(() => {
        analytics.page('Published Ride Archive', 'App', isAuthenticated);
    }, []);

    const { handleNavigation, location } = useMyNavigation();

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const handleAction = async (rideId, mutateData, status) => {
        setIsModalAction(true);
        setIsModal(false);
        await updateRideStatus({ rideId, status });
        await mutateData();
        setIsModalAction(false);
        if (status === 'DELETE') handleNavigation('app.rides.offered.archived', null);
    };

    const handleContact = (id) => {
        handleNavigation('app.messages', { otherUser: id });
    };

    const handleUserProfile = (id) => {
        handleNavigation('app.user', { id });
    };

    const getSwipeMenuOptions = (userId) => [
        {
            icon: 'Messages1',
            handleClick: handleContact,
            params: [userId]
        },
        {
            icon: 'User',
            handleClick: handleUserProfile,
            params: [userId]
        }
    ];

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidParams, data, isValidating, mutateData, translations, handleNavigation, online }) => {
                console.log('data is', data);
                const bookedPassengers = data?.bookings.filter((booking) => booking.status !== 'PENDING');
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            {data && data.status !== 'PUBLISHED' && !isModalAction && (
                                <div
                                    className={classNames('flex items-center', {
                                        'flex-row-reverse': rtl
                                    })}
                                >
                                    {!data?.archive && (
                                        <BtnIcon disabled={!online} icon='Archive' iconSize={'5.8vw'} noBg handleClick={setMode} params={['ARCHIVE_RIDE']} />
                                    )}
                                    <BtnIcon disabled={!online} icon='Trash' iconSize={'5.8vw'} noBg handleClick={setMode} params={['DELETE_RIDE']} />
                                </div>
                            )}
                        </LayoutPageHeader>

                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar'>
                            <LayoutPageBody minHeight={'min-h-body-w-header'}>
                                <>
                                    {data && !isModalAction ? (
                                        <motion.div
                                            key='rideOfferArchived'
                                            initial={'in'}
                                            animate={'in'}
                                            variants={animations.elementSlides}
                                            className={`list-none w-full bg-white rounded-3xl relative`}
                                        >
                                            <StatusOffered status={data?.status} square archive={true} ladiesOnly={data?.ladiesOnly} />
                                            <OfflineMode margins='mt-6' />

                                            <div className={`mt-vw-6 px-vw-6 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                                                <Pill icon={data?.recurring ? 'Repeat1' : 'Calendar2'} colorPreset={'dark-gray'}>
                                                    <FormattedDateTime
                                                        dateTime={data?.dateTime}
                                                        date
                                                        dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null}
                                                    />
                                                </Pill>
                                                <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                                                    <FormattedDateTime dateTime={data?.dateTime} time />
                                                </Pill>
                                            </div>

                                            <Itinerary data={data} archive={true} />
                                            {data.status === 'PUBLISHED' && (
                                                <div className={classNames('w-full border-t-8 border-gray-100 flex flex-col', { 'items-end': rtl })}>
                                                    <div className='px-vw-6 py-vw-6'>
                                                        <Pill
                                                            sizePreset='small'
                                                            margins=''
                                                            icon='Users'
                                                            bgColor=''
                                                            border='ring-2'
                                                            borderColor='ring-gray-700'
                                                            textColor='text-gray-700'
                                                            textTransform=' font-semibold'
                                                            colorPreset='light-gray'
                                                        >
                                                            {translations?.data?.['e.booking.bookings']}
                                                        </Pill>
                                                    </div>

                                                    {bookedPassengers?.length > 0 ? (
                                                        <ul>
                                                            {bookedPassengers.map((booking) => {
                                                                return (
                                                                    <CardSection
                                                                        key={booking._id}
                                                                        border='first:border-t border-b'
                                                                        paddingY='py-vw-4'
                                                                        swipeMenuOptions={getSwipeMenuOptions(booking.passengerId._id)}
                                                                        arrow={false}
                                                                    >
                                                                        <CardUserBis archive={true} booking={booking} />
                                                                    </CardSection>
                                                                );
                                                            })}
                                                        </ul>
                                                    ) : (
                                                        <div className={classNames('px-vw-10 text-gray-600 font-semibold')}>
                                                            {translations?.data['e.noPassengersOnRide.past']}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </motion.div>
                                    ) : (
                                        <CardSkeleton rtl={rtl} type='booking' />
                                    )}
                                </>
                            </LayoutPageBody>
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center'>
                                <OfflineMode margins='' />
                                {mode === 'DELETE_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.delete.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='cancel'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, mutateData, 'DELETE')}
                                        >
                                            <>
                                                <span className='w-vw-6' />
                                                {translations?.data?.['e.ride.status.delete']}
                                                <Icons icon={'Trash'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'ARCHIVE_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.archive.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(data?.id, mutateData, 'ARCHIVE')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.archive']}
                                                <Icons icon={'Archive'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

export default connect(mapStateToProps, null)(PageRideOfferArchived);
