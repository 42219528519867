import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const NavTabItem = ({ children, active, id, tab, toggleTab, from, to, page }) => {
    const navigate = useNavigate();
    const activeClass = 'text-bb-blue-500 ';
    const inactiveClass = 'text-gray-300';

    const handleTab = () => {
        if (id !== tab) {
            toggleTab(page, id);
            navigate(to, {
                state: {
                    from,
                    to,
                    fromSearch: ''
                }
            });
        }
    };

    return (
        <div
            onClick={handleTab}
            className={classNames('px-vw-8 md:px-4 flex justify-center items-center w-1/2 h-vw-16 md:h-20 font-bold text-vw-xl md:text-lg relative', {
                [activeClass]: tab === id,
                [inactiveClass]: tab !== id
            })}
        >
            <div className='h-full flex items-center'>{children}</div>
        </div>
    );
};

export default NavTabItem;
