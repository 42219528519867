import classNames from 'classnames';
import Icons from 'components/icons/index';
import { useLayoutEffect } from 'react';

import { useEffect, useState, useRef } from 'react';
import useDidMountEffect from 'hooks/useDidMountEffect';

const currentTimeAsString = () => {
    const today = new Date();
    const hours = today.getHours();
    const mins = today.getMinutes();
    const strHours = `${hours > 9 ? hours : `0${hours}`}`;
    const strMins = `${mins > 9 ? mins : `0${mins}`}`;
    return `${strHours}:${strMins}`;
};

const convertTime = (type, varTime, time24, amPm) => {
    let currentTime = currentTimeAsString();
    let time = varTime;

    // console.log('time is', time);
    let hours,
        mins,
        AP,
        res = '';
    switch (type) {
        case 'fromGlobal':
            hours = Number(time?.slice(0, 2));

            mins = time.slice(3, 5).toString();
            if (!time24) {
                // console.log('time', time);
                // console.log('hours', hours);
                AP = 'AM';
                if (hours > 11 && hours < 24) AP = 'PM';

                if (hours > 12 && hours < 24) hours -= 12;
                if (hours === 0 || hours === 24) hours = 12;
            } else {
                AP = '';
            }

            if (time.slice(0, 2)) {
                hours = hours.toString();

                hours = hours.length === 1 ? `0${hours}` : hours;
                res = `${hours}${mins}`;
            }

            // console.log('AMPM is', AP);
            return {
                time: res,
                amPm: AP
            };

        case 'toGlobal':
            hours = Number(time.slice(0, 2));
            mins = time.slice(2, 4).toString();
            if (!time24) {
                if (amPm === 'PM') {
                    if (hours !== 12) hours += 12;
                    else hours = 12;
                }
                if (amPm === 'AM') {
                    if (hours === 12) hours = 0;
                }
            }

            if (time.slice(0, 2)) {
                hours = hours.toString();
                hours = hours.length === 1 ? `0${hours}` : hours;
                res = `${hours.toString()}:${mins}`;
            }

            return res;
    }
};

const TimeInput = ({ handleUpdate, time24 = false, value, recurring, generalTime, selectedDay }) => {
    // console.log('loading component now');
    const refA = useRef();
    const [amPm, setAmPm] = useState(convertTime('fromGlobal', value, time24, null).amPm);
    // const [amPm, setAmPm] = useState(null);
    const [time, setTime] = useState(convertTime('fromGlobal', value, time24, null).time);

    // const [amPm, setAmPm] = useState('AM');
    // const [time, setTime] = useState('0000');

    const [isFocus, setIsFocus] = useState(false);

    const handleChange = (e) => {
        let v = e.target.value.toString();

        const regex = /[^0-9]?[^0-9]?[^0-9]?[^0-9]/;
        v = v.replace(regex, '');
        // check for length
        if (v.charAt(0) === '-') v = '';

        if (true) {
            if (v.toString().length <= 4) {
                // if start character is 0
                if (!time24) {
                    if (Number(v.charAt(0)) > 1) v = '';
                    if (Number(v.charAt(0)) === 1 && Number(v.charAt(1)) > 2) {
                        v = v.slice(0, 1);
                    }
                    if (Number(v.charAt(0)) === 0 && Number(v.charAt(1)) === 0) {
                        v = v.slice(0, 1);
                    }
                    if (Number(v.charAt(2)) > 5) {
                        v = v.slice(0, 2);
                    }
                }

                if (time24) {
                    if (Number(v.charAt(0)) > 2) v = '';
                    if (Number(v.charAt(0)) === 2 && Number(v.charAt(1)) > 3) {
                        v = v.slice(0, 1);
                    }
                    if (Number(v.charAt(2)) > 5) {
                        v = v.slice(0, 2);
                    }
                }
            } else v = v.slice(0, 4);
            setTime(v);
        }
    };
    useEffect(() => {
        refA?.current?.focus();
    }, []);

    useEffect(() => {
        // console.log('time in comp is', time);
        // console.log('AM/PM in comp is', amPm);
    }, [time, amPm]);

    const [placeholders, setPlaceholders] = useState({
        a: '0',
        b: '8',
        c: '4',
        d: '5'
    });

    useLayoutEffect(() => {
        // setAmPm(convertTime('fromGlobal', value, time24, null).amPm);
        // setTime(convertTime('fromGlobal', value, time24, null).time);
        makePlaceholders();
    }, []);

    const handleFocus = (e) => {
        setIsFocus(true);
        refA.current.focus();
    };

    const handleBlur = (e) => {
        setIsFocus(false);
    };

    useDidMountEffect(() => {
        // console.log('loading it');
        handleUpdate(convertTime('toGlobal', time, time24, amPm));
    }, [time, amPm]);

    const submitForm = (event) => {
        event.preventDefault();
        handleUpdate(convertTime('toGlobal', time, time24, amPm), true);
    };

    const clearInput = () => {
        setTime('');
        // console.log('clicked');
    };

    const makePlaceholders = () => {
        let a, b, c, d;
        // console.log('gen', generalTime);
        let date;
        if (!recurring || (recurring && (selectedDay === 'general' || !generalTime))) date = new Date();
        else date = new Date(`2022-10-22T${generalTime}`);
        // const date = !recurring || selectedDay === 'general' ? new Date() : new Date(`2022-10-22T${generalTime}`);
        let nHrs = date.getHours();
        let nMins = date.getMinutes();
        if (!time24) {
            if (nHrs >= 12) {
                // setAmPm('PM');
                if (nHrs > 12) nHrs -= 12;
            } else {
                // setAmPm('AM');
                if (nHrs === 0) nHrs = 12;
            }
        }
        a = padNumber(nHrs).slice(0, 1);
        b = padNumber(nHrs).slice(1, 2);
        c = padNumber(nMins).slice(0, 1);
        d = padNumber(nMins).slice(1, 2);
        setPlaceholders({ a, b, c, d });
    };

    const padNumber = (nbr) => {
        if (Number(nbr) < 10) return `0${Number(nbr).toString()}`;
        return Number(nbr).toString();
    };

    return (
        <>
            <div className='relative ' tabIndex={1} onFocus={handleFocus} onBlur={handleBlur}>
                <form type='submit' onSubmit={submitForm}>
                    <input
                        // style={{ zIndex: '-20' }}
                        inputMode='numeric'
                        ref={refA}
                        onFocus={handleFocus}
                        type='text'
                        // min='0'
                        // max='2359'
                        value={time}
                        onInput={(e) => handleChange(e)}
                        // onChange={(e) => handleChange(e)}
                        // onChange={(e) => handleChange(e, 'b')}
                        className='absolute top-0 left-0 text-center focus:outline-none cursor-none caret-transparent text-xs w-0 h-0 opacity-0'
                    />
                </form>
                <div
                    className={classNames(
                        'relative mt-2 border-2 ring-4 w-full min-h-24 bg-gray-100 rounded-2xl flex items-center justify-center text-6xl font-extrabold text-bb-blue-500',
                        {
                            'border-transparent ring-transparent': !isFocus,
                            'border-bb-blue-500 ring-bb-blue-100': isFocus
                        }
                    )}
                >
                    {!time24 && (
                        <div className='flex flex-col text-xl font-semibold  mr-2'>
                            <div
                                onClick={() => setAmPm('AM')}
                                className={classNames({
                                    'text-gray-300': amPm !== 'AM'
                                })}
                            >
                                AM
                            </div>
                            <div
                                onClick={() => setAmPm('PM')}
                                className={classNames({
                                    'text-gray-300': amPm !== 'PM'
                                })}
                            >
                                PM
                            </div>
                        </div>
                    )}
                    <Digit time={time} type='hours' sub='a' placeholder={placeholders.a} />
                    <Digit time={time} type='hours' sub='b' placeholder={placeholders.b} />
                    <div
                        className={classNames('-mt-2 px-4', {
                            'text-gray-300': !parseTime(time)['hours']['b']
                        })}
                    >
                        :
                    </div>
                    <Digit time={time} type='mins' sub='a' placeholder={placeholders.c} />
                    <Digit time={time} type='mins' sub='b' placeholder={placeholders.d} />
                    {!time24 && <div className='h-full w-6'></div>}
                    {time && (
                        <button
                            onClick={clearInput}
                            className={classNames('z-100 focus:outline-none absolute top-1/2 transform -translate-y-1/2 text-gray-500 right-4')}
                        >
                            <Icons icon='Close' />
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default TimeInput;

const parseTime = (time) => {
    return {
        hours: {
            a: time?.toString().slice(0, 1),
            b: time?.toString().slice(1, 2)
        },
        mins: {
            a: time?.toString().slice(2, 3),
            b: time?.toString().slice(3, 4)
        }
    };
};

const Digit = ({ time, type, sub, placeholder = '0' }) => {
    return (
        <div
            className={classNames('w-12 ', {
                'text-right': type === 'hours',
                'text-gray-300': !parseTime(time)[type][sub]
            })}
        >
            {parseTime(time)[type][sub] || placeholder}
        </div>
    );
};
