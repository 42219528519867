import Spinner from 'components/blocks/Spinner';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnRequestCancel = ({ params, handleClick, spinner, children, disabled, middleAction, label }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        type={middleAction ? 'cancel-middle' : 'cancel'}
                        justify={spinner ? 'justify-center' : 'justify-between'}
                        rtl={rtl}
                        params={params}
                        disabled={disabled}
                        handleClick={handleClick}
                    >
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <>
                                <span className='w-6' />
                                {children || translations?.data?.[label ?? 'e.cancelRequest']}
                                <Icons icon={middleAction ? 'ArrowRightShort1' : 'CloseCircle'} flip={rtl} />
                            </>
                        )}
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnRequestCancel;

// 'e.confirmRequest': 'Confirmer la demande',
// 'e.sendBookingRequest': 'Envoyer une demande',
// 'e.contactDriver': 'Contacter le conducteur',
// 'e.viewBookingRequest': 'Consulter la demande'
