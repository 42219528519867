import React, { useEffect, useState } from 'react';

import { Routes, Route, Outlet, useLocation } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from 'store';
import RequireAuth from 'hoc/RequireAuth';

// import GoogleMaps from 'components/blocks/GoogleMaps';
import {
    PageHomeInstall,
    PageSocialInstall,
    PageSocialDone,
    PageAuthLogin,
    PageAuthRegister,
    PageAppHome,
    PageUserView,
    PageTripFind,
    PageTripFindModal,
    PageTripResults,
    PageTripView,
    PageTripBook,
    PageTripPublish,
    PageTripPublishModal,
    PageRides,
    PageRideOffer,
    PageRideOfferArchived,
    PageRidesOffered,
    PageRidesOfferedArchived,
    PageRideBooking,
    PageRideBookingArchived,
    PageRidesBooked,
    PageRidesBookedArchived,
    PageProfile,
    PageProfilePersonal,
    PageProfilePersonalModal,
    PageProfileVerify,
    PageProfileVerifyModal,
    PageProfilePasswordModal,
    PageError,
    PageStaticOverlay,
    PageStatic,
    PageStaticAbout,
    PageStaticSafety,
    PageStaticFAQ,
    PageStaticHelp,
    PageStaticSupport,
    PageStaticTerms,
    PageStaticPrivacy,
    PageStaticWeNeedYou,
    PageAdminUsers,
    PageAdminUserEdit,
    PageAdminVerify,
    PageAdminVerification,
    PageCom,
    PageNotifications,
    PageMessages
} from 'pages';

import { Layout } from 'components/layouts';

import { routePath, routeObj } from 'constants/structRoutes';
import { loadAppSettings, toggleLang, toggleLoading } from 'store/actions/globalState';
import { AnimatePresence } from 'framer-motion';
import { loadUser } from 'store/actions/auth';
import setAuthToken from 'helpers/setAuthToken';
import { Slide, ToastContainer } from 'react-toastify';
import LoadGoogleMapsAPI from 'components/maps/LoadGoogleMapsAPI';
import { OnlineStatusProvider } from 'hooks/useOnlineStatus';
import { loadNamedState, saveNamedState } from 'store/localStorage';
import { getLatestNews, updateProfile } from 'fetch/apiRequests';
import { setStaticPage } from 'store/actions/staticPages';
import { analytics } from 'helpers/segment';

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const contextClass = {
    success: 'bg-emerald-400',
    error: 'bg-red-600',
    info: 'bg-gray-600',
    warning: 'bg-orange-400',
    default: 'bg-indigo-600',
    dark: 'bg-white-600 font-gray-300'
};

const latestUpdate = async () => {
    if (store?.getState()?.auth?.isAuthenticated) {
        const updateId = (await getLatestNews())?.[0]?._id;
        if (localStorage.updateId) {
            let updateIdLocal = loadNamedState('updateId');
            if (updateIdLocal !== updateId) {
                setTimeout(() => store.dispatch(setStaticPage('latestNews', null)), 300);
                saveNamedState('updateId', updateId);
            }
        } else {
            setTimeout(() => store.dispatch(setStaticPage('latestNews', null)), 300);
            saveNamedState('updateId', updateId);
        }
    }
};

const App = () => {
    // document.addEventListener('contextmenu', (event) => {
    //     event.preventDefault();
    // });

    const location = useLocation();
    useEffect(() => {
        loadAppSettings();
        handleLoginStamp();
        store.dispatch(loadUser(true, true, 'app'));

        // let tmpLang = loadNamedState('globalState')?.lang;
        // if (tmpLang === 'en' || tmpLang === 'fr' || tmpLang === 'ar')
        //     store.dispatch(toggleLang(tmpLang));
        // else store.dispatch(toggleLang('en'));
        store.dispatch(toggleLoading(false));
    }, []);

    useEffect(() => {
        latestUpdate();
    }, [store?.getState()?.auth?.isAuthenticated]);

    const handleLoginStamp = async () => {
        if (store?.getState().auth.isAuthenticated) await updateProfile({});
    };

    return (
        <>
            <Provider store={store}>
                <OnlineStatusProvider>
                    <LoadGoogleMapsAPI store={store} />
                    {/* {isLoading && <PageLoading />} */}
                    <PageStaticOverlay />
                    <AnimatePresence>
                        <ToastContainer
                            className='mt-24'
                            toastClassName={({ type }) =>
                                contextClass[type || 'default'] + ' relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'
                            }
                            bodyClassName={() => 'text-sm font-white font-med block p-3'}
                            position='top-right'
                            hideProgressBar={true}
                            autoClose={1750}
                            transition={Slide}
                        />

                        <Routes location={location} key={location.key}>
                            <Route path='/' element={<PageHomeInstall />} />
                            <Route path='/social' element={<PageSocialInstall />} />
                            <Route path='/done' element={<PageSocialDone />} />

                            <Route element={<Layout />}>
                                <Route path={routeObj.login.id} element={<PageAuthLogin />} />
                                <Route path={routeObj.register.id} element={<PageAuthRegister />} />

                                <Route path={routeObj.static.id} element={<PageStatic />}>
                                    <Route path={routeObj.static.about.id} element={<PageStaticAbout />} />
                                    <Route path={routeObj.static.safety.id} element={<PageStaticSafety />} />
                                    <Route path={routeObj.static.faq.id} element={<PageStaticFAQ />} />
                                    <Route path={routeObj.static.help.id} element={<PageStaticHelp />}>
                                        <Route path={routeObj.static.help.support.id} element={<PageStaticSupport />} />
                                        <Route path={routeObj.static.help.terms.id} element={<PageStaticTerms />} />
                                        <Route path={routeObj.static.help.privacy.id} element={<PageStaticPrivacy />} />
                                        <Route path={routeObj.static.help.weNeedYou.id} element={<PageStaticWeNeedYou />} />
                                    </Route>
                                </Route>

                                <Route
                                    path={routeObj.app.id}
                                    element={
                                        <RequireAuth>
                                            <PageAppHome />
                                        </RequireAuth>
                                    }
                                >
                                    <Route path={routePath('app.admin.users')} element={<PageAdminUsers />} />
                                    <Route path={routePath('app.admin.user')} element={<PageAdminUserEdit />} />
                                    <Route path={routePath('app.admin.verify')} element={<PageAdminVerify />} />
                                    <Route path={routePath('app.admin.verification')} element={<PageAdminVerification />} />
                                    <Route path={routeObj.app.searchCarSharing.id} element={<PageTripFind />} />

                                    <Route
                                        path={`${routeObj.app.searchCarSharing.id}/${routeObj.app.searchCarSharing.from.id}`}
                                        element={<PageTripFindModal section='from' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.searchCarSharing.id}/${routeObj.app.searchCarSharing.to.id}`}
                                        element={<PageTripFindModal section='to' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.searchCarSharing.id}/${routeObj.app.searchCarSharing.date.id}`}
                                        element={<PageTripFindModal section='date' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.searchCarSharing.id}/${routeObj.app.searchCarSharing.seats.id}`}
                                        element={<PageTripFindModal section='seats' />}
                                    />
                                    <Route path={routeObj.app.user.id} element={<PageUserView />} />
                                    <Route path={routeObj.app.search.id} element={<PageTripResults />} />
                                    <Route path={routeObj.app.trip.id} element={<PageTripView />} />
                                    <Route path={routeObj.app.book.id} element={<PageTripBook />} />
                                    <Route path={routeObj.app.offerSeats.id} element={<PageTripPublish />} />

                                    <Route
                                        path={`${routeObj.app.offerSeats.id}/${routeObj.app.offerSeats.from.id}`}
                                        element={<PageTripPublishModal section='from' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.offerSeats.id}/${routeObj.app.offerSeats.to.id}`}
                                        element={<PageTripPublishModal section='to' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.offerSeats.id}/${routeObj.app.offerSeats.date.id}`}
                                        element={<PageTripPublishModal section='date' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.offerSeats.id}/${routeObj.app.offerSeats.time.id}`}
                                        element={<PageTripPublishModal section='time' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.offerSeats.id}/${routeObj.app.offerSeats.seats.id}`}
                                        element={<PageTripPublishModal section='seats' />}
                                    />
                                    <Route
                                        path={`${routeObj.app.offerSeats.id}/${routeObj.app.offerSeats.comments.id}`}
                                        element={<PageTripPublishModal section='comments' />}
                                    />

                                    <Route path={routeObj.app.rides.id} element={<PageRides />} />

                                    <Route path={routePath('app.rides.offered')} element={<PageRidesOffered />} />
                                    <Route path={routePath('app.rides.offered.archived')} element={<PageRidesOfferedArchived />} />
                                    <Route path={routePath('app.rides.booked')} element={<PageRidesBooked />} />
                                    <Route path={routePath('app.rides.booked.archived')} element={<PageRidesBookedArchived />} />

                                    <Route path={`${routeObj.app.rides.id}/${routeObj.app.rides.offer.id}`} element={<PageRideOffer />} />
                                    <Route path={`${routeObj.app.rides.id}/${routeObj.app.rides.booking.id}`} element={<PageRideBooking />} />
                                    <Route
                                        path={`${routeObj.app.rides.id}/${routeObj.app.rides.archived.id}/${routeObj.app.rides.offer.id}`}
                                        element={<PageRideOfferArchived />}
                                    />
                                    <Route
                                        path={`${routeObj.app.rides.id}/${routeObj.app.rides.archived.id}/${routeObj.app.rides.booking.id}`}
                                        element={<PageRideBookingArchived />}
                                    />

                                    <Route path={routePath('app.profile')} element={<PageProfile />} />
                                    <Route path={routePath('app.profile.verify')} element={<PageProfileVerify />} />
                                    <Route path={routePath('app.profile.verify.verifyId')} element={<PageProfileVerifyModal section='id' />} />
                                    <Route path={routePath('app.profile.verify.verifyMobile')} element={<PageProfileVerifyModal section='mobile' />} />
                                    <Route path={routePath('app.profile.verify.verifyEmail')} element={<PageProfileVerifyModal section='email' />} />
                                    <Route path={routePath('app.profile.verify.verifyPlate')} element={<PageProfileVerifyModal section='plate' />} />
                                    <Route path={routePath('app.profile.personal')} element={<PageProfilePersonal />} />
                                    <Route path={routePath('app.profile.personal.first')} element={<PageProfilePersonalModal section='first' />} />
                                    <Route path={routePath('app.profile.personal.last')} element={<PageProfilePersonalModal section='last' />} />
                                    <Route path={routePath('app.profile.personal.gender')} element={<PageProfilePersonalModal section='gender' />} />
                                    <Route path={routePath('app.profile.personal.dob')} element={<PageProfilePersonalModal section='dob' />} />
                                    <Route path={routePath('app.profile.personal.email')} element={<PageProfilePersonalModal section='email' />} />
                                    <Route path={routePath('app.profile.personal.mobile')} element={<PageProfilePersonalModal section='mobile' />} />
                                    <Route path={routePath('app.profile.personal.photo')} element={<PageProfilePersonalModal section='photo' />} />
                                    <Route path={routePath('app.profile.password')} element={<PageProfilePasswordModal />} />
                                    <Route path={routePath('app.profile.reviews.given')} element={<PageProfile />} />
                                    <Route path={routePath('app.profile.reviews.received')} element={<PageProfile />} />

                                    <Route path={routeObj.app.com.id} element={<PageCom />} />

                                    <Route path={routeObj.app.notifications.id} element={<PageNotifications />} />
                                    <Route path={routeObj.app.messages.id} element={<PageMessages />} />
                                </Route>

                                <Route path='*' element={<PageError />} />
                            </Route>
                        </Routes>
                    </AnimatePresence>
                    <Outlet />
                </OnlineStatusProvider>
            </Provider>
        </>
    );
};

export default App;
