import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logChatPromiseExecution } from 'stream-chat';
import { MessageList, MessageInput, Thread, Window, useChannelActionContext, useChannelStateContext, useChatContext } from 'stream-chat-react';

import { MessagingChannelHeader, MessagingInput } from '../../components';
import './ChannelInner.css';
import ModalBottom from 'components/modals/ModalBottom';
import ModalTop from 'components/modals/ModalTop';
import OfflineMode from 'components/blocks/OfflineMode';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import { banUser } from 'fetch/apiRequests';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion';
import { animations } from 'constants/animations';
import LayoutReport from 'components/layouts/LayoutReport';
import { setChannelHistory } from 'store/actions/chat';
import Spinner from 'components/blocks/Spinner';
import BtnMiddleAction from 'components/buttons/BtnMiddleAction';
import LayoutUserChat from 'components/layouts/LayoutUserChat';
import classNames from 'classnames';
import MessageBox from 'components/blocks/MessageBox';
import { defaultSize } from 'components/icons/defaults';
import { analytics } from 'helpers/segment';

const menuHeight = {
    full: {
        height: { en: 'h-vw-90 md:h-96', fr: 'h-vw-90 md:h-96', ar: 'h-vw-90 md:h-96' },
        rounded: 'rounded-b-3xl'
    },
    profile: {
        height: {
            en: 'h-body',
            fr: 'h-body',
            ar: 'h-body'
        },
        rounded: ''
    },
    report: {
        height: {
            en: 'h-body md:h-full',
            fr: 'h-body md:h-full',
            ar: 'h-body md:h-full'
        },
        rounded: ''
    },
    whatsapp: {
        height: { en: 'h-vw-74 md:h-64', fr: 'h-vw-74 md:h-64', ar: 'h-vw-74 md:h-64' },
        rounded: 'rounded-b-3xl'
    },
    blockuser: {
        height: { en: 'h-vw-60 md:h-60', fr: 'h-vw-60 md:h-60', ar: 'h-vw-60 md:h-60' },
        rounded: 'rounded-b-3xl'
    },
    clear: {
        height: { en: 'h-vw-60 md:h-60', fr: 'h-vw-60 md:h-60', ar: 'h-vw-60 md:h-60' },
        rounded: 'rounded-b-3xl'
    }
};

const reportReasons = [
    'report.dui',
    'report.verbalHarassment',
    'report.dirty',
    'report.sexualHarassment',
    'report.rude',
    'report.dangerousDriving',
    'report.destructionProperty'
];

const ChannelInner = ({ auth: { isAuthenticated }, channelHistory, setChannelHistory, ...props }) => {
    const { theme, toggleMobile, mobileNav, params, handleContainer, setChannel, userId, phoneNumber } = props;
    const [reportingsList, setReportingsList] = useState([...reportReasons]);
    const [reportings, setReportings] = useState([]);
    const isOnline = useOnlineStatus();
    const { client, setActiveChannel } = useChatContext();
    const { sendMessage } = useChannelActionContext();
    const { messages, channel, members: membersRaw } = useChannelStateContext();
    const filteredMessages = messages.filter((message) => message);
    const singleMember = Object.values(channel.state?.members || {}).filter((member) => member.user?.id !== client.user?.id);

    const [member, setMember] = useState(null);
    const [members, setMembers] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [menuSection, setMenuSection] = useState(null);
    const [rootOrigin, setRootOrigin] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(false);

    // const overrideSubmitHandler = (message) => {
    //     let updatedMessage;

    //     if (sendMessage) {
    //         const newMessage = updatedMessage || message;
    //         const parentMessage = newMessage.parent;

    //         const messageToSend = {
    //             ...newMessage,
    //             parent: parentMessage
    //                 ? {
    //                       ...parentMessage,
    //                       created_at: parentMessage.created_at?.toString(),
    //                       pinned_at: parentMessage.pinned_at?.toString(),
    //                       updated_at: parentMessage.updated_at?.toString()
    //                   }
    //                 : undefined
    //         };

    //         const sendMessagePromise = sendMessage(messageToSend);
    //         logChatPromiseExecution(sendMessagePromise, 'send message');
    //     }
    // };

    const toggleModal = (val) => {
        if (isModal && val === true) {
            setRootOrigin(false);
        } else setRootOrigin(true);
        setIsModal(val ?? !isModal);
        setMenuSection(null);
        setReportingsList([...reportReasons]);
        setReportings([]);
    };

    const handleWhatsApp = () => {
        const text = `https://wa.me/${phoneNumber}`;
        sendMessage({ text });
        analytics.track('Whatsapp Shared', null, isAuthenticated);
        toggleModal(false);
    };

    const fetchMembers = async () => {
        const updatedMembers = await channel.queryMembers({}, { created_at: -1 });
        setMembers(updatedMembers.members);
        const singleMember = Object.values(updatedMembers.members || {}).filter((member) => member.user?.id !== client.user?.id);
        setMember(singleMember?.[0]);
        // setActiveChannel(channel);
    };

    const handleBan = async (ban) => {
        const res = await banUser({
            cid: channel.cid,
            userToBan: member.user_id,
            banStatus: ban
        });

        await fetchMembers();
        toggleModal(false);
    };

    const isBanned = member?.shadow_banned;

    const handleClearConversation = async () => {
        if (!loadingHistory) {
            setLoadingHistory(true);

            await channel.hide(null, true);

            setLoadingHistory(false);
            toggleModal(false);
            toggleMobile(true);
        }
    };

    useEffect(() => {
        const container = document.querySelector('.str-chat__list');
        // container.prepend(messageBox);
        handleContainer(container);

        setChannel(channel);
        // setMember(singleMember?.[0]?.user_id);
    }, [channel, client]);

    // useEffect(() => {
    //     console.log('member is', member);
    // }, [member]);

    useEffect(() => {
        fetchMembers();
        return () => {
            // console.log('inner unmounting');
            fetchMembers();
        };
    }, []);

    // useEffect(() => {
    //     if (!mobileNav) {
    //         setTimeout(() => window.scrollTo(0, 0), 400);
    //     }
    // }, [mobileNav]);

    const actions = ['delete', 'edit', 'flag', 'mute', 'react', 'reply'];

    return (
        <>
            <Window>
                <MessagingChannelHeader
                    theme={theme}
                    toggleMobile={toggleMobile}
                    goBack={params?.otherUser}
                    handleOpen={toggleModal}
                    isOpen={isModal}
                    isRootMenu={menuSection === null}
                    menuSection={menuSection}
                    banned={member?.shadow_banned}
                />
                <GetLang>
                    {({ translations, rtl, lang }) => (
                        <>
                            <ModalTop
                                isOpen={isModal}
                                handleOpen={toggleModal}
                                rounded={menuHeight?.[menuSection]?.rounded}
                                move={rtl ? '-translate-x-vw-100 md:translate-x-0' : 'translate-x-vw-100 md:translate-x-0'}
                                height={menuSection ? menuHeight[menuSection].height[lang] : menuHeight['full'].height[lang]}
                            >
                                <>
                                    <LayoutGroup>
                                        <motion.div className={classNames('flex  flex-col items-center h-full')}>
                                            {/* <OfflineMode margins='mt-vw-6 b-vw-6 md:mb-6' /> */}

                                            <div className={'relative w-full h-full'}>
                                                <AnimatePresence>
                                                    {menuSection === null && (
                                                        <motion.div
                                                            initial={rootOrigin ? 'in' : rtl ? 'right' : 'left'}
                                                            key={'fullMenu'}
                                                            exit={rtl ? 'right' : 'left'}
                                                            animate={'in'}
                                                            variants={animations.pageSlides}
                                                            className={
                                                                'absolute top-0 w-full px-vw-6 py-vw-6 md:px-6 md:py-6 flex flex-col justify-end-a h-full'
                                                            }
                                                        >
                                                            <BtnMiddleAction
                                                                disabled={!isOnline}
                                                                label='e.chat.userProfile'
                                                                icon='User'
                                                                handleClick={setMenuSection}
                                                                params={['profile']}
                                                            />
                                                            <BtnMiddleAction
                                                                disabled={!isOnline}
                                                                label='e.chat.reportUser'
                                                                icon='Alarm'
                                                                handleClick={setMenuSection}
                                                                params={['report']}
                                                            />
                                                            <BtnMiddleAction
                                                                disabled={!isOnline}
                                                                label='e.chat.whatsAppLink'
                                                                icon='Whatsapp'
                                                                handleClick={setMenuSection}
                                                                params={['whatsapp']}
                                                            />
                                                            <BtnMiddleAction
                                                                type='cancel-middle'
                                                                disabled={!isOnline}
                                                                label='e.chat.clear'
                                                                icon='Trash'
                                                                handleClick={setMenuSection}
                                                                params={['clear']}
                                                            />
                                                            {/* <BtnMiddleAction
                                                                type='cancel-middle'
                                                                disabled={!isOnline}
                                                                label='e.chat.blockUser'
                                                                icon='UserBlock'
                                                                handleClick={setMenuSection}
                                                                params={['blockuser']}
                                                            />
                                                            <BtnMiddleAction
                                                                disabled={!isOnline}
                                                                label='e.chat.unblockUser'
                                                                icon='UserUnblock'
                                                                handleClick={setMenuSection}
                                                                params={['blockuser']}
                                                            /> */}

                                                            {member?.shadow_banned === false && (
                                                                <BtnMiddleAction
                                                                    type='cancel-middle'
                                                                    disabled={!isOnline}
                                                                    label='e.chat.blockUser'
                                                                    icon='UserBlock'
                                                                    handleClick={setMenuSection}
                                                                    params={['blockuser']}
                                                                />
                                                            )}
                                                            {member?.shadow_banned === true && (
                                                                <BtnMiddleAction
                                                                    disabled={!isOnline}
                                                                    label='e.chat.unblockUser'
                                                                    icon='UserUnblock'
                                                                    handleClick={setMenuSection}
                                                                    params={['blockuser']}
                                                                />
                                                            )}
                                                        </motion.div>
                                                    )}
                                                    {menuSection === 'profile' && (
                                                        <motion.div
                                                            key={'userProfile'}
                                                            initial={rtl ? 'left' : 'right'}
                                                            exit={rtl ? 'left' : 'right'}
                                                            animate={'in'}
                                                            variants={animations.pageSlides}
                                                            className={'absolute top-vw-4 w-full h-full'}
                                                        >
                                                            <LayoutUserChat userId={singleMember?.[0]?.user_id} />
                                                            {/* <LayoutReport
                                                                reportingUser={client.user?.id}
                                                                reportedUser={singleMember?.[0]?.user_id}
                                                                handleModal={toggleModal}
                                                                showCancel
                                                            /> */}
                                                        </motion.div>
                                                    )}
                                                    {menuSection === 'report' && (
                                                        <motion.div
                                                            key={'reportUser'}
                                                            initial={rtl ? 'left' : 'right'}
                                                            exit={rtl ? 'left' : 'right'}
                                                            animate={'in'}
                                                            variants={animations.pageSlides}
                                                            className={'absolute top-0 w-full h-body md:h-full'}
                                                        >
                                                            <LayoutReport
                                                                reportingUser={client.user?.id}
                                                                reportedUser={singleMember?.[0]?.user_id}
                                                                handleModal={toggleModal}
                                                                showCancel
                                                                noHeader
                                                            />
                                                        </motion.div>
                                                    )}
                                                    {menuSection === 'whatsapp' && (
                                                        <motion.div
                                                            key={'whatsappLink'}
                                                            initial={rtl ? 'left' : 'right'}
                                                            exit={rtl ? 'left' : 'right'}
                                                            animate={'in'}
                                                            variants={animations.pageSlides}
                                                            className={
                                                                'absolute top-0 w-full h-full flex flex-col justify-between px-vw-6 py-vw-6 md:px-6 md:py-6'
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    direction: rtl ? 'rtl' : 'ltr'
                                                                }}
                                                                className='px-vw-2 md:px-2  font-semibold text-vw-lg md:text-base text-center text-gray-600'
                                                            >
                                                                {translations.data['e.chat.whatsAppLink.title']}
                                                            </div>

                                                            <div>
                                                                <BtnPill
                                                                    type='primary'
                                                                    justify={'justify-between'}
                                                                    rtl={rtl}
                                                                    params={[false]}
                                                                    handleClick={handleWhatsApp}
                                                                    disabled={!isOnline}
                                                                >
                                                                    <>
                                                                        <span className='w-vw-6 md:w-6' />
                                                                        <span
                                                                            style={{
                                                                                direction: rtl ? 'rtl' : 'ltr'
                                                                            }}
                                                                        >
                                                                            {translations.data['e.chat.whatsAppLink.action']}
                                                                        </span>
                                                                        <Icons icon={'Whatsapp'} size={defaultSize} />
                                                                    </>
                                                                </BtnPill>
                                                                <BtnPill
                                                                    type='default'
                                                                    justify={'justify-center'}
                                                                    rtl={rtl}
                                                                    params={[false]}
                                                                    handleClick={toggleModal}
                                                                >
                                                                    {translations?.data?.['e.later']}
                                                                </BtnPill>
                                                            </div>
                                                        </motion.div>
                                                    )}
                                                    {menuSection === 'clear' && (
                                                        <motion.div
                                                            key={'clear'}
                                                            initial={rtl ? 'left' : 'right'}
                                                            exit={rtl ? 'left' : 'right'}
                                                            animate={'in'}
                                                            variants={animations.pageSlides}
                                                            className={
                                                                'absolute top-0 w-full h-full flex flex-col justify-between px-vw-6 py-vw-6 md:px-6 md:py-6'
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    direction: rtl ? 'rtl' : 'ltr'
                                                                }}
                                                                className='px-vw-2 md:px-2 font-semibold md:text-base text-vw-lg text-center text-gray-600'
                                                            >
                                                                {translations.data['e.chat.clear.title']}
                                                            </div>

                                                            <div>
                                                                <BtnPill
                                                                    type='cancel'
                                                                    justify={loadingHistory ? 'justify-center' : 'justify-between'}
                                                                    rtl={rtl}
                                                                    params={[false]}
                                                                    handleClick={handleClearConversation}
                                                                    disabled={!isOnline}
                                                                >
                                                                    {loadingHistory ? (
                                                                        <Spinner strokeColor='#ffffff' />
                                                                    ) : (
                                                                        <>
                                                                            <span className='w-vw-6 md:w-6' />
                                                                            <span
                                                                                style={{
                                                                                    direction: rtl ? 'rtl' : 'ltr'
                                                                                }}
                                                                            >
                                                                                {translations.data['e.chat.clear.action']}
                                                                            </span>
                                                                            <Icons icon={'Trash'} size={defaultSize} />
                                                                        </>
                                                                    )}
                                                                </BtnPill>
                                                                <BtnPill
                                                                    type='default'
                                                                    justify={'justify-center'}
                                                                    rtl={rtl}
                                                                    params={[false]}
                                                                    handleClick={toggleModal}
                                                                >
                                                                    {translations?.data?.['e.later']}
                                                                </BtnPill>
                                                            </div>
                                                        </motion.div>
                                                    )}
                                                    {menuSection === 'blockuser' && (
                                                        <motion.div
                                                            key={'blockuser'}
                                                            initial={rtl ? 'left' : 'right'}
                                                            exit={rtl ? 'left' : 'right'}
                                                            animate={'in'}
                                                            variants={animations.pageSlides}
                                                            className={
                                                                'absolute top-0 w-full h-full flex flex-col justify-between px-vw-6 py-vw-6 md:px-6 md:py-6'
                                                            }
                                                        >
                                                            <div
                                                                style={{
                                                                    direction: rtl ? 'rtl' : 'ltr'
                                                                }}
                                                                className='px-vw-2 md:px2  font-semibold text-vw-lg md:text-base text-center text-gray-600'
                                                            >
                                                                {translations.data[isBanned ? 'e.chat.unblockUser.title' : 'e.chat.blockUser.title']}
                                                            </div>

                                                            <div>
                                                                <BtnPill
                                                                    type={isBanned ? 'primary' : 'cancel'}
                                                                    justify={'justify-between'}
                                                                    rtl={rtl}
                                                                    params={[isBanned ? false : true]}
                                                                    handleClick={handleBan}
                                                                    disabled={!isOnline}
                                                                >
                                                                    <>
                                                                        <span className='w-vw-6 md:w-6' />
                                                                        <span
                                                                            style={{
                                                                                direction: rtl ? 'rtl' : 'ltr'
                                                                            }}
                                                                        >
                                                                            {translations.data[isBanned ? 'e.chat.unblockUser' : 'e.chat.blockUser']}
                                                                        </span>
                                                                        <Icons icon={isBanned ? 'UserUnblock' : 'UserBlock'} size={defaultSize} />
                                                                    </>
                                                                </BtnPill>
                                                                <BtnPill
                                                                    type='default'
                                                                    justify={'justify-center'}
                                                                    rtl={rtl}
                                                                    params={[false]}
                                                                    handleClick={toggleModal}
                                                                >
                                                                    {translations?.data?.['e.later']}
                                                                </BtnPill>
                                                            </div>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                        </motion.div>
                                    </LayoutGroup>
                                </>
                            </ModalTop>
                            {false && (
                                <ModalBottom
                                    isOpen={false}
                                    handleOpen={null}
                                    move={rtl ? '-translate-x-vw-100' : 'translate-x-vw-100'}
                                    // height=''
                                >
                                    <div className='px-vw-6 py-vw-6 flex flex-col items-center'>
                                        <OfflineMode margins='' />

                                        <>
                                            <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                                {translations?.data?.['e.booking.status.delete.headline']}
                                            </div>
                                            {members?.[member]?.shadow_banned === false && (
                                                <BtnPill
                                                    type='cancel'
                                                    justify={'justify-between'}
                                                    rtl={rtl}
                                                    params={[true]}
                                                    handleClick={handleBan}
                                                    disabled={!isOnline}
                                                >
                                                    <>
                                                        <span className='w-vw-6' />
                                                        Block User
                                                        <Icons icon={'Slash'} size={defaultSize} />
                                                    </>
                                                </BtnPill>
                                            )}
                                            {members?.[member]?.shadow_banned === true && (
                                                <BtnPill
                                                    type='approve'
                                                    justify={'justify-between'}
                                                    rtl={rtl}
                                                    params={[false]}
                                                    handleClick={handleBan}
                                                    disabled={!isOnline}
                                                >
                                                    <>
                                                        <span className='w-vw-6' />
                                                        Unblock User
                                                        <Icons icon={'Check'} size={defaultSize} />
                                                    </>
                                                </BtnPill>
                                            )}
                                            <BtnPill type='cancel' justify={'justify-between'} rtl={rtl} params={[false]} handleClick={() => null}>
                                                <>
                                                    <span className='w-vw-6' />
                                                    Clear Conversation
                                                    <Icons icon={'Close'} size={defaultSize} />
                                                </>
                                            </BtnPill>

                                            <BtnPill type='confirmed' justify={'justify-between'} rtl={rtl} params={[]} handleClick={handleWhatsApp}>
                                                <span className='w-vw-6' />
                                                Whatsapp Link
                                                <Icons icon={'Whatsapp'} size={defaultSize} />
                                            </BtnPill>
                                        </>
                                    </div>
                                </ModalBottom>
                            )}

                            <div className='h-body-w-header-z overflow-y-auto-z no-scrollbar-z'>
                                {!messages?.filter((msg) => msg.type !== 'deleted').length ? (
                                    <MessageBox label={translations.data['e.chat.channel.welcome.label']} margins='mt-vw-6 md:mt-6'>
                                        {translations.data['e.chat.channel.welcome.text']}
                                    </MessageBox>
                                ) : null}
                            </div>
                            <MessageList
                                messageActions={actions}
                                returnAllReadData={true}
                                hideDeletedMessages={true}
                                // internalInfiniteScrollProps={}
                            />

                            {!isBanned && !mobileNav && <MessageInput focus grow maxRows={5} Input={MessagingInput} />}
                        </>
                    )}
                </GetLang>
            </Window>
            <Thread Input={MessagingInput} />
        </>
    );
};

const mapStateToProps = (state) => ({
    chat: state.chat,
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setChannelHistory }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChannelInner);
