import { LayoutPage, LayoutPageHeader, LayoutPageBody, LayoutPageSubHeader, LayoutFindSeats, LayoutFindDate, LayoutFindLocation } from 'components/layouts';
import { connect } from 'react-redux';

import CalendarHeader from 'components/calendar/CalendarHeader';
import OfflineMode from 'components/blocks/OfflineMode';
import InputShell from 'components/forms/InputShell';
import PseudoInput from 'components/forms/PseudoInput';
import { updateSearchDates } from 'store/actions/search';
import { getRelativeDate } from 'helpers/dates';
import Icons from 'components/icons/index';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';

const PageTripFindModal = ({ updateSearchDates, section, search: { search } }) => {
    const pageData = {
        page: `app.searchCarSharing.${section}`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false
    };

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({ rtl, lang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation, online }) => {
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            <OfflineMode position='absolute' />
                        </LayoutPageHeader>

                        {section === 'date' && (
                            <LayoutPageSubHeader label={`e.page.search.${section}.headline`}>
                                {section === 'date' && (
                                    <>
                                        <InputShell margins='mb-vw-8'>
                                            <PseudoInput
                                                disabled
                                                icon='Calendar2'
                                                placeholder='e.page.search.date'
                                                value={getRelativeDate(search.dateRange[0], lang)}
                                                width='w-4/5'
                                            />

                                            {search.dateRange[0] && (
                                                <button
                                                    onClick={() => {
                                                        updateSearchDates([]);
                                                        handleNavigation('app.searchCarSharing', null);
                                                    }}
                                                    className={classNames('focus:outline-none absolute top-1/2 transform -translate-y-1/2 text-gray-500', {
                                                        'right-vw-4': !rtl,
                                                        'left-vw-4': rtl
                                                    })}
                                                >
                                                    <Icons icon='Close' />
                                                </button>
                                            )}
                                        </InputShell>

                                        <CalendarHeader />
                                    </>
                                )}
                            </LayoutPageSubHeader>
                        )}
                        <div className='h-body-w-header overflow-y-auto no-scrollbar'>
                            {section !== 'date' && (
                                <LayoutPageSubHeader label={`e.page.search.${section}.headline`}>
                                    {section === 'date' && <CalendarHeader />}
                                </LayoutPageSubHeader>
                            )}
                            <LayoutPageBody minHeight2='min-h-body-a'>
                                <div className={`select-none px-vw-6 pb-vw-8`}>
                                    {(section === 'from' || section === 'to') && <LayoutFindLocation source={section} />}
                                    {section === 'seats' && <LayoutFindSeats />}
                                    {section === 'date' && <LayoutFindDate />}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    results: state.results
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateSearchDates }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageTripFindModal);
