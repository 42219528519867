import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

const styles = {
    PENDING: {
        color: 'text-gray-400',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Time'
    },
    APPROVED_BY_DRIVER: {
        color: 'text-emerald-600',
        bg: 'bg-emerald-50',
        border: 'border-emerald-300',
        icon: 'CheckDouble'
    },
    APPROVED_BY_ADMIN: {
        color: 'text-emerald-600',
        bg: 'bg-emerald-50',
        border: 'border-emerald-300',
        icon: 'CheckDouble'
    },
    CANCELED_BY_DRIVER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    CANCELED_BY_PASSENGER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    CANCELED_BY_ADMIN: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    DECLINED_BY_DRIVER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },

    DECLINED_BY_ADMIN: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    DECLINED_BY_PASSENGER: {
        color: 'text-red-500',
        bg: 'bg-red-50',
        border: 'border-red-500',
        icon: 'Slash'
    },
    ARCHIVED: {
        color: 'text-gray-700',
        bg: 'bg-gray-100',
        border: 'border-gray-300',
        icon: 'Archive'
    },
    REVIEW: {
        color: 'text-yellow-700',
        bg: 'bg-yellow-100',
        border: 'border-yellow-300',
        icon: 'StarStroke'
    },
    LADIESONLY: {
        color: 'text-pink-600',
        bg: 'bg-pink-50',
        border: 'border-t border-pink-600/20',
        icon: 'Female1'
    }
};

const StatusBooked = ({
    height = 'h-vw-12',
    ladiesOnly,
    threshold = 0,
    toReview,
    status = 'PENDING',
    noText,
    archive = false,
    icon,
    children,
    margins = 'mb-6a',
    seats
}) => {
    const colorStatus = archive ? (toReview ? 'REVIEW' : 'ARCHIVED') : status;

    const finalStatus = status.startsWith('APPROVED_BY_') && archive ? 'COMPLETE' : status;

    return (
        <GetLang>
            {({ translations, lang, rtl }) => (
                <>
                    {!noText ? (
                        <div className={(margins, 'w-full')}>
                            <div
                                className={classNames(
                                    height,
                                    rtl ? 'text-vw-base' : 'tracking-wide text-vw-sm ',
                                    'w-full px-vw-6 flex items-center justify-center uppercase font-medium ',
                                    styles[colorStatus]?.color,
                                    styles[colorStatus]?.bg,
                                    styles[colorStatus]?.border,
                                    {
                                        'flex-row-reverse': rtl
                                    }
                                )}
                            >
                                <Icons icon={icon || styles[status]?.icon} />
                                <span className='mx-vw-2 md:mx-2'>{children || translations?.data?.[`booking.request.${finalStatus}`]}</span>
                            </div>
                            {ladiesOnly && (
                                <div
                                    className={classNames(
                                        rtl ? 'text-vw-base' : 'tracking-wide text-vw-sm ',
                                        'w-full px-vw-6 h-vw-12 flex items-center justify-center uppercase font-medium  ',
                                        styles['LADIESONLY']?.color,
                                        styles['LADIESONLY']?.bg,
                                        styles['LADIESONLY']?.border,
                                        {
                                            'flex-row-reverse': rtl
                                        }
                                    )}
                                >
                                    <Icons icon={icon || styles['LADIESONLY']?.icon} />
                                    <span className='mx-vw-2'>{translations?.data?.['e.ride.status.ladiesOnly']}</span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                'h-vw-10 md:h-10 flex-none rounded-full flex items-center text-vw-sm md:text-sm font-bold relative',

                                styles[colorStatus]?.bg,
                                styles[colorStatus]?.color
                            )}
                        >
                            <div
                                className={classNames(
                                    'h-vw-10 w-vw-10 md:h-10 md:w-10 rounded-full flex items-center justify-center flex-none',
                                    styles[colorStatus]?.bg,
                                    styles[colorStatus]?.color
                                )}
                            >
                                <Icons icon={styles[status]?.icon} />
                            </div>
                            {seats > threshold ? <div className={classNames('pr-4 pl-0')}>{`x ${seats}`}</div> : null}
                        </div>
                    )}
                </>
            )}
        </GetLang>
    );
};

export default StatusBooked;
