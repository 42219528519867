import { routePath, validateRoute } from 'constants/structRoutes';
import {
    Outlet,
    useParams,
    useNavigate,
    useLocation,
    Navigate
} from 'react-router-dom';
const PageStatic = ({ step }) => {
    let location = useLocation();

    if (validateRoute('static', location)) {
        return <div>Page Static Home</div>;
    }

    return <Outlet />;
};

export default PageStatic;
