export const routeObj = {
    static: {
        id: '/s',
        about: {
            id: 'about'
        },
        safety: {
            id: 'safety'
        },
        faq: {
            id: 'faq'
        },
        help: {
            id: 'help',
            support: {
                id: 'support'
            },
            terms: {
                id: 'terms'
            },
            privacy: {
                id: 'privacy'
            },
            weNeedYou: {
                id: 'we-need-you'
            },
            pricing: {
                id: 'pricing'
            },
            gender: {
                id: 'gender'
            }
        },
        latest: {
            id: 'latest'
        }
    },

    login: {
        id: '/login'
    },
    register: {
        id: '/register'
    },
    social: {
        id: '/social'
    },
    done: {
        id: '/done'
    },
    logout: {
        id: '/logout'
    },
    settings: {
        id: '/settings'
    },

    app: {
        id: '/c',
        profile: {
            id: 'profile',

            verify: {
                id: 'verify',
                verifyId: { id: 'id' },
                verifyMobile: { id: 'mobile' },
                verifyEmail: { id: 'email' },
                verifyPlate: { id: 'plate' },
                verifyDriver: { id: 'driver' }
            },
            personal: {
                id: 'personal',
                first: { id: 'first' },
                last: { id: 'last' },
                gender: { id: 'gender' },
                dob: { id: 'dob' },
                email: { id: 'email' },
                mobile: { id: 'mobile' },
                photo: {
                    id: 'photo'
                }
            },
            reviews: {
                id: 'reviews',
                received: {
                    id: 'received'
                },
                given: {
                    id: 'given'
                }
            },
            password: {
                id: 'password'
            }
        },

        dashboard: {
            id: 'dashboard',
            profile: {
                id: 'profile',
                menu: {
                    id: 'menu'
                },
                account: {
                    id: 'account'
                }
            }
        },
        searchCarSharing: {
            id: 'search-car-sharing',
            from: {
                id: 'from'
            },
            to: {
                id: 'to'
            },
            date: {
                id: 'date'
            },
            seats: {
                id: 'seats'
            }
        },
        admin: {
            id: 'admin',
            users: {
                id: 'users'
            },
            user: {
                id: 'user'
            },
            verify: {
                id: 'verify'
            },
            verification: {
                id: 'verification'
            }
        },
        user: {
            id: 'user'
        },
        search: {
            id: 'search',
            filters: {
                id: 'filters'
            }
        },
        trip: {
            id: 'trip'
        },
        book: {
            id: 'book'
        },
        offerSeats: {
            id: 'offer-seats',
            from: {
                id: 'from',
                precise: {
                    id: 'precise'
                }
            },
            to: {
                id: 'to',
                precise: {
                    id: 'precise'
                }
            },
            date: {
                id: 'date'
            },
            time: {
                id: 'time'
            },
            seats: {
                id: 'seats'
            },
            comments: {
                id: 'comments'
            }
        },
        rides: {
            id: 'rides',
            archived: {
                id: 'archived',
                offer: {
                    id: 'offer'
                },
                booking: {
                    id: 'booking'
                }
            },
            offer: {
                id: 'offer'
            },
            booking: {
                id: 'booking'
            },
            offered: {
                id: 'offered',
                archived: {
                    id: 'archived'
                }
            },
            booked: {
                id: 'booked',
                archived: {
                    id: 'archived'
                }
            }
        },
        com: {
            id: 'com'
        },
        messages: {
            id: 'messages'
        },
        notifications: {
            id: 'notifications'
        }
    }
};

export const langButtons = [
    {
        lang: 'ar',
        label: 'عربي'
    },
    {
        lang: 'en',
        label: 'English'
    },
    {
        lang: 'fr',
        label: 'Français'
    }
];

export const timeButtons = [
    {
        time24: true,
        label: 'e.pageSettings.time.time24'
    },
    {
        time24: false,
        label: 'e.pageSettings.time.timeAMPM'
    }
];

export const motionButtons = [
    {
        motion: 0,
        label: 'e.pageSettings.motion.0'
    },
    {
        motion: 5,
        label: 'e.pageSettings.motion.5'
    },
    {
        motion: 10,
        label: 'e.pageSettings.motion.10'
    }
];

export const mapTypeToSentence = {
    PLATE_PENDING: {
        title: 'e.notification.admin.plate.title'
    },
    PLATE_APPROVED: {
        title: 'e.notification.admin.plate.title'
    },
    PLATE_CANCELED: {
        title: 'e.notification.admin.plate.title'
    },
    PLATE_REJECTED: {
        title: 'e.notification.admin.plate.title'
    },
    ID_PENDING: {
        title: 'e.notification.admin.id.title'
    },
    ID_APPROVED: {
        title: 'e.notification.admin.id.title'
    },
    ID_CANCELED: {
        title: 'e.notification.admin.id.title'
    },
    ID_REJECTED: {
        title: 'e.notification.admin.id.title'
    },
    BOOKING_DRIVER_PASSENGER_REQUESTED: {
        title: 'e.notification.booking.request.sent.title',
        body: 'e.notification.booking.request.sent.body'
    },
    BOOKING_DRIVER_PASSENGER_CANCELED: {
        title: 'e.notification.booking.request.canceled.title',
        body: 'e.notification.booking.request.canceled.body'
    },

    BOOKING_PASSENGER_DRIVER_CANCELED: {
        title: 'e.notification.booking.canceledByDriver.title',
        body: 'e.notification.booking.canceledByDriver.body'
    },

    BOOKING_PASSENGER_ADMIN_CANCELED: {
        title: 'e.notification.booking.canceledByAdmin.title',
        body: 'e.notification.booking.canceledByAdmin.body'
    },

    BOOKING_PASSENGER_DRIVER_DECLINED: {
        title: 'e.notification.booking.declinedByDriver.title',
        body: 'e.notification.booking.declinedByDriver.body'
    },

    BOOKING_PASSENGER_ADMIN_DECLINED: {
        title: 'e.notification.booking.delcinedByAdmin.title',
        body: 'e.notification.booking.delcinedByAdmin.body'
    },
    BOOKING_PASSENGER_DRIVER_APPROVED: {
        title: 'e.notification.booking.approvedByDriver.title',
        body: 'e.notification.booking.approvedByDriver.body'
    },

    BOOKING_PASSENGER_ADMIN_APPROVED: {
        title: 'e.notification.booking.approvedByAdmin.title',
        body: 'e.notification.booking.approvedByAdmin.body'
    },
    ERROR: {
        title: 'e.notification.error.title',
        body: 'e.notification.error.body'
    },
    REWARD: {
        title: 'e.notification.reward.title',
        body: 'e.notification.reward.body'
    },
    MESSAGE_SENT: {
        title: 'e.notification.messageSent.title',
        body: 'e.notification.messageSent.body'
    },
    MESSAGE_RECEIVED: {
        title: 'e.notification.messageReceived.title',
        body: 'e.notification.messageReceived.body'
    },
    REWARD_SIGNUP: {
        title: 'e.notification.reward.signup.title',
        body: 'e.notification.reward.signup.body'
    },
    REWARD_COMPLETED_RIDE_PASSENGER: {
        title: 'e.notification.reward.completedRidePassenger.title',
        body: 'e.notification.reward.completedRidePassenger.body'
    },
    REWARD_COMPLETED_RIDE_DRIVER_MAIN: {
        title: 'e.notification.reward.completedRideDriverMain.title',
        body: 'e.notification.reward.completedRideDriverMain.body'
    },
    REWARD_COMPLETED_RIDE_DRIVER_SEAT: {
        title: 'e.notification.reward.completedRideDriverSeat.title',
        body: 'e.notification.reward.completedRideDriverSeat.body'
    },
    REWARD_REVIEW_DRIVER: {
        title: 'e.notification.reward.reviewDriver.title',
        body: 'e.notification.reward.reviewDriver.body'
    },
    REWARD_REFERRAL: {
        title: 'e.notification.reward.referral.title',
        body: 'e.notification.reward.referral.body'
    },
    VERIFICATION_ID_PENDING: {
        title: 'e.notification.verification.id.pending.title',
        body: 'e.notification.verification.id.pending.body'
    },
    VERIFICATION_ID_REJECTED: {
        title: 'e.notification.verification.id.rejected.title',
        body: 'e.notification.verification.id.rejected.body'
    },
    VERIFICATION_ID_CANCELED: {
        title: 'e.notification.verification.id.canceled.title',
        body: 'e.notification.verification.id.canceled.body'
    },
    VERIFICATION_ID_APPROVED: {
        title: 'e.notification.verification.id.approved.title',
        body: 'e.notification.verification.id.approved.body'
    },
    VERIFICATION_PLATE_PENDING: {
        title: 'e.notification.verification.car.pending.title',
        body: 'e.notification.verification.car.pending.body'
    },
    VERIFICATION_PLATE_REJECTED: {
        title: 'e.notification.verification.car.rejected.title',
        body: 'e.notification.verification.car.rejected.body'
    },
    VERIFICATION_PLATE_CANCELED: {
        title: 'e.notification.verification.car.canceled.title',
        body: 'e.notification.verification.car.canceled.body'
    },
    VERIFICATION_PLATE_APPROVED: {
        title: 'e.notification.verification.car.approved.title',
        body: 'e.notification.verification.car.approved.body'
    },
    VERIFICATION_PROFILE_APPROVED: {
        title: 'e.notification.verification.profile.approved.title',
        body: 'e.notification.verification.profile.approved.body'
    }
};

export const routePath = (stringPath, obj = routeObj, trimChars = false) => {
    const subPathArr = stringPath.split('.');
    const names = subPathArr.map((item, index) => {
        const microArr = subPathArr.slice(0, index + 1);
        return (
            microArr.reduce((object, path) => {
                return (object || {})[path];
            }, obj)?.id || ''
        );
    });
    const reduced = names.reduce((prev, cur) => prev + '/' + cur);
    return trimChars ? reduced.substring(1) : reduced;
};

export const routesBuild = (arr) => {
    return arr.map((item, index) => arr.slice(0, index + 1).reduce((pVal, cVal) => pVal + '.' + cVal));
};

export const validateRoute = (pathString, location) => {
    if (location.pathname === `${routePath(pathString)}` || location.pathname === `${routePath(pathString)}/`) return true;

    return false;
};
