import Icons from 'components/icons/index';
import classNames from 'classnames';

const colors = {
    default: {
        text: 'text-gray-600',
        color: 'bg-gray-100',
        border: ''
    },
    'dark-gray': {
        text: 'text-gray-900',
        color: 'bg-gray-300',
        border: ''
    },
    black: {
        text: 'text-white',
        color: 'bg-gray-900',
        border: ''
    },
    'border-gray': {
        text: 'text-gray-900',
        // color: 'bg-gray-300',
        border: 'border-2 border-gray-900'
    },
    'light-green': {
        text: 'text-emerald-600',
        color: 'bg-emerald-100',
        border: ''
    },
    'light-red': {
        text: 'text-red-600',
        color: 'bg-red-100',
        border: ''
    },
    red: {
        text: 'text-white',
        color: 'bg-bb-red-400',
        border: ''
    },
    blue: {
        text: 'text-white',
        color: 'bg-bb-blue-550',
        border: ''
    },
    'light-blue': {
        text: 'text-bb-blue-500',
        color: 'bg-bb-blue-50',
        border: ''
    },
    'light-pink': {
        text: 'text-pink-600',
        color: 'bg-pink-100',
        border: ''
    },
    'light-violet': {
        text: 'text-violet-600',
        color: 'bg-violet-100',
        border: ''
    }
};

const IconRoundStatus = ({ icon, colorPreset = 'default' }) => {
    return (
        <div
            className={classNames(
                'h-vw-10 w-vw-10 md:h-10 md:w-10 rounded-full flex items-center justify-center flex-none',
                colors[colorPreset]?.color,
                colors[colorPreset]?.text
            )}
        >
            <Icons icon={icon ?? 'Close'} />
        </div>
    );
};

export default IconRoundStatus;
