import Icons from 'components/icons/index';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const RatingSlider = ({ rating, setUserRating, userRating, name, ask }) => {
    const starsArr = [1, 2, 3, 4, 5];

    const handleRating = (star) => {
        if (star === userRating) setUserRating(0);
        else setUserRating(star);
    };

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div
                    className={classNames(
                        rating ? 'pb-vw-4' : 'pb-vw-8',
                        'pt-vw-4 flex flex-col items-center'
                    )}
                >
                    {true && (
                        <div
                            style={{
                                direction: rtl ? 'rtl' : 'ltr'
                            }}
                            className={classNames(
                                'px-vw-2 mb-vw-4 font-semibold text-vw-lg text-center text-gray-600 flex gap-1'
                            )}
                        >
                            {rating ? (
                                translations?.data?.['e.rate.driver.done']
                            ) : (
                                <>
                                    <span>
                                        {
                                            translations?.data?.[
                                                'e.rate.driver.title'
                                            ]
                                        }
                                    </span>
                                    <span>{name}</span>
                                    <span>{rtl ? '؟' : '?'}</span>
                                </>
                            )}
                        </div>
                    )}
                    <div
                        className={classNames(
                            rating ? 'text-gray-500' : 'text-yellow-400',
                            ' flex items-center gap-5 ',
                            rtl && 'flex-row-reverse'
                        )}
                    >
                        {starsArr.map((star, index) => (
                            <div
                                key={star}
                                onClick={
                                    rating ? null : () => handleRating(star)
                                }
                            >
                                <Icons
                                    size={rating ? '6vw' : '9vw'}
                                    icon={
                                        star > (rating || userRating)
                                            ? 'StarStroke'
                                            : 'StarFilled'
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default RatingSlider;
