/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { defaultPublishObj } from 'constants/defaultObjects';

import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    isValid: true,
    selectedDay: null,
    publish: defaultPublishObj()
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const updateSelectedDay = (draft, action) => {
    const { day } = action;
    draft.selectedDay = day;
    return draft;
};

const updatePublishFull = (draft, action) => {
    const { rideObj } = action;
    draft.publish = rideObj;
    return draft;
};

const updatePublishLocation = (draft, action) => {
    const { location, source } = action;
    draft.publish[source] = location;
    return draft;
};

const updatePublishSeats = (draft, action) => {
    const { seatsOffered } = action;

    draft.publish.seatsOffered = seatsOffered;
    return draft;
};

const updatePublishDate = (draft, action) => {
    const { date } = action;

    draft.publish.date = date;
    return draft;
};

const updatePublishDateRange = (draft, action) => {
    const { key, body } = action;
    if (key != null) draft.publish.dateRange[key] = body;
    else draft.publish.dateRange = body;

    return draft;
};

const updatePublishTime = (draft, action) => {
    const { time } = action;

    draft.publish.time = time;
    return draft;
};

const updatePublishLadiesOnly = (draft, action) => {
    const { ladiesOnly } = action;

    draft.publish.ladiesOnly = ladiesOnly;
    return draft;
};

const updatePublishRecurring = (draft, action) => {
    const { recurring } = action;

    draft.publish.recurring = recurring;
    return draft;
};

const updatePublishDescription = (draft, action) => {
    const { description } = action;

    draft.publish.description = description;
    return draft;
};

const updatePublishPreferences = (draft, action) => {
    const { preferences } = action;

    draft.publish.preferences = preferences;
    return draft;
};

const togglePublishSwitching = (draft, action) => {
    const to = { ...draft.publish.to };
    const from = { ...draft.publish.from };
    draft.publish.from = to;
    draft.publish.to = from;
    return draft;
};

export default createReducer(initialState, {
    [types.PUBLISH_SELECT_DAY]: updateSelectedDay,
    [types.PUBLISH_LOCATION_UPDATE]: updatePublishLocation,
    [types.PUBLISH_RIDE_UPDATE]: updatePublishFull,
    [types.PUBLISH_SEATS_UPDATE]: updatePublishSeats,
    [types.PUBLISH_DATE_UPDATE]: updatePublishDate,
    [types.PUBLISH_DATERANGE_UPDATE]: updatePublishDateRange,
    [types.PUBLISH_TIME_UPDATE]: updatePublishTime,
    [types.PUBLISH_LADIESONLY_UPDATE]: updatePublishLadiesOnly,
    [types.PUBLISH_RECURRING_UPDATE]: updatePublishRecurring,
    [types.PUBLISH_DESCRIPTION_UPDATE]: updatePublishDescription,
    [types.PUBLISH_PREFERENCES_UPDATE]: updatePublishPreferences,
    [types.PUBLISH_STOP_SWITCH]: togglePublishSwitching
});
