import { LayoutPage, LayoutPageBody } from 'components/layouts';
import { routePath } from 'constants/structRoutes';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnIcon from 'components/buttons/BtnIcon';
// import imageSearch from 'components/images/bb_results_01@2x.png';
// import imageSearchMirror from 'components/images/bb_results_01@2x.png';
import imageSearch from 'components/images/bb_search_mode2.png';
import imageSearch1 from 'components/images/bg_search1.svg';
import imageSearchMirror1 from 'components/images/bg_search_mirror1.svg';
import imageSearchMirror from 'components/images/bb_search_mode2_mirror.png';
import { getRelativeDate, isDateInPast } from 'helpers/dates';
import { clearSearch, toggleSearchSwitching, updateSearchDates, updateSearchFilters, updateSearchLocation } from 'store/actions/search';
import BtnPill from 'components/buttons/BtnPill';
import InputShell from 'components/forms/InputShell';
import PseudoInput from 'components/forms/PseudoInput';
import { useLayoutEffect, useEffect } from 'react';
import { loadUser, loginSetStep } from 'store/actions/auth';
import OfflineMode from 'components/blocks/OfflineMode';
import { setScroll } from 'store/actions/globalState';
import { widthBiggerThan } from 'helpers/utils';
import { defaultSearchObj } from 'constants/defaultObjects';
import { updateProfile } from 'fetch/apiRequests';
import { setStaticPage } from 'store/actions/staticPages';
import { analytics } from 'helpers/segment';

import LottieBlock from 'components/blocks/LottieBlock';
import useWindowSize from 'hooks/useWindowSize';

const PageTripFind = ({
    setStaticPage,
    clearSearch,
    loadUser,
    setScroll,
    loginSetStep,
    updateSearchDates,
    updateSearchLocation,
    updateSearchFilters,
    toggleSearchSwitching,
    globalState: { lang },
    auth: {
        isAuthenticated,
        setupStep,
        user: { profile }
    },
    search: { search, isValid }
}) => {
    const pageData = {
        page: `app.searchCarSharing`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false
    };

    const { handleNavigation, location } = useMyNavigation();

    const { ratio } = useWindowSize();

    useEffect(() => {
        if (!location?.state?.from?.startsWith('/c/search-car-sharing')) {
            analytics.page('Search Page', 'App', isAuthenticated);
        }
    }, []);

    useEffect(() => {
        setTimeout(validateSetup, 600);
        // setTimeout(() => setStaticPage('latestNews', null), 700);
    }, []);

    const validateSetup = () => {
        if (setupStep !== 'language') {
            loginSetStep('language');
        }
    };

    const handleSearch = () => {
        setScroll(null);
        handleNavigation('app.search', null);
    };

    const handleBrowse = () => {
        setScroll(null);
        clearSearch();
        handleNavigation('app.search', null);
    };

    const handleClear = (box) => {
        const clearedPublish = defaultSearchObj();

        switch (box) {
            case 'location-from':
                updateSearchLocation(clearedPublish.from, 'from');
                break;
            case 'location-to':
                updateSearchLocation(clearedPublish.to, 'to');
                break;
            case 'date':
                let selectedDay = search?.dateRange?.[0] ? new Date(search?.dateRange?.[0])?.getDay() - 1 : null;
                if (selectedDay === -1) selectedDay = 6;

                const currentDays = [...search?.filters?.dateRange];
                if (selectedDay) currentDays[selectedDay] = false;
                updateSearchFilters({ dateRange: currentDays });

                updateSearchDates(clearedPublish.dateRange);

                break;
        }
    };

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({ rtl, lang, tripLang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation, online }) => {
                return (
                    <>
                        <div className='h-body md:h-full overflow-y-auto no-scrollbar'>
                            <LayoutPageBody minHeight='h-body md:h-full'>
                                <div className={`select-none`}>
                                    <div>
                                        <div className={classNames('text-white text-vw-4xl md:text-4xl bg-bb-blue-900 relative')}>
                                            <div
                                                className={classNames(ratio < 0.5 ? 'h-screen-2/5' : 'h-screen-1/3', 'w-full  md:h-128 bg-bb-blue-900-a')}
                                                // style={{
                                                //     backgroundImage: `url(${rtl ? imageSearchMirror1 : imageSearch1})`
                                                //     // zIndex: '-1'
                                                // }}
                                            >
                                                <div className='absolute inset-x-0 top-0 h-screen-2/5 overflow-hidden'>
                                                    <LottieBlock speed={0.3} rtl={rtl} />
                                                </div>
                                                <div
                                                    className={classNames('absolute w-full flex flex-col px-vw-10 md:px-10 pt-vh-6 md:pt-6', {
                                                        'items-end': rtl
                                                    })}
                                                >
                                                    <div
                                                        className={classNames(
                                                            'flex items-center gap-2 font-extrabold tracking-tight mb-vw-2 md:mb-2 leading-tighter',
                                                            {
                                                                'text-right flex-row-reverse': rtl
                                                            }
                                                        )}
                                                    >
                                                        <span> {translations?.data?.['e.page.search.poster.title']}</span>
                                                    </div>
                                                    <div
                                                        className={classNames('text-vw-xl md:text-xl text-bb-yellow-400 ', {
                                                            'text-right flex-row-reverse': rtl
                                                        })}
                                                    >
                                                        {translations?.data?.['e.page.search.poster.subtitle1']}
                                                    </div>
                                                    <div
                                                        className={classNames('text-vw-xl md:text-xl text-bb-yellow-400', {
                                                            'text-right justify-end': rtl
                                                        })}
                                                    >
                                                        {translations?.data?.['e.page.search.poster.subtitle2']}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={classNames(
                                                '-mt-8 px-vw-10 pt-vw-12 pb-vw-6 md:px-40 md:pt-12 md:pb-6 flex flex-col bg-white rounded-t-3xl relative'
                                            )}
                                        >
                                            <div className={classNames('absolute top-0 transform left-1/2 -translate-x-1/2 -translate-y-1/2')}>
                                                <BtnIcon
                                                    tapClass='bg-yellow-50 text-yellow-600'
                                                    color='text-yellow-600'
                                                    size='w-vw-14 h-vw-14 md:w-14 md:h-14'
                                                    bgColor='bg-bb-yellow-400'
                                                    icon='ArrowUpDown1'
                                                    iconSize={widthBiggerThan() ? '24px' : '6.8vw'}
                                                    handleClick={toggleSearchSwitching}
                                                />
                                            </div>
                                            <OfflineMode margins='pb-vw-6 md:pb-6' />
                                            <InputShell>
                                                <PseudoInput
                                                    clear={() => handleClear('location-from')}
                                                    show={search.from?.[tripLang]?.formatted}
                                                    toPage='app.searchCarSharing.from'
                                                    icon='MapPin1'
                                                    placeholder='e.page.search.from'
                                                    value={search.from?.[tripLang]?.formatted}
                                                />
                                            </InputShell>
                                            <InputShell>
                                                <PseudoInput
                                                    clear={() => handleClear('location-to')}
                                                    show={search.to?.[tripLang]?.formatted}
                                                    toPage='app.searchCarSharing.to'
                                                    icon='MapPin1'
                                                    placeholder='e.page.search.to'
                                                    value={search.to?.[tripLang]?.formatted}
                                                />
                                            </InputShell>
                                            <InputShell>
                                                <PseudoInput
                                                    clear={() => handleClear('date')}
                                                    show={search.dateRange?.length > 0}
                                                    toPage='app.searchCarSharing.date'
                                                    icon='Calendar2'
                                                    placeholder='e.page.search.date'
                                                    value={getRelativeDate(search.dateRange[0], lang)}
                                                    // width='w-4/5'
                                                />
                                                {/* <PseudoInput
                                                    toPage='app.searchCarSharing.seats'
                                                    icon='User'
                                                    value={search?.seats}
                                                    width=''
                                                /> */}
                                            </InputShell>

                                            <div className={classNames('flex items-center justify-center mt-vw-2')}>
                                                <BtnPill type='primary' handleClick={handleSearch} disabled={!online} justify={'justify-between'} rtl={rtl}>
                                                    <Icons icon={'Find'} />
                                                    {translations?.data?.['e.page.search.find']}
                                                    <span className='w-6' />
                                                </BtnPill>
                                            </div>
                                            <div className={classNames('flex items-center justify-center mt-vw-4')}>
                                                <BtnPill type='confirmed' handleClick={handleBrowse} disabled={!online} justify={'justify-between'} rtl={rtl}>
                                                    <Icons icon={'Global2'} />
                                                    {translations?.data?.['e.page.search.all']}
                                                    <span className='w-6' />
                                                </BtnPill>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState,
    search: state.search,
    results: state.results
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearSearch,
            updateSearchDates,
            updateSearchLocation,
            updateSearchFilters,
            toggleSearchSwitching,
            loginSetStep,
            setScroll,
            loadUser,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTripFind);
