import React, { useContext, useState, useEffect, useRef } from 'react';
import { ChatContext } from 'stream-chat-react';
import classNames from 'classnames';
import { getCleanImage } from '../../assets';
import Avatar from 'components/blocks/Avatar';

import './MessagingChannelPreview.css';
import GetLang from 'hoc/GetLang';
import { translations } from 'constants/translations';
import CardSection from 'components/cards/CardSection';

const getTimeStamp = (channel) => {
    let lastHours = channel.state.last_message_at?.getHours();
    let lastMinutes = channel.state.last_message_at?.getMinutes();
    let half = 'AM';

    if (lastHours === undefined || lastMinutes === undefined) {
        return '';
    }

    if (lastHours > 12) {
        lastHours = lastHours - 12;
        half = 'PM';
    }

    if (lastHours === 0) lastHours = 12;
    if (lastHours === 12) half = 'PM';

    if (lastMinutes.toString().length === 1) {
        lastMinutes = `0${lastMinutes}`;
    }

    return `${lastHours}:${lastMinutes} ${half}`;
};

const MessagingChannelPreview = (props) => {
    const { channel, latestMessage, lastMessage, setActiveChannel, setIsCreating, toggleMobile, setMode, channelActive } = props;

    const [unread, setUnread] = useState(null);

    const { channel: activeChannel, client } = useContext(ChatContext);

    const isActiveChannel = channel?.id === activeChannel?.id;

    const [member, setMember] = useState(Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID)?.[0]);

    // const member = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID)?.[0];

    const watchChannel = async () => {
        await channel?.watch();
        // console.log(channel.state.members);
    };

    useEffect(() => {
        setUnread(channel.countUnread());
    }, [channel.countUnread()]);

    useEffect(() => {
        const qM = async () => {
            const res = await channel.queryMembers({});
            const updatedMember = res.members.filter(({ user }) => user.id !== client.userID)?.[0];
            setMember(updatedMember);
            // console.log('updated Member', updatedMember);
        };
        qM();
    }, [channel]);

    useEffect(() => {
        // console.log('channel', channel);
        // console.log('client', client);
    }, [channel]);

    // useEffect(() => {
    //     console.log('channel', channel);
    // }, [channel]);

    // const handleClearConversation = async () => {

    //     await channel.hide(null, true);
    // };

    const handleClearConversation = () => {
        setMode?.({ type: 'DELETE_CONVERSATION', channel });
    };

    const handleBlockUser = () => {
        setMode?.({ type: 'BLOCK_USER', channel });
    };

    // const watchActiveChannel = async () => {
    //     await activeChannel?.watch();
    //     console.log(activeChannel.state.members);

    //     const singleMember = Object.values(activeChannel.state.members || {}).filter((member) => member.user?.id !== client.user?.id);
    //     setMember(singleMember?.[0]);
    // };

    useEffect(() => {
        setUnread(channel.countUnread());
    }, [channel.countUnread()]);

    // useEffect(() => {
    //     if (isActiveChannel) watchActiveChannel();
    // }, []);

    const swipeMenuOptions = [
        {
            icon: 'Trash',
            handleClick: handleClearConversation,
            params: []
        }
        // {
        //     icon: 'UserBlock',
        //     handleClick: handleBlockUser,
        //     params: []
        // }
    ];

    const handleActiveChannel = () => {
        setIsCreating(false);
        setActiveChannel(channel);
        toggleMobile();
    };

    const isBanned = member?.shadow_banned;

    return (
        <CardSection
            paddingY='py-vw-4 md:py-0 '
            border='border-ta border-b'
            borderColor={isActiveChannel ? 'border-gray-200' : 'border-gray-200'}
            bgColor={isActiveChannel ? 'bg-bb-blue-600' : 'bg-white'}
            swipeMenuOptions={swipeMenuOptions}
            handleClick={handleActiveChannel}
            arrow
            arrowColor={isActiveChannel ? 'text-white' : 'text-gray-500'}
        >
            <GetLang>
                {({ rtl, translations }) => (
                    <>
                        <div
                            style={{ direction: rtl ? 'rtl' : 'ltr' }}
                            className={classNames(
                                'flex items-center md:h-20'

                                // channel?.id === activeChannel?.id ? 'channel-preview__container selected2' : 'channel-preview__container2'
                            )}
                            // onClick={() => {
                            //     setIsCreating(false);
                            //     setActiveChannel(channel);
                            //     toggleMobile();
                            // }}
                        >
                            {/* {getAvatarGroup(members)} */}
                            <Avatar
                                profile={{ avatar: member?.user?.image, verifiedProfile: member?.user?.verifiedProfile }}
                                banned={isBanned}
                                // banned={true}
                                // size='w-vw-10 h-vw-10'
                                // iconSize={24}
                            />
                            <div className={classNames(rtl ? 'mr-vw-4 md:mr-4' : 'ml-vw-4 md:ml-4', 'w-full relative')}>
                                <div className='flex justify-between w-full'>
                                    <div className={classNames('text-vw-lg md:text-lg font-semibold ', isActiveChannel ? 'text-white' : 'text-bb-blue-550')}>
                                        {/* {channel.data.name || getChannelName(members)} */}
                                        {member?.user?.name || 'Unidentified User'}
                                    </div>
                                    <div
                                        className={classNames('text-vw-sm md:text-sm font-medium', isActiveChannel ? 'text-white/75' : 'text-gray-500')}
                                        // className='channel-preview__content-time2'
                                        style={{ direction: 'ltr' }}
                                    >
                                        {getTimeStamp(channel)}
                                    </div>
                                </div>
                                <div
                                    style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                    className={classNames(
                                        rtl && 'text-right',
                                        'text-vw-base md:text-base font-medium w-vw-60 md:w-60 flex-none truncate',
                                        isActiveChannel ? 'text-white/75' : 'text-gray-500'
                                    )}
                                >
                                    {latestMessage
                                        ? lastMessage?.type === 'deleted'
                                            ? translations.data['e.chat.deletedMessage']
                                            : latestMessage?.toLowerCase?.() === 'nothing yet...'
                                            ? translations.data['e.sendMessage']
                                            : latestMessage
                                        : translations.data['e.sendMessage']}

                                    {/* {lastMessage?.type === 'deleted'
                                    ? translations.data['e.chat.deletedMessage']
                                    : latestMessage
                                    ? latestMessage?.toLowerCase() === 'nothing yet'
                                        ? translations.data['e.sendMessage']
                                        : latestMessage
                                    : translations.data['e.sendMessage']} */}
                                </div>
                            </div>
                            {unread ? (
                                <div className={classNames('absolute top-vw-2 md:top-2', rtl ? 'left-vw-2 md:left-2' : 'right-vw-2 md:right-2')}>
                                    <div className='w-vw-6 h-vw-6 md:w-6 md:h-6 rounded-full bg-red-500 text-white text-vw-xs md:text-xs flex items-center justify-center'>
                                        {unread}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </GetLang>
        </CardSection>
    );
};

export default MessagingChannelPreview;
