import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavTab from 'components/blocks/NavTab';
import NavTabItem from 'components/blocks/NavTabItem';
import GetLang from 'hoc/GetLang';

import { routePath } from 'constants/structRoutes';
import { useEffect } from 'react';
import { toggleTab } from 'store/actions/globalState';

const TabCom = ({ toggleTab, globalState: { tabs }, initial }) => {
    useEffect(() => {
        toggleTab('com', initial);
    }, []);

    return (
        <GetLang>
            {({ translations, rtl }) => (
                <NavTab tab={tabs?.com} rtl={rtl} tabs={['notifications', 'messages']} exception={true}>
                    <NavTabItem
                        id='notifications'
                        tab={tabs?.com}
                        page='com'
                        toggleTab={toggleTab}
                        from={routePath('app.messages')}
                        to={routePath('app.notifications')}
                    >
                        {translations?.data?.['com.tab.notifications'] ?? 'Notifications'}
                    </NavTabItem>
                    <NavTabItem
                        id='messages'
                        tab={tabs?.com}
                        page='com'
                        toggleTab={toggleTab}
                        from={routePath('app.notifications')}
                        to={routePath('app.messages')}
                    >
                        {translations?.data?.['com.tab.messages'] ?? 'Messages'}
                    </NavTabItem>
                </NavTab>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    rides: state.rides
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleTab
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TabCom);
