import classNames from 'classnames';

const LayoutPageBody = ({ children, marginTop = '', paddingBottom = '', minHeight = 'min-h-body-w-header md:min-h-body-w-header-desktop' }) => {
    return (
        <div
            className={classNames(
                'select-none no-scrollbar',

                'h-max',

                minHeight,
                paddingBottom,
                marginTop
            )}
        >
            {children}
        </div>
    );
};

export default LayoutPageBody;
