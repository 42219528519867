import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnIcon from 'components/buttons/BtnIcon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateTooltip } from 'store/actions/onboarding';
import { motion } from 'framer-motion';

const styles = {
    red: {
        box: 'bg-red-50 text-red-500',
        text: 'text-red-500'
    },
    fullred: {
        pill: 'bg-red-400 text-red-600 font-bold',
        icon: 'text-red-600',
        text: 'text-vw-sm font-semibold text-red-600 rounded-2xl bg-red-100'
    },
    green: {
        pill: 'bg-emerald-500 text-white font-bold',
        icon: 'text-emerald-600',
        text: 'text-vw-sm font-medium text-emerald-600 rounded-2xl bg-emerald-50'
    },
    default: {
        pill: 'bg-gray-500 text-white font-bold',
        icon: 'text-gray-600',
        text: 'text-vw-sm font-medium text-gray-600 rounded-2xl bg-gray-100'
    },
    black: {
        pill: 'bg-gray-500 text-white font-bold',
        icon: 'text-white',
        text: 'text-vw-sm font-medium text-white rounded-2xl bg-gray-800'
    },
    'light-green': {
        pill: 'bg-emerald-500 text-white font-bold',
        icon: 'text-emerald-600',
        text: 'text-vw-sm font-medium text-emerald-600 rounded-2xl bg-emerald-50'
    },
    yellow: {
        pill: 'bg-bb-yellow-400 text-yellow-600 font-bold',
        icon: 'text-yellow-600',
        text: 'text-vw-sm font-semibold text-yellow-600 rounded-2xl bg-yellow-100'
    },
    blue: {
        pill: 'bg-bb-blue-550 text-white font-bold',
        icon: 'text-bb-blue-550',
        text: 'text-vw-sm font-medium text-bb-blue-500 rounded-2xl bg-bb-blue-50'
    }
};

const MessageBox = ({
    fullButton,
    alwaysOn,
    noTitle,
    icon = 'Close',
    type = 'error',
    message,
    padding = 'px-vw-6',
    innerPadding = 'px-vw-6',
    margins,
    preset = 'yellow',
    children,
    updateTooltip,
    onboarding: { tooltips },
    id,
    label = 'e.tip',
    action,
    bodyAction,
    noAction,
    className
}) => {
    const handleClose = () => {
        updateTooltip(id, false);
    };

    const handleBodyAction = () => {
        if (bodyAction) bodyAction();
        else if (action) action();
    };

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className={classNames(padding, margins)}>
                    <div
                        // onClick={handleBodyAction ?? null}
                        className={classNames(
                            rtl ? 'items-end' : 'items-start',

                            'relative  flex flex-col  rounded-2xl ',
                            styles[preset].text
                        )}
                    >
                        {!alwaysOn && (
                            <div className={classNames(noTitle ? 'top-1/2 -translate-y-1/2' : 'top-vw-4', 'absolute', rtl ? ' left-vw-4' : ' right-vw-4')}>
                                {id ? (
                                    <BtnIcon
                                        icon={icon}
                                        handleClick={fullButton ? null : handleClose}
                                        size='w-vw-10 h-vw-10'
                                        iconSize='5.8vw'
                                        color={styles[preset].icon}
                                        noBg
                                    />
                                ) : null}
                            </div>
                        )}
                        {!noTitle && label && (
                            <div className={classNames('px-vw-4 mt-vw-4')}>
                                <div className={classNames('rounded-full px-4 py-2 w-max mb-vw-3 ', styles[preset].pill, rtl && 'text-right')}>
                                    {translations.data[label] ?? label ?? 'N/A'}
                                </div>
                            </div>
                        )}
                        <div onClick={handleBodyAction ?? null} className={classNames('')}>
                            <div
                                className={classNames(className, label ? 'pb-vw-5' : 'py-vw-5', 'w-full break-all-a text-vw-sm', rtl && 'text-right', {
                                    'pr-vw-6 pl-vw-16': noTitle && rtl && !alwaysOn,
                                    'pl-vw-6 pr-vw-16': noTitle && !rtl && !alwaysOn,
                                    'px-vw-6': !noTitle || alwaysOn
                                })}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    onboarding: state.onboarding
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateTooltip
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
