import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updatePublishTime, updatePublishDateRange, updateSelectedDay } from 'store/actions/publish';
import useMyNavigation from 'hooks/useMyNavigation';
import { getNavParams } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import BtnIcon from 'components/buttons/BtnIcon';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import TimeInput from 'components/forms/TimeInput';
import { useState } from 'react';
import { useEffect } from 'react';
import { validateDateTime } from 'helpers/helperFunctions';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import { daysOfWeek } from 'constants/defaultObjects';
const LayoutPublishTime = ({
    updatePublishTime,
    updatePublishDateRange,
    updateSelectedDay,

    publish: {
        publish: { date, time: savedTime, recurring, dateRange },
        selectedDay,
        isValid
    },
    source
}) => {
    const { handleNavigation, location } = useMyNavigation();

    const [time, setTime] = useState(recurring ? (selectedDay === 'general' ? dateRange?.time : dateRange?.[selectedDay]?.time ?? dateRange?.time) : savedTime);

    const handleUpdate = (clear) => {
        // console.log('time input', time);
        if (!recurring) {
            updatePublishTime(clear ? '' : time);
            handleNavigation('app.offerSeats', null);
        } else {
            if (selectedDay !== 'general') {
                updatePublishDateRange(selectedDay, { ...dateRange[selectedDay], time: clear ? '' : time });
            } else {
                // for (const day in daysOfWeek) {
                //     updatePublishDateRange(day, { ...dateRange[day], time });
                // }
                updatePublishDateRange('time', time);
            }
            handleNavigation('app.offerSeats', null);
        }
        // updateSelectedDay(null);

        // if (redirect) handleNavigation('app.offerSeats', null);
    };

    const handleClear = () => {
        handleUpdate(true);
        handleNavigation('app.offerSeats', null);
    };

    const handleCancel = () => {
        handleNavigation('app.offerSeats', null);
    };

    // const getTime = () => {
    //     if (recurring) {
    //         return selectedDay === 'general' ? dateRange?.time : dateRange?.[selectedDay]?.time;
    //     }
    //     return time;
    // };

    return (
        <GetLang>
            {({ translations, tripLang, lang, rtl, time24 }) => (
                <div className={classNames('h-full flex flex-col justify-between')}>
                    <div>
                        <TimeInput
                            value={time}
                            handleUpdate={setTime}
                            time24={time24}
                            recurring={recurring}
                            generalTime={dateRange?.time}
                            selectedDay={selectedDay}
                        />
                    </div>
                    <div className={classNames('flex flex-col items-center-a justify-center-a mt-vw-6')}>
                        <BtnPill
                            type='primary'
                            // handleClick={() => handleNavigation(recurring ? 'app.offerSeats.date' : 'app.offerSeats', null)}
                            handleClick={handleUpdate}
                            // handleClick={() => handleNavigation('app.offerSeats', null)}
                            justify={'justify-between'}
                            disabled={!validateDateTime(['2022-04-11'], time)}
                            rtl={rtl}
                        >
                            <span className='w-6' />
                            {translations?.data?.['e.page.publish.action.setTime']}
                            <Icons icon={'Check'} />
                        </BtnPill>
                        <BtnPill type='confirmed' handleClick={handleClear} justify={'justify-between'} disabled={false} rtl={rtl}>
                            <span className='w-6' />
                            {translations?.data?.['e.page.publish.action.clearTime']}
                            <Icons icon={'Close'} />
                        </BtnPill>
                        <BtnPill type='default' handleClick={handleCancel} justify={'justify-center'} disabled={false} rtl={rtl}>
                            {translations?.data?.['e.later']}
                        </BtnPill>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishTime,
            updatePublishDateRange,
            updateSelectedDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPublishTime);
