import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import IconRound from 'components/blocks/IconRound';
import Paragraph from 'components/blocks/Paragraph';
import BtnPill from 'components/buttons/BtnPill';
import Input from 'components/forms/Input';
import Icons from 'components/icons/index';
import { regexEmail, regexString20 } from 'helpers/regexPatterns';
import GetLang from 'hoc/GetLang';
import { useEffect, useState } from 'react';
import { verifyUser, clearLoginErrors } from 'store/actions/auth';
import { verifyEmail } from 'fetch/apiRequests';
import { useRef } from 'react';
import ListBox from 'components/forms/ListBox';
import { genderListSignup } from 'constants/defaultObjects';
import MessageBox from 'components/blocks/MessageBox';
import { setStaticPage } from 'store/actions/staticPages';
import { motion } from 'framer-motion';
import { animations } from 'constants/animations';

const LoginFlowNew = ({
    verifyUser,
    setStaticPage,

    auth: { otpReq, loading, error },
    info,
    setInfo,
    clearLoginErrors
}) => {
    // const [info, setInfo] = useState({
    //     firstName: {
    //         value: '',
    //         isValid: false
    //     },
    //     lastName: {
    //         value: '',
    //         isValid: false
    //     },
    //     email: {
    //         value: '',
    //         isValid: false
    //     }
    // });
    const handleInfo = (id, value, isValid) => {
        console.log(value, isValid);
        // let value = tmpValue.trim();
        // if (id === 'email') value = value.toLowerCase();

        let bodyValue = { value, isValid };

        if (id === 'gender' && value === '000') bodyValue = { value: null, isValid: false };
        setInfo({ ...info, [id]: bodyValue });
    };

    const [tab, setTab] = useState(1);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [verifying, setVerifying] = useState(false);

    const handleNewUser = (lang) => {
        verifyUser(otpReq?.phoneNumber, lang, true);
    };

    useEffect(() => {
        setIsFormValid(Object.keys(info).every((item) => info[item].isValid) && isEmailValid);
    }, [info, isEmailValid]);
    const timer = useRef();

    const delayedAction = () => {
        clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
            timer.current = null;

            setVerifying(true);
            const res = await verifyEmail(info.email.value.trim().toLowerCase());

            setVerifying(false);
            setIsEmailValid(res);
        }, 500);
    };

    useEffect(() => {
        console.log(info);
    }, [info]);

    useEffect(() => {
        clearTimeout(timer.current);
        setIsEmailValid(null);

        if (verifying) setVerifying(false);
        if (info.email.isValid) delayedAction();
    }, [info.email.value]);

    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <div className='relative h-full text-vw-base'>
                    {!loading && <IconRound icon='User' preset='yellow' />}
                    <div className={classNames('py-vw-12')}>
                        <HeadlineType1 label='e.loginFlow.info.title' />

                        <div className={classNames('px-vw-10 flex flex-col items-center')}>
                            <Paragraph>{translations?.data?.['e.loginFlow.info.subtitle']}</Paragraph>

                            <div className='flex flex-col gap-3 w-full mb-vw-6'>
                                <Input
                                    placeholder={translations?.data?.['e.loginFlow.info.first.placeholder']}
                                    id='firstName'
                                    focus
                                    obj={info.firstName}
                                    handleChange={handleInfo}
                                    regex={regexString20}
                                    tabId={1}
                                    tab={tab}
                                    handleTab={setTab}
                                />
                                <Input
                                    placeholder={translations?.data?.['e.loginFlow.info.last.placeholder']}
                                    id='lastName'
                                    obj={info.lastName}
                                    handleChange={handleInfo}
                                    regex={regexString20}
                                    tabId={2}
                                    tab={tab}
                                    handleTab={setTab}
                                />
                                <Input
                                    placeholder={translations?.data?.['e.loginFlow.info.email.placeholder']}
                                    id='email'
                                    obj={info.email}
                                    handleChange={handleInfo}
                                    regex={regexEmail}
                                    tabId={3}
                                    tab={tab}
                                    handleTab={setTab}
                                    owValidation={true}
                                    owValidationValue={isEmailValid}
                                    loading={verifying}
                                />
                                <ListBox
                                    top={true}
                                    id='gender'
                                    list={genderListSignup}
                                    selectedValue={genderListSignup.find((item) => item.id === info?.gender?.value)}
                                    handleChange={handleInfo}
                                    placeholder={translations?.data?.['e.genderType.select']}
                                />
                                <MessageBox
                                    label=''
                                    noTitle
                                    fullButton
                                    id='genderSignupTooltip'
                                    margins={classNames('mb-vw-4')}
                                    padding=''
                                    icon='Info'
                                    action={() => setStaticPage('gender')}
                                >
                                    {translations?.data?.['e.tooltip.important']}
                                </MessageBox>
                            </div>
                        </div>
                    </div>
                    <BtnPill
                        type='primary'
                        justify='justify-between'
                        shadow={isFormValid}
                        handleClick={() => handleNewUser(lang)}
                        absolute='bottom-vw-8 inset-x-vw-10 '
                        disabled={!isFormValid}
                        tracking={!rtl && 'tracking-wide'}
                        rtl={rtl}
                    >
                        <span className='w-vw-6' />
                        {translations?.data?.['e.loginFlow.info.button']}
                        <Icons icon={'ArrowRightLong1'} flip={rtl} />
                    </BtnPill>
                    {error?.length > 0 && (
                        <motion.div
                            key='loginNewError'
                            initial={'bottomFast'}
                            animate={'delayedIn'}
                            variants={animations.pageSlides}
                            className={classNames(
                                'h-full-a absolute inset-x-vw-10 bottom-0 rounded-t-2xl bg-red-50  text-bb-red-400 text-vw-sm font-medium text-center'
                            )}
                            onClick={clearLoginErrors}
                        >
                            <div className='relative px-vw-6 pt-vw-8 pb-vw-6'>
                                <IconRound icon='Close' preset='red' presetSize='small'/>

                                {translations?.data?.[error[0]] ?? error[0]}
                            </div>
                        </motion.div>
                    )}
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            clearLoginErrors,
            verifyUser,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlowNew);
