import { defaultSize, defaultStroke } from './defaults';
const IconSettings = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <path d='M20 7h-9'></path>
                <path d='M14 17H5'></path>
                <circle cx='17' cy='17' r='3'></circle>
                <circle cx='7' cy='7' r='3'></circle>
            </svg>
        </>
    );
};

export default IconSettings;
