/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    filters: {
        time: {},
        smoking: null,
        pets: null,
        rating: null
    },
    sorting: 'ASC'
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const updateResultsFilters = (draft, action) => {
    const { filters } = action;

    return {
        ...draft,
        filters
    };
};

const updateResultsSorting = (draft, action) => {
    const { sorting } = action;

    return {
        ...draft,
        sorting
    };
};

export default createReducer(initialState, {
    [types.RESULTS_FILTERS_UPDATE]: updateResultsFilters,
    [types.RESULTS_SORTING_UPDATE]: updateResultsSorting
});
