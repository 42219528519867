import { defaultSize, defaultStroke } from './defaults';
const IconLogin = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <path d='M15 3h4a2 2 0 012 2v14a2 2 0 01-2 2h-4'></path>
                <polyline points='10 17 15 12 10 7'></polyline>
                <line x1='15' y1='12' x2='3' y2='12'></line>
            </svg>
        </>
    );
};

export default IconLogin;
