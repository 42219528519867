import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { routePath } from 'constants/structRoutes';

const PageRides = ({ globalState: { tabs } }) => {
    if (tabs?.rides === 'booked')
        return <Navigate to={`${routePath('app.rides.booked')}`} />;
    if (tabs?.rides === 'offered')
        return <Navigate to={`${routePath('app.rides.offered')}`} />;

    return (
        <>
            <Navigate to={`${routePath('app.rides.booked')}`} />
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(PageRides);
