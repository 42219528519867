export default function Spinner({
    size = '5vw',
    strokeWidth = '40',
    strokeColor = null,
    bgStrokeColor = 'text-gray-400',
    bgStrokeOpacity = 'opacity-25'
}) {
    return (
        <svg
            className={`animate-spin`}
            width={size}
            height={size}
            viewBox='0 0 500 500'
        >
            <defs>
                <linearGradient
                    id='linearGrad'
                    gradientTransform='rotate(90)'
                    // x1='0%'
                    // y1='0%'
                    // x2='50%'
                    // y2='0%'
                    // x3='100%'
                    // y3='0%'
                >
                    <stop offset='0%' stopColor='#0000cc' />
                    <stop offset='50%' stopColor='#dc2626' />

                    <stop offset='80%' stopColor='#fde047' />
                </linearGradient>
            </defs>
            <circle
                className={`${bgStrokeOpacity} ${bgStrokeColor}`}
                cx='250'
                cy='250'
                r='200'
                stroke='currentColor'
                strokeWidth={strokeWidth}
                fill='none'
            />
            <path
                className={`${strokeColor ? strokeColor : ''}`}
                d='M 250,50 A 200 200 0 1 1 250 450 A 200 200 0 1 1 250 50'
                stroke={strokeColor || 'url(#linearGrad)'}
                strokeLinecap='round'
                strokeWidth={strokeWidth}
                fill='none'
                strokeDasharray='750,1500'
            />
        </svg>
    );
}
