import React, { useState, useEffect, useRef } from 'react';
import { getDateString } from 'helpers/dates';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updatePublishTime, updatePublishDateRange, updateSelectedDay } from 'store/actions/publish';
import { DateTime } from 'luxon';

import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

import Icons from 'components/icons/index';
import IconRoundStatus from 'components/blocks/IconRoundStatus';
import SwitchBtn from 'components/blocks/SwitchBtn';
import { daysOfWeek, defaultWeekObj } from 'constants/defaultObjects';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getPresetByStatus, validateDateTime, validateEmptyWeek } from 'helpers/helperFunctions';
import BtnPill from 'components/buttons/BtnPill';
import InputShell from 'components/forms/InputShell';
import PseudoInput from 'components/forms/PseudoInput';
import useMyNavigation from 'hooks/useMyNavigation';

const CalendarMultiple = ({
    updatePublishDateRange,
    publish: {
        publish: { dateRange },
        isValid
    }
}) => {
    const { handleNavigation, location } = useMyNavigation();

    const [days, setDays] = useState(dateRange);

    const handleLocalUpdate = (dayIndex, body) => {
        setDays((prev) => ({ ...prev, [dayIndex]: body }));
    };

    const handleUpdate = (clear) => {
        let updatedDays = { ...days };

        if (clear) {
            updatedDays = defaultWeekObj();
            console.log(updatedDays);

            for (let i = 0; i < 7; i++) {
                updatedDays[i.toString()].enabled = false;
                updatedDays[i.toString()].time = days[i].time;
                updatedDays[i.toString()].seats = days[i].seats;
            }
            updatedDays.time = days.time;
            updatedDays.seats = days.seats;
        }

        updatePublishDateRange(null, updatedDays);
        handleNavigation('app.offerSeats', null);
    };

    const handleClear = () => {
        handleUpdate(true);
        handleNavigation('app.offerSeats', null);
    };

    const handleCancel = () => {
        handleNavigation('app.offerSeats', null);
    };

    return (
        <GetLang>
            {({ lang, rtl, translations, time24 }) => (
                <div>
                    <div className={classNames('flex flex-col gap-2')}>
                        {daysOfWeek.map((day, index) => (
                            <SwitchSectionCalendar
                                key={day}
                                icon='Calendar1'
                                iconColorPreset={'light-violet'}
                                colorOff='light-gray'
                                border='border-t-a'
                                label='e.view.recurring'
                                visible={true}
                                dayIndex={index}
                                day={day}
                                dayBody={days?.[index]}
                                updateDay={handleLocalUpdate}
                                handleClear={handleClear}
                            />
                        ))}
                    </div>

                    <div className={classNames('flex flex-col items-center-a justify-center-a mt-vw-6')}>
                        <BtnPill type='primary' handleClick={handleUpdate} justify={'justify-between'} disabled={!validateEmptyWeek(days)} rtl={rtl}>
                            <span className='w-6' />
                            {translations?.data?.['e.page.publish.action.setDays']}
                            <Icons icon={'Check'} />
                        </BtnPill>
                        <BtnPill type='confirmed' handleClick={handleClear} justify={'justify-between'} disabled={false} rtl={rtl}>
                            <span className='w-6' />
                            {translations?.data?.['e.page.publish.action.clearDays']}
                            <Icons icon={'Close'} />
                        </BtnPill>
                        <BtnPill type='default' handleClick={handleCancel} justify={'justify-center'} disabled={false} rtl={rtl}>
                            {translations?.data?.['e.later']}
                        </BtnPill>
                    </div>

                    {/* <BtnPill
                        type={'primary'}
                        justify={'justify-between'}
                        rtl={rtl}
                        params={['general', 'seats']}
                       
                        handleClick={() => handleNavigation('app.offerSeats', null)}
                        margins='mt-vw-6 '
                      
                    >
                       
                        <>
                            <span className='w-6' />
                            {translations?.data?.['e.page.publish.action.confirm']}
                            <Icons icon={'Check'} />
                        </>
                    </BtnPill> */}
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishTime,
            updatePublishDateRange,
            updateSelectedDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMultiple);

const SwitchSectionCalendar = ({ visible, dayIndex, dayBody, day, updateDay }) => {
    return (
        <GetLang>
            {({ rtl, lang, translations }) =>
                visible ? (
                    <>
                        <button className='focus:outline-none' onClick={() => updateDay(dayIndex, { ...dayBody, enabled: !dayBody.enabled })}>
                            <Pill
                                width={'w-full'}
                                sizePreset='small'
                                colorPreset={dayBody?.enabled ? 'light-blue' : 'faded'}
                                margins=''
                                justify={'justify-between'}
                                icon={dayBody.enabled ? 'Check' : null}
                                switchIcons
                            >
                                <FormattedDateTime dateString={DateTime.fromISO(day).setLocale(translations.formatting.locale).toFormat('cccc')} date />
                                {/* <Icons icon='Check' /> */}
                            </Pill>
                        </button>
                    </>
                ) : null
            }
        </GetLang>
    );
};
