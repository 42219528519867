import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePublishDate, updatePublishDateRange, updateSelectedDay } from 'store/actions/publish';
import GetLang from 'hoc/GetLang';

import 'react-nice-dates/build/style.css';
import Calendar from 'components/calendar/Calendar';
import useMyNavigation from 'hooks/useMyNavigation';
import Month from 'components/calendar/Month';
import Year from 'components/calendar/Year';
import CalendarMultiple from 'components/calendar/CalendarMultiple';

const LayoutPublishDate = ({
    updatePublishDate,
    updatePublishDateRange,
    updateSelectedDay,
    publish: {
        publish: { date, recurring, dateRange, time, seatsOffered }
    }
}) => {
    const { handleNavigation, location } = useMyNavigation();
    const handleDate = (date) => {
        updatePublishDate(date);
        handleNavigation('app.offerSeats', null);
    };

    const updateDayAndOpenModal = (day, key, redirect = true) => {
        updateSelectedDay(day);
        if (redirect) handleNavigation(`app.offerSeats.${key}`, null); // time or seats
    };

    const handleDateRange = (key, body) => {
        updatePublishDateRange(key, body);
        // handleNavigation('app.offerSeats', null);
    };

    return (
        <GetLang>
            {({ tripLang, lang, rtl }) => (
                <div className={recurring ? 'pb-20' : 'pb-60'}>
                    {/* <Month /> */}
                    {/* <Year /> */}

                    {recurring ? (
                        <CalendarMultiple dateRange={dateRange} handleDateRange={handleDateRange} updateDayAndOpenModal={updateDayAndOpenModal} />
                    ) : (
                        <Calendar selectedDates={[date]} handleDates={handleDate} hideHeader />
                    )}
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishDate,
            updatePublishDateRange,
            updateSelectedDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPublishDate);
