import { LayoutPage, LayoutPageBody } from 'components/layouts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import useMyNavigation from 'hooks/useMyNavigation';

import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';

import NoResults from 'components/blocks/NoResults';
import BtnLoadState from 'components/buttons/BtnLoadState';

import { useEffect, useRef, useState } from 'react';
import { setScroll } from 'store/actions/globalState';
import BtnScroll from 'components/buttons/BtnScroll';

import { PageLoading } from 'pages/index';
import TabSingle from 'components/tabs/TabSingle';
import CardUserAdmin from 'components/cards/CardUserAdmin';
import SwitchSection from 'components/blocks/SwitchSection';

import Input from 'components/forms/Input';
import Icons from 'components/icons/index';

const pageData = {
    page: 'app.admin.users',
    pageRedirect: 'app.searchCarSharing',
    apiUrl: '/profiles/find-admin',

    crudType: 'POST',
    backBtn: true,
    zIndex: 38,
    checkIdParam: false,
    ridePath: 'app.admin.user',
    resultsPerPage: 100
};

const { resultsPerPage } = pageData;

const PageAdminUsers = ({
    onboarding: { tooltips },
    globalState: { scroll },
    setScroll,
    auth: {
        user: { admin }
    }
}) => {
    const ref = useRef(null);

    const [waiting, setWaiting] = useState(false);
    const [mobile, setMobile] = useState('');
    const timer = useRef();

    const delayedAction = () => {
        clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
            timer.current = null;

            setWaiting(true);

            setFilter((prev) => ({ ...prev, mobile }));

            setWaiting(false);
        }, 500);
    };

    const { handleNavigation, location } = useMyNavigation();

    const [filter, setFilter] = useState({ female: null, verifiedProfile: null, verifiedId: null, verifiedCarRegistration: null, banned: null, mobile: '' });

    const handleFilter = (key) => {
        setFilter((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const handleMobile = (id, val) => {
        setMobile(val);
    };
    useEffect(() => {
        if (!admin) handleNavigation('app.searchCarSharing', null);
    }, []);

    useEffect(() => {
        // console.log('loaded verif');
        if (scroll?.page === 'app.admin.users') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    useEffect(() => {
        // console.log('mobile', mobile);
        clearTimeout(timer.current);

        if (waiting) setWaiting(false);
        delayedAction();
    }, [mobile]);

    useEffect(() => {
        // console.log('filter', filter);
    }, [filter]);

    return (
        <LayoutPage pageData={{ ...pageData, body: filter }} infinite={true}>
            {({
                rtl,
                lang,
                location,
                animStates,
                translations,

                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                size,
                setSize,
                handleNavigation,
                reducedMotion,
                getReducedMotion,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.count,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                const isEmpty = newData?.count === 0;
                const isReachingEnd = newData?.count === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('data is', data);
                return (
                    <>
                        <TabSingle label={'menu.admin.users'} suffix={`(${newData?.count})`} />
                        <div ref={ref} className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <BtnScroll containerRef={ref} page={'app.admin.users'} />
                            <LayoutPageBody minHeight={''}>
                                <div className=''>
                                    <>
                                        <div className=''>
                                            <OfflineMode />
                                        </div>

                                        <div className={classNames('px-vw-6 mt-vw-4')}>
                                            <Input
                                                root
                                                padding={classNames(rtl ? 'px-vw-16' : 'px-vw-16')}
                                                clear={() => setMobile('')}
                                                placeholder={'phone or name'}
                                                id='phone'
                                                focus
                                                obj={mobile}
                                                handleChange={handleMobile}
                                                loading={isValidating}
                                                // regex={regexString2}
                                                // tabId={1}
                                                // tab={tab}
                                                // handleTab={setTab}
                                            >
                                                <div
                                                    className={classNames(
                                                        rtl ? 'right-vw-6 flex-row-reverse' : 'left-vw-6',
                                                        'absolute  top-1/2 -translate-y-1/2 font-semibold text-gray-400 text-vw-base flex items-center gap-1'
                                                    )}
                                                >
                                                    <Icons icon='Find' size={'5vw'} />
                                                    {/* <Icons icon='Add' size={'5vw'} /> */}
                                                </div>
                                            </Input>
                                        </div>

                                        <SwitchSection
                                            icon={'Female1'}
                                            iconColorPreset={filter?.female ? 'light-pink' : 'default'}
                                            color='on'
                                            label='menu.admin.verify.filter.women'
                                            visible={true}
                                            val={filter?.female}
                                            setVal={() => handleFilter('female')}
                                        />
                                        <SwitchSection
                                            icon={'Id2'}
                                            iconColorPreset={filter?.verifiedId ? 'light-green' : 'default'}
                                            color='on'
                                            label='menu.admin.verify.filter.verifiedId'
                                            visible={true}
                                            val={filter?.verifiedId}
                                            setVal={() => handleFilter('verifiedId')}
                                        />
                                        <SwitchSection
                                            icon={'Car1'}
                                            iconColorPreset={filter?.verifiedCarRegistration ? 'light-green' : 'default'}
                                            color='on'
                                            label='menu.admin.verify.filter.verifiedCar'
                                            visible={true}
                                            val={filter?.verifiedCarRegistration}
                                            setVal={() => handleFilter('verifiedCarRegistration')}
                                        />
                                        {/* <SwitchSection
                                                
                                                icon={'UserBlock'}
                                                lock={!enabled}
                                                iconColorPreset={filter?.blockUser ? 'light-red' : 'default'}
                                                color='on'
                                                label='menu.admin.verify.filter.blockUser'
                                                visible={true}
                                                val={filter?.blockUser}
                                                setVal={() => handleFilter('blockUser')}
                                            /> */}

                                        <div>
                                            <ul className={`flex flex-col items-center`}>
                                                {newData?.data != null ? (
                                                    newData?.data?.length > 0 ? (
                                                        newData?.data?.map?.((item) => {
                                                            return <CardUserAdmin data={item} key={item._id} rtl={rtl} lang={lang} />;
                                                        })
                                                    ) : (
                                                        <div className='w-full px-vw-6 py-vw-6'>
                                                            <NoResults
                                                                title='e.nothingFound.verification.title'
                                                                description='e.nothingFound.verification.description'
                                                            />
                                                        </div>
                                                    )
                                                ) : (
                                                    <PageLoading bg='bg-white' overlay={true} />
                                                )}
                                            </ul>
                                            {!isReachingEnd && online && (
                                                <div className={classNames('px-vw-6 py-vw-4')}>
                                                    <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    auth: state.auth,
    onboarding: state.onboarding
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PageAdminUsers);
