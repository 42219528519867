/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { userLocale } from 'helpers/utils';
import { produce } from 'immer';

import * as types from 'store/actions/types';
import { saveNamedState } from 'store/localStorage';

const initialState = {
    lang: userLocale().lang,
    // lang: 'ar',
    navIsOpen: false,
    authModalIsOpen: false,
    authComponent: 'login',
    reducedMotion: 5,
    time24: true,
    scroll: null,
    isLoading: true,
    isTransition: false,
    tabs: {
        rides: 'booked',
        com: 'notifications'
    },
    siteData: {
        loading: true,
        tags: [],
        placesLists: [],
        categories: [],
        settings: {
            whatsIncluded: {},
            xchangeRates: {}
        }
    },
    googleMaps: {
        loading: { isLoading: true, msg: 'GOOGLE_MAP_NOT_INITIALISED' },
        error: { isError: false, msg: 'GOOGLE_MAP_INITIALISE_FAIL' }
    },
    newNotifications: 0
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const loadSettings = (draft, action) => {
    const { lang, reducedMotion, time24 } = action;

    return {
        ...draft,
        lang,
        reducedMotion,
        time24
    };
};

const toggleLang = (draft, action) => {
    const { lang } = action;

    return {
        ...draft,
        lang
    };
};

const toggleTime = (draft, action) => {
    const { time24 } = action;

    return {
        ...draft,
        time24
    };
};

const toggleReducedMotion = (draft, action) => {
    const { reducedMotion } = action;

    return {
        ...draft,
        reducedMotion
    };
};

const toggleLoading = (draft, action) => {
    const { isLoading } = action;

    return {
        ...draft,
        isLoading
    };
};

const toggleTransition = (draft, action) => {
    const { isTransition } = action;

    return {
        ...draft,
        isTransition
    };
};

const toggleNav = (draft, action) => {
    const { navIsOpen } = action;

    return {
        ...draft,
        navIsOpen
    };
};

const googleMapReady = (draft, action) => {
    draft.googleMaps.loading.isLoading = false;

    return draft;
};

const googleMapLoadFail = (draft, action) => {
    draft.googleMaps.error.isError = true;
    draft.googleMaps.loading.isLoading = false;

    return draft;
};

const setScroll = (draft, action) => {
    const { scroll } = action;
    return {
        ...draft,
        scroll
    };
};

const setStartUpData = (draft, action) => {
    const {
        payload: {
            data: {
                data: {
                    tags,
                    categories,
                    setting: { whatsIncluded, xchangeRates },
                    placesLists
                }
            }
        }
    } = action;

    draft.siteData.loading = false;
    draft.siteData.placesLists = placesLists;
    draft.siteData.categories = categories;
    draft.siteData.tags = tags.filter((tag) => tag.type === 'general');
    draft.siteData.settings.whatsIncluded = whatsIncluded;
    draft.siteData.settings.xchangeRates = xchangeRates;
    return draft;
};

const toggleTab = (draft, action) => {
    const { page, tab } = action;
    draft.tabs[page] = tab;
    return draft;
};

const setNewNotifications = (draft, action) => {
    const { newNotifications } = action;
    return {
        ...draft,
        newNotifications
    };
};

export default createReducer(initialState, {
    [types.LOAD_APP_SETTINGS]: loadSettings,
    [types.TOGGLE_LANG]: toggleLang,
    [types.TOGGLE_TIME]: toggleTime,
    [types.TOGGLE_REDUCEDMOTION]: toggleReducedMotion,
    [types.TOGGLE_LOADING]: toggleLoading,
    [types.TOGGLE_TRANSITION]: toggleTransition,
    [types.TOGGLE_NAV]: toggleNav,

    [`${types.GET_STARTUP_DATA}_FULFILLED`]: setStartUpData,
    [types.SET_SCROLL]: setScroll,

    [types.GOOGLE_MAP_INITIALISED]: googleMapReady,
    [types.GOOGLE_MAP_INITIALISED_FAILED]: googleMapLoadFail,

    [types.TOGGLE_TAB]: toggleTab,
    [types.NEW_NOTIFICATIONS_SET]: setNewNotifications
});
