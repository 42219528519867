import { connect } from 'react-redux';

import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { bindActionCreators } from 'redux';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';
import { PageLoading } from 'pages/index';
import IconRound from 'components/blocks/IconRound';
import { setStaticPage } from 'store/actions/staticPages';
import { loginSetStep } from 'store/actions/auth';
import { useEffect } from 'react';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import { userLocale } from 'helpers/utils';
import { analytics } from 'helpers/segment';
import LottieBlock from 'components/blocks/LottieBlock';

const PageStaticTemplate = ({
    analyticsPage = 'Untitled',
    category = 'Static',
    loginSetStep,
    setStaticPage,
    page,
    apiUrl,
    icon,
    overlay,
    iconColor,
    auth: { isAuthenticated, setupStep },
    keepLoginStep
}) => {
    const pageData = {
        page,
        pageRedirect: 'app.searchCarSharing',
        apiUrl,
        backBtn: true,
        zIndex: 41,
        btnIcon: 'Close',
        btnFlip: true,
        checkIdParam: false,
        crudType: 'GET',
        singleResult: true,
        overlay,
        iconColor
    };

    const closeModal = () => {
        setStaticPage(null);
    };

    const validateSetup = () => {
        if (setupStep !== 'language' && !keepLoginStep) {
            loginSetStep('language');
        }
    };

    useEffect(() => {
        setTimeout(validateSetup, 600);
    }, []);

    useEffect(() => {
        analytics.page(analyticsPage, category);
    }, []);

    const getDataLang = (data, lang) => {
        switch (lang) {
            case 'en':
                return {
                    title: data?.enTitle,
                    desc: data?.enDescription
                };
            case 'fr':
                return {
                    title: data?.frTitle ?? data?.enTitle,
                    desc: data?.frDescription ?? data?.enDescription
                };
            case 'ar':
                return {
                    title: data?.arTitle ?? data?.enTitle,
                    desc: data?.arDescription ?? data?.enDescription
                };
            default:
                return;
        }
    };

    return (
        <LayoutPage pageData={pageData}>
            {({
                rtl: tmpRtl,
                lang: tmpLang,
                location,
                animStates,
                params,
                isValidating,
                isValidParams,
                translations,
                data,
                mutateData,
                handleNavigation,
                online
            }) => {
                const langData = getDataLang(data, tmpLang);
                // console.log('terms', data);
                const localeObj = userLocale();
                const lang = tmpLang ?? localeObj.lang;
                const rtl = tmpRtl ?? localeObj.rtl;

                return (
                    <>
                        {overlay && (
                            <LayoutPageHeader
                                handleButton={closeModal}
                                pageData={pageData}
                                // pageParams={params}
                            ></LayoutPageHeader>
                        )}

                        <div className={classNames(overlay ? 'h-screen-100' : 'h-body', 'overflow-y-auto text-vw-base mt-vw-6a no-scrollbar')}>
                            <LayoutPageBody minHeight={classNames('min-h-body-w-header-footer-2')}>
                                <div className=''>
                                    {data ? (
                                        <div
                                            className={`list-none w-full  bg-bb-blue-700  bordera relative ring-8a transition-all duration-300 `}
                                            style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                        >
                                            <div className={classNames('relative w-full h-screen-1/4')}>
                                                <div className='absolute inset-x-0 top-0 h-screen-1/3 overflow-hidden'>
                                                    <LottieBlock speed={0.35} rtl={rtl} />
                                                </div>
                                                {/* <div className='absolute w-full flex justify-center px-vw-6 pt-vh-6 pb-vh-6 text-vw-2xl text-white font-bold '>
                                                    {langData?.title}
                                                </div> */}
                                                <div className='absolute w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 flex justify-center text-center px-vw-16 pt-vh-6 pb-vh-6 text-vw-2xl text-white font-bold '>
                                                    {langData?.title}
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    overlay && 'min-h-vh-84 h-full flex flex-col justify-between',
                                                    'px-vw-6 pt-vw-6 pb-vw-6  rounded-t-3xl bg-white relative w-full'
                                                )}
                                            >
                                                {icon && <IconRound icon={icon} preset='yellow' />}

                                                <div>
                                                    <OfflineMode margins=' my-6' />
                                                    <div
                                                        className={'static'}
                                                        dangerouslySetInnerHTML={{
                                                            __html: langData?.desc
                                                        }}
                                                    />
                                                </div>
                                                {overlay && (
                                                    <BtnPill
                                                        type='primary'
                                                        justify={'justify-center'}
                                                        rtl={rtl}
                                                        params={[false]}
                                                        handleClick={closeModal}
                                                        margins='mt-vw-6'
                                                    >
                                                        <>
                                                            {/* <span className='w-vw-6' /> */}
                                                            {translations?.data?.['e.static.btn.close']}
                                                            {/* <Icons icon={'Close'} size={'5.8vw'} /> */}
                                                        </>
                                                    </BtnPill>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <PageLoading bg='bg-white' overlay={overlay} />
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage,
            loginSetStep
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageStaticTemplate);
