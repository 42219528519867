import { routePath } from 'constants/structRoutes';
import { buildQueryString } from 'helpers/helperFunctions';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useMyNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleNavigation = (toPage = 'app', pageParams) => {
        let navLink = routePath(toPage);
        let navTo = navLink;
        let navFrom = location.pathname;
        let navSearch = location.search;

        const queryString = buildQueryString(pageParams);

        const getPrevState = () => {
            if (navFrom === '/c/user') {
                if (location?.state?.prevState) return location.state.prevState;
                else
                    return {
                        from: location?.state?.from,
                        to: location?.state?.to,
                        toSearch: location?.state?.toSearch,
                        fromSearch: location?.state?.fromSearch
                    };
            } else return null;
        };

        navigate(`${navLink}${queryString}`, {
            state: {
                from: navFrom,
                to: navTo,
                toSearch: queryString,
                fromSearch: navSearch,
                prevState: getPrevState()
            }
        });
    };

    return { handleNavigation, location };
};

export default useMyNavigation;
