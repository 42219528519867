/* eslint-disable import/no-anonymous-default-export */
import {
    CHAT_LOADING,
    CHAT_LOAD_SUCCESS,
    CHAT_LOAD_FAIL,
    CHAT_CLEAR_ERRORS,
    CHAT_LOGOUT,
    CHAT_SET_HISTORY,
    CHAT_SET_CHANNEL_ACTIVE
} from 'store/actions/types';

const initialState = {
    chatToken: null,
    user: null,
    chatLoading: false,
    channelActive: false,
    channelHistory: null,
    error: null
};

export default function (state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case CHAT_SET_CHANNEL_ACTIVE:
            return {
                ...state,
                channelActive: payload
            };
        case CHAT_SET_HISTORY:
            return {
                ...state,
                channelHistory: payload
            };

        case CHAT_LOADING:
            return {
                ...state,
                loading: true
            };

        case CHAT_LOAD_SUCCESS:
            const { user, chatToken } = payload;
            // localStorage.setItem('chatToken', chatToken);
            return {
                ...state,
                chatToken,
                user,
                chatLoading: false
            };

        case CHAT_LOAD_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case CHAT_CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };

        case CHAT_LOGOUT:
            localStorage.removeItem('chat');
            return {
                ...state,

                chatToken: null,
                user: null,
                error: null
            };

        default:
            return state;
    }
}
