import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translations } from 'constants/translations';
import { LOGOUT } from 'store/actions/types';
import { genericAction } from 'store/actions/generic';
import { useEffect } from 'react';

const PageError = ({
    genericAction,
    globalState: { lang },
    code = 404,
    error,
    isValidating
}) => {
    const rtl = translations?.[lang]?.rtl;
    // const tripLang = lang !== 'ar' ? 'en' : 'ar';
    console.log('error', error);

    const handleLogout = () => {
        genericAction({ type: LOGOUT });
    };

    useEffect(() => {
        if (!isValidating && error?.data?.data?.blocked) handleLogout();
    }, []);

    return (
        <div
            className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
        >
            {!isValidating && (
                <div className='flex flex-col items-center w-vw-100'>
                    <div>{translations?.[lang]?.data?.[code]}</div>
                    <div>{code}</div>
                    {error?.data?.data?.errorId && (
                        <div className='text-center w-2/3'>
                            {
                                translations?.[lang]?.data?.[
                                    error.data.data.errorId
                                ]
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            genericAction
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageError);
