import { defaultSize, defaultStroke } from './defaults';
const IconArrowUpDown2 = ({ size, className }) => {
    return (
        <>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size || defaultSize} height={size || defaultSize}>
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path
                            id='Path_351'
                            data-name='Path 351'
                            d='M12,10.828l-4.95,4.95L5.636,14.364,12,8l6.364,6.364L16.95,15.778Z'
                            transform='translate(0 -6)'
                        />
                        <path
                            id='Path_352'
                            data-name='Path 352'
                            d='M12,10.828l-4.95,4.95L5.636,14.364,12,8l6.364,6.364L16.95,15.778Z'
                            transform='translate(24 30.778) rotate(180)'
                        />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconArrowUpDown2;
