import { motion } from 'framer-motion';
import ItinerarySubSkeleton from 'components/skeletons/ItinerarySubSkeleton';
import Icons from 'components/icons/index';
import classNames from 'classnames';

const variants = {
    in: {
        y: 0,
        opacity: 1,
        transition: {
            y: { duration: 0.3, ease: [0, 0, 0, 1] }
        }
    },
    out: {
        y: '15vw',
        opacity: 0,
        transition: {
            y: { duration: 0.3, ease: [0, 0, 0, 1] }
        }
    }
};

const NotificationSkeleton = ({ type = 'search', rtl, key = 'skType' }) => {
    return (
        <motion.li
            key={key}
            className={classNames(
                'w-full text-vw-lg  animate-pulse list-none ',

                {
                    'bg-gray-50 border-t-8 border-gray-200': type !== 'view' && type !== 'booking'
                }
            )}
            variants={variants}
        >
            <div className={`px-vw-6 ${type !== 'view' ? 'mt-vw-6' : ''} w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                <div
                    className={`rounded-full  bg-gray-150 px-vw-6 ${type === 'view' || type === 'booking' ? 'h-vw-10 w-vw-32' : 'h-vw-10 w-vw-32'} flex ${
                        rtl ? 'flex-row-reverse' : 'flex-row'
                    } items-center gap-3 font-bold text-vw-sm`}
                ></div>
            </div>

            {type !== 'offered' && (
                <div className={`px-6 py-4 flex ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center gap-8`}>
                    <div className='w-12 h-12 rounded-full bg-gray-150'></div>
                    {type === 'view' && (
                        <div style={{ zIndex: 1 }}>
                            <div className={`mb-2 font-bold bg-gray-150 rounded-full  w-16 h-4  flex `}></div>

                            <div className={`text-gray-150 font-bold flex items-center gap-2 ${rtl ? 'flex-row-reverse' : 'flex-row'}`}>
                                <Icons icon={'StarFilled'} size={18} />
                                <div className={`font-bold bg-gray-150 rounded-full w-16 h-3`}></div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </motion.li>
    );
};

export default NotificationSkeleton;
