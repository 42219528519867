import { useRef } from 'react';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

export function useStickySWR(getKey, fetcher, options, sticky, infinite) {
    const val = useRef();
    // const sticky = args[3];

    const { data, isValidating, error, size, setSize, mutate, ...rest } =
        useSWRInfinite(getKey, fetcher, options);

    if (data !== undefined) {
        if (infinite) val.current = data;
        else val.current = data?.[0];
    }
    // console.log('sticky is', sticky);

    const newData = (data) => {
        if (infinite) return sticky ? val.current : data;
        else return sticky ? val.current : data?.[0];
    };

    return {
        ...rest,
        isValidating,
        error,
        data: newData(data),
        size,
        setSize,
        mutate
    };
}
