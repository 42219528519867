import Lottie from 'lottie-react';
import animSearch from 'components/animations/traffic_01.json';
import animPublish from 'components/animations/traffic_02.json';
import desktopInstall from 'components/animations/desktop_anim_install_2.json';
import { useRef, useEffect } from 'react';

const presets = {
    search: animSearch,
    publish: animPublish,
    'desktop-install': desktopInstall
};

const LottieBlock = ({ data, rtl, speed = 1, loop = true, delay = 100, preset = 'search' }) => {
    const lottieRef = useRef();

    useEffect(() => {
        setTimeout(() => lottieRef?.current?.setSpeed(speed), delay);
    }, [lottieRef?.current]);

    return <Lottie animationData={data ?? presets[preset]} loop={loop} lottieRef={lottieRef} style={{ transform: rtl ? 'scaleX(-1)' : 'scaleX(1)' }} />;
};

export default LottieBlock;
