import PageStaticTemplate from './PageStaticTemplate';

const PageStaticPrivacy = ({ overlay }) => {
    return (
        <PageStaticTemplate
            overlay={overlay}
            page='static.help.privacy'
            apiUrl='/static-pages/62fbcb93e2654aac50328ded'
            icon='EyeOff'
            iconColor='text-gray-500'
            keepLoginStep
            analyticsPage='Privacy Policy'
        />
    );
};

export default PageStaticPrivacy;
