import classNames from 'classnames';
import { useState, useEffect } from 'react';

const NavTab = ({ rtl, children, tab, tabs = ['booked', 'offered'], exception }) => {
    const [zDepth, setZDepth] = useState('');

    useEffect(() => {
        if (exception) {
            setTimeout(() => setZDepth('z-100'), 400);
        }

        return () => {
            if (exception) {
                setTimeout(() => setZDepth(''), 400);
            }
        };
    }, []);

    return (
        <div className={classNames('w-full min-h-vw-16 md:min-h-20 bg-gray-50 relative select-none overflow-hidden mb-0.5s', zDepth)}>
            <div className={` flex flex-nowrap ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center justify-between`}>{children}</div>
            <div className={`absolute bottom-0 inset-x-0 h-0.5 bg-gray-100`}></div>
            <div
                className={classNames('transform transition duration-300 ease-in-out absolute bottom-0 w-1/2 h-0.5 bg-bb-blue-550 rounded-full', {
                    'translate-x-full': tab === tabs[1] && !rtl,
                    '-translate-x-full': tab === tabs[1] && rtl,

                    'left-0': !rtl,
                    'right-0': rtl
                })}
            ></div>
        </div>
    );
};

export default NavTab;
