import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GetLang from 'hoc/GetLang';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence, useCycle } from 'framer-motion';
import { animations, getAnimStates, getReducedMotion } from 'constants/animations';
import {
    PageStaticAbout,
    PageStaticSafety,
    PageStaticFAQ,
    PageStaticSupport,
    PageStaticTerms,
    PageStaticPrivacy,
    PageStaticPricing,
    PageStaticWeNeedYou
} from 'pages/index';
import { setStaticPage } from 'store/actions/staticPages';
import PageVerifyId from 'pages/app/profile/PageVerifyId';
import PageVerifyPlate from 'pages/app/profile/PageVerifyPlate';
import PageImageZoom from 'pages/app/admin/PageImageZoom';
import PageProfileAvatar from 'pages/app/profile/PageProfileAvatar';
import PageProfileAvatarPublic from 'pages/app/profile/PageProfileAvatarPublic';
import PageStaticGender from './PageStaticGender';
import PageStaticLadiesOnly from './PageStaticLadiesOnly';
import PageStaticNews from './PageStaticNews';
import PageStaticDemo1 from './PageStaticDemo1';
import PageTripFilters from 'pages/app/search/PageTripFilters';

const PageStaticOverlay = ({ setStaticPage, globalState: { reducedMotion }, staticPages: { page } }) => {
    const animStates = {
        default: getAnimStates('app.settings').animPages
    };

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <AnimatePresence>
                    {page === 'demo1' && (
                        <motion.div
                            key='demo1'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticDemo1 close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'about' && (
                        <motion.div
                            key='about'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticAbout close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'safety' && (
                        <motion.div
                            key='safety'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticSafety close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'faq' && (
                        <motion.div
                            key='faq'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticFAQ close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'support' && (
                        <motion.div
                            key='support'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticSupport close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'terms' && (
                        <motion.div
                            key='terms'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticTerms close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}

                    {page === 'privacy' && (
                        <motion.div
                            key='privacy'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticPrivacy close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'pricing' && (
                        <motion.div
                            key='pricing'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticPricing close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'gender' && (
                        <motion.div
                            key='gender'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticGender close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'ladiesOnly' && (
                        <motion.div
                            key='ladiesOnly'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticLadiesOnly close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'weneedyou' && (
                        <motion.div
                            key='weneedyou'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticWeNeedYou close={setStaticPage} overlay={true} />
                        </motion.div>
                    )}
                    {page === 'verifyId' && (
                        <motion.div
                            key='verifyId'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageVerifyId close={setStaticPage} />
                        </motion.div>
                    )}
                    {page === 'verifyPlate' && (
                        <motion.div
                            key='verifyPlate'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageVerifyPlate close={setStaticPage} />
                        </motion.div>
                    )}
                    {page === 'imageZoom' && (
                        <motion.div
                            key='imageZoom'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageImageZoom close={setStaticPage} />
                        </motion.div>
                    )}
                    {page === 'uploadAvatar' && (
                        <motion.div
                            key='uploadAvatar'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageProfileAvatar close={setStaticPage} />
                        </motion.div>
                    )}
                    {page === 'publicAvatar' && (
                        <motion.div
                            key='publicAvatar'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageProfileAvatarPublic close={setStaticPage} />
                        </motion.div>
                    )}
                    {page === 'latestNews' && (
                        <div
                            className='fixed z-1000 inset-0'
                            // key='gender'
                            // className='fixed z-1000 inset-0 bg-bb-blue-900/50 shadow-2xl-a rounded-3xl-a overflow-y-auto-a no-scrollbar-a'
                            // initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            // animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            // variants={animations.pageSlides}
                            // exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageStaticNews close={setStaticPage} overlay={true} />
                        </div>
                    )}
                    {page === 'filterSettings' && (
                        <motion.div
                            key='filterSettings'
                            className='fixed z-1000 inset-0 bg-white'
                            initial={getReducedMotion('page', animStates.default, reducedMotion).initial}
                            animate={getReducedMotion('page', animStates.default, reducedMotion).animate}
                            variants={animations.pageSlides}
                            exit={() => getReducedMotion('page', animStates.default, reducedMotion).exit}
                        >
                            <PageTripFilters close={setStaticPage} />
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    staticPages: state.staticPages,
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageStaticOverlay);
