import {
    LayoutPage,
    LayoutPageHeader,
    LayoutPageBody,
    LayoutPageSubHeader,
    LayoutPublishSeats,
    LayoutPublishDate,
    LayoutPublishTime,
    LayoutPublishLocation
} from 'components/layouts';
import { connect } from 'react-redux';
import CalendarHeader from 'components/calendar/CalendarHeader';
import OfflineMode from 'components/blocks/OfflineMode';

const PageTripPublishModal = ({
    section,
    publish: {
        publish: { recurring }
    }
}) => {
    const pageData = {
        page: `app.offerSeats.${section}`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: section !== 'time' && section !== 'seats',
        zIndex: 41,
        checkIdParam: false
    };

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({ rtl, lang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation }) => {
                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params}>
                            <OfflineMode position='absolute' />
                        </LayoutPageHeader>
                        {section === 'date' && !recurring && (
                            <LayoutPageSubHeader label={`e.page.publish.${section}.headline`}>{!recurring && <CalendarHeader />}</LayoutPageSubHeader>
                        )}
                        <div className='h-body overflow-y-auto no-scrollbar'>
                            <LayoutPageBody>
                                {(section !== 'date' || (section === 'date' && recurring)) && (
                                    <LayoutPageSubHeader label={`e.page.publish.${section}.headline`}>
                                        {/* {section === 'date' && <CalendarHeader />} */}
                                    </LayoutPageSubHeader>
                                )}
                                <div className={`select-none px-vw-6`}>
                                    {(section === 'from' || section === 'to') && <LayoutPublishLocation source={section} />}
                                    {section === 'seats' && <LayoutPublishSeats />}
                                    {section === 'date' && <LayoutPublishDate />}
                                    {section === 'time' && <LayoutPublishTime />}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search,
    publish: state.publish
});

export default connect(mapStateToProps, null)(PageTripPublishModal);
