import { LayoutPage, LayoutPageBody } from 'components/layouts';
import { routePath } from 'constants/structRoutes';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { animations, getAnimStates, getReducedMotion, getSlideAnimation } from 'constants/animations';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import BtnIcon from 'components/buttons/BtnIcon';
import imagePublish from 'components/images/bg_publish_modal1.png';
import imagePublishMain from 'components/images/bg_publish1.svg';
import imagePublishMainMirror from 'components/images/bg_publish_mirror1.svg';
import imageError from 'components/images/bb_error.png';
import imageSuccess from 'components/images/bb_success.png';
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion';

import { getDateString, getRelativeDate, isDateInPast, isDateTimeInPast } from 'helpers/dates';
import {
    togglePublishSwitching,
    updatePublishTime,
    updatePublishLocation,
    updatePublishDate,
    updatePublishSeats,
    updatePublishLadiesOnly,
    updatePublishRecurring,
    updatePublishFull
} from 'store/actions/publish';
import BtnPill from 'components/buttons/BtnPill';
import PseudoInput from 'components/forms/PseudoInput';
import InputShell from 'components/forms/InputShell';
import { useLayoutEffect, useEffect } from 'react';
import { DateTime } from 'luxon';
import { publishRide } from 'fetch/apiRequests';
import { validateDateTime, validateEmptyWeek, validateTrip, validateDateRageEmpty } from 'helpers/helperFunctions';
import ModalBottom from 'components/modals/ModalBottom';
import { useState } from 'react';
import { PageLoading } from 'pages/index';
import PosterImage from 'components/blocks/PosterImage';
import NotificationBox from 'components/blocks/NotificationBox';
import LayoutPageSubHeader from 'components/layouts/LayoutPageSubHeader';
import OfflineMode from 'components/blocks/OfflineMode';
import MessageBox from 'components/blocks/MessageBox';
import { setStaticPage } from 'store/actions/staticPages';
import { defaultLocationObj, defaultPublishObj } from 'constants/defaultObjects';
import SwitchBtn from 'components/blocks/SwitchBtn';
import IconRound from 'components/blocks/IconRound';
import SwitchSection from 'components/blocks/SwitchSection';
import { analytics } from 'helpers/segment';
import LottieBlock from 'components/blocks/LottieBlock';
import CalendarMultipleInline from 'components/calendar/CalendarMultipleInline';
import useDidMountEffect from 'hooks/useDidMountEffect';
import useWindowSize from 'hooks/useWindowSize';

const PageTripPublish = ({
    setStaticPage,
    togglePublishSwitching,
    updatePublishLocation,
    updatePublishTime,
    updatePublishDate,
    updatePublishLadiesOnly,
    updatePublishRecurring,
    updatePublishFull,
    globalState: { lang, time24 },
    auth: {
        user,
        user: { profile },
        isAuthenticated
    },
    publish: { publish, isValid },
    onboarding: { tooltips }
}) => {
    const pageData = {
        page: `app.offerSeats`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        zIndex: 41,
        checkIdParam: false
    };
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);
    const [response, setResponse] = useState(null);
    const [fullResponse, setFullResponse] = useState(null);
    const [retry, setRetry] = useState(false);
    const { handleNavigation, location } = useMyNavigation();
    const [shouldSlide, setShouldSlide] = useState(false);

    const [clearable, setClearable] = useState(false);

    // const handlePublish = async () => {
    //     await publishRide(publish);
    // };

    const { ratio } = useWindowSize();

    useEffect(() => {
        if (!location?.state?.from?.startsWith('/c/offer-seats')) {
            analytics.page('Publish Page', 'App', isAuthenticated);
        }
    }, []);

    useEffect(() => {
        checkIfClearable();
    }, [publish]);

    const handleModalClose = () => {
        setIsModal(false);
        setResponse(null);
    };

    const handleRetry = () => {
        setResponse(null);
        handleAction();
    };

    const checkIfClearable = () => {
        const publishArrSingle = [
            publish.seatsOffered > 1,
            publish?.from?.location?.coordinates?.length > 0,
            publish?.to?.location?.coordinates?.length > 0,
            getDateString().date !== publish?.date?.[0],
            publish?.time
        ];
        const publishArrRecurring = [
            publish?.from?.location?.coordinates?.length > 0,
            publish?.to?.location?.coordinates?.length > 0,
            validateDateRageEmpty(publish?.dateRange)
        ];
        // console.log(publishArrRecurring);
        setClearable(publish?.recurring ? publishArrRecurring.some((item) => item) : publishArrSingle.some((item) => item));
    };

    const handleClear = (box) => {
        const clearedPublish = defaultPublishObj();
        clearedPublish.recurring = publish?.recurring;

        switch (box) {
            case 'location-from':
                updatePublishLocation(clearedPublish.from, 'from');
                break;
            case 'location-to':
                updatePublishLocation(clearedPublish.to, 'to');
                break;
            case 'date':
                updatePublishDate(clearedPublish.date);
                break;
            case 'time':
                updatePublishTime(clearedPublish.time);
                break;
            case 'seats':
                updatePublishSeats(clearedPublish.seats);
                break;
            case 'all':
                updatePublishFull(clearedPublish);
                break;
        }
    };

    const handleAction = async (status) => {
        setResponse(null);
        setIsModal(false);
        setIsModalAction(true);
        const res = await publishRide({ ...publish, status });
        setFullResponse(res);
        setResponse(res?.status?.toString() || '5xx');
        // console.log('res', res);
        setIsModalAction(false);
    };
    const handleLadiesOnly = () => {
        let tempFlag;
        if (profile?.gender === 'female' || user?.admin) tempFlag = !publish.ladiesOnly;
        else tempFlag = false;
        updatePublishLadiesOnly(tempFlag);
        analytics.track('Ladies-Only Publish Toggle', { ladiesOnly: tempFlag }, isAuthenticated);
    };

    const handleRecurring = () => {
        let tempFlag = !publish.recurring;
        updatePublishRecurring(tempFlag);
        analytics.track('Recurring Publish Toggle', { recurring: tempFlag }, isAuthenticated);
    };
    useLayoutEffect(() => {
        const isToday = isDateInPast(publish.date);
        if (isToday) updatePublishDate([isToday]);
        if (!validateDateTime(publish.date, publish.time)) updatePublishTime('');
    }, []);

    useEffect(() => {
        if (response) setIsModal(true);
    }, [response]);

    useEffect(() => {
        // console.log('full Response', fullResponse);
    }, [fullResponse]);

    // useEffect(() => {}, [publish?.recurring]);

    useDidMountEffect(() => {
        setShouldSlide(true);
    }, [publish?.recurring]);

    return (
        <LayoutPage pageData={pageData} makeRequest={false}>
            {({ rtl, lang, tripLang, translations, location, animStates, params, isValidating, isValidParams, data, mutateData, handleNavigation, online }) => {
                return (
                    <>
                        <div className='h-body overflow-y-auto no-scrollbar'>
                            <LayoutPageBody minHeight='h-body'>
                                <div className={`select-none`}>
                                    <div>
                                        <div className={classNames('text-white text-vw-4xl md:text-4xl bg-bb-blue-900 relative')}>
                                            <div className={classNames(ratio < 0.5 ? 'h-vh-30' : 'h-vh-32', 'w-full  md:h-128 bg-bb-blue-900-a')}>
                                                <div className='absolute inset-x-0 top-0 h-screen-1/3 overflow-hidden'>
                                                    <LottieBlock speed={0.35} rtl={rtl} preset='publish' />
                                                </div>

                                                <div
                                                    className={classNames('absolute w-full flex flex-col px-vw-10 md:px-10 pt-vh-6 md:pt-6', {
                                                        'items-end': rtl
                                                    })}
                                                >
                                                    <div
                                                        className={classNames(
                                                            'flex items-center gap-2 font-extrabold tracking-tight mb-vw-2 md:mb-2 leading-tighter',
                                                            {
                                                                'text-right flex-row-reverse': rtl
                                                            }
                                                        )}
                                                    >
                                                        <span> {translations?.data?.['e.page.publish.poster.title']}</span>
                                                    </div>
                                                    <div
                                                        className={classNames('text-vw-xl text-bb-yellow-400 ', {
                                                            'text-right flex-row-reverse': rtl
                                                        })}
                                                    >
                                                        {translations?.data?.['e.page.publish.poster.subtitle1']}
                                                    </div>
                                                    <div
                                                        className={classNames('w-screen-1/2 text-vw-xl text-bb-yellow-400', {
                                                            'text-right justify-end': rtl
                                                        })}
                                                    >
                                                        {translations?.data?.['e.page.publish.poster.subtitle2']}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <LayoutGroup>
                                            <motion.div
                                                layout
                                                className={classNames('-mt-8 px-vw-10 pt-vw-12 pb-vw-6 flex flex-col bg-white rounded-t-3xl relative ')}
                                            >
                                                <div className={classNames('absolute top-0 transform left-1/2 -translate-x-1/2 -translate-y-1/2')}>
                                                    <BtnIcon
                                                        tapClass='bg-yellow-50 text-yellow-600'
                                                        color='text-yellow-600'
                                                        bgColor='bg-bb-yellow-400'
                                                        size='w-vw-14 h-vw-14'
                                                        icon='ArrowUpDown1'
                                                        iconSize='6.8vw'
                                                        handleClick={togglePublishSwitching}
                                                    />
                                                </div>
                                                <OfflineMode margins='pb-vw-6' />
                                                {isDateTimeInPast(`${publish.date}T${publish.time}`) && (
                                                    <div className={classNames('px-vw-6-a flex flex-col gap-4 mb-vw-4')}>
                                                        <NotificationBox message='e.publish.error.dateTimeInPast' type='error' icon='Time' />
                                                    </div>
                                                )}

                                                {(tooltips?.viewPricing !== false || tooltips?.ladiesOnly !== false) && (
                                                    <div className={classNames('mt-vw-2 mb-vw-4 flex flex-col gap-2')}>
                                                        {tooltips?.viewPricing !== false && (
                                                            <motion.div layout='position' key='k1' initial={false}>
                                                                <MessageBox
                                                                    label=''
                                                                    noTitle
                                                                    id='viewPricing'
                                                                    margins=''
                                                                    padding=''
                                                                    bodyAction={() => setStaticPage('pricing')}
                                                                >
                                                                    <IconRound icon='Coins' preset='yellow' presetSize='small' />
                                                                    {translations?.data?.['e.tooltip.compensation.short']}
                                                                </MessageBox>
                                                            </motion.div>
                                                        )}
                                                        {(profile?.gender === 'female' || user?.admin) && tooltips?.ladiesOnly !== false && (
                                                            <motion.div layout='position' key='k2'>
                                                                <MessageBox
                                                                    label=''
                                                                    noTitle
                                                                    id='ladiesOnly'
                                                                    margins=''
                                                                    padding=''
                                                                    bodyAction={() => setStaticPage('ladiesOnly')}
                                                                >
                                                                    {tooltips.viewPricing === false && (
                                                                        <IconRound icon='Female1' preset='yellow' presetSize='small' />
                                                                    )}
                                                                    {translations?.data?.['e.tooltip.ladiesOnly.short']}
                                                                </MessageBox>
                                                            </motion.div>
                                                        )}
                                                    </div>
                                                )}

                                                <motion.div layout='position' key='publishFields'>
                                                    <SwitchSection
                                                        padding='py-vw-4a pt-vw-2 pb-vw-3'
                                                        border=''
                                                        icon={'Repeat1'}
                                                        iconColorPreset={publish?.recurring ? 'light-violet' : 'default'}
                                                        // color={data?.ladiesOnly && !data?.bookings?.length ? 'on' : 'locked'}
                                                        label='e.ride.recurring'
                                                        visible={true}
                                                        val={publish?.recurring}
                                                        setVal={handleRecurring}
                                                    />

                                                    <InputShell>
                                                        <PseudoInput
                                                            clear={() => handleClear('location-from')}
                                                            toPage='app.offerSeats.from'
                                                            icon='MapPin1'
                                                            placeholder='e.page.search.from'
                                                            show={publish?.from?.[tripLang]?.formatted}
                                                            value={publish?.from?.[tripLang]?.formatted}
                                                        />
                                                    </InputShell>
                                                    <InputShell>
                                                        <PseudoInput
                                                            clear={() => handleClear('location-to')}
                                                            toPage='app.offerSeats.to'
                                                            icon='MapPin1'
                                                            placeholder='e.page.search.to'
                                                            show={publish?.to?.[tripLang]?.formatted}
                                                            value={publish?.to?.[tripLang]?.formatted}
                                                        />
                                                    </InputShell>
                                                </motion.div>

                                                {publish?.recurring && (
                                                    <motion.div
                                                        layout='position'
                                                        // layout
                                                        key='singlePart'
                                                        className='mt-vw-4'
                                                        // initial={'inField'}
                                                        // animate={'fadeIn'}
                                                        // variants={animations.pageSlides}
                                                        // exit={'left'}
                                                    >
                                                        {/* <InputShell>
                                                            <PseudoInput
                                                                clear={() => handleClear('date')}
                                                                toPage='app.offerSeats.date'
                                                                icon='History1'
                                                                show={getDateString().date !== publish?.date?.[0]}
                                                                value={getRelativeDate(publish?.date, lang)}
                                                            />
                                                        </InputShell> */}
                                                        <CalendarMultipleInline shouldSlide={shouldSlide} hanldeSlide={setShouldSlide} />
                                                    </motion.div>
                                                )}

                                                {!publish?.recurring && (
                                                    <motion.div
                                                        layout='position'
                                                        key='recurringPart'
                                                        className='mt-vw-4'
                                                        // initial={'inField'}
                                                        // animate={'inField'}
                                                        // variants={animations.pageSlides}
                                                        // exit={'right'}
                                                    >
                                                        <motion.div
                                                            initial={'fadeOut'}
                                                            animate={'inField'}
                                                            variants={animations.pageSlides}
                                                            className={classNames('mb-vw-4')}
                                                        >
                                                            <InputShell>
                                                                <div {...getSlideAnimation(rtl)}>
                                                                    <PseudoInput
                                                                        clear={() => handleClear('date')}
                                                                        toPage='app.offerSeats.date'
                                                                        icon='Calendar2'
                                                                        show={getDateString().date !== publish?.date?.[0]}
                                                                        value={getRelativeDate(publish?.date, lang)}
                                                                    />
                                                                </div>
                                                            </InputShell>
                                                        </motion.div>

                                                        <div initial={'fadeOut'} animate={'inField'} variants={animations.pageSlides}>
                                                            <InputShell>
                                                                <PseudoInput
                                                                    clear={() => handleClear('time')}
                                                                    toPage='app.offerSeats.time'
                                                                    icon='Time'
                                                                    show={publish.time}
                                                                    value={
                                                                        validateDateTime(publish.date, publish.time)
                                                                            ? DateTime.fromISO(`${publish.date}T${publish.time}`).toFormat(
                                                                                  time24 ? 'HH:mm' : 'hh:mm a'
                                                                              )
                                                                            : ''
                                                                    }
                                                                    placeholder={'e.page.publish.time'}
                                                                    width=''
                                                                />
                                                                <PseudoInput
                                                                    toPage='app.offerSeats.seats'
                                                                    icon='Users'
                                                                    value={publish?.seatsOffered}
                                                                    width=''
                                                                />
                                                            </InputShell>
                                                        </div>
                                                    </motion.div>
                                                )}

                                                <motion.div
                                                    layout='position'
                                                    key='remainingPart'
                                                    className='mt-vw-2'
                                                    initial={false}
                                                    animate={'inField'}
                                                    variants={animations.pageSlides}
                                                >
                                                    <SwitchSection
                                                        padding='py-vw-4a pt-vw-2 pb-vw-3'
                                                        border=''
                                                        icon={'Female1'}
                                                        iconColorPreset={publish?.ladiesOnly ? 'light-pink' : 'default'}
                                                        // color={data?.ladiesOnly && !data?.bookings?.length ? 'on' : 'locked'}
                                                        label='e.ride.ladiesOnly'
                                                        visible={profile?.verifiedProfile && profile?.gender === 'female'}
                                                        val={publish?.ladiesOnly}
                                                        setVal={handleLadiesOnly}
                                                    />

                                                    <div className={classNames('flex items-center justify-center mt-2')}>
                                                        <BtnPill
                                                            type='primary'
                                                            handleClick={() => setIsModal(true)}
                                                            justify={'justify-between'}
                                                            disabled={!validateTrip(publish) || !online}
                                                            rtl={rtl}
                                                        >
                                                            <span className='w-6' />
                                                            {translations?.data?.['e.page.publish.action']}
                                                            <Icons icon={'UploadCloud'} />
                                                        </BtnPill>
                                                    </div>
                                                    {clearable && (
                                                        <div className={classNames('flex items-center justify-center mt-vw-4')}>
                                                            <BtnPill
                                                                type='confirmed'
                                                                handleClick={() => handleClear('all')}
                                                                disabled={!online}
                                                                justify={'justify-between'}
                                                                rtl={rtl}
                                                            >
                                                                <span className='w-6' />

                                                                {translations?.data?.['e.page.publish.action.clear']}
                                                                <Icons icon={'Close'} />
                                                            </BtnPill>
                                                        </div>
                                                    )}
                                                </motion.div>
                                            </motion.div>
                                        </LayoutGroup>
                                    </div>
                                    {/* {isDateTimeInPast(`${publish.date}T${publish.time}`) && (
                                        <div className={classNames('px-vw-6 flex flex-col gap-4')}>
                                            <NotificationBox message='e.publish.error.dateTimeInPast' type='warning' icon='Time' />
                                        </div>
                                    )} */}
                                </div>
                            </LayoutPageBody>
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height={getModalHeight(lang)}
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center'>
                                <OfflineMode margins='pb-vw-6' />
                                {!response && (
                                    <>
                                        <PosterImage src={imagePublish} />

                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 font-bold text-vw-xl text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.setToPublish.headline']}
                                        </div>
                                        <BtnPill
                                            type='primary'
                                            handleClick={handleAction}
                                            params={['PUBLISHED']}
                                            justify={'justify-between'}
                                            disabled={!validateTrip(publish) || !online}
                                            rtl={rtl}
                                        >
                                            <span className='w-vw-6' />
                                            {translations?.data?.['e.page.publish.action']}
                                            <Icons icon={'UploadCloud'} />
                                        </BtnPill>
                                        <BtnPill
                                            type='confirmed'
                                            handleClick={handleAction}
                                            params={['DRAFT']}
                                            justify={'justify-between'}
                                            disabled={!validateTrip(publish) || !online}
                                            rtl={rtl}
                                        >
                                            <span className='w-vw-6' />
                                            {translations?.data?.['e.page.publish.action.draft']}
                                            <Icons icon={'Unpublish'} />
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {response === '200' && (
                                    <>
                                        <PosterImage src={fullResponse?.data?.status === 'PUBLISHED' ? imageSuccess : imageSuccess} />

                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 font-bold text-vw-xl text-center text-gray-600'>
                                            {
                                                translations?.data?.[
                                                    fullResponse?.data?.status === 'PUBLISHED'
                                                        ? 'e.page.publish.status.success.headline'
                                                        : 'e.page.publish.status.draft.headline'
                                                ]
                                            }
                                        </div>

                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            <span
                                                className={classNames({
                                                    'text-right': rtl
                                                })}
                                            >
                                                {
                                                    translations?.data?.[
                                                        fullResponse?.data?.status === 'PUBLISHED'
                                                            ? 'e.page.publish.status.success.button'
                                                            : 'e.page.publish.status.draft.button'
                                                    ]
                                                }
                                            </span>
                                        </BtnPill>
                                    </>
                                )}
                                {response === '401' && (
                                    <>
                                        <PosterImage src={imageError} />

                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 font-bold text-vw-xl text-center text-gray-600'>
                                            {translations?.data?.['e.page.publish.status.forbidden.headline']}
                                        </div>

                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.page.publish.status.forbidden.button']}
                                        </BtnPill>
                                    </>
                                )}
                                {response === '400' && (
                                    <>
                                        <PosterImage src={imageError} />

                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 font-bold text-vw-xl text-center text-gray-600'>
                                            {translations?.data?.['e.page.publish.status.duplicate.headline']}
                                        </div>

                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.page.publish.status.duplicate.button']}
                                        </BtnPill>
                                    </>
                                )}
                                {response === '403' && (
                                    <>
                                        <PosterImage src={imageError} />
                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 font-bold text-vw-xl text-center text-gray-600'>
                                            {translations?.data?.['e.page.publish.status.forbidden.headline']}
                                        </div>

                                        <BtnPill type='confirmed' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.page.publish.status.forbidden.button']}
                                        </BtnPill>
                                    </>
                                )}
                                {response?.startsWith('5') && (
                                    <>
                                        <PosterImage src={imageError} />
                                        <div className='px-vw-2 mt-vw-4 mb-vw-4 font-bold text-vw-xl text-center text-gray-600'>
                                            {translations?.data?.['e.page.publish.status.server.headline']}
                                        </div>
                                        <BtnPill
                                            type='primary'
                                            handleClick={handleAction}
                                            justify={'justify-between'}
                                            disabled={!validateTrip(publish) || !online}
                                            rtl={rtl}
                                        >
                                            <span className='w-6' />
                                            {translations?.data?.['e.page.publish.status.server.button']}
                                            <Icons icon={'UploadCloud'} />
                                        </BtnPill>

                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                        {isModalAction && <PageLoading bg='bg-white' />}
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    publish: state.publish,
    auth: state.auth,
    onboarding: state.onboarding
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            togglePublishSwitching,
            updatePublishTime,
            updatePublishLocation,
            updatePublishDate,
            updatePublishLadiesOnly,
            updatePublishRecurring,
            updatePublishFull,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTripPublish);
