import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';

import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

import { daysOfWeek, daysOfWeekStrings } from 'constants/defaultObjects';
import Pill from 'components/blocks/Pill';

const DaysOfWeekFilter = ({ dateRange, handleDays, containerClass = 'px-vw-6 mb-vw-4' }) => {
    return (
        <GetLang>
            {({ lang, rtl, translations, time24 }) => (
                <div className={classNames(containerClass, rtl && 'flex-row-reverse', 'flex flex-wrap justify-between')}>
                    {daysOfWeek.map((day, dayIndex) => (
                        <button
                            key={dayIndex}
                            style={{ direction: rtl ? 'rtl' : 'ltr' }}
                            className='focus:outline-none'
                            onClick={() => handleDays(dayIndex, !dateRange[dayIndex])}
                        >
                            <Pill
                                sizePreset='small-circle'
                                textSize={lang === 'ar' ? 'text-vw-xxs' : 'text-vw-sm'}
                                colorPreset={dateRange[dayIndex] ? 'light-blue' : 'faded'}
                                margins=''
                            >
                                {daysOfWeekStrings[lang][dayIndex][lang === 'ar' ? 'iso3' : 'iso2']}
                            </Pill>
                        </button>
                    ))}
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    publish: state.publish
});

export default connect(mapStateToProps, null)(DaysOfWeekFilter);
