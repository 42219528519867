import DoubleList from 'components/blocks/DoubleList';
import Spinner from 'components/blocks/Spinner';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import { reportUser } from 'fetch/apiRequests';
import GetLang from 'hoc/GetLang';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { useState } from 'react';
import classNames from 'classnames';
import LayoutPageHeader from './LayoutPageHeader';
import { defaultSize } from 'components/icons/defaults';
import OfflineMode from 'components/blocks/OfflineMode';

const reportReasons = [
    'report.dui',
    'report.verbalHarassment',
    'report.dirtyVehicle',
    'report.sexualHarassment',
    'report.rude',
    'report.dangerousDriving',
    'report.destructionProperty'
];

const LayoutReport = ({ handleModal, reportedUser, reportingUser, showCancel, noHeader }) => {
    const isOnline = useOnlineStatus();
    const [reportingsList, setReportingsList] = useState([...reportReasons]);
    const [reportings, setReportings] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleReportings = (id, op) => {
        if (op === 'ADD') {
            setReportings((prev) => [...prev, id]);
            setReportingsList(reportingsList.filter((item) => item !== id));
        }
        if (op === 'REMOVE') {
            setReportingsList((prev) => [...prev, id]);
            setReportings(reportings.filter((item) => item !== id));
        }
    };

    const submitReporting = async () => {
        const body = {
            reportedUser,
            reportingUser,
            infractions: reportings.map((item) => item.slice(7))
        };
        console.log(body);
        setLoading(true);

        const res = await reportUser(body);
        // account for error
        setLoading(false);
        if (res.status === 200) handleModal(false);
    };

    const pageData = {
        backBtn: true,
        btnFlip: true,
        btnIcon: 'Close'
    };

    const closeModal = () => {
        handleModal(false);
    };

    return (
        <>
            {!noHeader && (
                <LayoutPageHeader
                    handleButton={closeModal}
                    pageData={pageData}
                    // pageParams={params}
                ></LayoutPageHeader>
            )}

            <GetLang>
                {({ rtl, translations }) => (
                    <div
                        className={classNames(
                            'h-full md:h-body-w-header-desktop relative px-vw-6 md:px-6 pb-vw-4 md:pb-4 flex flex-col justify-between',
                            noHeader && 'pt-vw-6 md:pt-12'
                        )}
                    >
                        <div>
                            <OfflineMode margins='mb-6' />
                            <div className='px-vw-2 md:px-2 mb-vw-6 md:mb-6 font-semibold text-vw-lg md:text-lg text-center text-gray-600'>
                                {translations.data['e.chat.reportUser.title']}
                            </div>

                            <DoubleList listFrom={reportingsList} listTo={reportings} handleList={handleReportings} />
                        </div>

                        <div className=''>
                            <BtnPill
                                type='primary'
                                justify={loading ? 'justify-center' : 'justify-between'}
                                rtl={rtl}
                                params={[false]}
                                handleClick={submitReporting}
                                margins='mb-2 last:mb-0'
                                disabled={!reportedUser || !reportingUser || !isOnline || !reportings?.length}
                            >
                                {loading ? (
                                    <Spinner strokeColor='#FFffff' />
                                ) : (
                                    <>
                                        <span className='w-vw-6 md:w-6' />
                                        {translations.data['e.chat.reportUser.action']}
                                        <Icons icon={'Alarm'} size={defaultSize} />
                                    </>
                                )}
                            </BtnPill>
                            {showCancel && (
                                <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModal} margins='mb-0'>
                                    {translations?.data?.['e.later']}
                                </BtnPill>
                            )}
                        </div>
                    </div>
                )}
            </GetLang>
        </>
    );
};

export default LayoutReport;
