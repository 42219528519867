import PageStaticTemplate from './PageStaticTemplate';

const PageStaticWeNeedYou = ({ overlay }) => {
    return (
        <PageStaticTemplate
            page='static.help.weNeedYou'
            apiUrl='/static-pages/62fd1a4fe2654aac50328dfd'
            icon='Heart3'
            overlay={overlay}
            analyticsPage='We Need You'
        />
    );
};

export default PageStaticWeNeedYou;
