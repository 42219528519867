import classNames from 'classnames';
import Icons from 'components/icons/index';
import { allowedPhonesObj, allowedPhonesPrefixObj } from 'constants/globalConstants';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';

const PhoneInput = ({ value, handleUpdate, handleIsValid, digits = 14, allowed, disabled }) => {
    const refA = useRef();
    const refB = useRef();
    const [isFocus, setIsFocus] = useState(true);
    const [isValid, setIsValid] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            refA?.current?.focus();
            // refA?.current.scrollIntoView();
        }, 150);
    }, []);

    useEffect(() => {
        if (allowed?.allowedPhones) {
            if (allowed?.allowedPhones[value]) setIsValid(true);
        } else {
            if (allowedPhonesObj[value]) setIsValid(true);
        }

        // console.log('phone is', value);
    }, [value]);

    const digitsArr = new Array(digits).fill();

    const handleFocus = (e) => {
        setIsFocus(true);
        refA.current.focus();
        // refA.current.scrollIntoView();
    };

    const handleBlur = (e) => {
        setIsFocus(false);
    };

    const handleChange = (e) => {
        let v = e.target.value.toString();
        const regexString = digitsArr.reduce((prev, curr) => `${prev}?[^0-9]`, '[^0-9]');
        // console.log('regex ex', regexString);
        const regex = new RegExp(regexString);
        v = v.replace(regex, '');
        // check for length

        if (v.charAt(0) === '-') v = '';

        // v = v.slice(0, digits);

        const ln = getPhoneLength(value);
        // console.log('found length', ln);
        // console.log('current length', value.length);
        // console.log('current value', value);
        v = v.slice(0, ln);
        if (v.length == ln) setIsValid(true);
        else setIsValid(false);

        handleUpdate(v);
    };

    const getPhoneLength = (phone) => {
        const tmpAllowedPrefix = allowed?.allowedPhonesPrefix || allowedPhonesPrefixObj;

        const foundKeys = Object.keys(tmpAllowedPrefix).filter((key) => phone.startsWith(key));
        const keyLengths = foundKeys.map((item) => ({
            key: item,
            length: item.length
        }));
        let foundVal = 0;
        let foundKey = 0;
        keyLengths.forEach((elm, i) => {
            if (foundVal < elm.length) {
                foundVal = elm.length;
                foundKey = elm.key;
            }
        });

        return tmpAllowedPrefix[foundKey];
    };

    useLayoutEffect(() => {
        const ln = getPhoneLength(value);
        if (value.length == ln) setIsValid(true);
        else setIsValid(false);
    }, []);

    useEffect(() => {
        handleIsValid(isValid);
    }, [isValid]);

    return (
        <div ref={refB} className={classNames('flex justify-between w-full focus:outline-none')} tabIndex={1} onFocus={handleFocus} onBlur={handleBlur}>
            <input
                // disabled={!allowed?.allowedPhonesPrefix}
                disabled={disabled}
                inputMode='numeric'
                ref={refA}
                onFocus={handleFocus}
                type='text'
                value={value}
                onInput={(e) => handleChange(e)}
                style={{ zIndex: '-1' }}
                className='outline-none absolute top-1/2 left-1/2 text-center focus:outline-none cursor-none caret-transparent text-vw-xs w-vw-1 h-vw-1 opacity-0a'
            />
            {isValid && <Icons icon='Check' containerClass='absolute right-vw-4 text-emerald-500' />}
            <Digit value={'+'} parse={false} />
            {digitsArr.map((elm, index) => (
                <Digit key={index + 1} value={value} place={index + 1} isFocus={isFocus} isValid={isValid} />
            ))}
        </div>
    );
};

export default PhoneInput;

const parseValue = (value, place) => {
    return value.toString().slice(place - 1, place);
};

const Digit = ({ value, place, isFocus, parse = true, isValid }) => {
    return (
        <div
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            className={classNames('w-vw-10  font-bold text-bb-blue-500 text-vw-xl flex items-center justify-center', {
                'border-b-2 border-bb-blue-500': value.length === place - 1 && isFocus && !isValid
            })}
        >
            {parse ? value?.toString()?.slice?.(place - 1, place) : value}
        </div>
    );
};
