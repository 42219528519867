import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

const LayoutPageSubHeader = ({ label, children, className }) => {
    return (
        <GetLang>
            {({ rtl, lang, translations }) => (
                <div className={classNames('flex flex-col px-vw-6 md:px-6')}>
                    <div
                        className={classNames(
                            'text-vw-4xl font-extrabold leading-tight text-bb-blue-500 mb-vw-8 md:mb-8 md:text-4xl',
                            { 'text-right': rtl, 'tracking-tight': !rtl },
                            className
                        )}
                    >
                        {translations?.data?.[label]}
                    </div>
                    <div>{children}</div>
                </div>
            )}
        </GetLang>
    );
};

export default LayoutPageSubHeader;
