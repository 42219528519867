import { routePath } from 'constants/structRoutes';
import { useNavigate, useLocation } from 'react-router-dom';
import Itinerary from 'components/blocks/Itinerary';
import AvatarFull from 'components/blocks/AvatarFull';
import { motion, useCycle } from 'framer-motion';
import StatusBooked from 'components/blocks/StatusBooked';
import classNames from 'classnames';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getPresetByStatus, getRecurringDay } from 'helpers/helperFunctions';

import { useRef, useEffect } from 'react';

import CardSection from './CardSection';
import useMyNavigation from 'hooks/useMyNavigation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setScroll } from 'store/actions/globalState';
import IconRoundStatus from 'components/blocks/IconRoundStatus';
import { analytics } from 'helpers/segment';

const CardBooking = ({ setMode, setCurrentId, data, rtl, lang, archive }) => {
    const [anim, cycleAnim] = useCycle('off', 'on');
    const path = archive ? 'app.rides.archived.booking' : 'app.rides.booking';

    const navigate = useNavigate();
    const location = useLocation();
    const handleButton = () => {
        navigate(`${routePath(path)}?id=${data?.id}`, {
            state: { from: location.pathname, to: routePath(path) }
        });
    };

    const { handleNavigation } = useMyNavigation();

    const handleContact = (id) => {
        analytics.track('Chat Passenger => Driver (Booking)', null, true);
        handleNavigation('app.messages', { otherUser: id });
    };

    const handleUserProfile = (id) => {
        handleNavigation('app.user', { id });
    };

    const handleMenuClick = (status) => {
        if (setCurrentId && setMode) {
            setCurrentId({
                id: data?._id,
                name: data?.driverId?.profile?.displayName || data?.driverId?.profile?.firstName,
                reviewId: data?.review?._id,
                currentRating: data?.review?.rating
            });
            setMode(status);
        }
    };

    const getSwipeMenuOptions = (status) => {
        const swipeMenuOptions = [];

        data?.status?.startsWith('APPROVED_BY_') &&
            !archive &&
            swipeMenuOptions.push({
                icon: 'CloseCircle',
                handleClick: handleMenuClick,
                params: ['CANCELED_BY_PASSENGER']
            });

        data?.status === 'PENDING' &&
            swipeMenuOptions.push({
                icon: 'CloseCircle',
                handleClick: handleMenuClick,
                params: ['DECLINED_BY_PASSENGER']
            });

        !data?.archive &&
            ((data?.status?.startsWith('APPROVED_BY_') && archive) || data?.status?.startsWith('CANCELED_BY_') || data?.status?.startsWith('DECLINED_BY_')) &&
            swipeMenuOptions.push({
                icon: 'Archive',
                handleClick: handleMenuClick,
                params: ['ARCHIVE_BOOKING']
            });
        (data?.status?.startsWith('CANCELED_BY_') || data?.status?.startsWith('DECLINED_BY_')) &&
            swipeMenuOptions.push({
                icon: 'Trash',
                handleClick: handleMenuClick,
                params: ['DELETE_BOOKING']
            });
        // data?.review?.rating === null &&
        //     swipeMenuOptions.push({
        //         icon: 'StarStroke',
        //         handleClick: handleMenuClick,
        //         params: ['RATE_BOOKING']
        //     });
        swipeMenuOptions.push({
            icon: 'Messages1',
            handleClick: handleContact,
            params: [data?.driverId?._id]
        });
        if (data?.driverId?.blockUser) swipeMenuOptions.pop();
        return swipeMenuOptions;
    };

    return (
        <>
            <CardSection
                // runOnOpen={() =>
                //     setCurrentId?.({
                //         id: data?._id,
                //         name: data?.driverId?.profile?.firstName,
                //         reviewId: data?.review?._id
                //     })
                // }
                // runOnClosed={() => setCurrentId?.(null)}
                parentAnim={anim}
                border='border-t-8 last:border-b-8'
                borderColor='border-gray-100'
                paddingX=''
                paddingY=''
                containerClass={'flex flex-col justify-between'}
                // borderColor='border-gray-200'
                verticalStack={true}
                // handleClick={handleButton}
                swipeMenuOptions={getSwipeMenuOptions(data?.driverId?._id, data?.driverId?.blockUser)}
            >
                <div
                    className={classNames('z-50a absolute top-vw-6', {
                        'left-vw-6': rtl,
                        'right-vw-6': !rtl
                    })}
                >
                    <StatusBooked threshold={1} status={data?.status} noText archive={data?.archive || archive} seats={data?.seats} />
                </div>

                <div
                    className={classNames('z-50a absolute top-vw-6 md:top-6 flex flex-col gap-2', {
                        'left-vw-6 md:left-6': rtl,
                        'right-vw-6 md:right-6': !rtl
                    })}
                >
                    <StatusBooked threshold={1} status={data?.status} noText archive={data?.archive || archive} seats={data?.seats} />
                    {data?.ladiesOnly ? <IconRoundStatus icon={'Female1'} colorPreset='light-pink' /> : null}
                </div>

                <div onTouchStart={() => cycleAnim()} onTouchEnd={() => cycleAnim()} onClick={handleButton}>
                    <div className={`px-vw-6 pt-vw-6 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                        <Pill
                            icon={data?.recurring ? 'Repeat1' : 'Calendar2'}
                            sizePreset='small'
                            colorPreset={getPresetByStatus(data?.status, data?.archive || archive, data?.recurring)}
                        >
                            <FormattedDateTime dateTime={data?.dateTime} date dateString={data?.recurring ? getRecurringDay(data?.recurringDay, lang) : null} />
                        </Pill>
                        <Pill icon='Time' sizePreset='small' colorPreset='light-gray'>
                            <FormattedDateTime dateTime={data?.dateTime} time />
                        </Pill>
                    </div>

                    <Itinerary data={data} archive={data?.archive || archive} />
                </div>
                {/* <div className={'absolute inset-0 bg-white opacity-80'} /> */}
                <AvatarFull
                    toReview={data?.review?.rating}
                    handleReview={() => handleMenuClick('RATE_BOOKING')}
                    // toReview={data?.review?.rating === null}
                    profile={data?.driverId?.profile}
                    preset={data?.archive || archive ? 'archive' : 'default'}
                />

                {/* {(data?.status?.startsWith('CANCELED_BY_') ||
                    data?.status?.startsWith('DECLINED_BY_')) &&
                    !archive && (
                        <div
                            className={
                                'z-10 absolute inset-0 pointer-events-none bg-white opacity-50'
                            }
                        ></div>
                    )} */}
            </CardSection>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CardBooking);
