import { connect } from 'react-redux';

import { LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { bindActionCreators } from 'redux';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';

import { setStaticPage } from 'store/actions/staticPages';

import { useState, useEffect, useRef } from 'react';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';

import GetLang from 'hoc/GetLang';

const PageProfileAvatarPublic = ({ setStaticPage, staticPages: { page, payload } }) => {
    const pageData = {
        backBtn: true,
        zIndex: 41,
        btnIcon: 'Close',
        btnFlip: true,
        checkIdParam: false
    };

    const [ready, setReady] = useState(false);

    const closeModal = () => {
        setStaticPage(null);
    };

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <>
                    <LayoutPageHeader
                        handleButton={closeModal}
                        pageData={pageData}
                        // pageParams={params}
                    ></LayoutPageHeader>

                    <div className={classNames('h-body-w-header-0', 'overflow-y-auto text-vw-base mt-vw-6a no-scrollbar font-semibold text-gray-500')}>
                        <LayoutPageBody minHeighta={classNames('min-h-body-w-header-footer-2')}>
                            <div className=''>
                                <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                    <div className={classNames('h-body flex flex-col justify-between', 'px-vw-6 relative w-full')}>
                                        <div
                                            style={{
                                                direction: rtl ? 'rtl' : 'ltr'
                                            }}
                                        >
                                            <OfflineMode margins=' my-6' />
                                            {/* <h2
                                                style={{
                                                    direction: rtl ? 'rtl' : 'ltr'
                                                }}
                                                className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                            >
                                                {translations?.data?.['e.verify.photo.title']}
                                            </h2> */}

                                            <div className={'flex justify-center relative mt-vw-8 px-vw-6'}>
                                                {payload ? (
                                                    <div>
                                                        <div className={classNames(!ready && 'hidden')}>
                                                            <div className={classNames('h-vh-36-a aspect-square overflow-hidden rounded-full')}>
                                                                <img
                                                                    src={payload}
                                                                    onLoad={() => setReady(true)}
                                                                    className={classNames('object-cover w-full h-full')}
                                                                />
                                                            </div>
                                                        </div>
                                                        {!ready && (
                                                            <div
                                                                className={classNames(
                                                                    'bg-bb-blue-50 text-bb-blue-550 h-vh-36-a w-full aspect-square overflow-hidden rounded-full  flex justify-center items-center'
                                                                )}
                                                            >
                                                                <Icons stroke={'0.75'} icon={'User'} size={'40vw'} />
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={classNames(
                                                            'bg-bb-blue-50 text-bb-blue-550 h-vh-36-a w-full aspect-square overflow-hidden rounded-full  flex justify-center items-center'
                                                        )}
                                                    >
                                                        <Icons stroke={'0.75'} icon={'User'} size={'40vw'} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={classNames('flex flex-col')}>
                                            <BtnPill
                                                type='primary'
                                                justify={'justify-center'}
                                                rtl={rtl}
                                                params={[false]}
                                                handleClick={closeModal}
                                                margins='mb-vw-4'
                                            >
                                                {translations?.data?.['e.static.btn.close']}
                                            </BtnPill>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LayoutPageBody>
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState,
    staticPages: state.staticPages
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageProfileAvatarPublic);
