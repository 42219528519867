import GetLang from 'hoc/GetLang';
import { motion } from 'framer-motion';
import { animations, getReducedMotion } from 'constants/animations';
import { pluralize } from 'helpers/helperFunctions';
import imageResult from 'components/images/bg_search_results1.svg';
import imageResultMirror from 'components/images/bg_search_results_mirror1.svg';
import Icons from 'components/icons/index';
import classNames from 'classnames';
import OfflineMode from './OfflineMode';
import LottieBlock from './LottieBlock';

const animStates2 = {
    results: { initial: 'bottomFast', animate: 'in', exit: 'bottomFast' }
};

const PosterResults = ({ data, isValidating = false, search, animStates: { animCards }, reducedMotion }) => {
    const locFrom = search.from.location.coordinates.length === 0 ? false : true;
    const locTo = search.to.location.coordinates.length === 0 ? false : true;

    return (
        <GetLang>
            {({ tripLang, translations, rtl, lang }) => (
                <>
                    <div
                        className={`bg-bb-blue-900-a bg-top  h-screen-1/3 bg-cover md:bg-contain md:h-60 relative px-vw-8 md:px-8 text-vw-base md:text-base text-white font-bold  py-vw-8 md:py-8`}
                        // style={{
                        //     backgroundImage: `url(${rtl ? imageResultMirror : imageResult})`

                        // }}
                    >
                        <div className='absolute inset-x-0 top-0 h-screen-1/3 overflow-hidden'>
                            <LottieBlock speed={0.35} rtl={rtl} />
                        </div>
                        <div className={classNames('absolute inset-x-0  px-vw-10 md:px-10  md:max-w-3xl md:mx-auto flex flex-col', rtl && 'items-end')}>
                            <div
                                className={` mb-vw-4 md:mb-4 font-black text-vw-xl md:text-xl text-white capitalize flex items-center gap-2 ${
                                    rtl ? 'flex-row-reverse align-right' : 'flex-row'
                                }`}
                            >
                                {!locFrom && !locTo && <div className=''>{translations?.data?.['e.results.allTrips']}</div>}
                                {!locFrom && locTo && <div className=''>{translations?.data?.['e.results.allTripsTo']}</div>}
                                {locFrom && !locTo && <div className=''>{translations?.data?.['e.results.allTripsFrom']}</div>}
                                {locFrom && (
                                    <div className=''>
                                        {search.from[tripLang].locality ||
                                            search.from[tripLang].admin_l2 ||
                                            search.from[tripLang].admin_l1 ||
                                            search.from[tripLang].formatted ||
                                            translations?.data?.['e.anywhere']}
                                    </div>
                                )}
                                {locFrom && locTo && (
                                    <span className=''>
                                        <Icons icon={rtl ? 'ArrowLeftLong1' : 'ArrowRightLong1'} />
                                    </span>
                                )}
                                {locTo && (
                                    <div className=''>
                                        {search.to[tripLang].locality ||
                                            search.to[tripLang].admin_l2 ||
                                            search.to[tripLang].admin_l1 ||
                                            search.to[tripLang].formatted ||
                                            translations?.data?.['e.anywhere']}
                                    </div>
                                )}
                            </div>
                            {data?.count == null && (
                                <div
                                    className={`${rtl ? 'text-right' : 'text-left w-vw-2/5 md:w-2/5'}`}
                                >{`${translations?.data?.['e.results.searching']} `}</div>
                            )}
                            {data?.count != null && !isValidating && (
                                <div className={`flex ${rtl ? 'justify-end' : 'justify-start'}`}>
                                    {data.count > 0 ? (
                                        <>
                                            <motion.div
                                                key='posterResults'
                                                initial={getReducedMotion('results', animStates2.results, reducedMotion).initial}
                                                animate={getReducedMotion('results', animStates2.results, reducedMotion).animate}
                                                variants={animations.staggerFromTop}
                                                className={`flex flex-col gap-1 ${rtl ? 'items-end' : 'items-start'}`}
                                            >
                                                <motion.span key='posterResultsSpan1' variants={animations.elementSlides}>
                                                    {`${translations?.data?.['e.results.found.a']}`}
                                                </motion.span>
                                                <motion.span
                                                    key='posterResultsSpan2'
                                                    variants={animations.elementSlides}
                                                    className='text-vw-2xl md:text-2xl w-max bg-bb-red-400 text-white rounded-lg px-vw-6 md:px-6 inline-flex items-center font-bold'
                                                >
                                                    {`${pluralize('w.rides', data?.count, lang)}`}
                                                </motion.span>
                                                <motion.span key='posterResultsSpan3' variants={animations.elementSlides}>
                                                    {`${translations?.data?.['e.results.found.b']}`}
                                                </motion.span>
                                            </motion.div>
                                        </>
                                    ) : (
                                        <span
                                            className={`w-screen-1/2 md:w-1/2 ${rtl ? 'text-right' : 'text-left'}`}
                                        >{`${translations?.data?.['e.results.none']}`}</span>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <OfflineMode margins='pt-vw-6 md:pt-6' force={false} />
                    {data?.count || true ? (
                        <motion.div
                            key='posterResultsSpan4'
                            initial={getReducedMotion('results', animCards, reducedMotion).initial}
                            animate={getReducedMotion('results', animCards, reducedMotion).animate}
                            variants={animations.result}
                            className={`md:max-w-3xl md:mx-auto text-gray-600 px-vw-6 md:px-6 flex items-center py-vw-6 md:py-6 gap-4 ${
                                rtl ? 'flex-row-reverse' : 'flex-row'
                            }`}
                        >
                            {/* <Icons icon='Calendar2' size={18} /> */}
                            <div
                                className={`text-vw-sm md:text-sm ${rtl ? 'text-right' : 'text-left'}`}
                            >{`${translations?.data?.['e.resultsOrderedByDay']}`}</div>
                        </motion.div>
                    ) : null}
                </>
            )}
        </GetLang>
    );
};

export default PosterResults;
