import { connect } from 'react-redux';
import { LayoutPage, LayoutPageHeader, LayoutPageBody, LayoutPageFooter } from 'components/layouts';
import { BtnContactDriver } from 'components/buttons';

import Icons from 'components/icons/index';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import BtnPill from 'components/buttons/BtnPill';
import OfflineMode from 'components/blocks/OfflineMode';
import useMyNavigation from 'hooks/useMyNavigation';
import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import Rating from 'components/blocks/Rating';
import ListItem from 'components/blocks/ListItem';
import CardSection from 'components/cards/CardSection';
import ModalBottom from 'components/modals/ModalBottom';
import { useState } from 'react';
import LayoutReport from 'components/layouts/LayoutReport';
import BtnMiddleAction from 'components/buttons/BtnMiddleAction';
import { PageLoading } from 'pages/index';
import { avatarCircles } from 'constants/styles';
import Roundify from 'components/blocks/Roundify';
import UserDisplay from 'components/blocks/UserDisplay';
import { setStaticPage } from 'store/actions/staticPages';
import { bindActionCreators } from 'redux';
import { analytics } from 'helpers/segment';
import { useEffect } from 'react';
import { handleWhatsApp } from 'constants/translations';

const PageUserView = ({ setStaticPage, auth: { user } }) => {
    const pageData = {
        page: 'app.user',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/profiles/public',
        backBtn: true,
        zIndex: 41,
        checkIdParam: true
    };

    const admin = user?.admin;
    const [isModal, setIsModal] = useState(false);
    const [isModalAction, setIsModalAction] = useState(false);

    const { handleNavigation, location } = useMyNavigation();

    const handleContact = (id) => {
        handleNavigation('app.messages', { otherUser: id });
    };

    // const handleWhatsApp = (phone, name, lang) => {

    //     window?.open(getWhatsAppUrl(phone, 'reachout', name, lang, user?.profile?.firstName), '_blank');
    // };

    useEffect(() => {
        analytics.page('Public User', 'App', user?.isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                // console.log('data', data);

                return (
                    <>
                        <LayoutPageHeader pageData={pageData} pageParams={params} useBrowserHistory={true}></LayoutPageHeader>

                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <LayoutPageBody minHeight={classNames('min-h-body-w-header-footer-2a')}>
                                <div className=''>
                                    {data ? (
                                        <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                            <OfflineMode margins=' mb-6' />
                                            <div
                                                className={classNames(
                                                    'px-vw-6 w-full justify-between flex items-center border-b border-gray-200 pb-vw-6',
                                                    rtl && 'flex-row-reverse'
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                    className={classNames(' flex flex-col')}
                                                >
                                                    <div className={classNames('text-bb-blue-550 text-vw-2xl font-bold')}>
                                                        {admin ? `${data?.firstName} ${data?.lastName}` : data?.firstName}
                                                    </div>
                                                    {data?.displayName && (
                                                        <div className={classNames('text-gray-400 text-vw-base font-bold')}>{`${data?.firstName}`}</div>
                                                    )}
                                                    {/* <div className={classNames('text-gray-400 text-vw-base font-bold')}>45 ans</div> */}
                                                    <UserDisplay
                                                        profile={data}
                                                        noName
                                                        className={classNames('mt-vw-2')}
                                                        banned
                                                        gender={admin}
                                                        phone={admin}
                                                        email={admin ? data?.user?.email : null}
                                                    />
                                                </div>
                                                <button
                                                    onClick={data?.avatar ? () => setStaticPage('publicAvatar', data?.avatar) : null}
                                                    className='focus:outline-none '
                                                >
                                                    <Avatar profile={data} iconSize={'10vw'} size={'w-vw-20 h-vw-20'} />
                                                </button>
                                            </div>

                                            {!data?.user?.blockUser && (
                                                <>
                                                    <CardSection tap={false} border='' borderColor='border-gray-100'>
                                                        <Rating
                                                            className='text-gray-500 font-semibold gap-4 text-vw-lg'
                                                            iconSize='6vw'
                                                            iconClass='text-gray-400'
                                                            rating={data?.rating}
                                                            reviews={data?.totalReviews || null}
                                                            showReviews
                                                        />
                                                    </CardSection>
                                                    <CardSection tap={false} border='border-t' borderColor='border-gray-200'>
                                                        <div
                                                            style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                                            className={classNames('text-gray-500 font-semibold text-vw-base flex gap-2')}
                                                        >
                                                            <span>{translations.data['e.publishedRides']}</span>
                                                            <span>{data?.totalPublished}</span>
                                                        </div>
                                                    </CardSection>
                                                    <div
                                                        className={classNames(
                                                            rtl ? 'items-end' : 'items-start',
                                                            'px-vw-6 w-full flex flex-col border-t-8 border-b-8 border-gray-100 py-vw-6 gap-4'
                                                        )}
                                                    >
                                                        {data?.verifiedProfile && (
                                                            <h2
                                                                style={{
                                                                    direction: rtl ? 'rtl' : 'ltr'
                                                                }}
                                                                className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                                            >
                                                                {`${lang !== 'ar' ? data?.displayName || data?.firstName : ''} ${
                                                                    translations.data['e.verified.profile']
                                                                }`}
                                                            </h2>
                                                        )}
                                                        <ul className={classNames(rtl ? 'items-end' : 'items-start', 'w-full flex flex-col gap-6')}>
                                                            {data?.verifiedMobile && (
                                                                <ListItem icon='Phone1'>{translations.data['e.verified.mobile']}</ListItem>
                                                            )}
                                                            {data?.verifiedEmail && <ListItem>{translations.data['e.verified.email']}</ListItem>}
                                                            {data?.verifiedId && <ListItem icon='Id2'>{translations.data['e.verified.id']}</ListItem>}
                                                            {data?.verifiedCarRegistration && (
                                                                <ListItem icon='Car1'>{translations.data['e.verified.plate']}</ListItem>
                                                            )}
                                                            {/* <ListItem preset='blue'>
                                                                Mobile Verified
                                                            </ListItem> */}
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        // <CardSkeleton rtl={rtl} type='view' />
                                        <PageLoading bg='bg-white' />
                                    )}
                                </div>
                            </LayoutPageBody>

                            {/* {false && (
                                <LayoutPageFooter rtl={rtl} animate={!!data} relative>
                                    <div className={`flex flex-col w-full `}>
                                        <></>
                                    </div>
                                </LayoutPageFooter>
                            )} */}
                            {data && (
                                <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 border-t-a border-gray-200-a`}>
                                    <>
                                        {user?.admin && (
                                            <BtnContactDriver
                                                user
                                                whatsApp
                                                disabled={!online}
                                                params={[data?.mobile, data?.firstName, data?.lang, user?.profile?.firstName]}
                                                handleClick={handleWhatsApp}
                                            />
                                        )}
                                        {!user?.admin && (
                                            <BtnContactDriver
                                                user
                                                disabled={!online || data?.user?.blockUser}
                                                params={[data?.user]}
                                                handleClick={handleContact}
                                            />
                                        )}

                                        {!data?.user?.blockUser && (
                                            <BtnMiddleAction
                                                params={[true]}
                                                handleClick={setIsModal}
                                                disabled={!online}
                                                label='e.chat.reportUser'
                                                icon='Alarm'
                                            />
                                        )}
                                    </>
                                </div>
                            )}
                        </div>

                        <ModalBottom isOpen={isModal} handleOpen={setIsModal} height='h-body' rounded=''>
                            {/* <OfflineMode margins='mt-6 -mb-4' /> */}
                            <div className=' flex flex-col items-center h-full'>
                                <>
                                    {/* <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                        {translations?.data?.['e.sendBookingRequest.headline']}
                                    </div> */}
                                    <LayoutReport reportingUser={user?._id} reportedUser={data?.user} handleModal={setIsModal} showCancel />
                                </>
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    search: state.search,
    staticPages: state.staticPages
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageUserView);
