import { connect } from 'react-redux';

import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { bindActionCreators } from 'redux';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';
import { PageLoading } from 'pages/index';
import IconRound from 'components/blocks/IconRound';
import { setStaticPage } from 'store/actions/staticPages';
import { loadUser, loginSetStep } from 'store/actions/auth';
import { useState, useEffect, useRef } from 'react';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import Uploader from 'components/blocks/Uploader';
import verifyImage from 'components/images/id_verification_01.jpg';
import BtnLoadState from 'components/buttons/BtnLoadState';

import { updateProfile } from 'fetch/apiRequests';
import useMyNavigation from 'hooks/useMyNavigation';
import BtnIcon from 'components/buttons/BtnIcon';
import { widthBiggerThan } from 'helpers/utils';
import { avatarCircles as styles } from 'constants/styles';
import { analytics } from 'helpers/segment';
import AvatarEditor from 'components/blocks/AvatarEditor';
import SwitchSection from 'components/blocks/SwitchSection';
import { updateSearchFilters } from 'store/actions/search';
import DaysOfWeekInline from 'components/calendar/DaysOfWeekInline';
import DaysOfWeekFilter from 'components/calendar/DaysOfWeekFilter';

const PageTripFilters = ({
    updateSearchFilters,
    search: { search, isValid, seats },
    auth: {
        user,
        user: { profile, admin, phoneNumber },
        isAuthenticated
    },
    setStaticPage,
    loadUser
}) => {
    const pageData = {
        page: `app.search.filters`,
        pageRedirect: 'app.searchCarSharing',
        backBtn: true,
        btnIcon: 'Close',
        btnFlip: true,
        zIndex: 41,
        checkIdParam: false
    };

    const { handleNavigation, location } = useMyNavigation();

    const [searchFilters, setSearchFilters] = useState(search?.filters);

    const closeModal = () => {
        setStaticPage(null);
    };

    const handleLadiesOnly = () => {
        let tempFlag;
        if (profile?.gender === 'female' || user?.admin) {
            tempFlag = !searchFilters?.ladiesOnly;
        } else tempFlag = false;

        setSearchFilters((prev) => ({ ...prev, ladiesOnly: tempFlag }));
        // analytics.track('Ladies-Only Search Toggle', { ladiesOnly: tempFlag }, isAuthenticated);
    };

    const handleId = () => {
        let tempFlag = !searchFilters?.verifiedId;
        setSearchFilters((prev) => ({ ...prev, verifiedId: tempFlag }));
    };

    const handleCarRegistration = () => {
        let tempFlag = !searchFilters?.verifiedCarRegistration;
        setSearchFilters((prev) => ({ ...prev, verifiedCarRegistration: tempFlag }));
    };

    const handleRecurring = () => {
        let tempFlag = !searchFilters?.recurring;
        setSearchFilters((prev) => ({ ...prev, recurring: tempFlag }));

        // updateSearchFilters({ recurring: tempFlag });
        // analytics.track('Recurring Search Toggle', { recurring: tempFlag }, isAuthenticated);
    };

    const handlePunctual = () => {
        let tempFlag = !searchFilters?.punctual;
        setSearchFilters((prev) => ({ ...prev, punctual: tempFlag }));
        // let tempFlag = !search?.filters?.punctual;

        // updateSearchFilters({ punctual: tempFlag });
        // analytics.track('Recurring Search Toggle', { recurring: tempFlag }, isAuthenticated);
    };

    const handleArchived = () => {
        let tempFlag = !searchFilters?.archived;
        setSearchFilters((prev) => ({ ...prev, archived: tempFlag }));
        // let tempFlag = !search?.filters?.archived;

        // updateSearchFilters({ archived: tempFlag });
        // analytics.track('Recurring Search Toggle', { archived: tempFlag }, isAuthenticated);
    };

    const handleDaysFilter = (dayIndex, status) => {
        const currentDays = [...searchFilters.dateRange];
        currentDays[dayIndex] = status;
        setSearchFilters((prev) => ({ ...prev, dateRange: currentDays }));
        // updateSearchFilters({ dateRange: currentDays });
        // const currentDays = [...search.filters.dateRange];
        // currentDays[dayIndex] = status;
        // updateSearchFilters({ dateRange: currentDays });
        // handleNavigation('app.offerSeats', null);
    };

    const updateAllFilters = () => {
        updateSearchFilters(searchFilters);
        setStaticPage(null);
    };

    const resetFilters = () => {
        let currentDays = [false, false, false, false, false, false, false];
        let selectedDay = search?.dateRange?.[0] ? new Date(search?.dateRange?.[0])?.getDay() - 1 : null;
        if (selectedDay === -1) selectedDay = 6;
        if (selectedDay) {
            currentDays[selectedDay] = true;
        }

        setSearchFilters({
            ladiesOnly: false,
            punctual: true,
            recurring: true,
            archived: false,
            dateRange: currentDays,
            verifiedId: false,
            verifiedCarRegistration: false
        });
    };

    return (
        <LayoutPage pageData={{ ...pageData, overlay: true }} makeRequest={false}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                // console.log('my verifications', data);

                return (
                    <>
                        <LayoutPageHeader
                            handleButton={closeModal}
                            pageData={pageData}
                            // pageParams={params}
                        ></LayoutPageHeader>

                        <div className={classNames('h-bodya', ' text-vw-base mt-vw-6a  font-semibold text-gray-500')}>
                            <LayoutPageBody minHeighta={classNames('min-h-body-w-header-footer-2')}>
                                <div className={classNames('overflow-y-auto no-scrollbar h-body-w-header-footer-0')}>
                                    {true ? (
                                        <div className={`list-none w-full  bg-white relative ring-8a transition-all duration-300 `}>
                                            <div className={classNames('flex flex-col justify-between', 'relative w-full')}>
                                                <div>
                                                    <OfflineMode margins=' my-6' />
                                                    <div>
                                                        <h2
                                                            style={{
                                                                direction: rtl ? 'rtl' : 'ltr'
                                                            }}
                                                            className={classNames('text-bb-blue-550 text-vw-xl font-bold px-vw-6 mb-vw-4')}
                                                        >
                                                            {translations?.data?.['e.filter.ride.title']}
                                                        </h2>

                                                        <SwitchSection
                                                            icon='Repeat1'
                                                            iconColorPreset={searchFilters?.recurring ? 'light-violet' : 'default'}
                                                            colorOff='light-gray'
                                                            border='border-t'
                                                            label='e.view.recurring'
                                                            visible={true}
                                                            val={searchFilters?.recurring}
                                                            setVal={handleRecurring}
                                                        />

                                                        <SwitchSection
                                                            icon='Calendar2'
                                                            iconColorPreset={searchFilters?.punctual ? 'blue' : 'default'}
                                                            colorOff='light-gray'
                                                            border='border-t'
                                                            label='e.view.punctual'
                                                            visible={true}
                                                            val={searchFilters?.punctual}
                                                            setVal={handlePunctual}
                                                        />
                                                        <SwitchSection
                                                            icon='Calendar3'
                                                            iconColorPreset={searchFilters?.dateRange?.includes(true) ? 'light-blue' : 'default'}
                                                            colorOff='light-gray'
                                                            border='border-t'
                                                            label='e.view.dayOfWeek'
                                                            visible={true}
                                                            noSwitch
                                                            // val={searchFilters?.recurring}
                                                            // setVal={handleRecurring}
                                                        />

                                                        <DaysOfWeekFilter dateRange={searchFilters?.dateRange} handleDays={handleDaysFilter} />
                                                    </div>
                                                    <div className={classNames('border-t-8 border-gray-100 pt-vw-4')}>
                                                        <h2
                                                            style={{
                                                                direction: rtl ? 'rtl' : 'ltr'
                                                            }}
                                                            className={classNames('text-bb-blue-550 text-vw-xl font-bold px-vw-6 mb-vw-4')}
                                                        >
                                                            {translations?.data?.['e.filter.security.title']}
                                                        </h2>

                                                        <SwitchSection
                                                            icon={'Id2'}
                                                            iconColorPreset={searchFilters?.verifiedId ? 'light-green' : 'default'}
                                                            color='on'
                                                            label='e.view.verifiedId'
                                                            border='border-t'
                                                            visible={true}
                                                            val={searchFilters?.verifiedId}
                                                            setVal={handleId}
                                                        />
                                                        <SwitchSection
                                                            icon={'Car1'}
                                                            iconColorPreset={searchFilters?.verifiedCarRegistration ? 'light-green' : 'default'}
                                                            color='on'
                                                            label='e.view.verifiedCar'
                                                            border='border-t'
                                                            visible={true}
                                                            val={searchFilters?.verifiedCarRegistration}
                                                            setVal={handleCarRegistration}
                                                        />
                                                        <SwitchSection
                                                            icon='Female1'
                                                            iconColorPreset={searchFilters?.ladiesOnly ? 'light-pink' : 'default'}
                                                            colorOff='light-gray'
                                                            border='border-t'
                                                            label='e.view.ladiesOnly'
                                                            visible={user?.admin || (profile?.verifiedProfile && profile?.gender === 'female')}
                                                            val={searchFilters?.ladiesOnly}
                                                            setVal={handleLadiesOnly}
                                                        />
                                                    </div>
                                                    {user?.admin && (
                                                        <div className={classNames('border-t-8 border-gray-100 pt-vw-4')}>
                                                            <h2
                                                                style={{
                                                                    direction: rtl ? 'rtl' : 'ltr'
                                                                }}
                                                                className={classNames('text-bb-blue-550 text-vw-xl font-bold px-vw-6 mb-vw-4')}
                                                            >
                                                                {translations?.data?.['e.filter.admin.title']}
                                                            </h2>

                                                            <SwitchSection
                                                                icon='Archive'
                                                                iconColorPreset={searchFilters?.archived ? 'black' : 'default'}
                                                                colorOff='light-gray'
                                                                border='border-t'
                                                                label='e.view.archived'
                                                                visible={user?.admin}
                                                                val={searchFilters?.archived}
                                                                setVal={handleArchived}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <PageLoading bg='bg-white' overlay={true} />
                                    )}
                                </div>
                                <div className={classNames('flex flex-col px-vw-6 pt-vw-4 ')}>
                                    {true && (
                                        <>
                                            <BtnPill
                                                type={'confirmed'}
                                                justify={'justify-center'}
                                                rtl={rtl}
                                                params={[]}
                                                handleClick={resetFilters}
                                                margins='mb-vw-2'
                                                disabled={!online}
                                            >
                                                {translations?.data?.['e.filter.reset']}
                                            </BtnPill>
                                            <BtnPill
                                                type={'primary'}
                                                justify={'justify-center'}
                                                rtl={rtl}
                                                params={[]}
                                                handleClick={updateAllFilters}
                                                margins=''
                                                disabled={!online}
                                            >
                                                {translations?.data?.['e.filter.showRides']}
                                            </BtnPill>
                                        </>
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState,
    search: state.search
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage,
            loginSetStep,
            loadUser,
            updateSearchFilters
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTripFilters);
