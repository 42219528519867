import GetLang from 'hoc/GetLang';
import useMyNavigation from 'hooks/useMyNavigation';
import { getNavParams } from 'helpers/helperFunctions';

const LocationBox = ({ search }) => {
    const { handleNavigation, location } = useMyNavigation();

    const handleNavAction = () => {
        const { toPage, navParams } = getNavParams({
            location,
            page: `app.search`,
            pageParams: null,
            isValid: false
        });
        handleNavigation(toPage, navParams);
    };
    const locFrom = search.from.location.coordinates.length === 0 ? false : true;
    const locTo = search.to.location.coordinates.length === 0 ? false : true;

    return (
        <GetLang>
            {({ rtl }) => (
                <div
                    onClick={handleNavAction}
                    className='px-vw-6 md:px-6 bg-gray-100 w-full h-vw-16 md:h-16 flex items-center rounded-3xl md:rounded-2xl text-vw-sm md:text-sm overflow-hidden select-none md:select-auto'
                >
                    {locFrom || locTo ? (
                        <div className={`w-full overflow-hidden flex flex-col gap-1 ${rtl ? 'items-end' : 'items-start'}`}>
                            <SubLocation source='from' search={search} active={locFrom} />
                            <SubLocation source='to' search={search} active={locTo} />
                        </div>
                    ) : (
                        <div className={`w-full overflow-hidden flex flex-col gap-1 ${rtl ? 'items-end' : 'items-start'}`}>
                            <SubLocation source='from' search={search} active={locFrom} />
                            <SubLocation source='to' search={search} active={locTo} />
                        </div>
                    )}
                </div>
            )}
        </GetLang>
    );
};

export default LocationBox;

const SubLocation = ({ search, source, active }) => {
    // console.log('search', search);
    return (
        <GetLang>
            {({ rtl, translations, tripLang }) => (
                <div className={`h-vw-4 md:h-4 flex items-center`}>
                    {active ? (
                        <div className={`capitalize truncate ${rtl ? 'text-right' : 'text-left'}`}>
                            {search?.[source]?.[tripLang]?.formatted || translations?.data?.['e.anywhere']}
                        </div>
                    ) : (
                        <div className={`${source === 'from' ? 'w-vw-16 md:w-20' : 'w-vw-20 md:w-24'} h-vw-2 md:h-2 rounded-full bg-gray-300`}></div>
                    )}
                </div>
            )}
        </GetLang>
    );
};
