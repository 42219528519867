import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as LogoFullWhite } from 'components/svg/logo_full_white.svg';
import IconRound from 'components/blocks/IconRound';
import HeadlineType1 from 'components/blocks/HeadlineType1';
import Paragraph from 'components/blocks/Paragraph';

import { setStaticPage } from 'store/actions/staticPages';

import GetLang from 'hoc/GetLang';
import { useEffect, useState } from 'react';
import { analytics } from 'helpers/segment';
import LottieBlock from 'components/blocks/LottieBlock';
import DesktopAlternate from 'components/blocks/DesktopAlternate';

import useWindowSize from 'hooks/useWindowSize';

const PageSocialDone = ({ setStaticPage }) => {
    const { ratio } = useWindowSize();

    useEffect(() => {
        analytics.page('Social Link Page - Sent');
    }, []);

    return (
        <GetLang>
            {({ rtl, lang, translations }) => (
                <>
                    <DesktopAlternate />
                    <div className={classNames('bg-bb-blue-900 flex flex-col justify-between min-h-screen-100 h-full bg-cover sm:hidden')}>
                        <div className={classNames('text-white text-vw-4xl md:text-4xl bg-bb-blue-900 relative')}>
                            <div className={classNames(ratio < 0.5 ? 'h-vh-28' : 'h-vh-24', 'w-full  md:h-128')}>
                                {/* <div className={classNames('w-full h-screen-1/3')}> */}
                                <div className='absolute inset-x-0 top-0 h-screen-2/5 overflow-hidden'>
                                    <LottieBlock speed={0.35} />
                                </div>
                                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 `}>
                                    <LogoFullWhite />
                                </div>
                            </div>
                        </div>

                        <div className={classNames('z-10 bg-white rounded-t-3xl')}>
                            <div className={classNames(ratio < 0.5 ? 'min-h-vh-72' : 'min-h-vh-72', 'relative h-full text-vw-base ')}>
                                <IconRound icon='Whatsapp' preset='yellow' />

                                <div
                                    className={classNames('px-vw-8 pt-vw-12 pb-vw-6 h-full flex flex-col justify-between')}
                                    style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                >
                                    <div>
                                        <HeadlineType1 label={translations.data['install.socialTitleSent']} textSize='text-vw-2xl' margins='mb-vw-4' />

                                        <div className={classNames('flex flex-col items-center')}>
                                            <div className='w-full'>
                                                <Paragraph preset='small-faded' margins='mb-vw-8' align='justify-left' className='static' color='text-gray-900'>
                                                    <div
                                                        className={'static'}
                                                        dangerouslySetInnerHTML={{
                                                            __html: translations.data['install.socialDone']
                                                            // content.introBody
                                                        }}
                                                    />
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classNames('w-full flex flex-col justify-center items-center')}>
                                        <div className='text-vw-sm static'>
                                            {`Copyright ${new Date().getFullYear()} - `}
                                            <strong>
                                                <u>
                                                    <a href='https://balabenzine.com' target='_blank'>
                                                        Bala Benzine
                                                    </a>
                                                </u>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    staticPages: state.staticPages,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSocialDone);
