import classNames from 'classnames';
import { Navigate, Link } from 'react-router-dom';
import Spinner from 'components/blocks/Spinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LOGOUT } from 'store/actions/types';
import { genericAction } from 'store/actions/generic';
import { motion } from 'framer-motion';
import LoginFlowFinished from 'pages/auth/LoginFlowFinished';
import LoginFlowNew from 'pages/auth/LoginFlowNew';
import LoginFlowStart from 'pages/auth/LoginFlowStart';
import LoginFlowVerify from 'pages/auth/LoginFlowVerify';
import { clearLoginErrors, loginSetStep } from 'store/actions/auth';
import { useEffect, useState } from 'react';
import { routePath } from 'constants/structRoutes';
import MotionDiv from 'components/blocks/MotionDiv';
import { PageLoading } from 'pages';
import { ReactComponent as LogoFullWhite } from 'components/svg/logo_full_white.svg';
import { ReactComponent as BGType1 } from 'components/svg/bg_type1.svg';
import { animations, getAnimStates, getReducedMotion } from 'constants/animations';
import LoginFlowLanguage from './LoginFlowLanguage';
import { loadNamedState } from 'store/localStorage';
import GetLang from 'hoc/GetLang';
import BtnIcon from 'components/buttons/BtnIcon';
import { defaultNewUserInfo } from 'constants/defaultObjects';
import { getGlobalSettings } from 'fetch/apiRequests';
import imageInstall1 from 'components/images/bg_search1.svg';
import LottieBlock from 'components/blocks/LottieBlock';
import DesktopAlternate from 'components/blocks/DesktopAlternate';

// 'vw-160': '160vw',
//                 'vw-168': '168vw',
//                 'vw-174': '174vw',
//                 'vw-182': '182vw',
//                 'vw-190': '190vw',

const modalState = {
    language: {
        height: 'h-vw-100'
    },
    start: {
        height: 'h-vw-128'
    },
    startError: {
        height: 'h-vw-136'
    },
    newUser: {
        height: 'h-vw-174'
    },
    blocked: {
        height: 'h-vw-72'
    },
    verifyOtp: {
        height: 'h-vw-96'
    },
    verifyOtpError: {
        height: 'h-vw-120'
    },
    verifyOtpErrorRetry: {
        height: 'h-vw-88'
    },
    finished: {
        height: 'h-vw-88'
    }
};

const loginSteps = [
    {
        name: 'language',
        backSteps: 0
    },
    {
        name: 'start',
        backSteps: 1
    },
    {
        name: 'startError',
        backSteps: 2
    },
    {
        name: 'newUser',
        backSteps: 1
    },
    {
        name: 'verifyOtp',
        backSteps: 2
    },
    {
        name: 'verifyOtpError',
        backSteps: 3
    },
    {
        name: 'finished',
        backSteps: 4
    },
    'language',
    'start',
    'newUser',
    'verifyOtp',
    'verifyOtpError',
    'finished'
];

const PageAuthLogin = ({ genericAction, clearLoginErrors, loginSetStep, auth, auth: { loading, setupStep }, globalState: { lang, reducedMotion } }) => {
    const { animPages } = getAnimStates('app.login', null, false);

    const [info, setInfo] = useState(defaultNewUserInfo());

    const [allowedPhones, setAllowedPhones] = useState(null);

    const handleStep = () => {
        const stepIndex = loginSteps.findIndex((item) => item.name === setupStep);
        const stepObj = loginSteps[stepIndex];
        stepIndex > 0 && loginSetStep(loginSteps[stepIndex - stepObj.backSteps].name);
    };

    const loadGlobalSettings = async () => {
        const res = await getGlobalSettings();
        // console.log(res);
        setAllowedPhones(res.body);
    };

    useEffect(() => {
        console.log('AuthLogin loaded');
        loadGlobalSettings();

        loginSetStep('language');

        clearLoginErrors();
    }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (auth.isAuthenticated === false) {
    //             genericAction({ type: LOGOUT });
    //         }
    //     }, 1500);
    // }, []);

    useEffect(() => {
        if (setupStep === 'start') setInfo(defaultNewUserInfo());
    }, [setupStep]);

    if (auth.isAuthenticated == null) {
        return <PageLoading />;
    }

    if (auth.isAuthenticated && !auth.loading && auth.setupStep === 'language') {
        return <Navigate to={routePath('app.searchCarSharing')} />;
    }

    const realHeight = () => {
        if (auth.setupStep === 'verifyOtpError' && auth.error?.[0] === 'Auth.otp.verify.error.status.17') return modalState['verifyOtpErrorRetry']?.height;
        return modalState[setupStep]?.height;
    };

    return (
        <GetLang>
            {({ rtl, lang, translations }) => (
                <>
                    <DesktopAlternate />
                    <div
                        key={'PageAuthLogin'}
                        className={classNames('sm:hidden bg-bb-blue-900 flex flex-col justify-between min-h-screen-100 h-full relative bg-cover')}
                        // style={{
                        //     backgroundImage: `url(${imageInstall1})`
                        //     // zIndex: '-1'
                        // }}
                    >
                        <div className='absolute object-cover w-screen-100 h-screen-100 overflow-hidden'>
                            {/* <img src={`${process.env.PUBLIC_URL}/assets/images/bg_type1.svg`} /> */}

                            <LottieBlock speed={0.35} />
                        </div>

                        <div className={classNames('z-100')}>
                            <div className='w-full min-h-vw-16 '>
                                <div className={`py-vw-2 px-vw-2 h-full flex ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center justify-between gap-4`}>
                                    {setupStep !== 'language' && setupStep !== 'finished' && (
                                        <BtnIcon icon={'ArrowLeftLong1'} handleClick={handleStep} flip={rtl} color='text-white' noBg />
                                    )}
                                </div>
                            </div>

                            <div className={` flex justify-center w-full h-vw-40 items-center-a `}>
                                <LogoFullWhite />
                            </div>
                        </div>
                        <div className={classNames(' rounded-t-3xl-a overflow-y-auto-a h-max')}>
                            <div
                                key={'startModal'}
                                initial={'bottom'}
                                animate={'in'}
                                exit={'bottom'}
                                variants={animations.pageSlides}
                                className={classNames('bg-white rounded-t-3xl transform transition-all duration-400 ease-in-out ', realHeight() || 'h-max ')}
                            >
                                {loading && (
                                    <motion.div className='z-50 absolute inset-0 bg-white rounded-t-3xl' key='loginLoading'>
                                        <div className='z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-4'>
                                            <Spinner size={'10vw'} />
                                        </div>
                                    </motion.div>
                                )}
                                {setupStep === 'language' && (
                                    <MotionDiv myKey={'language'}>
                                        <LoginFlowLanguage />
                                    </MotionDiv>
                                )}
                                {(setupStep === 'start' || setupStep === 'startError') && (
                                    <MotionDiv myKey={'start'}>
                                        <LoginFlowStart allowed={allowedPhones} />
                                    </MotionDiv>
                                )}
                                {setupStep === 'newUser' && (
                                    <MotionDiv myKey={'newUser'}>
                                        <LoginFlowNew info={info} setInfo={setInfo} />
                                    </MotionDiv>
                                )}
                                {(setupStep === 'verifyOtp' || setupStep === 'verifyOtpError') && (
                                    <MotionDiv myKey={'verifyOtp'}>
                                        <LoginFlowVerify info={info} />
                                    </MotionDiv>
                                )}
                                {setupStep === 'finished' && (
                                    <MotionDiv myKey={'finished'}>
                                        <LoginFlowFinished />
                                    </MotionDiv>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            genericAction,
            clearLoginErrors,
            loginSetStep
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageAuthLogin);
