import usePlacesAutocomplete, { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';

const localityByNeighborhood = {
    mkalless: 'mkalless',
    hamra: 'beirut',
    achrafieh: 'beirut',
    spears: 'beirut',
    bliss: 'beirut',
    spears: 'beirut',
    hamra: 'beirut',
    achrafieh: 'beirut',
    basta: 'beirut',
    snoubra: 'beirut',
    'tallet el khayyat': 'beirut',
    'tallet druze': 'beirut',
    verdun: 'beirut',
    bachoura: 'beirut',
    msaytbeh: 'beirut',
    'al zarif': 'beirut',
    'ain el mraiseh': 'beirut',
    raoucheh: 'beirut',
    manara: 'beirut',
    kraytem: 'beirut',
    'ramleh al bayda': 'beirut',
    'sin el fil': 'beirut',
    sanayeh: 'beirut',
    'charles helou': 'beirut',
    saray: 'beirut',
    mazraa: 'beirut',
    'bourj abi haydar': 'beirut',
    barbir: 'beirut',
    qasqas: 'beirut',
    "mala'ab": 'beirut',
    'watah al msaytbeh': 'beirut',
    'tarik el jdideh': 'beirut',
    'tariq el jdideh': 'beirut',
    'bir hasan': 'beirut',
    'forn el chebbak': 'beirut',
    'al kantari': 'beirut',
    sioufi: 'beirut',
    'hotel dieu': 'beirut',
    'abou samra': 'tripoli',
    'sahet al nour': 'tripoli',
    maarad: 'tripoli',
    qoubbeh: 'tripoli',
    tabbaneh: 'tripoli',
    'al tal': 'tripoli',
    zahriyeh: 'tripoli',
    haddadin: 'tripoli',
    azmi: 'tripoli',
    hadid: 'tripoli',
    zaytoun: 'tripoli',
    'ras beirut': 'beirut',
    jnah: 'beirut'
};

const adminl2ByLocality = {
    beirut: 'beirut',
    mkalles: 'mkalless',
    ghobeiry: 'baabda',
    'haret hreik': 'baabda',
    hadath: 'baabda',
    baabdat: 'matn',
    jamhour: 'baabda',
    louaizeh: 'baabda',
    kfarshima: 'baabda',
    faiyadiyeh: 'baabda',
    yarzeh: 'baabda',
    hazmieh: 'baabda',
    'jisr el bacha': 'matn',
    saloumi: 'beirut',
    'bourj hammoud': 'matn',
    zalka: 'matn',
    tripoli: 'tripoli',
    mina: 'tripoli',
    bohssas: 'tripoli',
    'tannourine el tahta': 'batroun',
    chekka: 'batroun',
    hamat: 'batroun',
    qalamoun: 'tripoli',
    beddaoui: 'minieh - danniyeh'
};

const adminl1ByAdminl2 = {
    tripoli: 'north governorate',
    koura: 'north governorate',
    beirut: 'beirut governorate',
    matn: 'mount lebanon governorate',
    baabda: 'mount lebanon governorate',
    jbeil: 'mount lebanon governorate',
    kesrouane: 'mount lebanon governorate',
    batroun: 'north governorate',
    saida: 'south governorate',
    sour: 'south governorate',
    'minieh - danniyeh': 'north governorate'
};

// key pairs for dictionary. Value should be standardized and in english
const wordByTypo = {
    '،': ',',
    'محافظة جبل لبنان': 'mount lebanon governorate',
    لبنان: 'lebanon',
    liban: 'lebanon',
    bayrut: 'beirut',
    beyrouth: 'beirut',
    bejrut: 'beirut',
    'راس بيروت': 'ras beirut',
    'محافظة بيروت': 'beirut governorate',
    'محافظة البقاع': 'beqaa governorate',
    'محافظة الجنوب': 'south governorate',
    بيروت: 'beirut',
    syoufi: 'sioufi',
    'jabal lubnan': 'mount lebanon governorate',
    'district de tripoli': 'tripoli',
    "qada'a tarablus": 'tripoli',
    trablos: 'tripoli',
    tarablus: 'tripoli',
    طرابلس: 'tripoli',
    barbeer: 'barbir',
    bahsas: 'bohssas',
    البحصاصة: 'bahssasa',
    بحصاص: 'bohssas',
    الكورة: 'koura',
    'محافظة الشمال': 'north governorate',
    zalqa: 'zalka',
    كوسبا: 'kousba',
    qobeh: 'qoubbeh',
    qobbeh: 'qoubbeh',
    القبة: 'qoubbeh',
    قبة: 'qoubbeh',
    'أبو سمرا': 'abou samra',
    'ras masqa el chmeliyeh': 'ras masqa',
    'ras masqa el jnoubiyeh': 'ras masqa',
    'ras maska': 'ras masqa',
    'رأس مسقا الشمالية': 'ras masqa',
    'رأس مسقا الجنوبية': 'ras masqa',
    'رأس مسقا': 'ras masqa',
    'basta el fawqa': 'basta',
    'basta al tahta': 'basta',
    sidon: 'saida',
    sayda: 'saida',
    صيدا: 'saida',
    tyre: 'sour',
    صور: 'sour',
    بعبدا: 'baabda',
    'beirut-haret hreik': 'haret hreik'
};

// translation for dictionary value. Values can come directly from the google result.
const wordTranslations = {
    beirut: 'بيروت',
    tripoli: 'طرابلس',
    'beirut governorate': 'محافظة بيروت',
    'mount lebanon governorate': 'محافظة جبل لبنان',
    'north governorate': 'محافظة الشمال',
    qoubbeh: 'القبة',
    'abou samra': 'أبو سمرا',
    'ras masqa': 'رأس مسقا',
    hamra: 'الحمرا',
    'ras beirut': 'راس بيروت',
    basta: 'البسطة',
    'al kantari': 'القنطاري',
    clemenceau: 'شارع كليمنصو',
    bliss: 'بلس',
    spears: 'سبيرز',
    'mar elias st': 'مار الياس',
    'beqaa governorate': 'محافظة البقاع',
    'south governorate': 'محافظة الجنوب',
    saida: 'صيدا',
    sour: 'صور',
    baabda: 'بعبدا',
    hadath: 'حدث',
    'haret hreik': 'حارة حريك',
    jnah: 'جناح',
    snoubra: 'صنوبرة',
    kraytem: 'قريطم',
    'al zarif': 'الظريف',
    'tallet druze': 'تل الدروز',
    'dar al fatwah': 'دار الفتوى',
    verdun: 'فردان',
    'tallet el khayyat': 'تلة الخياط'
};

const typoReplace = (str) => {
    let replacementString = str.toLowerCase();
    Object.keys(wordByTypo).forEach((keyStr) => {
        replacementString = replacementString.replace(keyStr, wordByTypo[keyStr]);
    });
    return replacementString;
};

const locationFindType = (arr, type) => {
    let arrType;
    if (!Array.isArray(type)) arrType = [type];
    else arrType = [...type];

    const foundItem = arr.find((item) => item.types.some((subType) => arrType.includes(subType)));

    const foundString = (foundItem?.long_name ?? foundItem?.short_name ?? '').toLowerCase();

    return foundString;
};

const buildLocationObj = (obj, formatted_address, coordinates) => {
    const searchArr = obj?.address_components;

    const formatted = typoReplace(formatted_address);
    const precise = '';
    // replace typos in address from dictionary
    const address = typoReplace(locationFindType(searchArr, ['neighborhood', 'route']));
    // console.log(
    //     'address before:',
    //     locationFindType(searchArr, ['neighborhood', 'route'])
    // );
    // console.log('address after typo:', address);

    // check first if locality ref exists in dic if not, just get it from the component and fix any typos
    const locality = localityByNeighborhood[address] ?? typoReplace(locationFindType(searchArr, ['locality', 'administrative_area_level_3']));
    const admin_l2 = adminl2ByLocality[locality] ?? typoReplace(locationFindType(searchArr, 'administrative_area_level_2'));
    const admin_l1 = adminl1ByAdminl2[admin_l2] ?? typoReplace(locationFindType(searchArr, 'administrative_area_level_1'));

    return {
        location: {
            type: 'Point',
            coordinates, // lng , lat . Google is lat, lng
            zoom: 8
        },
        place_id: obj.place_id,
        plus_code: obj?.plus_code?.global_code,
        en: {
            formatted,
            precise: '', // plus_code or
            address,
            locality,
            admin_l2,
            admin_l1
        },
        ar: {
            formatted,

            precise: '',
            address: wordTranslations[address] ?? address,
            locality: wordTranslations[locality] ?? locality,
            admin_l2: wordTranslations[admin_l2] ?? admin_l2,
            admin_l1: wordTranslations[admin_l1] ?? admin_l1
        }
    };
};

export const buildLocationFromAddress = async (address, place_id) => {
    try {
        const query = {};
        if (place_id) query.placeId = place_id;
        else query.address = address;
        const results = await getGeocode(query);
        // const details = await getDetails({ placeId: place_id });
        const { lat, lng } = await getLatLng(results[0]);
        // console.log('address', address);
        // console.log('results', results);
        // console.log('place_id', place_id);
        // console.log('details', details);

        return buildLocationObj(results[0], address, [lng, lat]);
    } catch (error) {
        // console.log('Error: ', error);
    }
};
