import { LayoutPage, LayoutPageBody, LayoutPageFooter } from 'components/layouts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { motion, LayoutGroup } from 'framer-motion';
import { CardOffered } from 'components/cards';
import CardSkeleton from 'components/skeletons/CardSkeleton';
import { animations, transitions, animStates2 } from 'constants/animations';
import TabRides from 'components/tabs/TabRides';

import useMyNavigation from 'hooks/useMyNavigation';
import Cloner from 'components/blocks/Cloner';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';
import MessageBox from 'components/blocks/MessageBox';
import NoResults from 'components/blocks/NoResults';

import Icons from 'components/icons/index';
import BtnPill from 'components/buttons/BtnPill';
import BtnLoadState from 'components/buttons/BtnLoadState';
import ModalBottom from 'components/modals/ModalBottom';
import BtnRequestCancel from 'components/buttons/BtnRequestCancel';
import { useState, useEffect, useRef } from 'react';
import { updateRideStatus } from 'fetch/apiRequests';
import useScroll from 'hooks/useScroll';
import { setScroll } from 'store/actions/globalState';
import BtnScroll from 'components/buttons/BtnScroll';
import BtnMiddleAction from 'components/buttons/BtnMiddleAction';
import { analytics } from 'helpers/segment';

const pageData = {
    page: 'app.rides.offered',
    pageRedirect: 'app.searchCarSharing',
    apiUrl: '/rides/me',
    backBtn: true,
    zIndex: 38,
    checkIdParam: false,
    ridePath: 'app.rides.offer',
    resultsPerPage: 20
};

const { resultsPerPage } = pageData;

const PageRidesOffered = ({ auth: { isAuthenticated }, onboarding: { tooltips }, globalState: { scroll }, setScroll }) => {
    const [isModal, setIsModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);
    const ref = useRef(null);
    useEffect(() => {
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    // useEffect(() => {
    //     console.log(currentId);
    // }, [currentId]);

    const { handleNavigation, location } = useMyNavigation();

    const handleButton = () => {
        setScroll(null);
        handleNavigation('app.rides.offered.archived', null);
    };

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const handleAction = async (mutateData, status) => {
        if (currentId) {
            setIsModalAction(true);
            setIsModal(false);
            await updateRideStatus({ rideId: currentId, status });
            await mutateData();
            setIsModalAction(false);
            setMode(null);
        }
    };

    useEffect(() => {
        if (scroll?.page === 'app.rides.offered') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    // console.log('rendering: List Published');

    useEffect(() => {
        analytics.page('Published Rides List', 'App', isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={pageData} infinite={true}>
            {({
                rtl,
                lang,
                location,
                animStates,
                translations,
                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                size,
                setSize,
                handleNavigation,
                reducedMotion,
                getReducedMotion,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.count,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                const isEmpty = newData?.count === 0;
                const isReachingEnd = newData?.count === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('data is', data);

                return (
                    <>
                        <TabRides initial='offered' />
                        <div ref={ref} className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <BtnScroll containerRef={ref} page={'app.rides.offered'} />
                            <LayoutPageBody minHeight={''}>
                                <div className=''>
                                    <LayoutGroup>
                                        <div>
                                            <OfflineMode />
                                            {tooltips?.ridesOffered && (
                                                <motion.div layout='position' className='py-vw-4 last:pb-0a'>
                                                    <MessageBox noTitle id='ridesOffered' label=''>
                                                        {translations?.data?.['e.offered.section.subtitle']}
                                                    </MessageBox>
                                                </motion.div>
                                            )}
                                        </div>
                                        <motion.div layout='position' transition={transitions.listAdjust}>
                                            {/* {!isEmpty && (
                                                <div className={''}>
                                                    <div
                                                        className={classNames(
                                                            'bg-bb-blue-50a text-bb-blue-550 font-bold text-xl px-vw-6 py-vw-6 text-center'
                                                        )}
                                                    >
                                                        You have {newData.count}{' '}
                                                        upcoming rides
                                                    </div>
                                                </div>
                                            )} */}
                                            <motion.ul
                                                key='offeredList'
                                                className={`px-vw-6a flex flex-col items-center`}
                                                initial={getReducedMotion('card', animStates.animCards, reducedMotion).initial}
                                                animate={getReducedMotion('card', animStates.animCards, reducedMotion).animate}
                                                variants={animations.staggerFromTop}
                                            >
                                                {!isModalAction && newData?.count != null ? (
                                                    newData?.count > 0 ? (
                                                        newData?.data?.map?.((item) => {
                                                            return (
                                                                <CardOffered
                                                                    scrollTop={ref?.current?.scrollTop}
                                                                    setMode={setMode}
                                                                    setCurrentId={setCurrentId}
                                                                    data={item}
                                                                    key={item._id}
                                                                    rtl={rtl}
                                                                    lang={lang}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <motion.div
                                                            className='w-full px-vw-6 py-vw-6'
                                                            initial={getReducedMotion('results', animStates2.results, reducedMotion).initial}
                                                            animate={getReducedMotion('results', animStates2.results, reducedMotion).animate}
                                                            variants={animations.elementSlides}
                                                        >
                                                            <NoResults title='e.nothingFound.offered.title' description='e.nothingFound.offered.description' />

                                                            <div className={classNames('px-vw-10a flex items-center justify-center w-max mx-auto')}>
                                                                <BtnPill
                                                                    paddingX='px-vw-8'
                                                                    type='primary'
                                                                    handleClick={() => handleNavigation('app.offerSeats', null)}
                                                                    disabled={!online}
                                                                    justify={'justify-between'}
                                                                    rtl={rtl}
                                                                >
                                                                    <Icons icon={'PublishPlus'} />
                                                                    {translations?.data?.['e.nothingFound.offered.button']}
                                                                    {/* <span className='w-6' /> */}
                                                                </BtnPill>
                                                            </div>
                                                        </motion.div>
                                                    )
                                                ) : (
                                                    <Cloner clones={4}>
                                                        <CardSkeleton rtl={rtl} type='offered' />
                                                    </Cloner>
                                                )}
                                            </motion.ul>
                                            {!isReachingEnd && !isModalAction && online && (
                                                <div className={classNames('px-vw-6 py-vw-4')}>
                                                    <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} />
                                                </div>
                                            )}
                                        </motion.div>
                                    </LayoutGroup>
                                </div>
                            </LayoutPageBody>
                            <div className={`relative flex flex-col items-center w-full py-vw-4 px-vw-6 border-t border-gray-200`}>
                                {/* <BtnPill type='confirmed' handleClick={handleButton} justify={'justify-between'} rtl={rtl}>
                                    <span className='w-vw-6' />

                                    {translations?.data?.['e.offered.archived.view']}
                                    <Icons icon={'Archive'} />
                                </BtnPill> */}
                                <BtnMiddleAction label='e.offered.archived.view' handleClick={handleButton} />
                            </div>
                        </div>
                        <ModalBottom
                            isOpen={isModal}
                            handleOpen={handleModalClose}
                            // height=''
                        >
                            <div className='px-vw-6 py-vw-6 flex flex-col items-center '>
                                <OfflineMode margins='' />
                                {mode === 'CANCEL_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.cancelRide.headline']}
                                        </div>
                                        <BtnRequestCancel disabled={!online} handleClick={() => handleAction(mutateData, 'CANCELED')}>
                                            {translations?.data?.['e.cancelRide']}
                                        </BtnRequestCancel>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'PUBLISH_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.setToPublish.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'PUBLISHED')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.setToPublish']}
                                                <Icons icon={'UploadCloud'} size={'5.8vw'} flip={rtl} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DELETE_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.delete.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='cancel'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'DELETE')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.delete']}
                                                <Icons icon={'Trash'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.[lang === 'ar' ? 'e.later' : 'e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'DRAFT_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.setToDraft.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'DRAFT')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.setToDraft']}
                                                <Icons icon={'Unpublish'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                                {mode === 'ARCHIVE_RIDE' && (
                                    <>
                                        <div className='px-vw-2 mt-vw-4 mb-vw-6 font-semibold text-vw-lg text-center text-gray-600'>
                                            {translations?.data?.['e.ride.status.archive.headline']}
                                        </div>
                                        <BtnPill
                                            disabled={!online}
                                            type='primary'
                                            justify={'justify-between'}
                                            rtl={rtl}
                                            params={[false]}
                                            handleClick={() => handleAction(mutateData, 'ARCHIVE')}
                                        >
                                            <>
                                                <span className='w-6' />
                                                {translations?.data?.['e.ride.status.archive']}
                                                <Icons icon={'Archive'} size={'5.8vw'} />
                                            </>
                                        </BtnPill>
                                        <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                            {translations?.data?.['e.later']}
                                        </BtnPill>
                                    </>
                                )}
                            </div>
                        </ModalBottom>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    onboarding: state.onboarding,
    auth: state.auth
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PageRidesOffered);
