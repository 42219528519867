import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnContactDriver = ({ params, handleClick, passenger, disabled, whatsApp, user }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        justify={'justify-between'}
                        rtl={rtl}
                        params={params}
                        disabled={disabled}
                        handleClick={handleClick}
                        type={whatsApp ? 'green' : 'default'}
                    >
                        <>
                            <span className='w-6' />
                            {translations?.data?.[user ? 'e.contactUser' : passenger ? 'e.contactPassenger' : 'e.contactDriver']}
                            <Icons icon={whatsApp ? 'Whatsapp' : 'Messages1'} />
                        </>
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnContactDriver;
