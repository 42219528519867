import { widthBiggerThan } from 'helpers/utils';
import { routePath } from './structRoutes';

export const getSlideAnimation = (rtl, shouldSlide) => {
    return shouldSlide
        ? {
              initial: { x: rtl ? -50 : 50, opacity: 0 },
              animate: { x: 0, opacity: 1 },
              transition: { ease: [0.6, 0, 0, 1], duration: 0.4, delay: 0 }
          }
        : {};
};

export const animations = {
    pageSlides: {
        none: false,

        static: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        fadeOut: {
            opacity: 0,
            transition: {
                opacity: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        delayedIn: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { delay: 0.2, duration: 0.4, ease: [0.6, 0, 0, 1] },
                opacity: { delay: 0.2, duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        in: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] },
                opacity: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        inField: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] },
                x: { duration: 0.4, ease: 'anticipate' }
                // opacity: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        inFieldDelayed: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] },
                x: { duration: 0.4, ease: 'anticipate' },
                delay: 0.1
                // opacity: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        topFast: {
            x: 0,
            y: '-5vh',
            opacity: 0,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        bottomFast: {
            x: 0,
            y: '5vh',
            opacity: 0,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },

        bottom: {
            x: 0,
            y: '100vh',
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        bottomMenu: {
            x: 0,
            y: '20vw',
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        top: {
            x: 0,
            y: '-100vh',
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        menuRight: {
            x: widthBiggerThan(1024) ? '384px' : widthBiggerThan() ? '512px' : '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        menuLeft: {
            x: widthBiggerThan(1024) ? '-384px' : widthBiggerThan() ? '-512px' : '-100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        right: {
            x: '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        left: {
            x: '-100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        rightField: {
            x: '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.2, ease: [0.6, 0, 0, 1] }
            }
        },
        leftField: {
            x: '-100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.2, ease: [0.6, 0, 0, 1] }
            }
        },
        quickRight: {
            x: '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.6 }
            }
        },
        quickLeft: {
            x: '-100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.6 }
            }
        }
    },
    elementSlides: {
        static: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        in: {
            x: 0,
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        topFast: {
            x: 0,
            y: '-20',
            opacity: 0,
            transition: {
                y: { duration: 0.3, ease: [0.6, 0, 0, 1] }
            }
        },
        topFastNoFade: {
            x: 0,
            y: '-20',
            opacity: 1,
            transition: {
                y: { duration: 0.3, ease: [0.6, 0, 0, 1] }
            }
        },
        bottomFast: {
            x: 0,
            y: '20',
            opacity: 0,
            transition: {
                y: { duration: 0.3, ease: [0, 0, 0, 1] }
            }
        },
        footerOut: {
            x: 0,
            y: '200',
            opacity: 1,
            transition: {
                y: { duration: 0.3, ease: [0, 0, 0, 1] }
            }
        },
        bottomFastNoFade: {
            x: 0,
            y: '20',
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0, 0, 0, 1] }
            }
        },
        bottom: {
            x: 0,
            y: '100vh',
            opacity: 1,
            transition: {
                y: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        menuRight: {
            x: widthBiggerThan(1024) ? '384px' : widthBiggerThan() ? '512px' : '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        menuLeft: {
            x: widthBiggerThan(1024) ? '-384px' : widthBiggerThan() ? '-512px' : '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        right: {
            x: '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        },
        left: {
            x: '100vw',
            y: 0,
            opacity: 1,
            transition: {
                x: { duration: 0.4, ease: [0.6, 0, 0, 1] }
            }
        }
    },
    result: {
        in: {
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.2, ease: [0, 0, 0, 1] }
            }
        },
        out: {
            y: 20,
            opacity: 1,
            transition: {
                y: { duration: 0.3, ease: [0, 0, 0, 1] }
            }
        }
    },
    staggerFromTop: {
        static: {
            transition: { staggerChildren: 0, delayChildren: 0 }
        },
        in: {
            transition: { staggerChildren: 0.05, delayChildren: 0 }
        },
        topFast: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        },
        bottomFast: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        },
        bottom: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        },
        right: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        },
        left: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        },
        out: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        }
    },
    navItem: {
        in: {
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.2, ease: [0, 0, 0, 1] }
            }
        },
        out: {
            y: -24,
            opacity: 1,
            transition: {
                y: { duration: 0.3, ease: [0, 0, 0, 1] }
            }
        }
    },

    card: {
        in: {
            y: 0,
            opacity: 1,
            transition: {
                y: { duration: 0.3, ease: [0, 0, 0, 1] }
            }
        },
        out: {
            y: '15vh',
            opacity: 0,
            transition: {
                y: { duration: 0.3, ease: [0, 0, 0, 1] }
            }
        },
        on: {
            scale: 1.02
        },
        off: {
            scale: 1
        }
    },
    tap: {
        on: {
            opacity: 1
        },
        off: {
            opacity: 0
        }
    }
};

export const transitions = {
    listAdjust: {
        type: 'spring',
        damping: 25,
        stiffness: 200
    }
};

export const animStates2 = {
    results: { initial: 'bottomFast', animate: 'in', exit: 'bottomFast' }
};

export const getReducedMotion = (element, animState, reducedMotion) => {
    switch (reducedMotion) {
        case 10:
            return { initial: false, animate: false, exit: false };
        case 5:
            if (element === 'results') return { initial: 'static', animate: 'static', exit: 'static' };
            if (element !== 'page') return { initial: false, animate: false, exit: false };
            return {
                initial: animState.initial,
                // initial: false,
                animate: animState.animate,
                exit: false
            };
        case 0:
            return {
                initial: animState?.initial,
                animate: animState?.animate,
                exit: animState?.exit
            };
        default:
            return {
                initial: animState?.initial,
                animate: animState?.animate,
                exit: animState?.exit
            };
    }
};

export const getAnimStates = (page, location, rtl) => {
    const animPages = { initial: 'in', animate: 'in', exit: 'in' };
    const animCards = {
        initial: 'out',
        animate: 'in'
    };

    const to = location?.state?.to;
    const from = page === 'app.user' && location?.state?.fromChat ? '/c/messages' : location?.state?.from;

    // console.log('page', page);
    // console.log('from', from);
    // console.log('to', to);
    // console.log('location', location);
    // console.log('===============================');

    switch (page) {
        case 'app.settings':
        case 'app.bugs':
            animPages.initial = 'bottom';
            animPages.exit = 'bottom';
            break;

        case 'app.register':
        case 'app.login':
            animPages.initial = 'bottomFast';
            animPages.exit = 'topFast';
            break;
        case 'app.searchCarSharing':
            if (from === '/c/search' || from === '/c/trip' || from === '/c/book') {
                animPages.initial = 'topFast';
            } else if (
                from === '/c/search-car-sharing/from' ||
                from === '/c/search-car-sharing/to' ||
                from === '/c/search-car-sharing/seats' ||
                from === '/c/search-car-sharing/date'
            ) {
                animPages.initial = 'topFast';
            } else animPages.initial = 'bottomFast';

            if (to === '/c/search') {
                animPages.exit = 'topFast';
            } else if (
                to === '/c/search-car-sharing/from' ||
                to === '/c/search-car-sharing/to' ||
                to === '/c/search-car-sharing/seats' ||
                to === '/c/search-car-sharing/date'
            ) {
                animPages.exit = 'topFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else animPages.exit = 'topFast';
            break;
        case 'app.searchCarSharing.date':
        case 'app.searchCarSharing.seats':
        case 'app.searchCarSharing.to':
        case 'app.searchCarSharing.from':
            if (from === '/c/trip') {
                animPages.initial = rtl ? 'right' : 'left';
                animCards.initial = 'in';
            } else if (from === '/c/search') {
                animPages.initial = 'in';
                animCards.initial = 'out';
            } else if (from === '/c/search-car-sharing') {
                animPages.initial = 'bottomFast';
                animCards.initial = 'out';
            } else {
                animPages.initial = 'bottomFast';
                animCards.initial = 'out';
            }
            if (to === '/c/trip') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/search-car-sharing') {
                animPages.exit = 'bottomFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }
            break;

        case 'app.search':
            if (from === '/c/trip') {
                animPages.initial = rtl ? 'right' : 'left';
                animCards.initial = 'in';
            } else if (from === '/c/search') {
                animPages.initial = 'in';
                animCards.initial = 'out';
            } else if (from === '/c/search-car-sharing') {
                animPages.initial = 'bottomFast';
                animCards.initial = 'out';
            } else {
                animPages.initial = 'bottomFast';
                animCards.initial = 'out';
            }
            if (to === '/c/trip') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/search-car-sharing') {
                animPages.exit = 'bottomFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }
            break;

        case 'app.trip':
            if (from === '/c/messages') {
                animPages.initial = 'in';
            } else if (from === '/c/search') {
                animPages.initial = rtl ? 'left' : 'right';
                animCards.initial = 'in';
            } else if (from === '/c/user') {
                animPages.initial = rtl ? 'right' : 'left';
                animCards.initial = 'in';
            } else if (from === '/c/rides/booking') {
                animPages.initial = rtl ? 'right' : 'left';
                animCards.initial = 'out';
            } else if (from === '/c/book') {
                animPages.initial = rtl ? 'right' : 'left';
                animCards.initial = 'out';
            } else {
                animPages.initial = false;
                animCards.initial = 'out';
            }
            if (to === '/c/messages') {
                animPages.exit = 'in';
            } else if (to === '/c/user') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/book') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/rides/booking') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/search') {
                animPages.exit = rtl ? 'left' : 'right';
            } else if (to === '/c/search-car-sharing') {
                animPages.exit = 'bottomFast';
            } else {
                animPages.exit = 'topFast';
            }
            break;
        // case 'app.messages':
        //     if (from) {
        //         animPages.initial = rtl ? 'right' : 'left';
        //     }
        //     if (to) {
        //         animPages.exit = rtl ? 'left' : 'right';
        //     } else {
        //         animPages.exit = 'in';
        //     }
        //     break;
        case 'app.user':
            if (
                from === '/c/trip' ||
                from === '/c/rides/booking' ||
                from === '/c/rides/archived/booking' ||
                from === '/c/rides/offer' ||
                from === '/c/rides/archived/offer'
            ) {
                animPages.initial = rtl ? 'left' : 'right';
                animCards.initial = 'in';
            } else if (from === '/c/messages') {
                animPages.initial = 'in';
                animCards.initial = 'in';
            } else {
                animPages.initial = false;
                animCards.initial = 'out';
            }

            if (
                to === '/c/trip' ||
                to === '/c/rides/booking' ||
                to === '/c/rides/archived/booking' ||
                to === '/c/rides/offer' ||
                to === '/c/rides/archived/offer'
            ) {
                animPages.exit = rtl ? 'left' : 'right';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }

            break;
        case 'app.book':
            if (from === '/c/messages') {
                animPages.initial = 'in';
            } else if (from === '/c/trip') {
                animPages.initial = rtl ? 'left' : 'right';
                animCards.initial = 'in';
            }
            if (!from) {
                animPages.initial = false;
                animCards.initial = 'out';
            }
            if (to === '/c/messages') {
                animPages.exit = 'in';
            } else if (to === '/c/confirm') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/trip') {
                animPages.exit = rtl ? 'left' : 'right';
            } else if (to === '/c/search-car-sharing') {
                animPages.exit = 'bottomFast';
            } else if (to === '/c/rides/booked' || to === '/c/rides/offered' || to === '/c/rides/booking' || to === '/c/rides/offer') {
                animPages.exit = rtl ? 'right' : 'left';
            } else {
                animPages.exit = 'topFast';
            }

            break;
        case 'app.rides.offer':
        case 'app.rides.booking':
        case 'app.rides.archived.offer':
        case 'app.rides.archived.booking':
            if (from === '/c/messages') {
                animPages.initial = 'in';
            } else if (from === '/c/user') {
                animPages.initial = rtl ? 'right' : 'left';
                animCards.initial = 'in';
            } else if (
                from === '/c/notifications' ||
                from === '/c/trip' ||
                from === '/c/rides/booked' ||
                from === '/c/rides/booked/archived' ||
                from === '/c/rides/offered' ||
                from === '/c/rides/offered/archived'
            ) {
                animPages.initial = rtl ? 'left' : 'right';
                animCards.initial = 'in';
            } else if (from === '/c/book') {
                animPages.initial = rtl ? 'left' : 'right';
            } else {
                animPages.initial = 'topFast';
                animCards.initial = 'out';
            }
            if (to === '/c/messages') {
                animPages.exit = 'in';
            } else if (to === '/c/user') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/confirm') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (
                to === '/c/notifications' ||
                to === '/c/trip' ||
                to === '/c/rides/booked' ||
                to === '/c/rides/booked/archived' ||
                to === '/c/rides/offered' ||
                to === '/c/rides/offered/archived'
            ) {
                animPages.exit = rtl ? 'left' : 'right';
            } else animPages.exit = 'topFast';

            break;
        case 'app.notifications':
            if (from === '/c/rides/archived/booking' || from === '/c/rides/booking' || from === '/c/rides/archived/offer' || from === '/c/rides/offer') {
                animPages.initial = rtl ? 'right' : 'left';
            } else if (from === '/c/messages') {
                animPages.initial = 'in';
            } else {
                animPages.initial = 'bottomFast';
            }

            if (to === '/c/messages') {
                animPages.exit = 'in';
            } else if (to === '/c/rides/archived/booking' || to === '/c/rides/booking' || to === '/c/rides/archived/offer' || to === '/c/rides/offer') {
                animPages.exit = rtl ? 'right' : 'left';
            } else {
                animPages.exit = 'topFast';
            }
            break;

        case 'app.rides.offered':
        case 'app.rides.booked':
            if (from === '/c/rides/offered/archived' || from === '/c/rides/booked/archived') {
                animPages.initial = rtl ? 'right' : 'left';
            } else if (from === '/c/rides/booking' || from === '/c/rides/offer') {
                animPages.initial = rtl ? 'right' : 'left';
            } else if (from === '/c/rides/booked' || from === '/c/rides/offered') {
                animPages.initial = 'in';
            } else {
                animPages.initial = 'bottomFast';
            }
            if (to === '/c/rides/offered/archived' || to === '/c/rides/booked/archived') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/rides/booking' || to === '/c/rides/offer') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/rides/booked' || to === '/c/rides/offered') {
                animPages.exit = 'in';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }
            break;

        case 'app.rides.offered.archived':
        case 'app.rides.booked.archived':
            if (from === '/c/rides/archived/booking' || from === '/c/rides/archived/offer') {
                animPages.initial = rtl ? 'right' : 'left';
            } else if (from === '/c/rides/booked' || from === '/c/rides/offered') {
                animPages.initial = rtl ? 'left' : 'right';
            } else {
                animPages.initial = false;
                animCards.initial = 'out';
            }

            if (to === '/c/rides/booked' || to === '/c/rides/offered') {
                animPages.exit = rtl ? 'left' : 'right';
            } else if (to === '/c/rides/archived/booking' || to === '/c/rides/archived/offer') {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else animPages.exit = 'topFast';

            break;

        case 'app.offerSeats':
            if (
                from === '/c/offer-seats/from' ||
                from === '/c/offer-seats/to' ||
                from === '/c/offer-seats/seats' ||
                from === '/c/offer-seats/date' ||
                from === '/c/offer-seats/time'
            ) {
                animPages.initial = 'topFast';
            } else animPages.initial = 'bottomFast';

            if (
                to === '/c/offer-seats/from' ||
                to === '/c/offer-seats/to' ||
                to === '/c/offer-seats/seats' ||
                to === '/c/offer-seats/date' ||
                to === '/c/offer-seats/time'
            ) {
                animPages.exit = 'topFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else animPages.exit = 'topFast';
            break;
        case 'app.offerSeats.date':
        case 'app.offerSeats.time':
        case 'app.offerSeats.seats':
        case 'app.offerSeats.to':
        case 'app.offerSeats.from':
            if (from === '/c/offer-seats') {
                animPages.initial = 'bottomFast';
                animCards.initial = 'out';
            } else {
                animPages.initial = 'bottomFast';
                animCards.initial = 'out';
            }
            if (to === '/c/offer-seats') {
                animPages.exit = 'bottomFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }
            break;

        case 'app.profile':
            if (from === routePath('app.profile')) {
                animPages.initial = rtl ? 'in' : 'in';
            } else if (from === routePath('app.profile.verify') || from === routePath('app.profile.personal') || from === routePath('app.profile.reviews')) {
                animPages.initial = rtl ? 'right' : 'left';
            } else if (from === routePath('app.profile.personal.photo') || from === routePath('app.profile.password')) {
                animPages.initial = 'topFast';
            } else {
                animPages.initial = 'bottomFast';
            }

            if (to === routePath('app.profile.verify') || to === routePath('app.profile.personal') || to === routePath('app.profile.reviews')) {
                animPages.exit = rtl ? 'right' : 'left';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }

            break;
        case 'app.profile.verify':
        case 'app.profile.reviews':
        case 'app.profile.personal':
            if (from === routePath('app.profile')) {
                animPages.initial = rtl ? 'left' : 'right';
            } else if (
                from === routePath('app.profile.verify.verifyId') ||
                from === routePath('app.profile.personal.first') ||
                from === routePath('app.profile.personal.photo')
                // from === routePath('app.profile.reviews') ||
                // from === routePath('app.profile.password')
            ) {
                animPages.initial = rtl ? 'right' : 'left';
            } else if (from === routePath('app.profile.personal.photo') || from === routePath('app.profile.password')) {
                animPages.exit = 'topFast';
            } else {
                animPages.exit = 'bottomFast';
            }

            if (to === routePath('app.profile')) {
                animPages.exit = rtl ? 'left' : 'right';
            } else if (to === routePath('app.profile.personal.photo') || to === routePath('app.profile.password')) {
                animPages.exit = 'topFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }

            break;
        case 'app.profile.verify.verifyId':
        case 'app.profile.verify.verifyMobile':
        case 'app.profile.verify.verifyEmail':
        case 'app.profile.verify.verifyPlate':
        case 'app.profile.verify.verifyDriver':
        case 'app.profile.personal.first':
        case 'app.profile.personal.last':
        case 'app.profile.personal.dob':
        case 'app.profile.personal.gender':
        case 'app.profile.personal.email':
        case 'app.profile.personal.mobile':
        case 'app.profile.personal.photo':
        case 'app.profile.password':
            if (from === routePath('app.profile.personal') || from === routePath('app.profile.verify')) {
                animPages.initial = 'bottomFast';
            } else {
                animPages.initial = 'bottomFast';
            }
            if (to === routePath('app.profile.personal') || to === routePath('app.profile.verify') || to === routePath('app.profile')) {
                animPages.exit = 'bottomFast';
            } else if (to === '/c/messages') {
                animPages.exit = 'in';
            } else {
                animPages.exit = 'topFast';
            }
            break;

        default:
    }

    return { animPages, animCards };
};
