import { connect } from 'react-redux';

import { LayoutPage, LayoutPageBody, LayoutPageHeader } from 'components/layouts';
import { bindActionCreators } from 'redux';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';
import { PageLoading } from 'pages/index';
import IconRound from 'components/blocks/IconRound';
import { setStaticPage } from 'store/actions/staticPages';
import { loadUser, loginSetStep } from 'store/actions/auth';
import { useState, useEffect, useRef } from 'react';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import Uploader from 'components/blocks/Uploader';
import verifyImage from 'components/images/id_verification_01.jpg';
import BtnLoadState from 'components/buttons/BtnLoadState';

import { updateProfile } from 'fetch/apiRequests';
import useMyNavigation from 'hooks/useMyNavigation';
import BtnIcon from 'components/buttons/BtnIcon';
import { widthBiggerThan } from 'helpers/utils';
import { avatarCircles as styles } from 'constants/styles';
import { analytics } from 'helpers/segment';
import AvatarEditor from 'components/blocks/AvatarEditor';

const PageProfileAvatar = ({ setStaticPage, loadUser, auth: { user, isAuthenticated } }) => {
    const pageData = {
        page: 'static.help.terms',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/profiles/me',
        backBtn: true,
        zIndex: 41,
        btnIcon: 'Close',
        btnFlip: true,
        checkIdParam: false,
        singleResult: true
    };

    const { handleNavigation, location } = useMyNavigation();

    const [isModalAction, setIsModalAction] = useState(false);
    const [uploadedImage, setUploadedImage] = useState();
    const [isCleared, setIsCleared] = useState(false);

    const widgetApi = useRef();

    const submitPhoto = async (mutateData) => {
        setIsModalAction(true);

        const res = await updateProfile({ avatar: uploadedImage ?? null }, user);
        analytics.track(uploadedImage ? 'Profile Photo Added' : 'Profile Photo Cleared', null, isAuthenticated);
        loadUser();
        mutateData();
        setIsModalAction(false);
        closeModal(true);
    };

    const closeModal = (mutate) => {
        if (mutate) handleNavigation('app.profile');
        setStaticPage(null);
    };

    const handleImage = (url) => {
        // console.log(url);
        setUploadedImage(url?.cdnUrl);
        setIsCleared(false);
    };

    const handleClear = (url) => {
        setUploadedImage(null);
        setIsCleared(true);
    };

    const openUploader = () => {
        const dialog = widgetApi.current.openDialog();

        dialog.switchTab('file');
    };

    const whichImageToDisplay = (currentAvatar) => {
        if (isCleared) return '';
        else {
            if (uploadedImage) return uploadedImage;
            else return currentAvatar;
        }
    };

    useEffect(() => {
        analytics.page('Upload Image', 'App', isAuthenticated);
    }, []);

    return (
        <LayoutPage pageData={{ ...pageData, overlay: true }}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                // console.log('my verifications', data);

                const currentAvatar = whichImageToDisplay(data?.avatar);

                return (
                    <>
                        <LayoutPageHeader
                            handleButton={closeModal}
                            pageData={pageData}
                            // pageParams={params}
                        ></LayoutPageHeader>

                        <div className={classNames('h-body-w-header-0', 'overflow-y-auto text-vw-base mt-vw-6a no-scrollbar font-semibold text-gray-500')}>
                            <LayoutPageBody minHeighta={classNames('min-h-body-w-header-footer-2')}>
                                <div className=''>
                                    {data ? (
                                        <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                            <div className={classNames('h-body flex flex-col justify-between', 'px-vw-6 relative w-full')}>
                                                <div
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                >
                                                    <OfflineMode margins=' my-6' />
                                                    <h2
                                                        style={{
                                                            direction: rtl ? 'rtl' : 'ltr'
                                                        }}
                                                        className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                                    >
                                                        {translations?.data?.['e.verify.photo.title']}
                                                    </h2>

                                                    <div className={classNames('mt-vw-4')}>{translations?.data?.['e.verify.photo.body.new']}</div>
                                                    {false && (
                                                        <div className={classNames('w-full flex justify-center object-contain h-vh-36 mt-vw-8')}>
                                                            <img src={verifyImage} />
                                                        </div>
                                                    )}
                                                    <AvatarEditor avatar={currentAvatar} handleClear={handleClear} />
                                                </div>
                                                <div className={classNames('flex flex-col')}>
                                                    {true && (
                                                        <BtnPill
                                                            type={'confirmed'}
                                                            justify={'justify-center'}
                                                            rtl={rtl}
                                                            params={[]}
                                                            handleClick={openUploader}
                                                            margins='mt-vw-6 mb-2'
                                                            disabled={!online}
                                                        >
                                                            {translations?.data?.['e.verify.photo.select']}
                                                        </BtnPill>
                                                    )}

                                                    <BtnLoadState
                                                        colorType={'primary'}
                                                        disabled={!online || (!data?.avatar && !uploadedImage)}
                                                        spinner={isModalAction}
                                                        handleClick={submitPhoto}
                                                        params={[mutateData]}
                                                        label={'e.verify.photo.update'}
                                                    />
                                                    <BtnPill
                                                        type='default'
                                                        justify={'justify-center'}
                                                        rtl={rtl}
                                                        params={[false]}
                                                        handleClick={closeModal}
                                                        margins='mb-vw-4'
                                                    >
                                                        {translations?.data?.['e.later']}
                                                    </BtnPill>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <PageLoading bg='bg-white' overlay={true} />
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                        <Uploader multiple={false} maxImages={1} handleUpdate={handleImage} params={[mutateData]} widgetRef={widgetApi} verify={false} />
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    globalState: state.globalState
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage,
            loginSetStep,
            loadUser
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageProfileAvatar);
