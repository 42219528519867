import { animations } from 'constants/animations';

import { motion } from 'framer-motion';

const MotionDiv = ({
    children,
    initial = 'fadeOut',
    animate = 'delayedIn',
    exit = 'fadeOut',
    variants = animations.pageSlides,
    className = 'h-full',
    myKey = 'defaultDiv'
}) => {
    return (
        <div key={myKey} initial={initial} animate={animate} exit={exit} variants={variants} className={className}>
            {children}
        </div>
        // <div className={className}>{children}</div>
    );
};

export default MotionDiv;
