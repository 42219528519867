import { defaultSize, defaultStroke } from './defaults';
const IconId1 = ({ size, className }) => {
    return (
        <>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size || defaultSize} height={size || defaultSize}>
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path fill='none' d='M0 0h24v24H0z' />
                        <path d='M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z' />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconId1;
