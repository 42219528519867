import getDistanceBetweenPoints from 'get-distance-between-points';
import Icons from 'components/icons/';
import Roundify from './Roundify';
import GetLang from 'hoc/GetLang';
import { widthBiggerThan } from 'helpers/utils';

const ProximityStatus = ({ locRequested, locFound, showDistance = false, end }) => {
    let distance = false;
    let objClass = {
        color: {
            a: 'text-gray-600',
            b: 'text-gray-600',
            c: 'text-emerald-600'
        },

        bg: {
            a: 'bg-gray-200',
            b: 'bg-gray-200',
            c: 'bg-gray-200'
        }
    };

    if (locRequested?.length > 0 && locFound?.length > 0) {
        distance = getDistanceBetweenPoints.getDistanceBetweenPoints(
            locRequested[1],
            locRequested[0], // Lat, Long of point A
            locFound[1],
            locFound[0] // Lat, Long of point B
        );

        if (distance >= 0)
            // green
            objClass = {
                color: 'text-emerald-700',

                bg: 'bg-emerald-200',
                width: 'w-vw-8 md:w-8'
            };

        if (distance > 1000)
            // yellow
            objClass = {
                color: 'text-yellow-600',

                bg: 'bg-yellow-200',
                width: 'w-vw-16 md:w-20'
            };

        if (distance > 2500)
            // red
            objClass = {
                color: 'text-red-600',

                bg: 'bg-red-200',
                width: 'w-vw-24 md:w-28'
            };
    }

    return (
        <GetLang>
            {({ translations, lang, rtl }) =>
                distance !== false && (
                    <div className={`mt-1 ${end ? '' : 'mb-4'} flex flex-col ${rtl ? 'items-end' : 'items-start'}`}>
                        <div className={`flex bg-gray-100 rounded-full w-max  ${rtl ? 'flex-row-reverse' : 'flex-row '}`}>
                            <Roundify
                                bgColor={objClass.bg}
                                color={objClass.color}
                                width={objClass.width}
                                justify={rtl ? 'justify-end' : 'justify-start'}
                                height='h-vw-8 md:h-8'
                            >
                                <Roundify bgColor='' color={objClass.color} height='h-vw-8 md:h-8' width='w-vw-8 md:w-8'>
                                    <Icons icon={'Walk1'} size={widthBiggerThan() ? '20px' : '5.8vw'} flip={rtl} />
                                </Roundify>
                            </Roundify>
                            <Roundify bgColor='' color='text-gray-400' height='h-vw-8 md:h-8' width='w-vw-10 md:w-10'>
                                <Icons icon={'MapPin1'} size={widthBiggerThan() ? '20px' : '5.8vw'} />
                            </Roundify>
                        </div>
                        {showDistance && (
                            <div className='text-sm mt-1 text-gray-400'>
                                <span>{translations?.data?.['e.distance.approx']}</span>
                                <span>{formatDistanceToPoint(distance)}</span>
                                <span>{translations?.data?.['e.distance.km']}</span>
                                <span>{translations?.data?.[end ? 'e.distanceFrom.end' : 'e.distanceFrom.start']}</span>
                            </div>
                        )}
                    </div>
                )
            }
        </GetLang>
    );
};

export default ProximityStatus;

const formatDistanceToPoint = (distance) => {
    return (distance / 1000).toFixed(1);
};
