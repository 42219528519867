import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import Spinner from 'components/blocks/Spinner';
import classNames from 'classnames';
import OfflineMode from 'components/blocks/OfflineMode';
import LayoutPageHeader from 'components/layouts/LayoutPageHeader';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { setStaticPage } from 'store/actions/staticPages';

const PageLoading = ({ setStaticPage, bg = 'bg-bb-blue-900', overlay }) => {
    const pageData = {
        backBtn: true,
        btnIcon: overlay ? 'Close' : 'ArrowLeftLong1',
        zIndex: 41,
        checkIdParam: false,
        btnFlip: overlay
    };

    const isOnline = useOnlineStatus();
    return (
        <div className={classNames('z-1000  fixed inset-0', bg)}>
            {!isOnline && (
                <LayoutPageHeader handleButton={overlay ? () => setStaticPage('') : null} pageData={pageData} useBrowserHistory={!overlay}></LayoutPageHeader>
            )}

            <span className='absolute left-1/2 top-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2'>
                <div className='flex flex-col items-center gap-4'>
                    <Spinner size='40' />
                    <OfflineMode />
                </div>
            </span>
        </div>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    search: state.search
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLoading);
