import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputLocation from 'components/forms/InputLocation';
import { updatePublishSeats, updatePublishDateRange } from 'store/actions/publish';
import useMyNavigation from 'hooks/useMyNavigation';
import { getNavParams } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import BtnIcon from 'components/buttons/BtnIcon';
import classNames from 'classnames';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import { useState } from 'react';

const LayoutPublishSeats = ({
    updatePublishSeats,
    updatePublishDateRange,
    publish: {
        publish: { seatsOffered, recurring, dateRange },
        selectedDay,
        isValid
    },
    source
}) => {
    const [seats, setSeats] = useState(
        recurring ? (selectedDay === 'general' ? dateRange?.seats : dateRange?.[selectedDay]?.seats ?? dateRange?.seats) : seatsOffered
    );

    const handleSetSeats = (opType) => {
        if (opType === 'add') {
            setSeats(seats + 1);
        }
        if (opType === 'sub') {
            setSeats(seats - 1);
        }
    };

    const handleUpdate = (time, redirect) => {
        if (!recurring) {
            updatePublishSeats(seats);
            handleNavigation('app.offerSeats', null);
        } else {
            if (selectedDay !== 'general') {
                updatePublishDateRange(selectedDay, { ...dateRange[selectedDay], seats });
            } else {
                updatePublishDateRange('seats', seats);
            }
            handleNavigation('app.offerSeats', null);
        }
        // updateSelectedDay(null);
    };

    const { handleNavigation, location } = useMyNavigation();

    const handleNavAction = () => {
        const { toPage, navParams } = getNavParams({
            location,
            page: `app.searchCarSharing.${source}`,
            pageParams: null,
            isValid
        });
        handleNavigation(toPage, navParams);
    };

    return (
        <GetLang>
            {({ translations, tripLang, lang, rtl }) => (
                <div>
                    <div className={classNames('flex justify-between gap-4 items-center', { 'flex-row-reverse': rtl })}>
                        <BtnIcon icon={'Subtract'} handleClick={handleSetSeats} params={['sub']} disabled={seats <= 1} />
                        <div className={classNames('text-6xl font-extrabold text-bb-blue-500')}>{seats}</div>
                        <BtnIcon icon={'Add'} handleClick={handleSetSeats} params={['add']} disabled={seats >= 8} />
                    </div>
                    <div className={classNames('flex items-center justify-center mt-vw-6')}>
                        <BtnPill type='primary' handleClick={handleUpdate} justify={'justify-between'} disabled={false} rtl={rtl}>
                            <span className='w-6' />
                            {translations?.data?.['e.page.publish.action.confirm']}
                            <Icons icon={'Check'} />
                        </BtnPill>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishSeats,
            updatePublishDateRange
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPublishSeats);
