import { NavLink } from 'react-router-dom';
import Icons from 'components/icons/';
import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { animations } from 'constants/animations';
import { useNavigate, useLocation } from 'react-router-dom';
import { setScroll } from 'store/actions/globalState';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { widthBiggerThan } from 'helpers/utils';
import classNames from 'classnames';

const NavItem = ({
    id,
    label = 'default',
    icon = 'IconCar1',
    linkTo = '/',
    activeArray = null,
    handleClick,
    delay = 0,
    animationState = false,
    setScroll,
    notification,
    rtl,
    toggleNav,
    navIsOpen
}) => {
    const [ripple, setRipple] = useState(false);
    const baseDelay = 50;
    const defaultClass = classNames('flex flex-col md:flex-row md:gap-2 md:items-center', rtl && 'md:flex-row-reverse');
    const activeClass = `text-bb-blue-500 ${defaultClass}`;
    const inactiveClass = `text-gray-400 ${defaultClass}`;

    const navigate = useNavigate();
    const location = useLocation();
    const handleButton = () => {
        setScroll(null);
        handleClick(false);
        navigate(linkTo, {
            state: { from: location.pathname, to: linkTo }
        });
    };

    const isActive = [...(activeArray || [linkTo])].some((elm) => elm === location.pathname);
    // const isActive = location.pathname === linkTo;

    useEffect(() => {
        if (ripple) {
            setTimeout(() => {
                setRipple(false);
            }, 550);
        }
    }, [ripple]);

    return (
        <li
            // key={linkTo}
            // // initial={'out'}
            // animate={'in'}
            // exit={'out'}
            // variants={animations.navItem}
            onClick={isActive ? (navIsOpen ? () => toggleNav(false) : null) : handleButton}
            style={{ transitionDelay: `${delay * baseDelay}ms` }}
            className={`cursor-pointer min-w-vw-14 md:min-w-14 list-none select-none flex relative items-center justify-center px-vw-2 md:px-0 duration-300 transform transition-all ${
                animationState ? 'translate-y-0' : 'translate-y-full'
            }`}
        >
            {/* {(ripple || isActive) && (
                <span className='absolute rounded-full animate-ripple-once opacity-0 h-14 w-14 transform bg-bb-blue-100'></span>
            )} */}
            {notification > 0 && <span className='absolute right-1/4 top-1/4 rounded-full h-3 w-3 transform bg-bb-red-400 border-gray-50 border-2'></span>}

            <div className={isActive || ripple ? activeClass : inactiveClass}>
                <div className='mx-auto'>
                    <Icons icon={icon} />
                </div>
                <div className='whitespace-nowrap hidden xs360:block text-vw-xs md:text-base lg:text-sm font-medium'>{label}</div>
            </div>
        </li>
    );
};
const mapDispatchToProps = (dispatch) => bindActionCreators({ setScroll }, dispatch);

export default connect(null, mapDispatchToProps)(NavItem);
