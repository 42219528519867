import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

// const list = ['male', 'female', 'na'];

const ListBox = ({ top = true, id, selectedValue, handleChange, list, placeholder, valueId = 'id' }) => {
    // const [selected, setSelected] = useState(list[0]);

    const handleList = (selectedValue) => {
        console.log(selectedValue);

        handleChange(id, selectedValue[valueId], true);
    };

    return (
        <GetLang>
            {({ rtl, lang, translations }) => (
                <div className='flex flex-col h-full w-full'>
                    <div className=''>
                        <Listbox value={selectedValue} onChange={handleList}>
                            {({ open }) => (
                                <div className='relative'>
                                    <Listbox.Button
                                        className={classNames(
                                            rtl ? 'text-right' : 'text-left',

                                            'relative h-vw-16 py-vw-3 w-full  block appearance-none placeholder-gray-400 placeholder-opacity-100 text-gray-700 leading-5 focus:outline-none bg-gray-100 font-semibold text-vw-lg border-2 border-transparent focus:border-bb-blue-500-a',
                                            {
                                                'rounded-b-2xl': top || (!top && !open),
                                                'rounded-t-2xl': !top || (top && !open),
                                                'pl-vw-6 pr-vw-14': !rtl,
                                                'pr-vw-6 pl-vw-14': rtl
                                            }
                                        )}
                                    >
                                        {selectedValue ? (
                                            <span className={classNames('block truncate')}> {translations.data[selectedValue?.text ?? 'something']}</span>
                                        ) : (
                                            <span className={classNames('block truncate text-gray-400')}>{placeholder}</span>
                                        )}
                                        <div
                                            className={classNames(' absolute top-1/2 transform -translate-y-1/2 ', {
                                                'right-vw-4': !rtl,
                                                'left-vw-4': rtl,
                                                'text-gray-500': true
                                            })}
                                        >
                                            <Icons icon={'ArrowUpDown2'} />
                                        </div>
                                    </Listbox.Button>
                                    <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                                        <Listbox.Options
                                            className={classNames(
                                                top
                                                    ? 'focus:outline-none top-0 -translate-y-full rounded-t-2xl border-b-2 shadow-2xl-top'
                                                    : 'rounded-b-2xl border-t-2 shadow-2xl',
                                                'border-gray-300 border-opacity-50 z-1000 absolute max-h-60 w-full overflow-auto  bg-gray-100 text-vw-base font-semibold  shadow-bb-blue-900/30'
                                            )}
                                        >
                                            {list.map((listItem, listItemIndex) => (
                                                <Listbox.Option
                                                    key={listItemIndex}
                                                    className={({ active }) =>
                                                        classNames(
                                                            rtl ? 'pr-vw-12 pl-vw-4' : 'pl-vw-12 pr-vw-4',
                                                            'relative cursor-default select-none py-vw-4 ',
                                                            active ? 'bg-bb-blue-500 text-white' : 'text-gray-500'
                                                        )
                                                    }
                                                    value={listItem}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(rtl ? 'text-right' : 'text-left', 'block truncate ')}>
                                                                {translations.data[listItem?.text ?? 'something']}
                                                            </span>
                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        rtl ? 'right-0 pr-vw-4' : 'left-0 pl-vw-4',
                                                                        'absolute inset-y-0  flex items-center  text-bb-blue-500'
                                                                    )}
                                                                >
                                                                    <Icons icon={'Check'} containerClass={classNames(active && 'text-white')} />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            )}
                        </Listbox>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default ListBox;
