import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLoadScript } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { googleMapsInitialised, googleMapsInitialisedFailed } from 'store/actions/googlemaps';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('en');
Geocode.enableDebug();

// function GoogleMaps({ store }) {
//     return <LoadMap store={store} />;
// }
const libraries = ['places'];

function LoadGoogleMapsAPI({ store }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
        language: 'en-US'
    });

    useEffect(() => {
        if (isLoaded) {
            store.dispatch(googleMapsInitialised());
        } else if (loadError) {
            store.dispatch(googleMapsInitialisedFailed());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, loadError]);

    return null;
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(LoadGoogleMapsAPI);
