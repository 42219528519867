import React from 'react';
export function AppStoreGallery({ classNames }) {
    return (
        <div className={classNames('w-vw-100 flex flex-nowrap gap-4 overflow-x-auto px-vw-8 mb-vw-8 no-scrollbar')}>
            <img
                src='https://ucarecdn.com/2534f4db-6edb-499c-a18a-9a9704516403/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/86d6acd9-8343-4abf-a5ec-3c8bd6a4df30/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/3f1315f9-eee1-4532-8bdd-d4acb2ab2c09/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/bece6e1c-692d-4d20-a8b7-4b0792305fdd/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/55bf8024-4709-4e4b-bc8e-b611162a54e2/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/c4e8b3bf-7bbe-4591-979d-bf59a6f7a363/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/6dbedf11-4ff3-4ba3-a427-a34e2ed168bb/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
            <img
                src='https://ucarecdn.com/d9757f6c-55f0-4736-b507-431c4b655fd7/-/preview/-/quality/smart/-/format/auto/'
                className='w-vw-48 h-auto rounded-xl'
            />
        </div>
    );
}
