import { defaultSize, defaultStroke } from './defaults';
const IconUserUnblock = ({ size, className, stroke = 2 }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
                fill='none'
                stroke='currentColor'
                strokeWidth={stroke}
                strokeLinecap='round'
                strokeLinejoin='round'
            >
                <circle
                    id='circle'
                    cx='10'
                    cy='10'
                    r='10'
                    transform='translate(2 2)'
                />
                <path
                    id='body'
                    d='M16.141,19.452V17.968A3,3,0,0,0,13.106,15H7.035A3,3,0,0,0,4,17.968v1.484'
                    transform='translate(2 0.449)'
                />
                <ellipse
                    id='head'
                    cx='3.122'
                    cy='3.053'
                    rx='3.122'
                    ry='3.053'
                    transform='translate(8.949 6.099)'
                />
            </svg>
        </>
    );
};

export default IconUserUnblock;
