import { connect } from 'react-redux';
import classNames from 'classnames';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';

const CustomDateSeparator = ({ chat: { channelHistory }, ...props }) => {
    const { date } = props;

    const dateId = date.toISOString().toString();

    const getMessageVisibility = () => {
        return true;
        if (channelHistory != null) {
            return new Date(date) > new Date(channelHistory) ? true : false;
        } else return false;
    };

    return (
        <>
            {getMessageVisibility() ? (
                <div className={classNames('pt-vw-4 md:pt-4 h-12')} id={dateId}>
                    <div className={classNames('w-max mx-auto')}>
                        <Pill sizePreset='small' colorPreset='light-blue'>
                            <FormattedDateTime dateTime={date.toISOString()} date />
                        </Pill>
                    </div>
                </div>
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    chat: state.chat
});

export default connect(mapStateToProps, null)(CustomDateSeparator);
