import { Switch } from '@headlessui/react';
import Icons from 'components/icons/index';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const presets = {
    blue: 'bg-bb-blue-500',
    on: 'bg-bb-blue-500',
    off: 'bg-red-500',
    locked: 'bg-gray-500',
    'light-gray': 'bg-gray-200'
};

const SwitchBtn = ({ enabled, setEnabled, color = 'blue', lock, colorOff = 'light-gray' }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className={classNames('flex gap-2 items-center', rtl && 'flex-row-reverse')}>
                    {lock ? <Icons icon='Lock1' size={'12px'} containerClass={'text-gray-400'} /> : null}
                    <Switch
                        checked={enabled}
                        onChange={setEnabled}
                        className={`${enabled ? presets[color] : presets[colorOff]} relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                        <span className='sr-only'>Enable notifications</span>

                        <div
                            className={classNames(
                                'inline-block-a flex justify-center items-center h-4 w-4 transform rounded-full bg-white transition-transform duration-200',
                                {
                                    'translate-x-6': (enabled && !rtl) || (!enabled && rtl),
                                    'translate-x-1': (!enabled && !rtl) || (enabled && rtl)
                                }
                            )}
                        >
                            <Icons
                                stroke='8'
                                icon={enabled ? 'Check' : 'Close'}
                                size={'12px'}
                                containerClass={enabled ? 'text-bb-blue-550' : 'text-gray-300'}
                            />
                        </div>
                    </Switch>
                </div>
            )}
        </GetLang>
    );
};

export default SwitchBtn;
