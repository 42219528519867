import React, { useState, useEffect, useRef } from 'react';
import { getDateString } from 'helpers/dates';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePublishDate, updatePublishDateRange, updateSelectedDay } from 'store/actions/publish';
import { DateTime } from 'luxon';
import { motion, LayoutGroup, AnimatePresence } from 'framer-motion';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

import Icons from 'components/icons/index';
import IconRoundStatus from 'components/blocks/IconRoundStatus';
import SwitchBtn from 'components/blocks/SwitchBtn';
import { daysOfWeek, daysOfWeekStrings } from 'constants/defaultObjects';
import Pill from 'components/blocks/Pill';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getPresetByStatus, validateDateTime, validateEmptyWeek } from 'helpers/helperFunctions';
import BtnPill from 'components/buttons/BtnPill';
import InputShell from 'components/forms/InputShell';
import PseudoInput from 'components/forms/PseudoInput';
import useMyNavigation from 'hooks/useMyNavigation';
import { animations, getSlideAnimation } from 'constants/animations';
import DaysOfWeekInline from './DaysOfWeekInline';

const CalendarMultipleInline = ({
    updatePublishDate,
    updatePublishDateRange,
    updateSelectedDay,
    publish: {
        publish: { date, recurring, dateRange, time, seatsOffered }
    },
    shouldSlide,
    handleSlide
}) => {
    const handleClear = (key, subkey) => {
        if (key === 'time') {
            handleDateRange(key, '');
        } else if (key === 'seats') {
            handleDateRange(key, 1);
        } else {
            if (subkey === 'time') {
                handleDateRange(key, { ...dateRange[key], timeOverride: false, time: '' });
            }
            if (subkey === 'seats') {
                handleDateRange(key, { ...dateRange[key], seatsOverride: false, seats: null });
            }
        }
    };

    const { handleNavigation, location } = useMyNavigation();
    const handleDate = (date) => {
        updatePublishDate(date);
        handleNavigation('app.offerSeats', null);
    };

    const updateDayAndOpenModal = (day, key, redirect = true) => {
        updateSelectedDay(day);
        if (redirect) handleNavigation(`app.offerSeats.${key}`, null); // time or seats
    };

    const handleDateRange = (key, body) => {
        updatePublishDateRange(key, body);
        // handleNavigation('app.offerSeats', null);
    };

    return (
        <GetLang>
            {({ lang, rtl, translations, time24 }) => (
                <div>
                    {/* <DaysOfWeekInline
                        handleDateRange={handleDateRange}
                        dateRange={dateRange}
                        // updateDayAndOpenModal={updateDayAndOpenModal}
                        // handleClear={handleClear}
                    /> */}
                    <motion.div
                        layout='position'
                        initial={'fadeOut'}
                        animate={'inFieldDelayed'}
                        variants={animations.pageSlides}
                        className={classNames(rtl && 'flex-row-reverse', 'flex flex-wrap justify-between mb-vw-4')}
                    >
                        {daysOfWeek.map((day, index) => (
                            <SwitchSectionCalendar
                                key={day}
                                icon='Calendar1'
                                iconColorPreset={'light-violet'}
                                colorOff='light-gray'
                                border='border-t-a'
                                label='e.view.recurring'
                                visible={true}
                                // visible={dateRange?.[index]?.enabled}
                                dayIndex={index}
                                day={day}
                                dayBody={dateRange?.[index]}
                                updateDay={handleDateRange}
                                generalTime={dateRange?.time}
                                generalSeats={dateRange?.seats}
                                updateDayAndOpenModal={updateDayAndOpenModal}
                                handleClear={handleClear}
                                showOnlyIfActive={true}
                            />
                        ))}
                    </motion.div>
                    <InputShell>
                        <div className={classNames('w-full flex gap-6 items-center justify-between', rtl && 'flex-row-reverse')}>
                            <div {...getSlideAnimation(rtl, shouldSlide)} transition={{ ...getSlideAnimation(rtl, shouldSlide), delay: 0.05 }}>
                                <PseudoInput
                                    // iconSize={'5vw'}
                                    iconGap='gap-4'
                                    clear={() => handleClear('time')}
                                    toPage='app.offerSeats.time'
                                    extraFunction={() => updateDayAndOpenModal('general', null, false)}
                                    icon='Time'
                                    show={dateRange?.time}
                                    value={
                                        validateDateTime(['2022-04-11'], dateRange?.time)
                                            ? DateTime.fromISO(`2022-04-11T${dateRange?.time}`).toFormat(time24 ? 'HH:mm' : 'hh:mm a')
                                            : ''
                                    }
                                    placeholder={'e.page.publish.time'}
                                    width='w-vw-24'
                                />
                            </div>
                            <div {...getSlideAnimation(rtl, shouldSlide)} transition={{ ...getSlideAnimation(rtl, shouldSlide), delay: 0.1 }}>
                                <PseudoInput
                                    // iconSize={'5vw'}
                                    iconGap='gap-4'
                                    clear={() => handleClear('seats')}
                                    show={dateRange?.seats > 1}
                                    toPage='app.offerSeats.seats'
                                    icon='Users'
                                    value={dateRange?.seats}
                                    // width='w-vw-16'
                                    // justifyContent={'justify-end'}
                                    extraFunction={() => updateDayAndOpenModal('general', null, false)}
                                />
                            </div>
                        </div>
                    </InputShell>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    publish: state.publish
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePublishDate,
            updatePublishDateRange,
            updateSelectedDay
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMultipleInline);

const SwitchSectionCalendar = ({
    showOnlyIfActive,
    visible,
    dayIndex,
    dayBody,
    day,
    updateDay,
    generalTime,
    generalSeats,
    updateDayAndOpenModal,
    handleClear,

    label,
    color,
    colorOff,
    lock,
    border = 'border-b',
    icon,
    iconColorPreset,
    padding = 'px-vw-6a md:px-6a py-vw-4a'
}) => {
    const getDayTime = () => {
        if (dayBody.time) {
            return dayBody.time ?? generalTime;
        }
        return generalTime;
    };

    const getDaySeats = () => {
        if (dayBody.seats) {
            return dayBody.seats ?? generalSeats;
        }
        return generalSeats;
    };

    return (
        <GetLang>
            {({ rtl, lang, translations }) =>
                visible ? (
                    <button
                        style={{ direction: rtl ? 'rtl' : 'ltr' }}
                        className='focus:outline-none'
                        onClick={() => updateDay(dayIndex, { ...dayBody, enabled: !dayBody.enabled })}
                    >
                        <Pill
                            sizePreset='small-circle'
                            textSize={lang === 'ar' ? 'text-vw-xxs' : 'text-vw-sm'}
                            colorPreset={dayBody?.enabled ? 'light-blue' : 'faded'}
                            margins=''
                        >
                            {daysOfWeekStrings[lang][dayIndex][lang === 'ar' ? 'iso3' : 'iso2']}
                            {/* <FormattedDateTime dateString={DateTime.fromISO(day).setLocale(translations.formatting.locale).toFormat('ccc')} date /> */}
                        </Pill>
                    </button>
                ) : null
            }
        </GetLang>
    );
};
