import PageStaticTemplate from './PageStaticTemplate';

const PageStaticLadiesOnly = ({ overlay }) => {
    return (
        <PageStaticTemplate
            overlay={overlay}
            page='static.help.gender'
            apiUrl='/static-pages/6319ca2e54f75daca95deee2'
            icon='Female1'
            iconColor='text-gray-500'
            keepLoginStep
            analyticsPage='Ladies-only rides'
        />
    );
};

export default PageStaticLadiesOnly;
