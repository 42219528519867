import Icons from 'components/icons/index';
import classNames from 'classnames';
import { motion, useCycle } from 'framer-motion';
import { widthBiggerThan } from 'helpers/utils';

const BtnIcon = ({
    preset,
    handleClick,
    params = [],
    icon,
    size = 'w-vw-12 h-vw-12 md:h-12 md:w-12',
    iconSize = widthBiggerThan() ? '28px' : '7.7vw',
    flip,
    disabledClass = 'bg-gray-100 text-gray-300',
    disabled = false,
    tapClass = 'bg-bb-blue-50 text-bb-blue-500',
    color = 'text-gray-500',
    bgColor = 'bg-gray-100',
    noBg = false
}) => {
    const [anim, cycleAnim] = useCycle('off', 'on');

    const handleAction = () => {
        if (!disabled && handleClick) {
            if (params) handleClick(...params);
            else handleClick();
        }
    };

    return (
        <button
            key='motionButtonIcon'
            onClick={handleAction}
            role='button'
            aria-roledescription='button'
            onTouchStart={() => !disabled && cycleAnim()}
            onTouchEnd={() => !disabled && cycleAnim()}
            className={classNames('flex flex-none items-center justify-center transition-colors duration-100 focus:outline-none rounded-full', color, size, {
                [disabledClass]: disabled,
                [tapClass]: anim === 'on',
                [bgColor]: !noBg
            })}
        >
            <Icons icon={icon} size={iconSize} flip={flip} />
        </button>
    );
};

export default BtnIcon;
