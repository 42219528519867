import { getRelativeDate } from 'helpers/dates';
import { capitalize } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import CardSection from 'components/cards/CardSection';
import { mapTypeToSentence, routePath } from 'constants/structRoutes';
import { useNavigate, useLocation } from 'react-router-dom';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import StatusNotification from 'components/blocks/StatusNotification';
import UserDisplay from 'components/blocks/UserDisplay';

const CardUserAdmin = ({ data, data: { id, user, type, status, createdAt } }) => {
    // let { page, linkTo, from, to, tripDate } = data || {};

    const navigate = useNavigate();
    const location = useLocation();

    const handleButton = () => {
        navigate(`${routePath('app.admin.user')}?id=${user?.id}`, {
            state: { from: location.pathname, fromSearch: '', to: `${routePath('app.admin.user')}`, toSearch: `?id=${id}` }
        });
    };
    // console.log('data', data);
    // console.log('status', status);
    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <CardSection
                    arrow={true}
                    arrowGap='gap-2'
                    border='border-t-8 last:border-b-8 md:border-t-2 last:border-b-2'
                    borderColor={'border-gray-100'}
                    paddingY='py-vw-4 md:py-4'
                    handleClick={id ? handleButton : null}
                    bgColor={'bg-white'}
                >
                    <div className={classNames('absolute top-vw-2.5 ', rtl ? 'left-vw-6' : 'right-vw-12')}>
                        <UserDisplay
                            profile={data}
                            noName
                            // fullName
                            // phone
                            // email={data?.user?.email}
                            gender
                            nameClass='text-vw-sm md:text-base lg:text-sm'
                            emailClass='text-vw-xs'
                            banned
                        />
                    </div>
                    <div className={classNames('w-full flex justify-between', rtl && 'flex-row-reverse')}>
                        {/* <StatusNotification status={`VERIFICATION_${type}_${status}` ?? 'ERROR'} /> */}
                        <div className={classNames('text-gray-600 flex items-center gap-1 text-vw-sm md:text-base font-semibold', rtl && 'flex-row-reverse')}>
                            <FormattedDateTime dateTime={createdAt} both />
                        </div>
                    </div>

                    <div className={`mt-vw-4 md:mt-4 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                        <div className={classNames('flex items-center gap-4', rtl ? 'flex-row-reverse' : 'flex-row')}>
                            <Avatar
                                profile={data ?? { avatar: 'https://ucarecdn.com/e2b2a458-d347-4187-9570-df3852f03654/maskable_icon_x128.png' }}
                                size={'h-vw-12 w-vw-12 md:h-16 md:w-16'}
                                preset={'default'}
                            />
                            <div style={{ direction: rtl ? 'rtl' : 'ltr' }}>
                                <UserDisplay
                                    profile={data}
                                    fullName
                                    phone
                                    lang
                                    email={data?.user?.email}
                                    noVerification
                                    // gender
                                    nameClass='text-vw-sm md:text-base lg:text-sm'
                                    emailClass='text-vw-xs'
                                />
                            </div>
                        </div>
                    </div>
                </CardSection>
            )}
        </GetLang>
    );
};

export default CardUserAdmin;
