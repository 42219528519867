import { getRelativeDate } from 'helpers/dates';
import { capitalize } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import CardSection from 'components/cards/CardSection';
import { mapTypeToSentence, routePath } from 'constants/structRoutes';
import { useNavigate, useLocation } from 'react-router-dom';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import StatusNotification from './StatusNotification';

const Notification = ({ data: { data, type, createdAt, userWhoTookAction, isRead } }) => {
    let { page, linkTo, from, to, tripDate, amount } = data || {};

    const navigate = useNavigate();
    const location = useLocation();

    const handleButton = () => {
        // let fixTypo = linkTo.startsWith('rides/') ? linkTo.replace(`rides/`, 'rides/archived/') : linkTo;
        let fixTypo = linkTo;
        navigate(`${routePath('app')}/${fixTypo}`, {
            state: { from: location.pathname, to: `${routePath(page)}` }
        });
    };

    from = capitalize(from?.toLowerCase().replace(', lebanon', ''));
    to = capitalize(to?.toLowerCase().replace(', lebanon', ''));

    const getDate = (lang) => capitalize(getRelativeDate(tripDate, lang));

    const reloadApp = () => {
        window.location.reload();
    };

    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <CardSection
                    arrow={!!linkTo}
                    border='border-t-8 last:border-b-8 md:border-t-2 last:border-b-2'
                    borderColor={isRead ? 'border-gray-100' : 'border-bb-blue-75'}
                    paddingY='py-vw-4 md:py-4'
                    handleClick={type?.startsWith('VERIFICATION_') ? reloadApp : linkTo ? handleButton : null}
                    bgColor={isRead ? 'bg-white' : 'bg-bb-blue-50'}
                >
                    <div className={classNames('w-full flex justify-between', rtl && 'flex-row-reverse')}>
                        <StatusNotification status={type?.startsWith?.('REWARD') ? 'REWARD' : type ?? 'ERROR'} />
                        <div className={classNames('text-gray-600 flex items-center gap-1 text-vw-sm md:text-base font-semibold', rtl && 'flex-row-reverse')}>
                            <FormattedDateTime dateTime={createdAt} both />
                        </div>
                    </div>

                    <div className={`mt-vw-4 md:mt-4 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                        <div style={{ direction: rtl ? 'rtl' : 'ltr' }} className={classNames('flex  w-full')}>
                            <Avatar
                                profile={
                                    userWhoTookAction?.profile ?? { avatar: 'https://ucarecdn.com/e2b2a458-d347-4187-9570-df3852f03654/maskable_icon_x128.png' }
                                }
                                // size='w-vw-10 h-vw-10'
                                // iconSize={24}
                            />
                            <div className={classNames(rtl ? 'mr-vw-4 md:mr-4' : 'ml-vw-4 md:ml-4', 'w-full relative')}>
                                <div className={classNames('text-vw-sm md:text-base text-gray-500 font-medium w-vw-60a flex-none', rtl && 'text-right')}>
                                    {type?.startsWith('BOOKING_') && (
                                        <>
                                            {userWhoTookAction?.profile?.firstName && (
                                                <span className='font-bold'>
                                                    {userWhoTookAction?.profile?.displayName || userWhoTookAction?.profile?.firstName}
                                                </span>
                                            )}
                                            {translations.data[mapTypeToSentence[type].body]}
                                            <span className='font-bold'>{getDate(lang)}</span>
                                            {translations.data['e.notification.booking.from']}
                                            <span className='font-bold'>{from}</span>
                                            {translations.data['e.notification.booking.to']}
                                            <span className='font-bold'>{to}</span>
                                        </>
                                    )}
                                    {type?.startsWith('REWARD_') && amount && (
                                        <>
                                            <span className='font-bold'>{`${amount} ${translations.data['e.notification.reward.points']} `}</span>
                                            <span> {translations.data['e.notification.reward.body']}</span>
                                            <span> {translations.data[mapTypeToSentence[type].title]}</span>
                                        </>
                                    )}
                                    {type?.startsWith('MESSAGE_') && (
                                        <>
                                            {userWhoTookAction?.profile?.firstName && (
                                                <span className='font-bold'>
                                                    {userWhoTookAction?.profile?.displayName || userWhoTookAction?.profile?.firstName}
                                                </span>
                                            )}
                                            {translations.data['e.notification.messageReceived.body']}
                                        </>
                                    )}
                                    {type?.startsWith('VERIFICATION_') && (
                                        <>
                                            <span> {translations.data[mapTypeToSentence[type]?.body]}</span>
                                        </>
                                    )}
                                    {!type && <>{translations.data[mapTypeToSentence['ERROR'].body]}</>}
                                </div>
                            </div>
                        </div>
                    </div>
                </CardSection>
            )}
        </GetLang>
    );
};

export default Notification;
