import Icons from 'components/icons/index';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const styles = {
    green: {
        iconColor: 'text-emerald-600',
        iconBg: 'bg-emerald-200',
        icon: 'Check',
        iconSize: '4.8vw',
        circleSize: 'w-vw-8 h-vw-8',
        blockClass: 'text-vw-base, font-semibold text-gray-500'
    },
    blue: {
        iconColor: 'text-bb-blue-500',
        iconBg: 'bg-bb-blue-75',
        icon: 'Add',
        iconSize: '4.8vw',
        circleSize: 'w-vw-8 h-vw-8',
        blockClass: 'text-vw-base, font-semibold text-gray-500'
    },
    pending: {
        iconColor: 'text-gray-500',
        iconBg: 'bg-gray-200',
        icon: 'Time',
        iconSize: '4.8vw',
        circleSize: 'w-vw-8 h-vw-8',
        blockClass: 'text-vw-base, font-semibold text-gray-500'
    }
};

const ListItem = ({ icon, iconColor, iconBg, iconSize, circleSize, preset = 'green', blockClass, children, approved }) => {
    return (
        <GetLang>
            {({ rtl }) => (
                <div className={classNames('flex gap-4 ', rtl && 'flex-row-reverse')}>
                    <div
                        className={classNames(
                            'rounded-full flex flex-none items-center justify-center',
                            circleSize ?? styles[preset].circleSize,
                            iconColor ?? styles[preset].iconColor,
                            iconBg ?? styles[preset].iconBg
                        )}
                    >
                        <Icons icon={icon ?? styles[preset].icon} size={iconSize ?? styles[preset].iconSize} />
                    </div>

                    <div
                        style={{
                            direction: rtl ? 'rtl' : 'ltr'
                        }}
                        className={classNames(blockClass ?? styles[preset].blockClass, 'mt-vw-0.5')}
                    >
                        {children}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default ListItem;
