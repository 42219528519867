import React, { useEffect, useRef, useState } from 'react';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';

import './MessagingChannelHeader.css';

import { TypingIndicator } from '../TypingIndicator/TypingIndicator';

import BtnIcon from 'components/buttons/BtnIcon';
import Avatar from 'components/blocks/Avatar';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';
import { useNavigate, useLocation } from 'react-router-dom';

const MessagingChannelHeader = (props) => {
    const { client } = useChatContext();
    const { channel, members: channelMembers } = useChannelStateContext();
    const {
        goBack,
        handleOpen,
        isRootMenu,
        isOpen,
        menuSection,
        banned

        // handleContainer,
        // setChannel,
        // setMember,
        // setMembers,
        // isDoing
    } = props;
    const navigate = useNavigate();
    const location = useLocation();

    // const members = Object.values(channel.state?.members || {}).filter((member) => member.user?.id !== client.user?.id);
    const members = Object.values(channelMembers || {}).filter((member) => member.user?.id !== client.user?.id);

    const [channelName, setChannelName] = useState(members?.[0]?.user?.name || 'generic convo');

    const handleButton = () => {
        if (goBack) {
            const { prevState } = location.state;
            navigate(`${location.state.from}${location.state.fromSearch ?? ''}`, {
                state: {
                    from: prevState ? prevState.from : location.pathname,
                    fromSearch: prevState ? prevState.fromSearch : location.search,
                    to: prevState ? prevState.to : location.state.from,
                    toSearch: prevState ? prevState.fromSearch : location.state.fromSearch,
                    fromChat: location.state.from === '/c/user'
                    // prevState: location?.state?.prevState
                    //     ? {
                    //           from: location.state.prevState.from,
                    //           fromSearch: location.state.prevState.fromSearch,
                    //           to: location.state.prevState.to,
                    //           toSearch: location.state.prevState.toSearch
                    //       }
                    //     : null
                }
            });
        } else props.toggleMobile(true);
    };

    // useEffect(() => {
    //     console.log('channel members changer', channelMembers);
    // }, [channelMembers]);

    // useEffect(() => {
    //     const container = document.querySelector('.str-chat__list');

    //     handleContainer(container);
    //     setChannel(channel);
    //     setMember(members?.[0]?.user?.id);
    //     const updateChannelUsers = () => {
    //         setMembers(channel.state.members);
    //         console.log('something updated in channel', channel);
    //     };
    //     updateChannelUsers();
    //     client.on('user.banned', updateChannelUsers);
    //     client.on('user.unbanned', updateChannelUsers);

    //     return () => {
    //         client.off('user.banned', updateChannelUsers);
    //         client.off('user.unbanned', updateChannelUsers);
    //     };
    // }, [channel]);
    // useEffect(() => {
    //     console.log('channel header mounted');
    // }, []);

    // console.log('channel header rendered');

    return (
        <GetLang>
            {({ rtl }) => (
                <div className='absolute-a top-0-a w-full min-h-vw-16 md:min-h-20 md:h-20 border-b-2 border-gray-100 z-900 bg-white select-none flex items-center'>
                    <div
                        className={classNames(
                            'w-full py-vw-2 px-vw-2 md:py-0 flex items-center justify-between',
                            rtl && 'flex-row-reverse',
                            rtl ? 'md:pl-4 md:pr-12' : 'md:pl-12 md:pr-4'
                        )}
                    >
                        <div
                            className={classNames(
                                'duration-300 ease-in-out flex items-center gap-2',
                                rtl && 'flex-row-reverse',
                                isOpen ? (rtl ? 'translate-x-vw-10 md:translate-x-0' : '-translate-x-vw-10 md:translate-x-0') : '',
                                menuSection === 'profile' ? '-translate-y-16 md:translate-y-0' : ''
                            )}
                        >
                            <span className={classNames('md:hidden duration-300 ease-in-out', isOpen && 'opacity-0')}>
                                <BtnIcon icon={rtl ? 'ArrowRightLong1' : 'ArrowLeftLong1'} noBg handleClick={isOpen ? null : handleButton} />
                            </span>

                            <Avatar
                                profile={{ avatar: members?.[0]?.user?.image, verifiedProfile: members?.[0]?.user?.verifiedProfile }}
                                banned={banned}
                                size='w-vw-10 h-vw-10 md:w-10 md:h-10'
                                iconSize={28}
                            />
                            <div className='mx-2 text-vw-lg md:text-lg font-semibold truncate text-bb-blue-550'>{channelName}</div>

                            {/* <div className='messaging__channel-header__right'>
                                <TypingIndicator />
                            </div> */}
                        </div>
                        <div
                            className={classNames('flex items-center', {
                                'flex-row-reverse': rtl
                            })}
                        >
                            {!isRootMenu && isOpen && <BtnIcon flip={rtl} icon={'ArrowBack'} noBg handleClick={() => handleOpen(true)} />}
                            <BtnIcon icon={isOpen ? 'Close' : 'MoreH'} noBg handleClick={() => handleOpen()} />
                        </div>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default React.memo(MessagingChannelHeader);
