import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import { widthBiggerThan } from 'helpers/utils';

const Rating = ({
    className = 'text-gray-400 font-bold gap-2',
    iconClass,
    reviewsClass = 'text-vw-base md:text-base lg:text-sm',
    iconSize = widthBiggerThan() ? '16px' : '4.3vw',
    rating,
    reviews,
    showReviews
}) => {
    const getReviewNumber = (lang) => {
        if (lang === 'ar') {
            if (Number(reviews) === 1 || Number(reviews) > 10) return 'e.reviews.1';
            if (Number(reviews) === 2) return 'e.reviews.2';
            if (Number(reviews) > 2 || Number(reviews) < 11) return 'e.reviews.3';
        } else {
            return Number(reviews) > 1 ? 'e.reviews.2' : 'e.reviews.1';
        }
    };
    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <div className={classNames(className, reviewsClass, 'flex items-center', rtl ? 'flex-row-reverse' : 'flex-row')}>
                    <Icons icon={'StarFilled'} size={iconSize} containerClass={iconClass} />
                    <div className={classNames()}>{rating && reviews ? `${rating?.toFixed(1)}/5` : `--/5`}</div>

                    {reviews && showReviews && (
                        <>
                            <span className='h-0.5 bg-gray-400 w-4'></span>
                            <span style={{ direction: rtl ? 'rtl' : 'ltr' }} className={reviewsClass}>{`${reviews} ${
                                translations.data[getReviewNumber(lang)]
                            }`}</span>
                        </>
                    )}
                </div>
            )}
        </GetLang>
    );
};

export default Rating;
