import { defaultSize, defaultStroke } from './defaults';
const IconDraft2 = ({ size, className }) => {
    return (
        <>
            <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={size || defaultSize}
                height={size || defaultSize}
            >
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <g fill='none'>
                        <path d='M1.938,16.938C7.5,13.625,8.5,9.438,8.5,6.5s-2.469-2.969-4.125.656-.906,9.531,1.75,10.094,6.656-2.531,7.5-5.25-1.5-3.469-3.469,0-.094,6.313,3.875,5.094c1.838-.564,4.281-2.125,5.125-4s-1-3.02-2.344-1.375c-2.5,3.063-.312,5.969,1.813,5.375a6.634,6.634,0,0,0,3.406-2.781' />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconDraft2;
