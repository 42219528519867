import Icons from 'components/icons/index';
import { avatarCircles, avatarStyles as styles } from 'constants/styles';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import Roundify from './Roundify';

const UserDisplay = ({
    profile,
    preset = 'default',
    noName,
    className,
    noVerification,
    fullName,
    phone,
    email,
    emailClass = 'text-vw-sm',
    gender,
    nameClass = 'text-vw-base md:text-base lg:text-sm',
    banned,
    lang
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <>
                    <div className={classNames('flex items-center gap-4', className, rtl && 'flex-row-reverse justify-end')}>
                        {!noName && (
                            <div
                                className={classNames(
                                    rtl ? 'text-right' : 'text-left',
                                    styles[preset].name,
                                    preset === 'dark' && profile?.firstName?.length > 12
                                        ? ' text-vw-base md:text-base lg:text-sm'
                                        : fullName
                                        ? nameClass
                                        : 'text-vw-xl md:text-xl lg:text-lg'
                                )}
                            >
                                {fullName ? `${profile?.firstName} ${profile?.lastName}` : profile?.firstName}
                            </div>
                        )}

                        {!noVerification && (
                            <>
                                {(profile?.verifiedProfile || profile?.verifiedCarRegistration || gender || profile?.user?.blockUser) && (
                                    <div style={{ direction: 'ltr' }} className={classNames('flex items-center gap-1', rtl && 'flex-row-reverse')}>
                                        {!profile?.user?.blockUser && (
                                            <>
                                                {profile?.verifiedProfile && (
                                                    <div>
                                                        <Roundify
                                                            bgColor={avatarCircles['large'].verify.bgColor}
                                                            color={avatarCircles['large'].verify.color}
                                                            width={avatarCircles['large'].verify.width}
                                                            height={avatarCircles['large'].verify.height}
                                                            extraClass={avatarCircles['large'].verify.extraClass}
                                                        >
                                                            <Icons icon={'Id2'} size={avatarCircles['large'].verify.iconSize} />
                                                        </Roundify>
                                                    </div>
                                                )}
                                                {profile?.verifiedCarRegistration && (
                                                    <div>
                                                        <Roundify
                                                            bgColor={avatarCircles['large'].verifyCar.bgColor}
                                                            color={avatarCircles['large'].verifyCar.color}
                                                            width={avatarCircles['large'].verifyCar.width}
                                                            height={avatarCircles['large'].verifyCar.height}
                                                            extraClass={avatarCircles['large'].verifyCar.extraClass}
                                                        >
                                                            <Icons icon={'Car1'} size={avatarCircles['large'].verifyCar.iconSize} />
                                                        </Roundify>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {gender && profile?.gender && (
                                            <div>
                                                <Roundify
                                                    bgColor={avatarCircles['large'][profile?.gender]?.bgColor}
                                                    color={avatarCircles['large'][profile?.gender]?.color}
                                                    width={avatarCircles['large'][profile?.gender]?.width}
                                                    height={avatarCircles['large'][profile?.gender]?.height}
                                                    extraClass={avatarCircles['large'][profile?.gender]?.extraClass}
                                                >
                                                    <Icons
                                                        icon={avatarCircles['large'][profile?.gender]?.icon}
                                                        size={avatarCircles['large'][profile?.gender]?.iconSize}
                                                    />
                                                </Roundify>
                                            </div>
                                        )}
                                        {banned && profile?.user?.blockUser && (
                                            <div>
                                                <Roundify
                                                    bgColor={avatarCircles['large'].banned?.bgColor}
                                                    color={avatarCircles['large'].banned?.color}
                                                    width={avatarCircles['large'].banned?.width}
                                                    height={avatarCircles['large'].banned?.height}
                                                    extraClass={avatarCircles['large'].banned?.extraClass}
                                                >
                                                    <Icons icon={avatarCircles['large'].banned?.icon} size={avatarCircles['large'].banned?.iconSize} />
                                                </Roundify>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {(phone || email || lang) && (
                        <div
                            style={{ direction: rtl ? 'rtl' : 'ltr' }}
                            className={classNames(
                                rtl && 'text-right',
                                'flex w-full flex-col mt-vw-1 font-bold text-gray-400 ',

                                emailClass
                            )}
                        >
                            {/* {phone && (
                                <span style={{ direction: 'ltr' }} className={classNames('')}>
                                    +{profile?.mobile}
                                </span>
                            )} */}
                            {(phone || lang) && (
                                <div className={classNames('flex gap-2')}>
                                    {phone && (
                                        <span style={{ direction: 'ltr' }} className={classNames('')}>
                                            +{profile?.mobile}
                                        </span>
                                    )}
                                    {lang && <span className={classNames('uppercase')}>{`(${profile?.lang})`}</span>}
                                </div>
                            )}
                            {email && <div className={classNames('w-vw-60 truncate')}>{email}</div>}
                        </div>
                    )}
                </>
            )}
        </GetLang>
    );
};

export default UserDisplay;
