import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StreamChat } from 'stream-chat';

import { useChecklist } from './ChecklistTasks';

import 'stream-chat-react/dist/css/index.css';
import './App.css';
import { Chat, Channel, ChannelList, ChannelHeader, MessageInput, MessageList, Thread, Window, useChannelStateContext } from 'stream-chat-react';
import {
    CreateChannel,
    CustomMessage,
    MessagingChannelList,
    MessagingChannelPreview,
    MessagingInput,
    MessagingThreadHeader,
    TypingIndicator
} from './components';

import { getRandomImage } from './assets';
import ChannelInner from './components/ChannelInner/ChannelInner';
import { useSearchParams } from 'react-router-dom';
import { buildQueryString, paramsToObject } from 'helpers/helperFunctions';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';
import CustomDateSeparator from './components/CustomDateSeparator/CustomDateSeparator';
import ModalBottom from 'components/modals/ModalBottom';
import OfflineMode from 'components/blocks/OfflineMode';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';

import { banUser } from 'fetch/apiRequests';
import TabCom from 'components/tabs/TabCom';
import { setChannelActive } from 'store/actions/chat';
import BtnRequestCancel from 'components/buttons/BtnRequestCancel';
import { defaultSize } from 'components/icons/defaults';
import { analytics } from 'helpers/segment';

const customClasses = {
    chat: 'h-body'
};

const options = { state: true, watch: true, presence: true, limit: 30 };

const sort = {
    last_message_at: -1,
    updated_at: -1,
    cid: 1
};

const targetOrigin = '/';

const PageMessages = ({ setChannelActive, chat: { channelActive, chatToken, user, otherUser }, auth }) => {
    const profile = auth?.user?.profile;
    const phoneNumber = auth?.user?.phoneNumber;
    let [searchParams] = useSearchParams();
    const params = paramsToObject(searchParams.entries());

    const [chatClient, setChatClient] = useState(null);
    const [channel, setChannel] = useState(null);

    const [isCreating, setIsCreating] = useState(false);
    const [isMobileNavVisible, setMobileNav] = useState(true);
    const [connecting, setIsConnecting] = useState(false);

    const [isModal, setIsModal] = useState(false);

    const [isModalAction, setIsModalAction] = useState(false);
    const [mode, setMode] = useState(null);

    const filters = {
        type: 'messaging',
        members: { $in: [user?.id] }
        // member_count: 2
    };

    // useEffect(() => {
    //     console.log('nav visible', isMobileNavVisible);
    // }, [isMobileNavVisible]);

    useEffect(() => {
        analytics.page('Chat', 'App', auth?.isAuthenticated);
    }, []);

    useEffect(() => {
        let client;
        if (user) {
            const initChat = async () => {
                setIsConnecting(true);
                try {
                    client = StreamChat.getInstance('kvdxukvtux28' || process.env.REACT_APP_STREAM_API_KEY);

                    await client.connectUser(
                        {
                            id: user.id,
                            name: profile?.displayName || profile?.firstName,
                            image: profile?.avatar
                        },
                        chatToken
                    );

                    setChatClient(client);

                    if (params.otherUser) {
                        setMobileNav(false);
                        setChannelActive(true);
                    }
                    setIsConnecting(false);
                } catch (error) {
                    console.log(error);
                    setIsConnecting(false);
                }
            };
            if (!connecting) initChat();
        }
        return () => client?.disconnectUser();
    }, [user, profile]);

    // useDidMountEffect(() => {
    //     if (user) {
    //         const initChat = async () => {
    //             try {
    //                 const client = StreamChat.getInstance('kvdxukvtux28' || process.env.REACT_APP_STREAM_API_KEY);

    //                 await client.connectUser(
    //                     {
    //                         id: user.id,
    //                         name: profile?.displayName ?? profile?.firstName,
    //                         image: profile?.avatar
    //                     },
    //                     chatToken
    //                 );

    //                 setChatClient(client);

    //                 if (params.otherUser) {
    //                     setMobileNav(false);
    //                 }
    //             } catch (error) {
    //                 console.log(error);
    //             }
    //         };

    //         initChat();
    //     }
    //     return () => chatClient?.disconnectUser();
    // }, [user]);
    const watchChannel = async () => {
        await channel?.watch({ presence: true });
        // console.log(channel.state.members);
    };
    useEffect(() => {
        watchChannel();
        // console.log('channel watched');
        allSticky.current = [];
    }, [channel, chatClient]);

    useEffect(() => {
        if (isMobileNavVisible) {
            watchChannel();
            // console.log('going from channel to menu');
        }
    }, [isMobileNavVisible]);

    const toggleMobile = (val) => {
        setMobileNav(val ?? !isMobileNavVisible);
        setChannelActive(val ? !val : !channelActive);
    };
    const container = useRef(null);

    const allSticky = useRef([]);
    const [stickyId, setStickyId] = useState(null);

    const handleSticky = (dateId, isSticky) => {
        const foundIndex = allSticky?.current?.findIndex((item) => item === dateId);

        if (isSticky) {
            if (foundIndex === -1) allSticky.current.push(dateId);
        } else {
            if (foundIndex > -1) allSticky.current.splice(foundIndex, 1);
        }
        setStickyId(allSticky?.current?.[allSticky.current.length - 1]);
        // console.log('all sticky are after function', allSticky.current);
    };

    const handleContainer = (ref) => {
        container.current = ref;
        // clear dateArr
        allSticky.current = [];
    };

    useEffect(() => {
        // console.log('Conversation Page mounted');
        return () => setChannelActive(false);
    }, []);

    useEffect(() => {
        // console.log(mode);
        if (mode) {
            setIsModal(true);
        }
    }, [mode]);

    const handleModalClose = () => {
        setMode(null);
        setIsModal(false);
    };

    const handleDelete = async () => {
        if (mode) {
            setIsModalAction(true);
            setIsModal(false);
            await mode.channel.hide(null, true);
            setIsModalAction(false);
            setMode(null);
        }
    };
    const handleBlock = async () => {
        if (mode) {
            // console.log('blocked');
            // setIsModalAction(true);
            // setIsModal(false);
            // await mode.channel.hide(null, true);
            // setIsModalAction(false);
            // setMode(null)
        }
    };

    const handleCreate = () => {
        setIsCreating(!isCreating);
    };

    const handleList = (props) => <MessagingChannelList {...props} onCreateChannel={handleCreate} params={params} />;

    const handlePreview = (props) => (
        <MessagingChannelPreview
            {...props}
            {...{
                setIsCreating,
                toggleMobile,
                setMode,
                channelActive
            }}
        />
    );

    const handleDateSeparator = (props) => (
        <CustomDateSeparator
            {...props}
            container={container}
            handleSticky={handleSticky}
            allSticky={allSticky}
            currentSticky={allSticky?.current?.[allSticky.current.length - 1]}
        />
    );

    const handleCustomMessage = (props) => <CustomMessage {...props} />;

    if (!chatClient) return null;

    return (
        <GetLang>
            {({ rtl, translations }) => (
                <>
                    {/* {isMobileNavVisible && <TabCom initial='messages' />} */}
                    {/* <div className={classNames('fixed h-body-w-header2 h-vh-100 md:h-vh-100 oveflow-hidden w-vw-100 md:w-full md:top-20')}> */}

                    <div
                        // style={{ zIndex }}
                        className='fixed md:statica inset-0  bg-white text-vw-base md:text-base no-scrollbar lining-nums md:mt-20'
                    >
                        <div
                            // style={{ transitionDuration: '2000ms' }}
                            className={classNames(rtl && 'flex-row-reverse', 'duration-300 transition-all ease-in-out-expo flex flex-nowrap md:w-full', {
                                'translate-x-0': isMobileNavVisible,
                                '-translate-x-vw-100 md:translate-x-0': !isMobileNavVisible && !rtl,
                                'translate-x-vw-100 md:translate-x-0': !isMobileNavVisible && rtl
                            })}
                        >
                            <Chat client={chatClient} theme={`messaging light`}>
                                <div
                                    id='mobile-channel-list2'
                                    // className={classNames('tran' isMobileNavVisible && 'show', 'h-body')}
                                    className={classNames(
                                        'w-vw-100 md:w-auto flex-none h-body-w-header2 h-vh-100 md:h-full overflow-y-hidden md:shadow-double-2 md:z-1000'
                                    )}
                                >
                                    <div className='md:hidden'>
                                        <TabCom initial='messages' />
                                    </div>
                                    <ChannelList filters={filters} sort={sort} options={options} List={handleList} Preview={handlePreview} />
                                </div>
                                <div className={classNames('w-vw-100 md:w-max flex-none md:flex-1 relative')}>
                                    <Channel
                                        // Input={MessagingInput}
                                        maxNumberOfFiles={10}
                                        Message={handleCustomMessage}
                                        multipleUploads={true}
                                        ThreadHeader={MessagingThreadHeader}
                                        TypingIndicator={TypingIndicator}
                                        DateSeparator={handleDateSeparator}
                                    >
                                        <ChannelInner
                                            theme={'light'}
                                            toggleMobile={toggleMobile}
                                            mobileNav={isMobileNavVisible}
                                            params={params}
                                            handleContainer={handleContainer}
                                            setChannel={setChannel}
                                            userId={user.id}
                                            phoneNumber={phoneNumber}
                                        />
                                    </Channel>
                                </div>
                            </Chat>
                        </div>
                    </div>

                    <ModalBottom
                        isOpen={isModal}
                        handleOpen={handleModalClose}
                        // height=''
                    >
                        <div className='px-vw-6 py-vw-6 md:px-6 md:py-6 flex flex-col items-center'>
                            <OfflineMode margins='' />

                            {mode?.type === 'BLOCK_USER' && (
                                <>
                                    <div className='px-vw-2 mt-vw-4 mb-vw-6 md:px-2 md:mt-4 md:mb-6 font-semibold text-vw-lg md:text-lg text-center text-gray-600'>
                                        {translations?.data?.['e.booking.status.archive.headline']}
                                    </div>
                                    <BtnPill type='primary' justify={'justify-between'} rtl={rtl} params={[]} handleClick={handleBlock}>
                                        <>
                                            <span className='w-vw-6 md:w-6' />
                                            {translations?.data?.['e.booking.status.archive']}
                                            <Icons icon={'Archive'} size={'5.8vw'} />
                                        </>
                                    </BtnPill>
                                    <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                        {translations?.data?.['e.later']}
                                    </BtnPill>
                                </>
                            )}
                            {mode?.type === 'DELETE_CONVERSATION' && (
                                <>
                                    <div className='px-vw-2 mt-vw-4 mb-vw-6 md:px-2 md:mt-4 md:mb-6 font-semibold text-vw-lg md:text-lg text-center text-gray-600'>
                                        {translations.data['e.chat.clear.title']}
                                    </div>
                                    <BtnPill type='cancel' justify={'justify-between'} rtl={rtl} params={[]} handleClick={handleDelete}>
                                        <>
                                            <span className='w-vw-6' />
                                            {translations?.data?.['e.chat.clear.action']}
                                            <Icons icon={'Trash'} size={defaultSize} />
                                        </>
                                    </BtnPill>
                                    <BtnPill type='default' justify={'justify-center'} rtl={rtl} params={[false]} handleClick={handleModalClose}>
                                        {translations?.data?.['e.later']}
                                    </BtnPill>
                                </>
                            )}
                        </div>
                    </ModalBottom>
                </>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    chat: state.chat
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ setChannelActive }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageMessages);
