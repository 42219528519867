import { Widget } from '@uploadcare/react-widget';

import 'styles/uploadCare.css';

const UC_VERIF_KEY_PUBLIC = process.env.REACT_APP_UC_VERIF_KEY_PUBLIC;
const UC_CONTENT_KEY_PUBLIC = process.env.REACT_APP_UC_CONTENT_KEY_PUBLIC;

const Uploader = ({ widgetRef, handleUpdate, params, multiple = true, maxImages = 1, verify }) => {
    const handleUpload = (res) => {
        handleUpdate(res, ...params);
    };

    return (
        <Widget
            ref={widgetRef}
            publicKey={verify ? UC_VERIF_KEY_PUBLIC : UC_CONTENT_KEY_PUBLIC}
            multiple={multiple}
            multipleMax={maxImages}
            previewStep='true'
            imagesOnly={true}
            imageShrink='2048x2048'
            tabs={'file camera'}
            // tabs={'file camera instagram'}
            // preloader={null}
            onChange={handleUpload}
        />
    );
};

export default Uploader;
