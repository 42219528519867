import PageStaticTemplate from './PageStaticTemplate';

const PageStaticSupport = ({ overlay }) => {
    return (
        <PageStaticTemplate
            page='static.help.support'
            apiUrl='/static-pages/62fbe3b2e2654aac50328df1'
            icon='Support1'
            overlay={overlay}
            analyticsPage='Contact Support'
        />
    );
};

export default PageStaticSupport;
