import { getDateString } from 'helpers/dates';

export const defaultOnboarding = () => ({
    firstTime: true,
    tooltips: {
        ridesOffered: true,
        ridesOfferedArchived: true,
        ridesBooked: true,
        ridesBookedArchived: true,
        manageBookings: true,
        tripViewBookings: true,
        publishPricing: true,
        viewPricing: true,
        ladiesOnly: true,
        searchResults: true
    }
});
export const defaultNewUserInfo = () => ({
    firstName: {
        value: '',
        isValid: false
    },
    lastName: {
        value: '',
        isValid: false
    },
    email: {
        value: '',
        isValid: false
    },
    gender: {
        value: null,
        isValid: false
    }
});

const localeLocation = () => {
    return {
        formatted: '',
        precise: '',
        address: '',
        locality: '',
        admin_l2: '',
        admin_l1: ''
    };
};

export const defaultLocationObj = () => {
    return {
        location: {
            type: 'Point',
            coordinates: [],
            zoom: 8
        },
        place_id: '',
        en: localeLocation(),
        ar: localeLocation()
    };
};

export const defaultSearchObj = () => {
    return {
        seats: 1,
        from: defaultLocationObj(),
        to: defaultLocationObj(),
        dateRange: [],
        filters: {
            ladiesOnly: false,
            punctual: true,
            recurring: true,
            archived: false,
            dateRange: [false, false, false, false, false, false, false]
        }
        // dateRange: [getDateString().date]
    };
};

export const defaultWeekObj = () => {
    return {
        0: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        1: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        2: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        3: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        4: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        5: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        6: {
            enabled: false,
            timeOverride: false,
            time: '',
            seatsOverride: false,
            seats: null,
            isoTime: ''
        },
        time: '',
        seats: 1

        // dateRange: [getDateString().date]
    };
};

export const defaultPublishObj = () => {
    return {
        seatsOffered: 1,
        description: '',
        preferences: {},
        from: defaultLocationObj(),
        to: defaultLocationObj(),
        date: [getDateString().date],
        dateRange: defaultWeekObj(),
        time: '',
        ladiesOnly: false,
        recurring: true
    };
};

export const genderList = [
    { id: 'male', text: 'e.genderType.male' },
    { id: 'female', text: 'e.genderType.female' },
    { id: 'na', text: 'e.genderType.na' }
];

export const langList = [
    { id: 'en', text: 'e.languages.en' },
    { id: 'fr', text: 'e.languages.fr' },
    { id: 'ar', text: 'e.languages.ar' }
];

export const yesNoNullList = [
    { id: 'yes', text: 'e.list.true', value: true },
    { id: 'no', text: 'e.list.false', value: false },
    { id: 'none', text: 'e.list.null', value: null }
];

export const genderListVerify = [
    { id: 'male', text: 'e.genderType.male' },
    { id: 'female', text: 'e.genderType.female' },
    { id: 'na', text: 'e.genderType.select' }
];

export const genderListSignup = [
    { id: '000', text: 'e.genderType.select' },
    { id: 'male', text: 'e.genderType.male' },
    { id: 'female', text: 'e.genderType.female' },
    { id: 'na', text: 'e.genderType.na' }
];

export const daysOfWeek = [
    '2022-04-11T00:00:00.001',
    '2022-04-12T00:00:00.001',
    '2022-04-13T00:00:00.001',
    '2022-04-14T00:00:00.001',
    '2022-04-15T00:00:00.001',
    '2022-04-16T00:00:00.001',
    '2022-04-17T00:00:00.001'
];

export const daysOfWeekStrings = {
    en: [
        {
            full: 'Monday',
            iso3: 'Mon',
            iso2: 'Mo',
            iso1: 'M'
        },
        {
            full: 'Tuesday',
            iso3: 'Tue',
            iso2: 'Tu',
            iso1: 'T'
        },
        {
            full: 'Wednesday',
            iso3: 'Wed',
            iso2: 'We',
            iso1: 'W'
        },
        {
            full: 'Thursday',
            iso3: 'Thu',
            iso2: 'Th',
            iso1: 'T'
        },
        {
            full: 'Friday',
            iso3: 'Fri',
            iso2: 'Fr',
            iso1: 'F'
        },
        {
            full: 'Saturday',
            iso3: 'Sat',
            iso2: 'Sa',
            iso1: 'S'
        },
        {
            full: 'Sunday',
            iso3: 'Sun',
            iso2: 'Su',
            iso1: 'S'
        }
    ],
    fr: [
        {
            full: 'Lundi',
            iso3: 'lun.',
            iso2: 'lu.',
            iso1: 'L'
        },
        {
            full: 'Mardi',
            iso3: 'mar.',
            iso2: 'ma.',
            iso1: 'M'
        },
        {
            full: 'Mercredi',
            iso3: 'mer',
            iso2: 'me',
            iso1: 'M'
        },
        {
            full: 'Jeudi',
            iso3: 'jeu.',
            iso2: 'je.',
            iso1: 'J'
        },
        {
            full: 'Vendredi',
            iso3: 'ven.',
            iso2: 've.',
            iso1: 'V'
        },
        {
            full: 'Samedi',
            iso3: 'sam.',
            iso2: 'sa.',
            iso1: 'S'
        },
        {
            full: 'Dimanche',
            iso3: 'dim.',
            iso2: 'di.',
            iso1: 'D'
        }
    ],
    ar: [
        {
            full: 'الاثنين',
            iso3: 'اثنين',
            iso2: 'ﺇ',
            iso1: 'ﺇ'
        },
        {
            full: 'الثلاثاء',
            iso3: 'ثلاثاء',
            iso2: 'ث',
            iso1: 'ث'
        },
        {
            full: 'الأربعاء',
            iso3: 'أربعاء',
            iso2: 'أ',
            iso1: 'أ'
        },
        {
            full: 'الخميس',
            iso3: 'خميس',
            iso2: 'خ',
            iso1: 'خ'
        },
        {
            full: 'الجمعة',
            iso3: 'جمعة',
            iso2: 'ج',
            iso1: 'ج'
        },
        {
            full: 'السبت',
            iso3: 'سبت',
            iso2: 'س',
            iso1: 'س'
        },
        {
            full: 'الأحد',
            iso3: 'أحد',
            iso2: 'أ',
            iso1: 'أ'
        }
    ]
};
