export const regexString = /^([^0-9!@#$%^&*()_+=[\]{}\\|\/<>,`~;:"?]*)$/;
export const regexString2 = /^([^0-9!@#$%^&*()_+=[\]{}\\|\/<>,`~;:"?]){2,}$/;
// export const regexEmail =
//     /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,6}$/;
export const regexString3 = /^[^-\s0-9!@#$%^&*()_+=[\]{}\\|\/<>,`~;:"?]([^0-9!@#$%^&*()_+=[\]{}\\|\/<>,`~;:"?]){1,}$/;
export const regexString16 =
    /^[^-\s0-9!@#$%^&*()_+=[\]{}\\|\/<>,.`~;:"?]([^0-9!@#$%^&*()_+=[\]{}\\|\/<>,`~;:"?]){1,14}[^-\s0-9!@#$%^&*()_+=[\]{}\\|\/<>,.`~;:"?]$/;
export const regexString20 =
    /^[^-\s0-9!@#$%^&*()_+=[\]{}\\|\/<>,.`~;:"?]([^0-9!@#$%^&*()_+=[\]{}\\|\/<>,`~;:"?]){1,18}[^-\s0-9!@#$%^&*()_+=[\]{}\\|\/<>,.`~;:"?]$/;
export const regexDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

export const regexEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexWhiteSpace = /^\S+(?: \S+)*$/;
export const regexPassword = /^[a-zA-Z0-9$|~=[\]'_+@.-]{8,}$/;
export const regexTime = /^[0-9]?[0-9]?[0-9]?[0-9]$/;
// export const regexTime = /^([0-1]?[0-9]|2[0-3])[0-5][0-9]$/;
export const regexUsername = /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
