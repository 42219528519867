import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import Icons from 'components/icons/index';

const styles = {
    error: {
        box: 'bg-red-50 text-red-500',
        text: 'text-red-500'
    },
    warning: {
        box: 'bg-yellow-100 text-yellow-600',
        text: 'text-yellow-600'
    },
    approve: {
        box: 'bg-emerald-50 text-emerald-500',
        text: 'text-emerald-500'
    }
};

const NotificationBox = ({
    icon = 'Checked',
    type = 'error',
    message,
    children
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div
                    className={classNames(
                        'w-full p-vw-4 rounded-2xl text-vw-sm flex items-center gap-2 font-bold',
                        { 'flex-row-reverse': rtl },
                        styles[type].box
                    )}
                >
                    <Icons icon={icon} className={'flex-none '} />
                    <div
                        className={classNames(
                            {
                                'text-right': rtl
                            },
                            styles[type].text
                        )}
                    >
                        {translations?.data?.[message] || children}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default NotificationBox;
