import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSearchDates, updateSearchFilters } from 'store/actions/search';
import GetLang from 'hoc/GetLang';

import 'react-nice-dates/build/style.css';
import Calendar from 'components/calendar/Calendar';
import useMyNavigation from 'hooks/useMyNavigation';

const LayoutFindDate = ({
    updateSearchDates,
    updateSearchFilters,
    search: {
        search: { dateRange, filters }
    }
}) => {
    const { handleNavigation, location } = useMyNavigation();
    const handleDates = (dates) => {
        updateSearchDates(dates);

        // updateSearchFilter

        let selectedDay = dates?.[0] ? new Date(dates?.[0])?.getDay() - 1 : null;
        if (selectedDay === -1) selectedDay = 6;
        const currentDays = [...filters.dateRange];

        if (selectedDay) currentDays[selectedDay] = true;
        updateSearchFilters({ dateRange: currentDays });

        handleNavigation('app.searchCarSharing', null);
    };

    return (
        <GetLang>
            {({ tripLang, lang, rtl }) => (
                <div className={'pb-vw-28'}>
                    <Calendar selectedDates={dateRange} handleDates={handleDates} hideHeader />
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    search: state.search
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateSearchDates,
            updateSearchFilters
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutFindDate);
