import { loadNamedState, saveNamedState } from 'store/localStorage';

const saveCurrentReferrer = (source = 'Direct', date = Date.now()) => {
    saveNamedState('appInstall', { source, date });
};
export const checkReferrer = (url) => {
    if (localStorage.appInstall) {
        let appInstall = loadNamedState('appInstall');
        if (appInstall.source && appInstall.source !== 'Direct') {
            if (Date.now() - Number(appInstall.date) > 24 * 60 * 60 * 1000) {
                // 24 hours
                // console.log('too late. will consider it as new attempt');
                saveCurrentReferrer(url);
            }
        } else {
            // check if document referrer not empty
            saveCurrentReferrer(url);
        }
    } else saveCurrentReferrer(url);
};
