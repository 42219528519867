import { defaultSize, defaultStroke } from './defaults';
const IconArrowBack = ({ size, className }) => {
    return (
        <>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size || defaultSize} height={size || defaultSize}>
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path id='Path_341' data-name='Path 341' d='M0,24H24V0H0Z' fill='none' />
                        <path
                            id='Path_348'
                            data-name='Path 348'
                            d='M163.95,14.45h0L159,9.5l4.95-4.95,1.414,1.414L162.828,8.5H174a2,2,0,0,0,2-2V0h2V6.5a4,4,0,0,1-4,4H162.828l2.536,2.536-1.413,1.413Z'
                            transform='translate(-157 6.5)'
                        />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconArrowBack;
