import classNames from 'classnames';
import Icons from 'components/icons/index';
import { widthBiggerThan } from 'helpers/utils';
import GetLang from 'hoc/GetLang';

const DoubleList = ({ listFrom, listTo, handleList, height }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className={classNames(height, 'mb-vw-8 md:mb-8 md:text-sm')}>
                    {listFrom?.length > 0 && (
                        <ul
                            className={classNames(
                                'select-none flex flex-wrap justify-center items-center gap-2',
                                listTo?.length > 0 ? 'mb-vw-16 md:mb-16' : ''
                            )}
                        >
                            {listFrom.map((item) => (
                                <li
                                    onClick={() => handleList(item, 'ADD')}
                                    className={classNames(
                                        rtl ? ' pr-vw-4 pl-vw-2 md:pr-4 md:pl-2 flex-row-reverse' : 'pl-vw-4 pr-vw-2 md:pl-4 md:pr-2',
                                        'cursor-pointer rounded-full h-vw-8 md:h-8 flex items-center gap-1 bg-gray-100 text-gray-600'
                                    )}
                                    key={item}
                                >
                                    {translations.data?.[item] || 'NA'}
                                    <Icons icon='Add' size={widthBiggerThan() ? '20px' : '4vw'} />
                                </li>
                            ))}
                        </ul>
                    )}
                    {listTo?.length > 0 && (
                        <ul
                            className={classNames(
                                listFrom?.length > 0 ? '' : 'mt-vw-8 md:mt-8',
                                'select-none flex flex-wrap justify-center items-center gap-2'
                            )}
                        >
                            {listTo.map((item) => (
                                <li
                                    onClick={() => handleList(item, 'REMOVE')}
                                    className={classNames(
                                        rtl ? 'pr-vw-4 pl-vw-2 md:pr-4 md:pl-2 flex-row-reverse' : 'pl-vw-4 pr-vw-2 md:pl-4 md:pr-2',
                                        'cursor-pointer rounded-full h-vw-8 md:h-8 flex items-center gap-1 bg-gray-900 text-white'
                                    )}
                                    key={item}
                                >
                                    {translations.data?.[item] || 'NA'}
                                    <Icons icon='Close' size={widthBiggerThan() ? '20px' : '4vw'} />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </GetLang>
    );
};

export default DoubleList;
