import { getRelativeDate } from 'helpers/dates';
import { capitalize } from 'helpers/helperFunctions';
import GetLang from 'hoc/GetLang';
import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import CardSection from 'components/cards/CardSection';
import { mapTypeToSentence, routePath } from 'constants/structRoutes';
import { useNavigate, useLocation } from 'react-router-dom';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import StatusNotification from 'components/blocks/StatusNotification';
import UserDisplay from 'components/blocks/UserDisplay';

const CardVerification = ({ data: { id, user, data, type, status, createdAt } }) => {
    let { page, linkTo, from, to, tripDate } = data || {};

    const navigate = useNavigate();
    const location = useLocation();

    const handleButton = () => {
        navigate(`${routePath('app.admin.verification')}?id=${id}`, {
            state: { from: location.pathname, fromSearch: '', to: `${routePath('app.admin.verification')}`, toSearch: `?id=${id}` }
        });
    };
    // console.log('type', type);
    // console.log('status', status);
    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <CardSection
                    arrow={true}
                    border='border-t-8 last:border-b-8 md:border-t-2 last:border-b-2'
                    borderColor={'border-gray-100'}
                    paddingY='py-vw-4 md:py-4'
                    handleClick={id ? handleButton : null}
                    bgColor={'bg-white'}
                >
                    <div className={classNames('w-full flex justify-between', rtl && 'flex-row-reverse')}>
                        <StatusNotification status={`VERIFICATION_${type}_${status}` ?? 'ERROR'} />
                        <div className={classNames('text-gray-600 flex items-center gap-1 text-vw-sm md:text-base font-semibold', rtl && 'flex-row-reverse')}>
                            <FormattedDateTime dateTime={createdAt} both />
                        </div>
                    </div>

                    <div className={`mt-vw-4 md:mt-4 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                        <div className={classNames('flex items-center gap-4', rtl ? 'flex-row-reverse' : 'flex-row')}>
                            <Avatar
                                profile={user?.profile ?? { avatar: 'https://ucarecdn.com/e2b2a458-d347-4187-9570-df3852f03654/maskable_icon_x128.png' }}
                                size={'h-vw-12 w-vw-12 md:h-16 md:w-16'}
                                preset={'default'}
                            />
                            <div>
                                {/* <div className={classNames(' font-bold', rtl ? 'text-right' : 'text-left', archive ? 'text-gray-700' : 'text-bb-blue-500')}>
                                    {profile?.displayName || profile?.firstName}
                                </div> */}

                                <UserDisplay profile={user?.profile} fullName />
                                {/* <Rating rating={profile?.rating} /> */}
                                {/* <div
                                    className={`text-gray-400 font-bold flex items-center gap-2 ${
                                        rtl ? 'flex-row-reverse' : 'flex-row'
                                    }`}
                                >
                                    <Icons icon={'StarFilled'} size={'4.3vw'} />
                                    <div>{profile?.rating?.toFixed(1) || 'NA'}</div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {false && (
                        <div className={`mt-vw-4 md:mt-4 w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                            <div style={{ direction: rtl ? 'rtl' : 'ltr' }} className={classNames('flex  w-full')}>
                                <Avatar
                                    profile={user?.profile ?? { avatar: 'https://ucarecdn.com/e2b2a458-d347-4187-9570-df3852f03654/maskable_icon_x128.png' }}
                                />
                                <div className={classNames(rtl ? 'mr-vw-4 md:mr-4' : 'ml-vw-4 md:ml-4', 'w-full relative')}>
                                    <div className={classNames('text-vw-sm md:text-base text-gray-500 font-medium w-vw-60a flex-none', rtl && 'text-right')}>
                                        {type === 'PLATE' && (
                                            <>
                                                {/* <span className='font-bold'>{`${amount} ${translations.data['e.notification.reward.points']} `}</span>
                                            <span> {translations.data['e.notification.reward.body']}</span>
                                            <span> {translations.data[mapTypeToSentence[type].title]}</span> */}
                                            </>
                                        )}
                                        {type === 'ID' && (
                                            <>
                                                {/* <span className='font-bold'>{`${amount} ${translations.data['e.notification.reward.points']} `}</span>
                                            <span> {translations.data['e.notification.reward.body']}</span>
                                            <span> {translations.data[mapTypeToSentence[type].title]}</span> */}
                                            </>
                                        )}
                                        {!type && <>{translations.data[mapTypeToSentence['ERROR'].body]}</>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </CardSection>
            )}
        </GetLang>
    );
};

export default CardVerification;
