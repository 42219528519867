import classNames from 'classnames';
import LottieBlock from 'components/blocks/LottieBlock';
import { ReactComponent as LogoFullWhite } from 'components/svg/logo_full_white.svg';

const DesktopAlternate = () => {
    return (
        <div className={classNames('hidden sm:flex bg-bb-blue-900 w-full h-screen-100 flex-col gap-4 justify-center items-center text-white relative')}>
            <div className={classNames('w-full h-screen-100')}>
                <div className='absolute inset-x-0 top-0 h-screen-100 overflow-hidden'>
                    <LottieBlock speed={0.35} preset='desktop-install' />
                </div>
                <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center gap-4`}>
                    <LogoFullWhite />
                    <div className={classNames('flex flex-col items-center gap-6')}>
                        <div>Please open this page on a mobile to install the app.</div>
                        <div>Veuillez ouvrir cette page sur un mobile pour installer l'application.</div>
                        <div style={{ direction: 'rtl' }}>يرجى تصفح هذه الصفحة على الهاتف المحمول لتثبيت التطبيق.</div>
                    </div>
                </div>

                <div className='absolute bottom-8 left-1/2 -translate-x-1/2 text-sm'>
                    {`Copyright ${new Date().getFullYear()} - `}
                    <strong>
                        <u>
                            <a href='https://balabenzine.com' target='_blank'>
                                Bala Benzine
                            </a>
                        </u>
                    </strong>
                </div>
            </div>
        </div>
    );
};

export default DesktopAlternate;
