import Spinner from 'components/blocks/Spinner';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnRequestConfirm = ({
    params,
    handleClick,
    spinner,
    children,
    disabled
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        type='primary'
                        justify={spinner ? 'justify-center' : 'justify-between'}
                        rtl={rtl}
                        disabled={disabled}
                        params={params}
                        handleClick={handleClick}
                    >
                        {spinner ? (
                            <Spinner strokeColor='#ffffff' />
                        ) : (
                            <>
                                <span className='w-6' />
                                {children ||
                                    translations?.data?.['e.confirmRequest']}
                                <Icons icon={'Plane1'} flip={rtl} />
                            </>
                        )}
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnRequestConfirm;

// 'e.confirmRequest': 'Confirmer la demande',
// 'e.sendBookingRequest': 'Envoyer une demande',
// 'e.contactDriver': 'Contacter le conducteur',
// 'e.viewBookingRequest': 'Consulter la demande'
