import PageStaticTemplate from './PageStaticTemplate';

const PageStaticSafety = ({ overlay }) => {
    return (
        <PageStaticTemplate
            page='static.safety'
            apiUrl='/static-pages/62fbcf4be2654aac50328dee'
            icon='ShieldCheck'
            overlay={overlay}
            analyticsPage='Safety Guidelines'
        />
    );
};

export default PageStaticSafety;
