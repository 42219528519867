import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { routePath } from 'constants/structRoutes';

const PageCom = ({ globalState: { tabs } }) => {
    if (tabs?.rides === 'notifications')
        return <Navigate to={`${routePath('app.notifications')}`} />;
    if (tabs?.rides === 'messages')
        return <Navigate to={`${routePath('app.messages')}`} />;

    return (
        <>
            <Navigate to={`${routePath('app.notifications')}`} />
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState
});

export default connect(mapStateToProps, null)(PageCom);
