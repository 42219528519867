import { widthBiggerThan } from 'helpers/utils';

export const statusStyles = {
    ARCHIVE: {
        date: {
            bg: 'ring-2 ring-gray-700 bg-white text-gray-700'
        },
        time: {
            text: 'text-gray-700'
        },
        itinerary: {
            text: 'text-gray-500',
            border: 'border-gray-500',
            line: 'bg-gray-500'
        },
        profile: {
            text: ''
        }
    },

    CANCELED_BY: {
        date: {
            bg: 'bg-gray-500 text-white'
        },
        time: {
            text: 'text-gray-500'
        },
        itinerary: {
            text: 'text-gray-500',
            border: 'border-gray-500',
            line: 'bg-gray-500'
        },
        profile: {
            text: ''
        }
    },
    APPROVED_BY: {
        date: {
            bg: 'bg-bb-blue-550 text-white'
        },
        time: {
            text: 'text-gray-800'
        },
        itinerary: {
            text: 'text-bb-blue-500',
            border: 'border-bb-blue-500',
            line: 'bg-bb-blue-500'
        },
        profile: {
            text: ''
        }
    },
    PENDING: {
        date: {
            bg: 'bg-gray-500 text-white'
        },
        time: {
            text: 'text-gray-500'
        },
        itinerary: {
            text: 'text-gray-400',
            border: 'border-gray-500',
            line: 'bg-gray-500'
        },
        profile: {
            text: ''
        }
    },
    DRAFT: {
        date: {
            bg: 'bg-gray-500 text-white'
        },
        time: {
            text: 'text-gray-500'
        },
        itinerary: {
            text: 'text-gray-400',
            border: 'border-gray-500',
            line: 'bg-gray-500'
        },
        profile: {
            text: ''
        }
    },
    PUBLISHED: {
        date: {
            bg: 'bg-bb-blue-550 text-white'
        },
        time: {
            text: 'text-gray-800'
        },
        itinerary: {
            text: 'text-bb-blue-500',
            border: 'border-bb-blue-500',
            line: 'bg-bb-blue-500'
        },
        profile: {
            text: ''
        }
    },
    CANCELED: {
        date: {
            bg: 'bg-gray-500 text-white'
        },
        time: {
            text: 'text-gray-500'
        },
        itinerary: {
            text: 'text-gray-500',
            border: 'border-gray-500',
            line: 'bg-gray-500'
        },
        profile: {
            text: ''
        }
    }
};

export const avatarCircles = {
    default: {
        icon: {
            icon: 'bg-bb-blue-50 text-bb-blue-550',
            circleSize: 'w-vw-12 h-vw-12 md:w-12 md:h-12',
            border: '',
            iconSize: widthBiggerThan() ? '28px' : '7.7vw',
            iconStroke: 1.75
        },
        verify: {
            position: 'absolute -bottom-vw-2 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        },
        verifyCar: {
            position: 'absolute -bottom-vw-2 -left-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '16px' : '3.8vw'
        },
        red: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-red-200',
            color: 'text-red-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        }
    },
    ride: {
        icon: {
            icon: 'bg-bb-blue-50 text-bb-blue-550',
            circleSize: 'w-vw-12 h-vw-12 md:w-12 md:h-12',
            border: '',
            iconSize: widthBiggerThan() ? '28px' : '7.7vw',
            iconStroke: 1.75
        },
        verify: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        },
        verifyCar: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-bb-blue-200',
            color: 'text-bb-blue-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        },
        red: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-red-200',
            color: 'text-red-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        }
    },
    archive: {
        icon: {
            icon: 'bg-gray-100 text-gray-700',
            circleSize: 'w-vw-12 h-vw-12 md:w-12 md:h-12',
            border: '',

            iconSize: widthBiggerThan() ? '28px' : '7.7vw',
            iconStroke: 1.75
        },
        verify: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        },
        verifyCar: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        }
    },
    dark: {
        icon: {
            icon: 'text-white',
            circleSize: 'w-vw-12 h-vw-12 xs360:w-vw-16 xs360:h-vw-16 md:w-12 md:h-12',
            border: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '28px' : '9.7vw',
            iconStroke: 1.35
        },
        verify: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        },
        verifyCar: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-6 md:w-6',
            height: 'h-vw-6 md:h-6',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '4.3vw'
        }
    },
    large: {
        icon: {
            icon: 'text-white',
            circleSize: 'w-vw-12 h-vw-12 xs360:w-vw-16 xs360:h-vw-16 md:w-12 md:h-12',
            border: 'border-2 border-white',
            iconSize: widthBiggerThan() ? '28px' : '9.7vw',
            iconStroke: 1.35
        },
        verify: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-8 md:w-8',
            height: 'h-vw-8 md:h-8',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '5.3vw'
        },
        verifyCar: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-emerald-200',
            color: 'text-emerald-600',
            width: 'w-vw-8 md:w-8',
            height: 'h-vw-8 md:h-8',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '5.3vw'
        },
        banned: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-red-100',
            color: 'text-red-600',
            width: 'w-vw-8 md:w-8',
            height: 'h-vw-8 md:h-8',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '5.3vw',
            icon: 'UserBlock'
        },
        male: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-blue-100',
            color: 'text-blue-600',
            width: 'w-vw-8 md:w-8',
            height: 'h-vw-8 md:h-8',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '5.3vw',
            icon: 'Male1'
        },
        female: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-pink-100',
            color: 'text-pink-600',
            width: 'w-vw-8 md:w-8',
            height: 'h-vw-8 md:h-8',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '5.3vw',
            icon: 'Female1'
        },
        na: {
            position: 'absolute -bottom-vw-1 -right-vw-1 md:-bottom-1 md:-right-1',
            bgColor: 'bg-gray-100',
            color: 'text-gray-600',
            width: 'w-vw-8 md:w-8',
            height: 'h-vw-8 md:h-8',
            extraClass: '',
            iconSize: widthBiggerThan() ? '20px' : '5.3vw',
            icon: 'User'
        }
    }
};

export const avatarStyles = {
    default: {
        container: 'border-t px-vw-6 py-vw-4 border-gray-200 md:px-6 md:py-4',
        name: 'text-bb-blue-500 font-bold',
        icon: 'text-gray-400 font-bold',
        iconSize: widthBiggerThan() ? '24px' : '4.3vw',
        avatar: ''
    },
    ride: {
        container: 'border-t border-b px-vw-6 py-vw-4 border-gray-200 md:px-6 md:py-4',
        name: 'text-bb-blue-500 font-bold',
        icon: 'text-gray-400 font-bold',
        iconSize: '4.3vw',
        avatar: ''
    },
    archive: {
        container: 'border-t border-ba px-vw-6 py-vw-4 border-gray-200 md:px-6 md:py-4',
        name: 'text-gray-700 font-bold',
        icon: 'text-gray-400 font-bold',
        iconSize: '4.3vw',
        avatar: ''
    },
    dark: {
        container: '',
        name: 'text-white font-medium mb-vw-1 md:mb-1',
        icon: 'text-white font-bold',
        iconSize: '4.3vw',
        avatar: ''
    }
};
