import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Icons from 'components/icons/';
import GetLang from 'hoc/GetLang';

const MenuItem = ({
    notification,
    active = true,
    label,
    labelClass = '',
    icon = 'IconCar1',
    iconSize = 32,
    delay = '0ms',
    linkTo = '/',
    animationState = true,
    handleClick,
    button,
    rtl = false
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const baseDelay = 25;
    const defaultClass = `delay-100 transition-colors duration-300 in-out-expo flex ${classNames({
        'flex-row-reverse': rtl
    })}  items-center w-full h-vw-12 md:h-12 lg:h-10 rounded-lg px-vw-4 md:px-4 transition-all duration-300 ease-in-out active:bg-white active:bg-opacity-20`;
    const activeClass = `text-yellow-400 bg-white bg-opacity-20 ${defaultClass}`;
    const inactiveClass = `text-white ${defaultClass}`;

    const handleButton = () => {
        handleClick();
        if (!button) navigate(linkTo);
    };

    return (
        <GetLang>
            {({ translations }) => (
                <li
                    style={{ transitionDelay: `${delay * baseDelay}ms` }}
                    className={`select-none relative flex items-center px-vw-6 md:px-6 transform duration-300 in-out-expo ${
                        animationState ? 'translate-x-0' : rtl ? 'translate-x-1/4' : '-translate-x-1/4'
                    } `}
                >
                    {!button ? (
                        <NavLink
                            to={linkTo}
                            onClick={handleButton}
                            state={{ from: location?.pathname, fromSearch: location?.search ?? '', to: linkTo }}
                            // onTouchEnd={handleButton}
                            className={({ isActive }) => (isActive && active ? activeClass : inactiveClass)}
                        >
                            {({ isActive }) => {
                                return (
                                    <>
                                        <div className='w-vw-8 md:w-8 flex items-center justify-center relative'>
                                            <Icons icon={icon} size={iconSize} />
                                            {notification > 0 && (
                                                // <div className='absolute flex top-0 left-0'>
                                                //     <span className='animate-ping rounded-full h-vw-3 w-vw-3 transform bg-bb-red-400'></span>
                                                //     <span className='absolute left-vw-0.5 top-vw-0.5 rounded-full h-vw-2 w-vw-2 transform bg-bb-red-400'></span>
                                                // </div>
                                                <div className={classNames('absolute flex -top-vw-2', rtl ? 'right-vw-4' : 'right-vw-4')}>
                                                    {/* <span className='animate-ping rounded-full h-3 w-3 transform bg-bb-red-400'></span> */}
                                                    <span className='absolute left-vw-0.5 top-vw-0.5 rounded-full h-vw-5 w-vw-5 transform bg-bb-red-400 text-white justify-center items-center flex text-vw-xxs font-semibold'>
                                                        {notification}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {label && (
                                            <div
                                                className={`${labelClass || 'text-vw-lg md:text-2xl lg:text-xl xl:text-base'} ${
                                                    rtl ? 'mr-vw-4 md:mr-4' : 'ml-vw-4 md:ml-4'
                                                } `}
                                            >
                                                {translations.data[label] ?? label}
                                            </div>
                                        )}
                                    </>
                                );
                            }}
                        </NavLink>
                    ) : (
                        <div onClick={handleButton} className={inactiveClass}>
                            <>
                                <div className='w-vw-8 md:w-8 flex items-center justify-center'>
                                    <Icons icon={icon} size={iconSize} />
                                </div>
                                {label && (
                                    <div
                                        className={`${labelClass || 'text-vw-lg md:text-2xl lg:text-xl xl:text-base'} ${
                                            rtl ? 'mr-vw-4 md:mr-4' : 'ml-vw-4 md:ml-4'
                                        } `}
                                    >
                                        {translations.data[label] ?? label}
                                    </div>
                                )}
                            </>
                        </div>
                    )}
                </li>
            )}
        </GetLang>
    );
};

export default MenuItem;
