import { LayoutPage, LayoutPageBody } from 'components/layouts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CardSkeleton from 'components/skeletons/CardSkeleton';

import TabRides from 'components/tabs/TabRides';

import Cloner from 'components/blocks/Cloner';
import OfflineMode from 'components/blocks/OfflineMode';
import classNames from 'classnames';

import NoResults from 'components/blocks/NoResults';

import BtnLoadState from 'components/buttons/BtnLoadState';

import { useState, useEffect, useRef } from 'react';
import { setScroll } from 'store/actions/globalState';
import TabCom from 'components/tabs/TabCom';
import FormattedDateTime from 'components/blocks/FormattedDateTime';
import { getRelativeDate } from 'helpers/dates';
import { capitalize } from 'helpers/helperFunctions';
import Notification from 'components/blocks/Notification';
import { updateNotification, updateNotificationClearDate } from 'fetch/apiRequests';
import NotificationSkeleton from 'components/skeletons/NotificationSkeleton';
import BtnScroll from 'components/buttons/BtnScroll';
import BtnPill from 'components/buttons/BtnPill';
import Icons from 'components/icons/index';
import { analytics } from 'helpers/segment';

const pageData = {
    page: 'app.notifications',
    pageRedirect: 'app.searchCarSharing',
    apiUrl: '/notifications/me',
    backBtn: true,
    zIndex: 38,
    checkIdParam: false,
    ridePath: 'app.rides.booking',
    resultsPerPage: 10
};

const { resultsPerPage } = pageData;

const PageNotifications = ({ globalState: { scroll }, setScroll, auth: { isAuthenticated } }) => {
    const ref = useRef(null);

    const [unreadNotifications, setUnreadNotifications] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const handleNotificationUpdate = async () => {
        await updateNotification(unreadNotifications, { isRead: true });
    };

    useEffect(() => {
        // console.log(unreadNotifications);
        handleNotificationUpdate();
    }, [unreadNotifications]);

    useEffect(() => {
        analytics.page('Notifications', 'App', isAuthenticated);
    }, []);

    useEffect(() => {
        if (scroll?.page === 'app.notifications') ref?.current?.scrollTo({ top: scroll.top });
    }, [ref]);

    const handleData = (childData, isValidating) => {
        if (childData && !isValidating) {
            const newData = childData?.map?.((page) => page.data)?.flat();
            setUnreadNotifications(newData?.filter((notif) => notif.isRead === false).map((item) => item.id));
        }
    };

    const clearNotifications = async (mutateData) => {
        setIsLoading(true);
        await updateNotificationClearDate(new Date());
        await mutateData();
        setIsLoading(false);
    };

    // console.log('rendering: List Notifications');

    return (
        <LayoutPage pageData={pageData} infinite={true} handleData={handleData}>
            {({
                rtl,
                lang,
                location,
                animStates,
                translations,

                params,
                isValidating,
                isValidParams,
                data,
                mutateData,
                size,
                setSize,
                handleNavigation,
                reducedMotion,
                getReducedMotion,
                online
            }) => {
                const newData = {
                    count: data?.[0]?.unread,
                    data: data?.map?.((page) => page.data)?.flat()
                };
                // if (data) setIsLoaded(true);
                // if (isValidating || !data) setIsLoaded(false);
                // if (data && !isValidating) {
                //     // setIsLoaded(true);
                //     setUnreadNotifications(newData?.data?.filter((notif) => notif.isRead === false).map((item) => item.id));
                // }

                const isEmpty = newData?.unread === 0;
                const isReachingEnd = newData?.unread === 0 || (data && data[data.length - 1]?.data?.length < resultsPerPage);

                // console.log('newdata is', newData);
                // console.log('data is', data);
                return (
                    <>
                        <TabCom initial='notifications' />
                        <div ref={ref} className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar flex flex-col justify-between'>
                            <BtnScroll containerRef={ref} page={'app.notifications'} />
                            <LayoutPageBody minHeight={''}>
                                <div className=''>
                                    <div className=''>
                                        <OfflineMode />
                                    </div>
                                    <div className={classNames('min-h-body-w-header flex flex-col justify-between')}>
                                        <ul className={`flex flex-col items-center`}>
                                            {newData?.count != null && !isLoading ? (
                                                newData?.data?.length > 0 ? (
                                                    newData?.data?.map?.((item) => {
                                                        return <Notification key={item._id} data={item} />;
                                                    })
                                                ) : (
                                                    <div className='w-full px-vw-6 py-vw-6'>
                                                        <NoResults
                                                            image='noNotifications'
                                                            title='e.nothingFound.notification.title'
                                                            description='e.nothingFound.notification.description'
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <Cloner clones={5}>
                                                    <NotificationSkeleton rtl={rtl} />
                                                </Cloner>
                                            )}
                                        </ul>

                                        <div className={classNames('flex flex-col items-center px-vw-6 py-vw-4')}>
                                            {!isReachingEnd && <BtnLoadState spinner={isValidating} handleClick={setSize} params={[size + 1]} />}
                                            {newData?.data?.length != 0 && !isLoading && (
                                                <BtnPill
                                                    type='confirmed'
                                                    justify={'justify-between'}
                                                    rtl={rtl}
                                                    params={[false]}
                                                    handleClick={() => clearNotifications(mutateData)}
                                                >
                                                    <>
                                                        <span className='w-vw-6' />
                                                        {translations?.data?.['e.notification.action.clear']}
                                                        <Icons icon={'Close'} size={'5.8vw'} />
                                                    </>
                                                </BtnPill>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setScroll
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PageNotifications);
