import React, { useState, useEffect, useRef } from 'react';
import { buildFullCalendar, getDateString } from 'helpers/dates';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import GetLang from 'hoc/GetLang';
import CalendarHeader from './CalendarHeader';

const currentYear = new Date().getFullYear();
const todayString = () => {
    const newDate = new Date();
    const newMonth = newDate.getMonth() + 1;
    const newYear = newDate.getFullYear();
    const newDay = newDate.getDate();
    const today = `${newYear}-${newMonth > 9 ? newMonth : `0${newMonth}`}-${newDay > 9 ? newDay : `0${newDay}`}`;

    return today;
};

const Calendar = ({ hideHeader, handleDates, selectedDates, multiple = false }) => {
    const fullCalendar = buildFullCalendar();

    const monthRefs = useRef([]);

    const [isMultiple, setIsMultiple] = useState(false);

    useEffect(() => {
        monthRefs?.current.find((ref) => ref?.id === selectedDates[0]?.slice(0, 7))?.scrollIntoView?.();
    }, []);

    useEffect(() => {}, [selectedDates]);

    const isDateSelected = (day) => {
        return selectedDates.some((item) => item == day.string);
    };

    const isDateActive = (date) => {
        const today = new Date();

        const testDate = new Date(`${getDateString(date).date}T23:59:59`);

        return testDate >= today;
    };

    const isToday = (date) => {
        const today = todayString();
        return date === today;
    };

    // handle Button

    const handleIsActive = (day, passFunc) => () => {
        if (isDateActive(day?.date)) passFunc(day);
    };

    const handleClick = (day) => {
        const newObj = [...selectedDates];
        if (day) {
            if (multiple) {
                if (isDateSelected(day)) {
                    handleDates(newObj.filter((item) => item != day.string));
                } else {
                    const newObj = [...selectedDates];
                    handleDates([...newObj, day.string]);
                }
            } else {
                handleDates([day.string]);
            }
        }
    };

    return (
        <GetLang>
            {({ lang, rtl, translations }) => (
                <>
                    {!hideHeader && <CalendarHeader />}

                    <div className=''>
                        {fullCalendar?.map((cMonth, index) => (
                            <div
                                id={`${cMonth.year}-${cMonth.monthPadded}`}
                                ref={(element) => (monthRefs.current[index] = element)}
                                key={`key_${cMonth.year}-${cMonth.monthPadded}`}
                                className={classNames('mb-vw-8')}
                            >
                                <div
                                    className={classNames('mb-vw-3 text-bb-blue-500 text-vw-2xl font-extrabold', {
                                        'text-right': rtl
                                    })}
                                >
                                    {DateTime.fromISO(`${cMonth.year}-${cMonth.monthPadded}-01T00:00:00.001`)
                                        .setLocale(translations.formatting.locale)
                                        .toFormat(cMonth.year === currentYear ? 'LLLL' : 'LLLL y')}
                                </div>
                                <div className={classNames('grida grid-cols-7a content-starta justify-starta flex flex-wrap', { 'flex-row-reverse': rtl })}>
                                    {cMonth.days.map((day, index) => {
                                        return (
                                            <div
                                                className={classNames('w-1/7 flex items-center justify-center flex-none')}
                                                key={`${day?.date ? day?.date.toString() : index}`}
                                            >
                                                <div
                                                    onClick={handleIsActive(day, handleClick)}
                                                    className={classNames(
                                                        'w-vw-12 flex-none select-none text-vw-base  h-vw-12 cursor-pointer flex items-center justify-center font-bold',
                                                        {
                                                            'bg-yellow-200 text-yellow-600 rounded-full ':
                                                                isToday(day?.string) && (!day || !isDateSelected(day)),

                                                            'text-gray-200': day && !isDateActive(day.date),
                                                            'bg-bb-blue-550 text-white rounded-full': day && isDateSelected(day),
                                                            'text-gray-400 rounded-full': day && !isDateSelected(day)
                                                        }
                                                    )}
                                                >
                                                    {day && day.day}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </GetLang>
    );
};

export default Calendar;
