import * as types from 'store/actions/types';

export function setStaticPage(page, payload) {
    return (dispatch) => {
        dispatch({
            type: types.STATIC_SET_PAGE,
            page,
            payload
        });
    };
}
