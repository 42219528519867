import Spinner from 'components/blocks/Spinner';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import classNames from '../../../node_modules/classnames/index';
import BtnPill from './BtnPill';

const BtnRatingSide = ({
    params,
    handleClick,
    children,
    disabled,
    archive,
    visible = true,
    state
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill
                        type={state ? 'rating-100' : 'rating-0'}
                        width={state ? 'w-vw-10' : 'w-max'}
                        height='h-vw-10'
                        uppercase={false}
                        tracking={''}
                        // justify={'justify-between'}
                        rtl={rtl}
                        disabled={disabled}
                        params={params}
                        handleClick={handleClick}
                        margins=''
                        paddingX={
                            !state
                                ? rtl
                                    ? 'pr-vw-4 pl-vw-3'
                                    : 'pl-vw-4 pr-vw-3'
                                : ''
                        }
                    >
                        {children || (
                            <>
                                {!state && (
                                    <div
                                        className={classNames(
                                            'flex-none',
                                            rtl ? 'pr-vw-4a' : 'pl-vw-4a'
                                        )}
                                    >{`${translations.data['e.rate']}`}</div>
                                )}
                                <Icons
                                    icon={state ? 'StarFilled' : 'StarStroke'}
                                    size={state ? '4vw' : '5vw'}
                                />
                            </>
                        )}
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnRatingSide;
