/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    tab: 'booked',
    booked: [],
    offered: []
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const toggleRidesTab = (draft, action) => {
    const { tab } = action;
    return { ...draft, tab };
};

export default createReducer(initialState, {
    [types.RIDES_TAB_TOGGLE]: toggleRidesTab
});
