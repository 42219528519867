import Pill from 'components/blocks/Pill';
import StatusBooked from 'components/blocks/StatusBooked';
import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const CardSeats = ({ data, archive, label, preset, padding = 'pb-vw-6 md:pb-6' }) => {
    const pendingCount = data?.booking?.PENDING ?? 0;
    const approvedCount = data?.booking?.APPROVED_BY_ADMIN + data?.booking?.APPROVED_BY_DRIVER;
    const totalCount = Number(pendingCount) + Number(approvedCount);

    return (totalCount > 0 && !archive) || (archive && approvedCount > 0) ? (
        <GetLang>
            {({ rtl, translations }) => (
                <div className='px-vw-6a mb-vw-6a'>
                    <div
                        className={classNames(
                            'px-vw-6 md:px-6 rounded-2xla ',
                            data?.archive || archive ? 'border-2a border-gray-200' : 'shadow-double-2a bg-white'
                        )}
                    >
                        <div className={classNames(padding, 'w-full flex gap-2 items-center justify-between', rtl && 'flex-row-reverse')}>
                            <Pill
                                margins=''
                                icon='Users'
                                sizePreset={label ? 'small' : 'small-circle'}
                                textTransform='font-semibold'
                                // textSize='text-vw-sm'
                                colorPreset={data?.archive || archive ? 'archive' : 'light-blue'}
                            >
                                {translations.data[label] ?? label}
                            </Pill>

                            <div className={classNames('flex items-center gap-2', rtl && 'flex-row-reverse')}>
                                {approvedCount ? (
                                    <StatusBooked
                                        status='APPROVED_BY_DRIVER'
                                        seats={data.booking.APPROVED_BY_ADMIN + data.booking.APPROVED_BY_DRIVER}
                                        noText
                                        archive={data?.archive || archive}
                                    />
                                ) : null}
                                {pendingCount && !archive ? <StatusBooked status='PENDING' seats={data.booking.PENDING} noText archive={archive} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </GetLang>
    ) : null;
};

export default CardSeats;
