import classNames from 'classnames';
import BtnPill from 'components/buttons/BtnPill';
import BtnRatingSide from 'components/buttons/BtnRatingSide';
import Icons from 'components/icons/index';
import { widthBiggerThan } from 'helpers/utils';
import GetLang from 'hoc/GetLang';
import Avatar from './Avatar';
import Rating from './Rating';
import Roundify from './Roundify';
import { avatarCircles, avatarStyles as styles } from 'constants/styles';
import UserDisplay from './UserDisplay';

const AvatarFull = ({ toReview, handleReview, profile, className, size = 'w-vw-12 h-vw-12 md:w-12 md:h-12', preset = 'default', noVerification, blocked }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className={classNames(className ?? styles[preset].container)}>
                    <div className={classNames('flex items-center justify-between', rtl && 'flex-row-reverse')}>
                        <div className={`text-vw-base md:text-base flex ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center`}>
                            <Avatar profile={profile} size2={size} preset={preset} />
                            <div className={classNames(rtl ? 'mr-vw-8 md:mr-4' : 'ml-vw-8 md:ml-4')}>
                                <UserDisplay profile={{ ...profile, user: { blockUser: blocked } }} preset={preset} noVerification={noVerification} banned />

                                {!blocked && <Rating rating={profile?.rating} reviews={profile?.totalReviews || null} />}
                            </div>
                        </div>

                        {(toReview || toReview === null) && <BtnRatingSide handleClick={handleReview} state={toReview} />}
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default AvatarFull;
