import { connect } from 'react-redux';
import { LayoutPage, LayoutPageBody } from 'components/layouts';

import CardSkeleton from 'components/skeletons/CardSkeleton';

import OfflineMode from 'components/blocks/OfflineMode';

import classNames from 'classnames';
import Avatar from 'components/blocks/Avatar';
import Rating from 'components/blocks/Rating';
import ListItem from 'components/blocks/ListItem';
import CardSection from 'components/cards/CardSection';
import { PageLoading } from 'pages/index';
import UserDisplay from 'components/blocks/UserDisplay';
import { setStaticPage } from 'store/actions/staticPages';
import { bindActionCreators } from 'redux';
import { analytics } from 'helpers/segment';
import { useEffect } from 'react';

const LayoutUserChat = ({ userId, setStaticPage }) => {
    const pageData = {
        page: 'app.userChat',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: `/profiles/public/${userId}`,
        backBtn: true,
        zIndex: 41,
        checkIdParam: false,
        managed: false
    };

    useEffect(() => {
        analytics.page('Chat > Public User', 'App', true);
    }, []);

    return (
        <LayoutPage pageData={pageData}>
            {({ rtl, lang, location, animStates, params, isValidating, isValidParams, translations, data, mutateData, handleNavigation, online }) => {
                console.log('data', data);

                return (
                    <>
                        <div className='h-body-w-header overflow-y-auto text-vw-base no-scrollbar'>
                            <LayoutPageBody minHeight={classNames('min-h-body-w-header-footer-2')}>
                                <div className=''>
                                    {data ? (
                                        <div className={`list-none w-full  bg-white  bordera relative ring-8a transition-all duration-300 `}>
                                            <OfflineMode margins=' mb-6' />
                                            <div
                                                className={classNames(
                                                    'px-vw-6 w-full justify-between flex items-center border-b border-gray-200 pb-vw-6',
                                                    rtl && 'flex-row-reverse'
                                                )}
                                            >
                                                <div
                                                    style={{
                                                        direction: rtl ? 'rtl' : 'ltr'
                                                    }}
                                                    className={classNames(' flex flex-col')}
                                                >
                                                    <div className={classNames('text-bb-blue-550 text-vw-2xl font-bold')}>
                                                        {data?.displayName || data?.firstName}
                                                    </div>
                                                    {data?.displayName && (
                                                        <div className={classNames('text-gray-400 text-vw-base font-bold')}>{`${data?.firstName}`}</div>
                                                    )}
                                                    <UserDisplay profile={data} noName className={classNames('mt-vw-2')} banned />
                                                </div>

                                                <button
                                                    onClick={data?.avatar ? () => setStaticPage('publicAvatar', data?.avatar) : null}
                                                    className='focus:outline-none '
                                                >
                                                    <Avatar profile={data} iconSize={'10vw'} size={'w-vw-20 h-vw-20'} />
                                                </button>
                                            </div>

                                            <CardSection tap={false} border='' borderColor='border-gray-100'>
                                                <Rating
                                                    className='text-gray-500 font-semibold gap-4 text-vw-lg'
                                                    iconSize='6vw'
                                                    iconClass='text-gray-400'
                                                    rating={data?.rating}
                                                    reviews={data?.totalReviews || null}
                                                    showReviews
                                                />
                                            </CardSection>
                                            <CardSection tap={false} border='border-t' borderColor='border-gray-200'>
                                                <div
                                                    style={{ direction: rtl ? 'rtl' : 'ltr' }}
                                                    className={classNames('text-gray-500 font-semibold text-vw-base flex gap-2')}
                                                >
                                                    <span>{translations.data['e.publishedRides']}</span>
                                                    <span>{data?.totalPublished}</span>
                                                </div>
                                            </CardSection>
                                            <div
                                                className={classNames(
                                                    rtl ? 'items-end' : 'items-start',
                                                    'px-vw-6 w-full flex flex-col border-t-8 border-b-8 border-gray-100 py-vw-6 gap-4'
                                                )}
                                            >
                                                {data?.verifiedProfile && (
                                                    <h2
                                                        style={{
                                                            direction: rtl ? 'rtl' : 'ltr'
                                                        }}
                                                        className={classNames('text-bb-blue-550 text-vw-xl font-bold')}
                                                    >
                                                        {`${lang !== 'ar' ? data?.displayName || data?.firstName : ''} ${
                                                            translations.data['e.verified.profile']
                                                        }`}
                                                    </h2>
                                                )}
                                                <ul className={classNames(rtl ? 'items-end' : 'items-start', 'w-full flex flex-col gap-6')}>
                                                    {data?.verifiedMobile && <ListItem icon='Phone1'>{translations.data['e.verified.mobile']}</ListItem>}
                                                    {data?.verifiedEmail && <ListItem>{translations.data['e.verified.email']}</ListItem>}
                                                    {data?.verifiedId && <ListItem icon='Id2'>{translations.data['e.verified.id']}</ListItem>}
                                                    {data?.verifiedCarRegistration && <ListItem icon='Car1'>{translations.data['e.verified.plate']}</ListItem>}
                                                    {/* <ListItem preset='blue'>
                                                            Mobile Verified
                                                        </ListItem> */}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        // <CardSkeleton rtl={rtl} type='view' />
                                        <PageLoading bg='bg-white' />
                                    )}
                                </div>
                            </LayoutPageBody>
                        </div>
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    search: state.search,
    staticPages: state.staticPages
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutUserChat);
