import classNames from 'classnames';
import Icons from 'components/icons/index';
import { widthBiggerThan } from 'helpers/utils';
import GetLang from 'hoc/GetLang';

const styles = {
    size: {
        none: {
            height: 'h-vw-10 md:h-10',
            width: 'w-max',
            iconSize: widthBiggerThan() ? '20px' : '4.4vw',
            textSize: 'text-normal',
            padding: 'px-vw-4 md:px-4',
            iconGap: 'mx-vw-2 md:mx-2',
            rounded: 'rounded-full',
            border: '',
            textTransform: 'capitalize font-bold'
        },
        default: {
            height: 'h-vw-14 md:h-16',
            width: 'w-max',
            iconSize: widthBiggerThan() ? '24px' : '5.38vw',
            textSize: 'text-vw-lg md:text-lg',
            padding: 'px-vw-6 md:px-6',
            iconGap: 'mx-vw-2 md:mx-2',
            rounded: 'rounded-full',
            border: 'ring-2',
            textTransform: 'capitalize font-bold'
        },
        small: {
            height: 'h-vw-10 md:h-10',
            width: 'w-max',
            iconSize: widthBiggerThan() ? '20px' : '4.4vw',
            textSize: 'text-normal',
            padding: 'px-vw-4 md:px-4',
            iconGap: 'mx-vw-2 md:mx-2',
            rounded: 'rounded-full',
            border: 'ring-2',
            textTransform: 'capitalize font-bold'
        },
        'small-circle': {
            height: 'h-vw-10 md:h-10',
            width: 'w-vw-10 md:w-10',
            iconSize: widthBiggerThan() ? '20px' : '4.4vw',
            textSize: 'text-vw-base md:text-base',
            padding: 'justify-center',
            iconGap: '',
            rounded: 'rounded-full',
            border: 'ring-2',
            textTransform: 'capitalize font-bold'
        },
        'small-label': {
            height: 'h-vw-10 md:h-10',
            width: 'w-max',
            iconSize: widthBiggerThan() ? '20px' : '4.4vw',
            textSize: 'text-vw-base md:text-base',
            padding: 'justify-center',
            iconGap: 'mx-vw-2 md:mx-2',
            rounded: 'rounded-full',
            border: 'ring-2',
            textTransform: 'capitalize font-bold'
        }
    },
    color: {
        blue: {
            textColor: 'text-white',
            bgColor: 'bg-bb-blue-550',
            iconColor: 'text-white',
            borderColor: 'ring-transparent'
        },
        'light-blue': {
            textColor: 'text-bb-blue-550',
            bgColor: 'bg-bb-blue-50',
            iconColor: 'text-bb-blue-550',
            borderColor: 'ring-transparent'
        },

        'light-violet': {
            textColor: 'text-violet-600',
            bgColor: 'bg-violet-100',
            iconColor: 'text-violet-600',
            borderColor: 'ring-transparent'
        },
        gray: {
            textColor: 'text-white',
            bgColor: 'bg-gray-500',
            iconColor: 'text-white',
            borderColor: 'ring-transparent'
        },
        'dark-gray': {
            textColor: 'text-white',
            bgColor: 'bg-gray-800',
            iconColor: 'text-white',
            borderColor: 'ring-transparent'
        },
        'light-gray': {
            textColor: 'text-gray-800',
            bgColor: 'bg-gray-100',
            iconColor: 'text-gray-600',
            borderColor: 'ring-transparent'
        },
        archive: {
            textColor: 'text-gray-700',
            bgColor: 'bg-white',
            iconColor: 'text-gray-700',
            borderColor: 'ring-gray-700'
        },
        none: {
            textColor: 'text-gray-700',
            bgColor: 'bg-white',
            iconColor: 'text-gray-700',
            borderColor: 'ring-transparent'
        },
        faded: {
            textColor: 'text-gray-400',
            bgColor: 'bg-gray-100',
            iconColor: 'text-gray-600',
            borderColor: 'ring-transparent'
        }
    }
};
const Pill = ({
    children,
    icon,
    iconGap,
    iconSize,
    iconColor,
    bgColor,
    textColor,
    textSize,
    textTransform,
    height,
    width,
    padding,
    flex = 'flex items-center gap-2',
    sizePreset = 'default',
    colorPreset = 'blue',
    rounded,
    border,
    borderColor,
    margins = 'mb-2 last:mb-0',
    switchIcons,
    justify,
    onClick,
    onFullClick
}) => {
    return (
        <GetLang>
            {({ translations, rtl, lang, time24 }) => (
                <div
                    onClick={onFullClick ?? null}
                    className={classNames(
                        justify,
                        'rounded-full flex items-center ',
                        margins,
                        borderColor ?? styles.color[colorPreset].borderColor,
                        border ?? styles.size[sizePreset].border,
                        rounded ?? styles.size[sizePreset].rounded,
                        height ?? styles.size[sizePreset].height,
                        width ?? styles.size[sizePreset].width,
                        textColor ?? styles.color[colorPreset].textColor,
                        bgColor ?? styles.color[colorPreset].bgColor,
                        padding ?? styles.size[sizePreset].padding,

                        textSize ?? styles.size[sizePreset].textSize,
                        {
                            'flex-row-reverse': rtl
                        }
                    )}
                >
                    {icon && !switchIcons && (
                        <div onClick={onClick ?? null}>
                            <Icons
                                className={classNames(iconColor ?? styles.color[colorPreset].iconColor)}
                                icon={icon}
                                size={iconSize ?? styles.size[sizePreset].iconSize}
                            />
                        </div>
                    )}
                    {children && (
                        <div className={classNames(flex, iconGap ?? styles.size[sizePreset].iconGap, textTransform ?? styles.size[sizePreset].textTransform)}>
                            {children}
                        </div>
                    )}
                    {icon && switchIcons && (
                        <Icons
                            className={classNames(iconColor ?? styles.color[colorPreset].iconColor)}
                            icon={icon}
                            size={iconSize ?? styles.size[sizePreset].iconSize}
                        />
                    )}
                </div>
            )}
        </GetLang>
    );
};

export default Pill;
