import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import IconRound from 'components/blocks/IconRound';
import Paragraph from 'components/blocks/Paragraph';
import BtnPill from 'components/buttons/BtnPill';

import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';

import { loginSetStep } from 'store/actions/auth';
import { toggleLang } from 'store/actions/globalState';
import { langButtons } from 'constants/structRoutes';
import BtnSettings from 'components/buttons/BtnSettings';
import HeadlineType1 from 'components/blocks/HeadlineType1';

const LoginFlowLanguage = ({ toggleLang, loginSetStep, auth: { loading } }) => {
    const handleVerifyLang = (lang) => {
        if (lang) loginSetStep('start');
    };

    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <div className='relative h-full text-vw-base'>
                    {!loading && <IconRound icon='Global2' preset='yellow' />}
                    <div className={classNames('py-vw-12')}>
                        <HeadlineType1
                            label='e.pageSettings.language'
                            textSize='text-vw-2xl'
                            margins='mb-vw-6'
                        />

                        <div
                            className={classNames(
                                'px-vw-10 flex flex-col items-center'
                            )}
                        >
                            <div className='flex flex-col w-full'>
                                {langButtons.map((singleLang) => (
                                    <BtnSettings
                                        key={`btn_lang_${singleLang.lang}`}
                                        val={singleLang.lang}
                                        testVal={lang}
                                        handleClick={toggleLang}
                                        label={singleLang.label}
                                    />
                                ))}
                                {/* {langButtons.map((singleLang) => (
                                    <BtnPill
                                        type={'confirmed'}
                                        justify={'justify-between'}
                                        rtl={false}
                                        params={[singleLang.lang]}
                                        disabled={false}
                                        handleClick={toggleLang}
                                    >
                                        <span className='w-6' />
                                        {singleLang.label}
                                        {singleLang.lang === lang ? (
                                            <Icons icon={'Check'} />
                                        ) : (
                                            <span className='w-6' />
                                        )}
                                    </BtnPill>
                                ))} */}
                            </div>

                            <Paragraph
                                preset='small-faded'
                                margins='mt-vw-4 mb-vw-6'
                            >
                                {translations?.data?.['e.youCanChangeLater']}
                            </Paragraph>
                        </div>
                    </div>

                    <BtnPill
                        type='primary'
                        justify='justify-between'
                        shadow={lang}
                        disabled={!lang}
                        handleClick={() => handleVerifyLang(lang)}
                        absolute='bottom-vw-8 inset-x-vw-10 '
                        tracking={!rtl && 'tracking-wide'}
                        rtl={rtl}
                    >
                        <span className='w-vw-6' />
                        {translations?.data?.['e.loginFlow.start.button']}
                        <Icons icon={'ArrowRightLong1'} flip={rtl} />
                    </BtnPill>
                </div>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loginSetStep,
            toggleLang
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFlowLanguage);
