import GetLang from 'hoc/GetLang';
import classNames from 'classnames';

const InputShell = ({ children, margins = 'mb-vw-4 last:mb-0 md:mb-4 md:last:mb-0', width = 'w-full lg:w-72' }) => {
    return (
        <GetLang>
            {({ rtl }) => (
                <div
                    className={classNames(
                        width,
                        margins,
                        'flex items-center relative justify-between min-h-vw-14 md:min-h-16 bg-gray-100 py-vw-4 px-vw-4 md:px-4 md:py-4 rounded-2xl text-gray-700 leading-normal text-vw-lg md:text-lg',
                        { 'flex-row-reverse': rtl }
                    )}
                >
                    {children}
                </div>
            )}
        </GetLang>
    );
};

export default InputShell;
