import { motion } from 'framer-motion';
import ItinerarySubSkeleton from 'components/skeletons/ItinerarySubSkeleton';
import Icons from 'components/icons/index';
import classNames from 'classnames';
import OfflineMode from 'components/blocks/OfflineMode';

const variants = {
    in: {
        y: 0,
        opacity: 1,
        transition: {
            y: { duration: 0.3, ease: [0, 0, 0, 1] }
        }
    },
    out: {
        y: '15vw',
        opacity: 0,
        transition: {
            y: { duration: 0.3, ease: [0, 0, 0, 1] }
        }
    }
};

const CardSkeleton = ({ type = 'search', rtl, key = 'skType' }) => {
    return (
        <motion.li
            key={key}
            className={classNames(
                'w-full text-vw-lg  animate-pulse list-none ',

                {
                    'bg-gray-50 border-t-8 border-gray-200': type !== 'view' && type !== 'booking'
                }
            )}
            variants={variants}
        >
            {type === 'booking' && <div className='bg-gray-100 h-vw-16 w-full'></div>}
            <OfflineMode margins='mt-6' />

            <div className={`px-vw-6 ${type !== 'view' ? 'mt-vw-6' : ''} w-full flex-col flex ${rtl ? 'items-end' : 'items-start'}`}>
                <div
                    className={`mb-vw-2 rounded-full  bg-gray-150 px-vw-6 ${
                        type === 'view' || type === 'booking' ? 'h-vw-14 w-vw-60' : 'h-vw-10 w-vw-48'
                    } flex ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center gap-3 font-bold text-vw-sm`}
                ></div>
                <div
                    className={`rounded-full  bg-gray-150 px-vw-6 ${type === 'view' || type === 'booking' ? 'h-vw-10 w-vw-32' : 'h-vw-10 w-vw-32'} flex ${
                        rtl ? 'flex-row-reverse' : 'flex-row'
                    } items-center gap-3 font-bold text-vw-sm`}
                ></div>
            </div>

            <div className={classNames('flex justify-between px-vw-6 pt-vw-6', rtl ? 'flex-row-reverse' : 'flex-row', type === 'offered' && 'pb-vw-4')}>
                <div>
                    <ul>
                        <ItinerarySubSkeleton rtl={rtl} type={type} />
                        <ItinerarySubSkeleton end={true} rtl={rtl} type={type} />
                    </ul>
                </div>
            </div>

            {type !== 'offered' && (
                <div className={`px-6 py-4 flex ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center gap-8`}>
                    <div className='w-12 h-12 rounded-full bg-gray-150'></div>
                    {type === 'view' && (
                        <div style={{ zIndex: 1 }}>
                            <div className={`mb-2 font-bold bg-gray-150 rounded-full  w-16 h-4  flex `}></div>

                            <div className={`text-gray-150 font-bold flex items-center gap-2 ${rtl ? 'flex-row-reverse' : 'flex-row'}`}>
                                <Icons icon={'StarFilled'} size={18} />
                                <div className={`font-bold bg-gray-150 rounded-full w-16 h-3`}></div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </motion.li>
    );
};

export default CardSkeleton;
