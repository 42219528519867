import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavTab from 'components/blocks/NavTab';
import NavTabItem from 'components/blocks/NavTabItem';
import GetLang from 'hoc/GetLang';

import { routePath } from 'constants/structRoutes';
import { useEffect } from 'react';
import { toggleTab } from 'store/actions/globalState';

const TabRides = ({ toggleTab, globalState: { tabs }, initial }) => {
    useEffect(() => {
        toggleTab('rides', initial);
    }, []);

    return (
        <GetLang>
            {({ translations, rtl }) => (
                <NavTab tab={tabs?.rides} rtl={rtl}>
                    <NavTabItem
                        id='booked'
                        tab={tabs?.rides}
                        page='rides'
                        toggleTab={toggleTab}
                        from={routePath('app.rides.offered')}
                        to={routePath('app.rides.booked')}
                    >
                        {translations?.data?.['rides.tab.booked']}
                    </NavTabItem>
                    <NavTabItem
                        id='offered'
                        tab={tabs?.rides}
                        page='rides'
                        toggleTab={toggleTab}
                        from={routePath('app.rides.booked')}
                        to={routePath('app.rides.offered')}
                    >
                        {translations?.data?.['rides.tab.published']}
                    </NavTabItem>
                </NavTab>
            )}
        </GetLang>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    rides: state.rides
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleTab
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TabRides);
