import { connect } from 'react-redux';
import { useState } from 'react';
import { useStickySWR } from 'hooks/useStickySWR';
import {
    LayoutPage,
    LayoutPageHeader,
    LayoutPageBody,
    LayoutPageFooter
} from 'components/layouts';
import { BtnRequestConfirm, BtnRequestView } from 'components/buttons';
import { fetcher } from 'fetch/swrHelpers';
import { makeBooking } from 'fetch/apiRequests';

const PageTripBook = ({
    auth: { user },
    search: {
        isValid,
        search: { seats }
    }
}) => {
    const pageData = {
        page: 'app.book',
        pageRedirect: 'app.searchCarSharing',
        apiUrl: '/rides/view',
        backBtn: true,
        zIndex: 42,
        checkIdParam: true
    };

    const [isBooking, setIsBooking] = useState(false);
    const [bookingRes, setBookingRes] = useState(null);
    const handleBooking = async (body) => {
        setIsBooking(true);
        const res = await makeBooking(body);
        setBookingRes(res);
        setIsBooking(false);
        // console.log('my booking', res);
    };

    return (
        <LayoutPage pageData={pageData}>
            {({
                rtl,
                lang,
                location,
                animPages,
                params,
                isValidParams,
                data,
                handleNavigation
            }) => {
                bookingRes?.error && console.log('Error', bookingRes);

                const bodyBooking = {
                    rideId: data?.id,
                    status: 'PENDING',
                    passengerId: user?._id,
                    driverId: data?.driverId?.id,
                    seats,
                    from: { ...data?.from },
                    to: { ...data?.to },
                    dateTime: data?.dateTime
                };

                return (
                    <>
                        <LayoutPageHeader
                            pageData={pageData}
                            pageParams={params}
                        ></LayoutPageHeader>
                        <LayoutPageBody>
                            <div className='px-6'>
                                {data && <div>Confirm your Booking</div>}
                            </div>
                            {bookingRes && (
                                <div className='mt-12'>
                                    {bookingRes.error
                                        ? `Error: ${
                                              bookingRes.message ||
                                              bookingRes.error
                                          }`
                                        : `Success: ${bookingRes.status}`}
                                </div>
                            )}
                        </LayoutPageBody>
                        <LayoutPageFooter rtl={rtl} centered animate={!!data}>
                            <div className={`flex flex-col gap-2 w-full `}>
                                {!bookingRes?._id ? (
                                    <BtnRequestConfirm
                                        spinner={isBooking}
                                        handleClick={handleBooking}
                                        params={[bodyBooking]}
                                    />
                                ) : (
                                    <BtnRequestView
                                        handleClick={handleNavigation}
                                        params={[
                                            'app.rides.booking',
                                            {
                                                id: bookingRes?.id
                                            }
                                        ]}
                                    />
                                )}

                                {/* {bookingData && (
                                    <div>{bookingData?.status}</div>
                                )} */}
                            </div>
                        </LayoutPageFooter>
                        {/* <ModalBooking
                            isBooking={isBooking}
                            body={{
                                rideId: data.id,
                                status: 'PENDING',
                                passengerId: user._id,
                                driverId: data?.driverId?.id,
                                seats,
                                from: { ...data?.from },
                                to: { ...data?.to },
                                time: data?.time,
                                date: data?.date
                            }}
                        /> */}
                    </>
                );
            }}
        </LayoutPage>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth,
    search: state.search
});

export default connect(mapStateToProps, null)(PageTripBook);

const ModalBooking = ({ isBooking, body }) => {
    const { data, error } = useStickySWR(
        () => (isBooking ? ['/bookings', body] : null),
        fetcher['POST'],
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false
        }
    );

    // console.log('body is', body);
    // console.log('after posting');
    return null;
};
