import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const MenuRuler = ({
    label,
    borderOpacity = 'border-opacity-10',
    borderColor = 'border-white',
    paddingX = 'px-vw-10 md:px-10',
    height = 'h-vw-12 md:h-12'
}) => {
    return (
        <GetLang>
            {({ rtl, translations, lang }) => (
                <div className={`relative w-full flex items-center justify-center ${paddingX} ${height}`}>
                    {label && (
                        <div
                            className={classNames(
                                'absolute left-1/2 -translate-x-1/2 px-vw-4 py-vw-1 rounded-full bg-bb-yellow-400 text-yellow-600 font-bold uppercase'
                            )}
                        >
                            {translations.data[label]}
                        </div>
                    )}
                    <div className={`w-full border-t ${borderColor} ${borderOpacity}`}></div>
                </div>
            )}
        </GetLang>
    );
};

export default MenuRuler;
