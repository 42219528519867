/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { defaultSearchObj } from 'constants/defaultObjects';

import { produce } from 'immer';

import * as types from 'store/actions/types';

const initialState = {
    isValid: true,
    search: defaultSearchObj()
};

function createReducer(initialState, handlers) {
    return produce((draft = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](draft, action);
        } else {
            return draft;
        }
    });
}

const updateSearchSeats = (draft, action) => {
    const { seats } = action;
    draft.search.seats = seats;
    return draft;
};

const updateSearchDates = (draft, action) => {
    const { dateRange } = action;
    draft.search.dateRange = dateRange;
    return draft;
};

const updateSearchLocation = (draft, action) => {
    const { location, source } = action;
    draft.search[source] = location;
    return draft;
};

const updateSearchFilters = (draft, action) => {
    const { filters } = action;
    // console.log('filters', filters);
    draft.search.filters = { ...draft.search.filters, ...filters };
    return draft;
};

const clearSearch = (draft, action) => {
    draft.search = defaultSearchObj();
    return draft;
};

const toggleSearchSwitching = (draft, action) => {
    const to = { ...draft.search.to };
    const from = { ...draft.search.from };
    draft.search.from = to;
    draft.search.to = from;
    return draft;
};

export default createReducer(initialState, {
    [types.SEARCH_SEATS_UPDATE]: updateSearchSeats,
    [types.SEARCH_DATES_UPDATE]: updateSearchDates,
    [types.SEARCH_LOCATION_UPDATE]: updateSearchLocation,
    [types.SEARCH_FILTERS_UPDATE]: updateSearchFilters,
    [types.SEARCH_CLEAR]: clearSearch,
    [types.SEARCH_STOP_SWITCH]: toggleSearchSwitching
});
