import classNames from 'classnames';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';

const OtpInput = ({ otp, handleOtp, digits = 4 }) => {
    const refA = useRef();
    const [isFocus, setIsFocus] = useState(true);
    useEffect(() => {
        refA?.current?.focus();
    }, []);

    const digitsArr = new Array(digits).fill();

    const handleFocus = (e) => {
        setIsFocus(true);
        refA.current.focus();
    };

    const handleBlur = (e) => {
        setIsFocus(false);
    };

    const handleChange = (e) => {
        let v = e.target.value.toString();
        const regexString = digitsArr.reduce(
            (prev, curr) => `${prev}?[^0-9]`,
            '[^0-9]'
        );
        // console.log('regex ex', regexString);
        const regex = new RegExp(regexString);
        v = v.replace(regex, '');
        // check for length
        if (v.charAt(0) === '-') v = '';
        v = v.slice(0, digits);
        handleOtp(v);
    };

    return (
        <div
            className={classNames('flex justify-between w-full relative')}
            tabIndex={1}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <input
                inputMode='numeric'
                ref={refA}
                onFocus={handleFocus}
                type='text'
                value={otp}
                onInput={(e) => handleChange(e)}
                style={{ zIndex: '-1' }}
                className='absolute top-1/2 left-1/2 text-center focus:outline-none cursor-none caret-transparent text-vw-xs w-vw-1 h-vw-1 opacity-0a'
            />
            {digitsArr.map((elm, index) => (
                <Digit
                    key={index + 1}
                    value={otp}
                    place={index + 1}
                    isFocus={isFocus}
                />
            ))}
        </div>
    );
};

export default OtpInput;

const parseValue = (value, place) => {
    return value.toString().slice(place - 1, place);
};

const Digit = ({ value, place, isFocus }) => {
    return (
        <div
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            className={classNames(
                'w-vw-16 h-vw-16  font-bold rounded-2xl text-bb-blue-500 text-vw-2xl flex items-center justify-center',
                {
                    'bg-gray-100': !parseValue(value, place),
                    'bg-bb-blue-50': parseValue(value, place),
                    'border-2 border-bb-blue-500':
                        value.length === place - 1 && isFocus
                }
            )}
        >
            {value?.toString()?.slice?.(place - 1, place)}
        </div>
    );
};
