export const whatsAppMessages = {
    en: {
        reachout: (name = '', adminUser) => `Hello ${name}, It's ${adminUser} from the Bala Benzine team. `
    },
    fr: {
        reachout: (name = '', adminUser) => `Bonjour ${name}, c'est ${adminUser} de l'equipe Bala Benzine. `
    },
    ar: {
        reachout: (name = '', adminUser) => `مرحبا، معكن خدمة زبائن بلا بنزين ،`
    }
};

export const getWhatsAppMessage = (id = 'reachout', name = '', lang = 'en', adminUser = '') => {
    return whatsAppMessages[lang][id](name, adminUser);
};

export const getWhatsAppUrl = (phone, id = 'reachout', name = '', lang = 'en', adminUser = '') => {
    return `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(getWhatsAppMessage(id, name, lang, adminUser))}`;
};

export const handleWhatsApp = (phone, name, lang, adminFirstName) => {
    window?.open(getWhatsAppUrl(phone, 'reachout', name, lang, adminFirstName), '_blank');
};

export const translations = {
    en: {
        formatting: {
            locale: 'en',
            dateResults: 'ccc, LLL d',
            dateTimeResults: 'ccc, LLL d, HH:mm'
        },
        rtl: false,
        lang: 'English',
        lang_iso: 'en',
        data: {
            'install.socialButton': `Send me the link`,
            'install.socialTitle': 'Get Install link',
            'install.socialTitleSent': 'Link sent',
            'install.socialBody': `Get the Installation link to Bala Benzine via WhatsApp by inputing your mobile number below.<p> Installing and using the Bala Benzine app <strong><i>requires a lebanese phone number</i></strong>.</p>`,
            'install.socialDone': `Open WhatsApp and click on the link we sent you to open the installation page.<p>👋 See you inside</p>`,
            'install.iPhoneTitle': `iPhone`,
            'install.introTitle': 'Installation',
            'install.introBody': `
                                    <strong>
                                        <u>
                                            <a href='https://balabenzine.com' target='_blank'>
                                                Bala Benzine
                                            </a>
                                        </u>
                                    </strong>&nbsp;
                                    is a carpooling web app <span class='text-xl'>🚘+📱</span>&nbsp;
                                    that connects passengers and drivers. Install it now and share your rides all over Lebanon!
            `,
            'install.reqTitle': `Requirements`,
            'install.reqBody': `Installing and using the Bala Benzine app <strong><i>requires a lebanese phone number</i></strong>. <p>If you're a potential foreign partner (press, online media, influencer...) and want to test the app, reach out on <span class='text-vw-sm'><strong><u>hello@balabenzine.app</u></strong></span> to arrange for a test account.</p>`,
            'install.iPhoneTitle': `iPhone`,
            'install.iPhoneBody': `<ul class='text-vw-sm'>
                                        <li>Safari 14.5 Browser (rel april 2021 or later)</li>
                                        <li>Make sure you're <strong>not in private browsing</strong></li>
                                    </ul>`,
            'install.androidTitle': `Android`,
            'install.androidBody': `<ul class='text-vw-sm'>
                                        <li>Google Chrome Browser</li>
                                        <li>Make sure you're <strong>not in incognito mode</strong></li>
                                    </ul>`,
            'install.btnInstall': `Install Bala Benzine`,
            'install.btnInstalled': `Installed`,
            'menu.ladiesOnly': 'Ladies-only rides',
            'menu.pricing': 'Pricing & Payment',
            'menu.admin.title': 'Admin',
            'menu.admin.users': 'All users',
            'menu.admin.verify': 'Verifications',
            'menu.admin.verify.filter.pending': 'Pending only',
            'menu.admin.verify.filter.women': 'Women',
            'menu.admin.verify.filter.verifiedId': 'Verified ID',
            'menu.admin.verify.filter.verifiedProfile': 'Verified Profile',
            'menu.admin.verify.filter.verifiedCar': 'Verified registration',
            'menu.admin.verify.filter.blockUser': 'Banned User',
            'menu.notifications': 'Notifications',
            'menu.rewards': 'Rewards',
            'menu.ambassador': 'Become an ambassador',
            'menu.profile': 'Profile',
            'menu.settings': 'App settings',
            'menu.logout': 'Logout',
            'menu.login': 'Login',
            'menu.register': 'Sign up',
            'menu.about': 'What is Bala Benzine?',
            'menu.safety': 'Safety Guidelines',
            'menu.faq': 'FAQ',
            'menu.help': 'Help',
            'menu.help.support': 'Contact Support',
            'menu.help.terms': 'Terms & Conditions',
            'menu.help.privacy': 'Privacy policy',
            'menu.help.weNeedYou': 'We Need You',
            'menu.help.latestNews': "What's new?",
            'menu.help.donate': 'Donate',
            'nav.find': 'Find',
            'nav.rides': 'My Rides',
            'nav.publish': 'Publish',
            'nav.messages': 'Chat',
            'nav.news': 'Notifications',
            'w.rides': { 1: 'ride', 2: 'rides', 3: 'rides' },
            'e.nothingFound.verification.title': 'No Verifications',
            'e.nothingFound.verification.description': "You don't have any new verification requests",
            'e.nothingFound.notification.title': 'No notifications',
            'e.nothingFound.notification.description': "You don't have any notifications to show",
            'e.nothingFound.channel.title': 'No conversations',
            'e.nothingFound.channel.description': "You don't have any conversations yet. Find a ride and contact the driver to get things moving.",
            'e.nothingFound.channel.button': 'Try again',
            'e.nothingFound.chat.title': 'Ooops...',
            'e.nothingFound.chat.description': "We're having trouble loading your conversations. Try again in a bit",
            'e.nothingFound.chat.button': 'Try again',
            'e.nothingFound.results.title': 'Nothing found',
            'e.nothingFound.results.description':
                'Try widening your search criteria. Try a neighborhood instead of a street / place or a city instead of a neighborhood.',
            'e.nothingFound.results.button': 'Refine your search',
            'e.nothingFound.booking.title': 'Nothing booked',
            'e.nothingFound.booking.description': 'As a passenger, you have no upcoming rides booked. Let us help you find one',
            'e.nothingFound.booking.button': 'Find a ride',
            'e.nothingFound.offered.title': 'Nothing published',
            'e.nothingFound.offered.description': 'As a driver, you have no upcoming rides published. Let us help you publish one',
            'e.nothingFound.offered.button': 'Publish a ride',
            'e.nothingFound.offered.archived.title': 'Archive is empty',
            'e.nothingFound.offered.archived.description':
                'As a driver, this archive will hold all rides that are completed or that have been manually moved here.',
            'e.nothingFound.booking.archived.title': 'Archive is empty',
            'e.nothingFound.booking.archived.description':
                'As a passenger, this archive will hold reservations for all rides that lie in the past (confirmed or cancelled) or that have been manually moved here.',
            'e.results.allTrips': 'All trips',
            'e.results.allTripsFrom': 'All trips from',
            'e.results.allTripsTo': 'All trips to',
            'e.anywhere': 'Anywhere',
            'e.results.found.a': 'We found',
            'e.results.found.b': 'matching your criteria',
            'e.results.none': "Oops! we couldn't find any rides matching your criteria",
            'e.resultsOrderedByDay': 'Rides are filtered and sorted according to filter settings',
            'e.results.searching': 'Hang tight while we look for matches...',
            'e.somethingWrong': 'Something went wrong',
            'e.issuesLoadingPage': "We're having issues loading this page",
            'e.tryAgain': 'Try again',
            'e.refreshThePage': 'Refresh the page',
            'e.loadMore': 'Load more',
            'e.noPassengersOnRide': 'No bookings on this ride yet',
            'e.noPassengersOnRide.past': 'You did not take any passengers on this ride',
            'e.ratingNA': 'Not available',
            'e.verify.title': 'Get your profile verified',
            'e.verify.subtitle': 'A verified profile has 5x more chance of finding a driver or passenger!',
            'e.verified.profile': 'has a verified profile',
            'e.verified.profile.me': 'Your profile is verified',
            'e.verified.id': 'ID verified',
            'e.verified.photo': 'Profile photo added',
            'e.verified.email': 'Email verified',
            'e.verified.mobile': 'Mobile verified',
            'e.verified.plate': 'Car Registration verified',
            'e.verify.photo.title': 'Profile photo',
            'e.verify.photo.body.new':
                "Upload a recent photo that shows clearly your face and looks like you in real life. The purpose is to allow passengers and drivers to identify each other. This isn't Instagram.",
            'e.verify.photo': 'Add profile photo',
            'e.verify.photo.select': 'Select photo',
            'e.verify.photo.clear': 'Clear photo',
            'e.verify.photo.update': 'Update and Close',

            'e.verify.id': 'Verify ID',
            'e.verify.email': 'Verify Email',
            'e.verify.mobile': 'Verify Mobile',
            'e.verify.plate': 'Verify Car Registration',
            'e.verify.cancel': 'Cancel Verification',
            'e.verify.submit': 'Submit Verification',
            'e.verify.approve': 'Approve Verification',
            'e.verify.reject': 'Reject Verification',
            'e.verify.pending': 'Pending. Click to view',
            'e.verify.addImages': 'Upload images',
            'e.verify.clearImages': 'Clear images',
            'e.verify.id.title': 'ID verification',
            'e.verify.id.body.new':
                "Upload 2 images: a selfie with your ID / driver's license in frame, and one of just your ID. Use the below photo as guidance. Then Click on Submit.",
            'e.verify.id.body.new.gender':
                "Select your gender and upload 2 images: a selfie with your ID / driver's license in frame, and one of just your ID. Use the below photo as guidance. Then Click on Submit.",
            'e.verify.id.body.pending':
                'You have a pending ID verification using the pictures below. If you would like to cancel the verification process, click on Cancel',
            'e.verify.plate.title': 'Car Registration verification',
            'e.verify.plate.body.new':
                'Upload the front and back of your car registration documents. If your car is not registered in your name, upload any additional documents that give you the right to drive that vehicle',
            'e.verify.plate.body.pending':
                'You have a pending Car registration using the pictures below. If you would like to cancel the verification process, click on Cancel',
            'e.user.memberSince': 'Member since',
            'e.user.publishedRides.many': 'published rides',
            'e.user.publishedRides.one': 'publushed ride',
            'e.rate.driver.title': 'How was your trip with',
            'e.rate.driver.done': 'Your rating for this trip',
            'e.rate': 'Rate',
            'e.scrollToTop': 'Scroll to top',
            'e.reviews.1': 'review',
            'e.reviews.2': 'reviews',
            'e.publishedRides': 'Total Published Rides:',
            'e.pageSettings': 'Page Settings',
            'e.pageSettings.language': 'App Language',
            'e.pageSettings.time': 'Time format',
            'e.pageSettings.time.time24': 'use 24H',
            'e.pageSettings.time.timeAMPM': 'use AM/PM',
            'e.pageSettings.tooltips.reset': 'Reset Tooltips',
            'e.pageSettings.reset': 'Reset All App settings',
            'e.pageSettings.motion': 'Motion Settings',
            'e.pageSettings.motion.0': 'Full Transitions',
            'e.pageSettings.motion.5': 'Transitions In',
            'e.pageSettings.motion.10': 'No transitions',
            'e.youCanChangeLater': 'You can change this later',
            'e.booking.confirmedBookings': 'Confirmed Bookings',
            'e.booking.bookings': 'Bookings',
            400: 'Bad Request',
            401: 'Unauthorized Request',
            403: 'Forbidden',
            404: 'Not Found',
            405: 'Method Not Allowed',
            406: 'Not Acceptable',
            500: 'Internal Server Error',
            502: 'Bad Gateway',
            'e.later': 'Maybe later',
            'e.notNow': 'Not now',
            'e.booking.status.archive': 'Move to archive',
            'e.booking.status.archive.headline': 'Move it to archive?',
            'e.booking.status.delete': 'Delete',
            'e.booking.status.delete.headline': 'Delete it?',
            'e.ride.status.archive': 'Move to archive',
            'e.ride.status.archive.headline': 'Move ride to archive?',
            'e.ride.status.delete': 'Delete ride',
            'e.ride.status.delete.headline': 'Delete this ride?',
            'e.ride.status.setToPublish': 'Publish ride',
            'e.ride.status.setToPublish.headline': 'Do you want to publish this ride now?',
            'e.ride.status.setToDraft': 'Unpublish ride',
            'e.ride.status.setToDraft.headline': 'After you unpublish, no one will see your ride',
            'e.exceededRequest': 'Requests exceeded',
            'e.confirmRequest': 'Send request',
            'e.cancelRequest': 'Cancel request',
            'e.cancelRequest.headline': 'Do you want to cancel your booking request?',
            'e.cancelBooking': 'Cancel booking',
            'e.cancelBooking.headline': 'Do you want to cancel your booking?',
            'e.declineRequest': 'Decline request',
            'e.approveRequest': 'Approve Request',
            'e.sendBookingRequest': 'Booking',
            'e.sendBookingRequest.headline': 'Send booking request?',
            'e.sendBookingRequest.exceed.headline': "You've exceeded the max allowed requests for this ride",
            'e.iUnderstand': 'Ok, I understand',
            'e.yesSure': 'Yes, for sure!',
            'e.keepIt.m': 'Keep it',
            'e.keepIt.f': 'Keep it',
            'e.cancel.m': 'Cancel',
            'e.cancel.f': 'Cancel',
            'e.contactDriver': 'Contact Driver',
            'e.driver': 'Driver',
            'e.contactPassenger': 'Contact Passenger',
            'e.contactUser': 'Contact User',
            'e.passengers': 'Passengers',
            'e.viewBookingRequest': 'View Booking Request',
            'e.cancelRide': 'Cancel ride',
            'e.cancelRide.headline': 'Canceling a ride will cancel all bookings associated with it',
            'rides.tab.booked': 'Booked',
            'rides.tab.published': 'Published',
            'err.generic': 'A generic error occurred',
            'err.auth.NoAuthHeader': 'No Auth header found',
            'err.ride.notFound': 'Ride not found',
            'err.booking.notFound': 'No booking found',
            'err.booking.selfbooking': "You're trying to your own trip. Not Allowed",
            'err.booking.inPast': "You're trying to book a past trip. Not allowed",
            'err.booking.existsForUser': 'A Booking on this trip exists for current user',
            'err.booking.notEnoughSeats': 'Not enough seats available',
            'e.distance.approx': '~ ',
            'e.distance.km': ' km ',
            'e.distance.meters': ' m ',
            'e.distanceFrom.start': ' from your start address',
            'e.distanceFrom.end': ' from your arrival address',
            'trip.status.CANCELED': 'This ride is cancelled',
            'publishing.status.DRAFT': 'Unpublished ride',
            'publishing.status.CANCELED': 'You cancelled this ride',
            'publishing.status.PUBLISHED': 'Published ride',
            'publishing.status.DRAFT.archive': 'Unpublished ride',
            'publishing.status.CANCELED.archive': 'Cancelled ride',
            'publishing.status.PUBLISHED.archive': 'Completed Ride',
            'trip.request.EXCEEDED_REQUEST_ATTEMPTS': "You've exceeded the max allowed requests for this ride",
            'trip.request.PENDING': 'Your booking for this ride is pending',
            'trip.request.APPROVED_BY_DRIVER': 'Your booking for this ride is confirmed',
            'trip.request.APPROVED_BY_ADMIN': 'Your booking for this ride is confirmed',
            'booking.request.COMPLETE': 'Completed Ride',
            'booking.request.PENDING': 'This request is pending',
            'booking.request.APPROVED_BY_ADMIN': 'This booking is confirmed',
            'booking.request.APPROVED_BY_DRIVER': 'This booking is confirmed',
            'booking.request.CANCELED_BY_DRIVER': 'Booking canceled by driver',
            'booking.request.CANCELED_BY_PASSENGER': 'You canceled this booking',
            'booking.request.CANCELED_BY_ADMIN': 'Your booking was canceled by Customer service',
            'booking.request.DECLINED_BY_DRIVER': 'Your request was declined',
            'booking.request.DECLINED_BY_ADMIN': 'Your request was declined',
            'booking.request.DECLINED_BY_PASSENGER': 'You canceled this request',
            'e.location.placeholder.from': "Martyrs' Square",
            'e.location.placeholder.to': 'Kaslik',
            'e.page.search.find': 'Find rides',
            'e.page.search.all': 'Browse all rides',
            'e.page.search.headline': 'Where do you want to go?',
            'e.page.search.action': 'Find rides',
            'e.page.search.from': 'Leaving from',
            'e.page.search.from.headline': 'Where are you leaving from?',
            'e.page.search.to': 'Heading to',
            'e.page.search.to.headline': 'Where are you heading to?',
            'e.page.search.date': 'Date',
            'e.page.search.date.headline': 'When are you leaving?',
            'e.page.search.seats': 'Seats',
            'e.page.search.seats.headline': 'Number of seats to book',
            'e.page.action.discardChanges': 'Discard changes',
            'e.page.publish.action.draft': 'Save as draft',
            'e.page.publish.action': 'Publish ride',
            'e.page.publish.action.setTime': 'Set Time',
            'e.page.publish.action.clearTime': 'Clear Time',
            'e.page.publish.action.setDays': 'Set Days',
            'e.page.publish.action.clearDays': 'Clear Days',

            'e.page.publish.action.confirm': 'Confirm',
            'e.page.publish.action.clear': 'Clear All Fields',
            'e.page.publish.from': 'Leaving from',
            'e.page.publish.from.headline': 'Where are you leaving from?',
            'e.page.publish.from.precise': 'Where would you like to pick up passengers?',
            'e.page.publish.to': 'Heading to',
            'e.page.publish.to.headline': 'Where are you heading?',
            'e.page.publish.to.precise': 'Where would you like to drop off passengers?',
            'e.page.publish.date': 'Date',
            'e.page.publish.days': 'Days',
            'e.page.publish.date.headline': 'When are you going?',
            'e.page.publish.time': 'Time',
            'e.page.publish.time.headline': 'At what time will you pick passengers up?',
            'e.page.publish.seats.middle.question': 'Keep the middle seat empty so that your passenger are comfortable?', // offer-seats/comfort
            'e.page.publish.seats.middle.yes': 'Yes, sure!',
            'e.page.publish.seats.middle.no': "No, I'll squeeze in 3",
            'e.page.publish.seats': 'Seats', // offer-seats/seats
            'e.page.publish.seats.headline': 'How many passengers can you take?', // offer-seats/seats
            'e.page.publish.description': 'Comments', // offer-seats/description
            'e.page.publish.description.headline': 'Anything to add about your ride?', // offer-seats/description
            'e.page.publish.description.placeholder': "Please be at pickup place on time. I can't be late for work",
            'e.page.publish.booking.question': 'Can passengers book instantly?', // offer-seats/approval
            'e.page.publish.booking.question.yes': 'Yes, sure!',
            'e.page.publish.booking.question.no': "No, I'll reply to each request myself",
            'e.page.publish.pricing.question': 'This is our recommended price per seat. Ok for you?', // offer-seats/price-recommendation
            'e.page.publish.pricing.question.yes': 'Yes, perfect!',
            'e.page.publish.pricing.question.no': 'No, I need to edit this amount',
            'e.page.publish.return.question': 'Coming back as well? Publish your return ride now!', // offer-seats/return-trip
            'e.page.publish.return.question.yes': 'Yes, sure!',
            'e.page.publish.return.question.no': "I'll publish my return ride later",
            'e.page.publish.return.date': 'When are you returning?',

            'e.useCurrentLocation': 'Use my current location',
            'e.offered.section.subtitle': 'Your upcoming trips as a driver. All trips you publish will be stored here.',
            'e.offered.archived.section.subtitle': 'Past or archived rides as a driver are stored here.',
            'e.booked.section.subtitle': 'Your upcoming trips as a passenger. All booking requests you make will be here.',
            'e.booked.archived.section.subtitle': 'Past or archived bookings / requests as a passenger are stored here.',

            'e.offered.archived.view': 'Archived rides',
            'e.offered.archived.headline': 'Archived rides',

            'e.booked.archived.view': 'Archived bookings',
            'e.booked.archived.headline': 'Archived bookings',
            'e.page.publish.status.draft.headline': 'Your draft ride is saved. You can view it in My Rides under the Published tab.',
            'e.page.publish.status.draft.button': 'Ok got it!',
            'e.page.publish.status.success.headline': "You're all set. Your ride is online and ready",
            'e.page.publish.status.success.button': "That's great!",
            'e.page.publish.status.forbidden.headline': "You're not allowed to make this request. Contact an admin",
            'e.page.publish.status.forbidden.button': 'I understand',
            'e.page.publish.status.duplicate.headline': 'You already have a ride around the same date/time. Rides need to be at least 30 mins apart.',
            'e.page.publish.status.duplicate.button': 'Ok understood',
            'e.page.publish.status.server.headline': "We're having trouble with our servers. You may want to try a bit later",
            'e.page.publish.status.server.button': "Let's try now",
            'e.publish.error.dateTimeInPast': 'The chosen date / time lies in the past',
            'e.page.publish.poster.title': 'Driver',
            'e.page.publish.poster.subtitle1': 'Publish a ride.',
            'e.page.publish.poster.subtitle2': 'Wait for passengers.',
            'e.page.search.poster.title': 'Passenger',
            'e.page.search.poster.subtitle1': 'Find a ride.',
            'e.page.search.poster.subtitle2': 'Contact a driver.',
            'e.loginFlow.start.title': "Let's start",
            'e.loginFlow.start.subtitle': "What's your mobile number?",
            'e.loginFlow.start.note': 'We will send you a verification code to this number by SMS',
            'e.loginFlow.start.button': 'Continue',
            'e.loginFlow.info.title': 'Hello There',
            'e.loginFlow.info.subtitle': "You're new around here. To guarantee the safety of all users, we need a bit more info.",
            'e.loginFlow.info.first.placeholder': 'First Name',
            'e.loginFlow.info.last.placeholder': 'Last Name',
            'e.loginFlow.info.email.placeholder': 'Email',
            'e.loginFlow.info.button': 'Continue',
            'e.loginFlow.blocked.title': 'Blocked',
            'e.loginFlow.blocked.subtitle': 'The account associated with this number is blocked. Try using a different number.',
            'Auth.form.error.blocked': 'The account associated with this number is blocked. Try using a different number.',
            'Auth.form.error.maxAttempts': 'You are either making too many request attempts or have exceeded your maximum per day.',
            'Auth.form.error.vonage': 'You are either making too many request attempts or have exceeded your maximum per day.',
            'Auth.otp.missing.fields.registration': 'Missing Registration fields: Email, first , last',
            'e.loginFlow.blocked.button': "Let's try again",
            'e.loginFlow.verify.title': 'Verify',
            'e.loginFlow.verify.subtitle': 'Please enter the 4 digit verification code sent to:',
            'e.loginFlow.verify.note': "Didn't receive the OTP?",
            'e.loginFlow.verify.note2': 'Resend Code',
            'Auth.otp.verify.error.status.1': 'Throttled. Trying to send too many messages per second (1)',
            'Auth.otp.verify.error.status.16': 'The code inserted does not match the expected value (16)',
            'Auth.otp.verify.error.status.5': 'Internal Error in Cloud Communications Platform (5).',
            'Auth.otp.verify.error.status.6': 'The request could not be routed (6).',
            'Auth.otp.verify.error.status.17': 'The wrong code was provided too many times. Wait 5 mins before clicking back and trying again (17)',
            'Auth.otp.request.error.status.1': 'Throttled. Trying to send too many messages per second (1)',
            'Auth.otp.request.error.status.5': 'Internal Error in Cloud Communications Platform (5).',
            'Auth.otp.request.error.status.6': 'The request could not be routed (6).',
            'Auth.otp.request.error.status.7': 'The number you are trying to verify is blacklisted for verification. (7).',
            'Auth.otp.request.error.status.8': 'API account disabled (8).',
            'Auth.otp.request.error.status.9': 'API quota exceeeded (9).',
            'Auth.otp.request.error.status.10': 'Concurrent verifications to the same number are not allowed (10).',
            'Auth.otp.request.error.status.15': 'The destination number is not in a supported network (15).',
            'Auth.otp.request.error.status.29': 'Non-Permitted Destination (29).',
            'e.loginFlow.finished.title': "You're all set",
            'e.loginFlow.finished.subtitle': 'Ready to start carpooling?',
            'e.loginFlow.finished.button': "Let's ride",
            'e.tip': 'Tip',
            'e.language.title': 'App Language',
            'e.language': 'English',
            'e.languages.en': 'English',
            'e.languages.fr': 'French',
            'e.languages.ar': 'Arabic',
            'e.reducedMotion.title': 'Motion Settings',
            'e.reducedMotion.option1': 'No motion',
            'e.reducedMotion.option2': 'Medium',
            'e.reducedMotion.option3': 'Full',
            'e.timeFormat.title': 'Time format',
            'e.timeFormat.option1': '24 Hour',
            'e.timeFormat.option2': 'AM/PM',
            'e.sendMessage': 'Send a message',
            'e.bug.submitBug': 'Report Bug',
            'e.bug.action': 'What were you trying to do?',
            'e.bug.description': 'Describe what happened',
            'e.bug.submit': 'Submit',
            'e.chat.channel.welcome.label': 'Safe space',
            'e.chat.channel.welcome.text':
                "Welcome to Bala Benzine conversations. It's a safe space for you to chat with a driver, or passenger, without revealing personal information. Ask about seat availability, eventual benzine fees, rules, etc...The dotted menu at the top gives you access to extra tools to keep you safe.",
            'e.chat.deletedMessage': 'Deleted Message',
            'e.chat.blockUser': 'Block Contact',
            'e.chat.blockUser.title': 'Are you sure you want to block this contact?',
            'e.chat.unblockUser': 'Unblock Contact',
            'e.chat.unblockUser.title': 'Are you sure you want to unblock this contact?',
            'e.chat.userProfile': 'About this user',
            'e.chat.reportUser': 'Report User',
            'e.chat.reportUser.title': 'What do you want to report?',
            'e.chat.reportUser.action': 'Report User',
            'report.dui': 'DUI',
            'report.verbalHarassment': 'Verbal harassment',
            'report.sexualHarassment': 'Sexual harassment',
            'report.dirtyVehicle': 'Dirty vehicle',
            'report.dangerousDriving': 'Dangerous driving',
            'report.rude': 'Rude',
            'report.destructionProperty': 'Destruction of property',
            'report.noShow': "Didn't show up",
            'report.fakeIdentity': 'Fake Identity',
            'report.carNotMatching': 'Car does not match',
            'e.chat.whatsAppLink': 'Share WhatsApp Link',
            'e.chat.whatsAppLink.title': 'Share a link to open a new conversation in WhatsApp. By doing so you are revealing your number to the user.',
            'e.chat.whatsAppLink.action': 'Share WhatsApp Link',
            'e.chat.clear': 'Clear Conversation',
            'e.chat.clear.title': 'Are you sure you want to clear this conversation?',
            'e.chat.clear.action': 'Clear Conversation',
            'e.notification.admin.plate.title': 'Car Registration',
            'e.notification.admin.id.title': 'Id Verification',
            'e.notification.admin.mobile.title': 'Mobile Verification',
            'e.notification.admin.email.title': 'Email Verification',
            'e.notification.action.clear': 'Clear Notifications',
            'e.notification.booking.request.sent.title': 'New Request',
            'e.notification.booking.request.sent.body': ' has sent you a booking request for your ride going on ',
            'e.notification.booking.request.canceled.title': 'Canceled',
            'e.notification.booking.request.canceled.body': ' has canceled the request for the trip on ',
            'e.notification.booking.canceledByDriver.title': 'Canceled',
            'e.notification.booking.canceledByDriver.body': ' has canceled your reservation for the trip on ',
            'e.notification.booking.canceledByAdmin.title': 'Canceled',
            'e.notification.booking.canceledByAdmin.body': 'An admin has canceled your reservation for the trip on ',
            'e.notification.booking.declinedByDriver.title': 'Declined',
            'e.notification.booking.declinedByDriver.body': ' has declined your request for the trip on ',
            'e.notification.booking.declinedByAdmin.title': 'Declined',
            'e.notification.booking.declinedByAdmin.body': 'An Admin has declined your request for the trip on ',
            'e.notification.booking.approvedByDriver.title': 'Confirmed',
            'e.notification.booking.approvedByDriver.body': ' has approved your request for the trip on ',
            'e.notification.booking.approvedByAdmin.title': 'Confirmed',
            'e.notification.booking.approvedByAdmin.body': 'An Admin has approved your request for the trip on ',
            'e.notification.error.title': 'Error',
            'e.notification.error.body': 'An error occurred when generating this notification',
            'e.notification.booking.from': ' from ',
            'e.notification.booking.to': ' to ',
            'com.tab.notifications': 'Notifications',
            'com.tab.messages': 'Chat',
            'e.notification.messageSent.title': 'Message Sent',
            'e.notification.messageSent.body': 'You sent some messages. Click to view the conversation.',
            'e.notification.messageReceived.title': 'New Messages',
            'e.notification.messageReceived.body': ' sent you messages. Click to view the conversation.',
            'e.notification.reward.title': 'Reward',
            'e.notification.reward.points': 'points',
            'e.notification.reward.body': 'for:',
            'e.notification.reward.signup.title': 'Signing up to Bala Benzine',
            'e.notification.reward.signup.body': '',
            'e.notification.reward.completedRidePassenger.title': 'Taking a ride as a passenger',
            'e.notification.reward.completedRidePassenger.body': '',
            'e.notification.reward.completedRideDriverMain.title': 'Completing a ride as a driver',
            'e.notification.reward.completedRideDriverMain.body': '',
            'e.notification.reward.completedRideDriverSeat.title': 'Successfully dropping a passenger',
            'e.notification.reward.completedRideDriverSeat.body': '',
            'e.notification.reward.reviewDriver.title': 'Reviewing a ride',
            'e.notification.reward.reviewDriver.body': '',
            'e.notification.reward.referral.title': 'Referring a new user',
            'e.notification.reward.referral.body': '',
            'e.notification.verification.id.pending.title': 'ID Verification',
            'e.notification.verification.id.pending.body': 'Your ID verification request is pending.',
            'e.notification.verification.id.rejected.title': 'Identity Rejected',
            'e.notification.verification.id.rejected.body': 'Your ID request has been rejected.',
            'e.notification.verification.id.canceled.title': 'Identity Canceled',
            'e.notification.verification.id.canceled.body': 'You canceled an ID request.',
            'e.notification.verification.id.approved.title': 'Identity Verified',
            'e.notification.verification.id.approved.body': 'Your Identity is now verified.',
            'e.notification.verification.car.pending.title': 'Car Verification',
            'e.notification.verification.car.pending.body': 'Your Car Registration verification request is pending.',
            'e.notification.verification.car.rejected.title': 'Registration Rejected',
            'e.notification.verification.car.rejected.body': 'Your Car Registration request has been rejected.',
            'e.notification.verification.car.canceled.title': 'Registration Canceled',
            'e.notification.verification.car.canceled.body': 'Your canceled a Car Registration request.',
            'e.notification.verification.car.approved.title': 'Registration Verified',
            'e.notification.verification.car.approved.body': 'Your Car Registration is now verified.',
            'e.notification.verification.profile.approved.title': 'Profile Verified',
            'e.notification.verification.profile.approved.body': 'Your profile is now fully verified. Congrats!',

            'e.suggestRide': 'Suggest this itinerary',
            'e.suggestRide.title': 'Suggest this itinerary?',
            'e.suggestRide.body': 'By suggesting itineraries, you help us to find new users who may propose the itineraries you are looking for.',
            'e.genderType.male': 'Man',
            'e.genderType.female': 'Woman',
            'e.genderType.na': "I'd rather not say",
            'e.genderType.select': 'Select your gender',
            'e.profile.personalInfo.gender': 'Gender',
            'e.profile.personalInfo.recommended': 'recommended',
            'e.profile.personalInfo.title': 'Personal Information',
            'e.profile.personalInfo.firstName': 'First Name',
            'e.profile.personalInfo.lastName': 'Last Name',
            'e.profile.personalInfo.displayName': 'Display Name',
            'e.profile.personalInfo.dob': 'Date of Birth',

            'e.profile.personalInfo.lang': 'App Language',
            'e.profile.personalInfo.email': 'Email',
            'e.profile.personalInfo.mobile': 'Mobile',
            'e.profile.personalInfo.public': 'Can be seen by other users',
            'e.profile.personalInfo.private': 'Will not be shared or seen by other users',
            'e.profile.personalInfo.save': 'Save changes',
            'e.profile.personalInfo.required': 'required',
            'e.profile.personalInfo.optional': 'optional',

            'e.offline.title': 'You are Offline',
            'e.tooltip.searchResults':
                'These icons are signs of high confidence in a user. They respectively mean that his ID and Vehicle registration papers have been verified by our teams.',
            'e.tooltip.acceptUsers': 'To accept, decline, or cancel a booking, click on one of the below users',
            'e.tooltip.tripViewBookings': 'This is the number of confirmed passengers on this ride so far.',
            'e.tooltip.compensation.short': 'Important information about pricing',
            'e.tooltip.compensation':
                "Using Bala Benzine is free. Don't forget to always contact the driver to ask if there are any costs for riding with them.",
            'e.tooltip.noBooking': "This driver isn't accepting any more bookings on this ride",
            'e.ride.acceptBookings': 'Passengers can send me booking requests for this ride',
            'e.ride.ladiesOnly': 'Ladies-only ride',
            'e.ride.recurring': 'This is a recurring ride',
            'e.view.ladiesOnly': 'Ladies-only rides',
            'e.view.archived': 'Archived rides',
            'e.view.punctual': 'One-time rides (non recurring)',
            'e.view.recurring': 'Recurring rides',
            'e.view.dayOfWeek': 'Rides matching Day of week',
            'e.view.verifiedId': 'Driver has a verified ID',
            'e.view.verifiedCar': 'Driver has verified car registration',
            'e.filter.button': 'Filter & Sort',
            'e.filter.title': 'Filter by',
            'e.filter.ride.title': 'Ride filters',
            'e.filter.security.title': 'Security filters',
            'e.filter.admin.title': 'Admin filters',
            'e.sort.title': 'Sort by',
            'e.filter.showRides': 'Show Rides',
            'e.filter.reset': 'Reset filters to default',
            'e.ride.doNotAcceptBookings': "You aren't accepting bookings",
            'e.static.btn.close': 'Close',
            'e.tooltip.important': 'Why is this important?',
            'e.tooltip.ladiesOnly.short': 'About ladies-only rides',
            'e.ride.status.ladiesOnly': 'Ladies-only ride',
            'e.list.true': 'Yes',
            'e.list.false': 'No',
            'e.list.null': 'None',
            'e.latestNews.title': "What's new",
            'e.days.every': 'Every'
        }
    },
    fr: {
        formatting: {
            locale: 'fr',
            dateResults: 'ccc d LLL',
            dateTimeResults: 'ccc, LLL d, HH:mm'
        },
        rtl: false,
        lang: 'French',
        lang_iso: 'fr',
        data: {
            'install.socialButton': `Envoie-moi le lien`,
            'install.socialTitle': "Le lien d'installation",
            'install.socialTitleSent': 'Lien envoyé',
            'install.socialBody': `Obtenez le lien d'installation de Bala Benzine via WhatsApp en saisissant votre numéro de téléphone portable ci-dessous.<p> L'installation et utilisation de Bala Benzine <strong><i>nécessite un numéro de téléphone libanais.</i></strong></p>`,
            'install.socialDone': `Lancez WhatsApp et clickez sur le lien qu'on vient de vous envoyer. <p>👋 On se revoit dans un petit moment!</p>`,

            'install.introTitle': 'Installation',
            'install.introBody': `
                                    <strong>
                                        <u>
                                            <a href='https://balabenzine.com' target='_blank'>
                                                Bala Benzine
                                            </a>
                                        </u>
                                    </strong>&nbsp;
                                    est une application web de covoiturage solidaire <span class='text-xl'>🚘+📱</span>&nbsp;
                                    qui met en relation des passagers et des conducteurs. Installez là dès maintenant et partagez vos trajets partout au Liban !
            `,
            'install.reqTitle': `Configuration requise`,
            'install.reqBody': `L’installation et l’utilisation de l’application Bala Benzine requiert <strong><i>un numéro de téléphone libanais</i></strong>. <p>Vous êtes un potentiel partenaire étranger (presse, média en ligne, influenceur...) et vous souhaitez tester l'application ? Contactez-nous : <span class='text-vw-sm'><strong><u>hello@balabenzine.app</u></strong></span></p>`,
            'install.iPhoneTitle': `iPhone`,
            'install.iPhoneBody': `<ul class='text-vw-sm'>
                                        <li>Navigateur Safari v14.5 (dispo. avril 2021) ou ultérieure</li>
                                        
                                        <li>Ne pas être en <strong>mode privé</strong> lors de l'installation</li>
                                    </ul>`,
            'install.androidTitle': `Android`,
            'install.androidBody': `<ul class='text-vw-sm'>
                                        <li>Navigateur Google Chrome</li>
                                        
                                        <li>Ne pas être en <strong>mode incognito</strong> lors de l'installation</li>
                                    </ul>`,
            'install.btnInstall': `Installer Bala Benzine`,
            'install.btnInstalled': `Installé`,
            'menu.ladiesOnly': 'Trajets réservés aux femmes',
            'menu.pricing': 'Coûts et frais',
            'menu.admin.title': 'Admin',
            'menu.admin.users': 'Tous les utilisateurs',
            'menu.admin.verify': 'Verifications',
            'menu.admin.verify.filter.pending': 'Demandes en attente',
            'menu.admin.verify.filter.women': 'Femmes',
            'menu.admin.verify.filter.verifiedId': 'Identité verifiée',
            'menu.admin.verify.filter.verifiedProfile': 'Profil verifié',
            'menu.admin.verify.filter.verifiedCar': "Certificat d'immatriculation verifié",
            'menu.admin.verify.filter.blockUser': 'Utilisateur banni',
            'menu.notifications': 'Notifications',
            'menu.rewards': 'Récompenses',
            'menu.ambassador': 'Devenir ambassadeur',
            'menu.profile': 'Profil',
            'menu.settings': "Paramètres de l'appli",
            'menu.logout': 'Deconnexion',
            'menu.login': 'Connexion',
            'menu.register': 'Inscription',
            'menu.about': "C'est quoi Bala Benzine ?",
            'menu.safety': 'Consignes de sécurité',
            'menu.faq': 'FAQ',
            'menu.help': 'Aide',
            'menu.help.support': 'Contacter le Support',
            'menu.help.terms': 'Termes et conditions',
            'menu.help.privacy': 'Politique de confidentialité',
            'menu.help.weNeedYou': 'On a besoin de vous',
            'menu.help.latestNews': 'Dans cette version',
            'menu.help.donate': 'Faire un don',
            'nav.find': 'Rechercher',
            'nav.rides': 'Mes trajets',
            'nav.publish': 'Publier',
            'nav.messages': 'Discuter',
            'nav.news': 'Notifications',
            'w.rides': { 1: 'trajet', 2: 'trajets', 3: 'trajets' },
            'e.nothingFound.verification.title': 'Pas de Verifications',
            'e.nothingFound.verification.description': "Vous n'avez pas de nouvelles demandes de vérification",
            'e.nothingFound.notification.title': 'Pas de notifications',
            'e.nothingFound.notification.description': "Vous n'avez aucune notification à afficher",
            'e.nothingFound.channel.title': 'Aucune conversation',
            'e.nothingFound.channel.description': "Vous n'avez pas encore de conversations. Trouvez un trajet et contactez le conducteur.",
            'e.nothingFound.channel.button': 'Réessayer',
            'e.nothingFound.chat.title': 'Oups...',
            'e.nothingFound.chat.description': 'Nous rencontrons des difficultés pour charger vos conversations. Réessayez dans quelques instants',
            'e.nothingFound.chat.button': 'Réessayer',
            'e.nothingFound.results.title': 'Aucun résultat',
            'e.nothingFound.results.description':
                "Essayer d'élargir votre critère de recherches. Essayez un quartier au lieu d'une rue / place ou une ville au lieu d'un quartier.",
            'e.nothingFound.results.button': 'Affiner votre recherche',
            'e.nothingFound.booking.title': 'Aucune réservation',
            'e.nothingFound.booking.description': "Vous n'avez aucun trajet de prévu. Laissez-nous vous aider à en trouver un",
            'e.nothingFound.booking.button': 'Trouver un trajet',
            'e.nothingFound.offered.title': 'Pas de trajets publiés',
            'e.nothingFound.offered.description': "En tant que conducteur, vous n'avez publié aucun trajet à venir. Laissez-nous vous aider à en publier un",
            'e.nothingFound.offered.button': 'Publier un trajet',
            'e.nothingFound.offered.archived.title': "L'archive est vide",
            'e.nothingFound.offered.archived.description':
                'En tant que conducteur, cette archive contiendra tous les trajets terminés ou déplacés manuellement ici.',
            'e.nothingFound.booking.archived.title': "L'archive est vide",
            'e.nothingFound.booking.archived.description':
                'En tant que passager, cette archive contiendra les réservations pour tous les trajets passés (confirmés ou annulés) ou qui ont été déplacés manuellement ici.',
            'e.results.allTrips': 'Tous les trajets',
            'e.results.allTripsFrom': 'Tous les trajets en provenance de',
            'e.results.allTripsTo': 'Tous les trajets vers',
            'e.anywhere': 'Partout',
            'e.results.found.a': 'On a trouvé',
            'e.results.found.b': 'disponible(s)',
            'e.results.none': 'Oups. Aucun trajet trouvé qui correspond à votre recherche',
            'e.resultsOrderedByDay': 'Les trajets sont filtrés et triés en fonction des paramètres de filtre',
            'e.results.searching': 'Merci de patienter...',
            'e.somethingWrong': 'Un problème est survenu',
            'e.issuesLoadingPage': 'Nous rencontrons des problèmes pour charger cette page',
            'e.tryAgain': 'Réessayer',
            'e.refreshThePage': 'Rafraîchir la page',
            'e.loadMore': 'Charger plus',
            'e.noPassengersOnRide': "Aucune réservation sur ce trajet pour l'instant",
            'e.noPassengersOnRide.past': "Vous n'avez eu aucun passager sur ce trajet",
            'e.ratingNA': 'Non disponible',
            'e.verify.title': 'Faites vérifier votre profil',
            'e.verify.subtitle': 'Un profil vérifié a 5x plus de chance de trouver un passager ou un conducteur !',
            'e.verified.profile': 'a un Profil Vérifié',
            'e.verified.profile.me': 'Votre profil est vérifié',
            'e.verified.id': 'Pièce d’identité vérifiée',
            'e.verified.photo': 'Photo de profil mise à jour',
            'e.verified.email': 'Adresse e-mail vérifiée',
            'e.verified.mobile': 'Numéro de téléphone vérifié',
            'e.verified.plate': "Certificat d'immatriculation vérifié",
            'e.verify.photo': 'Photo de profil absente',
            'e.verify.photo.title': 'Photo de profil',
            'e.verify.photo.body.new':
                "Ajouter une photo qui montre un visage dégagé et qui vous ressemble en vrai, pour permettre aux passagers et aux conducteurs de s'identifier et de se proteger.",
            'e.verify.photo': 'Ajouter une photo de profil',
            'e.verify.photo.select': 'Selectionner la photo',
            'e.verify.photo.clear': 'Effacer la photo',
            'e.verify.photo.update': 'Mettre à jour et fermer',
            'e.verify.id': "Vérification d'identité",
            'e.verify.email': 'Email non vérifié',
            'e.verify.mobile': 'Numéro de téléphone non vérifié',
            'e.verify.plate': "Verification du certificat d'immatriculation",
            'e.verify.cancel': 'Annuler la Verification',
            'e.verify.submit': 'Envoyer',
            'e.verify.approve': 'Approuver la Verification',
            'e.verify.reject': 'Refuser la Verification',
            'e.verify.pending': 'En cours. Plus de détails',
            'e.verify.addImages': 'Importer des images',
            'e.verify.clearImages': 'supprimer les images',
            'e.verify.id.title': "Vérification d'identité",
            'e.verify.id.body.new':
                "Selectionnez 2 photos: un selfie avec votre pièce d'identité (ou permis de conduire); et une photo claire de votre pièce d'identité (voir ci dessous). Puis envoyez!",
            'e.verify.id.body.new.gender':
                "Choisissez votre genre et 2 photos: un selfie avec votre pièce d'identité (ou permis de conduire); et une photo claire de votre pièce d'identité (voir ci dessous). Puis envoyez!",
            'e.verify.id.body.pending':
                "Vous avez une vérification d'identité en attente. Si vous souhaitez annuler le processus de vérification, cliquez sur Annuler",
            'e.verify.plate.title': "Vérification du certificat d'immatriculation",
            'e.verify.plate.body.new':
                "Téléchargez le certificat d'immatriculation recto verso. Si le vehicule n'est pas immatriculée à votre nom, renseignez tout document supplémentaire vous donnant le droit de conduire ce véhicule",
            'e.verify.plate.body.pending':
                'You have a pending Car registration using the pictures below. If you would like to cancel the verification process, click on Cancel',
            'e.user.memberSince': 'Membre depuis',
            'e.user.publishedRides.many': 'trajets publiés',
            'e.user.publishedRides.one': 'trajet publié',
            'e.rate.driver.title': 'Évaluez votre trajet avec',
            'e.rate.driver.done': 'Votre évaluation de ce trajet',
            'e.rate': 'Évaluez',
            'e.scrollToTop': 'Défiler vers le haut',
            'e.reviews.1': 'évaluation',
            'e.reviews.2': 'évaluations',
            'e.publishedRides': 'Nombre de trajets publiés:',
            'e.pageSettings': 'Page Settings',
            'e.pageSettings.language': "Langue de l'appli",
            'e.pageSettings.time': "Format de l'heure",
            'e.pageSettings.time.time24': 'Format 24H',
            'e.pageSettings.time.timeAMPM': 'Format AM/PM',
            'e.pageSettings.tooltips.reset': 'Réinitialiser les info-bulles',
            'e.pageSettings.reset': 'Réinitialiser tous les paramètres',
            'e.pageSettings.motion': 'Paramètres de mouvement',
            'e.pageSettings.motion.0': 'Transitions complètes',
            'e.pageSettings.motion.5': 'Transitions Entrant',
            'e.pageSettings.motion.10': 'Pas de transitions',
            'e.youCanChangeLater': 'Vous pouvez modifier ça plus tard',
            'e.booking.confirmedBookings': 'Réservations confirmées',
            'e.booking.bookings': 'Réservations',
            400: 'Bad Request',
            401: 'Unauthorized Request',
            403: 'Forbidden',
            404: 'Not Found',
            405: 'Method Not Allowed',
            406: 'Not Acceptable',
            500: 'Internal Server Error',
            502: 'Bad Gateway',
            'e.notNow': 'Plus tard',
            'e.later': 'Plus tard',
            'e.booking.status.archive': 'Archiver',
            'e.booking.status.archive.headline': 'Archiver?',
            'e.booking.status.delete': 'Supprimer',
            'e.booking.status.delete.headline': 'Supprimer?',
            'e.ride.status.archive': 'Archiver ce trajet',
            'e.ride.status.archive.headline': 'Archiver ce trajet ?',
            'e.ride.status.delete': 'Supprimer ce trajet',
            'e.ride.status.delete.headline': 'Supprimer ce trajet ?',
            'e.ride.status.setToPublish': 'Publier le trajet',
            'e.ride.status.setToPublish.headline': 'Voulez vous publier ce trajet ?',
            'e.ride.status.setToDraft': 'Dépublier le trajet',
            'e.ride.status.setToDraft.headline': 'Une fois dépublié, personne ne pourra voir ce trajet',
            'e.exceededRequest': 'Plafond atteind',
            'e.confirmRequest': 'Envoyer',
            'e.cancelRequest': 'Annuler',
            'e.cancelRequest.headline': 'Annuler la demande de réservation ?',
            'e.cancelBooking': 'Annuler la réservation',
            'e.cancelBooking.headline': 'Annuler la réservation ?',
            'e.declineRequest': 'Refuser la demande',
            'e.approveRequest': 'Accepter la demande',
            'e.sendBookingRequest': 'Réservations',
            'e.sendBookingRequest.headline': 'Envoyer la demande de réservation ?',
            'e.sendBookingRequest.exceed.headline': 'Vous avez depassé le nombre maximal de demande pour ce trajet',
            'e.iUnderstand': "Ok, J'ai compris",
            'e.yesSure': 'Oui, bien sur!',
            'e.keepIt.m': 'Je garde',
            'e.keepIt.f': 'Je garde',
            'e.cancel.m': 'Annuler',
            'e.cancel.f': 'Annuler',
            'e.contactDriver': 'Parler au conducteur',
            'e.driver': 'Conducteur',
            'e.contactPassenger': 'Contacter',
            'e.contactUser': 'Contacter',
            'e.passengers': 'Passagers',
            'e.viewBookingRequest': 'Voir la demande',
            'e.cancelRide': 'Annuler le trajet',
            'e.cancelRide.headline': "L'annulation d'un trajet annulera toutes les réservations qui lui sont associées",
            'rides.tab.booked': 'Réservés',
            'rides.tab.published': 'Publiés',
            'err.generic': 'A generic error occurred',
            'err.auth.NoAuthHeader': 'No Auth header found',
            'err.ride.notFound': 'Ride not found',
            'err.booking.notFound': 'No booking found',
            'err.booking.selfbooking': "You're trying to your own trip. Not Allowed",
            'err.booking.inPast': "You're trying to book a past trip. Not allowed",
            'err.booking.existsForUser': 'A Booking on this trip exists for current user',
            'err.booking.notEnoughSeats': 'Not enough seats available',
            'e.distance.approx': '~ ',
            'e.distance.km': ' km ',
            'e.distance.meters': ' m ',
            'e.distanceFrom.start': ' de votre adress de départ',
            'e.distanceFrom.end': " de votre adress d'arrivée",
            'trip.status.CANCELED': 'Ce trajet est annulé',
            'publishing.status.DRAFT': 'Trajet non publié',
            'publishing.status.CANCELED': 'Trajet annulé',
            'publishing.status.PUBLISHED': 'Trajet publié',
            'publishing.status.DRAFT.archive': 'Trajet non publié',
            'publishing.status.CANCELED.archive': 'Trajet annulé',
            'publishing.status.PUBLISHED.archive': 'Trajet terminé',
            'trip.request.EXCEEDED_REQUEST_ATTEMPTS': 'Vous avez depassé le nombre maximal de demande pour ce trajet',
            'trip.request.PENDING': 'Vous avez une demande en attente',
            'trip.request.APPROVED_BY_DRIVER': 'Vous avez une réservation pour ce trajet',
            'trip.request.APPROVED_BY_ADMIN': 'Vous avez une réservation pour ce trajet',
            'booking.request.COMPLETE': 'Trajet terminé',
            'booking.request.PENDING': 'Demande en attente',
            'booking.request.APPROVED_BY_ADMIN': 'Confirmé par le service client',
            'booking.request.APPROVED_BY_DRIVER': 'Confirmé par le conducteur',
            'booking.request.CANCELED_BY_DRIVER': 'Annulé par le conducteur',
            'booking.request.CANCELED_BY_PASSENGER': 'Vous avez annulé cette réservation',
            'booking.request.CANCELED_BY_ADMIN': 'Annulé par le service client',
            'booking.request.DECLINED_BY_DRIVER': 'Refusé par le conducteur',
            'booking.request.DECLINED_BY_ADMIN': 'Refusé par le service client',
            'booking.request.DECLINED_BY_PASSENGER': 'Vous avez annulé cette demande',
            'e.location.placeholder.from': "Martyrs' Square",
            'e.location.placeholder.to': 'Kaslik',
            'e.page.search.find': 'Rechercher',
            'e.page.search.all': 'Tous les trajets',
            'e.page.search.headline': 'Où voulez-vous aller ?',
            'e.page.search.action': 'Rechercher',
            'e.page.search.from': 'Départ',
            'e.page.search.from.headline': "D'où partez-vous exactement ?",
            'e.page.search.to': 'Destination',
            'e.page.search.to.headline': 'Où voulez-vous aller ?',
            'e.page.search.date': 'Date',
            'e.page.search.date.headline': 'Quand partez-vous ?',
            'e.page.search.seats': 'Nombre de places que vous souhaitez réserver',
            'e.page.search.seats.headline': 'Places',
            'e.page.action.discardChanges': 'Discard changes',
            'e.page.publish.action.draft': 'Annuler',
            'e.page.publish.action': 'Publier',
            'e.page.publish.action.setTime': "Confirmer l'heure",
            'e.page.publish.action.clearTime': "Effacer l'heure",
            'e.page.publish.action.setDays': 'Confirmer les jours',
            'e.page.publish.action.clearDays': 'Effacer les jours',
            'e.page.publish.action.confirm': 'Confirmer',
            'e.page.publish.action.clear': 'Effacer le formulaire',
            'e.page.publish.from': 'Départ',
            'e.page.publish.from.headline': 'D’où partez-vous ?',
            'e.page.publish.from.precise': 'Où souhaitez-vous récupérer vos passagers ?',
            'e.page.publish.to': 'Destination',
            'e.page.publish.to.headline': 'Où allez-vous ?',
            'e.page.publish.to.precise': 'Où souhaitez-vous déposer vos passagers ?',
            'e.page.publish.date': 'Date',
            'e.page.publish.date': 'Jours',
            'e.page.publish.date.headline': 'Quand partez-vous ?',
            'e.page.publish.time': 'Heure',
            'e.page.publish.time.headline': 'À quelle heure vous souhaitez retrouver vos passagers?',
            'e.page.publish.seats.middle.question': "Laissez le siège du milieu vide pour que vos passagers soient plus à l'aise", // offer-seats/comfort
            'e.page.publish.seats.middle.yes': 'Oui, avec plaisir',
            'e.page.publish.seats.middle.no': 'Non, ils se serreront à trois',
            'e.page.publish.seats': 'Places', // offer-seats/seats
            'e.page.publish.seats.headline': 'Combien de passagers pouvez-vous accepter ?', // offer-seats/seats
            'e.page.publish.description': 'Commentaires', // offer-seats/description
            'e.page.publish.description.headline': 'Quelque chose à ajouter à propos de votre trajet ?', // offer-seats/description
            'e.page.publish.description.placeholder': "Soyez à l'heure SVP.",
            'e.page.publish.booking.question': 'Les passagers peuvent-ils réserver directement ?', // offer-seats/approval
            'e.page.publish.booking.question.yes': 'Oui, avec plaisir',
            'e.page.publish.booking.question.no': 'Non, je répondrai à chaque demande moi-même',
            'e.page.publish.pricing.question': 'Voici notre prix recommandé par siège. Ça vous va ?', // offer-seats/price-recommendation
            'e.page.publish.pricing.question.yes': 'Oui, parfait',
            'e.page.publish.pricing.question.no': 'Non, il faut que je modifie ce montant',
            'e.page.publish.return.question': 'Vous faites aussi le voyage de retour ? Publiez votre trajet maintenant', // offer-seats/return-trip
            'e.page.publish.return.question.yes': 'Oui, avec plaisir',
            'e.page.publish.return.question.no': 'Je publierai mon trajet retour plus tard',
            'e.page.publish.return.date': 'Quand repartez-vous ?',
            'e.useCurrentLocation': 'Utiliser ma position actuelle',
            'e.offered.section.subtitle': 'Vos prochains trajets en tant que conducteur. Tous les trajets que vous publiez seront ici.',
            'e.offered.archived.section.subtitle': 'Les trajets terminés ou archivés en tant que conducteur sont ici.',
            'e.booked.section.subtitle': 'Vos prochains trajets en tant que passager. Toutes réservations / demandes que vous faites sont ici.',
            'e.booked.archived.section.subtitle': 'Les demandes / réservations de trajets terminés ou archivés sont ici.',
            'e.offered.archived.view': 'Archives',
            'e.offered.archived.headline': 'Trajets archivés',
            'e.booked.archived.view': 'Archives',
            'e.booked.archived.headline': 'Réservations archivées',
            'e.page.publish.status.draft.headline': "Votre trajet est sauvegardé. Vous pouvez le consulter dans l'onglet Mes Trajets puis Publiés",
            'e.page.publish.status.draft.button': 'Ok',
            'e.page.publish.status.success.headline': 'Ça y est, votre trajet est prêt et en ligne.',
            'e.page.publish.status.success.button': 'Super!',
            'e.page.publish.status.forbidden.headline': "Vous n'avez pas le droit de faire cette demande.",
            'e.page.publish.status.forbidden.button': 'Ok, je comprends',
            'e.page.publish.status.duplicate.headline':
                "Vous avez déjà un trajet vers la même date/heure. Les trajets doivent être espacés d'au moins 30 minutes.",
            'e.page.publish.status.duplicate.button': 'Ok Compris',
            'e.page.publish.status.server.headline': 'Problème technique. Veuillez réessayer plus tard',
            'e.page.publish.status.server.button': 'Réessayons maintenant',
            'e.publish.error.dateTimeInPast': "L'heure et date choisis tombe dans le passé",
            'e.page.publish.poster.title': 'Conducteur',
            'e.page.publish.poster.subtitle1': 'Publiez un trajet.',
            'e.page.publish.poster.subtitle2': 'Les passagers vont suivre.',
            'e.page.search.poster.title': 'Passager',
            'e.page.search.poster.subtitle1': 'Trouvez un trajet.',
            'e.page.search.poster.subtitle2': 'Contactez un conducteur.',
            'e.loginFlow.start.title': 'Commençons',
            'e.loginFlow.start.subtitle': 'Quel est votre numéro de portable ?',
            'e.loginFlow.start.note': 'Nous vous enverrons un code de vérification à ce numéro par SMS',
            'e.loginFlow.start.button': 'Continuer',
            'e.loginFlow.info.title': 'Bonjour',
            'e.loginFlow.info.subtitle': "Pour garantir la sécurité de tous, nous avons besoin d'un peu plus d'informations.",
            'e.loginFlow.info.first.placeholder': 'Prénom',
            'e.loginFlow.info.last.placeholder': 'Nom',
            'e.loginFlow.info.email.placeholder': 'Email',
            'e.loginFlow.info.button': 'Continuer',
            'e.loginFlow.blocked.title': 'Bloqué',
            'e.loginFlow.blocked.subtitle': "Le compte associé à ce numéro est bloqué. Essayez d'utiliser un autre numéro.",
            'Auth.form.error.blocked': "Le compte associé à ce numéro est bloqué. Essayez d'utiliser un autre numéro.",
            'Auth.form.error.maxAttempts': 'Soit vous faites trop de tentatives de demande, soit vous avez dépassé votre maximum par jour.',
            'Auth.form.error.vonage': 'Soit vous faites trop de tentatives de demande, soit vous avez dépassé votre maximum par jour.',
            'Auth.otp.missing.fields.registration': 'Champs manquants: Email, Prenom , Nom',
            'e.loginFlow.blocked.button': 'On réessaye',
            'e.loginFlow.verify.title': 'Vérifier',
            'e.loginFlow.verify.subtitle': 'Veuillez saisir le code de vérification à 4 chiffres envoyé à',
            'e.loginFlow.verify.note': "Vous n'avez pas reçu l'OTP ?",
            'e.loginFlow.verify.note2': 'Renvoyer le code',
            'Auth.otp.verify.error.status.1': "Étranglé. Vous essayez d'envoyer trop de messages par seconde (1)",
            'Auth.otp.verify.error.status.16': 'Le code inséré ne correspond pas à la valeur attendue (16)',
            'Auth.otp.verify.error.status.5': 'Erreur interne dans la plate-forme de communication cloud (5).',
            'Auth.otp.verify.error.status.6': "La demande n'a pas pu être acheminée (6).",
            'Auth.otp.verify.error.status.17':
                'Le mauvais code a été fourni trop de fois. Attendez 5 minutes, puis appuyez sur le bouton de retour pour réessayer (17)',
            'Auth.otp.request.error.status.1': "Étranglé. Vous essayez d'envoyer trop de messages par seconde (1)",
            'Auth.otp.request.error.status.5': 'Erreur interne dans la plate-forme de communication cloud (5).',
            'Auth.otp.request.error.status.6': "La demande n'a pas pu être acheminée (6).",
            'Auth.otp.request.error.status.7': 'Le numéro que vous essayez de vérifier est sur liste noire. (7).',
            'Auth.otp.request.error.status.8': 'Compte API désactivé (8).',
            'Auth.otp.request.error.status.9': "Quota d'API dépassé (9).",
            'Auth.otp.request.error.status.10': 'Les vérifications simultanées au même numéro ne sont pas autorisées (10).',
            'Auth.otp.request.error.status.15': "Le numéro de destination n'est pas dans un réseau pris en charge (15).",
            'Auth.otp.request.error.status.29': 'Destination non autorisée (29).',
            'e.loginFlow.finished.title': 'Vous êtes prêt',
            'e.loginFlow.finished.subtitle': 'Prêt à commencer le covoiturage ?',
            'e.loginFlow.finished.button': 'Allons-y',
            'e.tip': 'Astuce',
            'e.language.title': "Langue de l'app",
            'e.language': 'Français',
            'e.languages.en': 'Anglais',
            'e.languages.fr': 'Français',
            'e.languages.ar': 'Arabe',
            'e.reducedMotion.title': 'Paramètres de mouvement',
            'e.reducedMotion.option1': 'Aucun mouvement',
            'e.reducedMotion.option2': 'Moyen',
            'e.reducedMotion.option3': 'Maximum',
            'e.timeFormat.title': "Format de l'heure",
            'e.timeFormat.option1': '24 Heures',
            'e.timeFormat.option2': 'AM/PM',
            'e.sendMessage': 'Envoyer un message',
            'e.bug.submitBug': 'Signaler un Bug',
            'e.bug.action': "Qu'est ce que vous essayez de faire?",
            'e.bug.description': "Décrivez ce qui s'est passé",
            'e.bug.submit': 'Envoyer',
            'e.chat.channel.welcome.label': 'Espace de discussion',
            'e.chat.channel.welcome.text':
                "Bienvenue dans les conversations de Bala Benzine. C'est un espace sécurisé pour discuter avec un conducteur ou un passager, sans révéler d'informations personnelles. Renseignez-vous sur la disponibilité des sièges, les frais éventuels de benzine, les règles, etc. Le menu en pointillés en haut vous donne accès à des outils supplémentaires pour assurer votre sécurité.",
            'e.chat.deletedMessage': 'Message Effacé',
            'e.chat.blockUser': 'Bloquer ce contact',
            'e.chat.blockUser.title': 'Voulez-vous vraiment bloquer ce contact ?',
            'e.chat.unblockUser.title': 'Voulez-vous vraiment débloquer ce contact ?',
            'e.chat.unblockUser': 'Débloquer ce Contact',
            'e.chat.userProfile': "Profil de l'utilisateur",
            'e.chat.reportUser': 'Signaler un abus',
            'e.chat.reportUser.title': 'Que voulez-vous signaler ?',
            'e.chat.reportUser.action': 'Signaler un abus',
            'report.dui': "Conduite en état d'ébriété",
            'report.verbalHarassment': 'Harcèlement verbal',
            'report.sexualHarassment': 'Harcèlement sexuel',
            'report.dirtyVehicle': 'Véhicule sale',
            'report.dangerousDriving': 'Conduite dangereuse',
            'report.rude': 'Impolitesse',
            'report.destructionProperty': 'Destruction de biens',
            'e.chat.whatsAppLink': 'Partager lien WhatsApp',
            'e.chat.whatsAppLink.title':
                "Partagez un lien pour ouvrir une nouvelle conversation dans WhatsApp. Cette opération révèlera votre numéro à l'utilisateur.",
            'e.chat.whatsAppLink.action': 'Partage lien WhatsApp',
            'e.chat.clear': 'Effacer la Conversation',
            'e.chat.clear.title': 'Voulez-vous vraiment supprimer cette conversation ?',
            'e.chat.clear.action': 'Effacer la Conversation',
            'e.notification.admin.plate.title': "C. d'immatriculation",
            'e.notification.admin.id.title': "Verification d'identité",
            'e.notification.admin.mobile.title': 'Verification du mobile',
            'e.notification.admin.email.title': "Verification de l'email",
            'e.notification.action.clear': 'Effacer les notifications',
            'e.notification.booking.request.sent.title': 'Nouvelle demande',
            'e.notification.booking.request.sent.body': ' vous a envoyé(e) une demande de réservation pour le trajet allant  ',
            'e.notification.booking.request.canceled.title': 'Annulé',
            'e.notification.booking.request.canceled.body': ' a annulé(e) la demande de réservation pour le trajet allant ',
            'e.notification.booking.canceledByDriver.title': 'Annulé',
            'e.notification.booking.canceledByDriver.body': ' a annulé(e) votre réservation pour le trajet allant ',
            'e.notification.booking.canceledByAdmin.title': 'Annulé',
            'e.notification.booking.canceledByAdmin.body': 'Bala Benzine a annulé votre réservation pour le trajet allant ',
            'e.notification.booking.declinedByDriver.title': 'Refusé',
            'e.notification.booking.declinedByDriver.body': ' a refusé(e) la demande de réservation pour le trajet allant ',
            'e.notification.booking.declinedByAdmin.title': 'Refusé',
            'e.notification.booking.declinedByAdmin.body': 'Bala Benzine a refusé la demande de réservation pour le trajet allant ',
            'e.notification.booking.approvedByDriver.title': 'Confirmé',
            'e.notification.booking.approvedByDriver.body': ' a confirmé(e) votre réservation pour le trajet allant ',
            'e.notification.booking.approvedByAdmin.title': 'Confirmé',
            'e.notification.booking.approvedByAdmin.body': 'Bala Benzine a confirmé votre réservation pour le trajet allant ',
            'e.notification.booking.from': ' de ',
            'e.notification.booking.to': ' vers ',
            'com.tab.notifications': 'Notifications',
            'com.tab.messages': 'Discussions',
            'e.notification.messageSent.title': 'Message Envoyé',
            'e.notification.messageSent.body': 'Vous avez envoyé des messages. Appuyez ici pour lire la conversation.',
            'e.notification.messageReceived.title': 'Nouveaux Messages',
            'e.notification.messageReceived.body': ' vous a envoyé des messages. Appuyez ici pour lire la conversation.',
            'e.notification.reward.title': 'Récompense',
            'e.notification.reward.points': 'points',
            'e.notification.reward.body': 'pour:',
            'e.notification.reward.signup.title': "Création d'un compte sur Bala Benzine",
            'e.notification.reward.signup.body': '',
            'e.notification.reward.completedRidePassenger.title': 'Avoir pris un trajet en tant que passager',
            'e.notification.reward.completedRidePassenger.body': '',
            'e.notification.reward.completedRideDriverMain.title': 'Avoir complété un trajet en tant que conducteur',
            'e.notification.reward.completedRideDriverMain.body': '',
            'e.notification.reward.completedRideDriverSeat.title': 'Avoir déposé un passager',
            'e.notification.reward.completedRideDriverSeat.body': '',
            'e.notification.reward.reviewDriver.title': 'Avoir donné un avis sur un trajet',
            'e.notification.reward.reviewDriver.body': '',
            'e.notification.reward.referral.title': "Le parrainage d'un nouvel utilisateur",
            'e.notification.reward.referral.body': '',
            'e.notification.verification.id.pending.title': 'ID Verification',
            'e.notification.verification.id.pending.body': 'Your ID verification request is pending.',
            'e.notification.verification.id.rejected.title': 'Identity Rejected',
            'e.notification.verification.id.rejected.body': 'Your ID request has been rejected.',
            'e.notification.verification.id.canceled.title': 'Identity Canceled',
            'e.notification.verification.id.canceled.body': 'You canceled an ID request.',
            'e.notification.verification.id.approved.title': 'Identity Verified',
            'e.notification.verification.id.approved.body': 'Your Identity is now verified.',
            'e.notification.verification.car.pending.title': 'Car Verification',
            'e.notification.verification.car.pending.body': 'Your Car Registration verification request is pending.',
            'e.notification.verification.car.rejected.title': 'Registration Rejected',
            'e.notification.verification.car.rejected.body': 'Your Car Registration request has been rejected.',
            'e.notification.verification.car.canceled.title': 'Registration Canceled',
            'e.notification.verification.car.canceled.body': 'Your canceled a Car Registration request.',
            'e.notification.verification.car.approved.title': 'Registration Verified',
            'e.notification.verification.car.approved.body': 'Your Car Registration is now verified.',
            'e.notification.verification.profile.approved.title': 'Profile Verified',
            'e.notification.verification.profile.approved.body': 'Your profile is now fully verified. Congrats!',
            'e.suggestRide': 'Suggérer cet itinéraire',
            'e.suggestRide.title': 'Suggérer cet itinéraire?',
            'e.suggestRide.body':
                'En suggérant des itinéraires, vous nous aidez à trouver de nouveaux utilisateurs qui pourraient proposer les itinéraires que vous recherchez',
            'e.genderType.male': 'Homme',
            'e.genderType.female': 'Femme',
            'e.genderType.na': 'Je préfère ne pas révéler',
            'e.genderType.select': 'Renseigne ton genre',
            'e.profile.personalInfo.gender': 'Genre',
            'e.profile.personalInfo.recommended': 'conseillé',
            'e.profile.personalInfo.title': 'Informations personelles',
            'e.profile.personalInfo.firstName': 'Prénom',
            'e.profile.personalInfo.lastName': 'Nom',
            'e.profile.personalInfo.displayName': 'Pseudo',
            'e.profile.personalInfo.dob': 'Date de naissance',
            'e.profile.personalInfo.lang': "Langue del'appli",
            'e.profile.personalInfo.email': 'Email',
            'e.profile.personalInfo.mobile': 'Mobile',
            'e.profile.personalInfo.public': "Peut être vu par d'autres utilisateurs",
            'e.profile.personalInfo.private': "Ne sera pas partagé ou vu par d'autres utilisateurs",
            'e.profile.personalInfo.save': 'Sauvegarder',
            'e.profile.personalInfo.required': 'obligatoire',
            'e.profile.personalInfo.optional': 'optionnel',
            'e.offline.title': 'Pas de connection',
            'e.tooltip.searchResults':
                "Ces icônes sont des signes de grande confiance en un utilisateur. Ils signifient respectivement que sa carte d'identité et sa carte grise ont été vérifiées par nos équipes.",
            'e.tooltip.acceptUsers': "Pour accepter, refuser ou annuler une réservation, cliquez sur l'un des utilisateurs ci-dessous.",
            'e.tooltip.tripViewBookings': "C'est le nombre de passagers confirmés sur ce trajet jusqu'à présent.",
            'e.tooltip.compensation.short': 'A propos des coûts et frais',
            'e.tooltip.compensation': "L'usage de l'application Bala Benzine est gratuit. Contactez le conducteur pour savoir les coûts du trajet à partager.",
            'e.tooltip.noBooking': "Ce conducteur n'accepte plus de réservations pour ce trajet",
            'e.ride.acceptBookings': "J'accepte qu'on m'envoie des demandes de réservations sur ce trajet",
            'e.ride.ladiesOnly': 'Trajet réservé aux femmes',
            'e.ride.recurring': 'Ce trajet est récurrent',
            'e.view.ladiesOnly': 'Trajets reservés aux femmes',
            'e.view.archived': 'Trajets archivés',
            'e.view.punctual': 'Trajets ponctuels',
            'e.view.recurring': 'Trajets récurrent',
            'e.view.dayOfWeek': 'Trajets qui tombent les:',
            'e.view.verifiedId': 'Identité du conducteur verifiée',
            'e.view.verifiedCar': 'Immatriculation du véhicule verifiée',
            'e.filter.button': 'Filtrer & Trier',
            'e.filter.title': 'Filtrer par',
            'e.sort.title': 'Trier par',
            'e.filter.showRides': 'Afficher les trajets',

            'e.filter.ride.title': 'Filtrer par types de trajets',
            'e.filter.security.title': 'Filtrer par mesures de sécurité',
            'e.filter.admin.title': "Filtres d'administration",
            'e.filter.reset': 'Valeurs par défaut',

            'e.ride.doNotAcceptBookings': "Vous n'acceptez pas les réservations",
            'e.static.btn.close': 'Fermer',
            'e.tooltip.important': 'Pourquoi est-ce important ?',
            'e.tooltip.ladiesOnly.short': 'À propos des trajets réservés aux femmes',
            'e.ride.status.ladiesOnly': 'Trajet réservé aux femmes',
            'e.list.true': 'Oui',
            'e.list.false': 'Non',
            'e.list.null': 'Nul',
            'e.latestNews.title': 'Mises à jour',
            'e.days.every': 'Tous les'
        }
    },
    ar: {
        formatting: {
            locale: 'ar-LB',
            dateResults: 'ccc ، d LLL',
            dateTimeResults: 'ccc, LLL d, HH:mm'
        },
        rtl: true,
        lang: 'Arabic',
        lang_iso: 'ar',
        data: {
            'install.socialButton': `أرسل لي الرابط`,
            'install.socialTitle': 'رابط التثبيت',
            'install.socialTitleSent': 'تم إرسال الرابط',
            'install.socialBody': `احصل على رابط تثبيت  Bala Benzine عبر WhatsApp عن طريق إدخال رقم هاتفك المحمول  <p>تثبيت واستخدام بلا بنزين <strong><i>يتطلب رقم هاتف لبناني</i></strong></p>`,
            'install.socialDone': `افتح WhatsApp وانقر على الرابط الذي أرسلناه لك للتو.<p> 👋 سنراك بعد قليل</p>`,
            'install.introTitle': 'تثبيت التطبيق',
            'install.introBody': `
                                    بلا بنزين&nbsp;
                                    <strong>
                                        <u>
                                            <a href='https://balabenzine.com' target='_blank'>
                                                Bala Benzine
                                            </a>
                                        </u>
                                    </strong>&nbsp;
            هو تطبيق لمشاركة السيارات <span className='text-xl'>🚘+📱</span> 
            يضع السائقين والركاب على اتصال. قم بتثبيته الآن وشارك رحلاتك في جميع أنحاء لبنان!!
            `,
            'install.reqTitle': `متطلبات التثبيت`,
            'install.reqBody': `للتسجيل ، ستحتاج إلى هاتف برقم هاتف لبناني. لطلبات الصحافة الأجنبية لاختبار التطبيق ، يرجى التواصل على <strong><u> hello@balabenzine.app </u></strong>.`,
            'install.iPhoneTitle': `ايفون`,
            'install.iPhoneBody': `<ul class='text-vw-sm'>
                                        <li> متصفح سفاري الإصدار 14.5 (إصداره أبريل 2021) أو أحدث</li>
                                        <li>تأكد من أنك <strong> لست في وضع التصفح الخاص </ strong></li>
                                    </ul>`,
            'install.androidTitle': `أندرويد`,
            'install.androidBody': `<ul class='text-vw-sm'>
                                        <li>متصفح جوجل كروم</li>
                                        <li>تأكد من أنك <strong> لست في وضع التصفح الخاص </ strong></li>
                                    </ul>`,
            'install.btnInstall': `تثبيت بلا بنزين`,
            'install.btnInstalled': `التطبيق مثبت`,
            'menu.ladiesOnly': 'رحلات للسيدات فقط',
            'menu.pricing': 'الأسعار والتعرفة',
            'menu.admin.title': 'إدارة',
            'menu.admin.users': 'جميع المستخدمين',
            'menu.admin.verify': 'قيد التحقق',
            'menu.admin.verify.filter.pending': 'عرض الطلبات الجارية فقط',
            'menu.admin.verify.filter.women': 'المستخدمين النساء',
            'menu.admin.verify.filter.verifiedId': 'هوية مؤكدة',
            'menu.admin.verify.filter.verifiedProfile': 'ملف كامل مؤكد',
            'menu.admin.verify.filter.verifiedCar': 'رخصة سيارة مؤكدة',
            'menu.admin.verify.filter.blockUser': 'مستخدم محظور',
            'menu.notifications': 'إشعارات',
            'menu.rewards': 'مكافآت',
            'menu.ambassador': 'أصبح سفيراً لبلا بنزين',
            'menu.profile': 'الملف الشخصي',
            'menu.settings': 'إعدادات التطبيق',
            'menu.logout': 'تسجيل خروج',
            'menu.login': 'تسجيل الدخول',
            'menu.register': 'سجل',
            'menu.about': 'شو بلا بنزين؟',
            'menu.safety': 'إرشادات لسلامتكم',
            'menu.faq': 'سؤال جواب',
            'menu.help': 'مساعدة',
            'menu.help.support': 'حدا يساعدني',
            'menu.help.terms': 'الأحكام والشروط',
            'menu.help.privacy': 'سياسة الخصوصية',
            'menu.help.weNeedYou': 'ساعدونا',
            'menu.help.latestNews': 'آخر التحديثات',
            'menu.help.donate': 'تبــــــــــــــــــــرع',
            'nav.find': 'بحث',
            'nav.rides': 'رحلاتي',
            'nav.publish': 'إنشر',
            'nav.messages': 'دردش',
            'nav.news': 'إشعارات',
            'w.rides': { 1: 'رحلة', 2: 'رحلتين', 3: 'رحلات' },
            'e.nothingFound.verification.title': 'لا توجد عمليات تحقق جديدة',
            'e.nothingFound.verification.description': 'ليس لديك أي طلبات تحقق جديدة',
            'e.nothingFound.notification.title': 'لا إشعارات',
            'e.nothingFound.notification.description': 'ليس لديك أية اشعارات حتى الآن',
            'e.nothingFound.channel.title': 'No conversations',
            'e.nothingFound.channel.description': "You don't have any conversations yet. Find a ride and contact the driver to get things moving.",
            'e.nothingFound.channel.button': 'Try again',
            'e.nothingFound.chat.title': 'Ooops...',
            'e.nothingFound.chat.description': "We're having trouble loading your conversations. Try again in a bit",
            'e.nothingFound.chat.button': 'Try again',
            'e.nothingFound.results.title': 'لم يتم العثور على نتائج',
            'e.nothingFound.results.description': 'حاول توسيع معايير البحث الخاصة بك. جرب حيًا بدلاً من شارع / مكان أو مدينة بدلاً من حي.',
            'e.nothingFound.results.button': 'حدد بحثك',
            'e.nothingFound.booking.title': 'ليس لديك حجوزات',
            'e.nothingFound.booking.description': 'ليس لديك رحلات قادمة محجوزة. دعنا نساعدك في العثور على واحدة',
            'e.nothingFound.booking.button': 'ابحث عن رحلة',
            'e.nothingFound.offered.title': 'ليس لديك رحلات منشورة',
            'e.nothingFound.offered.description': 'كسائق ، لم تنشر أي رحلات بعد. دعنا نساعدك في نشر واحد',
            'e.nothingFound.offered.button': 'انشر رحلة',
            'e.nothingFound.offered.archived.title': 'الأرشيف فارغ',
            'e.nothingFound.offered.archived.description': 'كسائق ، سيحتفظ هذا الأرشيف بجميع الرحلات المكتملة أو التي تم نقلها يدويًا هنا.',
            'e.nothingFound.booking.archived.title': 'الأرشيف فارغ',
            'e.nothingFound.booking.archived.description':
                'بصفتك راكبًا ، سيحتفظ هذا الأرشيف بحجوزات جميع الرحلات التي كانت في الماضي (تم تأكيدها أو إلغاؤها) أو التي تم نقلها يدويًا هنا.',
            'e.results.allTrips': 'كل الرحلات',
            'e.results.allTripsFrom': 'كل الرحلات القادمة من',
            'e.results.allTripsTo': 'كل الرحلات المتجهة إلى',
            'e.anywhere': 'شي مطرح',
            'e.results.found.a': 'تم العثور على ',
            'e.results.found.b': 'مطابقة لبحثك ',
            'e.results.none': 'لم نتمكن من العثور على أي رحلات تطابق بحثك',
            'e.resultsOrderedByDay': 'النتائج مرتبة ومصنفة وفقًا لإعدادات التصفية والفرز ',
            'e.results.searching': '...شكراً لانتظارك',
            'e.somethingWrong': 'حدث خطأ ما',
            'e.issuesLoadingPage': 'نواجه مشاكل في تحميل هذه الصفحة',
            'e.tryAgain': 'يرجى إعادة المحاولة',
            'e.refreshThePage': 'إعادة تحميل الصفحة',
            'e.loadMore': 'تحميل المزيد من النتائج',
            'e.noPassengersOnRide': 'ليس لديك حجوزات لهذه الرحلة',
            'e.noPassengersOnRide.past': 'لقد أكملت هذه الرحلة من دون ركاب',
            'e.ratingNA': 'غير متوفر',
            'e.verify.title': 'تحقق من ملف التعريف الخاص بك',
            'e.verify.subtitle': 'يتمتع الملف الشخصي الذي تم التحقق منه بفرصة أكبر بمقدار 5 أضعاف في أن يتصل به أحد الركاب.',
            'e.verified.profile': 'تم التأكد من ملف هذا الشخص',
            'e.verified.profile.me': 'تم التأكد من ملفك الشخصي',

            'e.verified.id': 'تم التحقق من الهوية',
            'e.verified.email': 'تم التحقق من البريد الإلكتروني',
            'e.verified.mobile': 'تم التحقق من الهاتف',
            'e.verified.photo': 'صورة الملف الشخصي محدثة',
            'e.verified.plate': 'تم التحقق من رخصة آلية المركبة',
            'e.verify.photo': 'أضف صورة الملف الشخصي',
            'e.verify.photo.title': 'صورة الملف الشخصي',
            'e.verify.photo.body.new':
                'قم بتحميل صورة حديثة تظهر بوضوح وجهك وتشبهك في الحياة الواقعية. والغرض من ذلك هو السماح للركاب والسائقين بالتعرف على بعضهم البعض والبقاء محميين.',
            'e.verify.photo': 'أضف صورة الملف الشخصي',
            'e.verify.photo.select': 'اختر الصور',
            'e.verify.photo.clear': 'حذف الصورة',
            'e.verify.photo.update': 'تحديث الصورة وإغلاق النافذة',
            'e.verify.id': 'التحقق من الهوية',
            'e.verify.email': 'التحقق من البريد الإلكتروني',
            'e.verify.mobile': 'تحقق من الهاتف المحمول',
            'e.verify.plate': 'تحقق من رخصة آلية المركبة',
            'e.verify.cancel': 'إلغاء التحقق',
            'e.verify.submit': 'إرسال',
            'e.verify.approve': 'الموافقة على التحقق',
            'e.verify.reject': 'رفض التحقق',
            'e.verify.pending': 'قيد الانتظار. اضغط للعرض',
            'e.verify.addImages': 'تحميل الصور',
            'e.verify.clearImages': 'حذف الصور',
            'e.verify.id.title': 'التحقق من الهوية',
            'e.verify.id.body.new':
                'التقط صورة شخصية مع إظهار هويتك (ألهوية أو رخصة القيادة) كما في الصورة أدناه ، ثم استخدم زر التحميل لإضافتها وإرسالها للتحقق',
            'e.verify.id.body.new.gender':
                'اختر جنسك ثم التقط صورة شخصية مع إظهار هويتك (ألهوية أو رخصة القيادة) كما في الصورة أدناه ، ثم استخدم زر التحميل لإضافتها وإرسالها للتحقق',
            'e.verify.id.body.pending': 'لديك إثبات هوية قيد الانتظار. إذا كنت ترغب في إلغاء عملية التحقق ، انقر فوق "إلغاء الأمر"',
            'e.verify.plate.title': 'التحقق من رخصة آلية مركبة',
            'e.verify.plate.body.new':
                'قم بتحميل مستندات تسجيل سيارتك من الأمام والخلف. إذا لم تكن سيارتك مسجلة باسمك ، فقم بتحميل أي مستندات إضافية تمنحك الحق في قيادة تلك السيارة.',
            'e.verify.plate.body.pending': 'لديك إثبات رخصة آلية قيد الانتظار. إذا كنت ترغب في إلغاء عملية التحقق ، انقر فوق "إلغاء الأمر"',
            'e.user.memberSince': 'عضو منذ',
            'e.user.publishedRides.many': 'عدد الرحلات المنشورة',
            'e.user.publishedRides.one': 'عدد الرحلات المنشورة',
            'e.rate.driver.title': 'كيف كانت رحلتك مع',
            'e.rate.driver.done': 'تقييمك لهذه الرحلة',
            'e.rate': 'قيم',
            'e.scrollToTop': 'إلى أعلى الصفحة',
            'e.reviews.1': 'رأي',
            'e.reviews.2': 'رأيان',
            'e.publishedRides': 'عدد الرحلات المنشورة:',
            'e.reviews.3': 'آراء',
            'e.pageSettings': 'Page Settings',
            'e.pageSettings.language': 'لغة التطبيق',
            'e.pageSettings.time': 'اعدادات الوقت',
            'e.pageSettings.time.time24': '24H',
            'e.pageSettings.time.timeAMPM': 'AM/PM',
            'e.pageSettings.tooltips.reset': 'إعادة تعيين تلميحات',
            'e.pageSettings.reset': 'إعادة ضبط جميع إعدادات التطبيق',
            'e.pageSettings.motion': 'إعدادات الحركة',
            'e.pageSettings.motion.0': 'حركة كاملة',
            'e.pageSettings.motion.5': 'حركة انتقال للدخول',
            'e.pageSettings.motion.10': 'بدون حركة',
            'e.youCanChangeLater': 'يمكنك تعديل هذا لاحقا',
            'e.booking.confirmedBookings': 'الحجوزات المؤكدة',
            'e.booking.bookings': 'الحجوزات',
            400: 'Bad Request',
            401: 'Unauthorized Request',
            403: 'Forbidden',
            404: 'Not Found',
            405: 'Method Not Allowed',
            406: 'Not Acceptable',
            500: 'Internal Server Error',
            502: 'Bad Gateway',
            'e.later': 'ربما لاحقاً',
            'e.notNow': 'ربما لاحقاً',
            'e.booking.status.archive': 'تحويل نحو الأرشيف',
            'e.booking.status.archive.headline': 'تحويل نحو الأرشيف؟',
            'e.booking.status.delete': 'حذف',
            'e.booking.status.delete.headline': 'هل تريد حذف هذا الحجز؟',
            'e.ride.status.archive': 'تحويل نحو الأرشيف',
            'e.ride.status.archive.headline': 'تحويل نحو الأرشيف؟',
            'e.ride.status.delete': 'حذف هذه الرحلة',
            'e.ride.status.delete.headline': 'هل تريد حذف هذه الرحلة؟',
            'e.ride.status.setToPublish': 'إنشر الرحلة',
            'e.ride.status.setToPublish.headline': 'هل تريد أن تنشر هذه الرحلة الآن؟',
            'e.ride.status.setToDraft': 'إلغاء النشر',
            'e.ride.status.setToDraft.headline': 'بعد إلغاء النشر ، لا يمكن لأحد رؤية رحلتك',
            'e.exceededRequest': 'لقد تخطيت الحد الأقصى',
            'e.confirmRequest': 'أرسل طلب حجز',
            'e.cancelRequest': 'إلغاء الطلب',
            'e.cancelRequest.headline': 'هل تريد إلغاء طلب الحجز؟',
            'e.cancelBooking': 'إلغاء الحجز',
            'e.cancelBooking.headline': 'هل تريد إلغاء الحجز؟',
            'e.declineRequest': 'رفض الطلب',
            'e.approveRequest': 'موافقة الطلب',
            'e.sendBookingRequest': 'الحجوزات',
            'e.sendBookingRequest.headline': 'هل تريد ارسال طلب حجز؟',
            'e.sendBookingRequest.exceed.headline': 'لقد تخطيت الحد الأقصى للطلب على هذه الرحلة',
            'e.iUnderstand': 'أوكي ، مفهوم',
            'e.yesSure': 'نعم ، بالتأكيد!',
            'e.keepIt.m': 'إحتفظ به',
            'e.keepIt.f': 'إحتفظ بها',
            'e.cancel.m': 'إلغاء',
            'e.cancel.f': 'إلغاء',
            'e.contactDriver': 'تواصل مع السائق',
            'e.driver': 'السائق',
            'e.contactPassenger': 'تواصل مع الراكب',
            'e.contactUser': 'تواصل',
            'e.passengers': 'الركاب',
            'e.viewBookingRequest': 'عرض طلب الحجز',
            'e.cancelRide': 'إلغاء الرحلة',
            'e.cancelRide.headline': 'سيؤدي إلغاء الرحلة إلى إلغاء جميع الحجوزات المرتبطة بها',
            'rides.tab.booked': 'كراكب',
            'rides.tab.published': 'كسائق',
            'err.generic': 'A generic error occurred',
            'err.auth.NoAuthHeader': 'No Auth header found',
            'err.ride.notFound': 'Ride not found',
            'err.booking.notFound': 'No booking found',
            'err.booking.selfbooking': "You're trying to your own trip. Not Allowed",
            'err.booking.inPast': "You're trying to book a past trip. Not allowed",
            'err.booking.existsForUser': 'A Booking on this trip exists for current user',
            'err.booking.notEnoughSeats': 'Not enough seats available',
            'e.distance.approx': 'حوالي ',
            'e.distance.km': ' كم ',
            'e.distance.meters': ' m ',
            'e.distanceFrom.start': 'من عنوان الإنطلاق ',
            'e.distanceFrom.end': 'من عنوان الوصول ',
            'trip.status.CANCELED': 'لقد الغيت هذه الرحلة',
            'publishing.status.DRAFT': 'لم يتم نشر هذه الرحلة بعد',
            'publishing.status.CANCELED': 'لقد الغيت هذه الرحلة',
            'publishing.status.PUBLISHED': 'لقد تم نشر هذه الرحلة',
            'publishing.status.DRAFT.archive': 'لم يتم نشر هذه الرحلة بعد',
            'publishing.status.CANCELED.archive': 'رحلة ملغاة',
            'publishing.status.PUBLISHED.archive': 'انتهت هذه الرحلة',
            'trip.request.EXCEEDED_REQUEST_ATTEMPTS': 'لقد تخطيت الحد الأقصى للطلب على هذه الرحلة',
            'trip.request.PENDING': 'لديك طلب في قيد الإنتظار',
            'trip.request.APPROVED_BY_DRIVER': 'لديك حجز مؤكد لهذه الرحلة',
            'trip.request.APPROVED_BY_ADMIN': 'لديك حجز مؤكد لهذه الرحلة',
            'booking.request.COMPLETE': 'انتهت هذه الرحلة',
            'booking.request.PENDING': 'هذا الطلب في قيد الإنتظار',
            'booking.request.APPROVED_BY_ADMIN': 'هذا الحجز مؤكد',
            'booking.request.APPROVED_BY_DRIVER': 'هذا الحجز مؤكد من قبل السائق',
            'booking.request.CANCELED_BY_DRIVER': 'هذا الحجز ملغى من قبل السائق',
            'booking.request.CANCELED_BY_PASSENGER': 'هذا الحجز ملغى من قبلك',
            'booking.request.CANCELED_BY_ADMIN': 'تم إلغاء رحلتك من قبل خدمة العملاء',
            'booking.request.DECLINED_BY_DRIVER': 'تم رفض طلبك',
            'booking.request.DECLINED_BY_ADMIN': 'تم رفض طلبك',
            'booking.request.DECLINED_BY_PASSENGER': 'هذا الطلب ملغى من قبلك',
            'e.location.placeholder.from': 'ساحة الشهداء',
            'e.location.placeholder.to': 'المعرض',
            'e.page.search.find': 'ابحــــــــــــث',
            'e.page.search.all': 'تصفح جميع الرحلات',
            'e.page.search.headline': 'إلى أين تود الذهاب؟',
            'e.page.search.action': 'البحث عن الرحلات',
            'e.page.search.from': 'نقطة الإنطلاق',
            'e.page.search.from.headline': 'نقطة الإنطلاق',
            'e.page.search.to': 'نقطة الوصول',
            'e.page.search.to.headline': 'نقطة الوصول',
            'e.page.search.date': 'متى',
            'e.page.search.date.headline': 'متى تود الذهاب؟',
            'e.page.search.seats': 'عدد المقاعد',
            'e.page.search.seats.headline': 'عدد المقاعد',
            'e.page.action.discardChanges': 'تجاهل التغييرات',
            'e.page.publish.action.draft': 'حفظ بدون نشر',
            'e.page.publish.action': 'إنشر الرحلة',
            'e.page.publish.action.setTime': 'تأكيد الوقت',
            'e.page.publish.action.clearTime': 'مسح الوقت',
            'e.page.publish.action.setDays': 'تأكيد الأيام',
            'e.page.publish.action.clearDays': 'مسح الأيام',
            'e.page.publish.action.confirm': 'تأكيد',
            'e.page.publish.action.clear': 'مسح الحقول',
            'e.page.publish.from': 'نقطة الإنطلاق',
            'e.page.publish.from.headline': 'من أين تريد الإنطلاق؟',
            'e.page.publish.from.precise': 'من أي نقطة تريد أن تأخذ الركاب؟',
            'e.page.publish.to': 'نقطة الوصول',
            'e.page.publish.to.headline': 'إلى أين تود الذهاب؟',
            'e.page.publish.to.precise': 'أين تريد إنزال الركاب؟',
            'e.page.publish.date': 'متى',
            'e.page.publish.days': 'الأيام',
            'e.page.publish.date.headline': 'متى تود الذهاب؟',
            'e.page.publish.time': 'الوقت',
            'e.page.publish.time.headline': 'في أي وقت ستلتقي بالركاب؟',
            'e.page.publish.seats.middle.question': 'حافظ على المقعد الأوسط فارغًا حتى يشعر الراكب بالراحة؟', // offer-seats/comfort
            'e.page.publish.seats.middle.yes': 'نعم بالتأكيد!',
            'e.page.publish.seats.middle.no': "No, I'll squeeze in 3",
            'e.page.publish.seats': 'عدد المقاعد', // offer-seats/seats
            'e.page.publish.seats.headline': 'كم عدد الركاب الذين يمكنك اصطحابهم؟', // offer-seats/seats
            'e.page.publish.description': 'أي تعليق', // offer-seats/description
            'e.page.publish.description.headline': 'أي شيء تريد إضافته حول رحلتك؟', // offer-seats/description
            'e.page.publish.description.placeholder': "Please be at pickup place on time. I can't be late for work",
            'e.page.publish.booking.question': 'هل يمكن للمسافرين الحجز على الفور؟', // offer-seats/approval
            'e.page.publish.booking.question.yes': 'نعم بالتأكيد!',
            'e.page.publish.booking.question.no': 'لا ، سأرد على كل طلب بنفسي',
            'e.page.publish.pricing.question': 'هذا هو السعر الذي نوصي به لكل مقعد. ما رأيك؟', // offer-seats/price-recommendation
            'e.page.publish.pricing.question.yes': 'نعم ممتاز!',
            'e.page.publish.pricing.question.no': 'لا ، أنا بحاجة لتعديل هذا المبلغ',
            'e.page.publish.return.question': 'هل ستعود ايضا؟ يمكنك نشر رحلة عودتك الآن!', // offer-seats/return-trip
            'e.page.publish.return.question.yes': 'نعم بالتأكيد!',
            'e.page.publish.return.question.no': 'سأقوم بنشر رحلة عودتي لاحقًا',
            'e.page.publish.return.date': 'متى تريد العودة؟',
            'e.useCurrentLocation': 'استخدم موقعي الحالي',

            'e.offered.section.subtitle': 'رحلاتك القادمة كسائق. جميع الرحلات التي تنشرها ستكون هنا.',
            'e.offered.archived.section.subtitle': 'كل رحلاتك الماضية أو المؤرشفة كسائق تجدها هنا.',
            'e.booked.section.subtitle': 'رحلاتك القادمة كراكب. كل الحجوزات أو الطلبات تجدها هنا.',
            'e.booked.archived.section.subtitle': 'كل الحجوزات أو الطلبات الماضية أو المؤرشفة كراكب تجدها هنا.',
            'e.offered.archived.view': 'عرض أرشيف الرحلات',
            'e.offered.archived.headline': 'أرشيف الرحلات',
            'e.booked.archived.view': ' عرض أرشيف الحجوزات',
            'e.booked.archived.headline': 'أرشيف الحجوزات',
            'e.page.publish.status.draft.headline': 'تم حفظ مسودة رحلتك. يمكنك مشاهدتها في رحلاتي ثم تحت فئة السائق',
            'e.page.publish.status.draft.button': 'تمام',
            'e.page.publish.status.success.headline': 'رحلتك تم نشرها على النت',
            'e.page.publish.status.success.button': 'تمام',
            'e.page.publish.status.forbidden.headline': 'ليس لديك الحق في تقديم هذا الطلب.',
            'e.page.publish.status.forbidden.button': 'حسناً',
            'e.page.publish.status.duplicate.headline': 'لديك بالفعل رحلة في نفس التاريخ / الوقت تقريبًا. يجب أن تكون المسافة بين رحلاتك 30 دقيقة على الأقل.',
            'e.page.publish.status.duplicate.button': 'حسناً',
            'e.page.publish.status.server.headline': 'مشكلة تقنية. الرجاء معاودة المحاولة في وقت لاحق',
            'e.page.publish.status.server.button': 'اريد ان احاول الان',
            'e.publish.error.dateTimeInPast': 'التاريخ / الوقت المختار يقع في الماضي',
            'e.page.publish.poster.title': 'هل أنت سائق؟',
            'e.page.publish.poster.subtitle1': 'انشر رحلة',
            'e.page.publish.poster.subtitle2': 'وانتظر الركاب',
            'e.page.search.poster.title': 'هل أنت راكب؟',
            'e.page.search.poster.subtitle1': 'ابحث عن رحلة',
            'e.page.search.poster.subtitle2': 'تواصل مع سائق',
            'e.loginFlow.start.title': 'دعونا نبدأ هنا',
            'e.loginFlow.start.subtitle': 'ما هو رقم جوالك؟',
            'e.loginFlow.start.note': 'سنرسل لك رمز التحقق إلى هذا الرقم عبر رسالة قصيرة',
            'e.loginFlow.start.button': 'فلنكمل',
            'e.loginFlow.info.title': 'مرحباً',
            'e.loginFlow.info.subtitle': 'لضمان سلامة جميع المستخدمين ، نحتاج إلى مزيد من المعلومات',
            'e.loginFlow.info.first.placeholder': 'الاسم الأول',
            'e.loginFlow.info.last.placeholder': 'اسم العائلة',
            'e.loginFlow.info.email.placeholder': 'بريد الالكتروني',
            'e.loginFlow.info.button': 'فلنكمل',
            'e.loginFlow.blocked.title': 'ممنوع',
            'e.loginFlow.blocked.subtitle': 'الحساب المرتبط بهذا الرقم محظور. حاول استخدام رقم مختلف.',
            'Auth.form.error.blocked': 'الحساب المرتبط بهذا الرقم محظور. حاول استخدام رقم مختلف.',
            'Auth.form.error.maxAttempts': 'إما أنك تجري محاولات طلب كثيرة جدًا أو أنك تجاوزت الحد الأقصى لكل يوم',
            'Auth.form.error.vonage': 'إما أنك تجري محاولات طلب كثيرة جدًا أو أنك تجاوزت الحد الأقصى لكل يوم',
            'Auth.otp.missing.fields.registration': 'Missing Registration fields: Email, first , last',
            'e.loginFlow.blocked.button': 'دعنا نحاول مرة أخرى',
            'e.loginFlow.verify.title': 'التحقق من الهاتف',
            'e.loginFlow.verify.subtitle': 'الرجاء إدخال رمز التحقق المكون من 4 أرقام المرسل إلى',
            'e.loginFlow.verify.note': 'لم تستلم كلمة المرور؟',
            'e.loginFlow.verify.note2': 'أعد إرسال الرمز',
            'Auth.otp.verify.error.status.1': 'مخنوق. محاولة إرسال عدد كبير جدًا من الرسائل في الثانية (1)',
            'Auth.otp.verify.error.status.16': 'الكود المُدرج لا يتطابق مع القيمة المتوقعة (16)',
            'Auth.otp.verify.error.status.5': 'خطأ داخلي في Cloud Communications Platform (5).',
            'Auth.otp.verify.error.status.6': 'لا يمكن توجيه الطلب (6).',
            'Auth.otp.verify.error.status.17': 'تم تقديم رمز خاطئ عدة مرات. الرجاء الإنتظار 5 دقائق قبل إعادة المحاولة (17)',
            'Auth.otp.request.error.status.1': 'مخنوق. محاولة إرسال عدد كبير جدًا من الرسائل في الثانية (1)',
            'Auth.otp.request.error.status.5': 'خطأ داخلي في Cloud Communications Platform (5).',
            'Auth.otp.request.error.status.6': 'لا يمكن توجيه الطلب (6).',
            'Auth.otp.request.error.status.7': 'الرقم الذي تحاول التحقق منه مدرج في القائمة السوداء للتحقق. (7).',
            'Auth.otp.request.error.status.8': 'تم تعطيل حساب API (8).',
            'Auth.otp.request.error.status.9': 'تم تجاوز حصة API (9).',
            'Auth.otp.request.error.status.10': 'لا يسمح بالتحقق المتزامن لنفس الرقم (10).',
            'Auth.otp.request.error.status.15': 'رقم الوجهة ليس في شبكة مدعومة (15).',
            'Auth.otp.request.error.status.29': 'وجهة غير مسموح بها (29).',
            'e.loginFlow.finished.title': 'حسابك جاهز',
            'e.loginFlow.finished.subtitle': 'هل أنت جاهز لبدء مشاركة الرحلات؟',
            'e.loginFlow.finished.button': 'أنا جاهز',
            'e.tip': 'تلميح',
            'e.language.title': 'لغة التطبيق',
            'e.language': 'عربي',
            'e.languages.en': 'إنجليزي',
            'e.languages.fr': 'فرنسي',
            'e.languages.ar': 'عربي',
            'e.reducedMotion.title': 'إعدادات الحركة',
            'e.reducedMotion.option1': 'بدون حركة',
            'e.reducedMotion.option2': 'حركة متوسطة',
            'e.reducedMotion.option3': 'حركة كاملة',
            'e.timeFormat.title': 'اعدادات الوقت',
            'e.timeFormat.option1': '24 ساعة',
            'e.timeFormat.option2': 'AM/PM',
            'e.sendMessage': 'ارسل رسالة',
            'e.bug.submitBug': 'إبلاغ عن عطل',
            'e.bug.action': 'ماذا كنت تحاول أن تفعل؟',
            'e.bug.description': 'صف ما حدث',
            'e.bug.submit': 'أرسل',
            'e.chat.channel.welcome.label': 'مساحة آمنة للدردشة',
            'e.chat.channel.welcome.text':
                'مرحبا بكم في محادثات بالا بنزين. إنها مساحة آمنة لك للدردشة مع سائق أو راكب دون الكشف عن معلوماتك الشخصية. اسأل عن توفر المقاعد ، ورسوم البنزين ، والقواعد ، وما إلى ذلك ... تتيح لك القائمة المنقطة في الأعلى الوصول إلى أدوات إضافية للحفاظ على سلامتك.',
            'e.chat.deletedMessage': 'تم حذف هذه الرسالة',
            'e.chat.blockUser': 'حظر المستخدم',
            'e.chat.blockUser.title': 'هل أنت متأكد أنك تريد حظر هذا المستخدم؟',
            'e.chat.unblockUser.title': 'هل أنت متأكد أنك تريد إلغاء حظر هذا المستخدم؟',
            'e.chat.unblockUser': 'إلغاء حظر المستخدم',
            'e.chat.userProfile': 'معلومات عن هذا المستخدم',
            'e.chat.reportUser': 'أبلغ عن مستخدم',
            'e.chat.reportUser.title': 'ماذا تريد أن تبلغ؟',
            'e.chat.reportUser.action': 'أبلغ عن مستخدم',
            'report.dui': 'القيادة تحت التأثير',
            'report.verbalHarassment': 'تحرش لفظي',
            'report.sexualHarassment': 'تحرش جنسي',
            'report.dirtyVehicle': 'سيارة قذرة',
            'report.dangerousDriving': 'قيادة خطرة',
            'report.rude': 'وقاحة',
            'report.destructionProperty': 'تدمير الممتلكات',
            'e.chat.whatsAppLink': 'مشاركة رابط WhatsApp',
            'e.chat.whatsAppLink.title': 'شارك رابطًا لفتح محادثة جديدة في WhatsApp. من خلال القيام بذلك ، فإنك تكشف عن رقمك للمستخدم',
            'e.chat.whatsAppLink.action': 'مشاركة رابط WhatsApp',
            'e.chat.clear': 'امسح هذه المحادثة',
            'e.chat.clear.title': 'هل أنت متأكد أنك تريد حذف هذه المحادثة؟',
            'e.chat.clear.action': 'امسح هذه المحادثة',
            'e.notification.admin.plate.title': 'رخصة آلية مركبة',
            'e.notification.admin.id.title': 'التحقق من الهوية',
            'e.notification.admin.mobile.title': 'التحقق من الجوال',
            'e.notification.admin.email.title': 'البريد الالكتروني',
            'e.notification.action.clear': 'إزالة الاشعارات',
            'e.notification.booking.request.sent.title': 'طلب جديد',
            'e.notification.booking.request.sent.body': ' أرسل(ت) إليك طلب حجز لرحلتك الجارية ',
            'e.notification.booking.request.canceled.title': 'إلغاء',
            'e.notification.booking.request.canceled.body': ' ألغى(ت) طلب الرحلة  ',
            'e.notification.booking.canceledByDriver.title': 'إلغاء',
            'e.notification.booking.canceledByDriver.body': ' ألغى(ت) طلب الرحلة  ',
            'e.notification.booking.canceledByAdmin.title': 'إلغاء',
            'e.notification.booking.canceledByAdmin.body': 'بلا بنزين ألغى طلب الرحلة  ',
            'e.notification.booking.declinedByDriver.title': 'رفض',
            'e.notification.booking.declinedByDriver.body': ' رفض(ت) طلب الرحلة  ',
            'e.notification.booking.declinedByAdmin.title': 'رفض',
            'e.notification.booking.declinedByAdmin.body': ' بلا بنزين رفض طلب الرحلة  ',
            'e.notification.booking.approvedByDriver.title': 'تأكيد الحجز',
            'e.notification.booking.approvedByDriver.body': ' قبل(ت) طلب الرحلة  ',
            'e.notification.booking.approvedByAdmin.title': 'تأكيد الحجز',
            'e.notification.booking.approvedByAdmin.body': 'بلا بنزين قبل طلب الرحلة  ',
            'e.notification.booking.from': ' من ',
            'e.notification.booking.to': ' إلى ',
            'com.tab.notifications': 'إشعارات',
            'com.tab.messages': 'دردشات',
            'e.notification.messageSent.title': 'إرسال رسالة جديدة',
            'e.notification.messageSent.body': 'لقد أرسلت رسائل. اضغط هنا لقراءة المحادثة.',
            'e.notification.messageReceived.title': 'استلام رسالة جديدة',
            'e.notification.messageReceived.body': ' أرسل(ت) لك رسائل. اضغط هنا لقراءة المحادثة .',
            'e.notification.reward.title': 'مكافأة',
            'e.notification.reward.points': 'نقطة',
            'e.notification.reward.body': 'ل:',
            'e.notification.reward.signup.title': 'تسجيل على منصة بلا بنزين',
            'e.notification.reward.signup.body': '',
            'e.notification.reward.completedRidePassenger.title': 'أخذ رحلة كراكب',
            'e.notification.reward.completedRidePassenger.body': '',
            'e.notification.reward.completedRideDriverMain.title': 'إكمال رحلة كسائق',
            'e.notification.reward.completedRideDriverMain.body': '',
            'e.notification.reward.completedRideDriverSeat.title': 'Successfully dropping a passenger',
            'e.notification.reward.completedRideDriverSeat.body': '',
            'e.notification.reward.reviewDriver.title': 'إعطاء رأي عن رحلة',
            'e.notification.reward.reviewDriver.body': '',
            'e.notification.reward.referral.title': 'إحالة مستخدم جديد',
            'e.notification.reward.referral.body': '',
            'e.notification.verification.id.pending.title': 'ID Verification',
            'e.notification.verification.id.pending.body': 'Your ID verification request is pending.',
            'e.notification.verification.id.rejected.title': 'Identity Rejected',
            'e.notification.verification.id.rejected.body': 'Your ID request has been rejected.',
            'e.notification.verification.id.canceled.title': 'Identity Canceled',
            'e.notification.verification.id.canceled.body': 'You canceled an ID request.',
            'e.notification.verification.id.approved.title': 'Identity Verified',
            'e.notification.verification.id.approved.body': 'Your Identity is now verified.',
            'e.notification.verification.car.pending.title': 'Car Verification',
            'e.notification.verification.car.pending.body': 'Your Car Registration verification request is pending.',
            'e.notification.verification.car.rejected.title': 'Registration Rejected',
            'e.notification.verification.car.rejected.body': 'Your Car Registration request has been rejected.',
            'e.notification.verification.car.canceled.title': 'Registration Canceled',
            'e.notification.verification.car.canceled.body': 'Your canceled a Car Registration request.',
            'e.notification.verification.car.approved.title': 'Registration Verified',
            'e.notification.verification.car.approved.body': 'Your Car Registration is now verified.',
            'e.notification.verification.profile.approved.title': 'Profile Verified',
            'e.notification.verification.profile.approved.body': 'Your profile is now fully verified. Congrats!',
            'e.suggestRide': 'اقترح خط سير',
            'e.suggestRide.title': 'اقترح خط سير الرحلة هذا؟',
            'e.suggestRide.body': 'من خلال اقتراح مسارات الرحلة ، فإنك تساعدنا في العثور على مستخدمين جدد قد يقترحون مسارات الرحلة التي تبحث عنها',
            'e.genderType.male': 'رجل',
            'e.genderType.female': 'امراة',
            'e.genderType.na': 'أفضل عدم الكشف',
            'e.genderType.select': 'اختر جنسك',
            'e.profile.personalInfo.gender': 'جنس',
            'e.profile.personalInfo.recommended': 'ينصح به',
            'e.profile.personalInfo.title': 'معلومات شخصية',
            'e.profile.personalInfo.firstName': 'الاسم',
            'e.profile.personalInfo.lastName': 'اسم العائلة',
            'e.profile.personalInfo.displayName': 'لقب',
            'e.profile.personalInfo.dob': 'تاريخ الميلاد',
            'e.profile.personalInfo.lang': 'لغة التطبيق',
            'e.profile.personalInfo.email': 'البريد الإلكتروني',
            'e.profile.personalInfo.mobile': 'التلفون الخليوي',
            'e.profile.personalInfo.public': 'المعلومات التي يمكن أن يراها المستخدمون الآخرون',
            'e.profile.personalInfo.private': 'لن يتم مشاركتها أو رؤيتها مع مستخدمين آخرين',
            'e.profile.personalInfo.save': 'احفظ التغييرات',
            'e.profile.personalInfo.required': 'إلزامي',
            'e.profile.personalInfo.optional': 'اختياري',
            'e.offline.title': 'لا يوجد اتصال بالشبكة',
            'e.tooltip.searchResults':
                'هذه الرموز هي علامات على ثقة عالية في المستخدم. والمعنى منهما أنه تم التحقق من أوراق ألهوية وتسجيل السيارة من قبل فرقنا.',
            'e.tooltip.acceptUsers': 'لقبول حجز أو رفضه أو إلغائه ، انقر فوق أحد المستخدمين أدناه',
            'e.tooltip.tripViewBookings': 'هذا هو عدد الركاب المؤكدين على هذه الرحلة حتى الآن',
            'e.tooltip.compensation.short': 'معلومات مهمة عن الأسعار والتعرفة',
            'e.tooltip.compensation': 'استخدام بلا بنزين مجاني. لا تنس دائمًا الاتصال بالسائق لسؤاله عما إذا كانت هناك أي تكاليف للركوب معهم.',
            'e.tooltip.noBooking': 'هذا السائق لا يقبل أي حجوزات أخرى لهذه الرحلة',
            'e.ride.acceptBookings': 'يمكن للركاب أن يرسلوا لي طلبات حجز لهذه الرحلة',
            'e.ride.ladiesOnly': 'رحلة للسيدات فقط',
            'e.ride.recurring': 'هذه رحلة متكررة على عدة أيام',
            'e.view.ladiesOnly': 'الرحلات المخصصة للسيدات',
            'e.view.archived': 'الرحلات المؤرشفة',
            'e.view.punctual': 'رحلات لمرة واحدة (غير متكررة)',
            'e.view.recurring': 'الرحلات المتكررة',
            'e.view.dayOfWeek': 'الرحلات المطابقة لأيام الأسبوع المختارة',
            'e.view.verifiedId': 'هوية السائق مؤكدة',
            'e.view.verifiedCar': 'أوراق تسجيل السيارة مؤكدة',
            'e.filter.button': 'فلتــــــــــــــر و إفـــــــــــــــرز',
            'e.filter.title': 'فلتر الرحلات حسب',
            'e.sort.title': 'الفرز حسب',
            'e.filter.showRides': 'عرض الرحلات',

            'e.filter.ride.title': 'فلتر حسب الرحلات',
            'e.filter.security.title': 'فلاتر للسلامة',
            'e.filter.admin.title': 'فلاتر الإدارة',
            'e.filter.reset': 'إعادة الفلاتر إلى الترتيب الأولي',

            'e.ride.doNotAcceptBookings': 'أنت لا تقبل الحجوزات',
            'e.static.btn.close': 'أغلق النافذة',
            'e.tooltip.important': 'لماذا هذا مهم؟',
            'e.tooltip.ladiesOnly.short': 'عن الرحلات المخصصة للسيدات فقط',
            'e.ride.status.ladiesOnly': 'رحلة مخصصة للسيدات',
            'e.list.true': 'نعم',
            'e.list.false': 'كلا',
            'e.list.null': 'لا اجابة',
            'e.latestNews.title': 'آخر التحديثات',
            'e.days.every': 'كل'
        }
    }
};
