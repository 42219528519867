import { defaultSize, defaultStroke } from './defaults';
const IconUnpublish = ({ size, className }) => {
    return (
        <>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size || defaultSize} height={size || defaultSize}>
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path
                            id='Union_1'
                            data-name='Union 1'
                            d='M-352.111,5.3l-2.475,2.475L-356,6.364l2.475-2.475L-356,1.414-354.586,0l2.475,2.475L-349.636,0l1.414,1.414L-350.7,3.889l2.475,2.475-1.414,1.414Z'
                            transform='translate(364 9)'
                        />
                        <path
                            id='Path_359'
                            data-name='Path 359'
                            d='M1,14.5A6.5,6.5,0,0,1,4.064,8.981a8,8,0,0,1,15.872,0,6.5,6.5,0,0,1-2.936,12L7,21A6.531,6.531,0,0,1,1,14.5Zm15.848,4.487a4.5,4.5,0,0,0,2.03-8.309l-.807-.5-.12-.942a6,6,0,0,0-11.9,0l-.12.942-.8.5a4.5,4.5,0,0,0,2.029,8.309L7.325,19h9.35l.173-.013Z'
                        />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconUnpublish;
