import { defaultSize, defaultStroke } from './defaults';
const IconRepeat1 = ({ size, className }) => {
    return (
        <>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size || defaultSize} height={size || defaultSize}>
                <g
                    fill='none'
                    fillRule='nonzero'
                    stroke='none'
                    strokeWidth='1'
                    strokeLinecap='butt'
                    strokeLinejoin='miter'
                    strokeMiterlimit='10'
                    strokeDasharray=''
                    strokeDashoffset='0'
                    fontFamily='none'
                    fontWeight='none'
                    fontSize='none'
                    textAnchor='none'
                    style={{ mixBlendMode: 'normal' }}
                >
                    <path fill='none' d='M0 0h24v24H0z' />
                    <g fill='currentColor'>
                        <path id='Path_357' data-name='Path 357' d='M12,2A10,10,0,1,1,2,12H4A8,8,0,1,0,5.385,7.5H8v2H2v-6H4V6A9.981,9.981,0,0,1,12,2Z' />
                    </g>
                </g>
            </svg>
        </>
    );
};

export default IconRepeat1;
