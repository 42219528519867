import { animations } from 'constants/animations';
import { motion } from 'framer-motion';
import classNames from 'classnames';

const LayoutPageFooter = ({ children, rtl, animate, relative = false, key = 'PageFooter' }) => {
    return true ? (
        <motion.div
            key={key}
            initial={'in'}
            animate={'in'}
            variants={animations.elementSlides}
            className={classNames(' min-h-vw-16 md:min-h--16 bg-white border-t border-gray-200 transition-all transform duration-300 ease-in-out', {
                'fixed bottom-vw-20 md:bottom-16 inset-x-0': !relative
            })}
        >
            <div className={`py-vw-4 px-vw-6 md:py-4 md:px-6 h-full flex ${rtl ? 'flex-row-reverse' : 'flex-row'} items-center justify-between `}>
                {children}
            </div>
        </motion.div>
    ) : null;
};

export default LayoutPageFooter;
