import * as types from 'store/actions/types';
import { saveNamedState } from 'store/localStorage';
import store from 'store';
export function updateOnboarding(onboarding) {
    saveNamedState('onboarding', onboarding);
    return (dispatch) => {
        dispatch({
            type: types.ONBOARDING_UPDATE,
            onboarding
        });
    };
}

export function updateTooltip(tooltip, value) {
    saveNamedState('onboarding', {
        ...store.getState().onboarding,
        tooltips: { ...store.getState().onboarding.tooltips, [tooltip]: value }
    });
    return (dispatch) => {
        dispatch({
            type: types.ONBOARDING_TOOLTIP_UPDATE,
            tooltip,
            value
        });
    };
}

export function updateFirstTime(value) {
    saveNamedState('onboarding', {
        ...store.getState().onboarding,
        firstTime: value
    });
    return (dispatch) => {
        dispatch({
            type: types.ONBOARDING_FIRSTTIME_UPDATE,
            value
        });
    };
}
