import classNames from 'classnames';

const PosterImage = ({
    src,
    flip,
    bg = 'bg-bb-blue-900',
    rounded = 'rounded-2xl',
    width = 'w-full',
    height = 'h-60'
}) => {
    return (
        <div
            className={classNames(
                'object-cover overflow-hidden',
                bg,
                rounded,
                width,
                height
            )}
        >
            <img src={src} />
        </div>
    );
};

export default PosterImage;
