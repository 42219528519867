import GetLang from 'hoc/GetLang';
import { DateTime } from 'luxon';
import ItinerarySub from 'components/blocks/ItinerarySub';
const Itinerary = ({ data, search, showTime, showDistance, showProximity, archive = false }) => {
    return (
        <GetLang>
            {({ rtl }) => (
                <div className={`flex ${rtl ? 'flex-row-reverse' : 'flex-row'} justify-between px-vw-6 pt-vw-4 pb-vw-6 md:px-6 md:pt-4 md:pb-6`}>
                    <div
                    // style={{ zIndex: 1 }}
                    >
                        <ul>
                            <ItinerarySub
                                status={data?.status}
                                recurring={data?.recurring}
                                showProximity={showProximity}
                                showTime={showTime}
                                showDistance={showDistance}
                                stopData={data.from}
                                time={DateTime.fromISO(`2022-01-01T${data.time}`).toFormat('HH:mm')}
                                locRequested={search?.from?.location?.coordinates}
                                locFound={data.from.location?.coordinates}
                                rtl={rtl}
                                archive={archive}
                            />
                            <ItinerarySub
                                status={data?.status}
                                recurring={data?.recurring}
                                showProximity={showProximity}
                                showTime={showTime}
                                showDistance={showDistance}
                                stopData={data.to}
                                end={true}
                                locRequested={search?.to?.location?.coordinates}
                                locFound={data.to.location?.coordinates}
                                rtl={rtl}
                                archive={archive}
                            />
                        </ul>
                    </div>
                </div>
            )}
        </GetLang>
    );
};

export default Itinerary;
