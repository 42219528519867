import classNames from 'classnames';
import Roundify from 'components/blocks/Roundify';

const ItinerarySubSkeleton = ({ end = false, rtl, type }) => {
    return (
        <li className=''>
            <div className={`px-0 flex ${rtl ? 'flex-row-reverse' : 'flex-row'}`}>
                {/* {type === 'view' && (
                    <div className='flex flex-col pt-2'>
                        <span
                            className={`w-12 flex-shrink-0 text-lg font-bold ${
                                !end ? 'bg-gray-150' : ''
                            }  h-4 rounded-full`}
                        ></span>
                    </div>
                )} */}
                <div className='relative flex flex-col min-h-281 items-center mx-vw-4 flex-shrink-0 '>
                    <div
                        className={`w-vw-1 h-vw-6 ${classNames({
                            'bg-gray-150': end
                        })}`}
                    ></div>
                    <div className='absolute top-vw-4 left-1/2 transform -translate-x-1/2'>
                        <div className='w-vw-2.5 h-vw-2.5 bg-white border-2 rounded-full border-gray-150'></div>
                    </div>
                    <div
                        className={`w-vw-1 h-full ${classNames({
                            'bg-gray-150': !end
                        })}`}
                    ></div>
                </div>
                <div className={`relative pt-vw-2 pb-vw-2 flex flex-col flex-1 ${rtl ? 'items-end' : 'items-start'}`}>
                    <div className={`mb-vw-2 font-bold bg-gray-150 rounded-full ${end ? 'w-vw-36' : 'w-vw-44'}  h-vw-4  capitalize flex `}></div>

                    {(type === 'search' || type === 'view') && (
                        <div className={`flex gap-1 ${rtl ? 'flex-row-reverse' : 'flex-row'}`}>
                            <Roundify height='h-vw-8' width='w-vw-24' bgColor={'bg-gray-150'} />
                        </div>
                    )}
                    {type === 'view' && (
                        <div
                            className={`mt-vw-2 ${end ? 'mb-vw-2' : 'mb-vw-6'} font-bold bg-gray-150 rounded-full ${
                                end ? 'w-vw-20' : 'w-vw-16'
                            } w-vw-48 h-vw-2 capitalize flex `}
                        ></div>
                    )}
                </div>
            </div>
        </li>
    );
};

export default ItinerarySubSkeleton;
