import { motion, useCycle } from 'framer-motion';

import { animations } from 'constants/animations';
import classNames from 'classnames';
import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';

import { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'hooks/useOnClickOutside';
import SwipeContainer from 'pages/app/rides/SwipeContainer';
import BtnIcon from 'components/buttons/BtnIcon';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { widthBiggerThan } from 'helpers/utils';

const swipeWidth = {
    0: {
        width: 95,
        minHeight: 'min-h-vw-36 md:min-h-36'
    },
    1: {
        width: 95,
        minHeight: 'min-h-vw-36 md:min-h-36'
    },
    2: {
        width: 175,
        minHeight: 'min-h-vw-36 md:min-h-36'
    },
    3: {
        width: 250,
        minHeight: 'min-h-vw-44 md:min-h-44'
    },
    4: {
        width: 325,
        minHeight: 'min-h-vw-60 md:min-h-60'
    },
    5: {
        width: 400,
        minHeight: 'min-h-vw-80 md:min-h-80'
    }
};

const CardSection = ({
    tap = true,
    runOnOpen,
    runOnClosed,
    swipeMenuOptions = [],
    border = 'border-b-4',
    borderColor = 'border-gray-200',
    bgColor = 'bg-white',
    verticalStack,
    handleClick,
    arrow,
    children,
    paddingX = 'px-vw-6 md:px-6',
    paddingY = 'py-vw-6 md:py-6',
    containerClass,
    parentAnim,
    arrowColor = 'text-gray-500',
    arrowGap = ''
}) => {
    const [anim, cycleAnim] = useCycle('off', 'on');

    const tmpAnim = parentAnim ?? anim;

    const isOnline = useOnlineStatus();

    const handleButton = () => {
        handleClick?.();
        // handleClick?.(bookings.find((item) => item._id === booking._id));
    };

    const ref = useRef();
    const [isFocus, setIsFocus] = useState(false);
    useOnClickOutside(ref, () => setIsFocus(false));

    return (
        <GetLang>
            {({ rtl }) => (
                <motion.li
                    className={classNames(border, borderColor, 'list-none w-full bg-gray-100 h-full-a border-gray-200 relative')}
                    variants={animations.card}
                    ref={ref}
                >
                    {swipeMenuOptions?.length > 0 && (
                        <div
                            style={{
                                width: verticalStack ? swipeWidth['1'].width : swipeWidth[swipeMenuOptions.length].width
                            }}
                            // className='flex pt-vw-6 bg-gray-50a'
                            className={classNames('bg-gray-100 absolute inset-y-0', rtl ? 'left-0' : 'right-0')}
                        >
                            <div
                                className={classNames(
                                    verticalStack ? 'flex-col pt-vw-4 md:pt-4 gap-2' : 'justify-between',
                                    'flex text-gray-600 w-full h-full items-center ',
                                    paddingX,
                                    !rtl && 'flex-row-reverse'
                                )}
                            >
                                {swipeMenuOptions?.map?.((option) => {
                                    return (
                                        <BtnIcon
                                            key={option.icon}
                                            icon={option.icon ?? 'Check'}
                                            iconSize={option.iconSize ?? widthBiggerThan() ? '24px' : '5.8vw'}
                                            handleClick={option.handleClick ?? null}
                                            params={option.params ?? []}
                                            disabled={!isOnline}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <SwipeContainer
                        runOnOpen={runOnOpen}
                        runOnClosed={runOnClosed}
                        swipe={swipeMenuOptions?.length > 0}
                        rtl={rtl}
                        swipeOffset={verticalStack ? swipeWidth['1'].width : swipeWidth[swipeMenuOptions.length].width}
                        minHeight={verticalStack && swipeWidth[swipeMenuOptions.length].minHeight}
                        isFocus={isFocus}
                        setIsFocus={setIsFocus}
                    >
                        {({ isOpen }) => {
                            return (
                                <div
                                    style={{
                                        width: widthBiggerThan()
                                            ? '100%'
                                            : `calc (100vw + ${verticalStack ? swipeWidth['1'].width : swipeWidth[swipeMenuOptions.length].width})`
                                    }}
                                    className={classNames(
                                        'flex items-stretch flex-none',
                                        verticalStack && swipeWidth[swipeMenuOptions.length].minHeight,
                                        rtl && 'flex-row-reverse'
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            'flex-none w-vw-100 md:w-full bg-white2 relative transition-all duration-300 shadow-bb-gray-400/50',
                                            bgColor,
                                            verticalStack && swipeWidth[swipeMenuOptions.length].minHeight,

                                            tmpAnim === 'on' && tap && 'scale-95'
                                        )}
                                        onTouchStart={handleClick ? () => cycleAnim() : null}
                                        onTouchEnd={handleClick ? () => cycleAnim() : null}
                                        onClick={handleButton}
                                    >
                                        <div
                                            className={classNames(
                                                paddingX,
                                                paddingY,
                                                'flex items-center justify-between h-full',
                                                arrowGap,
                                                rtl ? 'flex-row-reverse' : 'flex-row'
                                            )}
                                        >
                                            <div className={classNames(containerClass, 'h-full w-full')}>{children}</div>
                                            {arrow && <Icons icon={rtl ? 'ArrowLeftShort1' : 'ArrowRightShort1'} containerClass={arrowColor} />}
                                        </div>
                                    </div>
                                </div>
                            );
                        }}
                    </SwipeContainer>
                </motion.li>
            )}
        </GetLang>
    );
};

export default CardSection;
