import classNames from 'classnames';
import { statusStyles } from 'constants/styles';
import { getStatusStyle } from 'helpers/helperFunctions';

import ProximityStatus from './ProximityStatus';

const buildAddress = (data, rtl) => {
    const { formatted, admin_l2, admin_l1, locality, address } = data[rtl ? 'ar' : 'en'];
    if (formatted) {
        const plusCodeParse = parsePlusCode(formatted);
        // console.log('parsedCode', plusCodeParse);
        if (plusCodeParse === 'lebanon') return address || locality || admin_l2 || admin_l1;
        return plusCodeParse.toLowerCase().replace(', lebanon', '');
    } else {
        if (admin_l2) {
            if (locality) {
                if (address) {
                    return `${address} - ${locality}`;
                } else return locality;
            } else return admin_l2;
        } else return admin_l1;
    }
};

const parsePlusCode = (str) => {
    const firstWord = str?.split?.(' ')?.[0];
    if (firstWord.charAt(4) === '+') {
        return str.slice(firstWord.length + 1);
    }
    return str;
    // plus code
};

const ItinerarySub = ({
    status = 'PUBLISHED',
    showDistance,
    showTime,
    showProximity,
    stopData,
    time,
    end = false,
    locFound,
    locRequested,
    rtl,
    archive = false,
    recurring
}) => {
    // console.log(status);
    // console.log(archive);
    return (
        <li className=''>
            <div className={`px-0 flex ${rtl ? 'flex-row-reverse' : 'flex-row'}`}>
                {showTime && (
                    <div className='flex flex-col pt-vw-2 md:pt-2'>
                        <time
                            className={classNames(
                                'w-vw-12 md:w-12 flex-shrink-0 text-vw-lg font-semibold',
                                statusStyles[archive ? 'ARCHIVE' : getStatusStyle(status)].itinerary.text
                            )}
                        >
                            {time}
                        </time>
                    </div>
                )}
                <div className='relative flex flex-col min-h-281a items-center mx-vw-4 md:mx-4 flex-shrink-0 '>
                    <div
                        className={`w-vw-1 md:w-1 h-vw-8 md:h-8 ${classNames({
                            [statusStyles[archive ? 'ARCHIVE' : getStatusStyle(status)].itinerary.line]: end
                            // 'bg-white': !end
                        })}`}
                    ></div>
                    <div className='absolute top-vw-4 md:top-4 left-1/2 transform -translate-x-1/2'>
                        <div
                            className={classNames(
                                'w-vw-2.5 md:w-2.5 h-vw-2.5 md:h-2.5 bg-white border-2 rounded-full',
                                statusStyles[archive ? 'ARCHIVE' : getStatusStyle(status)].itinerary.border
                            )}
                        ></div>
                    </div>

                    <div
                        className={`w-vw-1 md:w-1 h-full ${classNames({
                            [statusStyles[archive ? 'ARCHIVE' : getStatusStyle(status)].itinerary.line]: !end
                        })}`}
                    ></div>
                </div>
                <div className={`relative pt-vw-2 md:pt-2 flex flex-col flex-1 ${rtl ? 'items-end' : 'items-start'}`}>
                    <div
                        className={classNames(
                            'font-semibold capitalize flex text-vw-base md:text-base',
                            statusStyles[archive ? 'ARCHIVE' : getStatusStyle(status)].itinerary.text,
                            { 'flex-row-reverse text-right': rtl }
                        )}
                    >
                        {buildAddress(stopData, rtl)}
                    </div>
                    {showProximity && locFound && locRequested && (
                        <ProximityStatus showDistance={showDistance} locRequested={locRequested} locFound={locFound} rtl={rtl} end={end} />
                    )}
                </div>
            </div>
        </li>
    );
};

export default ItinerarySub;
