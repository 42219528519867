import classNames from 'classnames';
import GetLang from 'hoc/GetLang';

const HeadlineType1 = ({ label, children, textSize = 'text-vw-h3', textColor = 'text-bb-blue-500', decoration = true, margins = 'mb-vw-2' }) => {
    return (
        <GetLang>
            {({ rtl, translations }) => (
                <div className={classNames('font-bold flex items-center justify-center gap-3', textSize, textColor, margins)}>
                    {decoration && <DecorationCircle />}
                    <span className='flex-none'>{children ?? translations?.data?.[label] ?? label}</span>
                    {decoration && <DecorationCircle />}
                </div>
            )}
        </GetLang>
    );
};

export default HeadlineType1;

const DecorationCircle = () => {
    return <span className='w-vw-2 h-vw-2 rounded-full bg-bb-yellow-400' />;
};
