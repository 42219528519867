import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleLang, toggleNav } from 'store/actions/globalState';
import { ReactComponent as LogoFullWhite } from 'components/svg/logo_full_white.svg';

import { LOGOUT } from 'store/actions/types';
import { genericAction } from 'store/actions/generic';

import MenuItem from 'components/blocks/MenuItem';
import MenuRuler from 'components/blocks/MenuRuler';
import MenuIcon from 'components/blocks/MenuIcon';
import Icons from 'components/icons/index';
import { translations } from 'constants/translations';
import { routePath } from 'constants/structRoutes';
import { motion, AnimatePresence, useCycle } from 'framer-motion';

import { animations, getAnimStates, getReducedMotion } from 'constants/animations';
import { PageSettings } from 'pages';
import AvatarFull from 'components/blocks/AvatarFull';
import { useNavigate } from 'react-router-dom';
import useMyNavigation from 'hooks/useMyNavigation';
import PageBug from 'pages/app/settings/PageBug';
import BtnDonate from 'components/buttons/BtnDonate';
import { widthBiggerThan } from 'helpers/utils';
import BtnIcon from 'components/buttons/BtnIcon';
import NavButton from 'components/blocks/NavButton';
import { setStaticPage } from 'store/actions/staticPages';
import { appVersion } from 'constants/globalConstants';

const LayoutMenu = ({
    setStaticPage,
    genericAction,
    toggleNav,
    globalState: { lang, navIsOpen, reducedMotion, newNotifications },
    auth: { isAuthenticated, user }
}) => {
    const rtl = translations?.[lang]?.rtl;
    const [menuDepth, setMenuDepth] = useState(500);
    const { location, handleNavigation } = useMyNavigation();

    useEffect(() => {
        // console.log('location is', location);
        // console.log(routePath('login'));
        if (location.pathname === routePath('login')) setMenuDepth(-1);
        else setMenuDepth(500);
    }, [location]);

    const handleLogout = () => {
        handleNavigation('app.login', null);
        setTimeout(() => genericAction({ type: LOGOUT }), 300);
        handleNavClick();
    };
    const handleNavClick = () => {
        toggleNav(false);
    };

    const handleProfile = () => {
        handleNavigation('app.profile', null);
        toggleNav(false);
    };

    const [settings, setSettings] = useState(false);
    const [bug, setBug] = useState(false);
    const [anim, cycleAnim] = useCycle('off', 'on');
    const handleSettings = (st) => {
        setSettings(st);
        // if (st) setTimeout(() => toggleNav(false), 400);
    };

    const handleBug = (st) => {
        setBug(st);
        // if (st) setTimeout(() => toggleNav(false), 400);
    };

    const animStates = {
        settings: getAnimStates('app.settings').animPages,
        bugs: getAnimStates('app.bugs').animPages
    };

    const iconSizes = widthBiggerThan(1024) ? '24px' : widthBiggerThan() ? '28px' : '5.8vw';
    // const iconSizes = widthBiggerThan() ? '32px' : '5.8vw';

    const textSizes = 'text-vw-sm md:text-xl xl:text-base';

    return (
        <>
            <motion.div
                key='MainMenu'
                style={{ zIndex: navIsOpen ? 500 : menuDepth }}
                initial={false}
                // animate={navIsOpen ? 'in' : rtl ? 'right' : 'left'}
                animate={navIsOpen ? 'in' : rtl ? 'menuRight' : 'menuLeft'}
                variants={animations.pageSlides}
                className={classNames(
                    'overflow-hidden fixed w-full top-0 bottom-vw-20 md:top-0 md:w-128 lg:w-96 md:bottom-0 bg-gradient-to-tr from-bb-blue-700 to-bb-blue-900',
                    rtl ? 'bg-gradient-to-tl right-0' : 'bg-gradient-to-tr left-0',
                    {
                        'pointer-events-none': !navIsOpen
                    }
                )}
            >
                <div
                    className='overflow-y-auto no-scrollbar h-full  pb-vw-8 md:pb-8'
                    // initial={rtl ? 'right' : 'left'}
                    // animate={navIsOpen ? 'in' : rtl ? 'right' : 'left'}
                    // variants={animations.pageSlides}
                >
                    <div
                        className={classNames(
                            'flex w-screen-2/3 h-vh-8 mx-auto in-out-expo justify-center items-center my-vh-3  md:hidden'
                            // navIsOpen ? 'translate-x-0a' : rtl ? 'translate-x-1/4a' : '-translate-x-1/4a'
                        )}
                    >
                        <LogoFullWhite />
                    </div>
                    <div
                        className={classNames(
                            rtl && 'flex-row-reverse',
                            'hidden md:flex justify-between h-8 items-center my-10 px-4'
                            // navIsOpen ? 'translate-x-0a' : rtl ? 'translate-x-1/4a' : '-translate-x-1/4a'
                        )}
                    >
                        <BtnIcon icon='ArrowLeftLong1' noBg color='text-white' handleClick={toggleNav} flip={rtl} />
                        <LogoFullWhite />
                        <span className='w-12' />
                    </div>

                    {/* <NavButton toggleNav={toggleNav} navIsOpen={navIsOpen} desktop={widthBiggerThan()} /> */}

                    <nav className='relative '>
                        {isAuthenticated && (
                            <div className='px-vw-6 md:px-6 select-none '>
                                <button
                                    onClick={handleProfile}
                                    onTouchStart={() => cycleAnim()}
                                    onTouchEnd={() => cycleAnim()}
                                    className={classNames(
                                        'w-full focus:outline-none outline-none px-vw-6 py-vw-4 md:px-6 md:py-4 border-ba border-ta text-white border-white border-opacity-30 bg-gradient-to-tr from-bb-blue-500/10 to-red-200/20  rounded-3xl md:rounded-xl shadow-2xl  flex items-center justify-between transition-all duration-300 ',
                                        rtl && 'flex-row-reverse',
                                        anim === 'on' ? 'translate-y-2 ' : ''
                                    )}
                                >
                                    <AvatarFull profile={user?.profile} preset='dark' noVerification={true} />
                                    <Icons icon={rtl ? 'ArrowLeftShort1' : 'ArrowRightShort1'} className='flex-none ' />
                                </button>
                            </div>
                        )}

                        {/* <BtnDonate /> */}

                        <ul className='w-full flex flex-col gap-1 mt-vw-8 md:mt-8'>
                            {isAuthenticated ? (
                                <>
                                    {/* <NavItem
                        id={4}
                        // label={' '}
                        label={translations?.[lang]?.data?.['nav.news']}
                        icon='Notification'
                        linkTo={routePath('app.notifications')}
                        handleClick={toggleNav}
                        delay={3}
                        animationState={isAuthenticated}
                        notification={newNotifications}
                        rtl={rtl}
                    /> */}

                                    {/* <MenuItem
                                        label={'nav.messages'}
                                        icon='Messages1'
                                        iconSize={iconSizes}
                                        linkTo={`${routePath('app.messages')}`}
                                        handleClick={handleNavClick}
                                        delay={4}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    /> */}
                                    <MenuItem
                                        label={'nav.news'}
                                        icon='Notification'
                                        iconSize={iconSizes}
                                        linkTo={`${routePath('app.notifications')}`}
                                        handleClick={handleNavClick}
                                        delay={4}
                                        animationState={navIsOpen}
                                        notification={newNotifications}
                                        rtl={rtl}
                                    />
                                    {/* <MenuItem
                                        label={'menu.rewards'}
                                        icon='Reward1'
                                        iconSize={iconSizes}
                                        linkTo=''
                                        handleClick={() => handleSettings(true)}
                                        button
                                        delay={4}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    /> */}

                                    {/* <MenuItem
                                        label={'menu.ambassador'}
                                        icon='Empathy'
                                        iconSize={iconSizes}
                                        linkTo=''
                                        handleClick={() => handleSettings(true)}
                                        button
                                        delay={4}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    /> */}
                                    <MenuItem
                                        label={'menu.settings'}
                                        icon='Settings'
                                        iconSize={iconSizes}
                                        linkTo=''
                                        handleClick={() => handleSettings(true)}
                                        button
                                        delay={4}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                    {user?.admin && (
                                        <>
                                            <MenuRuler label='menu.admin.title' />
                                            <MenuItem
                                                label={'menu.admin.users'}
                                                icon='Users'
                                                iconSize={iconSizes}
                                                linkTo={`${routePath('app.admin.users')}`}
                                                handleClick={handleNavClick}
                                                delay={3}
                                                animationState={navIsOpen}
                                                rtl={rtl}
                                            />
                                            <MenuItem
                                                label={'menu.admin.verify'}
                                                icon='Verified'
                                                iconSize={iconSizes}
                                                linkTo={`${routePath('app.admin.verify')}`}
                                                handleClick={handleNavClick}
                                                delay={3}
                                                animationState={navIsOpen}
                                                rtl={rtl}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <MenuItem
                                        label={'menu.register'}
                                        icon='UserPlus'
                                        iconSize={iconSizes}
                                        linkTo={`${routePath('register')}`}
                                        handleClick={handleNavClick}
                                        delay={3}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                    <MenuItem
                                        label={'menu.login'}
                                        icon='Login'
                                        iconSize={iconSizes}
                                        linkTo={`${routePath('login')}`}
                                        handleClick={handleNavClick}
                                        delay={4}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                    <MenuItem
                                        label={'menu.settings'}
                                        icon='Settings'
                                        iconSize={iconSizes}
                                        linkTo=''
                                        handleClick={() => handleSettings(true)}
                                        button
                                        delay={4}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                </>
                            )}
                        </ul>

                        <MenuRuler />
                        <ul className='w-full flex flex-col gap-1'>
                            {user?.admin && user?.phoneNumber === '33648517574' ? (
                                <MenuItem
                                    label={'Demo Page'}
                                    icon='Info'
                                    labelClass={textSizes}
                                    iconSize={iconSizes}
                                    // linkTo={`${routePath('static.about')}`}
                                    button={true}
                                    handleClick={() => setStaticPage('demo1')}
                                    delay={5}
                                    animationState={navIsOpen}
                                    rtl={rtl}
                                />
                            ) : null}
                            <MenuItem
                                label={'menu.about'}
                                icon='Drop1'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.about')}`}
                                button={true}
                                handleClick={() => setStaticPage('about')}
                                delay={5}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.ladiesOnly'}
                                icon='Female1'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.safety')}`}
                                button={true}
                                handleClick={() => setStaticPage('ladiesOnly')}
                                delay={6}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.pricing'}
                                icon='Coins'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.safety')}`}
                                button={true}
                                handleClick={() => setStaticPage('pricing')}
                                delay={7}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.safety'}
                                icon='ShieldCheck'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.safety')}`}
                                button={true}
                                handleClick={() => setStaticPage('safety')}
                                delay={8}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.faq'}
                                icon='MessageQuestion'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.faq')}`}
                                button={true}
                                handleClick={() => setStaticPage('faq')}
                                delay={9}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                        </ul>
                        {/* <MenuRuler /> */}

                        <ul className='w-full flex flex-col gap-1'>
                            <MenuItem
                                label={'menu.help.support'}
                                icon='Support1'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.help.support')}`}
                                button={true}
                                handleClick={() => setStaticPage('support')}
                                delay={10}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.help.terms'}
                                labelClass={textSizes}
                                icon='FileList3'
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.help.terms')}`}
                                button={true}
                                handleClick={() => setStaticPage('terms')}
                                delay={11}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.help.privacy'}
                                labelClass={textSizes}
                                icon='EyeOff'
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.help.privacy')}`}
                                button={true}
                                handleClick={() => setStaticPage('privacy')}
                                delay={12}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                            <MenuItem
                                label={'menu.help.weNeedYou'}
                                icon='Heart3'
                                labelClass={textSizes}
                                iconSize={iconSizes}
                                // linkTo={`${routePath('static.help.weNeedYou')}`}
                                button={true}
                                handleClick={() => setStaticPage('weneedyou')}
                                delay={13}
                                animationState={navIsOpen}
                                rtl={rtl}
                            />
                        </ul>
                        <MenuRuler />
                        <ul className='w-full flex flex-col gap-1 '>
                            {isAuthenticated && (
                                <>
                                    <MenuItem
                                        label={'menu.help.latestNews'}
                                        icon='List1'
                                        iconSize={iconSizes}
                                        button={true}
                                        handleClick={() => setStaticPage('latestNews')}
                                        delay={14}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                    <MenuItem
                                        label={'e.bug.submitBug'}
                                        icon='Bug1'
                                        iconSize={iconSizes}
                                        linkTo=''
                                        handleClick={() => handleBug(true)}
                                        button
                                        delay={15}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                    <MenuItem
                                        active={false}
                                        label={'menu.logout'}
                                        icon='Logout'
                                        iconSize={iconSizes}
                                        linkTo={`${routePath('app.searchCarSharing')}`}
                                        handleClick={handleLogout}
                                        delay={16}
                                        animationState={navIsOpen}
                                        rtl={rtl}
                                    />
                                </>
                            )}
                        </ul>

                        <MenuRuler />
                        <div className='px-vw-10 md:px-24'>
                            <ul className='w-full justify-between flex '>
                                <a
                                    href='https://www.twitter.com/BalaBenzine'
                                    target='_blank'
                                    className='w-vw-8 md:w-8 flex items-center justify-center text-white'
                                >
                                    <Icons icon={'Twitter'} size={iconSizes} />
                                </a>
                                <a
                                    href='https://m.facebook.com/balabenzine'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='w-vw-8 md:w-8 flex items-center justify-center text-white'
                                >
                                    <Icons icon={'Facebook'} size={iconSizes} />
                                </a>
                                <a
                                    href='https://www.instagram.com/bala_benzine'
                                    target='_blank'
                                    className='w-vw-8 md:w-8 flex items-center justify-center text-white'
                                >
                                    <Icons icon={'Instagram'} size={iconSizes} />
                                </a>
                                {/* <a href='https://www.youtube.com' target='_blank' className='w-vw-8 md:w-8 flex items-center justify-center text-white'>
                                    <Icons icon={'Youtube'} size={iconSizes} />
                                </a> */}
                                <a
                                    href='https://www.linkedin.com/company/bala-benzine/'
                                    target='_blank'
                                    className='w-vw-8 md:w-8 flex items-center justify-center text-white'
                                >
                                    <Icons icon={'Linkedin'} size={iconSizes} />
                                </a>
                            </ul>
                        </div>
                        <MenuRuler />
                        <div className='flex justify-center text-white text-vw-xxs tracking-wide'>{`Bala Benzine App v.${appVersion}`}</div>
                    </nav>
                </div>
                {/* <div
                    style={{ zIndex: 60 }}
                    className='fixed inset-x-0 bottom-0 h-vw-32 md:h-40 bg-gradient-to-t from-bb-blue-700 to-transparent pointer-events-none md:hidden'
                ></div> */}
            </motion.div>
            <AnimatePresence>
                {settings && (
                    <motion.div
                        key='settings'
                        className='fixed z-1000 inset-0 bg-white'
                        initial={getReducedMotion('page', animStates.settings, reducedMotion).initial}
                        animate={getReducedMotion('page', animStates.settings, reducedMotion).animate}
                        variants={animations.pageSlides}
                        exit={() => getReducedMotion('page', animStates.settings, reducedMotion).exit}
                    >
                        <PageSettings handleSettings={handleSettings} />
                    </motion.div>
                )}

                {bug && (
                    <motion.div
                        key='bug'
                        className='fixed z-1000 inset-0 bg-white'
                        initial={getReducedMotion('page', animStates.bugs, reducedMotion).initial}
                        animate={getReducedMotion('page', animStates.bugs, reducedMotion).animate}
                        variants={animations.pageSlides}
                        exit={() => getReducedMotion('page', animStates.bugs, reducedMotion).exit}
                    >
                        <PageBug handleBug={handleBug} />
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

const mapStateToProps = (state) => ({
    globalState: state.globalState,
    auth: state.auth
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleNav,
            genericAction,
            setStaticPage
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMenu);
