import Icons from 'components/icons/index';
import GetLang from 'hoc/GetLang';
import BtnPill from './BtnPill';

const BtnMiddleAction = ({
    params,
    handleClick,
    disabled,
    label = 'e.viewBookingRequest',
    icon = 'ArrowRightShort1',
    flip,
    justify = 'justify-between',
    type = 'confirmed',
    iconSize
}) => {
    return (
        <GetLang>
            {({ rtl, translations }) => {
                return (
                    <BtnPill type={type} justify={justify} rtl={rtl} disabled={disabled} params={params} handleClick={handleClick}>
                        <>
                            <span className='w-6' />
                            <span
                                style={{
                                    direction: rtl ? 'rtl' : 'ltr'
                                }}
                            >
                                {translations?.data?.[label] ?? label}
                            </span>
                            <Icons icon={icon} flip={flip ?? rtl} size={iconSize} />
                        </>
                    </BtnPill>
                );
            }}
        </GetLang>
    );
};

export default BtnMiddleAction;
