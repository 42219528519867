import { routePath, validateRoute } from 'constants/structRoutes';
import {
    Outlet,
    useParams,
    useNavigate,
    useLocation,
    Navigate,
    Link
} from 'react-router-dom';
import { motion } from 'framer-motion';
import { animations } from 'constants/animations';

const PageAppHome = () => {
    let location = useLocation();

    if (validateRoute('app', location)) {
        return (
            <Navigate to={routePath('app.searchCarSharing')} />

            // <motion.div
            //     style={{ zIndex: 30 }}
            //     className='fixed inset-0 flex flex-col gap-4 bg-red-100 '
            //     initial={'static'}
            //     animate={'static'}
            //     exit={'static'}
            //     variants={animations.pageSlides}
            // >
            //     <h1>Page App Home</h1>
            // </motion.div>
        );
    }

    return (
        <>
            <Outlet />
        </>
    );
};

export default PageAppHome;
