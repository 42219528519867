import React from 'react';
import IconAdd from './IconAdd';
import IconArchive from './IconArchive';
import IconAlarm from './IconAlarm';
import IconArrowBack from './IconArrowBack';
import IconArrowDownLong from './IconArrowDownLong';
import IconArrowUpLong from './IconArrowUpLong';
import IconArrowLeftLong1 from './IconArrowLeftLong1';
import IconArrowRightLong1 from './IconArrowRightLong1';
import IconArrowLeftShort1 from './IconArrowLeftShort1';
import IconArrowRightShort1 from './IconArrowRightShort1';
import IconArrowUpDown1 from './IconArrowUpDown1';
import IconArrowUpDown2 from './IconArrowUpDown2';
import IconBug1 from './IconBug1';
import IconCalendar2 from './IconCalendar2';
import IconCalendar3 from './IconCalendar3';
import IconCar1 from './IconCar1';
import IconCheck from './IconCheck';
import IconCheckCircle from './IconCheckCircle';
import IconCheckDouble from './IconCheckDouble';
import IconClose from './IconClose';
import IconCloseCircle from './IconCloseCircle';
import IconCloud from './IconCloud';
import IconCoins from './IconCoins';
import IconCompass1 from './IconCompass1';
import IconCloudOff from './IconCloudOff';
import IconDownloadCloud2 from './IconDownloadCloud2';
import IconDraft1 from './IconDraft1';
import IconDraft2 from './IconDraft2';
import IconDrop1 from './IconDrop1';
import IconEmpathy from './IconEmpathy';
import IconEye from './IconEye';
import IconEyeClose from './IconEyeClose';
import IconEyeOff from './IconEyeOff';
import IconFacebook from './IconFacebook';
import IconFemale1 from './IconFemale1';
import IconFileList3 from './IconFileList3';
import IconFind from './IconFind';
import IconGasStation from './IconGasStation';
import IconGlobal1 from './IconGlobal1';
import IconGlobal2 from './IconGlobal2';
import IconHistory1 from './IconHistory1';
import IconHeart1 from './IconHeart1';
import IconHeart2 from './IconHeart2';
import IconHeart3 from './IconHeart3';
import IconInfo from './IconInfo';
import IconId1 from './IconId1';
import IconId2 from './IconId2';
import IconInstagram from './IconInstagram';
import IconItinerary1 from './IconItinerary1';
import IconItinerary2 from './IconItinerary2';
import IconLinkedin from './IconLinkedin';
import IconList1 from './IconList1';
import IconLock1 from './IconLock1';
import IconLogin from './IconLogin';
import IconLogoBlue from './IconLogoBlue';
import IconLogout from './IconLogout';
import IconLogoWhite from './IconLogoWhite';
import IconMale1 from './IconMale1';
import IconMapPin1 from './IconMapPin1';
import IconMenuLeft1 from './IconMenuLeft1';
import IconMessageQuestion from './IconMessageQuestion';
import IconMessages1 from './IconMessages1';
import IconMoreH from './IconMoreH';
import IconMoreV from './IconMoreV';
import IconNotification from './IconNotification';
import IconPhone1 from './IconPhone1';
import IconPlane1 from './IconPlane1';
import IconProfile1 from './IconProfile1';
import IconPublishPlus from './IconPublishPlus';
import IconRefresh from './IconRefresh';
import IconRepeat1 from './IconRepeat1';
import IconReward1 from './IconReward1';
import IconRide1 from './IconRide1';
import IconRoute1 from './IconRoute1';
import IconSettings from './IconSettings';
import IconShieldCheck from './IconShieldCheck';
import IconShieldUser from './IconShieldUser';
import IconSlash from './IconSlash';
import IconStarFilled from './IconStarFilled';
import IconStarStroke from './IconStarStroke';
import IconSubtract from './IconSubtract';
import IconSupport1 from './IconSupport1';
import IconTime from './IconTime';
import IconTrash from './IconTrash';
import IconTwitter from './IconTwitter';
import IconUnlock1 from './IconUnlock1';
import IconUploadCloud from './IconUploadCloud';
import IconUnpublish from './IconUnpublish';
import IconUser from './IconUser';
import IconUsers from './IconUsers';
import IconUser3 from './IconUser3';
import IconUser6 from './IconUser6';
import IconUserBlock from './IconUserBlock';
import IconUserUnblock from './IconUserUnblock';
import IconUserCheck from './IconUserCheck';
import IconUserPlus from './IconUserPlus';
import IconVerified from './IconVerified';
import IconWalk1 from './IconWalk1';
import IconWhatsapp from './IconWhatsapp';
import IconYoutube from './IconYoutube';
import classNames from 'classnames';

const Icons = ({ icon = 'Car1', size, containerClass, displayClass, className, stroke, flip = false, ...rest }) => {
    return (
        <span
            {...rest}
            className={classNames(containerClass, {
                '-scale-x-100': flip
            })}
        >
            {React.createElement(Map[`Icon${icon}`] || Map['IconClose'], {
                size,
                className,
                stroke,
                displayClass
            })}
        </span>
    );
};

const Map = {
    IconAdd,
    IconArchive,
    IconAlarm,
    IconArrowBack,
    IconArrowDownLong,
    IconArrowUpLong,
    IconArrowLeftLong1,
    IconArrowRightLong1,
    IconArrowLeftShort1,
    IconArrowRightShort1,
    IconArrowUpDown1,
    IconArrowUpDown2,
    IconBug1,
    IconCalendar2,
    IconCalendar3,
    IconCar1,
    IconCheck,
    IconCheckCircle,
    IconCheckDouble,
    IconClose,
    IconCloseCircle,
    IconCloud,
    IconCoins,
    IconCloudOff,
    IconCompass1,
    IconDownloadCloud2,
    IconDraft1,
    IconDraft2,
    IconDrop1,
    IconEmpathy,
    IconEye,
    IconEyeClose,
    IconEyeOff,
    IconFacebook,
    IconFemale1,
    IconFileList3,
    IconFind,
    IconGasStation,
    IconGlobal1,
    IconGlobal2,
    IconHistory1,
    IconHeart1,
    IconHeart2,
    IconHeart3,
    IconInfo,
    IconId1,
    IconId2,
    IconInstagram,
    IconItinerary1,
    IconItinerary2,
    IconLinkedin,
    IconList1,
    IconLock1,
    IconLogin,
    IconLogoBlue,
    IconLogout,
    IconLogoWhite,
    IconMale1,
    IconMapPin1,
    IconMenuLeft1,
    IconMessageQuestion,
    IconMessages1,
    IconMoreH,
    IconMoreV,
    IconNotification,
    IconPhone1,
    IconPlane1,
    IconProfile1,
    IconPublishPlus,
    IconRefresh,
    IconRepeat1,
    IconReward1,
    IconRide1,
    IconRoute1,
    IconSettings,
    IconShieldCheck,
    IconShieldUser,
    IconSlash,
    IconStarFilled,
    IconStarStroke,
    IconSubtract,
    IconSupport1,
    IconTime,
    IconTrash,
    IconTwitter,
    IconUnlock1,
    IconUploadCloud,
    IconUnpublish,
    IconUser,
    IconUsers,
    IconUser3,
    IconUser6,
    IconUserBlock,
    IconUserUnblock,
    IconUserCheck,
    IconUserPlus,
    IconVerified,
    IconWalk1,
    IconWhatsapp,
    IconYoutube
};

export default Icons;
